import React from 'react';
import { LastModifiedButton } from '@michelin/changelogs-ui';
import defaultConfig from 'global-variables';
import { getBaseUrl } from 'prefs-and-service-offers';
import { useHistory, useParams } from 'react-router';

function LastModified({ entity, identifier }: { entity: string; identifier?: string }) {
  const { selectedAccount } = useParams<{ selectedAccount: string }>();
  const history = useHistory();
  const changeLogsEndpoint: string = `${defaultConfig.externalAPIUrl}/cp-be/changelogs-ms/${selectedAccount}/`;

  return (
    <LastModifiedButton
      api={changeLogsEndpoint}
      entity={entity}
      identifier={identifier}
      callback={() => {
        history.push(`${getBaseUrl()}/change-history/list#entity=${entity}`);
      }}
    />
  );
}

export default LastModified;
