/* eslint-disable react/no-unused-prop-types */
import React, { MouseEvent, useState } from 'react';
import { Dialog, Link, Typography } from '@material-ui/core';
import { LoadingBounce } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import moment from 'moment-timezone';
import { useRouteMatch } from 'react-router-dom';
import { ClosableDialogContent } from './ClosableDialogContent';
import { ClosableDialogTitle } from './ClosableDialogTitle';
import * as Utils from './Utils';

/*
  This component it's related to the task GSDCP-58.
  It needs a Date, Title as props and a Dialog Content as a Child.
  Ex.:
    <ClosableDialogLink lastUpdateDate={lastUpdateDate} title={title}>
      <LogTable tableColumns={tableColumns}/>
    </ClosableDialogLink>
*/
interface ClosableDialogLinkProps {
  lastUpdateDate?: Date | null;
  title: string;
  children?: JSX.Element | null;
  loading: boolean;
  error?: boolean;
}

export function ClosableDialogLink(props: ClosableDialogLinkProps) {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const urlParts = url.split('/');
  const isMainProfilesNPreferences = urlParts.length === 4 && urlParts[2] === urlParts[3];

  const [open, setOpen] = useState(false);
  const handleClickOpen = (e: MouseEvent) => {
    setOpen(true);
    e.stopPropagation();
  };
  const { lastUpdateDate, title } = props;
  let linkText = !props.error ? t('Not Started') : t('Error loading data');
  let linkTitle = '';
  if (lastUpdateDate) {
    const officialDate = new Date(lastUpdateDate.toString());
    linkTitle = `${moment(lastUpdateDate.toString()).tz('America/New_York').format('YYYY-MM-DD HH:mm:ss')} EST`;
    linkText = `${t('{{month}} {{day}}, {{year}}', {
      month: t(Utils.getShortMonthKey(officialDate.getMonth() + 1)),
      day: officialDate.getDate(),
      year: officialDate.getFullYear(),
    })}`;
  }

  return (
    <div style={{ display: 'inline-flex' }}>
      <Link
        style={{
          cursor: lastUpdateDate ? 'pointer' : 'text',
          textDecoration: lastUpdateDate ? 'underline' : 'none',
        }}
        variant="body2"
        color="primary"
        onClick={
          lastUpdateDate
            ? handleClickOpen
            : (e: MouseEvent) => {
                e.stopPropagation();
              }
        }
        title={linkTitle}
      >
        <Typography style={{ fontSize: '0.8rem' }}>
          {props.loading && !props.error ? (
            <LoadingBounce
              style={{
                zoom: 0.7,
                opacity: 0.7,
                marginTop: isMainProfilesNPreferences ? 14 : 6,
                marginRight: 8,
              }}
            />
          ) : (
            <>
              <span style={{ whiteSpace: 'nowrap' }}>{t('Last modified:')}</span>
              {isMainProfilesNPreferences ? <br /> : ' '}
              <span style={{ whiteSpace: 'nowrap' }}>{linkText}</span>
            </>
          )}
        </Typography>
      </Link>
      <Dialog
        onClick={(e: MouseEvent) => {
          e.stopPropagation();
        }}
        onClose={() => setOpen(false)}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="xl"
      >
        <ClosableDialogTitle onClose={() => setOpen(false)}>{title}</ClosableDialogTitle>

        <ClosableDialogContent dividers>{props.children}</ClosableDialogContent>
      </Dialog>
    </div>
  );
}
