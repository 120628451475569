import React, { useEffect, useMemo, useState } from 'react';
import { Dialog, DialogContent, Link, useMediaQuery, useTheme } from '@material-ui/core';
import { Contacts, Star } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { FilterGroup, Panel, ValueAvatar, useListSelectFilter } from '@michelin/acid-components';
import {
  clearMaestroDataSourceCache,
  useMaestroDataSource,
  usePermissions,
  useTranslation,
} from '@michelin/central-provider';
import { LocationOVM } from '@michelin/fcp-view-models/dist/models/location';
import { SelectOption, getOptions } from '@michelin/select-options-provider';
import CreatedByAvatar from 'components/CreatedByAvatar';
import { CONTENT_AREA_HEIGHT } from 'components/Util';
import { Template } from 'devextreme-react';
import DataGrid, { Column, SearchPanel } from 'devextreme-react/data-grid';
import GLOBAL_CONFIG from 'global-variables';
import { ContactsTable } from 'modules/Contacts/Tables/ContactsTable';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';
import { getBaseUrl } from '../../../prefs-and-service-offers';

export function addCountry(options: SelectOption[], country: string) {
  return options.map((option) => {
    if (option.value === 'All' || option.value === 'all') return option;
    return {
      value: option.value,
      label: `${option.label} (${country})`,
    };
  });
}

const useStyles = makeStyles(() => ({
  avatarDiv: {
    '&:hover': { cursor: 'pointer' },
    width: '100%',
  },
}));

const filterTheAccountTypes = (options: SelectOption[], customerType: string) => {
  let ret: SelectOption[] = [];
  if (customerType) {
    const innerType = customerType;
    ret = options.filter((a: SelectOption) => {
      if (/pc/i.test(innerType)) {
        return true;
      }
      if (/ho/i.test(innerType)) {
        if (/^pc/i.test(a.value)) return false;
      }
      if (/bt/i.test(innerType)) {
        if (/^(pc|ho)/i.test(a.value)) return false;
      }
      if (/st/i.test(innerType)) {
        if (/^(pc|ho|bt)/i.test(a.value)) return false;
      }
      return true;
    });
  }
  ret.sort((a: SelectOption, b: SelectOption) => {
    if (/all/i.test(a.value)) return -5;
    if (/all/i.test(b.value)) return 5;
    if (/^pc/i.test(a.value)) return -4;
    if (/^pc/i.test(b.value)) return 4;
    if (/^ho/i.test(a.value)) return -3;
    if (/^ho/i.test(b.value)) return 3;
    if (/^bt/i.test(a.value)) return -2;
    if (/^bt/i.test(b.value)) return 2;
    if (/^st/i.test(a.value)) return 1;
    if (/^st/i.test(b.value)) return -1;
    return 0;
  });
  return ret;
};

const baseURL = GLOBAL_CONFIG.externalAPIUrl;

export function LocationsTable() {
  /* **************** */
  /* Local Variables */
  /* *************** */
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const permissions = usePermissions();
  const theme = useTheme();
  const cellView = useMediaQuery(theme.breakpoints.down(500));
  const classes = useStyles();
  const [state, setState] = useState({
    dialogOpen: false,
    locationCustomer: undefined,
  });
  const { dialogOpen, locationCustomer } = state;
  const params = useParams<{ selectedAccount: string }>();

  /* *********** */
  /* Permissions */
  /* *********** */
  const listPermission = permissions.allowsAction('locations.list');
  const readPermission = permissions.allowsAction('locations.read');

  if (!listPermission) {
    enqueueSnackbar(t('User has no permissions to list Locations.'), {
      variant: 'warning',
    });
    history.push(getBaseUrl());
  } else if (!readPermission) {
    enqueueSnackbar(t('User has no permissions to read Locations.'), {
      variant: 'warning',
    });
    history.push(getBaseUrl());
  }

  /* *********** */
  /* Listing     */
  /* *********** */

  const locationCustomerType = permissions?.location?.customer_type || '';
  const accountType = useMemo(() => {
    const aux = getOptions('account_types', 'all', t('Any Account Type'));
    return filterTheAccountTypes(aux, locationCustomerType);
  }, [locationCustomerType, t]);

  const accountTypeFilter = useListSelectFilter({
    label: t('Account Type'),
    options: accountType,
    defaultValue: 'all',
    hashParam: 'accountType',
  });

  const states = useMemo(() => {
    const statesAux = () => {
      let aux = getOptions('us_states', 'all', t('All States/Provinces'));
      aux = [...addCountry(aux, 'US'), ...addCountry(getOptions('ca_provinces'), 'CA')];
      return aux;
    };
    return statesAux();
  }, [t]);

  const statesFilter = useListSelectFilter({
    label: t('State'),
    options: states,
    defaultValue: 'all',
    hashParam: 'states',
  });

  const filterGroup = useMemo(
    () => new FilterGroup([accountTypeFilter.filter, statesFilter.filter]),
    [accountTypeFilter.filter, statesFilter.filter],
  );
  const relationship = permissions.location?.relationship || '';
  let idHighHierarchy = relationship.slice(0, 7);
  let hierarchy = relationship.slice(0, relationship.indexOf(params.selectedAccount || '') + 7);

  const ds = useMaestroDataSource({
    API: {
      method: 'GET',
      endpoint: `cp-be/locations/${idHighHierarchy}/list`,
      baseURL,
    },
    reqOptions: {
      filters: {
        hierarchy: hierarchy,
        accountId: params.selectedAccount,
        accountType: accountTypeFilter.value === 'all' ? '' : accountTypeFilter.value,
        state: statesFilter.value === 'all' ? '' : statesFilter.value?.toLowerCase(),
      },
    },
    componentPlacements: {
      searchPlacement: cellView ? 'outside' : 'embeded',
    },
    filterGroup,
    cacheKey: `locations-${permissions.location?.customer_number || ''}`,
  });

  /* ******************** */
  /* Render Cell Methods  */
  /* ******************** */

  function RenderName({ row }: { row: LocationOVM }) {
    return (
      <>
        {readPermission ? (
          <Link
            onClick={() => history.push(`locationDetails/${row.hash_key}/general-info/view`)}
            style={{ cursor: 'pointer' }}
          >
            {row.customer_name || t('N/A')}
          </Link>
        ) : (
          row.customer_name || t('N/A')
        )}
        <br />
        {t('Location Id')}
        {': '}
        {row.extrnl_cust_id ? <b>{row.extrnl_cust_id}</b> : t('N/A')}
      </>
    );
  }

  function RenderAddress({ row }: { row: LocationOVM }) {
    return (
      <>
        {row.customer_addr1 ? <div>{row.customer_addr1}</div> : null}
        {row.customer_addr2 ? <div>{row.customer_addr2}</div> : null}
        {`${row.customer_city}, ${row.customer_state} ${row.customer_zip}`}
      </>
    );
  }

  function RenderAccountInfo({ row }: { row: LocationOVM }) {
    const accounts: string[] = [];
    if (row.parent_company_number) {
      accounts.push(`${t('PC')} ${row.parent_company_number}`);
    }
    if (row.home_office_number) {
      accounts.push(`${t('HO')} ${row.home_office_number}`);
    }
    if (row.bill_to_customer) {
      accounts.push(`${t('BT')} ${row.bill_to_customer}`);
    }
    if (row.ship_to_customer) {
      accounts.push(`${t('ST')} ${row.ship_to_customer}`);
    }
    return (
      <ul style={{ padding: 0, fontSize: '0.9em', marginLeft: '0.8rem' }}>
        {accounts.map((s: string, i: number) => {
          if (i < accounts.length - 1) {
            return <li key={`renderAI-${s}`}>{s}</li>;
          }
          return (
            <li key={`renderAI-${s}`}>
              <b>{s}</b>
              <Star style={{ height: 12 }} />
            </li>
          );
        })}
      </ul>
    );
  }

  useEffect(
    () => () => {
      clearMaestroDataSourceCache(`locations-${permissions.location?.customer_number || ''}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /* ********** */
  /* Render     */
  /* ********** */
  return (
    <Panel spacing={0}>
      <Dialog
        maxWidth="xl"
        open={dialogOpen}
        onClose={() => {
          setState({ ...state, dialogOpen: false });
        }}
      >
        <DialogContent>
          <ContactsTable popupMode specificCustomer={locationCustomer} />
        </DialogContent>
      </Dialog>
      <DataGrid
        id="locationsTable"
        style={{ height: CONTENT_AREA_HEIGHT }}
        noDataText={t('No data found...')}
        scrolling={{ mode: 'virtual', rowRenderingMode: 'virtual' }}
        dataSource={ds.dataSource}
        allowColumnResizing
        wordWrapEnabled
        remoteOperations
        columnAutoWidth
        showBorders
        onToolbarPreparing={ds.onToolbarPreparing}
        className="freespaced-table"
      >
        <Template name="filterFields" render={ds.renderFilterFields} />
        <Template name="resultsCounter" render={ds.renderResultsCounter} />
        <SearchPanel visible highlightSearchText={false} placeholder={t('Search...')} />

        <Column
          caption={t('Level')}
          dataType="string"
          dataField="level"
          allowSorting
          minWidth={100}
          alignment="center"
          cellRender={({ row }) => (
            <CreatedByAvatar customerType={row.data.customer_type} customerNumber={row.data.customer_number} />
          )}
        />
        <Column
          caption={t('Account / Location')}
          dataType="string"
          dataField="customer_name"
          minWidth={200}
          allowSorting
          cellRender={({ row }) => <RenderName row={row.data as LocationOVM} />}
        />

        <Column
          caption={t('Account Address')}
          dataType="string"
          dataField="customer_addr1"
          minWidth={200}
          cellRender={({ row }) => <RenderAddress row={row.data as LocationOVM} />}
          allowSorting={false}
        />

        <Column
          caption={t('Location Contacts')}
          dataType="array"
          dataField="contacts"
          minWidth={180}
          alignment="center"
          cellRender={({ row }) => (
            <div
              className={classes.avatarDiv}
              key={row.data.hash_key}
              onClick={() => {
                setState({
                  ...state,
                  dialogOpen: true,
                  locationCustomer: row.data,
                });
              }}
            >
              <ValueAvatar
                color="primary"
                icon={<Contacts />}
                value={row.data.numberOfContacts ?? 0}
                tooltip={t('List of contacts')}
              />
            </div>
          )}
          allowSorting={false}
        />
        <Column
          caption={t('Account Info')}
          dataType="string"
          dataField="customer_name"
          minWidth={150}
          allowSorting={false}
          cellRender={({ row }) => <RenderAccountInfo row={row.data as LocationOVM} />}
        />
      </DataGrid>
    </Panel>
  );
}
