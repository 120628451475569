/* eslint-disable react/no-danger */

/* eslint-disable max-len */
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import { Tooltip } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { ThemeType } from '@michelin/core-profile-ui-tools/build/types';
import { getOptionLabel } from '@michelin/select-options-provider';

const VALIDATION_ERROR_COLOR = 'rgb(241, 111, 111)';

const useValidationTooltipStyles = makeStyles((theme: ThemeType) => ({
  tooltip: {
    backgroundColor: VALIDATION_ERROR_COLOR,
    maxWidth: '500px',
    color: theme.palette.primary.contrastText,
  },
  tooltipArrow: { color: VALIDATION_ERROR_COLOR },
}));

interface ValidatedItemsProps {
  value: string;
  axleValue?: string;
  type: string;
  validOptions: string[];
}

export default function ValidatedItems(props: ValidatedItemsProps) {
  const { t } = useTranslation();
  const divStyle: CSSProperties = {
    color: VALIDATION_ERROR_COLOR,
    marginBottom: 4,
    marginTop: 4,
  };

  const titleStyle: CSSProperties = {
    margin: 8,
    fontSize: '1.4em',
    lineHeight: '1.3em',
  };

  const classes = useValidationTooltipStyles();

  let title = '';
  let options = '';
  const list: {
    key: string;
    text: string;
    subItems?: { key: string; text: string }[];
  }[] = [];

  const step = 'inflation pressure';

  switch (props.type) {
    case 'vehicle': {
      const field = 'Vehicle Type';

      options = props.validOptions.map((v) => getOptionLabel('vehicle_types_onsite', v)).join(', ');
      title = t(
        '<b>{{field}} = {{value}}</b> is not an available {{fieldLowerCase}} option for the Onsite Tire Profile(s) linked to this SOP Profile. Please correct this by one of the following methods:',
        { field, fieldLowerCase: field.toLowerCase(), value: props.value },
      );
      list.push({
        key: 'a1',
        text: t(
          '<b>Update the {{field}} in this {{step}} record</b> to match one available in the linked tire profile(s)',
          { field, step },
        ),
      });
      list.push({
        key: 'a2',
        text: t('Update the linked Onsite Tire Profile(s) by <b>setting Vehicle Type = {{options}}</b>', { options }),
      });
      list.push({ key: 'a3', text: t('<b>Delete this {{step}}</b> record from this list', { step }) });
      break;
    }
    case 'application': {
      const field = t('Application Type');

      options = props.validOptions.map((v) => getOptionLabel('application_types', v)).join(', ');
      title = t(
        '<b>{{field}} = {{value}}</b> is not an available {{fieldLowerCase}} option for the Onsite Tire Profile(s) linked to this SOP Profile. Please correct this by one of the following methods:',
        { field, fieldLowerCase: field.toLowerCase(), value: props.value },
      );
      list.push({
        key: 'a1',
        text: t(
          '<b>Update the {{field}} in this {{step}} record</b> to match one available in the linked tire profile(s)',
          { field, step },
        ),
      });
      list.push({
        key: 'a2',
        text: t('Update the linked Onsite Tire Profile(s) by <b>setting Application Type = {{options}}</b>', {
          options,
        }),
      });
      list.push({ key: 'a3', text: t('<b>Delete this {{step}}</b> record from this list', { step }) });
      break;
    }
    case 'axle': {
      const field = t('Axle Position');

      options = props.validOptions.map((v) => getOptionLabel('axle_types', v)).join(', ');
      title = t(
        '<b>{{field}} = {{value}}</b> is not an available {{fieldLowerCase}} option for the Onsite Tire Profile(s) linked to this SOP Profile. Please correct this by one of the following methods:',
        { field, fieldLowerCase: field.toLowerCase(), value: props.value },
      );
      list.push({
        key: 'a1',
        text: t(
          '<b>Update the {{field}} in this {{step}} record</b> to a {{fieldLowerCase}} specified and available in the linked onsite tire profile(s)',
          { field, fieldLowerCase: field.toLowerCase(), step },
        ),
      });
      list.push({
        key: 'a2',
        text: t('<b>Update</b> the linked Onsite Tire Profile directly, by following these {{stepsCount}} steps:', {
          stepsCount: t('three'),
        }),
        subItems: [
          {
            key: 's1',
            text: t('<b>Assign the</b> {{fieldLowerCase}} <b>{{value}}</b> to the tire profile', {
              value: props.value,
              fieldLowerCase: field.toLowerCase(),
            }),
          },
          {
            key: 's2',
            text: t(
              'From the newly assigned {{fieldLowerCase}}, add the tire size shown in this record to the Tire Replacement Priority',
              { fieldLowerCase: field.toLowerCase() },
            ),
          },
        ],
      });
      list.push({ key: 'a3', text: t('<b>Delete this {{step}}</b> record from this list', { step }) });
      break;
    }
    case 'tire': {
      const field = t('Tire Size');

      title = t(
        '<b>{{field}} = {{value}}</b> is not an available {{fieldLowerCase}} option for the Onsite Tire Profile(s) linked to this SOP Profile. Please correct this by one of the following methods:',
        { field, fieldLowerCase: field.toLowerCase(), value: props.value },
      );
      list.push({
        key: 'a1',
        text: t(
          '<b>Update the {{field}} in this {{step}} record</b> to a {{fieldLowerCase}} specified and available in the linked onsite tire profile(s)',
          { field, fieldLowerCase: field.toLowerCase(), step },
        ),
      });

      list.push({
        key: 'a2',
        text: t('<b>Update</b> the linked Onsite Tire Profile directly, by following these {{stepsCount}} steps:', {
          stepsCount: t('three'),
        }),
        subItems: [
          {
            key: 's1',
            text: t(
              'In the Tires module, <b>select the Onsite Tire Profile</b> linked to this SOP profile and switch to edit mode by clicking',
            ),
          },
          {
            key: 's2',
            text: t(
              '<b>Assign the axle position {{axleField}} to the tire profile</b> if it is not currently assigned',
            ),
          },
          {
            key: 's3',
            text: t(
              'From the {{axleField}} axle tab, <b>add the tire size</b> shown in this record to the Tire Replacement Priority and save changes',
              { axleField: props.axleValue || '' },
            ),
          },
        ],
      });
      list.push({ key: 'a3', text: t('<b>Delete this {{step}}</b> record from this list', { step }) });

      break;
    }
    default:
      break;
  }

  return (
    <div style={divStyle}>
      <Tooltip
        interactive
        arrow
        placement="top"
        classes={{
          tooltip: classes.tooltip,
          arrow: classes.tooltipArrow,
        }}
        title={
          <div style={titleStyle}>
            <div dangerouslySetInnerHTML={{ __html: title }} />
            <ul>
              {list.map((item) => (
                <li key={`list_${item.key}`}>
                  <div dangerouslySetInnerHTML={{ __html: item.text }} />
                  <ol>
                    {item.subItems &&
                      item.subItems.map((subItem) => (
                        <li key={`list_${subItem.key}`}>
                          <div dangerouslySetInnerHTML={{ __html: subItem.text }} />
                        </li>
                      ))}
                  </ol>
                </li>
              ))}
            </ul>
          </div>
        }
      >
        <span style={{ fontWeight: 'bold' }}>{` ${props.value}`}</span>
      </Tooltip>
    </div>
  );
}
