import React from 'react';
import { Grid } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { Button, Panel } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { Contact, ContactType } from 'components/Contact/utils';
import uuid from 'uuid';
import { MECH_SERVICE_VALUE, TIRE_SERVICE_VALUE } from '../utils';
import Selector, { ChangeEvent as SelectorChangeEvent } from './Selector';
import { CustomTooltip } from './tables/utils';
import { parseDaysAndLocation } from './utils';

interface ContactTypesProps {
  contact: Contact;
  disablePriorityLevels?: boolean;
  viewMode: boolean;
  onContactTypeChange: (event: SelectorChangeEvent) => void;
  onPriorityLevelsClick: () => void;
}

export default function ContactTypes(props: ContactTypesProps): JSX.Element {
  const { contact, disablePriorityLevels, viewMode, onContactTypeChange, onPriorityLevelsClick } = props;

  const { t } = useTranslation();
  const { contact_types } = contact;
  const { daysOfTheWeekSelected, startEndTimeSelected } = parseDaysAndLocation(contact);

  // Start Empty contact type.
  const tireContactType: ContactType = {
    id: uuid.v4(),
    service: TIRE_SERVICE_VALUE,
  };
  const mechanicalContactType: ContactType = {
    id: uuid.v4(),
    service: MECH_SERVICE_VALUE,
  };

  if (contact_types) {
    // Initialice contact types
    const alreadyAddedTireContactType = contact_types.find((x) => x.service === TIRE_SERVICE_VALUE);
    const alreadyAddedMechContactType = contact_types.find((x) => x.service === MECH_SERVICE_VALUE);

    if (alreadyAddedTireContactType) {
      tireContactType.id = alreadyAddedTireContactType.id;
      tireContactType.role_areas = alreadyAddedTireContactType.role_areas;
      tireContactType.service = alreadyAddedTireContactType.service;
    }
    if (alreadyAddedMechContactType) {
      mechanicalContactType.id = alreadyAddedMechContactType.id;
      mechanicalContactType.role_areas = alreadyAddedMechContactType.role_areas;
      mechanicalContactType.service = alreadyAddedMechContactType.service;
    }
  }

  const validToAssignContactTypes = daysOfTheWeekSelected && startEndTimeSelected;
  const validToAddPriorityLevels = contact_types && contact_types.some((x) => x.role_areas && x.role_areas.length > 0);
  let selectorWarning: JSX.Element | undefined;

  if (!validToAssignContactTypes) {
    selectorWarning = (
      <CustomTooltip
        interactive
        arrow
        title={<p>{t("The contact's work schedule above must be updated before you can manage Contact Types.")}</p>}
      >
        <Warning style={{ margin: 4, verticalAlign: 'middle' }} />
      </CustomTooltip>
    );
  }

  let priorityLevelsControl = (
    <Button
      disabled={disablePriorityLevels || !validToAddPriorityLevels}
      onClick={onPriorityLevelsClick}
      size="small"
      style={{ margin: 4, marginRight: 0 }}
    >
      {t('Priority Levels')}
    </Button>
  );

  if (!validToAddPriorityLevels) {
    priorityLevelsControl = (
      <CustomTooltip
        interactive
        arrow
        title={
          <p>
            {t(
              'At least one contact type must be added prior to viewing or managing priority levels for this contact.',
            )}
          </p>
        }
      >
        <span>{priorityLevelsControl}</span>
      </CustomTooltip>
    );
  }
  return (
    <>
      <Grid item container>
        <Panel
          title={t('Contact Types')}
          control={
            <>
              {selectorWarning}
              {priorityLevelsControl}
            </>
          }
          spacing={2}
        >
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Panel title={t('Tire Contact Types')} spacing={2}>
                <Selector
                  style={{ padding: 8 }}
                  id="tireSelector"
                  contact_type={tireContactType}
                  viewMode={viewMode || !validToAssignContactTypes}
                  onChange={onContactTypeChange}
                />
              </Panel>
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <Panel title={t('Mechanical Contact Types')} spacing={2}>
                <Selector
                  style={{ padding: 8 }}
                  id="mechanicalSelector"
                  contact_type={mechanicalContactType}
                  viewMode={viewMode || !validToAssignContactTypes}
                  onChange={onContactTypeChange}
                />
              </Panel>
            </Grid> */}
          </Grid>
        </Panel>
      </Grid>
    </>
  );
}
