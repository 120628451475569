/* eslint-disable max-len */

/* eslint-disable no-prototype-builtins */

/* eslint-disable no-param-reassign */
import { MaestroAPI, maestroApiCall } from '@michelin/central-provider';
import { MethodType } from '@michelin/central-provider/dist/helpers/createAPICall';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SOPInflation, SOPProfile, SOPPullPoint, SOPTireProfile } from 'components/SOPs/Types';
import GLOBAL_CONFIG from '../global-variables';

const baseURL = GLOBAL_CONFIG.externalAPIUrl;

interface SOPProfileEndpoint {
  profile: SOPProfile;
  tire_profiles: string[];
}
interface SOPCreateParams {
  fleetId: string | undefined;
  data: SOPProfile;
}

interface SOPUpdateParams {
  fleetId: string | undefined;
  profileId: string | undefined;
  data: SOPProfile;
}

const messageFormating = (message: string) => {
  switch (message) {
    case 'At least one tire profile relationship is needed':
      return 'At least one tire profile relationship is needed, or the one selected is not are your level.';
    default:
      return '';
  }
};

const cleanData = (data: any): SOPProfileEndpoint => {
  const newData: SOPProfileEndpoint = { profile: { ...data }, tire_profiles: data.tire_profiles || [] };

  // Remove the extra data in the entity.
  if (newData.profile.inflation) {
    const newInfaltions: SOPInflation[] = [];

    newData.profile.inflation.inflations.forEach((item) => {
      delete item.key;
      delete item.tire_profile_errors;
      delete item.type;
      if (item.comments === '') delete item.comments;

      item.axle_types?.forEach((axle: string) => {
        item.application_types?.forEach((application: string) => {
          item.vehicle_types?.forEach((vehicle: string) => {
            newInfaltions.push({
              ...item,
              axle_types: undefined,
              application_types: undefined,
              vehicle_types: undefined,
              axle_type: axle,
              application_type: application,
              vehicle_type: vehicle,
            });
          });
        });
      });
    });

    newData.profile.inflation.inflations = newInfaltions;
  }

  if (newData.profile.pull_point) {
    const newPullPoints: SOPPullPoint[] = [];

    newData.profile.pull_point.pull_points.forEach((item) => {
      delete item.key;
      delete item.tire_profile_errors;
      delete item.type;
      if (item.comments === '') delete item.comments;

      item.axle_types?.forEach((axle: string) => {
        item.application_types?.forEach((application: string) => {
          item.vehicle_types?.forEach((vehicle: string) => {
            newPullPoints.push({
              ...item,
              axle_types: undefined,
              application_types: undefined,
              vehicle_types: undefined,
              axle_type: axle,
              application_type: application,
              vehicle_type: vehicle,
            });
          });
        });
      });
    });
    newData.profile.pull_point.pull_points = newPullPoints;
  }

  if (newData.profile.wheel_balance) {
    newData.profile.wheel_balance.wheel_balances.forEach((item) => {
      delete item.key;
      delete item.application_types;
      delete item.vehicle_types;
      delete item.axle_types;
      delete item.tire_profile_errors;
      delete item.type;
      if (item.comments === '') delete item.comments;
    });
  }

  if (newData.profile.brands) {
    newData.profile.brands.items.forEach((item) => {
      delete item.key;
    });
  }

  delete newData.profile.tire_profiles;

  return newData;
};

const groupData = (data: any): SOPProfile => {
  const newData: SOPProfile = { ...data };

  const newInflation: SOPProfile['inflation'] = {
    inflations: [],
  } as SOPProfile['inflation'];
  const newPullPoint: SOPProfile['pull_point'] = {
    pull_points: [],
  } as SOPProfile['pull_point'];

  newData.inflation?.inflations?.forEach((item: any) => {
    const inflation = newInflation.inflations?.find((i) => i.size === item.size);
    const newId =
      (newData.inflation.inflations.map((v: any) => v.id).filter(Boolean).length === 0
        ? 0
        : Math.max(...newData.inflation?.inflations.map((v: any) => v.id))) + 1;
    if (inflation) {
      if (!inflation.axle_types?.find((i) => i === item.axle_type)) inflation.axle_types?.push(item.axle_type);
      if (!inflation.application_types?.find((i) => i === item.application_type))
        inflation.application_types?.push(item.application_type);
      if (!inflation.vehicle_types?.find((i) => i === item.vehicle_type))
        inflation.vehicle_types?.push(item.vehicle_type);
    } else {
      newInflation.inflations.push({
        ...item,
        id: item.id ? item.id : newId,
        application_type: null,
        vehicle_type: null,
        axle_type: null,
        axle_types: [item.axle_type],
        application_types: [item.application_type],
        vehicle_types: [item.vehicle_type],
        type: 'inflation',
      });
    }
  });

  newData.pull_point?.pull_points?.forEach((item: any) => {
    const pullPoint = newPullPoint.pull_points?.find((i) => i.size === item.size);
    const newId =
      (newData.inflation.inflations.map((v: any) => v.id).filter(Boolean).length === 0
        ? 0
        : Math.max(...newData.inflation?.inflations.map((v: any) => v.id))) + 1;
    if (pullPoint) {
      if (!pullPoint.axle_types?.find((i) => i === item.axle_type)) pullPoint.axle_types?.push(item.axle_type);
      if (!pullPoint.application_types?.find((i) => i === item.application_type))
        pullPoint.application_types?.push(item.application_type);
      if (!pullPoint.vehicle_types?.find((i) => i === item.vehicle_type))
        pullPoint.vehicle_types?.push(item.vehicle_type);
    } else {
      newPullPoint.pull_points.push({
        ...item,
        id: item.id ? item.id : newId,
        application_type: null,
        vehicle_type: null,
        axle_type: null,
        axle_types: [item.axle_type],
        application_types: [item.application_type],
        vehicle_types: [item.vehicle_type],
        type: 'pull_point',
      });
    }
  });

  newData.wheel_balance?.wheel_balances?.forEach((item: any) => {
    const newId =
      (newData.inflation.inflations.map((v: any) => v.id).filter(Boolean).length === 0
        ? 0
        : Math.max(...newData.inflation?.inflations.map((v: any) => v.id))) + 1;
    item.type = 'wheel';
    item.id = item.id ? item.id : newId;
  });

  newData.brands?.items?.forEach((item: any) => {
    const newId =
      (newData.inflation.inflations.map((v: any) => v.id).filter(Boolean).length === 0
        ? 0
        : Math.max(...newData.inflation?.inflations.map((v: any) => v.id))) + 1;
    item.id = item.id ? item.id : newId;
  });

  newData.inflation = newInflation;
  newData.pull_point = newPullPoint;

  return newData;
};

export const createSOP = async ({ fleetId, data }: SOPCreateParams): Promise<SOPProfile | null> => {
  if (!fleetId || !data) throw new Error('Invalid params');

  const apiType: MaestroAPI = { method: 'POST', endpoint: `cp-be/sop/${fleetId}`, baseURL };
  const newData: SOPProfileEndpoint = cleanData(data);
  const res = await maestroApiCall(apiType, newData);
  return groupData(res);
};

export const updateSOP = async ({
  fleetId,
  profileId,
  data,
}: SOPUpdateParams): Promise<{ profile: SOPProfile; message: string; saved: boolean }> => {
  if (!fleetId || !profileId || !data) throw new Error('Invalid params');
  const apiType: MaestroAPI = { method: 'PUT', endpoint: `cp-be/sop/${fleetId}/${profileId}`, baseURL };
  const newData: SOPProfileEndpoint = cleanData(data);
  const res = await maestroApiCall(apiType, newData);

  if (!res.saved) throw Error(messageFormating(res.message));
  res.profile = groupData(res.profile);
  return res;
};

export function useCreateSOP(fleetId: string) {
  const queryClient = useQueryClient();
  return useMutation(createSOP, {
    onSuccess: () => {
      queryClient.invalidateQueries(['sop', fleetId]);
    },
  });
}

export function useUpdateSOP(fleetId: string, profileId: string) {
  const queryClient = useQueryClient();
  return useMutation(updateSOP, {
    onSuccess: () => {
      queryClient.invalidateQueries(['sop-profile', profileId, fleetId]);
    },
  });
}

export const getSOP = async (
  fleetId: string | undefined,
  profileId: string | undefined,
): Promise<SOPProfile | null> => {
  if (!fleetId || !profileId) return null;
  const apiType: MaestroAPI = { method: MethodType.GET, endpoint: `cp-be/sop/${fleetId}/${profileId}`, baseURL };
  const response = await maestroApiCall(apiType);
  return groupData(response);
};

export function useSOP({ fleetId, profileId }: { fleetId: string | undefined; profileId: string | undefined }) {
  return useQuery(['sop-profile', profileId, fleetId], () => getSOP(fleetId, profileId));
}

export type SOPTireProfilesSelectList = {
  id: string;
  profile_name: string;
  application_type: string;
  vehicle_type: string;
  locations: any[];
  assign_to_all_locations: boolean;
  disabled: boolean;
};

function getTireProfiles({ fleetId, profileId, query }: { fleetId: string; profileId?: string; query?: string }) {
  let apiType: MaestroAPI = { method: MethodType.GET, endpoint: `cp-be/sop/${fleetId}/tire-profiles`, baseURL };
  if (profileId)
    apiType = { method: MethodType.GET, endpoint: `cp-be/sop/${fleetId}/tire-profiles/${profileId}`, baseURL };
  return maestroApiCall(apiType, { query }).then((res) => res);
}

export function useGetTireProfiles({
  fleetId,
  profileId,
  query,
}: {
  fleetId: string;
  profileId?: string;
  query?: string;
}) {
  return useQuery(['get-tire-profiles-sop', fleetId, profileId, query], () =>
    getTireProfiles({ fleetId, profileId, query }),
  );
}

export const getAssociateTireProfiles = async (
  fleetId: string | undefined,
  tireProfiles: string[],
): Promise<SOPTireProfile[] | null> => {
  if (!fleetId || tireProfiles.length === 0) return null;
  const apiType: MaestroAPI = {
    method: MethodType.GET,
    endpoint: `cp-be/tires/${fleetId}/list-attributes?tire_profiles=${tireProfiles?.join(',')}`,
    baseURL,
  };
  const response = await maestroApiCall(apiType);
  return response;
};

// --------------------------------------------------
// SOP PROCEDURES
// --------------------------------------------------

interface SOPProceduresData {
  inspection_requirements: string;
  service_installation_notes: string;
  pickup_and_delivery: string;
  proof_of_delivery: string;
  scrap_only: string;
  scrap_pending: string;
  adjustment_notes: string;
  wheel_instructions: string;
  additional_instructions: string;
}

interface SOPProceduresUpdate {
  fleetId: string | undefined;
  data: SOPProceduresData;
}

const getSOPProcedures = async (fleetId: string | undefined): Promise<SOPProceduresData | null> => {
  if (!fleetId) return null;
  const apiType: MaestroAPI = { method: MethodType.GET, endpoint: `cp-be/sop/${fleetId}/procedures`, baseURL };
  return maestroApiCall(apiType);
};

function useSOPProcedures({ fleetId }: { fleetId: string | undefined }) {
  return useQuery(['sop-procedures', fleetId], () => getSOPProcedures(fleetId));
}

const updateSOPProcedures = async ({ fleetId, data }: SOPProceduresUpdate): Promise<SOPProceduresData | null> => {
  if (!fleetId || !data) return null;
  const apiType: MaestroAPI = { method: 'PUT', endpoint: `cp-be/sop/${fleetId}/procedures`, baseURL };
  return maestroApiCall(apiType, data);
};

function useUpdateSOPProcedures({ fleetId }: { fleetId: string | undefined }) {
  const queryClient = useQueryClient();
  return useMutation(updateSOPProcedures, {
    onSuccess: () => {
      queryClient.invalidateQueries(['sop-procedures', fleetId]);
    },
  });
}

export { useSOPProcedures, useUpdateSOPProcedures };

export type { SOPProceduresData };
