export function isValidEmail(email: string | null | undefined): boolean {
  return email
    ? /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        String(email).toLowerCase(),
      )
    : true;
}

export function isValidPhoneNumber(phoneNumber: string | null | undefined): boolean {
  return phoneNumber ? /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(phoneNumber) : true;
}

export function isRequired(field: any): boolean {
  return !!field;
}

export function isValidDollarAmount(amount: string): boolean {
  return amount ? /^\$?[0-9]+\.?[0-9]?[0-9]?$/.test(amount) : true;
}

export function isValidPositiveInt(value: string): boolean {
  return value ? /^\+?(0|[1-9]\d*)$/.test(value) : true;
}

export function isValidZeroPositiveInt(value: string): boolean {
  return value ? /^\+?([0-9]\d*)$/.test(value) : true;
}
