import React from 'react';
import { Grid, Link, Popover, Theme, Typography, makeStyles } from '@material-ui/core';
import { List } from '@material-ui/icons';
import { TireProfileOVM } from '@michelin/fcp-view-models';
import { getOptionLabel } from '@michelin/select-options-provider';
import { TranslateFunction } from 'components/ChangeLog/Format';
import CreatedByAvatar from 'components/CreatedByAvatar';
import { getBaseUrl } from 'prefs-and-service-offers';
import { useHistory } from 'react-router-dom';

export interface TireProfileRow {
  data: TireProfileOVM;
}

const useStyles = makeStyles((theme: Theme) => ({
  tireSizeSummaryContainer: {
    borderRadius: '5px',
  },
  tireSizeSummaryHeader: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
  },
  tireSizeSummaryDetailContainer: {
    padding: '5px',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  margin: {
    margin: 2,
  },
  filterControl: {
    width: 200,
    marginRight: 7,
  },
  noPointer: {
    cursor: 'default',
  },
}));

export const ProfileNameCell = ({ row }: { row: TireProfileRow }) => {
  const history = useHistory();
  if (!row || !row.data || !row.data.main?.profile_name || !row.data.id) return <></>;

  return (
    <>
      <Link
        onClick={() => history.push(`${getBaseUrl()}/tire-profile/${row.data.id}/view`)}
        style={{ cursor: 'pointer' }}
      >
        {row.data.main.profile_name}
      </Link>
    </>
  );
};

export const CreatedByCell = (row: TireProfileRow) => {
  if (!row || !row.data || !row.data.owner) return null;
  return (
    <CreatedByAvatar customerType={row.data.owner.customer_type} customerNumber={row.data.owner.customer_number} />
  );
};

export const AxlesCell = ({ row, t }: { row: TireProfileRow; t: TranslateFunction }) => {
  if (!row || !row.data || !row.data.axles) return <></>;

  const axleArray: string[] = [];
  Object.entries(row.data.axles).forEach(([key, value]) => {
    if (value && key !== '__typename') axleArray.push(key.charAt(0).toUpperCase() + key.slice(1));
  });

  return (
    <div>
      {axleArray.sort().map((axle: string) => {
        if (axle.toLowerCase() === 'all_axles') return <div key={axle}>{t('All')}</div>;
        return <div key={axle}>{getOptionLabel('axle_types', axle.toLowerCase())}</div>;
      })}
    </div>
  );
};

const TireSizes = ({ axlesDetail, t }: { axlesDetail: any; t: TranslateFunction }) => {
  const classes = useStyles();

  if (!axlesDetail) return <></>;

  const axleArray: any = Object.keys(axlesDetail);
  const typeNameIndex: number = axleArray.indexOf('__typename');
  if (typeNameIndex > -1) axleArray.splice(typeNameIndex, 1);

  return (
    <Grid container className={classes.tireSizeSummaryContainer}>
      <Grid item xs={12} lg={12}>
        {axleArray.sort().map((item: any, key: any) => {
          if (
            !axlesDetail[item] ||
            !axlesDetail[item].tire_replacement_priority ||
            axlesDetail[item].tire_replacement_priority.length === 0
          )
            return <div key={key} />;

          const axleName =
            item.toLowerCase() === 'all_axles' ? t('All') : getOptionLabel('axle_types', item.toLowerCase());

          return (
            <Grid container key={key} className={classes.tireSizeSummaryDetailContainer}>
              <Grid item md={12} style={{ marginTop: '7px' }}>
                <Typography variant="body2" style={{ fontWeight: 700 }}>
                  {axleName}
                </Typography>

                <Grid container style={{ borderBottom: '1px solid #777', paddingLeft: 10 }}>
                  <Grid item md={2}>
                    <Typography variant="body2" style={{ fontWeight: 700 }}>
                      {t('Priority')}
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Typography variant="body2" style={{ fontWeight: 700 }}>
                      {t('Type')}
                    </Typography>
                  </Grid>
                  <Grid item md={4}>
                    <Typography variant="body2" style={{ fontWeight: 700 }}>
                      {t('Brand')}
                    </Typography>
                  </Grid>
                  <Grid item md={4}>
                    <Typography variant="body2" style={{ fontWeight: 700 }}>
                      {t('Size')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12}>
                {axlesDetail[item] &&
                  axlesDetail[item].tire_replacement_priority.map((item2: any, key: any) => (
                    <Grid container style={{ paddingLeft: 10 }} key={key}>
                      <Grid item md={2}>
                        <Typography variant="body2">{item2.priority}</Typography>
                      </Grid>
                      <Grid item md={2}>
                        <Typography variant="body2">{item2.tire_type}</Typography>
                      </Grid>
                      <Grid item md={4}>
                        <Typography variant="body2">{item2.brand}</Typography>
                      </Grid>
                      <Grid item md={4}>
                        <Typography variant="body2">{item2.tire_size}</Typography>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export const TireSizesCell = ({ row, t }: { row: TireProfileRow; t: TranslateFunction }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  if (!row || !row.data) return <></>;

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {row.data.axles && (
        <Link
          aria-label="tire-sizes"
          className={classes.margin}
          style={{ cursor: 'pointer', textDecoration: 'none' }}
          color="primary"
          onClick={(event: any) => handlePopoverOpen(event)}
          onMouseLeave={handlePopoverClose}
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
        >
          <List />
          <Popover
            id="mouse-over-popover"
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <TireSizes axlesDetail={row.data.axles} t={t} />
          </Popover>
        </Link>
      )}
    </>
  );
};

export const OptionCell = ({ option, value }: any) => {
  return <span>{getOptionLabel(option, value)}</span>;
};

export default TireSizes;
