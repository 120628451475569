export interface IAssigneeCustomerType {
  assigned?: string;
  treeId?: string;
  treeParentId?: string | null;
  hash_key: string;
  range_key: string;
  customer_addr1: string;
  customer_addr2: string;
  customer_city: string;
  customer_name: string;
  customer_number: string;
  customer_state: string;
  customer_type: string;
  customer_zip: string;
  extrnl_cust_id: string;
  relationship: string;
  ship_to_customer: string;
  bill_to_customer: string;
  home_office_number: string;
  parent_company_number: string;
}

export interface IAssigneeTireProfileType {
  hash_key: string;
  range_key: string;
}

export interface IAssigneeType {
  hash_key: string;
  range_key: string;
  gsi1_hash_key: string;
  gsi1_range_key: string;
  is_deleted: boolean;
}

export const assigneeCustomerType: string = `
customer {
  hash_key
  range_key
  customer_addr1
  customer_addr2
  customer_city
  customer_country
  customer_name
  customer_number
  customer_state
  customer_type
  customer_zip
  extrnl_cust_id
  parent_company_number
  home_office_number
  bill_to_customer
  ship_to_customer
  relationship
}
`;

export const assigneeType: string = `
hash_key
range_key
gsi1_hash_key
gsi1_range_key
is_deleted
`;

// ${assigneeCustomerType}

// tire_profile {
//   ${tireProfile}
// }
