import React from 'react';
import { Container } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import LastModified from 'components/ChangeLog/LastModified';
import { PageTitle } from 'components/PageTitle/PageTitle';
import WheelsTable from './Tables/WheelsTable';

export function WheelsModule() {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle title={t('Wheel List')} rightItems={<LastModified entity="wheel_profile" />} />
      <Container>
        <WheelsTable action="add" serviceOfferIds="" />
      </Container>
    </>
  );
}
