export function byVal(inVal: any) {
  return JSON.parse(JSON.stringify(inVal));
}

export function removeEmptyAttributes(obj: any) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') removeEmptyAttributes(obj[key]); // recurse
    // eslint-disable-next-line no-param-reassign
    else if (obj[key] === undefined || obj[key] === null || obj[key] === '') delete obj[key]; // delete
  });
}

export function removeKeys(obj: any, keys: string[]) {
  let index;
  for (const prop in obj) {
    // check that this is objects own property
    // not from prototype prop inherited
    if (obj.hasOwnProperty(prop)) {
      switch (typeof obj[prop]) {
        case 'string':
          index = keys.indexOf(prop);
          if (index > -1) {
            delete obj[prop];
          }
          break;
        case 'object':
          index = keys.indexOf(prop);
          if (index > -1) {
            delete obj[prop];
          } else {
            removeKeys(obj[prop], keys);
          }
          break;
        default:
          break;
      }
    }
  }
}

const values: string[] = [];
export const findValuesByKey = (obj: any, keys: string[]): string[] => {
  findValuesByKeyRecursive(obj, keys);
  return values.filter((a, b) => values.indexOf(a) === b);
};

export function findValuesByKeyRecursive(obj: any, keys: string[]) {
  let index;
  for (const prop in obj) {
    // check that this is objects own property
    // not from prototype prop inherited
    if (obj.hasOwnProperty(prop)) {
      switch (typeof obj[prop]) {
        case 'string':
          index = keys.indexOf(prop);
          if (index > -1) {
            values.push(obj[prop]);
          }
          break;
        case 'object':
          index = keys.indexOf(prop);
          if (index > -1) {
            values.push(obj[prop]);
          } else {
            findValuesByKeyRecursive(obj[prop], keys);
          }
          break;
        default:
          break;
      }
    }
  }
}

/**
 * Check to see if object alreadys exists in an array of like objects
 *
 * @param  {Array}  array: the array to check
 * @param  {Object} filters: the keys and values to check for
 * @return {boolean}
 */

export function objectExists(array: any[], filters: any): boolean {
  const keys: string[] = [];

  let compString: string = '';
  for (const [key, value] of Object.entries(filters)) {
    // console.log(`key[value]: ${key}[${value}]`)
    if (!value) return false; // not found - because you can have undefined values which will not exist
    keys.push(key);
    compString += `${key}${value}`;
    // console.log('compString: ' + compString)
  }
  let found: boolean = false;
  array.forEach((i1: any, k1: any) => {
    let compToString: string = '';
    keys.forEach((i2: any, k2: any) => {
      compToString += `${i2}${i1[i2]}`;
    });
    if (compToString === compString) found = true;
  });

  return found;
}
