import React, { CSSProperties, FunctionComponent, useEffect, useState } from 'react';
import { LoadingBounce } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { auth } from 'Auth';
import { Template } from 'devextreme-react';
import DataGrid, { Column, Pager, Paging, Scrolling, SearchPanel } from 'devextreme-react/data-grid';
import moment, { Moment } from 'moment';
import DatePicker from '../../DatePicker/DatePicker';
import { getChanges } from './UpdateLink';

interface Change {
  updated_field: string;
  old_value: string;
  new_value: string;
}

const datePickersDivStyle: CSSProperties = {
  width: '100%',
  display: 'flex',
  position: 'relative',
  flexWrap: 'wrap',
  minHeight: '100px',
  paddingBottom: '14.7px',
  justifyContent: 'space-around',
};

interface TableProps {
  id: string;
}

type Log = {
  changes: Change[];
  gsi1_range_key: string;
  hash_key: string;
  updated_by: string;
  updated_field: string;
};

type ChangeRecord = {
  id?: string;
  color?: string;
  fleet_id_no?: string;
  license_plate?: string;
  license_state_abbreviation?: string;
  type?: string;
  vin?: string;
  year?: string;
  make_name?: string;
  model_name?: string;
  option_name?: string;
  tire_size_name?: string;
  updatedBy?: string;
  updatedAt?: string;

  updated_field?: string;
  update_user?: string;
  update_date?: string;
  old_value?: string;
  new_value?: string;
};

const buildLogs = (response: Log[], t: Function) => {
  let ret: ChangeRecord[] = [];

  response.forEach((log) => {
    const localArray: ChangeRecord[] = [];
    let isCreate = false;
    if (log.changes.length === 0) {
      // removed
      ret.push({
        vin: log.hash_key.replace(/[^~]+~([^~]+)~[^~]+/, '$1'),
        updated_field: t('ALL'),
        old_value: t('None'),
        new_value: t('Vehicle Removed'),
        update_user: log.updated_by ? log.updated_by.replace(/(\|.+|")/g, '') : '',
        update_date: log.gsi1_range_key,
      });
    }

    log.changes.forEach((change) => {
      if (/createdAt/.test(change.updated_field)) isCreate = true;
      let changeRecord: ChangeRecord = {
        vin: log.hash_key.replace(/[^~]+~([^~]+)~[^~]+/, '$1'),
        update_user: log.updated_by ? log.updated_by.replace(/(\|.+|")/g, '') : '',
        update_date: log.gsi1_range_key,
      };
      Object.entries(change).forEach(([key, value]) => {
        changeRecord = {
          ...changeRecord,
          [key]: value,
        };
      });
      localArray.push(changeRecord);
    });

    if (isCreate) {
      ret.push({
        vin: log.hash_key.replace(/[^~]+~([^~]+)~[^~]+/, '$1'),
        updated_field: t('ALL'),
        old_value: t('None'),
        new_value: t('Vehicle Added'),
        update_user: log.updated_by ? log.updated_by.replace(/(\|.+|")/g, '') : '',
        update_date: log.gsi1_range_key,
      });
    } else {
      ret = ret.concat(localArray);
    }
  });
  return ret;
};

const Component: FunctionComponent<TableProps> = () => {
  const { t } = useTranslation();
  let minDate: Moment = moment();
  minDate = moment().subtract(30, 'days');
  const [loading, setLoading] = useState(true);
  const [rowz, setRowz] = useState<ChangeRecord[]>([]);
  const [changes, setChanges] = useState<ChangeRecord[]>([]);
  const [minSelectedDate, minStateChangeHandler] = useState(minDate);
  const [maxSelectedDate, maxStateChangeHandler] = useState(moment());

  const fieldNames: any = {
    id: t('ID'), // don't use
    color: t('Color'),
    assigned_location: t('Assigned Location'),
    fleet_id_no: t('Vehicle Unit Number'),
    license_plate: t('License Plate Number'),
    license_state_abbreviation: t('License Plate State'),
    type: t('Type'), // "urban" - don't use
    vin: t('VIN'),
    year: t('Year'),
    make_name: t('Make'),
    model_name: t('Model'),
    option_name: t('Option'),
    tire_size_name: t('Tire Size'),
    updatedBy: t('Updated By'),
    updatedAt: t('Updated At'),
  };

  const filterDates = (rows: ChangeRecord[], dateSelected: Moment, minChanged: boolean) => {
    // eslint-disable-next-line no-param-reassign
    dateSelected = dateSelected.startOf('day');
    setChanges(
      rows.filter((row: ChangeRecord) => {
        const modifiedDate = row && row.update_date;
        if (modifiedDate) {
          const date = moment(modifiedDate);
          if (minChanged) {
            return date >= dateSelected && date <= maxSelectedDate;
          }
          return date <= dateSelected && date >= minSelectedDate;
        }
        return undefined;
      }),
    );
  };

  useEffect(() => {
    getChanges(auth.getCustomerNumber()).then((resp) => {
      setLoading(false);
      const result = buildLogs(resp.body, t);
      setRowz(result);
      filterDates(result, moment().add(1, 'days'), false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <LoadingBounce />;
  }

  const onMinDateChangeHandler = (event: Moment) => {
    if (event.isValid()) {
      const date = event;
      minStateChangeHandler(date);
      filterDates(rowz, date, true);
    }
  };

  const onMaxDateChangeHandler = (event: Moment) => {
    if (event.isValid()) {
      const date = event;
      maxStateChangeHandler(date);
      filterDates(rowz, date, false);
    }
  };

  const onToolbarPreparing = (e: any) => {
    e.toolbarOptions.items.unshift({
      location: 'before',
      template: 'filterFields',
    });
  };

  const renderFilterFields = () => (
    <div key="filterFieldsContainer" style={datePickersDivStyle}>
      <DatePicker
        key="startDatePicker"
        name={t('Start')}
        onChangeHandler={onMinDateChangeHandler}
        dateValue={minSelectedDate}
        maxDate={maxSelectedDate}
      />
      <DatePicker
        key="endDatePicker"
        name={t('End')}
        onChangeHandler={onMaxDateChangeHandler}
        dateValue={maxSelectedDate}
        minDate={minSelectedDate}
      />
    </div>
  );

  const renderUpdatedFieldCell = (cell: any) => (
    <span>{fieldNames[cell.row.data.updated_field] || t(cell.row.data.updated_field)}</span>
  );

  const renderOldValueCell = (cell: any) => (
    <span>{fieldNames[cell.row.data.old_value] || t(cell.row.data.old_value)}</span>
  );
  const renderNewValueCell = (cell: any) => (
    <span>{fieldNames[cell.row.data.new_value] || t(cell.row.data.new_value)}</span>
  );

  const calculateDateCellValue = (row: any) => {
    const date = row && row.update_date && moment(row.update_date);
    if (date && date.isValid()) {
      return date.format('MM/DD/YYYY hh:mm A');
    }
    return '';
  };

  return (
    <DataGrid dataSource={changes} onToolbarPreparing={onToolbarPreparing} columnAutoWidth wordWrapEnabled>
      <Template name="filterFields" render={renderFilterFields} />
      <SearchPanel visible />
      <Scrolling mode="virtual" />
      <Pager allowedPageSizes={[5, 10, 20, 30]} showPageSizeSelector />
      <Paging defaultPageSize={10} enabled />
      <Column caption={t('VIN')} dataField="vin" />
      <Column caption={t('Updated Field')} dataField="updated_field" cellRender={renderUpdatedFieldCell} />
      <Column caption={t('Original Value')} dataField="old_value" cellRender={renderOldValueCell} />
      <Column caption={t('New Value')} dataField="new_value" cellRender={renderNewValueCell} />
      <Column caption={t('Updated By')} dataField="update_user" />
      <Column
        caption={t('Modified Date')}
        dataField="update_date"
        calculateCellValue={(row: any) => calculateDateCellValue(row)}
      />
    </DataGrid>
  );
};

export default Component;
