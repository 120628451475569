import React, { useEffect, useMemo, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Container, Fab, FilterGroup, GlobalActions, Panel, useListSelectFilter } from '@michelin/acid-components';
import {
  clearMaestroDataSourceCache,
  useMaestroDataSource,
  usePermissions,
  useSnackbar,
  useTranslation,
} from '@michelin/central-provider';
import { TireProfileOVM } from '@michelin/fcp-view-models';
import { getOptionLabel, getOptions } from '@michelin/select-options-provider';
import { auth } from 'Auth';
import LastModified from 'components/ChangeLog/LastModified';
import { locationsCountMap } from 'components/LocationAssignments';
import { NewLocationAssignments } from 'components/NewLocationAssignments';
import { PageTitle } from 'components/PageTitle/PageTitle';
import TireProfileCloneDialog from 'components/TireProfile/TireProfileClone/TireProfileCloneDialog';
import { CONTENT_AREA_HEIGHT } from 'components/Util';
import { DataGrid, Template } from 'devextreme-react';
import { Column, Button as DevXButton, Scrolling, SearchPanel } from 'devextreme-react/data-grid';
import { useDeleteTireProfile, useGetVehicleTypeList } from 'hooks/useTireProfile';
import { SOPValidationResult, sopValidation } from 'modules/Tires/sopValidations';
import { getBaseUrl } from 'prefs-and-service-offers';
import { useHistory } from 'react-router-dom';
import { IPermissions } from '../types';
import { tireList } from '../utils';
import { AxlesCell, CreatedByCell, OptionCell, ProfileNameCell, TireProfileRow, TireSizesCell } from './Columns';

function TireProfiles() {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const permissions = usePermissions();

  const theme = useTheme();
  const history = useHistory();
  const tabletView = useMediaQuery(theme.breakpoints.down(800));
  const cellView = useMediaQuery(theme.breakpoints.down(500));

  const [showCloneDialog, setShowCloneDialog] = useState<boolean>(false);
  const [cloneHashKey, setCloneHashKey] = useState<string>('');

  const [deleteDefaultDialogOpened, setDeleteDefaultDialogOpened] = useState<string>('');
  const [sopValidationResult, setSOPValidationResult] = useState<SOPValidationResult>({ valid: false, sopName: '' });

  const deleteTireProfile = useDeleteTireProfile();
  const tiresPermissions: IPermissions = {
    read: permissions.allowsAction('tires.read'),
    create: permissions.allowsAction('tires.create'),
    update: permissions.allowsAction('tires.update'),
    delete: permissions.allowsAction('tires.delete'),
    list: permissions.allowsAction('tires.list'),
    override_delete: permissions.allowsAction('tires.override_delete'),
  };

  const vehicleTypeList = useGetVehicleTypeList({ fleetId: permissions.location?.customer_number || '' });

  const profileTypes = useMemo(() => getOptions('profile_types'), []);
  const applicationTypes = useMemo(() => {
    return [{ label: t('N/A'), value: 'n/a', order: 0 }, ...getOptions('application_types')];
  }, [t]);

  const vehicleTypes = useMemo(() => {
    if (!vehicleTypeList || vehicleTypeList.isLoading === true) return [];

    const vtv = vehicleTypeList.data?.map((item: string) => ({
      label: getOptionLabel('vehicle_types_onsite', item),
      value: item,
    }));

    return [{ label: t('N/A'), value: 'n/a', order: 0 }, ...(vtv || [])];
  }, [vehicleTypeList, t]);

  const profileTypesFilter = useListSelectFilter({
    label: t('Profile Type'),
    options: profileTypes,
    unselectedLabel: t('Any Level'),
    hashParam: 'profileLevel',
  });

  const creatorFilter = useListSelectFilter({
    label: t('Creator'),
    options: getOptions('account_types', 'all', t('All')),
    hashParam: 'creator',
    defaultValue: 'all',
  });

  const applicationTypeFilter = useListSelectFilter({
    label: t('Application Type'),
    options: applicationTypes,
    hashParam: 'applicationType',
    defaultValue: 'n/a',
  });

  const vehicleTypeFilter = useListSelectFilter({
    label: t('Vehicle Type'),
    options: vehicleTypes,
    hashParam: 'vehicleType',
    defaultValue: 'n/a',
  });

  const filterGroup = useMemo(
    () =>
      new FilterGroup([
        profileTypesFilter.filter,
        creatorFilter.filter,
        applicationTypeFilter.filter,
        vehicleTypeFilter.filter,
      ]),
    [profileTypesFilter.filter, creatorFilter.filter, applicationTypeFilter.filter, vehicleTypeFilter.filter],
  );

  useEffect(() => {
    if (!permissions.allowsAction('tires.list')) {
      enqueueSnackbar(t('User has no permissions to list tire profiles.'), { variant: 'warning' });
      history.push(getBaseUrl());
    } else if (!permissions.allowsAction('tires.read')) {
      enqueueSnackbar(t('User has no permissions to read tire profiles.'), { variant: 'warning' });
      history.push(getBaseUrl());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    clearMaestroDataSourceCache('tire-list');
  }, []);

  const ds = useMaestroDataSource({
    API: tireList(auth.getCustomerNumber()),
    reqOptions: {
      filters: {
        profileType: profileTypesFilter.value,
        creator: creatorFilter.value === 'all' ? '' : creatorFilter.value,
        vehicleType: vehicleTypeFilter.value === 'n/a' ? '' : vehicleTypeFilter.value,
        applicationType: applicationTypeFilter.value === 'n/a' ? '' : applicationTypeFilter.value,
      },
    },
    componentPlacements: {
      filtersPlacement: tabletView ? 'outside' : 'embeded',
      searchPlacement: cellView ? 'outside' : 'embeded',
    },
    filterGroup,
    cacheKey: 'tire-list',
  });

  const NewLocationsCell = (row: any) => {
    return (
      <NewLocationAssignments
        owner={row.row.data.owner_id}
        mode="view"
        all={row.row.data.main.assign_to_all_locations}
        locations={row.row.data.locations.map((rel: any) => ({ hash_key: rel.range_key }))}
        disabled={false}
      />
    );
  };

  const cloneProfile = (e: any) => {
    setCloneHashKey(e.row.data.id);
    setShowCloneDialog(true);
  };

  const handleTireProfileCloneDialogClose = (reload: boolean) => {
    setShowCloneDialog(false);
    if (reload) ds.reload();
  };

  const DeleteDefaultDialog: any = (row: any) => {
    const onDeleteProfileOkClick = async () => {
      if (!auth || !auth.apolloClient) return;
      try {
        if (!permissions.location?.customer_number || permissions.location?.customer_number.length <= 0) {
          throw new Error('Location id is invalid');
        }
        if (!row.row.data.id || row.row.data.id.length <= 0) {
          throw new Error('Tire Profile id is invalid');
        }
        try {
          const response = await deleteTireProfile.mutateAsync({
            fleetId: permissions.location?.customer_number,
            tireId: row.row.data.id,
          });
          if (!response) {
            enqueueSnackbar(t('Error deleting Tire Profile'), {
              variant: 'error',
            });
          } else {
            enqueueSnackbar(t('Tire Profile Deleted'), {
              variant: 'success',
            });
          }
        } catch (error) {
          enqueueSnackbar(t('Error deleting Tire Profile'), {
            variant: 'error',
          });
        }
        ds.reload();
      } catch (err) {
        enqueueSnackbar(t('Error deleting Tire Profile'), {
          variant: 'error',
        });
      }
    };

    const locQty: string = row.row.data.main.assign_to_all_locations
      ? locationsCountMap.get(row.row.data.id) || '...'
      : row.row.data.locations.length;
    const tireProfileName: string = row.row.data.main.profile_name;

    const tireMsg: string = t(
      'Tire Profiles are needed to support various services such as the ONCall Tire ERS service or the Digital Tires Services Manual so to ensure efficient and effective support during tire service related events.  Deleting this tire profile may orphan up to {{cnt}} locations from having the necessary tire preferences that these services require.',
      { cnt: locQty },
    );
    const confMsg: string = t('Do you wish to acknowledge and proceed with deletion?  This action cannot be undone.');

    let sopMsg: string = '';
    if (!sopValidationResult.valid && sopValidationResult.sopName && sopValidationResult.sopName.length > 0) {
      sopMsg = t(
        'Deleting the Onsite Tire Profile {{TireProfileName}} will also cause its related SOP Profile titled {{SOPName}} to be invalid.  An SOP Profile must be linked to an onsite tire profile for it to be valid.',
        { TireProfileName: tireProfileName, SOPName: sopValidationResult.sopName },
      );
    }

    return (
      <div>
        <Dialog
          open={deleteDefaultDialogOpened === row.row.data.id}
          disableBackdropClick
          disableEscapeKeyDown
          fullWidth
          maxWidth="sm"
          aria-labelledby="confirmation-dialog-title"
        >
          <DialogTitle id="confirmation-dialog-title">{t('Confirm Tire Profile Deletion')}</DialogTitle>
          <DialogContent dividers>
            <div>
              <p>{tireMsg}</p>
              <p>{sopMsg}</p>
              <p>{confMsg}</p>
            </div>
          </DialogContent>
          <DialogActions>
            <NewLocationAssignments
              owner={row.row.data.owner_id}
              mode="view"
              all={row.row.data.main.assign_to_all_locations}
              locations={row.row.data.locations.map((rel: any) => ({ hash_key: rel.range_key }))}
              disabled={false}
            />
            <Button
              onClick={() => {
                onDeleteProfileOkClick();
                setDeleteDefaultDialogOpened('');
              }}
              color="primary"
            >
              {t('Yes, Acknowledge & Proceed')}
            </Button>
            <Button
              onClick={() => {
                setDeleteDefaultDialogOpened('');
              }}
              color="primary"
            >
              {t('Cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const RowPrepared = (row: any): any => {
    if (!row.data || (Object.keys(row.data).length === 0 && row.data.constructor === Object)) return;
    if (
      row.rowType.toLowerCase() === 'data' &&
      (auth.iOwnThisAccountType(row.data.owner.customer_type, 'switched') || 0) < 0
    ) {
      // eslint-disable-next-line no-param-reassign
      row.rowElement.style.backgroundColor = '#DDEE9928'; // using color with alpha channel to support dark and light modes
    }
  };

  return (
    <>
      <TireProfileCloneDialog
        open={showCloneDialog}
        cloneHashKey={cloneHashKey}
        handleTireProfileCloneDialogClose={handleTireProfileCloneDialogClose}
      />

      <DataGrid
        style={{ height: CONTENT_AREA_HEIGHT }}
        noDataText={t('No tire profiles found...')}
        className="freespaced-table"
        dataSource={ds.dataSource}
        allowColumnReordering
        wordWrapEnabled
        remoteOperations
        onToolbarPreparing={ds.onToolbarPreparing}
        onRowPrepared={RowPrepared}
      >
        <SearchPanel visible />
        <Scrolling mode="virtual" />
        <Template name="filterFields" render={ds.renderFilterFields} />
        <Template name="resultsCounter" render={ds.renderResultsCounter} />
        <Column caption={t('Creator')} alignment="center" allowSorting dataField="creator" cellRender={CreatedByCell} />
        <Column
          caption={t('Profile Name')}
          allowSorting
          dataField="profile_name"
          cellRender={({ row }) => <ProfileNameCell row={row} />}
        />

        <Column
          caption={t('Profile Type')}
          cellRender={({ row }) => <OptionCell option="profile_types" value={row.data.main.profile_type} />}
          allowSorting
          dataField="profile_type"
        />
        <Column
          caption={t('Application')}
          cellRender={({ row }) => <OptionCell option="application_types" value={row.data.main.application_type} />}
          allowSorting
          dataField="application"
        />
        <Column
          caption={t('Vehicle')}
          cellRender={({ row }) => (
            <OptionCell option="vehicle_types_onsite" value={row.data.main.vehicle_type_vmrs_code} />
          )}
          dataField="vehicle_type"
        />
        <Column
          caption={t('Axles')}
          alignment="left"
          cellRender={({ row }) => <AxlesCell row={row} t={t} />}
          allowSearch={false}
        />
        <Column
          caption={t('Tire Sizes')}
          alignment="left"
          cellRender={({ row }) => <TireSizesCell row={row} t={t} />}
        />

        <Column
          caption={t('Locations')}
          width={150}
          cellRender={({ row }: { row: TireProfileRow }) => (
            <div>
              {auth.getAccountTypeShort() !== 'ST' && <NewLocationsCell row={row} />}
              {deleteDefaultDialogOpened === row.data.id && (
                <DeleteDefaultDialog row={row} open={deleteDefaultDialogOpened} />
              )}
            </div>
          )}
        />

        <Column type="buttons" width={110} allowSearch={false}>
          <DevXButton
            text={t('Clone')}
            icon="copy"
            hint={t('Clone')}
            onClick={cloneProfile}
            visible={tiresPermissions && tiresPermissions.create}
          />
          <DevXButton
            text={t('Delete')}
            icon="trash"
            hint={t('Delete')}
            onClick={async ({ row }: { row: { data: TireProfileOVM } }) => {
              const svr: SOPValidationResult = await sopValidation('sop', { id: row.data.id || '' });
              setSOPValidationResult(svr);
              setDeleteDefaultDialogOpened(row.data.id || '');
            }}
            visible={({ row }: { row: { data: TireProfileOVM } }) => row.data.allowedActions?.delete}
          />
        </Column>
      </DataGrid>
    </>
  );
}

export function TireProfileList() {
  const { t } = useTranslation();
  const permissions = usePermissions();
  const history = useHistory();

  const createClick = () => {
    history.push(`${getBaseUrl()}/tire-profile/create`);
  };

  return (
    <>
      <PageTitle title={t('Tire Profile List')} rightItems={<LastModified entity="tire_profile" />} />
      <Container>
        <Panel spacing={0}>
          <TireProfiles />
          <GlobalActions>
            {permissions.allowsAction('tires.create') && (
              <Fab color="primary" label={t('Create Tire Profile')} onClick={createClick}>
                <Add />
              </Fab>
            )}
          </GlobalActions>
        </Panel>
      </Container>
    </>
  );
}
