import React, { useEffect, useMemo, useState } from 'react';
import { IconButton, Link } from '@material-ui/core';
import { Add, Delete, RestoreFromTrash } from '@material-ui/icons';
import SendIcon from '@material-ui/icons/Send';
import {
  AppBar,
  Container,
  Fab,
  FilterGroup,
  GlobalActions,
  Panel,
  SelectOption,
  Tab,
  TabPanel,
  Tabs,
  Tooltip,
  useListSelectFilter,
} from '@michelin/acid-components';
import { useMaestroDataSource, usePermissions, useTranslation } from '@michelin/central-provider';
import { VehicleType } from '@michelin/core-profile-ui-tools/build/types';
import { getOptionLabel } from '@michelin/select-options-provider';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { TABBED_CONTENT_HEIGHT } from 'components/Util';
import { Template } from 'devextreme-react/core/template';
import DataGrid, { Column, SearchPanel } from 'devextreme-react/data-grid';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { auth } from '../../Auth';
import { getBaseUrl, getHomeUrl } from '../../prefs-and-service-offers';
import CreatedByAvatar from '../CreatedByAvatar';
import { vehiclesListingAPIType } from '../Types/APITypes';
import { VehicleUpdateLink } from './ChangeLog/UpdateLink';
import { LocationSelectorProvider } from './LocationSelectorProvider';
import { VehiclesDashboardProps } from './Utils';
import useVehicleActions from './hooks/useVehicleActions';

export function VehiclesDashboard(props: VehiclesDashboardProps) {
  const { vehicleType } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const { activate, deactivate, remove } = useVehicleActions();
  const [forceReload, setForceReload] = useState<number>(Date.now());

  function buildOptions() {
    const options: SelectOption[] = [];
    options.push({ label: t('Active vehicles'), value: 'true' });
    options.push({ label: t('Archived vehicles'), value: 'false' });
    return options;
  }

  const vehiclesFilter = useListSelectFilter({
    label: '',
    hashParam: 'active',
    defaultValue: 'true',
    options: buildOptions(),
  });

  const filterGroup = useMemo(() => new FilterGroup([vehiclesFilter.filter]), [vehiclesFilter.filter]);

  const ds = useMaestroDataSource({
    API: vehiclesListingAPIType(auth.getCustomerNumber()),
    reqOptions: {
      filters: {
        get_archived: vehiclesFilter.value === 'false',
        type: vehicleType,
      },
    },
    filterGroup,
    cacheKey: forceReload.toString(),
  });

  useEffect(() => {
    async function reload() {
      await ds.dataSource.reload();
    }
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceReload]);

  const reloadList = () => {
    setForceReload(Date.now());
  };

  const deleteVehicle = async (data: VehicleType) => {
    remove(data, reloadList);
  };

  async function activateVehicle(data: VehicleType) {
    const hasBlankFields: boolean = !data.fleet_id_no || !data.license_plate || !data.license_state_abbreviation;
    if (hasBlankFields) {
      history.push(`${getBaseUrl()}/urban-vehicles/${data.id}/edit`);
    } else activate(data, reloadList);
  }

  function renderActions(data: any) {
    if (!data) return null;

    return (
      <>
        {vehicleType === 'commercial' && props.allowDelete && vehiclesFilter.value === 'true' && (
          <IconButton onClick={() => deleteVehicle(data as VehicleType)}>
            <Tooltip placement="top" title={t('Delete') as string} arrow>
              <Delete fontSize="small" color="primary" />
            </Tooltip>
          </IconButton>
        )}
        {vehicleType === 'urban' && props.allowDelete && vehiclesFilter.value === 'true' && (
          <IconButton onClick={() => deactivate(data as VehicleType)}>
            <Tooltip placement="top" title={t('Deactivate') as string} arrow>
              <Delete fontSize="small" color="primary" />
            </Tooltip>
          </IconButton>
        )}
        {vehicleType === 'urban' &&
          props.allowDelete &&
          vehiclesFilter.value === 'false' &&
          !!data.is_archived &&
          !data.is_transferred &&
          !data.transfer_source &&
          !String(data.reason_text).includes('sold_') && (
            <IconButton onClick={() => activateVehicle(data)}>
              <Tooltip placement="top" title={t('Activate') as string} arrow>
                <RestoreFromTrash fontSize="small" color="primary" />
              </Tooltip>
            </IconButton>
          )}
        {vehicleType === 'urban' &&
          props.allowTransfer &&
          vehiclesFilter.value === 'false' &&
          !!data.is_archived &&
          !data.is_transferred &&
          !!data.transfer_source && (
            <IconButton onClick={() => activateVehicle(data)}>
              <Tooltip placement="top" title={t('Complete transfer') as string} arrow>
                <SendIcon fontSize="small" color="primary" style={{ transform: 'rotate(180deg)' }} />
              </Tooltip>
            </IconButton>
          )}
      </>
    );
  }

  return (
    <>
      <LocationSelectorProvider />
      <DataGrid
        style={{ height: TABBED_CONTENT_HEIGHT }}
        noDataText={t('No vehicles found...')}
        scrolling={{ mode: 'virtual', rowRenderingMode: 'virtual' }}
        className="freespaced-table"
        dataSource={ds.dataSource}
        allowColumnReordering
        wordWrapEnabled
        remoteOperations
        columnAutoWidth
        onToolbarPreparing={ds.onToolbarPreparing}
      >
        <Template name="filterFields" render={ds.renderFilterFields} />
        <Template name="resultsCounter" render={ds.renderResultsCounter} />
        <SearchPanel visible highlightSearchText={false} />
        <Column
          alignment="center"
          caption={t('Creator')}
          dataField="st"
          dataType="string"
          width={100}
          cellRender={({ row }) => {
            const custType: string =
              (row.data.st && 'ST') || (row.data.bt && 'BT') || (row.data.ho && 'HO') || (row.data.pc && 'PC');
            const custNumb: string = `${row.data.st || row.data.bt || row.data.ho || row.data.pc}`;
            return <CreatedByAvatar customerType={custType} customerNumber={custNumb} />;
          }}
          allowSearch={false}
          allowSorting={false}
        />
        <Column
          alignment="left"
          caption={t('Vehicle Unit Number')}
          cellRender={({ row }) => {
            const tag =
              !!row.data.is_archived &&
              // eslint-disable-next-line no-extra-boolean-cast
              (!!row.data.transfer_to
                ? `[${t('Transferred').toLocaleUpperCase()}]`
                : !!row.data.transfer_source && `[${t('Incoming Transfer').toLocaleUpperCase()}]`);

            return (
              <>
                <Link
                  onClick={
                    !props.allowView ? undefined : () => history.push(`${vehicleType}-vehicles/${row.data.id}/view`)
                  }
                  style={{ cursor: 'pointer' }}
                >
                  <b>{row.data.fleet_id_no}</b>
                </Link>
                <br />
                <div style={{ opacity: 0.5 }}>{vehicleType === 'urban' && tag}</div>
              </>
            );
          }}
          dataField="fleet_id_no"
          allowSorting
        />
        <Column alignment="left" caption={t('VIN')} dataField="vin" allowSorting />
        <Column alignment="left" caption={t('Year')} dataField="year" allowSorting />
        <Column
          alignment="left"
          caption={t('Make, Model & Asset Type')}
          dataField="make_name"
          allowSorting
          cellRender={({ row }) => (
            <>
              <b>Make:</b> {row.data.make_name}
              <br />
              <b>Model:</b> {row.data.model_name}
              {row.data.asset_type_id ? (
                <>
                  <br />
                  <b>Asset Type:</b> {getOptionLabel('asset_types', row.data.asset_type_id)}
                </>
              ) : null}
            </>
          )}
        />
        {vehicleType === 'urban' ? (
          <Column alignment="left" caption={t('Option')} dataField="option_name" allowSorting />
        ) : null}
        {vehicleType === 'urban' ? (
          <Column alignment="left" caption={t('Tire Size')} dataField="tire_size_name" allowSorting />
        ) : null}
        <Column
          alignment="left"
          caption={t('License')}
          allowSorting
          dataField="license_plate"
          cellRender={({ row }) => (
            <>
              <b>Number:</b> {row.data.license_plate}
              <br />
              <b>State:</b> {row.data.license_state_abbreviation}
            </>
          )}
        />
        <Column alignment="left" caption={t('Color')} dataField="color" allowSorting />
        <Column cellRender={({ row }) => renderActions(row.data)} />
      </DataGrid>
    </>
  );
}

function VehiclesList(props: any) {
  const { type } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedTab, setSelectedTab] = useState<number>(type === 'commercial' ? 0 : 1);
  const permissions = usePermissions();

  const deletePermission = permissions.allowsAction('vehicles.delete');
  const listPermission = permissions.allowsAction('vehicles.list');
  const readPermission = permissions.allowsAction('vehicles.read');
  const addPermission = permissions.allowsAction('vehicles.create');
  const transferPermission = permissions.allowsAction('vehicles.transfer');
  const urbanPermission = permissions.allowsAction('vehicles.urban');
  const commercialPermission = permissions.allowsAction('vehicles.commercial');

  if ((!urbanPermission || !auth.isUrbanCustomer()) && type === 'urban') {
    // Has no permissions to manage urban vehicles
    enqueueSnackbar(t('User has no permissions to manage vehicles.'), { variant: 'warning' });
    history.push('commercial-vehicles');
  }

  if (!commercialPermission && type === 'commercial') {
    // Has no permissions to manage urban vehicles
    enqueueSnackbar(t('User has no permissions to manage vehicles.'), { variant: 'warning' });
    history.push(getHomeUrl());
  }

  if (auth.getCountryCode() !== 'us') {
    // Has no permissions manage vehicles
    enqueueSnackbar(t('User has no permissions to manage vehicles.'), { variant: 'warning' });
    history.push(getHomeUrl());
  }

  if (!listPermission) {
    // Has no permissions to list
    enqueueSnackbar(t('User has no permissions to list vehicles.'), { variant: 'warning' });
    history.push(getHomeUrl());
  } else if (!readPermission) {
    // Has no permissions to read
    enqueueSnackbar(t('User has no permissions to read vehicles.'), { variant: 'warning' });
    history.push(getHomeUrl());
  }

  return (
    <>
      <PageTitle title={t('Vehicle Management')} rightItems={<VehicleUpdateLink />} />
      <Container>
        <Panel spacing={0}>
          {commercialPermission && (!urbanPermission || !auth.isUrbanCustomer()) && (
            <VehiclesDashboard
              vehicleType="commercial"
              allowView={readPermission}
              allowDelete={deletePermission}
              allowTransfer={transferPermission}
            />
          )}
          {!commercialPermission && urbanPermission && auth.isUrbanCustomer() && (
            <VehiclesDashboard
              vehicleType="urban"
              allowView={readPermission}
              allowDelete={deletePermission}
              allowTransfer={transferPermission}
            />
          )}
          {commercialPermission && urbanPermission && auth.isUrbanCustomer() && (
            <>
              <AppBar style={{ height: 48 }} color="primary" position="static">
                <Tabs
                  value={selectedTab}
                  onChange={(event, newValue: number) => {
                    if (newValue === 0) history.push('commercial-vehicles');
                    if (newValue === 1) history.push('urban-vehicles');
                    setSelectedTab(newValue);
                  }}
                  variant="scrollable"
                  scrollButtons="on"
                >
                  {commercialPermission && <Tab label={t('Commercial Trucks')} value={0} style={{ minWidth: 260 }} />}
                  {urbanPermission && auth.isUrbanCustomer() && (
                    <Tab label={t('Passenger / LT')} value={1} style={{ minWidth: 260 }} />
                  )}
                </Tabs>
              </AppBar>

              <TabPanel index={0} value={selectedTab} spacing={0} style={{ border: 'none' }}>
                <VehiclesDashboard
                  vehicleType="commercial"
                  allowView={readPermission}
                  allowDelete={deletePermission}
                  allowTransfer={transferPermission}
                />
              </TabPanel>
              <TabPanel index={1} value={selectedTab} spacing={0} style={{ border: 'none' }}>
                <VehiclesDashboard
                  vehicleType="urban"
                  allowView={readPermission}
                  allowDelete={deletePermission}
                  allowTransfer={transferPermission}
                />
              </TabPanel>
            </>
          )}
          <GlobalActions>
            <Fab
              color="primary"
              label={t('Create Vehicle')}
              onClick={() => history.push(`${getBaseUrl()}/${type}-vehicles/add`)}
              disabled={!addPermission}
            >
              <Add />
            </Fab>
          </GlobalActions>
        </Panel>
      </Container>
    </>
  );
}

export default VehiclesList;
