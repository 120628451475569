import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { AppBar, Container, Panel, Tab, TabPanel, Tabs } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { auth } from 'Auth';
import BillingPreferences from 'components/Billing/Preferences';
import { BillingPreferences as BillingPreferencesType } from 'components/Billing/Preferences/query';
import { getBillingProfilesSummaryList } from 'components/Billing/Profiles/utils';
import BillingRequirements from 'components/Billing/Requirements';
import { tableQuery, tableRequirementsQuery } from 'components/Billing/UpdateLink/profileQuery';
import LastModified from 'components/ChangeLog/LastModified';
import { isHierarchyHigher } from 'components/Contact/ContactDetails/utils';
import { PageTitle } from 'components/PageTitle/PageTitle';
import DeliveryReceiptNotes from 'modules/Billing/DeliveryReceiptNotes/List';
import { BillingProfilesList } from 'modules/Billing/Profiles/List';
import { SelectedServiceOffer, ServiceOfferIds, getBaseUrl, getSelectedServiceOffer } from 'prefs-and-service-offers';
import { useHistory, useRouteMatch } from 'react-router-dom';

interface NavTabsProps {
  selectedTab?: number;
  action: 'view' | 'edit' | 'create';
}

type DataAndLoading = {
  data: any;
  loading: boolean;
};

export interface DefaultProfiles {
  tire_wheel_ers: boolean;
  tire_wheel_onsite: boolean;
  tire_wheel_ers_onsite: boolean;
  mechanical_onsite: boolean;
}

function BillingsModule(props: NavTabsProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch<{ profileId: string }>();
  const [headerTitle, setHeaderTitle] = useState(t('Billing Preferences') as string);
  const [headerSubtitle, setHeaderSubtitle] = useState('');

  // TODO begin of code which is still required for details page (BillingPreferences) till we work on its refactor
  const customerData = auth.getCustomerData();
  const [dataAndLoading, setDataAndLoading] = useState<DataAndLoading>({ data: undefined, loading: true });
  const [profiles, setProfiles] = useState<BillingPreferencesType[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filteredProfiles, setFilteredProfiles] = useState<BillingPreferencesType[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadingProfiles, setLoadingProfiles] = useState(true);
  const [defaultProfiles, setDefaultProfiles] = useState<DefaultProfiles>({
    tire_wheel_ers: false,
    tire_wheel_onsite: false,
    tire_wheel_ers_onsite: false,
    mechanical_onsite: false,
  });

  const [selectedTab, setSelectedTab] = useState(props.selectedTab || 0);
  useEffect(() => setSelectedTab(props.selectedTab || 0), [props.selectedTab]);

  const {
    loading: versionLoading,
    error: versionError,
    data: versionData,
  } = useQuery(tableQuery, {
    variables: {
      relationship: auth.getRelationship(),
      queryName: 'billingProfileLog',
      anyRecord: true,
      dateString: (() => {
        const a = new Date();
        a.setMonth(a.getMonth() - 3);
        return a.toISOString().replace(/t.+$/i, '');
      })(),
    },
    fetchPolicy: 'no-cache',
  });

  const {
    loading: versionRequirementsLoading,
    error: versionRequirementsError,
    data: versionRequirementsData,
  } = useQuery(tableRequirementsQuery, {
    variables: {
      accountNumber: auth.getUltimateParent().customerNumber,
      dateString: (() => {
        const a = new Date();
        a.setMonth(a.getMonth() - 3);
        return a.toISOString().replace(/t.+$/i, '');
      })(),
    },
    fetchPolicy: 'no-cache',
  });

  if (
    !versionLoading &&
    !versionError &&
    versionData &&
    dataAndLoading.data === undefined &&
    !versionRequirementsLoading &&
    !versionRequirementsError &&
    versionRequirementsData
  ) {
    versionRequirementsData.getBillingRequirementChangeLog.forEach((item: any) => {
      const reqItem: any = {
        update_user: item.update_user,
        update_date: item.update_date,
        changes: item.changes,
        profile_type: 'N/A',
        profile_name: 'N/A',
        section: t('Requirements'),
        service_type: 'N/A',
      };
      versionData.getBillingProfileChangeLog.push(reqItem);
    });

    setDataAndLoading({ data: versionData, loading: false });
  }

  const selectedServiceOffer: SelectedServiceOffer | undefined = getSelectedServiceOffer() || undefined;
  const onCallFlag: boolean =
    !!selectedServiceOffer && !!selectedServiceOffer.id && selectedServiceOffer.id === ServiceOfferIds.onCallTire;

  // To get more information about the sort order reach out the user story: GSDCP-1965
  function createSortNewProfilesFunc(customerData: any) {
    return (a: BillingPreferencesType, b: BillingPreferencesType) => {
      const a_ParentOwned =
        customerData && !isHierarchyHigher(customerData.customer_type, (a.owner && a.owner.customer_type) || '');
      const b_ParentOwned =
        customerData && !isHierarchyHigher(customerData.customer_type, (b.owner && b.owner.customer_type) || '');

      // Parent owned first
      if (a_ParentOwned && !b_ParentOwned) {
        return -1;
      }
      if (!a_ParentOwned && b_ParentOwned) {
        return 1;
      }

      // Default second/first
      if (a.general_information.default && !b.general_information.default) {
        // A is default. B is not.
        return -1;
      }

      if (a.general_information.default && b.general_information.default) {
        // A and B are default. Compare by name.
        if (a.general_information.profile_name > b.general_information.profile_name) {
          return 1;
        }
        if (a.general_information.profile_name === b.general_information.profile_name) {
          return 0;
        }
        return -1;
      }

      if (a.general_information.default === false && b.general_information.default) {
        return 1;
      }

      if (a.general_information.profile_name > b.general_information.profile_name) {
        return 1;
      }

      if (a.general_information.profile_name === b.general_information.profile_name) {
        return 0;
      }
      return -1;
    };
  }

  const asyncLoadProfileSummaryList = async () => {
    setLoadingProfiles(true);
    const newProfiles = await getBillingProfilesSummaryList();
    newProfiles.sort(createSortNewProfilesFunc(customerData));

    const df: DefaultProfiles = {
      tire_wheel_ers: false,
      tire_wheel_onsite: false,
      tire_wheel_ers_onsite: false,
      mechanical_onsite: false,
    };
    newProfiles.forEach((bp: any) => {
      if (bp.owner.customer_type === auth.getAccountTypeShort() && bp.general_information.default) {
        (df as any)[`${bp.general_information.service_type}_${bp.general_information.profile_type}`] = true;
      }
    });
    setDefaultProfiles(df);
    setProfiles(newProfiles);
    setFilteredProfiles(newProfiles);
    setLoadingProfiles(false);
  };

  useEffect(() => {
    asyncLoadProfileSummaryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReloadProfileSummaryList = async () => {
    setLoadingProfiles(true);
    await asyncLoadProfileSummaryList();
  };
  // End of code required for details page (BillingPreferences) till we work on its refactor

  function onTabChange(event: React.ChangeEvent<{}>, newValue: number) {
    let tabURL = 'profiles';

    switch (newValue) {
      case 0:
        tabURL = 'profiles';
        break;
      case 1:
        tabURL = 'requirements';
        break;
      case 2:
        tabURL = 'receiptnotes';
        break;
      default:
        tabURL = 'profiles';
        break;
    }
    history.push(`${getBaseUrl()}/billings/${tabURL}`);
  }

  const { profileId } = match.params;
  const displayBillingPrererences = profileId || (props.action === 'create' && selectedTab === 0);

  return (
    <>
      <PageTitle rightItems={<LastModified entity="billing" />} title={headerTitle} subtitle={headerSubtitle} />
      <Container>
        <Panel spacing={0}>
          <AppBar color="primary" position="static" style={{ height: 48 }}>
            <Tabs
              variant="scrollable"
              value={selectedTab}
              onChange={onTabChange}
              aria-label="nav tabs"
              scrollButtons="on"
            >
              <Tab style={{ minWidth: 260 }} label={t('Billing Profiles')} value={0} />
              <Tab style={{ minWidth: 260 }} label={t('Billing Requirements')} value={1} />
              <Tab style={{ minWidth: 260 }} label={t('Delivery Receipt Notes')} value={2} />
            </Tabs>
          </AppBar>
          <TabPanel value={selectedTab} index={0} style={{ padding: 0, border: 'none' }}>
            {displayBillingPrererences ? (
              <BillingPreferences
                key="billingPreferences"
                profileId={profileId}
                action={props.action}
                onCallFlag={onCallFlag}
                setDataAndLoading={setDataAndLoading}
                profileSummaryList={profiles}
                handleReloadProfileSummaryList={handleReloadProfileSummaryList}
                defaultProfiles={defaultProfiles}
                setHeaderTitle={setHeaderTitle}
                setHeaderSubtitle={setHeaderSubtitle}
              />
            ) : (
              <BillingProfilesList onCallFlag={onCallFlag} setDataAndLoading={setDataAndLoading} enableLocation />
            )}
          </TabPanel>
          <TabPanel value={selectedTab} index={1} style={{ padding: 0, border: 'none' }}>
            <BillingRequirements />
          </TabPanel>
          <TabPanel value={selectedTab} index={2} style={{ padding: 0, border: 'none' }}>
            <DeliveryReceiptNotes />
          </TabPanel>
        </Panel>
      </Container>
    </>
  );
}

export { BillingsModule };
