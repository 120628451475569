import React, { useContext } from 'react';
import { Divider } from '@material-ui/core';
import { useTranslation } from '@michelin/central-provider';
import { AccountLocationRender, renderAddress } from 'components/Contact/ContactDetails/ContactTypes/tables/utils';
import { PO_ISSUER_ROLE_AREA_VALUE, PRIMARY_LEVEL_VALUE } from 'components/Contact/ContactDetails/utils';
import { RenderNameAndAccountInfo } from 'components/Contact/ContactList';
import { Contact } from 'components/Contact/utils';
import { DataGrid } from 'devextreme-react';
import { Column, Scrolling } from 'devextreme-react/data-grid';
import { BillingProfileContext } from '../..';
import { BillingProfileLocation } from '../../query';

interface Props {
  levelsPerLocationHashKey: Map<string, Map<string, Contact>>;
  comboPerLocationHashKey: Map<string, Contact>;
  contactTypes: Array<string>;
  contactTypeLevel: string;
}

interface Row {
  contacts: Array<Contact>;
  location: BillingProfileLocation;
}

function buildRows(
  locations: BillingProfileLocation[],
  levelsPerLocationHashKey: Map<string, Map<string, Contact>>,
): Row[] {
  const rows: Row[] = [];
  locations.forEach((location) => {
    const contacts = levelsPerLocationHashKey.get(location.hash_key);
    if (!contacts) return;
    const mappedContacts = Array.from(contacts.values());
    rows.push({ contacts: mappedContacts, location });
  });
  return rows;
}

function contactInfoRender(
  row: Row,
  contactTypes: Array<string>,
  contactLevel: string,
  comboPerLocationHashKey: Map<string, Contact>,
  t: Function,
): JSX.Element {
  const { location, contacts } = row;
  return (
    <>
      {contacts.map((contact: Contact, index: number) => {
        const mappedContactTypes = contactTypes.map((contactType: string): JSX.Element | undefined => {
          const assignedContact = comboPerLocationHashKey.get(location.hash_key + contactType);
          if (assignedContact && assignedContact.hash_key === contact.hash_key) {
            return (
              <div key={contact.hash_key + contactLevel + contactType} style={{ marginTop: '0.2rem' }}>
                <span style={{ fontWeight: 900 }}>
                  {contactLevel === PRIMARY_LEVEL_VALUE ? t('Primary') : t('Secondary')}
                </span>
                {` ${t('Tire')} `}
                {contactType === PO_ISSUER_ROLE_AREA_VALUE ? t('PO Issuer') : t('ERS Manager')}
              </div>
            );
          }
          return undefined;
        });

        return (
          <div key={contact.hash_key}>
            <div style={{ display: 'flex' }}>
              <RenderNameAndAccountInfo row={contact} />
              <div style={{ marginTop: 16, marginLeft: 64 }}>{mappedContactTypes}</div>
            </div>
            {index < contacts.length - 1 ? (
              <div style={{ width: 300, marginTop: '1rem' }}>
                <Divider variant="middle" />
              </div>
            ) : undefined}
          </div>
        );
      })}
    </>
  );
}

export default function PORequiredManageTable(props: Props): JSX.Element {
  const { levelsPerLocationHashKey, contactTypeLevel, contactTypes, comboPerLocationHashKey } = props;
  const { expLocations } = useContext(BillingProfileContext);
  const { t } = useTranslation();
  const rows = buildRows(expLocations, levelsPerLocationHashKey);

  return (
    <DataGrid dataSource={rows} showBorders repaintChangesOnly columnAutoWidth style={{ minHeight: '50vh' }}>
      <Scrolling mode="virtual" />
      <Column
        calculateSortValue={(row: Row) => `${row.location.customer_state} ${row.location.customer_city}`}
        allowSorting
        caption={t('Account/Location')}
        dataField="account_location"
        cellRender={(row: any) => <AccountLocationRender location={row.data.location} clickable />}
      />
      <Column
        calculateSortValue={(row: Row) => `${row.location.customer_addr1} ${row.location.customer_addr2}`}
        allowSorting
        caption={t('Address')}
        dataField="address"
        cellRender={(row: any) => renderAddress(row.data.location)}
      />
      <Column
        allowSorting={false}
        caption={t('Contact Info & Contact Types')}
        dataField="contact_info"
        cellRender={(row: any) =>
          contactInfoRender(row.data, contactTypes, contactTypeLevel, comboPerLocationHashKey, t)
        }
      />
    </DataGrid>
  );
}
