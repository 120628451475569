import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from '@michelin/central-provider';
import { auth } from 'Auth';
import { ClosableDialogLink } from 'components/ClosableDialog/ClosableDialogLink';
import { apiBaseRoute } from 'components/Types/Api';
import ChangeLog from './ChangeLog';

const moment = require('moment');

type WrapperProps = {
  hash_key?: string;
};

export async function getChanges(fleet_id: string, justDate?: boolean) {
  try {
    const headers: { [key: string]: string } = { 'Content-Type': 'application/json' };

    headers.Authorization = `Bearer ${auth.getAuthResultTokenAccess()}`;
    const url = `${apiBaseRoute}/cp-be/changelogs/asset-changelogs?customerNumber=${fleet_id.replace(/1~/, '')}${
      justDate ? '&latestLogDate=true' : ''
    }`;
    const responseData = await fetch(url, {
      body: undefined,
      cache: 'no-cache',
      headers,
      method: 'GET',
      signal: undefined,
    });

    if (responseData.status >= 300) {
      const jsonError = await responseData.json();
      throw Error(JSON.stringify(jsonError.errors));
    }
    const result = await responseData.json();
    return { body: result, status: responseData.status, aborted: false };
  } catch (error) {
    return { body: error, status: 500, aborted: (error as { code: number }).code === 20 };
  }
}

export const VehicleUpdateLink: FunctionComponent<WrapperProps> = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState(null);

  useEffect(() => {
    getChanges(auth.getCustomerNumber(), true).then((resp) => {
      setLoading(false);
      const newDate = resp.body && /^\d/.test(resp.body) ? moment.utc(resp.body) : null;
      setDate(newDate);
    });
  }, []);

  return (
    <ClosableDialogLink loading={loading} lastUpdateDate={date} title={`${t('Vehicles')} - ${t('Change Log')}`}>
      <ChangeLog id={auth.getCustomerNumber()} />
    </ClosableDialogLink>
  );
};
