import React from 'react';
import { Container } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import LastModified from 'components/ChangeLog/LastModified';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { ContactsTable } from './Tables/ContactsTable';

export function ContactsModule() {
  const { t } = useTranslation();
  return (
    <>
      <PageTitle rightItems={<LastModified entity="contact" />} title={t('Contacts List')} />
      <Container>
        <ContactsTable />
      </Container>
    </>
  );
}
