import React from 'react';
import { Grid, InputAdornment, makeStyles } from '@material-ui/core';
import { LoadingBounce, Panel, Switch, TextField, Tooltip } from '@michelin/acid-components';
import { usePermissions, useTranslation } from '@michelin/central-provider';
import { PlainMessage } from 'components/Messages';
import { NewLocationAssignments } from 'components/NewLocationAssignments';
import { TABBED_CONTENT_HEIGHT } from 'components/Util';
import { LocationDetailsDataProvider } from 'modules/Locations/types';
import { Controller, useFormContext } from 'react-hook-form';
import { SaveError } from '../../../components/Location/LocationTypes';

interface ProgramsProps {
  loadingData: boolean;
  isEdit: boolean;
  saveError: SaveError;
  forceUpdate: () => void;
  owner: string;
  customerLevel: string;
  stCustomer: string;
  btCustomer: string;
  errorData: boolean;
}

export interface IUrbanAssignmentChild {
  hash_key: string;
  urban_admin_allow_create_vehicle?: boolean;
  urban_admin_allow_preapproved_txs?: boolean;
  urban_admin_preapproved_txs_limit_amount?: string;
}

const useStyles = makeStyles((theme) => ({
  gridItemStyle: {
    [theme.breakpoints.down('sm')]: { display: 'flex', justifyContent: 'flex-end', textAlign: 'end' },
    [theme.breakpoints.up('sm')]: { display: 'flex', justifyContent: 'flex-end', textAlign: 'end' },
  },
  switchStyles: {
    [theme.breakpoints.down('sm')]: { marginRight: 0, textAlign: 'end' },
    [theme.breakpoints.up('sm')]: { textAlign: 'end' },
  },
  preApprovalContainer: {
    [theme.breakpoints.down('sm')]: { display: 'flex', justifyContent: 'end', marginRight: 8 },
    [theme.breakpoints.up('sm')]: { textAlign: 'center' },
  },
  tooltip: {
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 14,
    maxWidth: 400,
  },
  arrow: {
    color: theme.palette.grey[900],
  },
}));

export const ProgramsDetails = (props: ProgramsProps) => {
  const { isEdit, saveError, loadingData, forceUpdate, owner, customerLevel, stCustomer, btCustomer, errorData } =
    props;

  const permissions = usePermissions();
  const { t } = useTranslation();
  const classes = useStyles();
  const { control, getValues } = useFormContext<LocationDetailsDataProvider>();
  const isBTLevel = customerLevel === 'BT';
  const urbanReadOnly = !isEdit || !isBTLevel || !permissions.allowsAction('locations.update_is_urban');
  const onCallDRDashboardReadOnly =
    !isEdit || !permissions.allowsAction('locations.update_access_to_oncall_dr_dashboard');
  const isCommercialReadOnly = !isEdit || !permissions.allowsAction('locations.update_is_commercial');

  const { commercialAll, commercialData, urbanAll, urbanData, onCallDRAll, onCallDRData, stLocations } =
    getValues().programsData;
  if (loadingData) {
    return <LoadingBounce style={{ height: TABBED_CONTENT_HEIGHT }} />;
  }
  if (errorData) {
    return <PlainMessage title={t('Error')} messages={[t('Error loading data. Please try again later.')]} />;
  }
  return (
    <>
      {urbanData && (
        <Grid container item xs={12}>
          <Panel title={t('Urban Account Administration')}>
            <Grid container spacing={2}>
              <Grid spacing={2} item xs={12} sm={6}>
                <Grid item xs={12} className={classes.gridItemStyle}>
                  <Controller
                    name="programsData.urbanData.is_urban"
                    defaultValue={urbanData.is_urban}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Switch
                        checked={value}
                        id="isUrbanSwitch"
                        readOnly={urbanReadOnly}
                        onChange={(e) => {
                          onChange(e.target.checked);
                          forceUpdate();
                        }}
                        color="primary"
                        disabled={urbanReadOnly}
                        className={classes.switchStyles}
                        label={t('Is Urban')}
                        fullWidth={false}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} className={classes.gridItemStyle}>
                  <Tooltip
                    classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
                    title={
                      <div>
                        {t(
                          'Toggle off means that the Fleet Admin must add and maintain vehicle information on the platform.',
                        )}
                        <br />
                        {t('Toggle on means that the Service Provider may add and update vehicle information.')}
                        <br />
                        {t('Best option for walk-in service.')}
                      </div>
                    }
                  >
                    <Controller
                      name="programsData.urbanData.urban_admin_allow_create_vehicle"
                      defaultValue={urbanData.urban_admin_allow_create_vehicle}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Switch
                          checked={value}
                          id="isUrbanAllowCreateVehicleSwitch"
                          readOnly={urbanReadOnly || !urbanData.is_urban}
                          onChange={(e) => {
                            onChange(e.target.checked);
                            forceUpdate();
                          }}
                          color="primary"
                          className={classes.switchStyles}
                          label={t('Allow providers to add vehicles')}
                          fullWidth={false}
                        />
                      )}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.preApprovalContainer}>
                <div style={{ textAlign: 'end', width: 'fit-content' }}>
                  <Tooltip
                    classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
                    title={
                      <div>
                        {t('Toggle off means all transactions will need to be approved by the Fleet Admin.')}
                        <br />
                        {t(
                          'Toggle on means that transactions will be pre-approved if under the dollar limit provided.',
                        )}
                        <br />
                      </div>
                    }
                  >
                    <Controller
                      name="programsData.urbanData.urban_admin_allow_preapproved_txs"
                      defaultValue={urbanData.urban_admin_allow_preapproved_txs}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Switch
                          checked={value}
                          id="isUrbanAllowPreApprovedSwitch"
                          readOnly={urbanReadOnly || !urbanData.is_urban}
                          onChange={(e) => {
                            onChange(e.target.checked);
                            forceUpdate();
                          }}
                          color="primary"
                          className={classes.switchStyles}
                          label={t('Allow Pre-approved Transactions')}
                          fullWidth={false}
                        />
                      )}
                    />
                  </Tooltip>
                  <br />
                  {!!urbanData.urban_admin_allow_preapproved_txs && !!urbanData.is_urban && (
                    <Controller
                      name="programsData.urbanData.urban_admin_preapproved_txs_no_limits"
                      defaultValue={urbanData.urban_admin_preapproved_txs_no_limits}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Switch
                          checked={value}
                          id="isUrbanNoLimitsSwitch"
                          readOnly={urbanReadOnly}
                          onChange={(e) => {
                            onChange(e.target.checked);
                            forceUpdate();
                          }}
                          color="primary"
                          className={classes.switchStyles}
                          label={t('No-Limits')}
                          fullWidth={false}
                        />
                      )}
                    />
                  )}
                  <br />
                  <Controller
                    name="programsData.urbanData.urban_admin_preapproved_txs_limit_amount"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextField
                        margin="dense"
                        style={{
                          textAlign: 'start',
                          maxWidth: 126,
                          display:
                            !!urbanData.urban_admin_preapproved_txs_no_limits ||
                            !urbanData.urban_admin_allow_preapproved_txs ||
                            !urbanData.is_urban
                              ? 'none'
                              : undefined,
                        }}
                        fullWidth={false}
                        label={t('Limit Amount')}
                        type="number"
                        value={field.value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          !urbanReadOnly && field.onChange(e.currentTarget.value)
                        }
                        error={saveError.errors.includes('preapprovedLimitAmount')}
                        disabled={!isEdit || !permissions.allowsAction('locations.update_limits_amount')}
                        required={!!urbanData.urban_admin_allow_preapproved_txs}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment style={{ transform: 'scale(0.8)' }} position="start">
                              $
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </div>
              </Grid>
              {isBTLevel && (
                <>
                  <Grid item xs={6} className={classes.gridItemStyle}>
                    <Controller
                      name="programsData.urbanAll"
                      defaultValue={urbanAll}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Switch
                          checked={value}
                          id="isUrbanAllSwitch"
                          readOnly={urbanReadOnly || !urbanData.is_urban}
                          onChange={(e) => {
                            onChange(e.target.checked);
                            forceUpdate();
                          }}
                          color="primary"
                          disabled={urbanReadOnly || !urbanData.is_urban}
                          className={classes.switchStyles}
                          label={t('Apply to all {{stQty}} ST locations', {
                            stQty: stLocations ? stLocations?.length : 0,
                          })}
                          fullWidth={false}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <Controller
                      name="programsData.urbanData.stUrbanLocations"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <NewLocationAssignments
                          owner={owner}
                          mode={!urbanReadOnly && !urbanAll && urbanData.is_urban ? 'edit' : 'view'}
                          all={urbanAll}
                          locations={value ? value.map((hash_key) => ({ hash_key })) : []}
                          onUpdate={(newAssignments) => {
                            onChange(newAssignments.map((na) => na.hash_key));
                          }}
                          disabled={(urbanReadOnly && urbanData.stUrbanLocations?.length === 0) || !urbanData.is_urban}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Panel>
        </Grid>
      )}
      {(stCustomer || btCustomer) && commercialData && (
        <Grid container item xs={12}>
          <Panel title={t('Commercial Account Management')}>
            <Grid container spacing={2}>
              <Grid spacing={2} item xs={12}>
                <Grid item xs={12}>
                  <Controller
                    name="programsData.commercialData.is_commercial"
                    defaultValue={commercialData.is_commercial}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Switch
                        checked={value}
                        id="isCommercialSwitch"
                        readOnly={isCommercialReadOnly}
                        onChange={(e) => {
                          onChange(e.target.checked);
                          forceUpdate();
                        }}
                        color="primary"
                        disabled={isCommercialReadOnly}
                        className={classes.switchStyles}
                        label={t('Is Commercial', {
                          stQty: stLocations ? stLocations?.length : 0,
                        })}
                        fullWidth={false}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              {!stCustomer && (
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <Controller
                    name="programsData.commercialAll"
                    defaultValue={commercialAll}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Switch
                        checked={value}
                        id="commercialAllSwitch"
                        readOnly={isCommercialReadOnly}
                        onChange={(e) => {
                          onChange(e.target.checked);
                          forceUpdate();
                        }}
                        color="primary"
                        disabled={isCommercialReadOnly}
                        className={classes.switchStyles}
                        label={t('Apply to all {{stQty}} ST locations', {
                          stQty: stLocations ? stLocations?.length : 0,
                        })}
                        fullWidth={false}
                      />
                    )}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <Controller
                    name="programsData.commercialData.stCommercialLocations"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <NewLocationAssignments
                        owner={owner}
                        mode={!isCommercialReadOnly && !commercialAll ? 'edit' : 'view'}
                        all={commercialAll}
                        locations={value ? value.map((hash_key) => ({ hash_key })) : []}
                        onUpdate={(newAssignments) => {
                          onChange(newAssignments.map((na) => na.hash_key));
                        }}
                        disabled={isCommercialReadOnly && commercialData?.stCommercialLocations?.length === 0}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Panel>
        </Grid>
      )}
      {(stCustomer || btCustomer) && onCallDRData && (
        <Grid container item xs={12}>
          <Panel title={t('ON Call DR Dashboard')}>
            <Grid container spacing={2}>
              <Grid spacing={2} item xs={12}>
                <Grid item xs={12}>
                  <Controller
                    name="programsData.onCallDRData.oncall_dr_dashboard"
                    defaultValue={onCallDRData.oncall_dr_dashboard}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Switch
                        checked={value}
                        id="onCAllDRSwitch"
                        readOnly={onCallDRDashboardReadOnly}
                        onChange={(e) => {
                          onChange(e.target.checked);
                          forceUpdate();
                        }}
                        color="primary"
                        disabled={onCallDRDashboardReadOnly}
                        className={classes.switchStyles}
                        label={t('Enabled for use and access of the ON Call DR Dashboard')}
                        fullWidth={false}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              {!stCustomer && (
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                  <Controller
                    name="programsData.onCallDRAll"
                    defaultValue={onCallDRAll}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Switch
                        checked={value}
                        onChange={(e) => {
                          onChange(e.target.checked);
                          forceUpdate();
                        }}
                        color="primary"
                        readOnly={onCallDRDashboardReadOnly}
                        disabled={onCallDRDashboardReadOnly}
                        fullWidth={false}
                        className={classes.switchStyles}
                        label={t('Enable all {{stQty}} ST locations for use and access of the ON Call DR Dashboard', {
                          stQty: stLocations ? stLocations?.length : 0,
                        })}
                      />
                    )}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <Controller
                    name="programsData.onCallDRData.stOnCallLocations"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <NewLocationAssignments
                        owner={owner}
                        mode={!onCallDRDashboardReadOnly && !onCallDRAll ? 'edit' : 'view'}
                        all={onCallDRAll}
                        locations={value ? value.map((hash_key) => ({ hash_key })) : []}
                        onUpdate={(newAssignments) => {
                          onChange(newAssignments.map((na) => na.hash_key));
                        }}
                        disabled={onCallDRDashboardReadOnly && onCallDRData.stOnCallLocations?.length === 0}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Panel>
        </Grid>
      )}
    </>
  );
};
