import { auth } from 'Auth';
import gql from 'graphql-tag';
import { ITireProfile } from '../../TireProfileTypes';
import { typeTireProfile } from '../Types';

export const queryTireProfileList = gql`
  query  GetCustomerTireProfiles($hashKey: String!, $filter: String) {
    getCustomerTireProfiles(hash_key: $hashKey, filter: $filter) {
      ${typeTireProfile}
    }
  }
`;

export const queryCustomerCount = gql`
  query GetCustomerHierarchy($accountNumber: String!, $childLevel: String!) {
    getCustomerHierarchy(accountNumber: $accountNumber, childLevel: $childLevel) {
      acc_count
    }
  }
`;

export async function getTireProfiles(searchTerm: string): Promise<ITireProfile[] | null> {
  if (!auth || !auth.apolloClient) return [];

  // console.log('getTireProfiles(): searchTerm', searchTerm)
  let result: any;
  try {
    const queryResult = await auth.apolloClient.query({
      query: queryTireProfileList,
      variables: { hashKey: `1~${auth.getCustomerNumber()}`, filter: searchTerm },
      // fetchPolicy: 'cache-first',
      fetchPolicy: 'network-only',
    });
    result = queryResult;
  } catch (e) {
    console.log('Error: Tire Profiles: could not retrieve data: error:', JSON.stringify(e, null, 7));
    return null;
  }

  return result && result.data ? result.data.getCustomerTireProfiles || [] : [];
}
