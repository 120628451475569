/* eslint-disable max-len */

/* eslint-disable no-await-in-loop */

/* eslint-disable no-console */

/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';
import { DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { Button, Select, TextField, useDialogs } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { SelectOption, getOptions } from '@michelin/select-options-provider';
import { removeEmptyAttributes, removeKeys } from 'components/Util/objectUtils';
import cloneDeep from 'lodash/cloneDeep';
import { auth } from '../../../Auth';
import { createTireProfileMutation } from '../AppSync/Mutations/mutationsTireProfile';
import { createManyAssignees } from '../Assignee/GraphQL/Mutation/mutationAssignees';
import { queryTireProfileDetail } from '../AppSync/Queries/queryTireProfileDetail';
import { IAssigneeType } from '../Assignee/AssigneeType';
import { IExistingTireProfiles, ITireProfile, IUniqueTireProfile } from '../TireProfileTypes';
import { checkUniqueProfileAtLevel, getExistingProfiles } from '../Details/util';

const uuidv4 = require('uuid/v4');

interface IErrors {
  hasErrors?: boolean;
  default?: { isError: boolean; message: string };
  name?: { isError: boolean; message: string };
  type?: { isError: boolean; message: string };
  profileType?: { isError: boolean; message: string };
  applicationType?: { isError: boolean; message: string };
  vehicleType?: { isError: boolean; message: string };
  vehicleAttrsTractor?: { isError: boolean; message: string };
  vehicleAttrsTrailer?: { isError: boolean; message: string };
}

interface IProps {
  open: boolean;
  existingTireProfiles?: IExistingTireProfiles;
  cloneHashKey: string;
  handleTireProfileCloneDialogClose: Function;
}

// const vehicleTypesTractor: string[] = ['Tractor (Power Unit)', 'Truck', 'Tractor-Trailer Combination (Required for Uniform System of Accounts)'];
// const vehicleTypesTrailer: string[] = ['Semi-Trailer', 'Full Trailer', 'Tractor-Trailer Combination (Required for Uniform System of Accounts)'];
const vehicleTypesVMRSTractor: string[] = ['2', '1', '5'];
const vehicleTypesVMRSTrailer: string[] = ['3', '4', '5'];

const TireProfileCloneDialog = (props: IProps) => {
  const { errorDialog } = useDialogs();
  const { t } = useTranslation();

  const [workingData, setWorkingData] = useState<ITireProfile>();
  const [validProfileName, setValidProfileName] = useState<boolean>(true);
  const [errors, setErrors] = useState<IErrors>();
  const [validProfile, setValidProfile] = useState<boolean>(false);
  const [originalProfileName, setOriginalProfileName] = useState<string>('');
  const [newProfileHashKey, setNewProfileHashKey] = useState<string>('');
  const [profileTypeOptions, setProfileTypeOptions] = useState<SelectOption[]>();
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState<SelectOption[]>();
  const [vehicleTypesOnsite, setVehicleTypesOnsite] = useState<SelectOption[]>();
  const [vehicleTypesERS, setVehicleTypesERS] = useState<SelectOption[]>();
  const [vehicleAttrsTrailerOptions, setvehicleAttrsTrailerOptions] = useState<SelectOption[]>();
  const [applicationTypeOptions, setApplicationTypeOptions] = useState<SelectOption[]>();
  const [currentAssignments, setCurrentAssignments] = useState<IAssigneeType[]>([]);
  const [existingTireProfiles, setExistingTireProfiles] = useState<IExistingTireProfiles | undefined>();
  // const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (!props.open) return;

    const vto: SelectOption[] = getOptions('vehicle_types_onsite');
    setVehicleTypesOnsite(vto);

    const vte: SelectOption[] = getOptions('vehicle_types_ers');
    setVehicleTypesERS(vte);

    // this will be overwritten later - but in case vehicle type is picked before profile type is defined
    setVehicleTypeOptions(vto);

    const opts: SelectOption[] = getOptions('vehicle_attrs_trailer');
    opts.splice(0, 0, opts.splice(1, 1)[0]); // reorder to put All on top
    setvehicleAttrsTrailerOptions(opts);

    setValidProfile(false);

    const profileTypes: SelectOption[] = getOptions('profile_types');
    setProfileTypeOptions(profileTypes);
    setApplicationTypeOptions(getOptions('application_types'));

    getProfileData();
    // getAssignedCustomers();
    getExistingProfilesForLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  const getExistingProfilesForLocation = async () => {
    const etps: IExistingTireProfiles | undefined = await getExistingProfiles();
    setExistingTireProfiles(etps);
  };

  const getProfileData = async () => {
    if (!auth.apolloClient) return;

    try {
      const data = await auth.apolloClient.query({
        query: queryTireProfileDetail,
        variables: { hash_key: `3~${props.cloneHashKey}` },
        fetchPolicy: 'network-only',
      });

      const hash_key = uuidv4();
      setNewProfileHashKey(`3~${hash_key}`);

      setOriginalProfileName(data.data.getTireProfileDetail.main.profile_name);

      const newProfileName: string = `${t('Copy of {{profileName}}', {
        profileName: data.data.getTireProfileDetail.main.profile_name,
      })}`;

      setWorkingData({
        ...data.data.getTireProfileDetail,
        hash_key: `3~${hash_key}`,
        main: {
          ...data.data.getTireProfileDetail.main,
          profile_type: undefined,
          application_type: undefined,
          vehicle_type: undefined,
          vehicle_type_vmrs_code: undefined,
          vehicle_attrs_tractor: 'All',
          vehicle_attrs_trailer: 'All',
          default: false,
          profile_name: newProfileName,
        },
      });

      validateProfileName(newProfileName.trim());

      const locations: IAssigneeType[] = [];
      data.data.getTireProfileDetail.locations.forEach((location: any) => {
        locations.push(location);
      });
      setCurrentAssignments(locations);
    } catch (e) {
      console.log('getProfileData: error:', JSON.stringify(e, null, 7));
    }
  };

  const saveTireProfile = async (): Promise<boolean> => {
    if (!auth || !auth.apolloClient || !workingData) return false;

    const newData: any = {
      ...workingData,
      gsi1_hash_key: `1~${auth.getCustomerNumber()}`,
      gsi1_range_key: newProfileHashKey,
      gsi4_hash_key: `3~${auth.getUltimateParent().customerNumber}`,
      gsi4_range_key: `${auth.getRelationship()}`,
      main: {
        ...workingData.main,
        default: false,
      },
    };

    removeKeys(newData, ['__typename', 'id', 'update_user', 'update_date', 'owner', 'locations']);
    removeEmptyAttributes(newData);

    // check for uniqueness
    // todo probably need to check at assignments as well
    if (existingTireProfiles) {
      const uniqueProfile: IUniqueTireProfile = checkUniqueProfileAtLevel(newData, existingTireProfiles, t);
      if (!uniqueProfile.isUnique) {
        const uniqueMsg = [
          t('Profile is not unique. The combination of the following fields must be unique for each profile'),
          `• ${'Profile type'}`,
          `• ${'Application type'}`,
          `• ${'Vehicle type'}`,
          `• ${'Vehicle tractor attribute type'}`,
          `• ${'Vehicle trailer attribute type'}`,
          `• ${'Axle'}`,
          `• ${'Tire size'}`,
        ];

        errorDialog(uniqueProfile.nonUniqueMessage || uniqueMsg, t('Unique profile'), t('Ok'));
        return false;
      }
    }

    try {
      await auth.apolloClient.mutate({
        mutation: createTireProfileMutation,
        variables: {
          hash_key: newProfileHashKey,
          input: ((data: any) => {
            const temp = cloneDeep(data);
            const axles: string[] = Object.keys(temp.axles);
            axles.forEach((axle) => {
              temp.axles[axle].tire_replacement_priority.forEach((tt: any) => {
                // eslint-disable-next-line no-param-reassign
                delete tt.axleId;
              });
            });
            return temp;
          })(newData),
        },
      });

      if (!newData.main.assign_to_all_locations) saveLocationAssignments();
    } catch (e) {
      console.log(JSON.stringify(e, null, 10));
    }
    return true;
  };

  const saveLocationAssignments = async () => {
    if (!auth || !auth.apolloClient) return;
    if (!currentAssignments) return;

    const createArray: Array<IAssigneeType> = [];
    for (let i = 0; i < currentAssignments.length; i++) {
      const createRecord: IAssigneeType = refactorCreateRecord(currentAssignments[i]);
      createArray.push(createRecord);
    }

    if (createArray && createArray.length > 0) {
      for (let i = 0; i < createArray.length; i += 25) {
        try {
          await auth.apolloClient.mutate({
            mutation: createManyAssignees,
            variables: {
              input: createArray.slice(i, i + 25),
            },
          });
        } catch (e) {
          console.log(JSON.stringify(e, null, 10));
        }
      }
    }
  };

  const refactorCreateRecord = (customer: any): IAssigneeType => {
    const createRecord: IAssigneeType = {
      gsi1_hash_key: customer.gsi1_hash_key,
      gsi1_range_key: newProfileHashKey,
      hash_key: newProfileHashKey,
      range_key: customer.gsi1_hash_key,
      is_deleted: false,
    };
    return createRecord;
  };

  const cloneCloneClick = () => {
    if (!workingData) return;

    // check profile name
    if (!validateProfileName(workingData.main.profile_name.trim())) return;

    // check profile type
    if (!workingData.main.profile_type || workingData.main.profile_type.trim() === '') {
      const msg: string = t('Profile type is required');
      setErrors({
        ...errors,
        profileType: {
          isError: true,
          message: msg,
        },
      });
      return;
    }

    // check application type
    if (!workingData.main.application_type || workingData.main.application_type.trim() === '') {
      const msg: string = t('Application type is required');
      setErrors({
        ...errors,
        applicationType: {
          isError: true,
          message: msg,
        },
      });
      return;
    }

    // check vehicle type
    if (!workingData.main.vehicle_type || workingData.main.vehicle_type.trim() === '') {
      const msg: string = t('Vehicle type is required');
      setErrors({
        ...errors,
        vehicleType: {
          isError: true,
          message: msg,
        },
      });
      return;
    }

    if (validProfileName) {
      saveTireProfile().then((success) => {
        if (success) props.handleTireProfileCloneDialogClose(true);
      });
    }
  };

  const cloneCancelClick = () => {
    props.handleTireProfileCloneDialogClose(false);
  };

  const updateProfileName = (event: React.ChangeEvent<any>) => {
    saveProfileName(event);
  };

  const saveProfileName = (event: React.ChangeEvent<any>) => {
    if (!workingData) return;
    if (!validateProfileName(event.target.value)) return;

    let localProfile: ITireProfile = workingData;
    localProfile = {
      ...localProfile,
      main: {
        ...localProfile.main,
        profile_name: event.target.value,
      },
    };

    setWorkingData(localProfile);
  };

  const validateProfileName = (profileName: string): boolean => {
    // required
    if (profileName.length === 0) {
      const msg: string = t('Profile Name is required');
      setErrors({
        ...errors,
        name: {
          isError: true,
          message: msg,
        },
      });
      setValidProfileName(false);
      return false;
    }

    if (workingData) {
      let localProfile: ITireProfile = workingData;
      localProfile = {
        ...localProfile,
        main: {
          ...localProfile.main,
          profile_name: profileName,
        },
      };

      setWorkingData(localProfile);
    }

    setValidProfileName(true);
    setErrors({
      ...errors,
      name: {
        isError: false,
        message: '',
      },
    });

    return true;
  };

  const updateProfileType = (event: React.ChangeEvent<any>) => {
    setValidProfile(false);

    if (!workingData) return;

    if (workingData) {
      let localProfile: ITireProfile = workingData;
      localProfile = {
        ...localProfile,
        main: {
          ...localProfile.main,
          profile_type: event.target.value,
        },
      };

      switch (event.target.value) {
        case 'ers':
          setVehicleTypeOptions(vehicleTypesERS);
          break;
        case 'onsite':
          setVehicleTypeOptions(vehicleTypesOnsite);
          break;
        case 'ers_onsite':
          setVehicleTypeOptions(vehicleTypesOnsite);
          break;
        default:
          break;
      }

      setErrors({
        ...errors,
        profileType: {
          isError: false,
          message: '',
        },
      });

      setValidProfile(true);
      setWorkingData(localProfile);
    }
  };

  const updateApplicationType = (event: React.ChangeEvent<any>) => {
    setValidProfile(false);

    if (!workingData) return;

    if (workingData) {
      let localProfile: ITireProfile = workingData;
      localProfile = {
        ...localProfile,
        main: {
          ...localProfile.main,
          application_type: event.target.value,
        },
      };

      setErrors({
        ...errors,
        applicationType: {
          isError: false,
          message: '',
        },
      });

      setValidProfile(true);
      setWorkingData(localProfile);
    }
  };

  const updateVehicleType = (event: React.ChangeEvent<any>) => {
    setValidProfile(false);

    if (!workingData || !vehicleTypeOptions) return;

    const vehicle = vehicleTypeOptions.find((o) => o.value === event.target.value) || undefined;
    if (!vehicle) return;

    if (workingData) {
      let localProfile: ITireProfile = workingData;
      localProfile = {
        ...localProfile,
        main: {
          ...localProfile.main,
          vehicle_type: vehicle.label,
          vehicle_type_vmrs_code: event.target.value,
          vehicle_attrs_tractor: 'all',
          vehicle_attrs_trailer: 'all',
        },
      };

      setErrors({
        ...errors,
        vehicleType: {
          isError: false,
          message: '',
        },
      });

      setValidProfile(true);
      setWorkingData(localProfile);
    }
  };

  const updateVehicleAttrsTractor = (event: React.ChangeEvent<any>) => {
    setValidProfile(false);

    if (!workingData) return;

    if (workingData) {
      let localProfile: ITireProfile = workingData;
      localProfile = {
        ...localProfile,
        main: {
          ...localProfile.main,
          vehicle_attrs_tractor: event.target.value,
        },
      };

      setErrors({
        ...errors,
        vehicleAttrsTractor: {
          isError: false,
          message: '',
        },
      });

      setValidProfile(true);
      setWorkingData(localProfile);
    }
  };

  const updateVehicleAttrsTrailer = (event: React.ChangeEvent<any>) => {
    setValidProfile(false);

    if (!workingData) return;

    if (workingData) {
      let localProfile: ITireProfile = workingData;
      localProfile = {
        ...localProfile,
        main: {
          ...localProfile.main,
          vehicle_attrs_trailer: event.target.value,
        },
      };

      setErrors({
        ...errors,
        vehicleAttrsTrailer: {
          isError: false,
          message: '',
        },
      });

      setValidProfile(true);
      setWorkingData(localProfile);
    }
  };

  return (
    <>
      {workingData && (
        <Dialog
          fullWidth
          aria-labelledby="customized-dialog-title"
          open={props.open}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogTitle id="form-dialog-title">
            {t('Clone {{profileName}} Tire Profile', { profileName: originalProfileName })}
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <TextField
                  style={{ width: '100%' }}
                  required
                  id="main.profile_name"
                  label={t('New Cloned Profile Name')}
                  value={workingData.main.profile_name || ''}
                  helperText={errors && errors.name && errors.name.message}
                  onChange={(event: React.ChangeEvent<any>) => updateProfileName(event)}
                  error={!validProfileName || (errors && errors.name && errors.name.isError)}
                />
              </Grid>
              <Grid item sm={12} lg={6}>
                <Select
                  style={{ width: '100%' }}
                  required
                  id="main.profile_type"
                  name="main.profile_type"
                  value={workingData.main.profile_type || ''}
                  label={t('Profile Type')}
                  options={profileTypeOptions}
                  onChange={(event: React.ChangeEvent<any>) => updateProfileType(event)}
                  error={errors && errors.profileType && errors.profileType.isError}
                  helperText={errors && errors.profileType && errors.profileType.message}
                />
              </Grid>

              <Grid item sm={12} lg={6}>
                <Select
                  style={{ width: '100%' }}
                  required
                  name="main.application_type"
                  value={workingData.main.application_type || ''}
                  label={t('Application Type')}
                  options={applicationTypeOptions}
                  onChange={(event: React.ChangeEvent<any>) => updateApplicationType(event)}
                  error={errors && errors.applicationType && errors.applicationType.isError}
                  helperText={errors && errors.applicationType && errors.applicationType.message}
                />
              </Grid>

              <Grid item sm={12} lg={6}>
                <Select
                  style={{ width: '100%' }}
                  required
                  name="main.vehicle_type"
                  value={workingData.main.vehicle_type_vmrs_code || ''}
                  label={t('Vehicle Type')}
                  options={vehicleTypeOptions}
                  onChange={(event: React.ChangeEvent<any>) => updateVehicleType(event)}
                  error={errors && errors.vehicleType && errors.vehicleType.isError}
                  helperText={errors && errors.vehicleType && errors.vehicleType.message}
                />
              </Grid>

              <Grid item xs={12} lg={12}>
                <Grid container spacing={2}>
                  {workingData.main &&
                  vehicleTypesVMRSTractor.includes(workingData.main.vehicle_type_vmrs_code || '') ? (
                    <Grid
                      item
                      xs={12}
                      lg={
                        workingData.main &&
                        workingData.main.vehicle_type_vmrs_code &&
                        vehicleTypesVMRSTractor.includes(workingData.main.vehicle_type_vmrs_code) &&
                        workingData.main.vehicle_type_vmrs_code === '5'
                          ? 6
                          : 12
                      }
                    >
                      <Select
                        fullWidth
                        required={
                          !!(
                            workingData.main &&
                            vehicleTypesVMRSTractor.includes(workingData.main.vehicle_type_vmrs_code || '')
                          )
                        }
                        name="main.vehicle_attrs_tractor"
                        value={workingData.main.vehicle_attrs_tractor || ''}
                        label={t('Tractor Attribute')}
                        options={getOptions('vehicle_attrs_tractor').sort()}
                        readOnly={
                          !workingData.main ||
                          !vehicleTypesVMRSTractor.includes(workingData.main.vehicle_type_vmrs_code || '')
                        }
                        onChange={(event: React.ChangeEvent<any>) => updateVehicleAttrsTractor(event)}
                        error={errors && errors.vehicleAttrsTractor && errors.vehicleAttrsTractor.isError}
                        helperText={errors && errors.vehicleAttrsTractor && errors.vehicleAttrsTractor.message}
                      />
                    </Grid>
                  ) : null}

                  {workingData.main &&
                  vehicleTypesVMRSTrailer.includes(workingData.main.vehicle_type_vmrs_code || '') ? (
                    <Grid
                      item
                      xs={12}
                      lg={
                        workingData.main &&
                        workingData.main.vehicle_type_vmrs_code &&
                        vehicleTypesVMRSTrailer.includes(workingData.main.vehicle_type_vmrs_code) &&
                        workingData.main.vehicle_type_vmrs_code === '5'
                          ? 6
                          : 12
                      }
                    >
                      <Select
                        fullWidth
                        required={
                          (workingData.main &&
                            workingData.main.vehicle_type_vmrs_code &&
                            vehicleTypesVMRSTrailer.includes(workingData.main.vehicle_type_vmrs_code)) === true
                        }
                        name="main.vehicle_attrs_trailer"
                        value={workingData.main.vehicle_attrs_trailer || ''}
                        label={t('Trailer Attribute')}
                        options={vehicleAttrsTrailerOptions}
                        readOnly={
                          !workingData.main ||
                          !workingData.main.vehicle_type_vmrs_code ||
                          !vehicleTypesVMRSTrailer.includes(workingData.main.vehicle_type_vmrs_code)
                        }
                        onChange={(event: React.ChangeEvent<any>) => updateVehicleAttrsTrailer(event)}
                        error={errors && errors.vehicleAttrsTrailer && errors.vehicleAttrsTrailer.isError}
                        helperText={errors && errors.vehicleAttrsTrailer && errors.vehicleAttrsTrailer.message}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button size="small" disabled={!validProfile} style={{ margin: '8px' }} onClick={cloneCloneClick}>
              {t('Clone')}
            </Button>
            <Button size="small" color="danger" style={{ margin: '8px' }} onClick={cloneCancelClick}>
              {t('Cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default TireProfileCloneDialog;
