import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useMaestroDataSource, useTranslation } from '@michelin/central-provider';
import { DrNoteOVM } from '@michelin/fcp-view-models/dist/models/billing';
import { auth } from 'Auth';
import { billingDrNotesList } from 'components/Types/APITypes';
import { TABBED_CONTENT_HEIGHT } from 'components/Util';
import { DataGrid } from 'devextreme-react';
import { Column, Scrolling } from 'devextreme-react/data-grid';

export default function DeliveryReceiptNotes(): JSX.Element {
  const { t } = useTranslation();
  const theme = useTheme();
  const tabletView = useMediaQuery(theme.breakpoints.down(800));
  const cellView = useMediaQuery(theme.breakpoints.down(500));

  function renderNote(note: string) {
    return <div style={{ wordBreak: 'break-word' }}>{note}</div>;
  }

  const ds = useMaestroDataSource({
    API: billingDrNotesList(auth.getCustomerNumber()),
    componentPlacements: {
      filtersPlacement: tabletView ? 'outside' : 'embeded',
      searchPlacement: cellView ? 'outside' : 'embeded',
    },
    cacheKey: 'billing-dr-notes-list',
  });

  return (
    <DataGrid
      dataSource={ds.dataSource}
      style={{ height: TABBED_CONTENT_HEIGHT, border: 'none' }}
      showBorders
      wordWrapEnabled
    >
      <Scrolling mode="virtual" />
      <Column width="5%" caption={'#'} dataField={'line'} alignment={'center'} />
      <Column caption={t('Notes')} dataField={'value'} cellRender={(row: DrNoteOVM) => renderNote(row.value || '')} />
    </DataGrid>
  );
}
