import React from 'react';
import { Grid, InputAdornment } from '@material-ui/core';
import { Panel, TextField } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { getOptions } from '@michelin/select-options-provider';
import { SelectOption } from 'components/Select';
import { isValidDollarAmount, isValidPositiveInt } from 'components/Util/validate';
import { getListProfileType } from '../../Billing/Preferences/GeneralInformation';
import { Contact } from '../utils';
import { getDefaultIndex } from './utils';

interface Props {
  viewMode: boolean;
  errorFields: Set<string>;
  contact: Contact;
  updateValueHandler: (
    validateFunction?: (field: any) => boolean,
    errorField?: string,
  ) => (event: any, newValue?: any) => void;
}

export default function ApprovalLimits(props: Props): JSX.Element {
  const { viewMode: disabled, errorFields, updateValueHandler, contact } = props;

  const { t } = useTranslation();
  const tireLimitOptions: Array<SelectOption> = getOptions('contacts_approval_limits_no_of_tires');
  const wheelLimitOptions: Array<SelectOption> = getOptions('contacts_approval_limits_no_of_wheels');

  return (
    <Panel title={t("Contact's Approval Limits")}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            readOnly={disabled}
            id="num_of_tires"
            select
            label={t('No. of Tires')}
            error={errorFields.has('numOfTires')}
            defaultValue={contact.num_of_tires}
            SelectProps={{
              options: tireLimitOptions,
            }}
            onChange={updateValueHandler(isValidPositiveInt, 'numOfTires')}
            value={
              tireLimitOptions[
                getDefaultIndex(tireLimitOptions, contact.num_of_tires ? contact.num_of_tires.toString() : '')
              ].value
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            readOnly={disabled}
            id="num_of_wheels"
            select
            label={t('No. of Wheels')}
            error={errorFields.has('numOfWheels')}
            defaultValue={contact.num_of_wheels}
            SelectProps={{
              options: wheelLimitOptions,
            }}
            onChange={updateValueHandler(isValidPositiveInt, 'numOfWheels')}
            value={
              wheelLimitOptions[
                getDefaultIndex(wheelLimitOptions, contact.num_of_wheels ? contact.num_of_wheels.toString() : '')
              ].value
            }
          />
        </Grid>
        {getListProfileType() !== 'ers' ? (
          <Grid item xs={12} sm={6}>
            <TextField
              type="number"
              readOnly={disabled}
              id="tire_dollar_amount"
              label={t('Dollar Amt (Onsite services only)')}
              error={errorFields.has('tireDollarAmount')}
              defaultValue={
                contact.tire_dollar_amount && contact.tire_dollar_amount.toString().length > 0
                  ? contact.tire_dollar_amount
                  : 0
              }
              onChange={updateValueHandler(isValidDollarAmount, 'tireDollarAmount')}
              onKeyDown={(e: any) => {
                if (e.key === '-' || e.key === 'v') {
                  e.preventDefault();
                }
              }}
              InputProps={{
                inputProps: { min: 0 },
                readOnly: disabled,
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </Grid>
        ) : null}
        {/* <Grid item xs={12} sm={6}>
          <TextField
            type="number"
            readOnly={disabled}
            id="mechanical_dollar_amount"
            label={t('Dollar Amt (Mechanical services only)')}
            error={errorFields.has('mechanicalDollarAmount')}
            defaultValue={contact.mechanical_dollar_amount && contact.mechanical_dollar_amount.toString().length > 0 ? contact.mechanical_dollar_amount : 0}
            onChange={updateValueHandler(isValidDollarAmount, 'mechanicalDollarAmount')}
            onKeyDown={(e: any) => {
              if (e.key === '-' || e.key === 'v') {
                e.preventDefault();
              }
            }}
            InputProps={{
              inputProps: { min: 0 },
              readOnly: disabled,
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        </Grid> */}
      </Grid>
    </Panel>
  );
}
