/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import { Grid, Link, useMediaQuery, useTheme } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Button, IconButton, Panel, Select, Switch, useDialogs } from '@michelin/acid-components';
import { usePermissions, useTranslation } from '@michelin/central-provider';
import { SelectOption, getOptionLabel, getOptions } from '@michelin/select-options-provider';
import { DataGrid } from 'devextreme-react';
import { Column, Scrolling, Sorting, StateStoring } from 'devextreme-react/data-grid';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Actions, SOPBrandPreferences as SOPBrandPreferencesType, SOPProfile } from '../Types';
import { BrandFormModal } from './form';

interface SOPBrandsProps {
  action: Actions;
  modalState: { action: Actions; open: boolean };
  handleModalState: (action: Actions, open: boolean) => void;
}

function SOPBrandPreferences(props: SOPBrandsProps) {
  const { t } = useTranslation();
  const permissions = usePermissions();
  const { control, formState } = useFormContext<SOPProfile>();
  const { confirmDialog } = useDialogs();
  const theme = useTheme();
  const cellView = useMediaQuery(theme.breakpoints.down(500));

  const { fields, append, update, remove } = useFieldArray<SOPProfile, 'brands.items', 'key'>({
    control,
    name: 'brands.items',
    keyName: 'key',
  });

  const brandTypeOptions: SelectOption[] = [{ label: t('All'), value: 'all' }].concat(getOptions('brand_types'));
  const [rowIndex, setRowIndex] = useState<number>(0);
  const [rowId, setRowId] = useState<number>(0);
  const [brandType, setBrandType] = useState('all');
  const brandTypeFilterList = fields
    .map((field) => field.type)
    .filter((item, index, array) => array.indexOf(item) === index);

  function ReorderPriority(id: number, action: string) {
    if (action !== 'delete' && (!formState.dirtyFields.brands || !id)) return;

    const brand = fields.filter((b) => b.id === id)[0]; // new or updated brand

    if (!brand) return;

    if (action === 'edit') {
      const filterFields = fields.filter((field) => field.type === brand.type);
      const item = filterFields.filter((field) => field.priority === brand.priority && field.id !== brand.id)[0];
      let newPriority = 0;

      filterFields.forEach((b, index) => {
        if (b.priority !== index + 1) newPriority = index + 1;
      });

      if (!newPriority || !item) return;
      update(
        fields.findIndex((f) => f.id === item.id),
        { ...item, priority: newPriority },
      );
    } else {
      const filterFields = fields.filter(
        (field) => field.type === brand.type && field.priority >= brand.priority && field.id !== brand.id,
      );

      filterFields
        .sort((x, y) => x.priority - y.priority)
        .forEach((item, index) => {
          const newPriority = index + brand.priority + (action === 'delete' ? 0 : 1);
          update(
            fields.findIndex((f) => f.id === item.id),
            { ...item, priority: newPriority },
          );
        });
    }
  }

  // Function to refresh the Priority after saving a new row.
  useEffect(() => {
    ReorderPriority(rowId, props.modalState.action);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowId]);

  function RenderId(id: any, index: number) {
    const idFormat = (id || 1).toString().padStart(5, 'BP000');
    if (!permissions.allowsAction('sop.update')) return <div>{idFormat}</div>;

    return (
      <Link
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setRowIndex(index);
          props.handleModalState(props.action === 'view' ? 'view' : 'edit', true);
        }}
      >
        {idFormat}
      </Link>
    );
  }

  function ActionsCell({ brand }: { brand: any }) {
    if (!permissions.allowsAction('sop.update') || props.action === 'view') return null;
    const i = fields.indexOf(brand);
    return (
      <div style={{ minWidth: '100px' }}>
        <IconButton
          aria-label="delete"
          color="primary"
          onClick={() => {
            confirmDialog(
              t('Are you sure you want to delete this Brand Preferences?'),
              t('Delete Brand Preferences'),
              t('Yes'),
              t('No'),
              () => {
                ReorderPriority(brand.id, 'delete');
                remove(i);
              },
            );
          }}
          size="small"
        >
          <Delete fontSize="small" />
        </IconButton>
      </div>
    );
  }

  const BrandsFilters = () => (
    <>
      <Grid item xs={3}>
        <Select
          label={t('Brand Type')}
          value={brandType}
          options={brandTypeOptions.filter(
            (option) => brandTypeFilterList.includes(option.value) || option.value === 'all',
          )}
          variant="standard"
          onChange={(e: any) => setBrandType(e.target.value)}
          required
          fullWidth
        />
      </Grid>
      <Grid item xs={3}>
        {brandType && brandType.toLowerCase() === 'valve_caps' && (
          <Controller
            name="brands.require_flow_thru_caps"
            control={control}
            render={(item: any) => (
              <Switch
                checked={item.field.value === true}
                name="require_flow_thru_caps"
                onChange={(event, newValue) => item.field.onChange(newValue)}
                label={t('Require flow thru caps')}
                fullWidth={false}
              />
            )}
          />
        )}
      </Grid>
    </>
  );

  const BrandsGrid = () => (
    <Grid item xs={12}>
      <DataGrid
        dataSource={fields.filter((field) => field.type === brandType || brandType === 'all')}
        className="freespaced-table"
        showBorders
        wordWrapEnabled
        columnAutoWidth
        columnResizingMode="nextColumn"
      >
        <StateStoring enabled type="localStorage" storageKey="stg-sop-step5" />
        <Scrolling mode="virtual" />
        <Sorting mode="multiple" />
        <Column
          caption={t('Id')}
          dataType="string"
          dataField="id"
          allowSorting
          alignment="center"
          cellRender={(column: any) => RenderId(column.value, fields.indexOf(column.data))}
        />
        <Column
          caption={t('Brand Type')}
          dataType="string"
          dataField="type"
          allowSorting
          alignment="left"
          cellRender={(column: any) => getOptionLabel('brand_types', column.value)}
          defaultSortOrder="asc"
        />
        <Column
          caption={t('Brand Name')}
          dataType="string"
          dataField="brand"
          allowSorting
          alignment="left"
          cellRender={(column: any) => {
            try {
              const option = `brand_${column.data.type}` as
                | 'brand_casings'
                | 'brand_valve_caps'
                | 'brand_valve_stems'
                | 'brand_mud_flaps';
              return getOptionLabel(option, column.value);
            } catch (ex) {
              return <div style={{ color: 'red' }}>{t('Not Found')}</div>;
            }
          }}
        />
        <Column
          caption={t('Priority')}
          dataType="string"
          dataField="priority"
          allowSorting
          alignment="center"
          defaultSortOrder="asc"
        />
        <Column allowSearch={false} allowSorting={false} cellRender={(row) => <ActionsCell brand={row.data} />} />
      </DataGrid>
    </Grid>
  );

  const ActionButton = () => {
    if (props.action === 'view') return <></>;
    return (
      <Button size="small" color="primary" onClick={() => props.handleModalState('add', true)}>
        {t('Add')}
      </Button>
    );
  };

  return (
    <Panel title={cellView ? '' : t('Brand Preferences')} control={<ActionButton />}>
      <Grid container spacing={2} direction="row">
        <BrandsFilters />
        <BrandsGrid />
        <BrandFormModal
          action={props.modalState.action}
          open={props.modalState.open}
          data={(props.modalState.action === 'add' ? {} : fields[rowIndex]) as SOPBrandPreferencesType}
          list={fields}
          onSave={(e: SOPBrandPreferencesType) => {
            const cloneData = { ...e };
            const maxId = (fields.length === 0 ? 0 : Math.max(...fields.map((v) => v.id)) || 0) + 1;

            // Setting the last updated id for the ReOrder priority function.
            if (props.modalState.action === 'add') append({ ...cloneData, id: maxId });
            setRowId(maxId);
            if (props.modalState.action === 'edit') update(rowIndex, cloneData);
            setRowId(cloneData.id);

            // Refresh the action and form state
            props.handleModalState('', false);
          }}
          handleOpen={(value: boolean) => props.handleModalState('', value)}
        />
      </Grid>
    </Panel>
  );
}

export default SOPBrandPreferences;
