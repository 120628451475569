import React, { useState } from 'react';
import MomentUtils from '@date-io/moment';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useTranslation } from '@michelin/central-provider';
import moment, { Moment } from 'moment';
import 'moment/locale/fr';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 150,
    marginLeft: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

interface DatePickerProps {
  onChangeHandler: Function;
  name: string;
  dateValue: Moment | undefined;
  minDate?: Moment | undefined;
  maxDate?: Moment | undefined;
}

export default function DatePicker(props: DatePickerProps) {
  const classes = useStyles();

  const { t, i18n } = useTranslation();

  const [selectedDate, handleDateChange] = useState(props.dateValue);
  const [minDate] = useState(props.minDate);
  const [maxDate] = useState(props.maxDate);
  moment.locale(i18n.language.replace(/_.+/, ''));
  return (
    <form className={classes.container} noValidate>
      <MuiPickersUtilsProvider libInstance={moment} locale={i18n.language.replace(/_.+/, '')} utils={MomentUtils}>
        <KeyboardDatePicker
          label={t(props.name)}
          value={selectedDate}
          onChange={(event: any) => {
            handleDateChange(event);
            props.onChangeHandler(event);
          }}
          format="MM/DD/YYYY"
          maxDate={maxDate || moment().toString()}
          minDate={minDate}
          cancelLabel={t('Cancel')}
          okLabel={t('Ok')}
          clearLabel={t('Clear')}
          todayLabel={t('Today')}
        />
      </MuiPickersUtilsProvider>
    </form>
  );
}
