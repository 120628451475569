import React, { useEffect, useState } from 'react';
import { AppBar, Container, Panel, Tab, TabPanel, Tabs } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import LastModified from 'components/ChangeLog/LastModified';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { useHistory } from 'react-router-dom';
import { getBaseUrl } from '../../prefs-and-service-offers';
import { SOPProcedures } from './Procedures';
import SOPList from './SOPList';
import { SOPListProps } from './Types';

export default function SOPMain(props: SOPListProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const [tabValue, setTabValue] = useState<number>(0);
  const [entity, setEntity] = useState<string>('sops');

  function onTabChange(event: React.ChangeEvent<{}>, newValue: number) {
    let tabURL;

    switch (newValue) {
      case 0:
        tabURL = 'sop/list';
        break;
      case 1:
        tabURL = 'sop/procedures/view';
        break;
      default:
        tabURL = 'sop/list';
        break;
    }

    if (tabURL) history.push(`${getBaseUrl()}/${tabURL}`);
  }

  useEffect(() => {
    if (props.action === 'procedures-view' || props.action === 'procedures-edit') {
      setTabValue(1);
      setEntity('sop_notes');
    } else {
      setTabValue(0);
      setEntity('sop');
    }
  }, [props.action]);

  return (
    <>
      <PageTitle title={t('Standard Operating Procedures')} rightItems={<LastModified entity={entity} />} />
      <Container>
        <Panel spacing={0}>
          <AppBar color="primary" position="static" style={{ height: 48 }}>
            <Tabs
              orientation="horizontal"
              variant="fullWidth"
              value={tabValue}
              onChange={onTabChange}
              aria-label={`${t('Standard Operating Procedures')}`}
            >
              <Tab style={{ minWidth: 200 }} label={`${t('SOP Profiles')}`} />
              <Tab style={{ minWidth: 330 }} label={`${t('Tire Service Procedures')}`} />
            </Tabs>
          </AppBar>
          <TabPanel value={tabValue} index={0}>
            <SOPList />
          </TabPanel>

          <TabPanel value={tabValue} index={1} spacing={0}>
            <SOPProcedures mode={props.action === 'procedures-view' ? 'view' : 'edit'} />
          </TabPanel>
        </Panel>
      </Container>
    </>
  );
}
