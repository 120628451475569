import React from 'react';
import { Add, Cancel, NavigateBefore, NavigateNext, Save } from '@material-ui/icons';
import { Fab } from '@michelin/acid-components';
import { auth } from 'Auth';
import { gql } from 'apollo-boost';
import {
  ERS_MANAGER_ROLE_AREA_VALUE,
  PO_ISSUER_ROLE_AREA_VALUE,
  PRIMARY_LEVEL_VALUE,
  SECONDARY_LEVEL_VALUE,
  TIRE_SERVICE_VALUE,
  getContactTypesRoleAreaLabelFromValue,
} from 'components/Contact/ContactDetails/utils';
import { Contact } from 'components/Contact/utils';
import { TranslateCallback } from 'components/Util';
import { BillingProfileLocation } from '../query';
import { BillingReassignContactType, State } from './dialogs';

export const missingCriticalRolesText = (
  alreadyAssignedLocations: number,
  totalLocations: number,
  contactLevel: string,
  contactTypes: Array<string>,
  t: Function,
) => {
  const mappedContactTypes = contactTypes
    .map((x) => `${t('Tire')} ${getContactTypesRoleAreaLabelFromValue(x, t as TranslateCallback)}`)
    .join(', ');
  const mapContactLevel = new Map<string, string>();
  mapContactLevel.set('BT', 'Bill To');
  mapContactLevel.set('ST', 'Ship To');
  mapContactLevel.set('HO', 'Home Office');
  mapContactLevel.set('PC', 'Parent Company');

  return (
    <p>
      {t(
        '{{alreadyAssignedLocations}} out of {{totalLocations}} "{{contactLevel}}" Locations are missing contacts that have the contact type roles(s) of: "{{contectTypes}}".',
        {
          alreadyAssignedLocations,
          totalLocations,
          contactLevel: t(mapContactLevel.get(contactLevel)),
          contectTypes: t(mappedContactTypes),
        },
      )}
      <br />
      {t(
        'Do you wish to update the contacts for each of these locations to ensure that these roles are available for the Billing profile?',
      )}
      <br />
      <br />
      {t('Click')}
      <b>{` ${t('Update').toUpperCase()} `}</b>
      {t('to update the contacts for the location(s).')}
      <br />
      {t('Click')}
      <b>{` ${t('Close').toUpperCase()} `}</b>
      {t('to return to the page.')}
    </p>
  );
};

export const missingCriticalRolesTitle = 'Locations Missing Critical Roles';

/*  FABS  */
export function CancelFab(props: { onClick: () => void; t: Function; style?: React.CSSProperties }): JSX.Element {
  const { onClick, style, t } = props;

  return (
    <Fab
      color="primary"
      label={t('Cancel')}
      style={style}
      onClick={() => {
        onClick();
      }}
    >
      <Cancel />
    </Fab>
  );
}

export function AddFab(props: {
  onClick: () => void;
  t: Function;
  disabled?: boolean;
  style?: React.CSSProperties;
}): JSX.Element {
  const { onClick, disabled, style, t } = props;
  return (
    <Fab
      color="primary"
      label={t('Add')}
      style={style}
      disabled={disabled}
      onClick={() => {
        onClick();
      }}
    >
      <Add />
    </Fab>
  );
}

export function SaveFab(props: { onClick: () => void; t: Function; style?: React.CSSProperties }): JSX.Element {
  const { onClick, style, t } = props;

  return (
    <Fab
      color="primary"
      label={t('Save')}
      style={style}
      onClick={() => {
        onClick();
      }}
    >
      <Save />
    </Fab>
  );
}

export function NextFab(props: { onClick: () => void; t: Function; style?: React.CSSProperties }): JSX.Element {
  const { onClick, style, t } = props;

  return (
    <Fab
      color="primary"
      label={t('Next')}
      style={style}
      onClick={() => {
        onClick();
      }}
    >
      <NavigateNext />
    </Fab>
  );
}

export function BackFab(props: { onClick: () => void; t: Function; style?: React.CSSProperties }): JSX.Element {
  const { onClick, style, t } = props;

  return (
    <Fab
      color="primary"
      label={t('Back')}
      style={style}
      onClick={() => {
        onClick();
      }}
    >
      <NavigateBefore />
    </Fab>
  );
}

function getChildren(
  location: BillingProfileLocation,
  allLocations: BillingProfileLocation[],
): BillingProfileLocation[] {
  switch (location.customer_type) {
    case 'PC':
      return allLocations.filter((l) => l.parent_company_number === location.customer_number);
    case 'HO':
      return allLocations.filter((l) => l.home_office_number === location.customer_number);
    case 'BT':
      return allLocations.filter((l) => l.bill_to_customer === location.customer_number);
    default:
      return [location];
  }
}

function getParents(
  location: BillingProfileLocation,
  allLocations: BillingProfileLocation[],
): BillingProfileLocation[] {
  let parent: BillingProfileLocation | undefined;
  switch (location.customer_type) {
    case 'HO':
      parent = allLocations.find((l) => l.customer_number === location.parent_company_number);
      break;
    case 'BT':
      parent = allLocations.find((l) => l.customer_number === location.home_office_number);
      break;
    case 'ST':
      parent = allLocations.find((l) => l.customer_number === location.bill_to_customer);
      break;
    default:
      break;
  }
  if (!parent) return [];
  return [parent, ...getParents(parent, allLocations)];
}

export function getChildrenAndParentsFromArray(
  locations: BillingProfileLocation[],
  allLocations: BillingProfileLocation[],
): BillingProfileLocation[] {
  const res: BillingProfileLocation[] = [];
  const included = new Map<string, boolean>();
  locations.forEach((l) => {
    [...getParents(l, allLocations), ...getChildren(l, allLocations)].forEach((c) => {
      if (included.has(c.customer_number)) return;
      included.set(c.customer_number, true);
      res.push(c);
    });
  });
  return res;
}

export interface LocationsWithPrimaryAndSecondary {
  neededToAssignContactLocations: BillingProfileLocation[];
  filteredLocationsByLevel: BillingProfileLocation[];
  levelsPerLocationHashKey: Map<string, Map<string, Contact>>;
  comboPerLocationHashKey: Map<string, Contact>;
}

export function setLocationsWithPrimaryAndSecondary(
  contactLevel: string,
  contacts: Array<Contact>,
  locations: BillingProfileLocation[],
  contactTypes: string[],
  assignedContactTypes: Map<string, BillingReassignContactType>,
  contactTypeLevelToCheck: string,
): LocationsWithPrimaryAndSecondary {
  let neededToAssignContactLocations: BillingProfileLocation[] = [];
  const filteredLocationsByLevel = locations.filter((x) => contactLevel === x.customer_type);
  const locationsAlreadyAssigned = new Set<string>();
  const contacTypesSet = new Set(contactTypes);
  const filteredLocationsSet = new Set(filteredLocationsByLevel.map((x) => x.hash_key));
  const levelsPerLocationHashKey = new Map<string, Map<string, Contact>>();
  const comboPerLocationHashKey = new Map<string, Contact>();

  contacts.forEach((contact) => {
    if (!contact || contact.contact_level !== contactLevel) return;
    if (!contact.contact_types) return;
    contact.contact_types.forEach((contactType) => {
      if (contactType.service !== TIRE_SERVICE_VALUE || !contactType.role_areas) return;
      contactType.role_areas.forEach((contactTypeRoleArea) => {
        if (!contacTypesSet.has(contactTypeRoleArea.role_area)) return;
        contactTypeRoleArea.levels.forEach((contactTypeLevel) => {
          if (contactTypeLevelToCheck !== contactTypeLevel.level) return;
          if (!filteredLocationsSet.has(contactTypeLevel.location)) return;
          const isAlreadyAddedLocation = levelsPerLocationHashKey.get(contactTypeLevel.location);
          comboPerLocationHashKey.set(contactTypeLevel.location + contactTypeRoleArea.role_area, contact);
          if (isAlreadyAddedLocation) {
            isAlreadyAddedLocation.set(contact.hash_key, contact);
            levelsPerLocationHashKey.set(contactTypeLevel.location, isAlreadyAddedLocation);
          } else {
            const newMapContactLocation = new Map();
            newMapContactLocation.set(contact.hash_key, contact);
            levelsPerLocationHashKey.set(contactTypeLevel.location, newMapContactLocation);
          }
          locationsAlreadyAssigned.add(contactTypeLevel.location);
        });
      });
    });
  });

  assignedContactTypes.forEach((x) => {
    if (contacTypesSet.has(x.roleArea)) {
      const locationHashKey = `1~${x.location.customer_number}`;

      const isAlreadyAddedLocation = levelsPerLocationHashKey.get(locationHashKey);
      if (isAlreadyAddedLocation) {
        isAlreadyAddedLocation.set(x.contact.hash_key, x.contact as any);
        levelsPerLocationHashKey.set(locationHashKey, isAlreadyAddedLocation);
      } else {
        const newMapContactLocation = new Map();
        newMapContactLocation.set(x.contact.hash_key, x.contact);
        levelsPerLocationHashKey.set(locationHashKey, newMapContactLocation);
      }

      locationsAlreadyAssigned.add(locationHashKey);
      comboPerLocationHashKey.set(locationHashKey + x.roleArea, x.contact as any);
    }
  });

  neededToAssignContactLocations = filteredLocationsByLevel.filter(
    (location) => !locationsAlreadyAssigned.has(location.hash_key),
  );

  return {
    neededToAssignContactLocations,
    filteredLocationsByLevel,
    levelsPerLocationHashKey,
    comboPerLocationHashKey,
  };
}

export function getContactTypesFromContacts(location: BillingProfileLocation, contacts: Array<BillingReassignContact>) {
  const contactTypesPerLocation = new Map<string, BillingReassignContact>();

  contacts.forEach((contact) => {
    const { contact_types } = contact;
    if (contact_types) {
      contact_types.forEach((contactType) => {
        const { role_areas, service } = contactType;
        if (service === TIRE_SERVICE_VALUE && role_areas) {
          role_areas.forEach((contactTypeRoleArea) => {
            const { levels, role_area } = contactTypeRoleArea;
            if ((role_area === ERS_MANAGER_ROLE_AREA_VALUE || role_area === PO_ISSUER_ROLE_AREA_VALUE) && levels) {
              levels.forEach((contactTypeLevel) => {
                const { level, location: locationHashKey } = contactTypeLevel;

                if (
                  location.hash_key === locationHashKey &&
                  (level === PRIMARY_LEVEL_VALUE || level === SECONDARY_LEVEL_VALUE)
                ) {
                  contactTypesPerLocation.set(`${role_area}~${level}`, contact);
                }
              });
            }
          });
        }
      });
    }
  });

  return contactTypesPerLocation;
}

/* QUERY */
const contactRelationshipsQuery = gql`
  query ContactLocationRelationship($locations: [String]!) {
    getContactLocationsRelationships(locations: $locations) {
      hash_key
      range_key
      is_deleted
      contact {
        hash_key
        first_name
        last_name
        contact_types {
          id
          service
          role_areas {
            role_area
            levels {
              location
              level
            }
          }
        }
        cell_phone
        work_phone
        ext
        email_address
        preferred_method
        job_title
        contact_level
      }
    }
  }
`;

export type BillingReassignContact = Pick<
  Contact,
  | 'hash_key'
  | 'first_name'
  | 'last_name'
  | 'email_address'
  | 'work_phone'
  | 'cell_phone'
  | 'preferred_method'
  | 'contact_types'
  | 'contact_level'
>;

export async function getLocationContacts(locationNumber: string): Promise<Array<BillingReassignContact>> {
  const apolloClient = auth.apolloClient;

  if (apolloClient) {
    try {
      const queryData = await apolloClient.query({
        query: contactRelationshipsQuery,
        variables: {
          locations: [locationNumber],
        },
        fetchPolicy: 'no-cache',
      });

      const contacts =
        queryData.data.getContactLocationsRelationships &&
        queryData.data.getContactLocationsRelationships
          .filter((x: any) => !x.is_deleted && x.contact)
          .map((x: any) => x.contact);

      return contacts;
    } catch (e) {
      console.error('Error requesting contacts', e);
    }
  }

  return [];
}

/* STATE HANDLING */
export function resetState(): State {
  return {
    currentIndex: undefined,
    firstSelectedLocationIndex: undefined,
    newAssignedContactTypes: new Map(),
    createContactMode: false,
    removedAssignedContactTypes: new Set(),
  };
}
