import React, { FunctionComponent } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Table, TableCell, TableHead, TableRow } from '@material-ui/core';
import { LoadingBounce } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { queryTireProfileList } from '../../queries';
import InventoryItem from './inventoryItem';

export type TireOrderingMap = {
  [key: string]: string;
};

type InventoryProps = {
  editFlag: boolean;
  map: TireOrderingMap;
  editMap: Function;
  customerNumber: string;
};

export type TirePriorities = {
  tire_type: string;
  tread_design: string;
  tire_size: string;
  brand: string;
  load_range: string;
  mspn: string;
};

type TireDetails = {
  getCustomerTireProfiles: Axles[];
};

type Axles = {
  axles: Axle;
};

type Axle = {
  all_axles: AxleDetail;
  carry: AxleDetail;
  drive: AxleDetail;
  lift: AxleDetail;
  pusher: AxleDetail;
  steer: AxleDetail;
  tag: AxleDetail;
  trailer: AxleDetail;
};

type AxleDetail = {
  tire_replacement_priority: TirePriorities[];
};

const isNotEmpty = (s: string) => {
  if (s && /^\S+/i.test(s.trim())) {
    return true;
  }
  return false;
};

const getPriorities = (tireDetails: Axles[]): TirePriorities[] => {
  const ret: TirePriorities[] = [];
  tireDetails.forEach((detail) => {
    if (detail.axles.carry && detail.axles.carry.tire_replacement_priority.length > 0) {
      detail.axles.carry.tire_replacement_priority.forEach((priority) => {
        if (/michelin|bfg|uniroyal|mrt|megamile|oliver/i.test(priority.brand)) {
          ret.push(priority);
        }
      });
    }
    if (detail.axles.drive && detail.axles.drive.tire_replacement_priority.length > 0) {
      detail.axles.drive.tire_replacement_priority.forEach((priority) => {
        if (/michelin|bfg|uniroyal|mrt|megamile|oliver/i.test(priority.brand)) {
          ret.push(priority);
        }
      });
    }
    if (detail.axles.lift && detail.axles.lift.tire_replacement_priority.length > 0) {
      detail.axles.lift.tire_replacement_priority.forEach((priority) => {
        if (/michelin|bfg|uniroyal|mrt|megamile|oliver/i.test(priority.brand)) {
          ret.push(priority);
        }
      });
    }
    if (detail.axles.pusher && detail.axles.pusher.tire_replacement_priority.length > 0) {
      detail.axles.pusher.tire_replacement_priority.forEach((priority) => {
        if (/michelin|bfg|uniroyal|mrt|megamile|oliver/i.test(priority.brand)) {
          ret.push(priority);
        }
      });
    }
    if (detail.axles.steer && detail.axles.steer.tire_replacement_priority.length > 0) {
      detail.axles.steer.tire_replacement_priority.forEach((priority) => {
        if (/michelin|bfg|uniroyal|mrt|megamile|oliver/i.test(priority.brand)) {
          ret.push(priority);
        }
      });
    }
    if (detail.axles.tag && detail.axles.tag.tire_replacement_priority.length > 0) {
      detail.axles.tag.tire_replacement_priority.forEach((priority) => {
        if (/michelin|bfg|uniroyal|mrt|megamile|oliver/i.test(priority.brand)) {
          ret.push(priority);
        }
      });
    }
    if (detail.axles.trailer && detail.axles.trailer.tire_replacement_priority.length > 0) {
      detail.axles.trailer.tire_replacement_priority.forEach((priority) => {
        if (/michelin|bfg|uniroyal|mrt|megamile|oliver/i.test(priority.brand)) {
          ret.push(priority);
        }
      });
    }
    if (detail.axles.all_axles && detail.axles.all_axles.tire_replacement_priority.length > 0) {
      detail.axles.all_axles.tire_replacement_priority.forEach((priority) => {
        if (/michelin|bfg|uniroyal|mrt|megamile|oliver/i.test(priority.brand)) {
          ret.push(priority);
        }
      });
    }
  });

  ret.sort((a: any, b: any) => {
    if (isNotEmpty(a.mspn) && isNotEmpty(b.mspn)) {
      if (a.mspn < b.mspn) return -3;
      return 3;
    }
    if (isNotEmpty(a.mspn) && !isNotEmpty(b.mspn)) {
      return -2;
    }
    if (!isNotEmpty(a.mspn) && isNotEmpty(b.mspn)) {
      return 2;
    }
    if (a.tire_size < b.tire_size) {
      return -1;
    }
    return 1;
  });
  const hash: any = {};
  return ret.filter((x: any) => {
    if (
      hash[
        String(x.tire_type).trim() +
          String(x.brand).trim() +
          String(x.tire_size).trim() +
          String(x.tread_design).trim() +
          String(x.load_range).trim() +
          String(x.mspn).trim()
      ]
    ) {
      return false;
    }
    hash[
      String(x.tire_type).trim() +
        String(x.brand).trim() +
        String(x.tire_size).trim() +
        String(x.tread_design).trim() +
        String(x.load_range).trim() +
        String(x.mspn).trim()
    ] = 1;
    return !/no preference/i.test(x.tread_design);
  });
};

const getKey = (priority: TirePriorities): string => {
  if (/new/i.test(priority.tire_type)) return priority.mspn;
  return `${priority.brand}#${priority.tire_size}#${priority.tread_design}`;
};

const getLevel = (key: string, map: TireOrderingMap): string => {
  if (key in map) return map[key];
  return '';
};

export const Inventory: FunctionComponent<InventoryProps> = (props) => {
  const { editFlag, map, editMap, customerNumber } = props;
  const { t } = useTranslation();
  const { loading, data } = useQuery<TireDetails>(queryTireProfileList, {
    variables: {
      hashKey: customerNumber,
      filter: 'onsite',
    },
    fetchPolicy: 'no-cache',
  });

  if (loading) return <LoadingBounce />;

  const tireProfilePriorities: TirePriorities[] = getPriorities(data ? data.getCustomerTireProfiles : []);

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell align="left">{t('Minimum Inventory Levels')}</TableCell>
          <TableCell align="left">{t('MSPN')}</TableCell>
          <TableCell align="left">{t('Tire Type')}</TableCell>
          <TableCell align="left">{t('Brand')}</TableCell>
          <TableCell align="left">{t('Tire Size')}</TableCell>
          <TableCell align="left">{t('Tread Design')}</TableCell>
          <TableCell align="left">{t('Load Range')}</TableCell>
        </TableRow>
        {tireProfilePriorities.map((priority) => {
          const keyFromMap = getKey(priority);

          return (
            <InventoryItem
              editFlag={editFlag}
              priority={priority}
              inventoryLevel={getLevel(keyFromMap, map)}
              key={`invItem${keyFromMap}`}
              onChange={(val: string) => {
                editMap(keyFromMap, val, map);
              }}
            />
          );
        })}
      </TableHead>
    </Table>
  );
};
