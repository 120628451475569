import { MaestroAPI, maestroApiCall } from '@michelin/central-provider';
import { MethodType } from '@michelin/central-provider/dist/helpers/createAPICall';
import { LocationProgramsOVM, ServiceProviderOVM } from '@michelin/fcp-view-models';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import GLOBAL_CONFIG from '../global-variables';

const baseURL = GLOBAL_CONFIG.externalAPIUrl;

interface UpdateLocationProgramsParams {
  fleetId: string | undefined;
  data: {
    urban_admin_ship_to_numbers?: string[];
    urban_admin_assign_to_all?: boolean;
    urban_admin_allow_create_vehicle?: boolean;
    urban_admin_allow_preapproved_txs?: boolean;
    urban_admin_preapproved_txs_limit_amount?: string;
    urban_admin_preapproved_txs_no_limits?: boolean;
    isUrban?: boolean;
    oncall_dr_dashboard_assign_to_all?: boolean;
    oncall_dr_dashboard_ship_to_numbers?: string[];
    oncall_sds_dashboard?: boolean;
    commercial_assign_to_all?: boolean;
    commercial_ship_to_numbers?: string[];
    is_commercial?: boolean;
  };
}

// eslint-disable-next-line max-len
const updateLocationPrograms = async ({
  fleetId,
  data,
}: UpdateLocationProgramsParams): Promise<{ customer: {}; message: string; saved: boolean }> => {
  if (!fleetId || !data) throw new Error('Invalid params');
  const apiType: MaestroAPI = { method: 'PUT', endpoint: `cp-be/locations/${fleetId}`, baseURL };
  const res = await maestroApiCall(apiType, data);
  if (!res.saved) {
    throw Error(res.message);
  }
  return res;
};

export function useUpdateLocationPrograms({ fleetId }: { fleetId: string | undefined }) {
  const queryClient = useQueryClient();
  return useMutation(updateLocationPrograms, {
    onSuccess: () => {
      queryClient.invalidateQueries(['getLocationsPrograms', fleetId]);
    },
  });
}

const getLocationPrograms = async (fleetId: string): Promise<LocationProgramsOVM | null> => {
  if (!fleetId) return null;
  const apiType: MaestroAPI = {
    method: MethodType.GET,
    endpoint: `cp-be/locations/${fleetId}/programs`,
    baseURL: baseURL || '',
  };
  const response = await maestroApiCall<LocationProgramsOVM>(apiType);
  return response as LocationProgramsOVM;
};

export function useGetLocationPrograms(fleetId: string) {
  return useQuery<LocationProgramsOVM | null>(['getLocationsPrograms', fleetId], () => {
    return getLocationPrograms(fleetId);
  });
}

const getServiceProvidersList = async (
  fleetId: string,
  isUrban: boolean,
  state: string,
  city: string,
  query: string,
  isCommercial: boolean,
  openFlag: boolean,
): Promise<{ data: ServiceProviderOVM[]; total: number } | null> => {
  if (!fleetId || !openFlag) return null;
  const apiType: MaestroAPI = {
    method: MethodType.GET,
    endpoint: `/cp-be/locations/${fleetId}/provider-options?is_urban=${isUrban}&is_commercial=${isCommercial}&state=${state}&city=${city}&query=${query}`,
    baseURL: baseURL || '',
  };
  const response = await maestroApiCall<{ data: ServiceProviderOVM[]; total: number }>(apiType);
  return response;
};
export function useGetServiceProvidersOptionsList({
  fleetId,
  isUrban,
  state,
  city,
  query,
  isCommercial,
  openFlag,
}: {
  fleetId: string;
  isUrban: boolean;
  state: string;
  city: string;
  query: string;
  isCommercial: boolean;
  openFlag: boolean;
}) {
  return useQuery<{ data: ServiceProviderOVM[]; total: number } | null>(['serviceProvidersOptionsList'], () => {
    return getServiceProvidersList(fleetId, isUrban, state, city, query, isCommercial, openFlag);
  });
}
