import React, { useContext, useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Button } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { BillingProfileContext, ReassignContactTypesState } from '..';
import PORequiredManageDialog from '../PurchasingProcedures/Dialogs/poRequiredManageDialog';
import MissingCriticalRolesDialog, { BillingReassignContactType } from './dialogs';
import {
  LocationsWithPrimaryAndSecondary,
  missingCriticalRolesText,
  missingCriticalRolesTitle,
  setLocationsWithPrimaryAndSecondary,
} from './utils';

interface Props {
  reassignContactTypesState: ReassignContactTypesState;
  onClose: (newAssignedContactTypes: Map<string, BillingReassignContactType>) => void;
  display: boolean;
  setModifiedFlag: Function;
}

export default function ReassignContactTypes(props: Props): JSX.Element {
  const { onClose, reassignContactTypesState, display, setModifiedFlag } = props;
  const { t } = useTranslation();
  const { contactTypes, contactLevel, manualClick, contactTypeLevel } = reassignContactTypesState;

  const { contacts, expLocations, assignedContactTypes } = useContext(BillingProfileContext);

  const [
    { neededToAssignContactLocations, filteredLocationsByLevel, levelsPerLocationHashKey, comboPerLocationHashKey },
    setLocationsWPAS,
  ] = useState<LocationsWithPrimaryAndSecondary>({
    neededToAssignContactLocations: [],
    filteredLocationsByLevel: [],
    levelsPerLocationHashKey: new Map(),
    comboPerLocationHashKey: new Map(),
  });

  useEffect(() => {
    setLocationsWPAS(
      setLocationsWithPrimaryAndSecondary(
        reassignContactTypesState.contactLevel,
        contacts,
        expLocations,
        reassignContactTypesState.contactTypes,
        assignedContactTypes,
        reassignContactTypesState.contactTypeLevel,
      ),
    );
  }, [
    reassignContactTypesState.contactLevel,
    contacts,
    expLocations,
    reassignContactTypesState.contactTypes,
    assignedContactTypes,
    reassignContactTypesState.contactTypeLevel,
  ]);

  const [open, setOpen] = useState(false);

  const criticalText = missingCriticalRolesText(
    neededToAssignContactLocations.length,
    filteredLocationsByLevel.length,
    contactLevel,
    contactTypes,
    t,
  );
  const onSave = (newAssignedContactTypes: Map<string, BillingReassignContactType>) => {
    setOpen(false);
    setModifiedFlag(true);
    onClose(newAssignedContactTypes);
  };

  if (display && manualClick && neededToAssignContactLocations.length === 0) {
    return (
      <PORequiredManageDialog
        contactTypeLevel={contactTypeLevel}
        levelsPerLocationHashKey={levelsPerLocationHashKey}
        comboPerLocationHashKey={comboPerLocationHashKey}
        contactTypes={contactTypes}
        open
        onCloseHandler={() => onClose(assignedContactTypes)}
      />
    );
  }
  return (
    <>
      <Dialog open={display && !open && neededToAssignContactLocations.length > 0}>
        <DialogTitle>{t(missingCriticalRolesTitle)}</DialogTitle>
        <DialogContent>{criticalText}</DialogContent>
        <DialogActions>
          <Button color="success" onClick={() => setOpen(true)}>
            {t('Update')}
          </Button>
          <Button color="danger" onClick={() => onClose(assignedContactTypes)}>
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
      <MissingCriticalRolesDialog
        open={open}
        locations={neededToAssignContactLocations}
        onCancel={() => {
          setOpen(false);
          onClose(assignedContactTypes);
        }}
        onSave={onSave}
        alreadyAssignedContactTypes={assignedContactTypes}
        contactTypes={contactTypes}
        contactTypeLevel={contactTypeLevel}
      />
    </>
  );
}
