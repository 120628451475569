import React from 'react';
import { Delete, Edit } from '@material-ui/icons';
import { Button, IconButton, Panel } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { getOptionLabel } from '@michelin/select-options-provider';
import { DataGrid } from 'devextreme-react';
import { Column, Scrolling } from 'devextreme-react/data-grid';
import { NotificationSubscription } from '../utils';
import { getNotificationSubscriptionLabelFromValue } from './utils';

/* RENDER METHODS */
export function renderNotificationType(event: string, type: string): JSX.Element {
  if (!type || type === '') return <div>-</div>;
  let label = '';
  switch (event) {
    case 'profile':
      label = getOptionLabel('notification_subscription_types_profile', type);
      break;
    case 'tire_ers':
      label = getOptionLabel('notification_subscription_types_tire_ers', type);
      break;
    case 'bulk_order':
      label = getOptionLabel('notification_subscription_types_bulk_order', type);
      break;
    case 'urban_fleet_ordering':
      label = getOptionLabel('notification_subscription_types_urban_fleet_ordering', type);
      break;
    case 'mechanical_care':
      label = getOptionLabel('notification_subscription_types_mechanical_care', type);
      break;
    default:
      label = getOptionLabel('notification_subscription_empty_options', type);
  }

  return <div>{label}</div>;
}

function renderCCEmails(rowData: any, messageDialog: any, t: Function): JSX.Element {
  if (!rowData || rowData.length === 0) return <div>-</div>;
  if (rowData.length === 1) return <div>{rowData}</div>;
  return (
    <div
      style={{ cursor: 'pointer' }}
      onClick={() => {
        messageDialog(rowData, t('CC Emails'));
      }}
    >
      <b>{t('{{emailsCount}} Emails', { emailsCount: rowData.length })}</b>
    </div>
  );
}

function renderActionButtons(
  rowData: any,
  viewMode: boolean,
  onSubscriptionEdited: (rowData: any) => void,
  onSubscriptionDeleted: (rowData: any) => void,
): JSX.Element {
  return (
    <div>
      <IconButton
        aria-label="edit"
        style={{ margin: '8px', visibility: viewMode ? 'hidden' : 'unset' }}
        onClick={() => onSubscriptionEdited(rowData)}
        size="small"
        id={`${rowData.event}${rowData.type}editNotificationSubscriptionButton`}
      >
        <Edit fontSize="small" />
      </IconButton>
      <IconButton
        aria-label="delete"
        style={{ margin: '8px', visibility: viewMode ? 'hidden' : 'unset' }}
        onClick={() => onSubscriptionDeleted(rowData)}
        size="small"
        hidden={viewMode}
        disabled={viewMode}
        id={`${rowData.event}${rowData.type}deleteNotificationSubscriptionButton`}
      >
        <Delete fontSize="small" />
      </IconButton>
    </div>
  );
}

interface Props {
  viewMode: boolean;
  notificationSubscriptions: Array<NotificationSubscription>;
  handleAddNotificationButton: () => void;
  onSubscriptionEdited: (rowData: any) => void;
  onSubscriptionDelete: (rowData: any) => void;
  messageDialog: Function;
}

export default function NotificationSubscriptions(props: Props): JSX.Element {
  const {
    viewMode: disabled,
    notificationSubscriptions: ns,
    handleAddNotificationButton,
    onSubscriptionEdited,
    onSubscriptionDelete,
    messageDialog,
  } = props;
  const { t } = useTranslation();
  const noDataMessage = t('No notification subcription available to display.');

  return (
    <Panel
      title={t('Notification Preferences')}
      control={
        <Button
          disabled={disabled}
          id="addNotificationButton"
          size="small"
          style={{ margin: '8px' }}
          onClick={() => handleAddNotificationButton()}
        >
          {t('Add')}
        </Button>
      }
    >
      <DataGrid
        dataSource={ns}
        allowColumnReordering
        allowColumnResizing
        style={{ maxHeight: '75vh' }}
        noDataText={noDataMessage}
      >
        <Scrolling mode="virtual" />
        <Column
          caption="Event"
          allowFiltering={false}
          cellRender={({ data }: any) =>
            data.event ? getOptionLabel('notification_subscription_events', data.event) : ''
          }
        />
        <Column
          caption={t('Notification Type')}
          allowFiltering={false}
          cellRender={({ data }: any) => renderNotificationType(data.event, data.type)}
        />
        <Column
          caption={t('Notification Method')}
          allowFiltering={false}
          cellRender={({ data }: any) =>
            data.method
              ? data.method.map((x: string) => getNotificationSubscriptionLabelFromValue(x, t)).join(', ')
              : '-'
          }
        />
        <Column
          caption={t('CC Emails')}
          allowFiltering={false}
          cellRender={({ data }: any) => renderCCEmails(data.cc_emails, messageDialog, t)}
        />
        <Column
          caption=""
          allowFiltering={false}
          cellRender={({ data }: any) =>
            renderActionButtons(data, disabled, onSubscriptionEdited, onSubscriptionDelete)
          }
        />
      </DataGrid>
    </Panel>
  );
}
