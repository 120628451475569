import React from 'react';
import { Grid } from '@material-ui/core';
import { Panel, Switch } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { TireProfileOVM } from '@michelin/fcp-view-models';
import { Actions, AxleName } from 'modules/Tires/types';
import { Controller, useFormContext } from 'react-hook-form';

type props = {
  axleName: AxleName;
  action: Actions;
};

const TirePressureRegulator = ({ axleName, action }: props) => {
  const { t } = useTranslation();
  const { control, watch, setValue } = useFormContext<TireProfileOVM>();

  // Watch's
  const installed = watch(`axles.${axleName}.tire_pressure_regulator.pressure_regulation_device_installed`);
  const shouldBeReinstalled = watch(`axles.${axleName}.tire_pressure_regulator.should_device_be_reinstalled`);
  const specialToolsRequired = watch(`axles.${axleName}.tire_pressure_regulator.special_tools_required`);

  if (axleName === 'steer') return null;

  return (
    <Panel
      title={t('Tire Pressure Regulator')}
      control={
        <span style={{ fontSize: '0.75rem' }}>
          <Controller
            name={`axles.${axleName}.tire_pressure_regulator.pressure_regulation_device_installed`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Switch
                fullWidth
                label={t('Installed')}
                checked={value || false}
                disabled={action === 'view'}
                onChange={(e: any) => {
                  onChange(e.target.checked);
                  setValue(`axles.${axleName}.tire_pressure_regulator.should_device_be_reinstalled`, false, {
                    shouldDirty: true,
                  });
                  setValue(`axles.${axleName}.tire_pressure_regulator.special_tools_required`, false, {
                    shouldDirty: true,
                  });
                  setValue(`axles.${axleName}.tire_pressure_regulator.driver_has_special_tools`, false, {
                    shouldDirty: true,
                  });
                }}
              />
            )}
          />
        </span>
      }
    >
      <Grid container spacing={2}>
        {installed && (
          <>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={8}>
              <Controller
                name={`axles.${axleName}.tire_pressure_regulator.should_device_be_reinstalled`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Switch
                    fullWidth
                    label={t('Should device be reinstalled')}
                    checked={value || false}
                    disabled={action === 'view'}
                    onChange={(e: any) => {
                      onChange(e.target.checked);
                      setValue(`axles.${axleName}.tire_pressure_regulator.special_tools_required`, false, {
                        shouldDirty: true,
                      });
                      setValue(`axles.${axleName}.tire_pressure_regulator.driver_has_special_tools`, false, {
                        shouldDirty: true,
                      });
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={2} />
          </>
        )}
        {shouldBeReinstalled && (
          <>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={8}>
              <Controller
                name={`axles.${axleName}.tire_pressure_regulator.special_tools_required`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Switch
                    fullWidth
                    label={t('Special tools required for removal')}
                    checked={value || false}
                    disabled={action === 'view'}
                    onChange={(e: any) => {
                      onChange(e.target.checked);
                      setValue(`axles.${axleName}.tire_pressure_regulator.driver_has_special_tools`, false, {
                        shouldDirty: true,
                      });
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={2} />
          </>
        )}
        {specialToolsRequired && (
          <>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={8}>
              <Controller
                name={`axles.${axleName}.tire_pressure_regulator.driver_has_special_tools`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Switch
                    fullWidth
                    label={t('Driver has special tools')}
                    checked={value || false}
                    disabled={action === 'view'}
                    onChange={(e: any) => onChange(e.target.checked)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={2} />
          </>
        )}
      </Grid>
    </Panel>
  );
};

export { TirePressureRegulator };
