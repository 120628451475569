import { useEffect, useRef } from 'react';
import dayjs from 'dayjs';

export const formatDate = (date: string, format?: string) => {
  return dayjs(date).format(format || 'MMMM D, YYYY h:mm A');
};

export const upperText = (text: string) => {
  if (text.length > 0) {
    return text
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
      .join(' ');
  }
  return '';
};

export function formatDateShortMonth(strDate: string) {
  if (!strDate) return null;
  return dayjs(strDate).format('MMM D YYYY h:mm A').toString();
}

export const toHoursMin = (totalMin: number | undefined): string | undefined => {
  if (typeof totalMin !== 'number') return 'No hours were received';
  const hours = Math.floor(totalMin / 60);
  const min = Math.floor(totalMin - hours * 60);
  return `${hours} hr, ${min} min`;
};

export const toDaysHoursMin = (totalMin: number | undefined): string | undefined => {
  if (typeof totalMin !== 'number' || totalMin < 0) return 'No hours were received';
  const days = Math.floor(totalMin / 1440);
  const hours = Math.floor((totalMin - days * 1440) / 60);
  const min = Math.floor(totalMin - hours * 60 - days * 1440);
  const daysString = days === 1 ? `${days} day,` : `${days} days,`;
  return days === 0 ? `${hours} hr, ${min} min` : `${daysString} ${hours} hr, ${min} min`;
};

export const toHoursMinShort = (minutes: number): string => {
  return `${Math.floor(minutes / 60)}:${minutes % 60}`;
};

export const snakeToTitleCase = (snakeCase: string) => {
  const titleCase = snakeCase
    .split('_')
    .filter((x) => x.length > 0)
    .map((x) => x.trim().charAt(0).toUpperCase() + x.slice(1).toLowerCase())
    .join(' ');
  return titleCase;
};

export const upperToTitleCase = (upperCase: string) => {
  const titleCase = upperCase
    .split(' ')
    .filter((x) => x.length > 0)
    .map((x) => x.trim().charAt(0).toUpperCase() + x.slice(1).toLowerCase())
    .join(' ');
  return titleCase;
};

export function useDebounce(time: number) {
  const timer = useRef<number | null>(null);
  useEffect(
    () => () => {
      if (timer.current) clearTimeout(timer.current);
    },
    [],
  );
  return (callback: () => void) => {
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(callback, time);
  };
}
