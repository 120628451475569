import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { PhoneInput, Select, useDialogs } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { SelectOption, getOptions } from '@michelin/select-options-provider';
import { auth } from 'Auth';
import Autocomplete from 'components/Autocomplete';
import { isHierarchyHigher } from 'components/Contact/ContactDetails/utils';
import { AuthorizationService, BillingPreferences, BillingPurchasingProcedures, BillingServiceTypes } from '../query';
import { PurchasingProcValidations } from '../utils';
import ContactLevelToAuthorize from './contactLevelToAuthorize';

interface Props {
  profile: BillingPreferences;
  purchasing_procedure: BillingPurchasingProcedures;
  validationErrors: PurchasingProcValidations;
  editFlag: boolean;
  ers: boolean;
  poRequired: boolean;
  primaryContactRequired: boolean;
  editProfile: (profile: BillingPreferences) => void;
  setModifiedFlag: (modified: boolean) => void;
  handleContactLevelChange: any;
}

function isIssuerVisible(ers: boolean, primaryContactRequired: boolean, profile: BillingPreferences): boolean {
  return (
    // ERS Checking
    (ers === true &&
      typeof profile.ers_purchasing_procedures !== 'undefined' &&
      profile.ers_purchasing_procedures !== null &&
      // ERS Primary Contact
      ((primaryContactRequired === true &&
        profile.ers_purchasing_procedures.primary_method_to_request_service === AuthorizationService.fleet_contact) ||
        // ERS Secondary Contact
        (primaryContactRequired === false &&
          profile.ers_purchasing_procedures.secondary_method_to_request_service ===
            AuthorizationService.fleet_contact))) ||
    // ON-Site Checking
    (ers === false &&
      typeof profile.onsite_purchasing_procedures !== 'undefined' &&
      profile.onsite_purchasing_procedures !== null &&
      // ON-Site Primary Contact
      ((primaryContactRequired === true &&
        profile.onsite_purchasing_procedures.primary_method_to_request_service ===
          AuthorizationService.fleet_contact) ||
        // ON-Site Secondary Contact
        (primaryContactRequired === false &&
          profile.onsite_purchasing_procedures.secondary_method_to_request_service ===
            AuthorizationService.fleet_contact)))
  );
}

function isPhoneVisible(ers: boolean, primaryContactRequired: boolean, profile: BillingPreferences): boolean {
  return (
    // ERS Checking
    (ers === true &&
      typeof profile.ers_purchasing_procedures !== 'undefined' &&
      profile.ers_purchasing_procedures !== null &&
      // ERS Primary Phone Number
      ((primaryContactRequired === true &&
        profile.ers_purchasing_procedures.primary_method_to_request_service ===
          AuthorizationService.break_down_phone_number) ||
        // ERS Secondary Phone Number
        (primaryContactRequired === false &&
          profile.ers_purchasing_procedures.secondary_method_to_request_service ===
            AuthorizationService.break_down_phone_number))) ||
    // ON-Site Checking
    (ers === false &&
      typeof profile.onsite_purchasing_procedures !== 'undefined' &&
      profile.onsite_purchasing_procedures !== null &&
      // ON-Site Primary Phone Number
      ((primaryContactRequired === true &&
        profile.onsite_purchasing_procedures.primary_method_to_request_service ===
          AuthorizationService.break_down_phone_number) ||
        // ON-Site Secondary Phone Number
        (primaryContactRequired === false &&
          profile.onsite_purchasing_procedures.secondary_method_to_request_service ===
            AuthorizationService.break_down_phone_number)))
  );
}

export default function ContactToRequireAuthorization(props: Props): JSX.Element {
  const {
    profile,
    purchasing_procedure,
    validationErrors,
    editFlag,
    ers,
    editProfile,
    setModifiedFlag,
    poRequired,
    primaryContactRequired,
    handleContactLevelChange,
  } = props;

  const { t } = useTranslation();
  const { alertDialog } = useDialogs();

  const maxLevel = (profile.owner && profile.owner.customer_type) || auth.getAccountTypeShort();

  const [optionsMethodToRequestService, setOptionsMethodToRequestService] = useState<SelectOption[]>([]);
  useEffect(() => {
    const opts: SelectOption[] = getOptions('method_to_req_service', 'Select', t('Select')).filter((x) => {
      if (
        profile.general_information.service_type === BillingServiceTypes.MECHANICAL &&
        (x.value === 'break_down_phone_number' || x.value === 'driver')
      ) {
        return false;
      }
      return true;
    });
    setOptionsMethodToRequestService(opts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.general_information.service_type]);

  const options_sorted_account_types = getOptions('sorted_account_types')
    .map((x) => {
      if (x.value.length <= 2) return x;
      const subValue = x.value.substr(2);
      if (subValue === auth.getAccountTypeShort()) {
        return {
          label: t('{{label}} Locations (My Location)', { label: t(x.label) }),
          value: x.value.substring(2),
        };
      }
      return {
        label: t('{{label}} Locations', { label: t(x.label) }),
        value: x.value.substring(2),
      };
    })
    .filter((x) => isHierarchyHigher(maxLevel, x.value));
  const authorized_tire_po_issuer = { label: t('Tire PO Issuer'), value: 'po_issuer' };
  const authorized_tire_ers_manager = { label: t('Tire ERS Manager'), value: 'ers_manager' };

  const methodToRequestServiceDisabled =
    purchasing_procedure.required_authorization_to_begin_service === 'po' && primaryContactRequired;
  const issuerVisible = isIssuerVisible(ers, primaryContactRequired, profile);
  const phoneVisible = isPhoneVisible(ers, primaryContactRequired, profile);

  const updateMethod = (newValue?: any) => {
    const newProfile = { ...profile };
    if (primaryContactRequired) {
      purchasing_procedure.primary_method_to_request_service = newValue;
    } else {
      purchasing_procedure.secondary_method_to_request_service = newValue;
    }
    if (newValue !== AuthorizationService.fleet_contact) {
      if (primaryContactRequired) {
        purchasing_procedure.pri_ers_authorized_contact_level = undefined;
        purchasing_procedure.pri_ers_authorized_contact_type = undefined;
      } else {
        purchasing_procedure.sec_ers_authorized_contact_level = undefined;
        purchasing_procedure.sec_ers_authorized_contact_type = undefined;
      }
    }

    if (ers) {
      newProfile.ers_purchasing_procedures = purchasing_procedure;
    } else {
      newProfile.onsite_purchasing_procedures = purchasing_procedure;
    }
    validationErrors.primary_contact_request_authorization = false;
    setModifiedFlag(true);
    editProfile(newProfile);
  };

  const updateContactType = (values: string[] = []) => {
    const newProfile = { ...profile };

    if (values.length > 2) {
      alertDialog(
        [
          t("You can't select more than 2 contact types."),
          t('Please remove one contact type before selecting another.'),
        ],
        t('Max number of contact types selected'),
      );
      return;
    }

    if (primaryContactRequired) {
      if (ers && values && !values.includes(authorized_tire_po_issuer.value) && methodToRequestServiceDisabled) {
        values.push(authorized_tire_po_issuer.value);
      }
      purchasing_procedure.pri_ers_authorized_contact_type = values;
    } else {
      purchasing_procedure.sec_ers_authorized_contact_type = values;
    }

    if (ers) {
      newProfile.ers_purchasing_procedures = purchasing_procedure;
    } else {
      newProfile.onsite_purchasing_procedures = purchasing_procedure;
    }

    setModifiedFlag(true);
    editProfile(newProfile);
  };

  const updateContactLevel = (newValue: any) => {
    const newProfile = { ...profile };

    if (primaryContactRequired) {
      purchasing_procedure.pri_ers_authorized_contact_level = newValue;
      if (purchasing_procedure.pri_ers_authorized_contact_type) {
        handleContactLevelChange(newValue, purchasing_procedure.pri_ers_authorized_contact_type, true);
      }
    } else {
      purchasing_procedure.sec_ers_authorized_contact_level = newValue;
      if (purchasing_procedure.sec_ers_authorized_contact_type) {
        handleContactLevelChange(newValue, purchasing_procedure.sec_ers_authorized_contact_type, true);
      }
    }

    if (ers) {
      newProfile.ers_purchasing_procedures = purchasing_procedure;
    } else {
      newProfile.onsite_purchasing_procedures = purchasing_procedure;
    }

    setModifiedFlag(true);
    editProfile(newProfile);
  };

  const updatePhoneNumber = (newValue: any) => {
    const newProfile = { ...profile };

    if (primaryContactRequired) {
      purchasing_procedure.pri_phone_number = newValue;
    } else {
      purchasing_procedure.sec_phone_number = newValue;
    }
    if (props.ers) {
      newProfile.ers_purchasing_procedures = purchasing_procedure;
    } else {
      newProfile.onsite_purchasing_procedures = purchasing_procedure;
    }
    validationErrors.primary_contact_phone = false;
    validationErrors.secondary_contact_phone = false;

    if (ers) {
      newProfile.ers_purchasing_procedures = purchasing_procedure;
    } else {
      newProfile.onsite_purchasing_procedures = purchasing_procedure;
    }
    setModifiedFlag(true);
    editProfile(newProfile);
  };

  const launchContactReassignWorkFlow = () => {
    if (
      primaryContactRequired &&
      purchasing_procedure.pri_ers_authorized_contact_type &&
      purchasing_procedure.pri_ers_authorized_contact_type.length > 0 &&
      purchasing_procedure.pri_ers_authorized_contact_level
    ) {
      handleContactLevelChange(
        purchasing_procedure.pri_ers_authorized_contact_level,
        purchasing_procedure.pri_ers_authorized_contact_type,
        true,
        true,
      );
    } else if (
      primaryContactRequired === false &&
      purchasing_procedure.sec_ers_authorized_contact_type &&
      purchasing_procedure.sec_ers_authorized_contact_type.length > 0 &&
      purchasing_procedure.sec_ers_authorized_contact_level
    ) {
      handleContactLevelChange(
        purchasing_procedure.sec_ers_authorized_contact_level,
        purchasing_procedure.sec_ers_authorized_contact_type,
        false,
        true,
      );
    }
  };

  const contactTypesValue = primaryContactRequired
    ? purchasing_procedure.pri_ers_authorized_contact_type
    : purchasing_procedure.sec_ers_authorized_contact_type;

  const phoneValidationError =
    (primaryContactRequired && validationErrors.primary_contact_phone) ||
    (primaryContactRequired === false && validationErrors.secondary_contact_phone);

  const contactTypesSuggestions = props.ers
    ? [authorized_tire_po_issuer, authorized_tire_ers_manager]
    : getOptions('contact_types');

  const contact_types_err = primaryContactRequired
    ? validationErrors.primary_contact_types
    : validationErrors.sec_contact_types;

  return (
    <>
      <Grid item sm={12} md={4}>
        <Select
          label={t('{{contactLevel}} Contact if Pre-Approval limit is reached*', {
            contactLevel: primaryContactRequired ? t('Primary') : t('Secondary'),
          })}
          value={
            primaryContactRequired
              ? purchasing_procedure.primary_method_to_request_service
              : purchasing_procedure.secondary_method_to_request_service
          }
          readOnly={!editFlag || (primaryContactRequired && poRequired) || methodToRequestServiceDisabled}
          onChange={(event) => updateMethod(event.target.value)}
          options={optionsMethodToRequestService}
          style={{ width: '100%' }}
          error={
            primaryContactRequired
              ? validationErrors.primary_contact_request_authorization
              : validationErrors.sec_contact_request_authorization
          }
          className="acidSelectFieldNowrap"
        />
      </Grid>
      {issuerVisible ? (
        <>
          <Grid item sm={12} md={4}>
            <div style={{ border: contact_types_err ? '2px solid #d42a2a' : '' }}>
              <Autocomplete
                id="autocompletepp"
                multiple
                variant="standard"
                label={t('Contact Types Who May Authorize Service')}
                disableClearable
                disableCloseOnSelect
                placeholder=""
                onChange={updateContactType}
                suggestions={contactTypesSuggestions}
                values={contactTypesValue}
                readOnly={!editFlag}
              />
            </div>
          </Grid>
          {contactTypesValue && contactTypesValue.length > 0 && (
            <Grid item sm={12} md={4} style={{ display: 'flex' }}>
              <ContactLevelToAuthorize
                editFlag={editFlag}
                launchContactReassignWorkFlow={launchContactReassignWorkFlow}
                options_sorted_account_types={options_sorted_account_types}
                primaryContactRequired={primaryContactRequired}
                purchasing_procedure={purchasing_procedure}
                updateContactLevel={updateContactLevel}
                validationErrors={validationErrors}
              />
            </Grid>
          )}
        </>
      ) : null}
      {phoneVisible ? (
        <Grid
          item
          sm={12}
          md={4}
          style={
            phoneValidationError
              ? {
                  borderColor: 'red',
                  borderStyle: 'solid',
                  borderWidth: 'thin',
                }
              : undefined
          }
        >
          <PhoneInput
            id="pr_breakdown_phone"
            label={t('Fleet Breakdown Phone Number')}
            value={
              primaryContactRequired ? purchasing_procedure.pri_phone_number : purchasing_procedure.sec_phone_number
            }
            defaultCountry={auth.getCountryCode() === 'ca' ? 'CA' : 'US'}
            readOnly={!editFlag}
            onChange={(event: any) => {
              updatePhoneNumber(event.value);
            }}
          />
        </Grid>
      ) : null}
    </>
  );
}
