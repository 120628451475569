import React from 'react';
import { Badge, Link } from '@material-ui/core';
import { BillingProfileOVM } from '@michelin/fcp-view-models/dist/models/billing';
import { AvailableSelectOptions, getOptionLabel } from '@michelin/select-options-provider';
import CreatedByAvatar from 'components/CreatedByAvatar';
import { NewLocationAssignments } from 'components/NewLocationAssignments';
import { getBaseUrl } from 'prefs-and-service-offers';
import { useHistory } from 'react-router-dom';

export const CreatedByRender = (row: { data: BillingProfileOVM }) => {
  if (!row?.data?.owner) return <></>;
  const { owner } = row.data;
  return <CreatedByAvatar customerType={owner.customer_type} customerNumber={owner.customer_number} />;
};

export const ProfileNameRender = ({
  row,
  readPermission,
}: {
  row: { data: BillingProfileOVM };
  readPermission: boolean;
}) => {
  const history = useHistory();
  if (!row?.data?.general_information?.profile_name || !row.data.id) return <></>;
  const profile_id = row.data.id || '';
  const { profile_name } = row.data.general_information;
  const canView = row.data.allowedActions?.view || false;
  if (profile_id.length <= 1 || !readPermission || !canView) {
    return <p>{profile_name}</p>;
  }
  return (
    <>
      {row.data.general_information.default === true ? (
        <Badge color="primary" variant="dot" anchorOrigin={{ horizontal: 'left', vertical: 'top' }}>
          <Link
            onClick={() => history.push(`${getBaseUrl()}/billing/${profile_id}`)}
            style={{ cursor: 'pointer', padding: '0.3rem' }}
          >
            {profile_name}
          </Link>
        </Badge>
      ) : (
        <Link onClick={() => history.push(`${getBaseUrl()}/billing/${profile_id}`)} style={{ cursor: 'pointer' }}>
          {profile_name}
        </Link>
      )}
    </>
  );
};

export const OptionRender = (option: AvailableSelectOptions, value: string) => {
  if (!value) return <></>;
  return <span>{getOptionLabel(option, value)}</span>;
};

export const LocationsRender = (row: { data: BillingProfileOVM }) => {
  if (!row?.data) return <></>;
  const { assigned_locations, owner, general_information } = row.data;
  return (
    <NewLocationAssignments
      owner={owner?.customer_number || ''}
      mode="view"
      all={general_information?.set_all_locations || false}
      locations={
        assigned_locations?.map((rel) => ({
          hash_key: rel,
        })) || []
      }
      disabled={false}
    />
  );
};
