import React from 'react';
import { Container } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import LastModified from 'components/ChangeLog/LastModified';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { AccountsTable } from './Tables/AccountsTable';

interface AccountsListProps {
  action: 'add' | 'edit' | 'list';
  serviceOfferIds: string;
}

export function AccountsModule(props: AccountsListProps) {
  const { t } = useTranslation();
  return (
    <>
      <PageTitle rightItems={<LastModified entity="account" />} title={t('Service Provider And National Accounts')} />
      <Container>
        <AccountsTable action={props.action} serviceOfferIds={props.serviceOfferIds} />
      </Container>
    </>
  );
}
