/* eslint-disable max-len */

/* eslint-disable no-param-reassign */

/* eslint-disable class-methods-use-this */

/* eslint-disable react/sort-comp */
import React, { useState } from 'react';
import { Checkbox, Grid, Link } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Add, ArrowBack, Delete, Delete as DeleteIcon, Timelapse } from '@material-ui/icons';
import { TreeItem, TreeView } from '@material-ui/lab';
import {
  Fab,
  GlobalActions,
  IconButton,
  LoadingBounce,
  WithDialogsProps,
  useDialogs,
  withDialogs,
} from '@michelin/acid-components';
import { WithTranslation, usePermissions, useTranslation, withTranslation } from '@michelin/central-provider';
import { SelectOption, getOptions } from '@michelin/select-options-provider';
import { useMutation } from '@tanstack/react-query';
import { auth } from 'Auth';
import { gql } from 'apollo-boost';
import {
  Contact,
  ContactLocationRelationship,
  Contact as ContactReassign,
  contactQuery,
  deleteNotAuthorizedByHierarchyMessage,
  getHighestLocation,
  getHighestLocationType,
  getIfDR,
  isInMyHierarchy,
  notAuthorizedByHierarchyTitle,
  queryContactBygetContactByKey,
  queryContactListPaginated,
  updateCustomerContactRAW,
} from 'components/Contact/utils';
import CreatedByAvatar from 'components/CreatedByAvatar';
import { Filter, FilterGroup, FilterGroupRenderer } from 'components/FilterFieldTable/Filter';
import { Customer, addCountry } from 'components/Location';
import { PlainMessage } from 'components/Messages';
import { CONTENT_AREA_HEIGHT } from 'components/Util';
import { DataGrid, Template } from 'devextreme-react';
import { Column, FilterRow, Scrolling, SearchPanel } from 'devextreme-react/data-grid';
import { getAllResultsPaginated } from 'graphql/utils';
import { deleteContacts } from 'hooks/useContacts';
import { WithSnackbarProps, withSnackbar } from 'notistack';
import { getBaseUrl } from 'prefs-and-service-offers';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import LocationAssignments from '../../LocationAssignments';
import { DependencyConflict } from '../ContactDetails';
import ReassignContactTypesDialog, {
  ReassignSelection,
} from '../ContactDetails/ContactTypes/dialogs/ReassignContactTypesDialog';
import { contactTypesRenderForTreeList } from '../ContactDetails/ContactTypes/tables/utils';
import {
  LocatedMichelinOffer,
  contactRelationshipsWithLocationsQuery,
  getMichelinOffer,
} from '../ContactDetails/ContactTypes/utils';
import {
  ERS_MANAGER_ROLE_AREA_VALUE,
  GENERAL_LEVEL_VALUE,
  MECH_SERVICE_VALUE,
  PO_ISSUER_ROLE_AREA_VALUE,
  PRIMARY_LEVEL_VALUE,
  SECONDARY_LEVEL_VALUE,
  TIRE_SERVICE_VALUE,
  canUserEditByHierarchy,
  getContactTypesRoleAreaLabelFromValue,
  mutateExteranContactsReassign,
  updateContactContactTypes,
} from '../ContactDetails/utils';
import DeactivateContactDialog from './deactivate_contact_dialog';
import getContactTypesPerLocation, { upsertRelationshipMutation } from './utils';

interface ContactListState {
  loading?: boolean;
  error?: { title: string; messages: string[] } | null;
  data?: Contact[];
  queriedContacts: Contact[];
  filteredRows?: Contact[];
  resultsCount?: number;
  deactivateDialogOpen: boolean;
  selectedRowToDeactivate: Contact | undefined;
  selectedContactToReassign: ContactReassign | undefined;
  reassignDialogOpen: boolean;
  locatedMichelinOffersToDeactivate: Array<LocatedMichelinOffer>;
  primaryOrSecondaryLevelToDeactivate: boolean;
  secondaryConflicts: Array<DependencyConflict>;
  massiveDelete: boolean;
  selectedRows: Contact[];
  filterGroup: FilterGroup;
  filterService: string;
  filterRoleAreaAll: string;
  filterRoleAreaTire: string;
  filterRoleAreaMech: string;
  filterState: string;
  filterLevel: string;
}

interface ContactListProps extends WithTranslation, WithDialogsProps, WithSnackbarProps, RouteComponentProps {
  specificCustomer?: Customer;
  popupMode?: boolean;
  additionalColumns?: Array<JSX.Element>;
  filterByLocationHashKey?: Set<string>;
  preLoadedContacts?: Array<Contact>;
}

interface props {
  stNumber: string;
  selectedRows: Contact[];
  createPermission: boolean;
  deletePermission: boolean;
  massiveDelete: boolean;
  overrideDeletePermission: boolean;
  handleClickDelete: () => void;
  createContact: () => void;
  loadTable: () => void;
  clearRows: () => void;
}
function ContactGlobalActions({
  stNumber,
  selectedRows,
  createPermission,
  deletePermission,
  massiveDelete,
  overrideDeletePermission,
  handleClickDelete,
  createContact,
  loadTable,
  clearRows,
}: props) {
  const { t } = useTranslation();
  const saveMutation = useMutation(deleteContacts);
  const { confirmDialog, alertDialog } = useDialogs();
  const [saving, setSaving] = useState(false);

  return (
    <GlobalActions>
      <Fab color="inherit" aria-label={t('Saving')} id="savingContactButton" disabled={!saving}>
        <Timelapse />
      </Fab>
      {overrideDeletePermission ? (
        <Fab
          color="primary"
          aria-label={t('Delete')}
          disabled={!deletePermission || saving}
          onClick={async () => {
            if (massiveDelete && selectedRows.length > 0) {
              setSaving(true);
              try {
                // const primaryOrSecondaryLevel = selectedRows.map((contact) => contact.contact_types?.map((contactType) => contactType.role_areas?.map((rolArea) => rolArea.levels.filter((level) => level.level === PRIMARY_LEVEL_VALUE || SECONDARY_LEVEL_VALUE).length > 0).includes(true)).includes(true)).filter((val) => val).length;
                // const tireManagerOrIssuerType = selectedRows.map((contact) => contact.contact_types?.filter((contactType) => contactType.service === 'tire').map((contactType) => contactType.role_areas?.map((rolArea) => rolArea.role_area === ERS_MANAGER_ROLE_AREA_VALUE || rolArea.role_area === PO_ISSUER_ROLE_AREA_VALUE).includes(true)).includes(true)).filter((val) => val).length;

                const affectedContacts: string[] = [];
                selectedRows.forEach((contact: Contact) => {
                  contact.contact_types?.forEach((contactType) => {
                    if (contactType.service === 'tire') {
                      contactType.role_areas?.forEach((roleArea) => {
                        // check for ERS manager & PO Issuer
                        if (
                          roleArea.role_area === ERS_MANAGER_ROLE_AREA_VALUE ||
                          roleArea.role_area === PO_ISSUER_ROLE_AREA_VALUE
                        ) {
                          roleArea.levels.forEach((level) => {
                            // check for Primary or Secondary
                            if (level.level === PRIMARY_LEVEL_VALUE || level.level === SECONDARY_LEVEL_VALUE) {
                              if (affectedContacts.indexOf(contact.hash_key) === -1)
                                affectedContacts.push(contact.hash_key);
                            }
                          });
                        }
                      });
                    }
                  });
                });

                if (affectedContacts.length > 0) {
                  confirmDialog(
                    t(
                      '{{contactsCount}} contacts are assigned primary and/or secondary priority levels required for ONCall Tire ERS Services at one or more locations. Are you sure you want to delete these contacts?',
                      { contactsCount: affectedContacts.length },
                    ),
                    t("You're about to delete a contact."),
                    t('Continue'),
                    t('Cancel'),
                    async () => {
                      await saveMutation.mutateAsync({
                        fleetId: stNumber,
                        ids: selectedRows.map((contact) => contact.hash_key.split('~')[1]),
                      });
                      loadTable();
                      // enqueueSnackbar(t('Commercial Products information saved.'), { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' } });
                      handleClickDelete();
                      setSaving(false);
                    },
                    () => {
                      clearRows();
                      handleClickDelete();
                      setSaving(false);
                    },
                    undefined,
                  );
                } else {
                  confirmDialog(
                    t('Are you sure you want to delete these {{contactsCount}} contacts?', {
                      contactsCount: selectedRows.length,
                    }),
                    t("You're about to delete a contact."),
                    t('Continue'),
                    t('Cancel'),
                    async () => {
                      await saveMutation.mutateAsync({
                        fleetId: stNumber,
                        ids: selectedRows.map((contact) => contact.hash_key.split('~')[1]),
                      });
                      loadTable();
                      handleClickDelete();
                      setSaving(false);
                    },
                    () => {
                      clearRows();
                      handleClickDelete();
                      setSaving(false);
                    },
                    undefined,
                  );
                }
              } catch (error) {
                alertDialog(
                  t('Please check your connection and try again.\nIf the issue persist, please contact support').split(
                    '\n',
                  ),
                  t('Error saving Commercial Products Data.'),
                );
              }
            } else {
              handleClickDelete();
            }
          }}
        >
          <Delete />
        </Fab>
      ) : null}
      <Fab color="primary" aria-label={t('Back')} onClick={handleClickDelete} disabled={!massiveDelete}>
        <ArrowBack />
      </Fab>
      <Fab color="primary" aria-label={t('Add')} disabled={!createPermission || massiveDelete} onClick={createContact}>
        <Add />
      </Fab>
    </GlobalActions>
  );
}
class ContactList extends React.Component<ContactListProps, ContactListState> {
  preloadLOSet: Set<string>;

  searchTerms: Map<String, string>;

  mechanicalFilterOptions: SelectOption[];

  tireFilterOptions: SelectOption[];

  allFilterOptions: SelectOption[];

  constructor(props: ContactListProps) {
    super(props);
    const { t } = props;
    this.state = {
      loading: true,
      resultsCount: 0,
      deactivateDialogOpen: false,
      selectedRowToDeactivate: undefined,
      reassignDialogOpen: false,
      locatedMichelinOffersToDeactivate: new Array<LocatedMichelinOffer>(),
      primaryOrSecondaryLevelToDeactivate: false,
      selectedContactToReassign: undefined,
      secondaryConflicts: [],
      queriedContacts: [],
      massiveDelete: false,
      selectedRows: [],
      filterGroup: new FilterGroup([]),
      filterLevel: 'All',
      filterState: 'all',
      filterService: 'all',
      filterRoleAreaAll: 'all',
      filterRoleAreaTire: 'all',
      filterRoleAreaMech: 'all',
    };

    this.searchTerms = new Map();
    this.preloadLOSet = new Set();

    this.mechanicalFilterOptions = [];
    this.tireFilterOptions = [];
    this.allFilterOptions = getOptions('contact_types', 'all', t('All Contact Types'));
  }

  createFilterGroup(selected_service: string): FilterGroup {
    const { t } = this.props;

    const sortedAccountTypesOptions = getOptions(
      'location_only_and_sorted_account_types',
      'All',
      t('All Contact Levels'),
    ).filter((elem) => {
      const customerData = this.props.specificCustomer ? this.props.specificCustomer : auth.getCustomerData();
      const highest = customerData ? getHighestLocationType(customerData) : 'ST';
      if (highest === 'PC') return true;
      if (highest === 'HO' && elem.value !== 'PC') return true;
      if (highest === 'BT' && elem.value !== 'PC' && elem.value !== 'HO') return true;
      return highest === 'ST' && elem.value !== 'PC' && elem.value !== 'HO' && elem.value !== 'BT';
    });

    let states = getOptions('us_states', 'all', t('All States/Provinces'));
    states = [...addCountry(states, 'US'), ...addCountry(getOptions('ca_provinces'), 'CA')];

    const filter = new FilterGroup([
      new Filter(
        t('Account Type'),
        sortedAccountTypesOptions,
        (row: Contact, accType: string) => {
          if (accType === 'All') return true;
          if (accType === 'LO') return this.preloadLOSet.has(row.hash_key);
          return row.contact_level === accType;
        },
        this.state.filterLevel,
        (value: string) => {
          this.setState({ filterLevel: value });
          return true;
        },
      ),
      new Filter(
        t('State / Province'),
        states,
        (row: Contact, state: string) =>
          (row &&
            row.locations &&
            typeof row.locations.find(
              (locationRel) => locationRel.location && locationRel.location.customer_state === state,
            ) !== 'undefined') ||
          state === 'all',
        this.state.filterState,
        (value: string) => {
          this.setState({
            filterState: value,
          });
          return true;
        },
      ),
      new Filter(
        t('Contact Type'),
        getOptions('contact_types_services_filter'),
        (row: Contact, contactTypeService: string) =>
          (row &&
            row.contact_types &&
            typeof row.contact_types.find((contact_type) => contact_type.service === contactTypeService) !==
              'undefined') ||
          contactTypeService === 'all',
        selected_service,
        (value: string) => {
          this.setState({
            filterService: value,
          });
          return true;
        },
        false,
      ),
      new Filter(
        t('All Roles'),
        this.allFilterOptions,
        (row: Contact, contactType: string) =>
          (row &&
            row.contact_types &&
            typeof row.contact_types.find(
              (contact_type) =>
                contact_type.role_areas &&
                typeof contact_type.role_areas.find(
                  (contactTypeRoleArea) => contactTypeRoleArea.role_area === contactType,
                ) !== 'undefined',
            ) !== 'undefined') ||
          contactType === 'all' ||
          selected_service !== 'all',
        this.state.filterRoleAreaAll,
        (value: string) => {
          this.setState({
            filterRoleAreaAll: value,
          });
          return true;
        },
        // selected_service === 'all',
        true,
      ),
      new Filter(
        t('Tire Related Roles'),
        this.tireFilterOptions,
        (row: Contact, contactType: string) =>
          (row &&
            row.contact_types &&
            typeof row.contact_types.find(
              (contact_type) =>
                contact_type.role_areas &&
                typeof contact_type.role_areas.find(
                  (contactTypeRoleArea) => contactTypeRoleArea.role_area === contactType,
                ) !== 'undefined',
            ) !== 'undefined') ||
          contactType === 'all' ||
          selected_service !== TIRE_SERVICE_VALUE,
        this.state.filterRoleAreaTire,
        (value: string) => {
          this.setState({
            filterRoleAreaTire: value,
          });
          return true;
        },
        // selected_service === TIRE_SERVICE_VALUE,
        false,
      ),
      new Filter(
        t('Mechanical Related Roles'),
        this.mechanicalFilterOptions,
        (row: Contact, contactType: string) =>
          (row &&
            row.contact_types &&
            typeof row.contact_types.find(
              (contact_type) =>
                contact_type.role_areas &&
                typeof contact_type.role_areas.find(
                  (contactTypeRoleArea) => contactTypeRoleArea.role_area === contactType,
                ) !== 'undefined',
            ) !== 'undefined') ||
          contactType === 'all' ||
          selected_service !== MECH_SERVICE_VALUE,
        this.state.filterRoleAreaMech,
        (value: string) => {
          this.setState({
            filterRoleAreaMech: value,
          });
          return true;
        },
        // selected_service === MECH_SERVICE_VALUE,
        false,
      ),
    ]);

    return filter;
  }

  shouldComponentUpdate(nextProps: Readonly<ContactListProps>, nextState: Readonly<ContactListState>): boolean {
    if (this.state.filterService !== nextState.filterService) {
      const newFilters = this.createFilterGroup(nextState.filterService);
      this.setState({
        filterGroup: newFilters,
      });
    }
    return true;
  }

  async loadTable() {
    this.setState({
      loading: true,
    });
    if (!auth.apolloClient) {
      this.setState({
        error: {
          title: this.props.t('Database Error'),
          messages: [this.props.t('ApolloClient is not available')],
        },
        loading: false,
      });
      return;
    }

    // Hierarchy
    // Parent
    const customerData = this.props.specificCustomer ? this.props.specificCustomer : auth.getCustomerData();
    const accountNumber = auth.getCustomerNumber();
    const requiresHierarchy = !this.props.specificCustomer;

    const { apolloClient } = auth;
    const rows: Contact[] = [];
    if (this.props.preLoadedContacts) {
      rows.push(...this.props.preLoadedContacts);
    } else if (customerData !== null && apolloClient !== null) {
      if (requiresHierarchy) {
        /*
          GSDCP-1414
          https://servicesolutions.atlassian.net/secure/RapidBoard.jspa?rapidView=8&modal=detail&selectedIssue=GSDCP-1414
          I should be able to view contacts above my hierarchy level but not be able to create
          new contacts or modify existing contacts having a Contact Level above my own
        */
        const highestLocation = getHighestLocation(customerData);

        const variables = {
          location: highestLocation,
          chainedHierarchy: highestLocation,
        };

        // Repeated data:
        const dataPaginated = await getAllResultsPaginated(
          queryContactListPaginated,
          variables,
          500,
          'getContactLocationsRelationshipsPaginated',
        );

        if (dataPaginated) {
          const contactHashKeysSet = new Set<string>();
          dataPaginated.forEach((x) => {
            if (x.hash_key === `1~${accountNumber}`) {
              this.preloadLOSet.add(x.gsi1_hash_key);
            }
            if (isInMyHierarchy(x, customerData)) contactHashKeysSet.add(x.gsi1_hash_key);
          });
          const contactHashKeys = Array.from(contactHashKeysSet.values());
          let n = 0;
          const queries: Array<Array<string>> = [];
          let tempQueries: Array<string> = [];
          contactHashKeys.forEach((x: string) => {
            n += 1;
            const query = `q${n}: ${queryContactBygetContactByKey.replace('$hash_key', x)}`;
            if (n === 999) {
              queries.push([...tempQueries]);
              tempQueries = [query];
            } else {
              tempQueries.push(query);
            }
          });
          queries.push(tempQueries);

          const contactsResolvedPromises: Array<Promise<any>> = [];
          queries.forEach((tempQuery) => {
            // eslint-disable-next-line no-async-promise-executor
            const queryPromise = new Promise(async (res, rej) => {
              try {
                if (tempQuery && tempQuery.length > 0) {
                  const fullQuery = gql`query Query{${tempQuery}}`;
                  const queryResults = await apolloClient.query({
                    query: fullQuery,
                    fetchPolicy: 'network-only',
                  });

                  const { data } = queryResults;
                  if (data) {
                    Object.keys(data).forEach((key: string) => {
                      rows.push(data[key]);
                    });
                  }
                }
                res([]);
              } catch (e) {
                rej(e);
              }
            });
            contactsResolvedPromises.push(queryPromise);
          });

          await Promise.all(contactsResolvedPromises);
        }
      } else {
        const { hash_key } = customerData;
        if (typeof apolloClient !== 'undefined' && apolloClient !== null && hash_key && hash_key.length > 2) {
          const { data } = await apolloClient.query({
            query: contactRelationshipsWithLocationsQuery,
            variables: { locations: hash_key.substr(2) },
            fetchPolicy: 'no-cache',
          });

          if (typeof data !== 'undefined' && data !== null) {
            const { getContactLocationsRelationships } = data;
            if (typeof getContactLocationsRelationships !== 'undefined' && getContactLocationsRelationships !== null) {
              const relationshipsFilter = (contactLocationRelationship: ContactLocationRelationship) => {
                if (contactLocationRelationship.is_deleted === false) {
                  const { filterByLocationHashKey } = this.props;
                  if (filterByLocationHashKey && filterByLocationHashKey.size > 0) {
                    return filterByLocationHashKey.has(contactLocationRelationship.hash_key.toString());
                  }
                  return true;
                }
                return false;
              };
              getContactLocationsRelationships
                .filter(relationshipsFilter)
                .forEach((contactLocationRelationship: ContactLocationRelationship) => {
                  const { contact } = contactLocationRelationship;
                  if (contact) {
                    rows.push(contact as any);
                  }
                });
            }
          }
        }
      }
    }

    const tireContactTypes: string[] = [];
    const mechanicalContactTypes: string[] = [];

    rows.forEach((contact: Contact | null) => {
      if (!contact) return;
      const { contact_types } = contact;
      if (contact_types) {
        contact_types.forEach((contact_type) => {
          const { role_areas, service } = contact_type;
          if (role_areas) {
            role_areas.forEach((contactTypeRoleArea) => {
              const { role_area } = contactTypeRoleArea;
              if (service === TIRE_SERVICE_VALUE) {
                tireContactTypes.push(role_area);
              } else {
                mechanicalContactTypes.push(role_area);
              }
            });
          }
        });
      }
    });

    this.tireFilterOptions = this.allFilterOptions.filter((option) => tireContactTypes.includes(option.value));
    this.tireFilterOptions.unshift({ value: 'all', label: this.props.t('All Contact Types') });
    this.mechanicalFilterOptions = this.allFilterOptions.filter((option) =>
      mechanicalContactTypes.includes(option.value),
    );
    this.mechanicalFilterOptions.unshift({ value: 'all', label: this.props.t('All Contact Types') });

    const group = this.createFilterGroup('all');

    this.setState({
      loading: false,
      data: rows,
      filteredRows: rows,
      queriedContacts: [...rows],
      filterGroup: group,
    });

    this.forceUpdate();
  }

  renderPriorityLevelAndLocations(contact: Contact): JSX.Element {
    let locations: any = [];
    if (contact.locations && contact.locations.length) {
      locations = contact.locations.filter((x) => !x.is_deleted);
      locations = locations.map((rel: any) => ({ hash_key: rel.hash_key }));
    }

    const contactTypePerLocation = getContactTypesPerLocation(contact);

    return (
      <LocationAssignments
        mode="view"
        all={false}
        locations={locations}
        disabled={false}
        owner=""
        filterByCustomerType={contact.contact_level}
        subTitle={`${contact.first_name} ${contact.last_name}`}
        additionalColumns={
          <Column
            allowSorting={false}
            caption={this.props.t("Contact's Priority Levels")}
            dataField="contact_types"
            cellRender={(row: any) => contactTypesRenderForTreeList(row.data, contactTypePerLocation, this.props.t)}
          />
        }
      />
    );
  }

  renderContactTypes(row: Contact): JSX.Element {
    const { contact_types } = row;
    const mechanicalContactTypes = new Array<string>();
    const tireContactTypes = new Array<string>();

    if (contact_types) {
      contact_types.forEach((contact_type) => {
        const { role_areas, service } = contact_type;
        if (role_areas) {
          role_areas.forEach((contactTypeRoleArea) => {
            const { role_area } = contactTypeRoleArea;
            if (service === TIRE_SERVICE_VALUE) {
              tireContactTypes.push(role_area);
            } else {
              // mechanicalContactTypes.push(role_area);
            }
          });
        }
      });
    }

    return (
      <TreeView defaultCollapseIcon={<Icons.ExpandMore />} defaultExpandIcon={<Icons.ChevronRight />}>
        {tireContactTypes.length > 0 ? (
          <TreeItem nodeId={`tireContactTypes${row.hash_key}`} label={this.props.t('Tire')} id="tireTreeItem">
            {tireContactTypes.map((x, index) => (
              <TreeItem
                key={x + row.hash_key}
                nodeId={x + index + row.hash_key}
                label={getContactTypesRoleAreaLabelFromValue(x, this.props.t)}
              />
            ))}
          </TreeItem>
        ) : undefined}
        {mechanicalContactTypes.length > 0 ? (
          <TreeItem
            nodeId={`mechanicalContactTypes${row.hash_key}`}
            label={this.props.t('Mechanical')}
            id="mechanicalTreeItem"
          >
            {mechanicalContactTypes.map((x, index) => (
              <TreeItem
                key={x + row.hash_key}
                nodeId={x + index + row.hash_key}
                label={getContactTypesRoleAreaLabelFromValue(x, this.props.t)}
              />
            ))}
          </TreeItem>
        ) : undefined}
      </TreeView>
    );
  }

  renderPriorityLevel(row: Contact): JSX.Element {
    const priorityLevels: string[] = [];
    let breakVar: boolean = false;
    if (row.contact_types) {
      row.contact_types.forEach((contactType) => {
        if (contactType.role_areas && !breakVar) {
          contactType.role_areas.forEach((role) => {
            if (role.levels && !breakVar) {
              role.levels.forEach((level) => {
                if (!priorityLevels.includes(level.level) && level.level !== GENERAL_LEVEL_VALUE)
                  priorityLevels.push(level.level);
                if (priorityLevels.length === 2) breakVar = true;
              });
            }
          });
        }
      });
    }
    let i = 0;
    return (
      <ul style={{ fontSize: '0.9em', whiteSpace: 'nowrap' }}>
        {priorityLevels.map((e: string) => (
          <li key={`level ${i++}`}>{e}</li>
        ))}
      </ul>
    );
  }

  deleteContact(row: Contact) {
    const { contact_types } = row;
    const locatedMichelinOffers = new Array<LocatedMichelinOffer>();
    let primaryOrSecondaryLevel = false;

    if (typeof contact_types !== 'undefined') {
      contact_types.forEach((contact_type) => {
        const { role_areas, service } = contact_type;
        if (role_areas && service) {
          role_areas.forEach((contactType_role_area) => {
            const { role_area, levels } = contactType_role_area;
            levels.forEach((contactType_level) => {
              const { level, location } = contactType_level;
              const michelinOffer = getMichelinOffer(level, service, role_area);
              if (typeof michelinOffer !== 'undefined') {
                locatedMichelinOffers.push({
                  michelinOffer,
                  locationHashKey: location,
                });
              } else if (level === PRIMARY_LEVEL_VALUE || level === SECONDARY_LEVEL_VALUE) {
                primaryOrSecondaryLevel = true;
              }
            });
          });
        }
      });
    }
    this.setState({
      selectedRowToDeactivate: row,
      deactivateDialogOpen: true,
      locatedMichelinOffersToDeactivate: locatedMichelinOffers,
      primaryOrSecondaryLevelToDeactivate: primaryOrSecondaryLevel,
    });
  }

  renderActionButtons(row: Contact, deletePermission: boolean, updatePermission: boolean): JSX.Element {
    const canUserEdit = canUserEditByHierarchy(row, updatePermission);

    return (
      <div>
        {deletePermission && canUserEdit ? (
          <IconButton
            aria-label="delete"
            style={{ margin: '8px' }}
            onClick={async () => {
              // check to see it's not important bro
              if (auth && auth.apolloClient) {
                const data = await auth.apolloClient.query({
                  query: getIfDR,
                  variables: { hash_key: `PURCHASING_CONTACT~${row.hash_key}`, range_key: '7', index: 'gsi4' },
                  fetchPolicy: 'no-cache',
                });
                const response = JSON.parse(data.data.getEntityPaginated.items);
                if (response.length === 0) {
                  if (row.locations && row.locations.some((x) => !auth.isLocationAllowed(x.hash_key.toString()))) {
                    this.props.alertDialog(deleteNotAuthorizedByHierarchyMessage, notAuthorizedByHierarchyTitle, 'OK');
                  } else {
                    this.props.confirmDialog(
                      this.props.t('Confirm deletion of {{firstName}} {{lastName}} from {{locations}} locations?', {
                        firstName: row.first_name,
                        lastName: row.last_name,
                        locations: row.locations ? row.locations.length : '-',
                      }),
                      this.props.t("You're about to delete a contact."),
                      this.props.t('Continue'),
                      this.props.t('Cancel'),
                      () => {
                        this.deleteContact(row);
                      },
                      undefined,
                      undefined,
                    );
                  }
                } else {
                  const map: any = {};
                  this.props.errorDialog(
                    <Grid container>
                      <Grid item md={12}>
                        {this.props.t(
                          'This contact is assigned as the Receiver of DR/Work Order for the following Billing Profiles:',
                        )}
                      </Grid>

                      {response
                        .filter((result: any) => {
                          if (map[result.gsi4_range_key]) return false;
                          map[result.gsi4_range_key] = 1;
                          return true;
                        })
                        .map((result: any) => {
                          return (
                            <Grid item md={12} key={result.hash_key}>
                              <Link
                                style={{
                                  textDecoration: 'underline',
                                  fontWeight: 700,
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  this.props.history.push(
                                    `${getBaseUrl()}/billing/${result.hash_key.replace(/(ONSITE|ERS)~RECEIVER~/, '')}`,
                                  );
                                }}
                              >
                                <b>{result.range_key}</b>
                              </Link>
                            </Grid>
                          );
                        })}

                      <Grid item md={12} />
                      <Grid item md={12}>
                        {this.props.t(
                          'Please reassign the Receiver of DR/Work Order to another contact for the above billing profiles',
                        )}
                      </Grid>
                    </Grid>,
                    `${this.props.t('Unable to Deactivate')} ${`${row.first_name} ${row.last_name}`}`,
                    this.props.t('Ok'),
                  );
                }
              }
            }}
            size="small"
          >
            <DeleteIcon fontSize="small" color="primary" />
          </IconButton>
        ) : undefined}
      </div>
    );
  }

  renderActionCheckbox(row: Contact, deletePermission: boolean, updatePermission: boolean): JSX.Element {
    const canUserEdit = canUserEditByHierarchy(row, updatePermission);

    return (
      <div>
        {deletePermission && canUserEdit && this.state.massiveDelete ? (
          <Checkbox
            onChange={(e, checked) => {
              const cloneSelectedRows = this.state.selectedRows;

              if (checked) {
                cloneSelectedRows.push(row);
                this.setState(() => ({ selectedRows: cloneSelectedRows }));
              } else {
                cloneSelectedRows.splice(cloneSelectedRows.indexOf(row), 1);
                this.setState(() => ({ selectedRows: cloneSelectedRows }));
              }
            }}
            checked={this.state.selectedRows.includes(row)}
            style={{ padding: 0 }}
            color="primary"
          />
        ) : undefined}
      </div>
    );
  }

  sortByName(contact: Contact): string {
    return contact.last_name.toString() + contact.first_name.toString();
  }

  getSearchText(contact: Contact): string | null {
    if (!contact || !contact.hash_key) return null;
    const res = this.searchTerms.get(contact.hash_key);
    if (res) return res;

    let locationsStr = '';
    let customerTypeStr = '';

    if (contact.locations) {
      contact.locations.forEach((x) => {
        const { location } = x;

        if (location) {
          locationsStr += ` ${location.customer_number}`;
          locationsStr += ` ${location.customer_city}`;
          locationsStr += ` ${location.customer_state}`;
          locationsStr += ` ${location.customer_name}`;
          customerTypeStr += ` ${location.customer_type}`;
        }
      });
    }

    let contactTypesStr = '';
    if (contact && contact.contact_types) {
      contact.contact_types.forEach((x) => {
        const { role_areas, service } = x;
        if (role_areas) {
          role_areas.forEach((roleArea) => {
            const { role_area } = roleArea;
            contactTypesStr += ` ${service}${role_area}`;
          });
        }
      });
    }

    // eslint-disable-next-line max-len
    const str =
      `${contact.first_name} ${contact.last_name} ${contact.cell_phone} ${contact.work_phone} ${contact.email_address} ${contact.ext} ${locationsStr} ${customerTypeStr} ${contactTypesStr}`.replace(
        / (null )+/g,
        ' ',
      );

    this.searchTerms.set(contact.hash_key, str);
    return str;
  }

  onToolbarPreparing(e: any) {
    e.toolbarOptions.items.unshift({
      location: 'before',
      template: 'filterFields',
    });
    e.toolbarOptions.items.unshift({
      location: 'after',
      template: 'resultsCounter',
      component: e.component,
    });
  }

  renderFilterFields() {
    return (
      <FilterGroupRenderer
        filterGroup={this.state.filterGroup}
        rows={this.state.data || []}
        filteredRows={this.state.filteredRows || []}
        setFilteredRows={(rows) => this.setState({ filteredRows: rows })}
      />
    );
  }

  async reassignSelectedDialogHandler(
    save: boolean,
    contactSelectedReassignRoleAreaLocation: Map<string, ReassignSelection>,
    unassignLocalRoleLocationComboSet: Set<string>,
  ) {
    if (save) {
      this.setState({
        reassignDialogOpen: false,
        loading: true,
      });
      // Reassign
      await mutateExteranContactsReassign(contactSelectedReassignRoleAreaLocation);

      // Clean contact
      const { selectedContactToReassign } = this.state;
      if (selectedContactToReassign) {
        const { hash_key, contact_types, email_address, work_phone, cell_phone } = selectedContactToReassign;

        if (contact_types) {
          contact_types.forEach((contactType) => {
            const { role_areas } = contactType;
            if (role_areas) {
              role_areas.forEach((contactTypeRoleArea) => {
                const { levels, role_area } = contactTypeRoleArea;
                levels.forEach((contactTypeLevel) => {
                  const { location } = contactTypeLevel;
                  if (unassignLocalRoleLocationComboSet.has(role_area + location)) {
                    contactTypeLevel.level = GENERAL_LEVEL_VALUE;
                  }
                });
              });
            }
          });
        }

        if (contact_types) {
          await updateContactContactTypes(hash_key, contact_types, email_address, work_phone, cell_phone);
        }
      }

      this.setState({
        selectedContactToReassign: undefined,
      });
      // Deactivate as no michelin offers
      this.deactivateContactDialogHandler(true, false);
    } else {
      this.setState({
        reassignDialogOpen: false,
        selectedRowToDeactivate: undefined,
      });
    }
  }

  async deactivateContactDialogHandler(
    deactivate: boolean,
    reassign: boolean,
    secondaryConflicts?: Array<DependencyConflict>,
  ) {
    if (deactivate === true && reassign === true) {
      const { selectedRowToDeactivate } = this.state;
      this.setState({
        deactivateDialogOpen: false,
        loading: true,
      });

      if (auth.apolloClient && selectedRowToDeactivate) {
        // Query Contact and Locations Data
        const data = await auth.apolloClient.query({
          query: contactQuery,
          variables: { hash_key: selectedRowToDeactivate.hash_key, range_key: 'v0_contact' },
          fetchPolicy: 'no-cache',
        });

        const queriedContact: ContactReassign = data.data.getContactByKey;

        this.setState({
          secondaryConflicts: secondaryConflicts || [],
          selectedContactToReassign: queriedContact,
          reassignDialogOpen: true,
          loading: false,
        });
        return;
      }
      this.setState({
        loading: false,
      });
    } else if (deactivate === true && reassign === false) {
      // const removeContactRelationshipMutationsResults = new Array<Promise<any>>();
      const client = auth.apolloClient;
      let stateChanged = false;
      if (client !== null) {
        const contactToDeactivate = this.state.selectedRowToDeactivate;
        if (typeof contactToDeactivate !== 'undefined') {
          const { hash_key, locations } = contactToDeactivate;
          this.setState({
            loading: true,
          });
          if (locations) {
            let n = 0;
            const mutations: Array<Array<string>> = [];
            let tempMutations: Array<string> = [];

            // Build mutations
            locations.forEach((location) => {
              n += 1;
              const mutation = `m${n}: ${upsertRelationshipMutation(
                location.hash_key.toString(),
                hash_key,
                true,
                location.gsi2_hash_key?.toString(),
                location.gsi2_range_key?.toString(),
              )}`;
              if (n === 999) {
                mutations.push([...tempMutations]);
                tempMutations = [mutation];
              } else {
                tempMutations.push(mutation);
              }
            });
            mutations.push([...tempMutations]);

            // Run mutations:
            const contactsRelationshipResolvedPromises: Array<Promise<any>> = [];
            const rows: Array<Contact> = [];
            mutations.forEach((tempMutation) => {
              // eslint-disable-next-line no-async-promise-executor
              const mutationPromise = new Promise(async (res, rej) => {
                try {
                  if (tempMutation && tempMutation.length > 0) {
                    const fullMutation = gql`mutation Mutation{${tempMutation}}`;
                    const mutationResults = await client.mutate({
                      mutation: fullMutation,
                    });

                    const { data } = mutationResults;
                    if (data) {
                      Object.keys(data).forEach((key: string) => {
                        rows.push(data[key]);
                      });
                    }
                  }
                  res([]);
                } catch (e) {
                  rej(e);
                }
              });
              contactsRelationshipResolvedPromises.push(mutationPromise);
            });

            await Promise.all(contactsRelationshipResolvedPromises);
          }

          const contactIsDeletedMutation = client.mutate({
            mutation: gql`
            mutation Mutation($hash_key: String!, $input: UpdateContactInput!) {
              ${updateCustomerContactRAW}
            }`,
            variables: {
              hash_key: contactToDeactivate.hash_key,
              input: {
                updateContactFields: {
                  is_deleted: true,
                  cell_phone: contactToDeactivate.cell_phone,
                  work_phone: contactToDeactivate.work_phone,
                  email_address: contactToDeactivate.email_address,
                },
              },
            },
          });
          await contactIsDeletedMutation;

          this.setState((prevState) => ({
            filteredRows: prevState.filteredRows
              ? prevState.filteredRows.filter(
                  (x) =>
                    (x && contactToDeactivate && x.hash_key !== contactToDeactivate.hash_key) || !contactToDeactivate,
                )
              : prevState.data,
            deactivateDialogOpen: false,
            selectedRowToDeactivate: undefined,
            loading: false,
          }));
          this.loadTable();
          stateChanged = true;
        }
      }
      if (!stateChanged) {
        this.setState({
          deactivateDialogOpen: false,
          selectedRowToDeactivate: undefined,
          loading: false,
        });
      }
    } else {
      this.setState({
        deactivateDialogOpen: false,
        selectedRowToDeactivate: undefined,
        loading: false,
      });
    }
  }

  componentDidMount() {
    this.loadTable();
  }

  renderResultsCounter(resultsCount: any) {
    const count = resultsCount > 0 ? resultsCount : 0;
    return (
      <span>
        {this.props.t('{{resultsCount}} out of {{totalCount}} Contacts', {
          resultsCount: count,
          totalCount: this.state.queriedContacts.length,
        })}
      </span>
    );
  }

  createContact() {
    this.props.history.push(`${getBaseUrl()}/contacts/create`);
  }

  render() {
    const createPermission = auth.usePermissions()?.allowsAction('contacts.create') || false;
    const deletePermission = auth.usePermissions()?.allowsAction('contacts.delete') || false;
    const updatePermission = auth.usePermissions()?.allowsAction('contacts.update') || false;
    const listPermission = auth.usePermissions()?.allowsAction('contacts.list') || false;
    const readPermission = auth.usePermissions()?.allowsAction('contacts.read') || false;
    const overrideDeletePermission = auth.usePermissions()?.allowsAction('contacts.override_delete') || false;

    if (!listPermission) {
      // Has no permissions to list
      this.props.enqueueSnackbar(this.props.t('User has no permissions to list contacts.'), { variant: 'warning' });
      this.props.history.push(getBaseUrl());
    } else if (!readPermission) {
      // Has no permissions to read
      this.props.enqueueSnackbar(this.props.t('User has no permissions to read contacts.'), { variant: 'warning' });
      this.props.history.push(getBaseUrl());
    }

    if (this.state.error) return <PlainMessage title={this.state.error.title} messages={this.state.error.messages} />;
    if (this.state.loading)
      return <LoadingBounce style={{ height: !this.props.popupMode ? CONTENT_AREA_HEIGHT : '70vh' }} />;
    if (!this.state.data)
      return (
        <PlainMessage title={this.props.t('Error loading data')} messages={[this.props.t('Data object is empty...')]} />
      );

    return (
      <>
        <DataGrid
          id="contactListTable"
          dataSource={this.state.filteredRows}
          columnAutoWidth
          keyExpr="hash_key"
          className={!this.props.popupMode ? 'freespaced-table' : undefined}
          style={!this.props.popupMode ? { maxHeight: CONTENT_AREA_HEIGHT } : { maxHeight: 'calc(100vh - 188px)' }}
          onContentReady={(e: any) => {
            const count: number = e.component.totalCount();
            if (count !== this.state.resultsCount) {
              this.setState({
                resultsCount: count,
              });
            }
          }}
          onEditorPreparing={(e: any) => {
            if (e.parentType === 'searchPanel') {
              e.updateValueTimeout = 1700;
            }
          }}
          onToolbarPreparing={this.onToolbarPreparing.bind(this)}
        >
          <Template name="filterFields" render={this.renderFilterFields.bind(this)} />
          <FilterRow visible />
          <SearchPanel visible highlightCaseSensitive key="searchPanelContactList" />
          <Template name="resultsCounter" render={() => this.renderResultsCounter(this.state.resultsCount)} />
          <Scrolling mode="virtual" />
          {this.props.additionalColumns}
          <Column
            caption={this.props.t('Level')}
            cellRender={({ row }) => <CreatedByAvatar customerType={row.data.contact_level} />}
            alignment="center"
          />
          <Column
            caption={this.props.t('Name & Account Info')}
            /* eslint-disable-next-line @typescript-eslint/no-use-before-define */
            cellRender={({ row }) => <RenderNameAndAccountInfo row={row.data} />}
            allowSorting
            calculateSortValue={this.sortByName}
            calculateCellValue={this.getSearchText.bind(this)}
            allowSearch
          />
          <Column caption={this.props.t('Contact Types')} cellRender={({ row }) => this.renderContactTypes(row.data)} />
          <Column
            caption={this.props.t('Assigned Locations & Priority Level(s)')}
            cellRender={({ row }) => this.renderPriorityLevelAndLocations(row.data)}
          />
          <Column
            caption=""
            visible={!this.state.massiveDelete}
            alignment="center"
            cellRender={({ row }) => this.renderActionButtons(row.data, deletePermission, updatePermission)}
          />
          <Column
            caption=""
            visible={this.state.massiveDelete}
            alignment="center"
            cellRender={({ row }) => this.renderActionCheckbox(row.data, deletePermission, updatePermission)}
          />
        </DataGrid>

        {/* Once the Contact clicks on the Delete/Remove button */}
        <DeactivateContactDialog
          open={this.state.deactivateDialogOpen}
          row={this.state.selectedRowToDeactivate}
          handleClose={this.deactivateContactDialogHandler.bind(this)}
          michelinOffersToDeactivate={this.state.locatedMichelinOffersToDeactivate}
          primaryOrSecondaryLevelToDeactivate={this.state.primaryOrSecondaryLevelToDeactivate}
        />

        {/* Select contact types to Reassign */}
        {typeof this.state.selectedContactToReassign !== 'undefined' ? (
          <ReassignContactTypesDialog
            alreadySelected={new Map()}
            open={this.state.reassignDialogOpen}
            locationsFilters={new Set()}
            handleClose={this.reassignSelectedDialogHandler.bind(this)}
            contact={this.state.selectedContactToReassign}
            secondaryDependencies={this.state.secondaryConflicts}
          />
        ) : undefined}
        <ContactGlobalActions
          stNumber={auth.getCustomerNumber()}
          selectedRows={this.state.selectedRows}
          createPermission={createPermission}
          deletePermission={deletePermission}
          massiveDelete={this.state.massiveDelete}
          overrideDeletePermission={overrideDeletePermission}
          handleClickDelete={() => this.setState((prevState) => ({ massiveDelete: !prevState.massiveDelete }))}
          createContact={this.createContact.bind(this)}
          clearRows={() => this.setState({ selectedRows: [] })}
          loadTable={this.loadTable.bind(this)}
        />
      </>
    );
  }
}

interface RenderNameAndAccountInfoProps {
  row: Contact;
  contactMethod?: 'email' | 'work' | 'cell' | 'fax' | 'all';
}

export function renderContactInformation(row: Contact, method: string = 'all'): JSX.Element {
  const icnStyle = { height: 16, verticalAlign: 'bottom', marginTop: 4 };
  const contactBlock = (data: string | null | undefined, Icon: any, defaultValue: string) => {
    if (method !== 'all' && method.trim().toLowerCase() !== defaultValue) return null;
    if (method === 'all' && (!data || !data.trim())) return null;
    return (
      <span style={{ whiteSpace: 'nowrap' }}>
        <Icon style={{ ...icnStyle, marginLeft: -6 }} />
        {(data || '').trim() || `${method} not set...`}
        {row.preferred_method && row.preferred_method.toLowerCase() === defaultValue ? (
          <Icons.Star style={icnStyle} />
        ) : null}
        <br />
      </span>
    );
  };

  let workPhone = row.work_phone;
  if (workPhone && row.ext) {
    workPhone += ` ext. ${row.ext}`;
  }
  return (
    <div style={{ marginTop: 2 }}>
      {contactBlock(row.cell_phone, Icons.PhoneAndroid, 'cell')}
      {contactBlock(workPhone, Icons.Phone, 'work')}
      {contactBlock(row.email_address, Icons.Email, 'email')}
      {method === 'fax' ? contactBlock(row.fax, Icons.ReceiptTwoTone, 'fax') : null}
    </div>
  );
}

export function RenderNameAndAccountInfo(props: RenderNameAndAccountInfoProps) {
  const { row, contactMethod } = props;
  const history = useHistory();
  const permissions = usePermissions();
  if (!row) return null;
  const readPermission = permissions.allowsAction('contacts.read');

  return (
    <div>
      {readPermission ? (
        <Link
          onClick={() => history.push(`${getBaseUrl()}/contacts/${row.hash_key.slice(2, row.hash_key.length)}/view`)}
          id={`link{${row.hash_key}}`}
          style={{ cursor: 'pointer', fontWeight: 'bold' }}
        >
          {`${row.first_name} ${row.last_name}`}
        </Link>
      ) : (
        <b>{`${row.first_name} ${row.last_name}`}</b>
      )}
      {renderContactInformation(row, contactMethod)}
    </div>
  );
}

export default withDialogs(withTranslation()(withSnackbar(withRouter(ContactList))));
