import { IAssigneeCustomerType } from 'components/TireProfile/Assignee/AssigneeType';
import { BillingPreferences, BillingProfileLocation, BillingProfileTypes } from '../query';
import { DefaultProfiles } from 'modules/Billing';

export function transformBillingLocationToAsignee(locations: BillingProfileLocation[]) {
  const mappedLocations: IAssigneeCustomerType[] = locations.map((x) => ({
    hash_key: x.hash_key,
    range_key: 'v0_customer',
    customer_addr1: x.customer_addr1,
    customer_addr2: x.customer_addr2,
    customer_city: x.customer_city,
    customer_name: x.customer_name,
    customer_number: x.customer_number,
    customer_state: x.customer_state,
    customer_type: x.customer_type,
    customer_zip: x.customer_zip,
    extrnl_cust_id: x.extrnl_cust_id,
    relationship: x.relationship,
    ship_to_customer: x.ship_to_customer || 'null',
    bill_to_customer: x.bill_to_customer || 'null',
    home_office_number: x.home_office_number || 'null',
    parent_company_number: x.parent_company_number || 'null',
  }));

  return mappedLocations;
}

export function transformAsigneeToBillingLocation(locations: IAssigneeCustomerType[]): any {
  const assignmentsToBillingProfileLocations = locations.map((x) => ({
    assigned: true,
    relationship: x.relationship,
    hash_key: x.hash_key,
    customer_number: x.customer_number,
    extrnl_cust_id: x.extrnl_cust_id,
    customer_name: x.customer_name,
    customer_addr1: x.customer_addr1,
    customer_addr2: x.customer_addr2,
    customer_city: x.customer_city,
    customer_state: x.customer_state,
    customer_zip: x.customer_zip,
    customer_type: x.customer_type,
    ship_to_customer: x.ship_to_customer,
    bill_to_customer: x.bill_to_customer,
    home_office_number: x.home_office_number,
    parent_company_number: x.parent_company_number,
  }));
  return assignmentsToBillingProfileLocations;
}

export function isDefaultProfile(billingProfile: BillingPreferences, defaultProfiles: DefaultProfiles) {
  if (!billingProfile || !billingProfile.owner) return false;

  const oldSetToAll = billingProfile.general_information.set_all_locations;
  // eslint-disable-next-line no-param-reassign
  billingProfile.general_information.set_all_locations = true;

  // eslint-disable-next-line no-param-reassign
  billingProfile.general_information.set_all_locations = oldSetToAll;

  let defaultAlreadyExists =
    (defaultProfiles as any)[
      `${billingProfile.general_information.service_type}_${billingProfile.general_information.profile_type}`
    ] ||
    (defaultProfiles as any)[`${billingProfile.general_information.service_type}_${BillingProfileTypes.ers_onsite}`];

  if (
    `${billingProfile.general_information.service_type}_${billingProfile.general_information.profile_type}` ===
    `${billingProfile.general_information.service_type}_${BillingProfileTypes.ers_onsite}`
  ) {
    defaultAlreadyExists =
      (defaultProfiles as any)[`${billingProfile.general_information.service_type}_${BillingProfileTypes.ers}`] ||
      (defaultProfiles as any)[`${billingProfile.general_information.service_type}_${BillingProfileTypes.onsite}`] ||
      (defaultProfiles as any)[`${billingProfile.general_information.service_type}_${BillingProfileTypes.ers_onsite}`];
  }

  return !defaultAlreadyExists;
}
