import gql from 'graphql-tag';
import { SubBillingRequirements } from './utilsRequirements';

/**
 * Here are the Billing Data Types and Queries needed by BillingPreferences View
 */
export const GET_BILLING_PREFERENCES = gql`
  query BillingPreferences($hash_key: String!) {
    getBillingPrefs(hash_key: $hash_key, range_key: "v0_billing") {
      assigned_locations
      gsi1_hash_key
      owner_key
      owner_relationship
      owner {
        hash_key
        range_key
        customer_type
        customer_number
      }
      general_information {
        default
        service_type
        profile_type
        participates_in_approve_orders
        profile_name
        po_required_for_service
        when_should_po_be_provided
        driver_name_required
        driver_signature_required
        set_all_locations
      }
      ers_purchasing_procedures {
        required_authorization_to_begin_service # String
        primary_method_to_request_service # String
        secondary_method_to_request_service # String
        method_to_receive_signed_order # String
        pri_phone_number # AWSPhone
        sec_phone_number # AWSPhone
        ers_ship_to_hash_key # String
        pri_tire_po_issuers # Array
        sec_tire_po_issuers # Array
        required_for_ers_event # Bool
        signed_order_receiver_location
        signed_order_receiver_contact
        signed_order_receiver_other
        pri_ers_authorized_contact_type # Array
        pri_ers_authorized_contact_level
        sec_ers_authorized_contact_type # Array
        sec_ers_authorized_contact_level
        ers_st_location {
          customer_name
          customer_city
          customer_state
          parent_company_number
          home_office_number
          bill_to_customer
          ship_to_customer
        }
        receiver_location {
          customer_name
          customer_city
        }
        receiver_contact {
          email_address
          fax
        }
      }
      onsite_purchasing_procedures {
        required_authorization_to_begin_service # String
        primary_method_to_request_service # String
        secondary_method_to_request_service # String
        method_to_receive_signed_order # String
        pri_phone_number # AWSPhone
        sec_phone_number # AWSPhone
        pri_tire_po_issuers # Array
        sec_tire_po_issuers # Array
        signed_order_receiver_location
        signed_order_receiver_contact
        signed_order_receiver_other
        pri_ers_authorized_contact_type # Array
        pri_ers_authorized_contact_level
        sec_ers_authorized_contact_type # Array
        sec_ers_authorized_contact_level
        receiver_location {
          customer_name
          customer_city
        }
        receiver_contact {
          email_address
          fax
        }
      }
      approvals {
        ers_pre_approvals_limits {
          maximum_num_of_tires
          maxium_num_of_wheels
          maximum_dollar_amount
        }
        onsite_pre_approvals_limits {
          maximum_num_of_tires
          maxium_num_of_wheels
          maximum_dollar_amount
        }
      }
      tire_details {
        off_installed
        requested_details
        other
        id
        billing_tire_casing_age
      }
      wheel_details {
        off_installed
        requested_details
        other
        id
      }
      requested_photos {
        types
        identifier_type
        other_photos
      }
    }
  }
`;

export const GET_BILLING_REQUIREMENTS = gql`
  query CustomerAssets($hash_key: String!) {
    getCustomerAssets(hash_key: $hash_key, range_key: "v0_billing") {
      billingRequirements {
        po {
          required
          flag
          mask
          alternate_mask
          stockable
          comments
        }
        fleet_dr_approval {
          required
          flag
          mask
          alternate_mask
          stockable
          comments
        }
      }
    }
  }
`;

const customerType: string = `
  customer {
    hash_key
    range_key
    customer_number
    extrnl_cust_id
    customer_name
    customer_addr1
    customer_addr2
    customer_city
    customer_state
    customer_zip
    customer_type
    parent_company_number
    home_office_number
    bill_to_customer
    ship_to_customer
  }
`;

const contactType: string = `
    is_deleted
  contact {
        hash_key
        is_deleted
    contact_id
    first_name
    last_name
    cell_phone
    work_phone
        email_address
        num_of_tires
        num_of_wheels
        tire_dollar_amount
        contact_types {
            service
            role_areas {
                role_area
                levels {
                    level
                    location
                }
            }
            id
        }
  }
`;

export const queryContactList = gql`
  query ContactList($hash_key: String!, $range_key: String!) {
      getCustomerAssets(hash_key: $hash_key, range_key: $range_key, filterDeleted: true) {
          ${customerType}
      contactLocationRelationship {
        ${contactType}
      }
          childAssets {
            ${customerType}
        contactLocationRelationship {
          ${contactType}
        }
             childAssets {
                 ${customerType}
          contactLocationRelationship {
            ${contactType}
          }
                 childAssets {
                   ${customerType}
            contactLocationRelationship {
              ${contactType}
            }
          }
        }
      }
    }
  }
`;

export const GET_BILLING_LOCATIONS = gql`
  query getAssignedBillingProfileLocations($profile_id: String!, $relationship: String!, $slim: Boolean) {
    getAssignedBillingProfileLocations(profile_id: $profile_id, relationship: $relationship, slim: $slim) {
      hash_key
      assigned
      customer_number
      extrnl_cust_id
      customer_name
      customer_addr1
      customer_addr2
      customer_city
      customer_state
      customer_zip
      customer_type
      relationship
    }
  }
`;

export const UPDATE_SPECIAL_CONTACTS = gql`
  mutation updateContactsForBilling($input: [BillingContactInput]) {
    updateContactsForBilling(input: $input)
  }
`;

export const UPDATE_BILLING_PROFILE = gql`
  mutation updateBillingProfile($profile: BillingPreferencesInput!, $locations: [BillingLocation]) {
    updateBillingProfile(profile: $profile, locations: $locations)
  }
`;

export const GET_CONTACT_BY_KEY = `
  getContactByKey(hash_key: "$hash_key", range_key: "v0_contact") {
    hash_key,
    range_key,
    contact_id,
    first_name,
    last_name,
        cell_phone,
        contact_types {
            service
            role_areas {
                role_area
                levels {
                    level
                    location
                }
            }
            id
        },
    work_phone,
    contact_level,
    ext,
    fax,
    email_address,
    num_of_tires,
    num_of_wheels,
    tire_dollar_amount,
    locations {
      hash_key
      is_deleted
    }
  }
`;

// TYPES ABOVE

export type BillingProfileLocation = {
  assigned: boolean;
  relationship: string;
  hash_key: string;
  customer_number: string;
  extrnl_cust_id: string;
  customer_name: string;
  customer_addr1: string;
  customer_addr2: string;
  customer_city: string;
  customer_state: string;
  customer_zip: string;
  customer_type: string;
  ship_to_customer: string;
  home_office_number: string;
  parent_company_number: string;
  bill_to_customer: string;
};

// TYPES BELOW

// INTERFACES ABOVE

export interface BillingRequirementsPreferencesQuery {
  getCustomerAssets: {
    billingRequirements: Pick<SubBillingRequirements, 'po' | 'fleet_dr_approval'>;
  };
}

interface BillingApprovalsLimits {
  maximum_num_of_tires?: number;
  maxium_num_of_wheels?: number;
  maximum_dollar_amount?: number;
}

export interface BillingGeneralInformation {
  default: boolean;
  service_type: BillingServiceTypes;
  profile_type: string;
  participates_in_approve_orders?: boolean;
  profile_name: string;
  po_required_for_service: boolean;
  when_should_po_be_provided?: BillingPOProvidedTypes;
  driver_name_required?: boolean;
  driver_signature_required?: boolean;
  set_all_locations?: boolean;
}

export interface BillingPurchasingProceduresContact {
  first_name?: string;
  last_name?: string;
  cell_phone?: string;
  work_phone?: string;
  hash_key?: string;
}

export interface BillingPurchasingProcedures {
  required_authorization_to_begin_service: string;
  primary_method_to_request_service: string;
  secondary_method_to_request_service: string;
  method_to_receive_signed_order: string;
  pri_phone_number?: string;
  sec_phone_number?: string;
  pri_tire_po_issuers?: BillingPurchasingProceduresContact[];
  sec_tire_po_issuers?: BillingPurchasingProceduresContact[];
  ers_ship_to_hash_key?: string;
  required_for_ers_event?: boolean;
  signed_order_receiver_location?: string;
  signed_order_receiver_contact?: string;
  signed_order_receiver_other?: string;
  pri_ers_authorized_contact_type?: string[];
  pri_ers_authorized_contact_level?: string;
  sec_ers_authorized_contact_type?: string[];
  sec_ers_authorized_contact_level?: string;
  ers_st_location: ERSTLocation;
  receiver_location?: ERSTLocation;
  receiver_contact?: ReceiverContact;
}

export interface ReceiverContact {
  email_address: string;
  fax: string;
}

export interface ERSTLocation {
  customer_name: string;
  customer_city: string;
  customer_state: string;
  parent_company_number: string;
  home_office_number: string;
  bill_to_customer: string;
  ship_to_customer: string;
  customer_type?: string;
  customer_addr1?: string;
  customer_addr2?: string;
  customer_zip?: string;
}

export interface BillingApprovals {
  ers_pre_approvals_limits?: BillingApprovalsLimits;
  onsite_pre_approvals_limits?: BillingApprovalsLimits;
}

export interface BillingTireRequestedInstalledDetails {
  off_installed?: BillingTireOffInstalled;
  requested_details?: BillingTireRequestedDetails[];
  other?: string;
  billing_tire_casing_age?: string;
  id: string;
}

export interface BillingWheelRequestedInstalledDetails {
  off_installed?: BillingWheelOffInstalled;
  requested_details?: BillingWheelRequestedDetails[];
  other?: string;
  id: string;
}

export interface BillingRequestedPhotos {
  types?: Array<string>;
  identifier_type?: BillingRequestedPhotosIdentifierType;
  other_photos?: string;
}

export interface BillingPreferencesLocation {
  hash_key: string;
  customer_number?: number;
  customer_type?: string;
  customer_name?: string;
}

export interface BillingPreferencesLocationRelationship {
  location?: BillingPreferencesLocation;
}

export interface BillingPreferences {
  hash_key: string;
  range_key: string;
  gsi1_hash_key: string;
  gsi1_range_key: string;
  gsi2_hash_key?: string;
  gsi2_range_key?: string;
  owner_key?: string;
  owner_relationship?: string;
  owner?: {
    hash_key: string;
    range_key: string;
    customer_type: string;
    customer_number: string;
  };
  locations?: Array<BillingPreferencesLocationRelationship>;
  general_information: BillingGeneralInformation;
  ers_purchasing_procedures?: BillingPurchasingProcedures;
  onsite_purchasing_procedures?: BillingPurchasingProcedures;
  approvals?: BillingApprovals;
  tire_details?: Array<BillingTireRequestedInstalledDetails>;
  wheel_details?: Array<BillingWheelRequestedInstalledDetails>;
  requested_photos: BillingRequestedPhotos;
  assigned_locations: Array<string>;
}

export interface QueryBillingPreferences {
  getBillingPrefs: BillingPreferences;
}

export interface QueryBillingLocations {
  getAssignedBillingProfileLocations: BillingProfileLocation[];
}

export interface BillingContact {
  first_name: string;
  last_name: string;
  num_of_tires: number;
  num_of_wheels: number;
  tire_dollar_amount: number;
  locations: Array<BillingContactLocationRelationship>;
  work_phone: string;
  cell_phone: string;
  email_address: string;
}

export interface BillingContactLocation {
  customer_number: number;
  customer_type: string;
}

export interface BillingContactLocationRelationship {
  contact?: BillingContact;
  location?: BillingContactLocation;
}

export interface BillingContacts {
  contactLocationRelationship: Array<BillingContactLocationRelationship>;
}

export interface BillingPreferencesInput {
  hash_key: string;
  range_key: string;
  general_information: BillingGeneralInformation;
  approvals: BillingApprovals;
  ers_purchasing_procedures?: BillingPurchasingProcedures;
  onsite_purchasing_procedures?: BillingPurchasingProcedures;
  tire_details?: BillingTireRequestedInstalledDetails[];
  wheel_details?: BillingWheelRequestedInstalledDetails[];
  owner_key: string;
  owner_relationship: string;
  requested_photos: BillingRequestedPhotos;
  is_deleted: boolean;
  gsi4_hash_key: string;
  gsi4_range_key: string;
}

// INTERFACES BELOW

// ENUMS ABOVE

export enum BillingServiceTypes {
  TIRE_WHEEL = 'tire_wheel',
  MECHANICAL = 'mechanical',
}

// export enum BillingProfileTypes {
//   ers = 'ERS',
//   onsite = 'Onsite',
//   ers_onsite = 'ERS & Onsite'
// }
export enum BillingProfileTypes {
  ers = 'ers',
  onsite = 'onsite',
  ers_onsite = 'ers_onsite',
}

// type signedType = 'Select' | 'email' | 'fax' | 'mail' | 'other' | 'provide_to_driver';
export enum MethodOfSignedOrder {
  Select = 'Select',
  email = 'email',
  fax = 'fax',
  mail = 'mail',
  other = 'other',
  provide_to_driver = 'provide_to_driver',
}

export enum BillingPOProvidedTypes {
  BEFORE = 'before',
  DURING = 'during',
  AFTER = 'after',
}

export enum BillingAuthBeginServiceTypes {
  ERS_SERVICE = 'ers_service',
  ONCALL_EVENT_NUMBER = 'oncall_event_number',
  ONSITE_SERVICE = 'onsite_service',
  PO = 'po',
  FLEET_REFERENCE_NUMBER = 'flee_reference_number',
  CONTRACT_NUMBER = 'contract_number',
  FLEET_CARD = 'fleet_card',
}

export enum BillingMethodRequestServiceTypes {
  DRIVER = 'driver',
  CALL_WORK = 'call_work',
  CALL_CELL = 'call_cell',
  EMAIL = 'email',
}

export enum BillingMethodReceiveSignedOrderTypes {
  EMAIL = 'email',
  FAX = 'fax',
  PROVIDE_TO_DRIVER = 'provide_to_driver',
  MAIL = 'mail',
}

export enum BillingWheelOffInstalled {
  OFF_WHEELS = 'off_wheels',
  INSTALLED_WHEELS = 'installed_wheels',
}

export enum BillingTireOffInstalled {
  OFF_TIRES = 'off_tires',
  INSTALLED_TIRES = 'installed_tires',
}

export enum BillingTireRequestedDetails {
  TIRE_CASING_BRAND = 'tire_casing_brand',
  DOT = 'dot',
  SIZE = 'size',
  TIRE_CASING_TREAD_DESIGN = 'tire_casing_tread_design',
  TIRE_CASING_TYPE = 'tire_casing_type',
  REMAINING_TREAD_DEPTH = 'remaining_tread_depth',
  OTHER = 'other',
}

export enum BillingWheelRequestedDetails {
  TYPE = 'type',
  OFFSET = 'offset',
  SIZE = 'size',
  OTHER = 'other',
  MATERIAL = 'material',
}

export enum BillingRequestedPhotosTypes {
  NONE = 'none',
  OFF_TIRE = 'off_tire',
  CLOSE_UP_TIRE_DAMAGE = 'close_up_tire_damage',
  WORK_ORDER = 'work_order',
  TIRE_IDENTIFIER = 'tire_identifier',
  OTHER = 'other',
}

export enum BillingRequestedPhotosIdentifierType {
  ASSET_NUMBER = 'asset_number',
  CUSTOMER_BRAND = 'customer_brand',
  DOT = 'dot',
  RFID = 'rfid',
}

export enum BillingTireCasingAge {
  NEVER_RETREADED_LESS_THAN_7_YR = 'never_retreaded_less_than_7_yr',
  RETREADED_ONCE_LESS_THAN_7_YR = 'retreaded_once_less_than_7_yr',
  RETREADED_TWICE_LESS_THAN_7_YR = 'retreaded_twice_less_than_7_yr',
  OLDER_THAN_7_YR = 'older_than_7_yr',
}

export enum AuthorizationService {
  Select = 'Select',
  break_down_phone_number = 'break_down_phone_number',
  driver = 'driver',
  fleet_contact = 'fleet_contact',
}
