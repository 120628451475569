import React, { useContext, useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { AccountAvatar } from '@michelin/acid-components';
import { CentralProviderContext, Location, useMaestroDataSource, useTranslation } from '@michelin/central-provider';
import { AccountLevel } from '@michelin/central-provider/dist/compositions/CustomerList/CustomerList';
import {
  getLocationAcidAccountNumbers,
  getLocationAcidAddress,
} from '@michelin/central-provider/dist/compositions/CustomerList/utils';
import { DataGrid, Template } from 'devextreme-react';
import { Column, SearchPanel } from 'devextreme-react/data-grid';

// eslint-disable-next-line max-len
let openLocationSelector: (
  title: string,
  loc: Location | undefined,
  onClose: null | (() => void),
  onSelected: null | ((l: any) => void),
) => void = () => {};

export function useLocationSelector() {
  return {
    selectLocation: openLocationSelector,
  };
}
interface LocationListItemProps {
  cf: any;
  cellView: boolean;
  onClick: (cf: Location) => void;
}

function LocationListItem({ cf, onClick, cellView }: LocationListItemProps): JSX.Element {
  return (
    <div onClick={() => onClick(cf)}>
      <AccountAvatar
        name={cf.customer_dba_name || cf.customer_name || ' '}
        accountType={cf.customer_type as AccountLevel}
        address={getLocationAcidAddress(cf)}
        addressDisplay={cellView ? 'cityState' : 'full'}
        accountNumbers={getLocationAcidAccountNumbers(cf)}
        style={{ cursor: 'pointer', whiteSpace: 'normal' }}
      />
    </div>
  );
}

export function LocationSelectorProvider(props: any) {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [location, setLocation] = useState<undefined | Location>(undefined);
  const [onSelected, setOnSelected] = useState<null | ((l: any) => void)>(null);
  const [onClose, setOnClose] = useState<null | (() => void)>(null);
  const { t } = useTranslation();
  const { application } = useContext(CentralProviderContext);
  const theme = useTheme();
  const cellView = useMediaQuery(theme.breakpoints.down(500));

  const ds = useMaestroDataSource({
    API: { method: 'GET', endpoint: 'central/users/accounts', baseURL: process.env.REACT_APP_API_BASE_URL || '' },
    reqOptions: {
      filters: {
        app_key: application,
        root: location ? location?.customer_number : undefined,
      },
    },
    componentPlacements: {
      searchPlacement: cellView ? 'outside' : 'embeded',
    },
  });

  const closeFn = () => {
    setOpen(false);
    setLocation(undefined);
    setOnClose(null);
    setOnSelected(null);
    setTitle('');
    if (onClose) onClose();
  };

  useEffect(() => {
    openLocationSelector = (
      newTitle: string,
      loc: Location | undefined,
      onCloseCallback: null | (() => void),
      onSelectedCallback: null | ((l: any) => void),
    ) => {
      setTitle(newTitle);
      setLocation(loc);
      setOnClose(() => onCloseCallback);
      setOnSelected(() => onSelectedCallback);
      setOpen(true);
    };
  }, [setOpen]);

  return (
    <>
      {props.children}
      {open && (
        <Dialog open fullWidth maxWidth="md" className="customerFleetListDialog" onClose={closeFn}>
          <DialogTitle disableTypography style={{ margin: 0, padding: theme.spacing(2) }}>
            <Typography variant="h6">{title}</Typography>
            {onClose ? (
              <IconButton
                aria-label="Close"
                style={{
                  position: 'absolute',
                  right: theme.spacing(1),
                  top: theme.spacing(1),
                  color: theme.palette.grey[500],
                }}
                onClick={closeFn}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent dividers>
            <div className="customerFleetList">
              <div>
                {cellView ? (
                  <div style={{ marginBottom: -10, textAlign: 'right' }}>{ds.renderResultsCounter()}</div>
                ) : undefined}
              </div>
              <DataGrid
                dataSource={ds.dataSource}
                remoteOperations
                noDataText={t('No accounts found...')}
                style={{ height: '60vh' }}
                className="freespaced-table"
                scrolling={{ mode: 'virtual', rowRenderingMode: 'virtual' }}
                allowColumnReordering
                repaintChangesOnly
                onToolbarPreparing={ds.onToolbarPreparing}
              >
                <Template name="resultsCounter" render={ds.renderResultsCounter} />
                <SearchPanel visible highlightSearchText={false} placeholder={t('Search...')} />
                <Column
                  dataField="account"
                  dataType="string"
                  cellRender={({ row }) => (
                    <LocationListItem
                      onClick={() => {
                        setOpen(false);
                        if (onSelected) onSelected(row.data);
                        closeFn();
                      }}
                      cf={row.data}
                      cellView={cellView}
                    />
                  )}
                />
              </DataGrid>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
