import React, { useEffect, useMemo, useState } from 'react';
import { Link, useMediaQuery, useTheme } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import { Add } from '@material-ui/icons';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ListIcon from '@material-ui/icons/List';
import MessageIcon from '@material-ui/icons/Message';
import { makeStyles } from '@material-ui/styles';
import {
  Container,
  Fab,
  FilterGroup,
  GlobalActions,
  Panel,
  useDialogs,
  useListSelectFilter,
} from '@michelin/acid-components';
import {
  clearMaestroDataSourceCache,
  useMaestroDataSource,
  usePermissions,
  useSnackbar,
  useTranslation,
} from '@michelin/central-provider';
import { WheelProfileOVM } from '@michelin/fcp-view-models/dist/models/wheel-profile';
import { getOptionLabel, getOptions } from '@michelin/select-options-provider';
import { auth } from 'Auth';
import CreatedByAvatar from 'components/CreatedByAvatar';
import { NewLocationAssignments } from 'components/NewLocationAssignments';
import { CONTENT_AREA_HEIGHT } from 'components/Util';
import { DataGrid } from 'devextreme-react';
import { Template } from 'devextreme-react/core/template';
import { Column, Button as DevXButton, SearchPanel } from 'devextreme-react/data-grid';
import GLOBAL_CONFIG from 'global-variables';
import { useDeleteWheelProfile } from 'hooks/useWheelsProfile';
import { getBaseUrl } from 'prefs-and-service-offers';
import { useHistory } from 'react-router-dom';
import WheelListComments from '../Components/WheelListComments';
import WheelListDetails from '../Components/WheelListDetails';
import { IPermissions } from '../types';

const useStyles = makeStyles((theme: any) => ({
  rightAlign: {
    textAlign: 'right',
  },
  marginTop: {
    marginTop: '13px',
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  margin: {
    margin: 2,
  },
}));

interface DataError {
  name: string;
  message: string;
  code: number;
  className: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: { field: string; level: string; message: string; replacements: string; errors: any };
}

type WheelsListProps = {
  action: 'add' | 'edit' | 'list';
  serviceOfferIds: string;
};

const baseURL = GLOBAL_CONFIG.externalAPIUrl;

export default function WheelsTable(props: WheelsListProps) {
  const { confirmDialog } = useDialogs();
  const { t } = useTranslation();
  const history = useHistory();
  const permissions = usePermissions();
  const theme = useTheme();
  const cellView = useMediaQuery(theme.breakpoints.down(500));
  const { enqueueSnackbar } = useSnackbar();
  const { location } = usePermissions();
  const accountId = location?.customer_number || '';
  const [profileFilterValue, setProfileFilterValue] = useState('all');
  const [creatorFilterValue, setCreatorFilterValue] = useState('all');
  const [conditionFilterValue, setConditionFilterValue] = useState('all');
  const [axlePositionFilterValue, setAxlePositionFilterValue] = useState('all');
  const [xOneFilterValue, setXOneFilterValue] = useState('all');

  const xOneValues = [
    { label: 'All', order: null, value: 'all' },
    { label: 'Yes', order: null, value: 'true' },
    { label: 'No', order: null, value: 'false' },
  ];

  const wheelPermisions: IPermissions = {
    read: permissions.allowsAction('wheels.read'),
    create: permissions.allowsAction('wheels.create'),
    update: permissions.allowsAction('wheels.update'),
    delete: permissions.allowsAction('wheels.delete'),
    list: permissions.allowsAction('wheels.list'),
  };

  const deleteWheelProfile = useDeleteWheelProfile();

  const profileTypeFilter = useListSelectFilter({
    label: t('Profile Type'),
    options: getOptions('profile_types', 'all', t('All')),
    defaultValue: profileFilterValue,
    hashParam: 'profile_type',
  });

  const creatorFilter = useListSelectFilter({
    label: t('Creator'),
    options: getOptions('account_types', 'all', t('All')),
    defaultValue: creatorFilterValue,
    hashParam: 'creator',
  });

  const conditionFilter = useListSelectFilter({
    label: t('Condition'),
    options: getOptions('wheel_conditions', 'all', t('All')),
    defaultValue: conditionFilterValue,
    hashParam: 'wheel_condition',
  });

  const axlePositionFilter = useListSelectFilter({
    label: t('Axle Position'),
    options: getOptions('axle_types', 'all', t('All')),
    defaultValue: axlePositionFilterValue,
    hashParam: 'axle_type',
  });

  const xOneFilter = useListSelectFilter({
    label: t('X One'),
    options: xOneValues,
    defaultValue: xOneFilterValue,
    hashParam: 'is_x_one',
  });

  const filterGroup = useMemo(
    () =>
      new FilterGroup([
        creatorFilter.filter,
        profileTypeFilter.filter,
        conditionFilter.filter,
        axlePositionFilter.filter,
        xOneFilter.filter,
      ]),
    [
      creatorFilter.filter,
      profileTypeFilter.filter,
      conditionFilter.filter,
      axlePositionFilter.filter,
      xOneFilter.filter,
    ],
  );

  const ds = useMaestroDataSource({
    API: {
      method: 'GET',
      endpoint: `/cp-be/wheels/${accountId}`,
      baseURL,
    },
    reqOptions: {
      filters: {
        profile_type: profileTypeFilter.value === 'all' ? '' : profileTypeFilter.value,
        creator: creatorFilter.value === 'all' ? '' : creatorFilter.value?.toLowerCase(),
        wheel_condition: conditionFilter.value === 'all' ? '' : conditionFilter.value?.toLowerCase(),
        axle_type: axlePositionFilter.value === 'all' ? '' : axlePositionFilter.value?.toLowerCase(),
        is_x_one: xOneFilter.value === 'all' ? '' : xOneFilter.value?.toLowerCase(),
      },
    },
    componentPlacements: {
      searchPlacement: cellView ? 'outside' : 'embeded',
    },
    filterGroup,
    cacheKey: `wheels-${location?.customer_number || ''}`,
  });

  useEffect(() => {
    if (profileTypeFilter.value) {
      setProfileFilterValue(profileTypeFilter.value);
    }
  }, [profileTypeFilter.value]);

  useEffect(() => {
    if (creatorFilter.value) {
      setCreatorFilterValue(creatorFilter.value);
    }
  }, [creatorFilter.value]);

  useEffect(() => {
    if (conditionFilter.value) {
      setConditionFilterValue(conditionFilter.value);
    }
  }, [conditionFilter.value]);

  useEffect(() => {
    if (axlePositionFilter.value) {
      setAxlePositionFilterValue(axlePositionFilter.value);
    }
  }, [axlePositionFilter.value]);

  useEffect(() => {
    if (xOneFilter.value) {
      setXOneFilterValue(xOneFilter.value);
    }
  }, [xOneFilter.value]);

  useEffect(() => {
    setProfileFilterValue('all');
    setCreatorFilterValue('all');
    setConditionFilterValue('all');
    setAxlePositionFilterValue('all');
    setXOneFilterValue('all');
  }, [props.serviceOfferIds]);

  useEffect(
    () => () => {
      clearMaestroDataSourceCache(`wheels-${permissions.location?.customer_number || ''}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleFabCreateClick = () => {
    history.push(`${getBaseUrl()}/wheel-details/create`);
  };

  const reloadAfterAction = () => {
    async function reload() {
      clearMaestroDataSourceCache(`wheels-${permissions.location?.customer_number || ''}`);
      await ds.dataSource.reload();
    }
    reload();
  };

  const ActionButtonsControl = () => (
    <GlobalActions>
      <Fab
        color="primary"
        label={t('Create')}
        disabled={!wheelPermisions || !wheelPermisions.create}
        onClick={handleFabCreateClick}
      >
        <Add />
      </Fab>
    </GlobalActions>
  );

  const confirmDeleteProfile = (wp: WheelProfileOVM) => {
    if (!wp.detail) return;

    confirmDialog(
      t('Are you sure you want to delete this wheel profile?'),
      t('Delete Wheel Profile'),
      t('Yes'),
      t('No'),
      async () => {
        await deleteProfile(wp);
      },
      undefined,
      { disableBackdropClick: true, disableEscapeKeyDown: true },
    );
  };

  const deleteProfile = async (wheelProfile: WheelProfileOVM | undefined) => {
    if (!wheelProfile || !wheelProfile.id || !wheelProfile?.owner?.customer_number) return;

    try {
      const response = await deleteWheelProfile.mutateAsync({
        fleetId: wheelProfile.owner?.customer_number,
        wheelId: wheelProfile.id,
      });
      if (!response) {
        enqueueSnackbar(t('Error deleting Wheel Profile'), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar(t('Wheel Profile Deleted'), {
          variant: 'success',
        });
      }
    } catch (error) {
      enqueueSnackbar(t('Error deleting Wheel Profile'), {
        variant: 'error',
      });
    }
    reloadAfterAction();
  };

  const renderProfileId = (e: any) => {
    const wp: WheelProfileOVM = e.data;
    if (!wp.detail) return null;
    if (wheelPermisions && wheelPermisions.read) {
      return (
        <Link onClick={() => history.push(`${getBaseUrl()}/wheel-details/view/${wp.id}`)} style={{ cursor: 'pointer' }}>
          {wp.detail.name}
        </Link>
      );
    }
    return wp.detail.name;
  };

  const renderXOneCell = (e: any) => {
    const wp: WheelProfileOVM = e.data;
    if (!wp.detail) return null;
    if (wp.detail.is_x_one !== 'true') return null;
    return <CheckCircleOutlineIcon fontSize="small" />;
  };

  const NewLocationsCell = (e: any) => {
    const profileData = e.row.data;
    return (
      <NewLocationAssignments
        owner={profileData.gsi1_hash_key.split('~')[1]}
        mode="view"
        all={profileData.detail.assign_to_all_locations}
        locations={profileData.locations.map((rel: any) => ({ hash_key: rel.range_key }))}
        disabled={false}
      />
    );
  };

  const renderWheelDetailsCell = (e: any) => {
    const wp: WheelProfileOVM = e.data;
    if (!wp.detail) return null;

    return <ShowWheelDetailsPopover wheelData={wp.detail} />;
  };

  const ShowWheelDetailsPopover = (localProps: any) => {
    const cls = useStyles(localProps);

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
      <>
        {localProps.wheelData && (
          <Link
            aria-label="tire-sizes"
            className={cls.margin}
            style={{ cursor: 'pointer', textDecoration: 'none' }}
            color="primary"
            onClick={(event: any) => handlePopoverOpen(event)}
            onMouseLeave={handlePopoverClose}
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
          >
            <ListIcon />
            <Popover
              id="mouse-over-popover"
              className={cls.popover}
              classes={{
                paper: cls.paper,
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <WheelListDetails detail={localProps.wheelData} />
            </Popover>
          </Link>
        )}
      </>
    );
  };

  const renderCommentsCell = (e: any) => {
    const wp: WheelProfileOVM = e.data;
    if (!wp.detail || !wp.detail.comments || wp.detail.comments.length === 0) return null;

    return <ShowCommentsPopover wheelData={wp.detail} />;
  };

  const renderProfileTypeCell = (e: any) => {
    const wp: WheelProfileOVM = e.data;
    if (!wp.detail) return null;

    const label: string = getOptionLabel('profile_types', wp.detail.profile_type || '');
    return <div>{label}</div>;
  };

  const renderWheelConditionCell = (e: any) => {
    const wp: WheelProfileOVM = e.data;
    if (!wp.detail) return null;
    const label: string = getOptionLabel('wheel_conditions', wp.detail.wheel_condition || '');
    return <div>{label}</div>;
  };

  const renderAxlePositionCell = (e: any) => {
    const wp: WheelProfileOVM = e.data;
    if (!wp.detail) return null;
    const label: string = getOptionLabel('axle_types', wp.detail.axle_position || '');
    return <div>{label}</div>;
  };

  const ShowCommentsPopover = (localProps: any) => {
    const cls = useStyles(localProps);

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
      <>
        {localProps.wheelData && (
          <Link
            aria-label="tire-sizes"
            className={cls.margin}
            style={{ cursor: 'pointer', textDecoration: 'none' }}
            color="primary"
            onClick={(event: any) => handlePopoverOpen(event)}
            onMouseLeave={handlePopoverClose}
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
          >
            <MessageIcon fontSize="small" color="primary" />
            <Popover
              id="mouse-over-popover"
              className={cls.popover}
              classes={{
                paper: cls.paper,
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <WheelListComments detail={localProps.wheelData} />
            </Popover>
          </Link>
        )}
      </>
    );
  };

  if (wheelPermisions && wheelPermisions.list === false) {
    // Has no permissions to list
    enqueueSnackbar(t('User has no permissions to list wheels.'), { variant: 'warning' });
    history.push(getBaseUrl());
  } else if (wheelPermisions && wheelPermisions.read === false) {
    enqueueSnackbar(t('User has no permissions to read wheels.'), { variant: 'warning' });
    history.push(getBaseUrl());
  }

  return (
    <>
      <ActionButtonsControl />
      <Container>
        <Panel spacing={0}>
          <DataGrid
            id="wheelsTable"
            remoteOperations
            wordWrapEnabled
            columnAutoWidth
            showBorders
            noDataText={t('No Wheel Profiles')}
            className="freespaced-table"
            style={{ height: CONTENT_AREA_HEIGHT }}
            dataSource={ds.dataSource}
            onToolbarPreparing={ds.onToolbarPreparing}
            scrolling={{ mode: 'virtual', rowRenderingMode: 'virtual' }}
            onDataErrorOccurred={(e) => {
              if (e.error) {
                const error = JSON.parse(e.error.message) as DataError[];
                e.error.message = error[0].message;
              }
            }}
          >
            <Template name="filterFields" render={ds.renderFilterFields} />
            <Template name="resultsCounter" render={ds.renderResultsCounter} />
            <SearchPanel visible highlightSearchText={false} placeholder={t('Search...')} />

            <Column
              caption={t('Creator')}
              alignment="center"
              dataField="creator"
              cellRender={({ row }) => (
                <CreatedByAvatar
                  customerType={row.data.owner.customer_type}
                  customerNumber={row.data.owner.customer_number}
                />
              )}
              allowSearch={false}
            />

            <Column
              caption={t('Name')}
              alignment="center"
              cellRender={renderProfileId}
              dataField="name"
              dataType="number"
              defaultSortIndex={0}
              defaultSortOrder="asc"
            />

            <Column caption="" dataField="hash_key" visible={false} allowSearch={false} />

            <Column caption={t('Profile Type')} dataField="profile_type" cellRender={renderProfileTypeCell} />
            <Column caption={t('Condition')} dataField="condition" cellRender={renderWheelConditionCell} />
            <Column caption={t('Axle Position')} dataField="axle_position" cellRender={renderAxlePositionCell} />
            <Column
              caption={t('Diameter')}
              dataField="diameter"
              cellRender={({ row }: { row: { data: WheelProfileOVM } }) => `${row?.data?.detail?.wheel_diameter}` || ''}
            />
            <Column
              caption={t('Rim Width')}
              dataField="rim_width"
              cellRender={({ row }: { row: { data: WheelProfileOVM } }) => `${row?.data?.detail?.rim_width}` || ''}
            />
            <Column
              caption={t('X One')}
              alignment="center"
              cellRender={renderXOneCell}
              allowSearch={false}
              allowSorting={false}
            />

            <Column
              caption={t('More...')}
              alignment="center"
              allowSorting={false}
              cellRender={renderWheelDetailsCell}
              allowSearch={false}
            />
            <Column
              caption={t('Comments')}
              alignment="center"
              allowSorting={false}
              dataField="detail.comments"
              cellRender={renderCommentsCell}
              allowSearch={false}
            />

            <Column
              visible={auth.getAccountTypeShort() !== 'ST'}
              caption={t('Ship To Locations')}
              allowSorting={false}
              width={150}
              cellRender={({ row }) => <NewLocationsCell row={row} />}
              dataField="locationAssignments"
              allowSearch={false}
            />

            <Column type="buttons" width={110} allowSearch={false} allowSorting={false}>
              <DevXButton
                aria-label="delete"
                text={t('Delete')}
                icon="trash"
                hint={t('Delete')}
                visible={({ row }: { row: { data: WheelProfileOVM } }) => row?.data?.allowedActions?.delete}
                onClick={({ row }: { row: { data: WheelProfileOVM } }) => confirmDeleteProfile(row.data)}
              />
            </Column>
          </DataGrid>
        </Panel>
      </Container>
    </>
  );
}
