import React, { useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { AccountAvatar, Button, Switch } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { BillingPreferences, BillingPurchasingProcedures } from '../query';
import { PurchasingProcValidations } from '../utils';
import AssignShipToDialog from './AssignShipToDialog';

const useStyles = makeStyles(() => ({
  red_border: {
    borderColor: 'red',
    borderStyle: 'solid',
    borderWidth: 'thin',
  },
  no_border: {
    borderStyle: 'none',
  },
}));

interface Props {
  profile: BillingPreferences;
  purchasing_procedure: BillingPurchasingProcedures;
  validationErrors: PurchasingProcValidations;
  editFlag: boolean;
  ers: boolean;
  editProfile: (profile: BillingPreferences) => void;
  setModifiedFlag: (modified: boolean) => void;
}

export default function ErsShipToSelection(props: Props): JSX.Element {
  const { profile, purchasing_procedure, validationErrors, editFlag, ers, editProfile, setModifiedFlag } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const [assignLocationDialogOpen, setAssignLocationDialogOpen] = useState(false);

  const updateReqForErsEvent = (newValue?: boolean) => {
    const newProfile = { ...profile };
    purchasing_procedure.required_for_ers_event = newValue;
    if (ers) {
      newProfile.ers_purchasing_procedures = purchasing_procedure;
    }
    setModifiedFlag(true);
    editProfile(newProfile);
  };

  const onSTAssigned = (st_hash_key?: string, customer_obj?: any) => {
    const newProfile = { ...profile };
    purchasing_procedure.ers_ship_to_hash_key = st_hash_key;
    purchasing_procedure.ers_st_location = {
      customer_name: customer_obj.customer_name,
      customer_city: customer_obj.customer_city,
      customer_state: customer_obj.customer_state,
      parent_company_number: customer_obj.parent_company_number,
      home_office_number: customer_obj.home_office_number,
      bill_to_customer: customer_obj.bill_to_customer,
      ship_to_customer: customer_obj.ship_to_customer,
    };
    if (ers) {
      newProfile.ers_purchasing_procedures = purchasing_procedure;
    }
    validationErrors.ers_ship_to = false;
    setModifiedFlag(true);
    editProfile(newProfile);
  };

  return (
    <>
      <Grid item sm={12} lg={4}>
        <label style={{ width: '100%', fontSize: '10px' }}>{t('ERS Ship To Number (for Billing Purposes)')}</label>
        <Button
          style={{ cursor: 'pointer' }}
          size="small"
          variant="text"
          fullWidth
          disabled={!editFlag}
          onClick={() => setAssignLocationDialogOpen(true)}
          color="primary"
        >
          {!purchasing_procedure.ers_st_location && (
            <AccountAvatar
              accountType="ST"
              name=""
              accountDisplay="short"
              addressDisplay="cityState"
              style={{ cursor: 'pointer' }}
              className={classes.no_border}
              address={{
                city: '',
                state: '',
              }}
              accountNumbers={{
                parentCompany: '',
                homeOffice: '',
                billTo: '',
                shipTo: '',
              }}
              terminalId=""
            />
          )}
          {purchasing_procedure.ers_st_location && (
            <AccountAvatar
              accountType="ST"
              name={purchasing_procedure.ers_st_location.customer_name}
              accountDisplay="short"
              addressDisplay="cityState"
              style={{ cursor: 'pointer' }}
              className={validationErrors.ers_ship_to ? classes.red_border : classes.no_border}
              address={{
                city: purchasing_procedure.ers_st_location.customer_city,
                state: purchasing_procedure.ers_st_location.customer_state,
              }}
              accountNumbers={{
                parentCompany: purchasing_procedure.ers_st_location.parent_company_number,
                homeOffice: purchasing_procedure.ers_st_location.home_office_number,
                billTo: purchasing_procedure.ers_st_location.bill_to_customer,
                shipTo: purchasing_procedure.ers_st_location.ship_to_customer,
              }}
              terminalId=""
            />
          )}
        </Button>
      </Grid>
      <Grid item sm={12} lg={4} style={{ marginTop: '1.7rem' }}>
        <Switch
          checked={purchasing_procedure.required_for_ers_event || false}
          name="terminal/location"
          onChange={(event: React.ChangeEvent<any>) => updateReqForErsEvent(event.target.checked)}
          readOnly={!editFlag}
          label={t('Terminal/Location ID Required for ERS Event?')}
          labelPosition="left"
        />
      </Grid>
      <AssignShipToDialog
        onClose={() => setAssignLocationDialogOpen(false)}
        selectedSTHashKey={purchasing_procedure.ers_ship_to_hash_key}
        onSTAssigned={onSTAssigned}
        assignLocationDialogOpen={assignLocationDialogOpen}
      />
    </>
  );
}
