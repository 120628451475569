import React, { useContext, useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { Select } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { SelectOption } from 'components/Select';
import { BillingProfileContext } from '..';
import { setLocationsWithPrimaryAndSecondary } from '../ReassignContactTypes/utils';
import { BillingPurchasingProcedures } from '../query';
import { PurchasingProcValidations } from '../utils';

interface Props {
  primaryContactRequired: boolean;
  purchasing_procedure: BillingPurchasingProcedures;
  validationErrors: PurchasingProcValidations;
  options_sorted_account_types: SelectOption[];
  editFlag: boolean;
  updateContactLevel: any;
  launchContactReassignWorkFlow: any;
}

export default function ContactLevelToAuthorize(props: Props): JSX.Element {
  const {
    primaryContactRequired,
    purchasing_procedure,
    updateContactLevel,
    validationErrors,
    options_sorted_account_types,
    editFlag,
    launchContactReassignWorkFlow,
  } = props;

  const { contacts, expLocations, assignedContactTypes } = useContext(BillingProfileContext);
  const contactLevel = primaryContactRequired
    ? purchasing_procedure.pri_ers_authorized_contact_level
    : purchasing_procedure.sec_ers_authorized_contact_level;
  const [locationLevelCount, setLocationLevelCount] = useState(0);
  const [requiresAssignation, setRequiresAssignation] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const contactTypes = primaryContactRequired
      ? purchasing_procedure.pri_ers_authorized_contact_type
      : purchasing_procedure.sec_ers_authorized_contact_type;
    const cLevel = primaryContactRequired
      ? purchasing_procedure.pri_ers_authorized_contact_level
      : purchasing_procedure.sec_ers_authorized_contact_level;
    const x = setLocationsWithPrimaryAndSecondary(
      cLevel || '',
      contacts,
      expLocations,
      contactTypes || [],
      assignedContactTypes,
      primaryContactRequired ? 'primary' : 'secondary',
    );
    setLocationLevelCount(x.filteredLocationsByLevel.length);
    setRequiresAssignation(x.neededToAssignContactLocations.length);
  }, [
    primaryContactRequired,
    purchasing_procedure.pri_ers_authorized_contact_level,
    purchasing_procedure.pri_ers_authorized_contact_type,
    purchasing_procedure.sec_ers_authorized_contact_level,
    purchasing_procedure.sec_ers_authorized_contact_type,
    contacts,
    expLocations,
    assignedContactTypes,
  ]);

  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Select
          label={t('Contact Level to Authorize Service')}
          value={contactLevel || ''}
          onChange={(event) => updateContactLevel(event.target.value)}
          fullWidth
          error={primaryContactRequired ? validationErrors.primary_contact_level : validationErrors.sec_contact_level}
          options={options_sorted_account_types}
          readOnly={!editFlag}
          disabled={!options_sorted_account_types.length}
        />
        {editFlag && contactLevel && options_sorted_account_types.length > 0 && (
          <div
            style={{ marginLeft: 8 }}
            onClick={() => {
              launchContactReassignWorkFlow();
            }}
          >
            <Edit color="primary" style={{ cursor: 'pointer' }} />
          </div>
        )}
      </div>
      {requiresAssignation > 0 && editFlag && (
        <Typography color="error" variant="body1" style={{ marginTop: 8 }}>
          {t(
            '{{amount}} out of {{totalAmount}} {{contactLevel}} Locations are missing contacts with the necessary contact types to authorize service. Click edit to update contacts at these locations.',
            { amount: requiresAssignation, totalAmount: locationLevelCount, contactLevel },
          )}
        </Typography>
      )}
    </div>
  );
}
