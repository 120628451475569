import React, { FunctionComponent, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { ArrowBack, Cancel, Edit, Save, Timelapse } from '@material-ui/icons';
import {
  Fab,
  GlobalActions,
  LoadingBounce,
  Panel,
  PreventTransitionPrompt,
  Select,
  Switch,
  TextField,
  useDialogs,
} from '@michelin/acid-components';
import { usePermissions, useTranslation } from '@michelin/central-provider';
import { WheelProfileDetailOVM, WheelProfileOVM } from '@michelin/fcp-view-models/dist/models/wheel-profile';
import { SelectOption, getOptions } from '@michelin/select-options-provider';
import LastModified from 'components/ChangeLog/LastModified';
import { PlainMessage } from 'components/Messages';
import { NewLocationAssignments } from 'components/NewLocationAssignments';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { scrollToDisplayFirstError } from 'components/Util';
import {
  useGetRimWidths,
  useGetWheelDiameters,
  useGetWheelProfile,
  useUpdateWheelProfile,
} from 'hooks/useWheelsProfile';
import { useSnackbar } from 'notistack';
import { getBaseUrl } from 'prefs-and-service-offers';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { BrandPreferencesComponent } from './Components/BrandPreferencesComponent';
import { IPermissions, WheelDetailsBrand } from './types';

type IProps = {
  action: string;
  location: {
    state: {
      action: string;
      miscData: {
        createId: number;
        onCallFlag: boolean;
      };
    };
  };
};

interface ILocationAssigned {
  hash_key: string;
  relationship: string;
}

interface DetailUI extends Omit<WheelProfileDetailOVM, 'assigned_locations'> {
  assigned_locations: ILocationAssigned[];
}

interface WheelProfileOVMDetail extends Omit<WheelProfileOVM, 'detail'> {
  detail: DetailUI;
}
interface viewParams {
  selectedAccount: string;
  profileId: string;
}

export const WheelProfileDetails: FunctionComponent<IProps> = (props: IProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { errorDialog, confirmDialog } = useDialogs();
  const { t } = useTranslation();
  const history = useHistory();
  const permissions = usePermissions();
  const params = useParams<viewParams>();

  const [wheelDiametersOptions, setWheelDiametersOptions] = useState<SelectOption[]>();
  const [rimWidthsOptions, setRimWidthsOptions] = useState<SelectOption[]>();
  const [saving, setSaving] = useState<boolean>(false);

  const wheelPermisions: IPermissions = {
    read: permissions.allowsAction('wheels.read'),
    create: permissions.allowsAction('wheels.create'),
    update: permissions.allowsAction('wheels.update'),
    delete: permissions.allowsAction('wheels.delete'),
    list: permissions.allowsAction('wheels.list'),
  };

  // Control permissions
  if (wheelPermisions && wheelPermisions.read === false) {
    enqueueSnackbar(t('Your user does not have enough permissions to read the information about this wheel profile.'), {
      variant: 'warning',
    });
    history.push(getBaseUrl());
  } else if (wheelPermisions && wheelPermisions.update === false && props.action.toLowerCase() === 'edit') {
    enqueueSnackbar(t('Your user does not have enough permissions to edit the information about this wheel profile.'), {
      variant: 'warning',
    });
    history.push(getBaseUrl());
  } else if (wheelPermisions && wheelPermisions.create === false && props.action.toLowerCase() === 'create') {
    enqueueSnackbar(t('Your user does not have enough permissions to create a wheel profile.'), { variant: 'warning' });
    history.push(getBaseUrl());
  }
  const { control, handleSubmit, getValues, setValue, formState, reset, trigger } = useForm<WheelProfileOVMDetail>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      detail: {
        assigned_locations: [],
        id: '',
        name: '',
        axle_position: 'all_axles',
        is_x_one: 'false',
        wheel_diameter: '',
        rim_width: '',
        wheel_condition: 'new',
        wheel_type: '',
        wheel_material: '',
        wheel_position: 'all',
        wheel_offset: '0',
        color_finish: '',
        operating_company: '',
        assign_to_all_locations: (permissions?.location?.customer_type || '').toLowerCase() === 'st',
        comments: '',
        profile_type: '',
        brands: [],
      },
    },
  });
  const isDirty = Object.keys(formState.dirtyFields).length > 0;
  const wheelProfileGet = useGetWheelProfile({
    fleetId: params.selectedAccount,
    wheelProfileId: params.profileId ? params.profileId : '',
  });
  const updateWheelProfileMutation = useUpdateWheelProfile({
    fleetId: params.selectedAccount,
    wheelProfileId: params.profileId ? params.profileId : '',
  });
  const wheelDiametersData = useGetWheelDiameters();
  useEffect(() => {
    if (wheelDiametersData.data) setWheelDiametersOptions(wheelDiametersData.data);
  }, [wheelDiametersData]);
  const rimWidhtsData = useGetRimWidths();
  useEffect(() => {
    if (rimWidhtsData.data) setRimWidthsOptions(rimWidhtsData.data);
  }, [rimWidhtsData]);

  useEffect(() => {
    setSaving(false);
  }, [props.action]);

  useEffect(() => {
    if (wheelProfileGet.data && props.action.toLowerCase() === 'view') {
      let auxWheelProfile = wheelProfileGet.data as WheelProfileOVMDetail | WheelProfileOVM;
      const assignedLocations: ILocationAssigned[] = [];
      if (
        wheelProfileGet.data.detail?.assigned_locations &&
        wheelProfileGet.data.detail.assigned_locations?.length > 0
      ) {
        wheelProfileGet.data.detail.assigned_locations.forEach((loc: string) => {
          const hierarchy: string[] = loc.replace(/~null/g, '').split('~');
          assignedLocations.push({
            hash_key: hierarchy[hierarchy.length - 1],
            relationship: loc,
          });
        });
      }

      auxWheelProfile = {
        ...auxWheelProfile,
        detail: {
          ...auxWheelProfile.detail,
          assigned_locations: assignedLocations,
        },
      };
      reset(auxWheelProfile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wheelProfileGet.data, props.action]);

  const handleFabEditClick = () => {
    history.push(`${getBaseUrl()}/wheel-details/edit/${params.profileId}`);
  };

  // Actions Buttons Functions
  const handleFabBackClick = () => {
    history.push(`${getBaseUrl()}/wheels`);
  };

  const handleFabCancelClick = () => {
    if (props.action.toLowerCase() === 'edit') {
      history.push(`${getBaseUrl()}/wheel-details/view/${params.profileId}`);
    } else {
      history.push(`${getBaseUrl()}/wheels`);
    }
  };

  const yesSave = async () => {
    const values = getValues();
    const dataToUpdate = { ...values };
    if (dataToUpdate.detail && !dataToUpdate?.detail?.id) {
      dataToUpdate.detail.id = dataToUpdate.detail.name;
    }
    if (dataToUpdate.detail && !dataToUpdate?.detail?.name) {
      dataToUpdate.detail.name = dataToUpdate.detail.id;
    }
    if (dataToUpdate.detail && dataToUpdate.detail.color_finish === '') delete dataToUpdate.detail.color_finish;

    if (dataToUpdate.detail && dataToUpdate.detail.operating_company === '')
      delete dataToUpdate.detail.operating_company;

    if (dataToUpdate.detail && dataToUpdate.detail.comments === '') delete dataToUpdate.detail.comments;

    if (props.action.toLowerCase() === 'create') {
      let auxAssignedLocations: string[] = [];
      if (dataToUpdate.detail) {
        if (dataToUpdate.detail?.assign_to_all_locations) {
          dataToUpdate.detail.assigned_locations = [];
        } else {
          auxAssignedLocations = dataToUpdate.detail.assigned_locations.map((assignment) =>
            assignment.hash_key ? assignment.hash_key.split('~')[1] : '',
          );
          dataToUpdate.detail.assigned_locations = [];
        }
      }
      const auxDetail = { ...dataToUpdate.detail, assigned_locations: auxAssignedLocations } as WheelProfileDetailOVM;
      try {
        const response = await updateWheelProfileMutation.mutateAsync({
          fleetId: params.selectedAccount,
          wheelProfile: { detail: { ...auxDetail } },
          action: 'add',
        });
        enqueueSnackbar(t('Successfully saved profile'), {
          variant: 'success',
        });

        if (response?.hash_key) {
          const hashKey = response.hash_key;
          const profileId = hashKey.includes('~') ? hashKey.split('~')[1] : hashKey;
          history.push(`${getBaseUrl()}/wheel-details/view/${profileId}`);
        } else {
          history.push(`${getBaseUrl()}/wheels`);
        }
      } catch (error) {
        enqueueSnackbar(t('Error saving wheel profile.'), {
          variant: 'error',
        });
        setSaving(false);
      }
    } else {
      let auxAssignedLocations: string[] = [];
      if (dataToUpdate.detail) {
        if (dataToUpdate.detail?.assign_to_all_locations) {
          dataToUpdate.detail.assigned_locations = [];
        } else {
          auxAssignedLocations = dataToUpdate.detail.assigned_locations.map((assignment) =>
            assignment.relationship ? assignment.relationship : 'null',
          );
          dataToUpdate.detail.assigned_locations = [];
        }
      }
      const auxDetail = { ...dataToUpdate.detail, assigned_locations: auxAssignedLocations } as WheelProfileDetailOVM;
      try {
        await updateWheelProfileMutation.mutateAsync({
          fleetId: params.selectedAccount,
          wheelProfileId: params.profileId ? params.profileId : '',
          wheelProfile: { detail: { ...auxDetail } },
          action: 'edit',
        });
        enqueueSnackbar(t('Successfully saved profile'), {
          variant: 'success',
        });
        history.push(`${getBaseUrl()}/wheel-details/view/${params.profileId}`);
      } catch (error) {
        enqueueSnackbar(t('Error saving wheel profile.'), {
          variant: 'error',
        });
        setSaving(false);
      }
    }
    return null;
  };

  const noSave = () => {
    setSaving(false);
  };

  const handleFabSaveClick = async () => {
    setSaving(true);
    if (!isDirty) {
      setSaving(false);
      return null;
    }

    // competitor acknowledgement
    const values = getValues();
    if (values.detail?.brands && values.detail.brands.length > 0) {
      confirmDialog(
        t(
          'Michelin does not guarantee the availability of the wheel brand specified through its ' +
            'independent dealer network. By clicking Yes, your changes will be saved but you are ' +
            'acknowledging and agreeing that Michelin does not make this guarantee. To cancel your ' +
            'changes and return to this page, click No.',
        ),
        t('Save Changes'),
        t('Yes'),
        t('No'),
        () => yesSave(),
        () => noSave(),
      );
    } else {
      yesSave();
    }
  };

  const onError = (errors: any) => {
    const msg = [];

    Object.keys(errors.detail).forEach((err) => {
      msg.push(`• ${t(errors['detail'][err]['message'])}`);
    });

    if (msg.length) {
      msg.unshift(t('The following fields are required:'), ' ');
      errorDialog(msg, t('Missing required fields'), t('Ok'));
      scrollToDisplayFirstError();
    } else {
      errorDialog('Plase check the required fields', t('Missing required fields'), t('Ok'));
    }
  };

  const ActionButtonsControlFab = () => (
    <GlobalActions>
      <Fab
        color="primary"
        label={t('Edit')}
        onClick={handleFabEditClick}
        disabled={props.action.toLowerCase() !== 'view' || getValues()?.allowedActions?.update === false}
      >
        <Edit />
      </Fab>
      <Fab
        color={isDirty ? 'primary' : 'inherit'}
        disabled={
          props.action.toLowerCase() === 'view' || !isDirty || Object.keys(formState.errors).length !== 0 || saving
        }
        label={t('Save')}
        onClick={handleSubmit(handleFabSaveClick, onError)}
        displayDisabled={props.action.toLowerCase() !== 'view' && !saving}
      >
        <Save />
      </Fab>
      <Fab
        color={'primary'}
        disabled={props.action.toLowerCase() === 'view' || saving}
        label={t('Cancel')}
        onClick={handleFabCancelClick}
      >
        <Cancel />
      </Fab>
      <Fab color="inherit" label={t('Saving')} disabled={!saving}>
        <Timelapse />
      </Fab>
      <Fab color="primary" label={t('Back')} disabled={props.action !== 'view'} onClick={handleFabBackClick}>
        <ArrowBack />
      </Fab>
    </GlobalActions>
  );

  if (wheelProfileGet.isError) {
    return (
      <Panel variant="none" style={{ border: 0 }}>
        <Grid container>
          <Grid item xs={12} spacing={2}>
            <PlainMessage
              title={t('Database Error')}
              messages={[
                t('Application could not load wheel profile data.'),
                t('Please try again later or contact support if the error persists'),
              ]}
            />
          </Grid>
        </Grid>
      </Panel>
    );
  }

  if (wheelProfileGet.isLoading && props.action !== 'create') return <LoadingBounce />;
  return (
    <>
      <PageTitle
        title={props.action.toLowerCase() === 'create' ? t('Create Wheel Profile') : t('Wheel Profile Details')}
        rightItems={
          props.action.toLowerCase() === 'create' ? null : (
            <LastModified entity="wheel_profile" identifier={`${params.profileId}`} />
          )
        }
      />
      <ActionButtonsControlFab />
      {(props.action === 'create' || wheelProfileGet.data) && (
        <div
          style={{
            padding: 16,
          }}
        >
          <Panel>
            <Grid container spacing={2} style={{ paddingBottom: '12px' }}>
              <Grid item xs={12} sm={6} lg={3}>
                <Controller
                  name="detail.name"
                  control={control}
                  rules={{
                    required: { value: true, message: t('Profile Name') },
                  }}
                  render={(item) => (
                    <TextField
                      id={item.field.name}
                      name="name"
                      label={t('Profile Name')}
                      type="string"
                      variant="standard"
                      value={item.field.value || ''}
                      error={!!item.formState.errors.detail?.name}
                      onChange={(e) => item.field.onChange(e.target.value)}
                      readOnly={props.action === 'view'}
                      required
                      helperText={!!item.formState.errors.detail?.name ? t('Profile name is required').toString() : ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Controller
                  name="detail.profile_type"
                  control={control}
                  rules={{
                    required: { value: true, message: t('Profile Type') },
                  }}
                  render={(item) => (
                    <Select
                      id={item.field.name}
                      name="profile_type"
                      label={t('Profile Type')}
                      value={item.field.value}
                      error={!!item.formState.errors.detail?.profile_type}
                      readOnly={props.action === 'view'}
                      helperText={!!item.formState.errors.detail?.profile_type ? t('Profile type is required') : ''}
                      options={getOptions('profile_types')}
                      fullWidth
                      required
                      onChange={(e) => item.field.onChange(e.target.value)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Controller
                  name="detail.axle_position"
                  control={control}
                  rules={{
                    required: { value: true, message: t('Axle Position') },
                  }}
                  render={(item) => (
                    <Select
                      id={item.field.name}
                      name="axel_position"
                      label={t('Axle Position')}
                      value={item.field.value}
                      error={!!item.formState.errors.detail?.axle_position}
                      readOnly={props.action === 'view'}
                      helperText={!!item.formState.errors.detail?.axle_position ? t('Axle position is required') : ''}
                      options={getOptions('axle_types')}
                      fullWidth
                      required
                      onChange={(e) => item.field.onChange(e.target.value)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Controller
                  name="detail.wheel_condition"
                  control={control}
                  rules={{
                    required: { value: true, message: t('Wheel Condition') },
                  }}
                  render={(item) => (
                    <Select
                      id={item.field.name}
                      name="wheel_condition"
                      label={t('Wheel Condition')}
                      value={item.field.value}
                      error={!!item.formState.errors.detail?.wheel_condition}
                      readOnly={props.action === 'view'}
                      helperText={
                        !!item.formState.errors.detail?.wheel_condition ? t('Wheel condition is required') : ''
                      }
                      options={getOptions('wheel_conditions')}
                      fullWidth
                      required
                      onChange={(e) => item.field.onChange(e.target.value)}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={3}>
                <Controller
                  name="detail.rim_width"
                  control={control}
                  rules={{
                    required: { value: true, message: t('Rim Width') },
                  }}
                  render={(item) => (
                    <Select
                      id={item.field.name}
                      name="rim_width"
                      label={t('Rim Width')}
                      value={item.field.value}
                      error={!!item.formState.errors.detail?.rim_width}
                      readOnly={props.action === 'view'}
                      helperText={!!item.formState.errors.detail?.rim_width ? t('Rim width is required') : ''}
                      options={
                        rimWidthsOptions ? rimWidthsOptions : [{ value: item.field.value, label: item.field.value }]
                      }
                      fullWidth
                      required
                      onChange={(e) => item.field.onChange(e.target.value)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Controller
                  name="detail.wheel_diameter"
                  control={control}
                  rules={{
                    required: { value: true, message: t('Wheel Diameter') },
                  }}
                  render={(item) => (
                    <Select
                      id={item.field.name}
                      name="wheel_diameter"
                      label={t('Wheel Diameter')}
                      value={item.field.value}
                      error={!!item.formState.errors.detail?.wheel_diameter}
                      readOnly={props.action === 'view'}
                      helperText={!!item.formState.errors.detail?.wheel_diameter ? t('Wheel diameter is required') : ''}
                      options={
                        wheelDiametersOptions
                          ? wheelDiametersOptions
                          : [{ value: item.field.value, label: item.field.value }]
                      }
                      fullWidth
                      required
                      onChange={(e) => item.field.onChange(e.target.value)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Controller
                  name="detail.wheel_type"
                  control={control}
                  rules={{
                    required: { value: true, message: t('Wheel Type') },
                  }}
                  render={(item) => (
                    <Select
                      id={item.field.name}
                      name="wheel_type"
                      label={t('Wheel Type')}
                      value={item.field.value}
                      error={!!item.formState.errors.detail?.wheel_type}
                      readOnly={props.action === 'view'}
                      helperText={!!item.formState.errors.detail?.wheel_type ? t('Wheel type is required') : ''}
                      options={getOptions('wheel_types')}
                      fullWidth
                      required
                      onChange={(e) => item.field.onChange(e.target.value)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Controller
                  name="detail.wheel_material"
                  control={control}
                  rules={{
                    required: { value: true, message: t('Wheel Material') },
                  }}
                  render={(item) => (
                    <Select
                      id={item.field.name}
                      name="wheel_material"
                      label={t('Wheel Material')}
                      value={item.field.value}
                      error={!!item.formState.errors.detail?.wheel_material}
                      readOnly={props.action === 'view'}
                      helperText={!!item.formState.errors.detail?.wheel_material ? t('Wheel material is required') : ''}
                      options={getOptions('wheel_materials')}
                      fullWidth
                      required
                      onChange={(e) => item.field.onChange(e.target.value)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Controller
                  name="detail.wheel_offset"
                  control={control}
                  rules={{
                    required: { value: true, message: t('Wheel Offset') },
                  }}
                  render={(item) => (
                    <Select
                      id={item.field.name}
                      name="wheel_offset"
                      label={t('Wheel Offset')}
                      value={item.field.value}
                      error={!!item.formState.errors.detail?.wheel_offset}
                      readOnly={props.action === 'view' || getValues()?.detail?.is_x_one !== 'true'}
                      helperText={!!item.formState.errors.detail?.wheel_offset ? t('Wheel offset is required') : ''}
                      options={getOptions('wheel_offsets')}
                      fullWidth
                      required
                      onChange={(e) => item.field.onChange(e.target.value)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Controller
                  name="detail.wheel_position"
                  control={control}
                  rules={{
                    required: { value: true, message: t('Wheel Position') },
                  }}
                  render={(item) => (
                    <Select
                      id={item.field.name}
                      name="wheel_position"
                      label={t('Wheel Position')}
                      value={item.field.value}
                      error={!!item.formState.errors.detail?.wheel_position}
                      readOnly={props.action === 'view'}
                      helperText={!!item.formState.errors.detail?.wheel_position ? t('Wheel position is required') : ''}
                      options={getOptions('wheel_positions')}
                      fullWidth
                      required
                      onChange={(e) => item.field.onChange(e.target.value)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Controller
                  name="detail.color_finish"
                  control={control}
                  render={(item) => (
                    <TextField
                      id={item.field.name}
                      name="name"
                      label={t('Color/Finish')}
                      type="string"
                      variant="standard"
                      value={item.field.value || ''}
                      onChange={(e) => item.field.onChange(e.target.value)}
                      readOnly={props.action === 'view'}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Controller
                  name="detail.is_x_one"
                  defaultValue={'false'}
                  control={control}
                  render={({ field: { onChange, value, name } }) => (
                    <Switch
                      label={t('X One')}
                      name="is_x_one"
                      checked={value === 'true' ? true : false}
                      id={name}
                      readOnly={props.action === 'view'}
                      onChange={(e: any) => {
                        onChange(e.target.checked === true ? 'true' : 'false');
                        setValue('detail.wheel_offset', '0');
                        trigger('detail.is_x_one');
                      }}
                      color="primary"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ paddingBottom: '12px' }}>
              <Grid item xs={12} lg={6}>
                <Grid container>
                  {!((permissions?.location?.customer_type || '').toLowerCase() === 'st') && (
                    <Grid item xs={12} lg={12}>
                      <Grid container alignContent="center" alignItems="center">
                        <Grid item xs={8} lg={8}>
                          <Controller
                            name="detail.assign_to_all_locations"
                            control={control}
                            rules={{
                              required: false,
                              validate: {
                                value: (value) =>
                                  (getValues().detail?.assigned_locations?.length > 0 && value === false) ||
                                  value === true ||
                                  t('You must assign to All or selected ship to accounts').toString(),
                              },
                            }}
                            render={(item) => (
                              <>
                                <Typography
                                  variant="body2"
                                  color={item.formState.errors.detail?.assign_to_all_locations ? 'error' : 'inherit'}
                                >
                                  {t('Choose locations to assign this profile to')}
                                </Typography>
                                <Switch
                                  id={item.field.name}
                                  checked={item.field.value}
                                  name="assign_to_all_locations"
                                  onChange={(e: any) => {
                                    item.field.onChange(e.target.checked);
                                    trigger('detail.assign_to_all_locations');
                                  }}
                                  readOnly={
                                    props.action === 'view' ||
                                    (permissions?.location?.customer_type || '').toLowerCase() === 'st' ||
                                    getValues().owner?.customer_type?.toLowerCase() === 'st'
                                  }
                                  label={t('Assign to all locations')}
                                />
                              </>
                            )}
                          />
                        </Grid>
                        <Grid item xs={4} lg={4} style={{ paddingTop: 20 }}>
                          <Controller
                            name="detail.assigned_locations"
                            control={control}
                            render={(item) => (
                              <NewLocationAssignments
                                owner={
                                  props.action === 'create'
                                    ? permissions.location?.customer_number.toString()
                                    : getValues().owner?.customer_number
                                }
                                mode={props.action === 'view' ? 'view' : 'edit'}
                                all={getValues().detail?.assign_to_all_locations || false}
                                locations={
                                  item.field.value.map((rel: ILocationAssigned) => ({ hash_key: rel.hash_key })) || []
                                }
                                onUpdate={(newCurrentAssignments) => {
                                  const auxAssignments: ILocationAssigned[] = [];
                                  newCurrentAssignments.forEach((customer) => {
                                    auxAssignments.push({
                                      hash_key: customer.hash_key,
                                      relationship: customer.relationship || '',
                                    });
                                  });
                                  setValue('detail.assigned_locations', auxAssignments, {
                                    shouldDirty: true,
                                  });
                                  trigger('detail.assign_to_all_locations');
                                }}
                                disabled={false}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12} lg={12}>
                    <Controller
                      name="detail.operating_company"
                      control={control}
                      render={(item) => (
                        <TextField
                          style={{ marginTop: '8px' }}
                          id={item.field.name}
                          name="operation_company"
                          label={t('Operating Company')}
                          type="string"
                          variant="standard"
                          value={item.field.value || ''}
                          onChange={(e) => item.field.onChange(e.target.value)}
                          readOnly={props.action === 'view'}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Controller
                      name="detail.comments"
                      control={control}
                      render={(item) => (
                        <TextField
                          style={{ marginTop: '8px' }}
                          id={item.field.name}
                          name="comments"
                          label={t('Comments')}
                          type="string"
                          variant="standard"
                          value={item.field.value || ''}
                          onChange={(e) => item.field.onChange(e.target.value)}
                          readOnly={props.action === 'view'}
                          multiline
                          rows={(permissions?.location?.customer_type || '').toLowerCase() === 'st' ? '8' : '5'}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Controller
                  name="detail.brands"
                  control={control}
                  render={(item) => (
                    <BrandPreferencesComponent
                      title={t('Wheel Brands')}
                      isEditing={props.action === 'view' ? false : true}
                      setDataHandler={(value: WheelDetailsBrand[]) => {
                        const wheelBrands: WheelDetailsBrand[] = value.map((obj: WheelDetailsBrand) => ({
                          priority: obj.priority,
                          brand: obj.brand,
                          id: obj.id,
                        }));
                        setValue('detail.brands', wheelBrands, {
                          shouldDirty: true,
                        });
                      }}
                      dataSource={getValues().detail.brands || []}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Panel>
        </div>
      )}
      <PreventTransitionPrompt when={isDirty && !saving && props.action !== 'view'} />
    </>
  );
};
