import { MaestroAPI, maestroApiCall } from '@michelin/central-provider';
import { MethodType } from '@michelin/central-provider/dist/helpers/createAPICall';
import GLOBAL_CONFIG from '../global-variables';

const baseURL = GLOBAL_CONFIG.externalAPIUrl;

export async function deleteContacts({ fleetId, ids }: { fleetId: string; ids: string[] }) {
  const apiType: MaestroAPI = { method: MethodType.DELETE, endpoint: `cp-be/contacts/${fleetId}`, baseURL };

  const response = await maestroApiCall(apiType, { contact_ids: ids.join(',') }).then((res) => res.data);
  return response;
}
