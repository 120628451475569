import React, { Dispatch, SetStateAction } from 'react';
import { Checkbox } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { TreeItem, TreeView } from '@material-ui/lab';
import { ContactListingOVM } from '@michelin/fcp-view-models';
import { getOptionLabel } from '@michelin/select-options-provider';
import { NewLocationAssignments } from 'components/NewLocationAssignments';
import { Column } from 'devextreme-react/data-grid';
import { TFunction } from 'i18next';
import { TIRE_SERVICE_VALUE, canUserEditByHierarchy, getContactTypesPerLocation } from '../utils';
import { contactTypesRenderForTreeList } from './Components';

export function RenderContactTypes(row: ContactListingOVM, t: TFunction): JSX.Element {
  const { contact_types } = row;
  const mechanicalContactTypes = new Array<string>();
  const tireContactTypes = new Array<string>();

  if (contact_types) {
    contact_types.forEach((contact_type) => {
      const { role_areas, service } = contact_type;
      if (role_areas) {
        role_areas.forEach((contactTypeRoleArea) => {
          const { role_area } = contactTypeRoleArea;
          if (role_area) {
            if (service === TIRE_SERVICE_VALUE) {
              tireContactTypes.push(role_area);
            } else {
              // mechanicalContactTypes.push(role_area);
            }
          }
        });
      }
    });
  }

  return (
    <TreeView defaultCollapseIcon={<Icons.ExpandMore />} defaultExpandIcon={<Icons.ChevronRight />}>
      {tireContactTypes.length > 0 ? (
        <TreeItem nodeId={`tireContactTypes${row.id}`} label={t('Tire')} id="tireTreeItem">
          {tireContactTypes.map((x, index) => (
            <TreeItem key={x + row.id} nodeId={x + index + row.id} label={getOptionLabel('contact_types', x)} />
          ))}
        </TreeItem>
      ) : undefined}
      {mechanicalContactTypes.length > 0 ? (
        <TreeItem nodeId={`mechanicalContactTypes${row.id}`} label={t('Mechanical')} id="mechanicalTreeItem">
          {mechanicalContactTypes.map((x, index) => (
            <TreeItem key={x + row.id} nodeId={x + index + row.id} label={getOptionLabel('contact_types', x)} />
          ))}
        </TreeItem>
      ) : undefined}
    </TreeView>
  );
}

export function RenderActionCheckbox(
  row: ContactListingOVM,
  deletePermission: boolean,
  updatePermission: boolean,
  selectedRows: ContactListingOVM[],
  massiveDelete: boolean,
  setSelectedRows: Dispatch<SetStateAction<ContactListingOVM[]>>,
): JSX.Element {
  const canUserEdit = canUserEditByHierarchy(row, updatePermission);
  const cloneSelectedRows = [...selectedRows];
  return (
    <div>
      {deletePermission && canUserEdit && massiveDelete ? (
        <Checkbox
          onChange={(e, checked) => {
            if (checked) {
              cloneSelectedRows.push(row);
              setSelectedRows(cloneSelectedRows);
            } else {
              cloneSelectedRows.splice(cloneSelectedRows.indexOf(row), 1);
              setSelectedRows(cloneSelectedRows);
            }
          }}
          checked={selectedRows.includes(row)}
          style={{ padding: 0 }}
          color="primary"
        />
      ) : undefined}
    </div>
  );
}

export function RenderPriorityLevelAndLocations(contact: ContactListingOVM, t: TFunction): JSX.Element {
  let locations: any = [];
  if (contact.locations && contact.locations.length) {
    locations = contact.locations.filter((x) => !x.is_deleted);
    locations = locations.map((rel: any) => ({ hash_key: rel.hash_key }));
  }
  const contactTypePerLocation = getContactTypesPerLocation(contact);

  return (
    <NewLocationAssignments
      mode="view"
      all={false}
      locations={locations}
      disabled={false}
      owner=""
      filterByCustomerType={contact.contact_level}
      subTitle={`${contact.first_name} ${contact.last_name}`}
      additionalColumns={
        <Column
          allowSorting={false}
          caption={t("Contact's Priority Levels")}
          dataField="contact_types"
          cellRender={(row: any) => contactTypesRenderForTreeList(row.data, contactTypePerLocation, t)}
        />
      }
    />
  );
}
