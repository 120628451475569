import React from 'react';
import { useTranslation } from '@michelin/central-provider';
import ContactList from 'components/Contact/ContactList';
import { Contact } from 'components/Contact/utils';
import { CheckBox } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';

type Row = Contact;

interface Props {
  editFlag: boolean;
  contacts: Array<Contact>;
  contactAssigned: string | undefined;
  setContact: (contact: Contact | undefined) => void;
}

function assignRender(
  row: Row,
  setContact: (contact: Contact | undefined) => void,
  contactAssigned: string | undefined,
  editFlag: boolean,
) {
  const { hash_key } = row;

  const onCheckBoxValueChange = (e: any) => {
    const { value } = e;

    if (value === true) {
      setContact(row);
    } else {
      setContact(undefined);
    }
  };

  return (
    <CheckBox
      disabled={!editFlag}
      value={typeof contactAssigned !== 'undefined' && contactAssigned === hash_key}
      onValueChanged={onCheckBoxValueChange}
    />
  );
}

export function PORequiredContactTable(props: Props): JSX.Element {
  const { contacts: rows, setContact, contactAssigned, editFlag } = props;

  const { t } = useTranslation();

  const additionalAssignColumn = [
    <Column
      allowSorting
      key="assign_column"
      allowFiltering={false}
      defaultSortOrder="desc"
      calculateSortValue={(row: any) => row.hash_key === contactAssigned}
      caption={t('Assign')}
      alignment="center"
      dataField="assign"
      cellRender={(row: any) => assignRender(row.data, setContact, contactAssigned, editFlag)}
    />,
  ];

  return (
    <ContactList
      popupMode
      additionalColumns={additionalAssignColumn}
      preLoadedContacts={rows as any}
    />
  );
}
