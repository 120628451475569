import React from 'react';
import { Grid } from '@material-ui/core';
import { LoadingBounce, Panel, Select } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { getOptions } from '@michelin/select-options-provider';
import { Contact } from 'components/Contact/utils';
import {
  AuthorizationService,
  BillingPreferences,
  BillingProfileLocation,
  BillingPurchasingProcedures,
} from '../query';
import { PurchasingProcValidations } from '../utils';
import ContactToRequireAuthorization from './contactToRequireAuthorization';
import DrWorkOrder from './drWorkOrder';
import ErsShipToSelection from './ersShipToSelection';
import { initPurchasingProcedure } from './utils';

export interface PurchasingProceduresProps {
  profile: BillingPreferences;
  ers: boolean;
  editFlag: boolean;
  loading: boolean;
  editProfile: (profile: BillingPreferences) => void;
  setModifiedFlag: (modifiedFlag: boolean) => void;
  profileType: string;
  locations: BillingProfileLocation[];
  setLocations: (locations: BillingProfileLocation[]) => void;
  contacts: Array<Contact>;
  handleContactLevelChange: (
    contactLevel: string,
    contactType: string[],
    primary: boolean,
    manualClick?: boolean,
  ) => void;
  validationErrors: PurchasingProcValidations;
}

export interface DrWorkOrderRequiredInfo {
  ersPrimaryLevelsPerLocationHashKey: Map<string, Contact>;
  poPrimaryLevelsPerLocationHashKey: Map<string, Contact>;
  contactsPerLocationHashKey: Map<string, Array<Contact>>;
  poAssigned: number;
  ersAssigned: number;
  contactsMap: Map<string, Contact>;
  loading: boolean;
}

export default function PurchasingProcedures(props: PurchasingProceduresProps) {
  const { t } = useTranslation();
  const options_required_authorization_to_begin_service = props.ers
    ? getOptions('required_authorization_to_begin_service_ers', 'Select', t('Select'))
    : getOptions('required_authorization_to_begin_service_onsite', 'Select', t('Select'));
  const authorized_tire_po_issuer = { label: 'Tire PO Issuer', value: 'po_issuer' };

  const {
    ers,
    profile,
    editFlag,
    editProfile,
    setModifiedFlag,
    validationErrors,
    handleContactLevelChange,
    contacts,
    setLocations,
    locations,
    loading,
  } = props;

  const title = props.ers ? t('ERS Purchasing Procedures') : t('On-Site Purchasing Procedures');
  const poRequired =
    profile && profile.general_information && profile.general_information.po_required_for_service
      ? profile.general_information.po_required_for_service
      : false;
  let purchasing_procedure: BillingPurchasingProcedures = initPurchasingProcedure(props.ers, poRequired);

  // If the profile is not create because it depends on the profile type then is created.
  if (profile) {
    if (props.ers) {
      if (profile.ers_purchasing_procedures) {
        purchasing_procedure = profile.ers_purchasing_procedures;
      } else {
        profile.ers_purchasing_procedures = purchasing_procedure;
        editProfile({ ...profile });
        setModifiedFlag(true);
      }
    }
    if (!props.ers) {
      if (profile.onsite_purchasing_procedures) {
        purchasing_procedure = profile.onsite_purchasing_procedures;
      } else {
        profile.onsite_purchasing_procedures = purchasing_procedure;
        editProfile({ ...profile });
        setModifiedFlag(true);
      }
    }
  }

  const updateReqAuth = (newValue?: any) => {
    const newProfile = { ...profile };
    purchasing_procedure.required_authorization_to_begin_service = newValue;
    if (newValue === 'po') {
      purchasing_procedure.primary_method_to_request_service = AuthorizationService.fleet_contact;
      if (purchasing_procedure.pri_ers_authorized_contact_type) {
        if (!purchasing_procedure.pri_ers_authorized_contact_type.includes(authorized_tire_po_issuer.value)) {
          purchasing_procedure.pri_ers_authorized_contact_type.push(authorized_tire_po_issuer.value);
        }
      } else {
        purchasing_procedure.pri_ers_authorized_contact_type = [authorized_tire_po_issuer.value];
      }
    }

    if (props.ers) {
      newProfile.ers_purchasing_procedures = purchasing_procedure;
    } else {
      newProfile.onsite_purchasing_procedures = purchasing_procedure;
    }
    validationErrors.required_authorization = false;
    setModifiedFlag(true);
    editProfile(newProfile);
  };

  return (
    <Panel title={title} style={{ marginBottom: 15 }}>
      {loading ? (
        <LoadingBounce style={{ height: 300 }} />
      ) : (
        <Grid container spacing={2} style={{ paddingTop: 5 }} id="poProc">
          <Grid item sm={12} md={12}>
            <Grid container spacing={2} style={{ paddingBottom: 20 }}>
              {ers && (
                <ErsShipToSelection
                  editFlag={editFlag}
                  profile={profile}
                  ers={ers}
                  purchasing_procedure={purchasing_procedure}
                  validationErrors={validationErrors}
                  editProfile={editProfile}
                  setModifiedFlag={setModifiedFlag}
                />
              )}
              <Grid item sm={12} lg={4} style={{ marginTop: '1.25rem' }}>
                <Select
                  required
                  label={t('Required Authorization for Billing Purposes')}
                  value={purchasing_procedure.required_authorization_to_begin_service}
                  onChange={(event) => updateReqAuth(event.target.value)}
                  style={{ width: '100%' }}
                  error={validationErrors.required_authorization}
                  options={options_required_authorization_to_begin_service}
                  readOnly={!editFlag || poRequired}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item sm={12} md={12}>
            <Grid container spacing={2} style={{ paddingBottom: 20 }}>
              <ContactToRequireAuthorization
                editFlag={editFlag}
                profile={profile}
                ers={ers}
                purchasing_procedure={purchasing_procedure}
                validationErrors={validationErrors}
                editProfile={editProfile}
                setModifiedFlag={setModifiedFlag}
                primaryContactRequired
                handleContactLevelChange={handleContactLevelChange}
                poRequired={poRequired}
              />
            </Grid>
          </Grid>
          <Grid item sm={12} md={12}>
            <Grid container spacing={2} style={{ paddingBottom: 20 }}>
              <ContactToRequireAuthorization
                editFlag={editFlag}
                profile={profile}
                ers={ers}
                purchasing_procedure={purchasing_procedure}
                validationErrors={validationErrors}
                editProfile={editProfile}
                setModifiedFlag={setModifiedFlag}
                primaryContactRequired={false}
                handleContactLevelChange={handleContactLevelChange}
                poRequired={poRequired}
              />
            </Grid>
          </Grid>
          <Grid item sm={12} md={12}>
            <DrWorkOrder
              editFlag={editFlag}
              profile={profile}
              ers={ers}
              purchasing_procedure={purchasing_procedure}
              validationErrors={validationErrors}
              editProfile={editProfile}
              setModifiedFlag={setModifiedFlag}
              locations={locations}
              setLocations={setLocations}
              contacts={contacts}
            />
          </Grid>
        </Grid>
      )}
    </Panel>
  );
}
