import React from 'react';
import { Grid } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import { useTranslation } from '@michelin/central-provider';
import { SelectOption, getOptions } from '@michelin/select-options-provider';
import Autocomplete from 'components/Autocomplete';
import { ContactType } from 'components/Contact/utils';

export interface ChangeEvent {
  selectName: string;
  id?: string;
  service?: string;
  newValue?: Array<string>;
}

interface Props {
  contact_type: ContactType;
  onChange: (event: ChangeEvent) => void;
  viewMode: boolean;
  style?: CSSProperties;
  id: string;
}

export default function Selector(props: Props) {
  const { contact_type, onChange, viewMode, id: componentId } = props;
  const { service, id, role_areas } = contact_type;
  const { t } = useTranslation();

  const handleChange = (selectName: string, value: Array<string> | string | unknown | undefined) => {
    if (selectName === 'roleAreas') {
      const newValue = value as Array<string>;
      onChange({
        selectName,
        id,
        service,
        newValue,
      });
    }
  };

  const possibleRoles = getOptions('contact_types');

  const mappedPosibbleRoles = new Map<string, SelectOption>();
  possibleRoles.forEach((x) => {
    mappedPosibbleRoles.set(x.value, x);
  });

  const selectedRoleAreasOptions: SelectOption[] = [];
  if (role_areas) {
    role_areas
      .map((x) => mappedPosibbleRoles.get(x.role_area))
      .forEach((x) => {
        if (x !== undefined) {
          selectedRoleAreasOptions.push(x);
        }
      });
  }

  return (
    <Grid container spacing={2} style={props.style}>
      <Autocomplete
        id={componentId}
        multiple
        disableCloseOnSelect
        values={selectedRoleAreasOptions.map((x) => x.value)}
        suggestions={possibleRoles}
        placeholder={t('Select Areas of Responsibility')}
        label={t('Role / Areas')}
        readOnly={viewMode || !(service && typeof service === 'string')}
        onChange={(value) => handleChange('roleAreas', value)}
      />
    </Grid>
  );
}
