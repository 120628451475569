import React, { useEffect, useState } from 'react';
import { FormControlLabel, Grid } from '@material-ui/core';
import { LocationAvatar, Select, TextField } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { getOptions } from '@michelin/select-options-provider';
import { RenderNameAndAccountInfo } from 'components/Contact/ContactList';
import { Contact } from 'components/Contact/utils';
import { getCustomers } from 'components/LocationAssignments/queries';
import { BillingPreferences, BillingProfileLocation, BillingPurchasingProcedures, MethodOfSignedOrder } from '../query';
import { PurchasingProcValidations } from '../utils';
import PORequiredDRLogic from './poRequiredDRLogic';

interface Props {
  profile: BillingPreferences;
  purchasing_procedure: BillingPurchasingProcedures;
  validationErrors: PurchasingProcValidations;
  contacts: Array<Contact>;
  locations: BillingProfileLocation[];
  editFlag: boolean;
  ers: boolean;
  editProfile: (profile: BillingPreferences) => void;
  setModifiedFlag: (modified: boolean) => void;
  setLocations: (locations: BillingProfileLocation[]) => void;
}

export default function DrWorkOrder(props: Props): JSX.Element {
  const {
    profile,
    purchasing_procedure,
    validationErrors,
    editFlag,
    ers,
    editProfile,
    setModifiedFlag,
    contacts,
    setLocations,
    locations,
  } = props;

  const { t } = useTranslation();
  const options_method_to_receive_signed_order = getOptions('method_to_receive_signed_order', 'Select', t('Select'));

  let emailFaxLocation = '';
  if (
    purchasing_procedure.method_to_receive_signed_order === MethodOfSignedOrder.mail &&
    purchasing_procedure.receiver_location
  ) {
    emailFaxLocation = `${purchasing_procedure.receiver_location.customer_name}, ${purchasing_procedure.receiver_location.customer_city}`;
  }
  if (
    purchasing_procedure.method_to_receive_signed_order === MethodOfSignedOrder.email &&
    purchasing_procedure.receiver_contact
  ) {
    emailFaxLocation = purchasing_procedure.receiver_contact.email_address;
  } else if (
    purchasing_procedure.method_to_receive_signed_order === MethodOfSignedOrder.fax &&
    purchasing_procedure.receiver_contact
  ) {
    emailFaxLocation = purchasing_procedure.receiver_contact.fax;
  }

  const updateSignedOrder = (newValue?: any) => {
    const newProfile = { ...profile };
    purchasing_procedure.method_to_receive_signed_order = newValue;
    if (ers) {
      newProfile.ers_purchasing_procedures = purchasing_procedure;
    } else {
      newProfile.onsite_purchasing_procedures = purchasing_procedure;
    }
    validationErrors.method_to_receive_signed_order = false;
    setModifiedFlag(true);
    editProfile(newProfile);
  };

  const updateSignedOrderOther = (newValue?: any) => {
    const prof = profile;
    purchasing_procedure.signed_order_receiver_other = newValue;
    if (ers) {
      prof.ers_purchasing_procedures = purchasing_procedure;
    } else {
      prof.onsite_purchasing_procedures = purchasing_procedure;
    }
    validationErrors.method_to_receive_signed_order = false;
    setModifiedFlag(true);
    editProfile({ ...prof });
  };

  const isOtherOption = () => purchasing_procedure.method_to_receive_signed_order === MethodOfSignedOrder.other;
  const isNot_Select_Other_Driver = () =>
    purchasing_procedure &&
    purchasing_procedure.method_to_receive_signed_order !== MethodOfSignedOrder.other &&
    purchasing_procedure.method_to_receive_signed_order !== MethodOfSignedOrder.Select &&
    purchasing_procedure.method_to_receive_signed_order !== MethodOfSignedOrder.provide_to_driver;

  const methodToReceive = purchasing_procedure.method_to_receive_signed_order;
  const [receiverContact, setReceiverContact] = useState<Contact | undefined>(undefined);
  const [receiverLocation, setReceiverLocation] = useState<BillingProfileLocation | undefined>(undefined);
  useEffect(() => {
    async function loadLocation() {
      if (purchasing_procedure.signed_order_receiver_location) {
        const l = await getCustomers([purchasing_procedure.signed_order_receiver_location]);
        setReceiverLocation(l.pop());
      }
    }
    setReceiverContact(contacts.find((e) => e && e.hash_key === purchasing_procedure.signed_order_receiver_contact));
    loadLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchasing_procedure.signed_order_receiver_contact, purchasing_procedure.signed_order_receiver_location]);

  return (
    <>
      <Grid container spacing={2} style={{ paddingBottom: 20 }}>
        <Grid item sm={12} md={4}>
          <Select
            id="method_to_receive_signed_order"
            label={t('Method to Receive Signed DR/Work Order')}
            value={purchasing_procedure.method_to_receive_signed_order || ''}
            readOnly={!editFlag}
            onChange={(event) => updateSignedOrder(event.target.value)}
            options={options_method_to_receive_signed_order}
            style={{ width: '100%' }}
            error={validationErrors.method_to_receive_signed_order}
          />
        </Grid>
        {isOtherOption() === false &&
          purchasing_procedure.method_to_receive_signed_order !== MethodOfSignedOrder.Select &&
          purchasing_procedure.method_to_receive_signed_order !== MethodOfSignedOrder.provide_to_driver && (
            <>
              <Grid item sm={12} md={4} style={{ paddingTop: 24 }}>
                <FormControlLabel
                  value=""
                  control={
                    <PORequiredDRLogic
                      editProfile={editProfile}
                      setModifiedFlag={setModifiedFlag}
                      preferences={profile}
                      contacts={contacts}
                      locations={locations}
                      ers={ers}
                      setLocations={setLocations}
                      editFlag={editFlag}
                    />
                  }
                  label={t('Receiver of Signed DR/Work Order*')}
                  labelPlacement="start"
                  style={{ margin: 0 }}
                />
              </Grid>
              <Grid item sm={12} md={4} style={{ paddingTop: 16 }}>
                {methodToReceive === MethodOfSignedOrder.fax || methodToReceive === MethodOfSignedOrder.email ? (
                  <RenderNameAndAccountInfo row={receiverContact as any} contactMethod={methodToReceive} />
                ) : (
                  receiverLocation && (
                    <LocationAvatar
                      accountType={receiverLocation.customer_type as any}
                      name={receiverLocation.customer_name}
                      accountNumbers={{
                        parentCompany: receiverLocation.parent_company_number,
                        homeOffice: receiverLocation.home_office_number,
                        billTo: receiverLocation.bill_to_customer,
                        shipTo: receiverLocation.ship_to_customer,
                      }}
                      address={{
                        city: receiverLocation.customer_city,
                        state: receiverLocation.customer_state,
                        address1: receiverLocation.customer_addr1,
                        address2: receiverLocation.customer_addr2,
                        zip: receiverLocation.customer_zip,
                      }}
                    />
                  )
                )}
              </Grid>
            </>
          )}
        {isOtherOption() === true && (
          <Grid item sm={12} md={4}>
            {purchasing_procedure && purchasing_procedure.method_to_receive_signed_order === MethodOfSignedOrder.other && (
              <TextField
                onChange={(event) => {
                  updateSignedOrderOther(event.target.value);
                }}
                label={t('Other')}
                value={purchasing_procedure.signed_order_receiver_other || ''}
                readOnly={!editFlag}
                error={validationErrors.method_to_receive_signed_order}
              />
            )}
            {isNot_Select_Other_Driver() === true && (
              <TextField value={emailFaxLocation.length > 0 ? emailFaxLocation : ''} readOnly />
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
}
