import { SelectOption } from '@michelin/acid-components';
import {
  ResponseOVM,
  TireProfileAxle,
  TireProfileAxles,
  TireProfileOVM,
  TireProfilePriorities,
} from '@michelin/fcp-view-models';
import { AXLES, AxleName } from '../types';

export const getAxle = (axleName: AxleName, tireProfile: TireProfileOVM): TireProfileAxle | null => {
  switch (axleName) {
    case 'all_axles':
      return tireProfile.axles?.all_axles || null;
    case 'carry':
      return tireProfile.axles?.carry || null;
    case 'tag':
      return tireProfile.axles?.tag || null;
    case 'trailer':
      return tireProfile.axles?.trailer || null;
    case 'steer':
      return tireProfile.axles?.steer || null;
    case 'pusher':
      return tireProfile.axles?.pusher || null;
    case 'lift':
      return tireProfile.axles?.lift || null;
    case 'drive':
      return tireProfile.axles?.drive || null;
  }
};

export const filterTheBrand = (brands: SelectOption[], isOnsite: boolean, priority: any): SelectOption[] => {
  if (priority === 1) {
    if (isOnsite) {
      return brands.filter((x) => /oliver|uniroyal|mrt|michelin|bfgoodrich/gi.test(x.value));
    } else {
      return brands.filter((x) => /mrt|michelin|bfgoodrich/gi.test(x.value));
    }
  }
  return brands;
};

interface EqualProfile {
  tireSize: string;
  axle: string;
}

export interface UniqueErrorData {
  [profileName: string]: EqualProfile[];
}
interface Match {
  profileName: string;
  tireSize: string;
  axle: string;
}

interface ErrorMetadata {
  isUnique: boolean;
  matches: Match[];
}
export const ErrorHandler = (
  res: ResponseOVM<TireProfileOVM>,
): { isError: boolean; data?: TireProfileOVM | undefined; errors?: string[] } => {
  if (res.error && res.error === 'Duplicated profile') {
    if (res.errors && res.errors.length > 0) {
      const metadata = res.errors[0].metadata as ErrorMetadata;
      const result: UniqueErrorData = metadata.matches.reduce((acc: UniqueErrorData, item) => {
        if (!acc[item.profileName]) {
          acc[item.profileName] = [];
        }
        acc[item.profileName].push({ axle: item.axle, tireSize: item.tireSize });
        return acc;
      }, {});
      throw new Error(JSON.stringify({ isUniqueError: true, uniqueErrorData: result }));
    }
    throw new Error(JSON.stringify({ isUniqueError: true, uniqueErrorData: [] }));
  } else {
    if (res.errors && res.errors?.length > 0) {
      const errorMessages: string[] = [];

      res.errors.forEach((error) => {
        const field = error.field?.split('.');
        let fieldMessage = '{{MAIN}}: {{AXLE}} - Field: {{FIELD}}';

        if (!field && error.message) {
          errorMessages.push(error.message);
        }

        if (field && field[0] === 'axles') {
          fieldMessage = fieldMessage.replace('{{MAIN}}', 'Axle');
          fieldMessage = fieldMessage.replace('{{AXLE}}', AXLES.find((axle) => axle.name === field[1])?.label || '');

          for (let i = 2; i <= field.length - 1; i++) {
            fieldMessage = fieldMessage.replace('{{FIELD}}', `${field[i]} {{FIELD}}`);
          }

          fieldMessage = fieldMessage.replace(' {{FIELD}}', ':');

          errorMessages.push(error.message?.replace(`"${error.field}"`, fieldMessage) || '');
        }
      });
      throw new Error(JSON.stringify({ isUniqueError: false, errorList: errorMessages }));
    }
  }
  return { isError: false, data: res.data };
};

export const getAutocompleteValues = (values: string[] | undefined, options: SelectOption[]): SelectOption[] => {
  if (!values) return [];
  return options.filter((opt) => values.includes(opt.value));
};

export const getAxleFromAxles = (axleName: AxleName, axles: TireProfileAxles): TireProfileAxle | null => {
  switch (axleName) {
    case 'all_axles':
      return axles?.all_axles || null;
    case 'carry':
      return axles?.carry || null;
    case 'tag':
      return axles?.tag || null;
    case 'trailer':
      return axles?.trailer || null;
    case 'steer':
      return axles?.steer || null;
    case 'pusher':
      return axles?.pusher || null;
    case 'lift':
      return axles?.lift || null;
    case 'drive':
      return axles?.drive || null;
  }
};
export const sortedAxlesBySizeAndPriority = (axles: TireProfileAxles | undefined) => {
  if (!axles || Object.keys(axles).length === 0) return axles;
  Object.keys(axles).forEach((key) => {
    const axle = getAxleFromAxles(key as AxleName, axles);
    if (axle && axle.tire_replacement_priority) {
      axle.tire_replacement_priority.sort((a, b) => {
        // First sorted by tire_size
        if (a.tire_size && b.tire_size) {
          if (a.tire_size < b.tire_size) return -1;
          if (a.tire_size > b.tire_size) return 1;
        }
        // if same tire_size, sorted by priority
        if (a.priority && b.priority) {
          if (a.priority < b.priority) return -1;
          if (a.priority > b.priority) return 1;
        }
        return 0; // if same tire_size and priority
      });
    }
  });
  return axles;
};

export const doWeEqual = (a: string, b: string, equalSizes: string[]) => {
  let ret = false;

  if (a.toUpperCase() === b.toUpperCase()) {
    return true;
  }
  equalSizes.forEach((e) => {
    const testString = e.split('~');
    if (
      (testString[0].toUpperCase() === a.toUpperCase() || testString[1].toUpperCase() === a.toUpperCase()) &&
      (testString[0].toUpperCase() === b.toUpperCase() || testString[1].toUpperCase() === b.toUpperCase())
    ) {
      ret = true;
    }
  });
  return ret;
};

export const getTheOtherEqualSize = (size: string, equalSizes: string[]) => {
  let ret: string | undefined = undefined;
  equalSizes.forEach((e) => {
    const testString = e.split('~');
    if (testString[0].toUpperCase() === size.toUpperCase()) {
      ret = testString[1];
    } else if (testString[1].toUpperCase() === size.toUpperCase()) {
      ret = testString[0];
    }
  });
  return ret;
};

export const updatePriorities = (
  replacementList: TireProfilePriorities[],
  tireSize: string,
  otherEqualSize: string | undefined,
): TireProfilePriorities[] => {
  let auxPriority = 0;
  const newReplacementPriority = replacementList.map((item) => {
    if (item.tire_size === tireSize || otherEqualSize === item.tire_size) {
      auxPriority += 1;
      return { ...item, priority: auxPriority };
    } else {
      return item;
    }
  });
  return newReplacementPriority;
};

export const doSizesEqual = (a: string, b: string, equalSizes: string[]) => {
  if (doWeEqual(a, b, equalSizes)) {
    return true;
  }
  return a.toUpperCase() === b.toUpperCase();
};

export const getOtd32 = (value: string): string => {
  const otd32 = /\/32/g.test(value || '') ? value : (value && /^\s$/.test(value)) || !value ? '' : `${value}/32`;
  return otd32;
};
