import { useCallback } from 'react';
import { useAPICallAsync } from '@michelin/central-provider';
import {
  FetchCustomTireSizeAspectRatiosFn,
  FetchCustomTireSizeAspectRatiosParams,
  FetchCustomTireSizeDiametersFn,
  FetchCustomTireSizeDiametersParams,
  FetchCustomTireSizeLoadsFn,
  FetchCustomTireSizeLoadsParams,
  FetchCustomTireSizeSizesFn,
  FetchCustomTireSizeSizesParams,
  FetchCustomTireSizeSpeedRatingsFn,
  FetchCustomTireSizeSpeedRatingsParams,
} from '@michelin/core-profile-ui-tools/build/components/UFOVehicleDetailsForm/types';
import { SelectOption } from '@michelin/select-options-provider';
import defaultConfig from '../../../global-variables';
import { customTireSizeAPIType } from '../../Types/APITypes';
import { buildOptionsAndValuesFromArray, buildOptionsAndValuesFromSpeedRatings } from '../Utils';

export const useGetCustomSizes = () => {
  const { call } = useAPICallAsync<SelectOption[], FetchCustomTireSizeSizesParams>({
    api: customTireSizeAPIType(),
    baseRoute: defaultConfig.externalAPIUrl,
    autoAbortKey: 'use::get::customTireSize',
  });

  const fn: FetchCustomTireSizeSizesFn = useCallback(
    async (params: FetchCustomTireSizeSizesParams) => {
      try {
        const response = await call({ ...params }, undefined, false);
        return buildOptionsAndValuesFromArray(response.data);
      } catch (e) {
        return [];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [call],
  );

  return fn;
};

export const useGetCustomAspectRatios = () => {
  const { call } = useAPICallAsync<SelectOption[], FetchCustomTireSizeAspectRatiosParams>({
    api: customTireSizeAPIType(),
    baseRoute: defaultConfig.externalAPIUrl,
    autoAbortKey: 'use::get::customAspectRatio',
  });

  const fn: FetchCustomTireSizeAspectRatiosFn = useCallback(
    async (params: FetchCustomTireSizeAspectRatiosParams) => {
      try {
        const response = await call({ ...params }, undefined, false);
        return buildOptionsAndValuesFromArray(response.data);
      } catch (e) {
        return [];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [call],
  );

  return fn;
};

export const useGetCustomDiameters = () => {
  const { call } = useAPICallAsync<SelectOption[], FetchCustomTireSizeDiametersParams>({
    api: customTireSizeAPIType(),
    baseRoute: defaultConfig.externalAPIUrl,
    autoAbortKey: 'use::get::customDiameter',
  });

  const fn: FetchCustomTireSizeDiametersFn = useCallback(
    async (params: FetchCustomTireSizeDiametersParams) => {
      try {
        const response = await call({ ...params }, undefined, false);
        return buildOptionsAndValuesFromArray(response.data);
      } catch (e) {
        return [];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [call],
  );

  return fn;
};

export const useGetCustomLoads = () => {
  const { call } = useAPICallAsync<SelectOption[], FetchCustomTireSizeLoadsParams>({
    api: customTireSizeAPIType(),
    baseRoute: defaultConfig.externalAPIUrl,
    autoAbortKey: 'use::get::customLoad',
  });

  const fn: FetchCustomTireSizeLoadsFn = useCallback(
    async (params: FetchCustomTireSizeLoadsParams) => {
      try {
        const response = await call({ ...params }, undefined, false);
        return buildOptionsAndValuesFromArray(response.data);
      } catch (e) {
        return [];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [call],
  );

  return fn;
};

export const useGetCustomSpeedRatings = () => {
  const { call } = useAPICallAsync<SelectOption[], FetchCustomTireSizeSpeedRatingsParams>({
    api: customTireSizeAPIType(),
    baseRoute: defaultConfig.externalAPIUrl,
    autoAbortKey: 'use::get::customSpeedRating',
  });

  const fn: FetchCustomTireSizeSpeedRatingsFn = useCallback(
    async (params: FetchCustomTireSizeSpeedRatingsParams) => {
      try {
        const response = await call({ ...params }, undefined, false);
        return buildOptionsAndValuesFromSpeedRatings(response.data);
      } catch (e) {
        return [];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [call],
  );

  return fn;
};
