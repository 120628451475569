/* eslint-disable no-restricted-syntax */
import React, { useState } from 'react';
import { DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { Button, Select } from '@michelin/acid-components';
import { usePermissions, useTranslation } from '@michelin/central-provider';
import { ProductOVM, TireProfileOVM } from '@michelin/fcp-view-models';
import { SelectOption, getOptions } from '@michelin/select-options-provider';
import { AxleName } from 'modules/Tires/types';
import { useFormContext } from 'react-hook-form';
import { getAxle, getTheOtherEqualSize } from '../utils';

interface props {
  open: boolean;
  axleName: AxleName;
  close: () => void;
  products: ProductOVM[];
}

export const AddTireSize = ({ open, axleName, close, products }: props) => {
  const { t } = useTranslation();
  const { location } = usePermissions();
  const { getValues, setValue, clearErrors } = useFormContext<TireProfileOVM>();

  const [tireSize, setTireSize] = useState('');

  const customerCountry = location ? location.customer_country : 'USA';
  const country = (!customerCountry || customerCountry.substring(0, 2)) === 'US' ? 'USA' : 'CAN';
  const axle = { ...getAxle(axleName, getValues()) };
  const equalSizes = getOptions('equal_sizes').map((option: SelectOption) => option.value);

  const selectedTireSizes = (): string[] => {
    if (!axle) return [];
    const data = axle.tire_replacement_priority?.map((item) => item.tire_size as string);
    const tireObjectsOne = data?.filter((v, i) => data?.indexOf(v) === i) || [];
    let toReturn = tireObjectsOne;
    if (tireObjectsOne.length > 0) {
      tireObjectsOne.forEach((tireSize) => {
        if (tireSize) {
          for (let i = 0; i < equalSizes.length; i++) {
            let size = '';
            if (tireSize === equalSizes[i].split('~')[0]) {
              size = equalSizes[i].split('~')[1];
            } else if (tireSize === equalSizes[i].split('~')[1]) {
              size = equalSizes[i].split('~')[0];
            }
            if (size !== '') {
              if (!toReturn.includes(size)) {
                toReturn.push(size);
              }
              break;
            }
          }
        }
      });
    }
    return toReturn;
  };

  const tiresSizesOptions = () => {
    const selectedTire = selectedTireSizes();
    const auxTireSizesOptions = getOptions(`${country}_tire_sizes` as any).filter(
      (tireSize) => !selectedTire.includes(tireSize.value),
    );
    const equalSizes = getOptions('equal_sizes').map((option: SelectOption) => option.value);

    const tireSizesOptions = auxTireSizesOptions.filter((option: SelectOption) => {
      let thereIsSize = false;
      for (const product of products) {
        if (axleName === 'steer' && product.type === 'Retread') continue;
        if (product.isMichelin && product.sizes) {
          for (const size of product.sizes) {
            const otherSize: string | undefined = getTheOtherEqualSize(size.name || '', equalSizes);
            if (size.name === option.value || otherSize === option.value) {
              thereIsSize = true;
              break;
            }
          }
        }
        if (thereIsSize) {
          break;
        }
      }
      return thereIsSize;
    });
    return tireSizesOptions;
  };
  const addTireSize = () => {
    const newTireSize = {
      tire_type: axleName === 'steer' ? 'New' : '',
      tire_size: tireSize,
      priority: 1,
      brand: '',
      load_range: null,
      mspn: null,
      note: null,
      original_tire_depth: null,
      tread_design: null,
      width: null,
    };

    if (!axle.tire_replacement_priority || axle.tire_replacement_priority.length === 0) {
      clearErrors(`axles.${axleName}.tire_replacement_priority`);
    }
    if (axle && axle.tire_replacement_priority) {
      axle.tire_replacement_priority.push(newTireSize);
    } else {
      axle.tire_replacement_priority = [newTireSize];
    }

    setValue(`axles.${axleName}.tire_replacement_priority`, axle.tire_replacement_priority);
    close();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={() => close()}
      aria-labelledby="customized-dialog-title"
      open={open}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle id="form-dialog-title">{t('Add Tire Replacement Priority')}</DialogTitle>
      <DialogContent dividers>
        <Grid container>
          <Grid item xs={12}>
            <Select
              id="tire_size"
              name="main.profile_type"
              label={t('Tire Size')}
              fullWidth
              options={tiresSizesOptions()}
              onChange={(event: React.ChangeEvent<any>) => {
                setTireSize(event.target.value);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button size="small" style={{ margin: '8px' }} onClick={addTireSize} disabled={!tireSize}>
          {t('Add')}
        </Button>
        <Button size="small" color="danger" style={{ margin: '8px' }} onClick={() => close()}>
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
