/* eslint-disable no-param-reassign */
import qs from 'qs';
import { auth as authObject } from '../../Auth';
import GLOBAL_CONFIG from '../../global-variables';

export async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const apiBaseRoute = GLOBAL_CONFIG.externalAPIUrl;

export enum MethodType {
  POST = 'POST',
  PATCH = 'PATCH',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

interface CreateApiCallProps {
  method?: 'GET' | 'POST' | 'DELETE' | 'PUT';
  url: string;
  data?: any;
  auth?: boolean;
  includeTenant?: boolean;
  authGuestToken?: string;
  includeTenantInURL?: boolean;
  abortController?: AbortController;
}

export async function createApiCall({
  method = 'GET',
  url = '',
  data = {},
  auth = false,
  authGuestToken = '',
  abortController,
}: CreateApiCallProps) {
  try {
    const headers: { [key: string]: string } = { 'Content-Type': 'application/json' };
    if (auth && !authGuestToken) headers.Authorization = `Bearer ${authObject.getAuthResultTokenAccess()}`;
    if (authGuestToken) headers.Authorization = `${authGuestToken}`;

    const extraData = { ...data };

    if (method === 'GET' || method === 'DELETE') {
      url += (!url.includes('?') ? '?' : '&') + qs.stringify(extraData, { encode: false, arrayFormat: 'brackets' });
    }
    const responseData = await fetch(`${apiBaseRoute}${url}`, {
      body: method !== 'POST' && method !== 'PUT' ? undefined : JSON.stringify(extraData),
      cache: 'no-cache',
      headers,
      method,
      signal: abortController ? abortController.signal : undefined,
    });
    if (responseData.status >= 300) {
      const jsonError = await responseData.json();
      if (jsonError.errors) throw Error(JSON.stringify(jsonError.errors));
      throw Error(jsonError.error);
    }
    const result = await responseData.json();
    return { body: result, status: responseData.status, aborted: false };
  } catch (error) {
    return { body: error, status: 500, aborted: (error as { code: number }).code === 20 };
  }
}

export async function createFormDataApiCall({
  method = 'GET',
  url = '',
  data,
  auth = false,
  authGuestToken = '',
}: {
  method: string;
  url: string;
  data: any;
  auth: boolean;
  authGuestToken: string;
}) {
  const headers: { [key: string]: string } = {};

  if (auth && !authGuestToken) headers.Authorization = `Bearer ${authObject.getAuthResultTokenAccess()}`;
  if (authGuestToken) headers.Authorization = `${authGuestToken}`;

  const abortController = new AbortController();
  const responseData = await fetch(`${apiBaseRoute}${url}`, {
    body: data,
    headers,
    cache: 'no-cache',
    method,
    signal: abortController ? abortController.signal : undefined,
  });
  if (responseData.status >= 300) throw Error(`Error code: ${responseData.status}`);
  const result = await responseData.json();
  return { body: result, status: responseData.status, abort: abortController.abort };
}
