/* eslint-disable no-param-reassign */

/* eslint-disable no-console */

/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';
import { LoadingBounce } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { auth } from 'Auth';
import { getAllResultsPaginated, paginatedMultiQuery } from 'graphql/utils';
import { getChainedHierarchy, getHighestLocation, queryContactListPaginated } from '../utils';
import ContactChangeLogTable from './ContactChangeLogTable';
import { blacklistedFields } from './utils';

export interface PaginatedContactLocationRelationship {
  hash_key: string;
  range_key: string;
  is_deleted?: boolean;
  gsi1_hash_key?: string;
  gsi2_range_key?: string;
}

export async function getContactLocationsRelationshipsPaginated(
  highestLocation: string,
  chainedHierarchy: string,
  filterIsDeleted?: boolean,
): Promise<Array<PaginatedContactLocationRelationship>> {
  const variables = {
    location: highestLocation,
    chainedHierarchy,
  };

  // Repeated data:
  try {
    const dataPaginated = await getAllResultsPaginated(
      queryContactListPaginated,
      variables,
      350,
      'getContactLocationsRelationshipsPaginated',
      undefined,
      !filterIsDeleted,
    );
    if (dataPaginated) {
      return dataPaginated;
    }
    return [];
  } catch (e) {
    console.error(e);
  }
  return [];
}

export async function getContactsRelationships(filterIsDeleted?: boolean): Promise<any[]> {
  // Hierarchy
  // Parent
  const customerData = auth.getCustomerData();

  if (customerData !== null) {
    const highestLocation = getHighestLocation(customerData);
    const chainedHierarchy = getChainedHierarchy(customerData);

    if (highestLocation) {
      const dataPaginated = await getContactLocationsRelationshipsPaginated(
        highestLocation,
        chainedHierarchy,
        filterIsDeleted,
      );
      if (dataPaginated) {
        return dataPaginated;
      }
    }
  }

  return [];
}

function flatChangeLogsMapValues(changeLogsMapped: Map<string, any[]> | null): any[] {
  const changeLogs: any[] = [];

  if (changeLogsMapped) {
    const changeLogsValuesRaw = Array.from(changeLogsMapped.values());
    changeLogsValuesRaw.forEach((changelogValues) => {
      try {
        changelogValues.forEach((changeLogPaginated) => {
          const entityChangeLogs = JSON.parse(changeLogPaginated);
          if (Array.isArray(entityChangeLogs)) {
            entityChangeLogs.forEach((changeLog) => {
              changeLogs.push(changeLog);
            });
          }
        });
      } catch (e) {
        console.error(e);
      }
    });
  }

  return changeLogs;
}

async function getContactsChangesFromRelationships(relationships: any[]): Promise<any> {
  const queries = buildPaginatedChangesQuery(relationships);
  const changeLogsMapped = await paginatedMultiQuery(queries, 10, true);
  return flatChangeLogsMapValues(changeLogsMapped);
}

function buildContactChangeLogQueries(
  contact_hash_key: string,
  location_hash_key: string,
  key: string,
  queries: Map<string, string>,
  queryOnlyRelationships: boolean,
) {
  queries.set(
    `q_${key}_relation`,
    `getEntityChangeLog(hash_key: "${location_hash_key}", range_key:"v0_${contact_hash_key}") {
            items
            nextToken
        }`,
  );
  if (!queryOnlyRelationships) {
    queries.set(
      `q_${key}_contactChangeLog`,
      `getEntityChangeLog(hash_key: "${contact_hash_key}") {
              items
              nextToken
          }`,
    );
  }
}

function buildPaginatedChangesQuery(relationships: any[]) {
  const queries: Map<string, string> = new Map();
  const contactsQueried = new Set<string>();

  relationships.forEach((relationship, i) => {
    const { hash_key: locationHashKey, gsi1_hash_key: contactHashKey } = relationship;
    if (!contactsQueried.has(contactHashKey)) {
      contactsQueried.add(contactHashKey);
      buildContactChangeLogQueries(contactHashKey, locationHashKey, `${i}`, queries, false);
    } else {
      buildContactChangeLogQueries(contactHashKey, locationHashKey, `${i}`, queries, true);
    }
  });

  return queries;
}

function createdContactChangelog(
  contact: any,
  processedChangelogs: any[],
  mapContactNames: Map<string, any>,
  unmappedContactNames: Map<string, any[]>,
  t: Function,
) {
  const { hash_key, first_name, last_name, create_user, created_date } = contact;

  const changesToModify = unmappedContactNames.get(hash_key);
  mapContactNames.set(hash_key, { first_name, last_name });
  if (changesToModify) {
    changesToModify.forEach((change) => {
      change.firstName = first_name;
      change.lastName = last_name;
    });
  }

  processedChangelogs.push({
    firstName: first_name,
    lastName: last_name,
    updated_fieldItem: t('All'),
    originalValue: t('None'),
    new_value: t('Contact Added'),
    updatedBy: create_user,
    modifiedDate: created_date,
  });
}

function deletedContactChangelog(
  contact: any,
  firstName: string | undefined,
  lastName: string | undefined,
  t: Function,
) {
  const { update_user, update_date } = contact;
  return {
    firstName,
    lastName,
    updated_fieldItem: t('All'),
    originalValue: t('Contact'),
    new_value: t('Contact Removed'),
    updatedBy: update_user,
    modifiedDate: update_date,
  };
}

function modifiedContactChangelog(
  changelog: any,
  processedChangelogs: any[],
  mapContactNames: Map<string, any>,
  unmappedContactNames: Map<string, any[]>,
  t: Function,
) {
  const { update_user, update_date, changes, hash_key } = changelog;
  let firstName: string;
  let lastName: string;

  const contactNames = mapContactNames.get(hash_key);
  if (contactNames) {
    firstName = contactNames.first_name;
    lastName = contactNames.last_name;
  }

  const unmappedNamesChangelogs = unmappedContactNames.get(hash_key);

  if (changes && Array.isArray(changes)) {
    changes.forEach((change) => {
      const { old_value, new_value, updated_field } = change;

      if (blacklistedFields.includes(updated_field)) return;

      let processedChange;

      if (updated_field === 'is_deleted') {
        processedChange = deletedContactChangelog(changelog, firstName, lastName, t);
      } else {
        processedChange = {
          firstName,
          lastName,
          updated_fieldItem: change.updated_field,
          originalValue: old_value,
          new_value,
          updatedBy: update_user,
          modifiedDate: update_date,
        };
      }

      if (processedChange) {
        processedChangelogs.push(processedChange);
        if (unmappedNamesChangelogs) {
          unmappedNamesChangelogs.push(processedChangelogs);
          unmappedContactNames.set(hash_key, unmappedNamesChangelogs);
        } else {
          unmappedContactNames.set(hash_key, [processedChangelogs]);
        }
      }
    });
  }
}

function getLocationFromHashKey(hash_key: string): string {
  return hash_key.split('~').pop() || '';
}

function modifiedRelationshipChangelog(
  changelogs: any,
  processedChangelogs: any[],
  mapContactNames: Map<string, any>,
  unmappedContactNames: Map<string, any[]>,
  t: Function,
) {
  const { changes, update_date, update_user, hash_key, gsi1_hash_key } = changelogs;
  let firstName: string;
  let lastName: string;

  const contactNames = mapContactNames.get(gsi1_hash_key);
  if (contactNames) {
    firstName = contactNames.first_name;
    lastName = contactNames.last_name;
  }

  const unmappedNamesChangelogs = unmappedContactNames.get(gsi1_hash_key);

  changes.forEach((change: any) => {
    const { new_value, updated_field } = change;
    let processedChange;
    if (updated_field === 'is_deleted') {
      if (new_value === true) {
        // Deactivated relationship
        processedChange = {
          new_value: t('Location Removed {{locationId}}', { locationId: getLocationFromHashKey(hash_key) }),
          originalValue: '-',
          updated_fieldItem: t('Location'),
          modifiedDate: typeof update_date === 'string' ? update_date : '',
          updatedBy: typeof update_user === 'string' ? update_user : '',
          firstName,
          lastName,
        };
      } else if (new_value === false) {
        // Reactivated relationship
        processedChange = {
          new_value: t('Location Added {{locationId}}', { locationId: getLocationFromHashKey(hash_key) }),
          originalValue: '-',
          updated_fieldItem: t('Location'),
          modifiedDate: typeof update_date === 'string' ? update_date : '',
          updatedBy: typeof update_user === 'string' ? update_user : '',
          firstName,
          lastName,
        };
      }
    }

    if (processedChange) {
      processedChangelogs.push(processedChange);
      if (unmappedNamesChangelogs) {
        unmappedNamesChangelogs.push(processedChangelogs);
        unmappedContactNames.set(gsi1_hash_key, unmappedNamesChangelogs);
      } else {
        unmappedContactNames.set(gsi1_hash_key, [processedChangelogs]);
      }
    }
  });
}

function createdRelationshipChangelog(
  changelogs: any,
  processedChangelogs: any[],
  mapContactNames: Map<string, any>,
  unmappedContactNames: Map<string, any[]>,
  t: Function,
) {
  const { created_date, create_user, gsi1_hash_key, hash_key } = changelogs;
  let firstName: string = '';
  let lastName: string = '';

  const contactNames = mapContactNames.get(gsi1_hash_key);
  if (contactNames) {
    firstName = contactNames.first_name;
    lastName = contactNames.last_name;
  }

  const unmappedNamesChangelogs = unmappedContactNames.get(gsi1_hash_key);

  const processedChange = {
    firstName,
    lastName,
    new_value: t('Location Added {{locationId}}', { locationId: getLocationFromHashKey(hash_key.toString()) }),
    originalValue: '-',
    updated_fieldItem: t('Location'),
    updatedBy: typeof create_user === 'string' ? create_user : '',
    modifiedDate: typeof created_date === 'string' ? created_date : '',
  };

  processedChangelogs.push(processedChange);
  if (unmappedNamesChangelogs) {
    unmappedNamesChangelogs.push(processedChangelogs);
    unmappedContactNames.set(gsi1_hash_key, unmappedNamesChangelogs);
  } else {
    unmappedContactNames.set(gsi1_hash_key, [processedChangelogs]);
  }
}

function processChangeLogs(changeLogs: any[], t: Function): any[] {
  const processedChangelogs: any[] = [];
  const mapContactNames: Map<string, string> = new Map();
  const unmappedContactNames: Map<string, any[]> = new Map();

  changeLogs.forEach((changelog) => {
    const { hash_key, range_key } = changelog;
    if (range_key && typeof range_key === 'string') {
      if (range_key === 'v0_contact') {
        // Contact
        createdContactChangelog(changelog, processedChangelogs, mapContactNames, unmappedContactNames, t);
      } else if (hash_key.startsWith('1~') && range_key.startsWith('v0_2')) {
        // Relationship
        createdRelationshipChangelog(changelog, processedChangelogs, mapContactNames, unmappedContactNames, t);
      } else if (hash_key.startsWith('2') && range_key.startsWith('v')) {
        // Contact Change
        modifiedContactChangelog(changelog, processedChangelogs, mapContactNames, unmappedContactNames, t);
      } else if (hash_key.startsWith('1~') && range_key.startsWith('v')) {
        // Relationship Change
        modifiedRelationshipChangelog(changelog, processedChangelogs, mapContactNames, unmappedContactNames, t);
      }
    }
  });

  return processedChangelogs;
}

interface State {
  loading: boolean;
  data: any[] | undefined;
  error: any;
}

export default function ContactLogTable(): JSX.Element {
  const [state, setState] = useState<State>({
    loading: true,
    data: undefined,
    error: undefined,
  });
  const { loading, data } = state;
  const { t } = useTranslation();

  useEffect(() => {
    const retrieveChangeLogs = async () => {
      try {
        const relationships = await getContactsRelationships(true);
        const changeLogs = await getContactsChangesFromRelationships(relationships);
        const processedChangeLogs = processChangeLogs(changeLogs, t);
        setState({
          loading: false,
          data: processedChangeLogs,
          error: undefined,
        });
      } catch (e) {
        setState({
          loading: false,
          data: undefined,
          error: undefined,
        });
      }
    };

    retrieveChangeLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <LoadingBounce />;
  }

  if (data) {
    return <ContactChangeLogTable rows={data} />;
  }

  return <div />;
}
