import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import * as MaterialSpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { SpeedDialActionProps } from '@material-ui/lab/SpeedDialAction/SpeedDialAction';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import isTouchDevice from 'is-touch-device';

export interface SpeedDialActions extends SpeedDialActionProps {
  title: string;
  name?: string;
  titleOpen?: boolean;
  onClick?: () => void;
}

interface SpeedDialProps {
  direction: MaterialSpeedDial.SpeedDialProps['direction'];
  actions: SpeedDialActions[];
  icon?: React.ReactNode;
  hidden?: MaterialSpeedDial.SpeedDialProps['hidden'];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 380,
      transform: 'translateZ(0px)',
      flexGrow: 1,
    },
    speedDial: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }),
);

const useStylesStaticTooltipLabel = makeStyles((theme: Theme) =>
  createStyles({
    StaticTooltipLabel: {
      whiteSpace: 'nowrap',
      fontSize: '0.75rem',
      width: 'max-content',
      backgroundColor: theme.palette.background.default,
    },
  }),
);

function SpeedDial({ actions, direction, hidden, icon }: SpeedDialProps) {
  const [open, setOpen] = React.useState<boolean>(false);
  const classes = useStyles(open);

  const classesStaticTooltipLabel = useStylesStaticTooltipLabel();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const isTouch = typeof isTouchDevice() === 'boolean' && isTouchDevice();

  return (
    <div className={classes.root}>
      <MaterialSpeedDial.default
        ariaLabel="SpeedDial"
        className={classes.speedDial}
        hidden={hidden}
        icon={icon ?? <SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction={direction || 'up'}
        data-testid="speed-dial-actions"
      >
        {open &&
          actions.map((action) => {
            return (
              <SpeedDialAction
                data-testid={action.id}
                id={action.id}
                key={action.id}
                icon={action.icon}
                title={action.title}
                aria-label={action.name}
                tooltipTitle={action.title}
                tooltipOpen={action.titleOpen ?? isTouch}
                onClick={() => {
                  handleClose();
                  if (action.onClick) action.onClick();
                }}
                classes={{ staticTooltipLabel: classesStaticTooltipLabel.StaticTooltipLabel }}
              />
            );
          })}
      </MaterialSpeedDial.default>
    </div>
  );
}

export { SpeedDial };
