import React, { CSSProperties } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { ClosableDialogContent } from 'components/ClosableDialog/ClosableDialogContent';
import { ClosableDialogTitle } from 'components/ClosableDialog/ClosableDialogTitle';

const contentStyle: CSSProperties = {
  color: '#44565f',
  fontSize: '1.1em',
};

const titleStyle: CSSProperties = {
  color: '#224173',
  fontWeight: 'bold',
};

export function PlainMessage(props: { messages: string[]; title: string }) {
  return (
    <div>
      <h1 style={contentStyle}>{props.title}</h1>
      {props.messages.map((e, i) => (
        <span key={`line-${i}`} style={contentStyle}>
          {e}
          <br />
        </span>
      ))}
    </div>
  );
}

export function Alert(props: { onClose: () => void; messages: string[]; title: string; ok?: string }) {
  return (
    <Dialog onClose={props.onClose} aria-labelledby="customized-dialog-title" maxWidth="sm" fullWidth open>
      <ClosableDialogTitle onClose={props.onClose}>
        <span style={titleStyle}>{props.title}</span>
      </ClosableDialogTitle>
      <ClosableDialogContent>
        {props.messages.map((e, i) => (
          <span key={`line-${i}`} style={contentStyle}>
            {e}
            <br />
          </span>
        ))}
      </ClosableDialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary" autoFocus>
          {props.ok || 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface ConfirmProps {
  yes?: string;
  no?: string;
  onYes: () => void;
  onNo: () => void;
  title: string;
  messages: string[];
}

export function Confirm(props: ConfirmProps) {
  return (
    <div>
      <Dialog
        open
        onClose={props.onNo}
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <span style={titleStyle}>{props.title}</span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.messages.map((e, i) => (
              <span key={`line-${i}`} style={contentStyle}>
                {e}
                <br />
              </span>
            ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onNo} color="primary">
            {props.no || 'No'}
          </Button>
          <Button onClick={props.onYes} color="primary" autoFocus>
            {props.yes || 'Yes'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

enum MessageType {
  None = 'None',
  Alert = 'Alert',
  Confirm = 'Confirm',
}

interface MessageProviderState {
  visible: boolean;
  type: MessageType;
  title: string;
  messages: string[];
  okFn: () => void;
  okStr?: string;
  notOkFn: () => void;
  notOkStr?: string;
}

export class MessageProvider extends React.Component<{}, MessageProviderState> {
  private static __singletonRef: MessageProvider;

  constructor(props: {}) {
    super(props);
    this.state = {
      visible: false,
      type: MessageType.None,
      title: '',
      messages: [''],
      okStr: 'Yes',
      notOkStr: 'No',
      okFn: () => {},
      notOkFn: () => {},
    };
    MessageProvider.__singletonRef = this;
  }

  public static alert(title: string, messages: string[], okFn?: () => void, okStr?: string) {
    MessageProvider.__singletonRef.setState({
      visible: true,
      type: MessageType.Alert,
      title,
      messages,
      okFn: okFn || (() => {}),
      okStr,
    });
  }

  public static confirm(
    title: string,
    messages: string[],
    okFn?: () => void,
    notOkFn?: () => void,
    okStr?: string,
    notOkStr?: string,
  ) {
    MessageProvider.__singletonRef.setState({
      visible: true,
      type: MessageType.Confirm,
      title,
      messages,
      okFn: okFn || (() => {}),
      okStr,
      notOkFn: notOkFn || (() => {}),
      notOkStr,
    });
  }

  public static hide() {
    MessageProvider.__singletonRef.setState({
      visible: false,
      type: MessageType.None,
      title: '',
      messages: [''],
      okStr: 'Yes',
      notOkStr: 'No',
      okFn: () => {},
      notOkFn: () => {},
    });
  }

  render() {
    if (!MessageProvider.__singletonRef.state.visible) return null;
    if (MessageProvider.__singletonRef.state.type === MessageType.Alert)
      return MessageProvider.__singletonRef.renderAlert();
    if (MessageProvider.__singletonRef.state.type === MessageType.Confirm)
      return MessageProvider.__singletonRef.renderConfirm();
    return null;
  }

  renderAlert() {
    return (
      <Alert
        title={MessageProvider.__singletonRef.state.title}
        messages={MessageProvider.__singletonRef.state.messages}
        ok={MessageProvider.__singletonRef.state.okStr}
        onClose={() => {
          MessageProvider.__singletonRef.state.okFn();
          MessageProvider.hide();
        }}
      />
    );
  }

  renderConfirm() {
    return (
      <Confirm
        yes={MessageProvider.__singletonRef.state.okStr}
        no={MessageProvider.__singletonRef.state.notOkStr}
        title={MessageProvider.__singletonRef.state.title}
        messages={MessageProvider.__singletonRef.state.messages}
        onYes={() => {
          MessageProvider.__singletonRef.state.okFn();
          MessageProvider.hide();
        }}
        onNo={() => {
          MessageProvider.__singletonRef.state.notOkFn();
          MessageProvider.hide();
        }}
      />
    );
  }
}

export default {
  Alert,
  PlainMessage,
};
