import React, { FunctionComponent, memo, useState } from 'react';
import { Dialog, Fab, Grid, IconButton, Typography } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { Panel, Select, TextField } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { getOptions } from '@michelin/select-options-provider';
import { ClosableDialogContent } from 'components/ClosableDialog/ClosableDialogContent';
import { ClosableDialogTitle } from 'components/ClosableDialog/ClosableDialogTitle';
import EditDelivery from 'components/HoursOfOperation/Edit';
import ViewDelivery from 'components/HoursOfOperation/View';
import { ReactQuillEditor } from 'components/ReactQuillEditor/ReactQuillEditor';
import { HoursOfOperation } from 'components/Types';
import { SaveError } from '../../LocationTypes';
import { TireOrderingInformation } from '../../detailsIndex';

type OrderingProceduresProps = {
  editFlag: boolean;
  tireOrderingInfo: TireOrderingInformation;
  setTireOrderingInfo: Function;
  saveErrors: SaveError;
  countryCode: string;
};

const useStyles = makeStyles(() => ({
  header: {},
  quill: {
    height: '300px',
  },
}));

const OrderingProcedures: FunctionComponent<OrderingProceduresProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { editFlag, tireOrderingInfo, setTireOrderingInfo, saveErrors } = props;

  const [tireOrderingInformation, setTireOrderingInformation] = useState<TireOrderingInformation>(tireOrderingInfo);
  const [orderDialog, setOrderDialog] = useState<boolean>(false);
  const orderOptions = getOptions('how_orders');
  const onlineOptions = getOptions('online_how_orders');

  const editorConfig = {
    readOnly: !editFlag,
    isEditing: editFlag,
  };

  const openMe = () => {
    setOrderDialog(true);
  };

  return (
    <Panel title={t('Ordering Procedures')}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Select
            label={t('How Orders are Placed')}
            disabled={!editFlag}
            fullWidth
            value={tireOrderingInformation.howOrders}
            onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
              const val = event.target.value ? String(event.target.value) : '';
              setTireOrderingInformation({
                ...tireOrderingInformation,
                howOrders: val,
                emailLink: '',
                onlineWebsite: '',
              });
              tireOrderingInfo.howOrders = val;
              tireOrderingInfo.emailLink = '';
              tireOrderingInfo.onlineWebsite = '';
              setTireOrderingInfo(tireOrderingInfo);
            }}
            options={orderOptions}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          {/email/i.test(tireOrderingInformation.howOrders) && (
            <TextField
              label={t('Link to Email Template')}
              required
              fullWidth
              error={saveErrors.errors.includes('emailLink')}
              value={tireOrderingInformation.emailLink}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const val = e.currentTarget.value;
                setTireOrderingInformation({
                  ...tireOrderingInformation,
                  emailLink: val,
                });
                tireOrderingInfo.emailLink = val;
                setTireOrderingInfo(tireOrderingInfo);
              }}
              disabled={!editFlag}
              InputProps={{
                inputProps: { type: 'url', pattern: 'https://.*', placeholder: 'https://example.com' },
              }}
            />
          )}
          {/online/i.test(tireOrderingInformation.howOrders) && (
            <Select
              label={t('How Orders are Placed')}
              disabled={!editFlag}
              error={saveErrors.errors.includes('onlineWebsite')}
              fullWidth
              value={tireOrderingInformation.onlineWebsite}
              onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
                const val = event.target.value ? String(event.target.value) : '';
                setTireOrderingInformation({
                  ...tireOrderingInformation,
                  onlineWebsite: val,
                });
                tireOrderingInfo.onlineWebsite = val;
                setTireOrderingInfo(tireOrderingInfo);
              }}
              options={onlineOptions}
            />
          )}
          <Dialog
            onClose={() => setOrderDialog(false)}
            aria-labelledby="customized-dialog-title"
            open={orderDialog}
            fullWidth
            maxWidth="sm"
          >
            <ClosableDialogTitle onClose={() => setOrderDialog(false)}>{t('Ordering Hours')}</ClosableDialogTitle>
            <ClosableDialogContent dividers>
              <ViewDelivery hoursOfOperations={tireOrderingInformation.orderingHours} />
            </ClosableDialogContent>
          </Dialog>
        </Grid>
        <Grid item xs={12} md={4} />
        <Grid item xs={2}>
          <span>{t('Ordering Hours')}</span>
        </Grid>
        <Grid item xs={6}>
          {editFlag && (
            <EditDelivery
              hoursOfOperations={tireOrderingInformation.orderingHours}
              onChange={(hoursOfOperations: HoursOfOperation) => {
                setTireOrderingInformation({
                  ...tireOrderingInformation,
                  orderingHours: hoursOfOperations,
                });
                tireOrderingInfo.orderingHours = hoursOfOperations;
                setTireOrderingInfo(tireOrderingInfo);
              }}
            />
          )}
          {!editFlag && (
            <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="Add"
              style={{ margin: 8 }}
              onClick={() => {
                openMe();
              }}
            >
              <IconButton muiiconbutton-label style={{ padding: 3, color: 'white' }}>
                <CalendarToday />
              </IconButton>
              {t('Ordering Hours')}
            </Fab>
          )}
        </Grid>
        <Grid item xs={12} className={classes.quill}>
          <Typography variant="subtitle1">{t('Ordering Instructions')}</Typography>
          <ReactQuillEditor
            config={editorConfig}
            id="orderingInstructions"
            content={tireOrderingInformation.orderingInstructions || ''}
            onUpdateEditorValue={(id: string, content: string) => {
              setTireOrderingInformation({
                ...tireOrderingInformation,
                orderingInstructions: content,
              });
              tireOrderingInfo.orderingInstructions = content;
              setTireOrderingInfo(tireOrderingInfo);
            }}
          />
        </Grid>
      </Grid>
    </Panel>
  );
};

export default memo(OrderingProcedures);
