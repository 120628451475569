import i18n from '@michelin/central-provider/dist/translations/CentralTranslation';
import { runningOnCanvas } from '@michelin/central-provider/dist/utils/canvas';
import { DayWorkHours } from 'components/Types';
import { Customer } from '../Location/LocationTypes';

export type TranslateCallback = typeof i18n.t;

function twoDigits(a: number): string {
  if (a < 10) return `0${a.toString()}`;
  return a.toString();
}

export const currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

export function getPartialTimeString(time: string) {
  try {
    const parts = time.trim().split(':');
    if (parts.length < 2) return '';
    const minutes = parseInt(parts[1], 10);
    let hours = parseInt(parts[0], 10);
    let suffix = 'am';
    if (hours >= 12) {
      suffix = 'pm';
      if (hours > 12) hours -= 12;
    }
    return `${hours}:${twoDigits(minutes)} ${suffix}`;
  } catch (e) {
    return '';
  }
}

export function getTimeString(time?: DayWorkHours | null) {
  const begin: string = (time && time.begin) || '';
  const end: string = (time && time.end) || '';
  const suffix: string = begin && end && begin >= end ? ' (+1d)' : '';
  let result = `${getPartialTimeString(begin)} - ${getPartialTimeString(end)}${suffix}`;
  result = result.trim();
  if (result === '-') return '';
  return result;
}

export function getCountryCode(customer: Customer) {
  return customer.customer_country ? customer.customer_country.toLowerCase() : 'us';
}

export function getSortingCustomerType(customerType: string | null | undefined): number {
  if (!customerType) return 5;
  const first = customerType.trim().substring(0, 1).toLocaleLowerCase();
  switch (first) {
    case 'p':
      return 0;
    case 'h':
      return 1;
    case 'b':
      return 2;
    case 's':
      return 3;
    case 'm':
      return 4;
    default:
      return 5;
  }
}

export const CONTENT_AREA_HEIGHT = !runningOnCanvas() ? 'calc(100vh - 162px)' : 'calc(100vh - 162px + 65px)';
export const TABBED_CONTENT_HEIGHT = !runningOnCanvas() ? 'calc(100vh - 200px)' : 'calc(100vh - 200px + 65px)';
export const TABBED_CONTENT_HEIGHT_GRID = !runningOnCanvas() ? 'calc(100vh - 237px)' : 'calc(100vh - 237px + 65px)';

export function scrollToDisplayFirstError() {
  setTimeout(() => {
    const elem = document.getElementsByClassName('Mui-error')[0];
    if (!elem) return;
    try {
      // @ts-ignore
      elem.scrollIntoViewIfNeeded();
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }, 100);
}
