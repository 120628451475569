import React, { useEffect, useRef, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { Button, Panel, TextField, useDialogs } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { WheelProfileBrand } from '@michelin/fcp-view-models/dist/models/wheel-profile';
import { SelectOption } from '@michelin/select-options-provider';
import { auth } from 'Auth';
import { DataGrid } from 'devextreme-react';
import { Column, Editing, Lookup, RowDragging } from 'devextreme-react/data-grid';
import { useGetWheelBrands, useUpdateWheelBrand } from 'hooks/useWheelsProfile';
import uuid from 'uuid';
import { byVal, removeKeys } from '../../../components/Util/objectUtils';
import { WheelDetailsBrand } from '../types';

interface BrandPreferencesProps {
  title: string;
  isEditing: boolean;
  dataSource: WheelProfileBrand[];
  setDataHandler: Function;
}

export function BrandPreferencesComponent(props: BrandPreferencesProps) {
  const refDataGrid = useRef<DataGrid>(null);
  const { messageDialog } = useDialogs();
  const { t } = useTranslation();

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<WheelProfileBrand[]>();
  const [openAddBrandDialog, setOpenAddBrandDialog] = useState<boolean>(false);
  const [newBrandAdded, setNewBrandAdded] = useState<string>();

  const getWheelBrandsNew = useGetWheelBrands(t);
  const updateWheelBrans = useUpdateWheelBrand();

  useEffect(() => {
    setIsEditing(props.isEditing);
    setDataSource(props.dataSource ? props.dataSource : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isEditing, props.dataSource]);

  const reorderPreferences = (e: any) => {
    if (!dataSource) return;

    const localPreferences: WheelDetailsBrand[] = byVal(dataSource);
    localPreferences.splice(e.toIndex, 0, localPreferences.splice(e.fromIndex, 1)[0]);

    // remap priorities
    localPreferences.forEach((item: any, key: number) => {
      localPreferences[key].priority = key + 1;
    });

    setDataSource(localPreferences);
    props.setDataHandler(localPreferences);
  };

  const insertPreference = (e: any) => {
    if (!dataSource) return;

    // ID Needed for Change Logs.
    e.data.id = uuid.v4();

    const localPreferences: WheelDetailsBrand[] = byVal(dataSource);
    localPreferences.forEach((item: any, key: number) => {
      localPreferences[key].priority = key + 1;
      removeKeys(localPreferences[key], ['__KEY__']);
    });

    setDataSource(localPreferences);
    props.setDataHandler(localPreferences);
  };

  const updatePreference = (e: any) => {
    if (!dataSource) return;

    // This is it for imported data
    e.data.id = e.data.id ? e.data.id : uuid.v4();

    const localPreferences: WheelDetailsBrand[] = byVal(dataSource);

    localPreferences.forEach((item: any, key: number) => {
      if (e.data.priority === key + 1) {
        localPreferences[key].brand = e.data.brand;
      }
    });

    setDataSource(localPreferences);
    props.setDataHandler(localPreferences);
  };

  const removePreference = (e: any) => {
    if (!dataSource) return;

    let localPreferences: WheelDetailsBrand[] = byVal(dataSource);
    localPreferences = localPreferences.filter((item: any) => item.priority !== e.data.priority);

    localPreferences.forEach((item: any, key: number) => {
      localPreferences[key].priority = key + 1;
    });

    setDataSource(localPreferences);
    props.setDataHandler(localPreferences);
  };

  const AddNewRow = () => {
    if (!isEditing || !refDataGrid || !refDataGrid.current) return null;
    const dgi = refDataGrid.current.instance;
    if (!dgi) return null;
    return (
      <Button color="primary" onClick={dgi.insertRow} size="small" style={{ marginTop: 4 }}>
        {t('Add')}
      </Button>
    );
  };

  const setBrandCellValue = (newData: any, selectedValue: any) => {
    if (!dataSource) return;

    // check to see if already picked - if so don't let them again
    for (let i = 0; i < dataSource.length; i++) {
      if (dataSource && dataSource[i]?.brand?.toLowerCase() === selectedValue.toLowerCase()) {
        // show dialog and don't allow adding a duplicate
        messageDialog(
          t('{{brandValue}} is already selected. Each value selected must be unique', { brandValue: selectedValue }),
          // `${selectedValue} is already selected.  Each value selected must be unique.`,
          t('Duplicate Selection'),
          t('Close'),
        );
        return;
      }
    }

    if (selectedValue === 'other') {
      setOpenAddBrandDialog(true);
      // eslint-disable-next-line no-param-reassign
      newData.brand = newBrandAdded;
    } else {
      // eslint-disable-next-line no-param-reassign
      newData.brand = selectedValue;
    }
  };

  const prepareToolbar = (e: any) => {
    const toolbarItems = e.toolbarOptions.items;
    toolbarItems.splice((x: any) => x.name === 'addRowButton');
  };

  interface IAddBrandDialogProps {
    handleAddBrand: Function;
  }

  const AddBrandDialog = (p: IAddBrandDialogProps) => {
    const [brandToAdd, setBrandToAdd] = useState<string>();

    return (
      <Dialog open={openAddBrandDialog} maxWidth="sm" fullWidth disableBackdropClick disableEscapeKeyDown>
        <DialogTitle>{t('Add Unlisted Wheel Brand')}</DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item xs={12} lg={12}>
              <TextField
                label={t('Brand To Add')}
                value={brandToAdd}
                onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setBrandToAdd(String(event.target.value));
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            style={{ margin: '8px' }}
            color="success"
            onClick={() => {
              p.handleAddBrand(brandToAdd);
            }}
          >
            {t('Add')}
          </Button>
          <Button
            size="small"
            style={{ margin: '8px' }}
            color="default"
            onClick={() => {
              setOpenAddBrandDialog(false);
            }}
          >
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleAddBrand = async (brand: string) => {
    setOpenAddBrandDialog(false);
    if (!auth || !auth.apolloClient) return null;

    // check to see if adding a brand already in the list - if so do not add it
    if (getWheelBrandsNew.data?.store.data.findIndex((v: SelectOption) => v.value.toLowerCase() === brand) !== -1) {
      // found in list
      return brand;
    }
    await updateWheelBrans.mutateAsync({ brand });

    setNewBrandAdded(brand);
    return brand;
  };

  return (
    <Panel title={t('Wheel Brands')} variant="secondary" control={<AddNewRow />}>
      {openAddBrandDialog && <AddBrandDialog handleAddBrand={(brand: string) => handleAddBrand(brand)} />}

      <div>{t('Select wheel brands in order of preference')}</div>
      {dataSource && (
        <DataGrid
          ref={refDataGrid}
          showBorders
          dataSource={dataSource}
          onRowInserted={insertPreference}
          onRowUpdated={updatePreference}
          onRowRemoved={removePreference}
          onToolbarPreparing={prepareToolbar}
          noDataText={t('No wheel brands brands selected')}
        >
          <Editing mode="row" allowUpdating={isEditing} allowAdding={isEditing} allowDeleting={isEditing} />
          <RowDragging allowReordering={isEditing} showDragIcons onReorder={reorderPreferences} />
          <Column
            dataField="priority"
            caption={t('Priority')}
            width="20%"
            alignment="center"
            allowEditing={false}
            allowSorting={false}
          />
          <Column
            dataField="brand"
            caption={t('Brand')}
            width="40%"
            allowSorting={false}
            setCellValue={setBrandCellValue}
          >
            <Lookup dataSource={getWheelBrandsNew.data} valueExpr="value" displayExpr="label" />
          </Column>
        </DataGrid>
      )}
    </Panel>
  );
}
