/* eslint-disable no-restricted-syntax */

/* eslint-disable no-console */

/* eslint-disable @typescript-eslint/no-use-before-define */

/* eslint-disable no-param-reassign */

/* eslint-disable no-case-declarations */
import React, { useEffect, useReducer } from 'react';
import { Grid } from '@material-ui/core';
import { ArrowBack, Edit, Save, Timelapse } from '@material-ui/icons';
import {
  Container,
  Fab,
  GlobalActions,
  LoadingBounce,
  Panel,
  PreventTransitionPrompt,
  WithDialogsProps,
  useDialogs,
} from '@michelin/acid-components';
import { usePermissions, useTranslation } from '@michelin/central-provider';
import { SelectOption, getOptions } from '@michelin/select-options-provider';
import { Customer, auth } from 'Auth';
import LastModified from 'components/ChangeLog/LastModified';
import HoursOfOperationEdit from 'components/HoursOfOperation/Edit';
import HoursOfOperationView from 'components/HoursOfOperation/View';
import { AssigneeCustomerType } from 'components/LocationAssignments/queries';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { CONTENT_AREA_HEIGHT, TranslateCallback, scrollToDisplayFirstError } from 'components/Util';
import { OptionsObject, SnackbarKey, SnackbarMessage, useSnackbar } from 'notistack';
import { getBaseUrl } from 'prefs-and-service-offers';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import uuid from 'uuid';
import {
  Contact,
  ContactLocationRelationship,
  ContactType,
  NotificationSubscription,
  contactLevelNotAuthorizedByHierarchyMessage,
  contactQuery,
  contactTypeRoleDeleteNotAuthorizedByHierarchyMessage,
  deepOmit,
  getExistingContactQuery,
  notAuthorizedByHierarchyTitle,
  obtainGsi2RelationHashKey,
  obtainGsi2RelationRangeKey,
} from '../utils';
import ApprovalLimits from './ApprovalLimits';
import ContactTypes from './ContactTypes';
import { ChangeEvent as SelectorChangeEvent } from './ContactTypes/Selector';
import ManageContactTypesLevelsDialog from './ContactTypes/dialogs/ManageContactTypesLevelsDialog';
import ReassignContactTypesDialog, { ReassignSelection } from './ContactTypes/dialogs/ReassignContactTypesDialog';
import UnassignPriorityLevelsDialog from './ContactTypes/dialogs/UnassignPriorityLevelsDialog';
import {
  contactLevelUpdateDispatchHandler,
  contactTypeChangeDispatchHandler,
  getDifferencesBetweenLocations,
  handleSwap,
  locationsUpdateDispatchHandler,
  parseDaysAndLocation,
} from './ContactTypes/utils';
import GeneralInformation from './GeneralInformation';
import NotificationSubscriptions from './NotificationSubscriptions';
import NotificationSubscriptionsDialog from './NotificationSubscriptionsDialog';
import {
  ExternalLevelDeassignation,
  GENERAL_LEVEL_VALUE,
  buildAndRunSaveMutationQueries,
  canUserEditByHierarchy,
  contactNeedsFirstTimeAssign,
  createContactHash,
  createInitState,
  createInitialEmptyContact,
  handleSavePriorityLevelsChange,
  isHierarchyHigher,
} from './utils';

function useContactsReducer(state: State, action: Action): State {
  const {
    contact,
    originalContact,
    errorFields,
    notificationSubscriptionSelected,
    unassignPriorityLevel,
    externalContactsWithRoleAreaReassigned,
    t,
  } = state;
  let modified: boolean = true;

  switch (action.type) {
    case 'contactCreation':
      const { contact: contactCreated, originalContact: originalContactCreated } = action;

      return {
        ...state,
        contact: contactCreated,
        originalContact: originalContactCreated,
        loading: false,
        contactId: action.contactId,
        panelTitle: t('Contact creation'),
        panelSubtitle: '',
      };

    case 'contactDataRetrieved':
      const { contact: contactRetrieved, originalContact: originalContactRetrieved } = action;
      if (contactRetrieved && !contactRetrieved.contact_types) {
        contactRetrieved.contact_types = [];
        originalContactRetrieved.contact_types = [];
      }
      return {
        ...state,
        contact: contactRetrieved,
        originalContact: originalContactRetrieved,
        loading: false,
        panelTitle: 'Contact',
        panelSubtitle: `${contactRetrieved.first_name} ${contactRetrieved.last_name}`,
        contactId: action.contactId,
      };

    case 'fieldUpdated':
      const {
        event: fieldUpdatedEvent,
        newValue: fieldUpdateNewValue,
        validateFunction,
        errorField: fieldUpdatedErrorField,
      } = action;

      if (!contact) return state;

      const path = fieldUpdatedEvent.target.id.split('.');
      let a: any = contact;

      while (path.length > 1) a = a[path.shift()];
      a[path[0]] = fieldUpdatedEvent.target.value || fieldUpdateNewValue || '';

      if (originalContact) {
        modified = JSON.stringify(contact) !== JSON.stringify(originalContact);
      }

      if (validateFunction) {
        if (!validateFunction(a[path[0]])) {
          if (fieldUpdatedErrorField) {
            errorFields.add(fieldUpdatedErrorField);
            return {
              ...state,
              errorFields,
              modified,
              contact,
            };
          }
        }
      }

      if (fieldUpdatedErrorField) {
        errorFields.delete(fieldUpdatedErrorField);
      }
      return {
        ...state,
        contact,
        modified,
      };

    case 'workHoursUpdated':
      const { contact: workHoursContact } = action;
      return {
        ...state,
        contact: workHoursContact,
        modified: JSON.stringify(state.contact) !== JSON.stringify(state.originalContact),
      };

    case 'onSubscriptionDeleted':
      return {
        ...state,
        notificationSubscriptionSelected: action.notificationSubscription,
        openDeleteNotificationSubscriptionDialog: true,
      };

    case 'onSubscriptionEdited':
      return {
        ...state,
        notificationSubscriptionSelected: JSON.parse(JSON.stringify(action.notificationSubscription)),
        openNotificationsSubscriptionDialog: true,
      };

    case 'handleAddNotification':
      return {
        ...state,
        notificationSubscriptionSelected: {
          event: undefined,
          type: undefined,
          method: undefined,
          cc_emails: [],
          id: uuid.v4(),
        },
        openNotificationsSubscriptionDialog: true,
      };

    case 'notificationSubscriptionOnCreateSubscription':
      if (contact) {
        let { notification_subscription: notificationSubscriptionToModify } = contact;

        if (notificationSubscriptionSelected) {
          if (notificationSubscriptionToModify) {
            const notificationSubIndex = notificationSubscriptionToModify.findIndex(
              (x) => x.id === notificationSubscriptionSelected.id,
            );
            if (notificationSubIndex === -1) {
              notificationSubscriptionToModify.push(notificationSubscriptionSelected);
            } else {
              notificationSubscriptionToModify[notificationSubIndex] = notificationSubscriptionSelected;
            }
          } else {
            // It's a notification subscription creation
            notificationSubscriptionToModify = [notificationSubscriptionSelected];
          }
        }

        return {
          ...state,
          contact: {
            ...contact,
            notification_subscription: JSON.parse(JSON.stringify(notificationSubscriptionToModify)),
          },
          modified: JSON.parse(JSON.stringify(contact)) !== JSON.parse(JSON.stringify(originalContact)),
        };
      }
      return state;

    case 'closeNotificationsSubscriptionDialog':
      return {
        ...state,
        openNotificationsSubscriptionDialog: false,
      };

    case 'locationsAssigned':
      return locationsUpdateDispatchHandler(state, action.relationshipsToSave);

    case 'closeDeleteNotificationsSubscriptionDialog':
      return { ...state, openDeleteNotificationSubscriptionDialog: false };

    case 'deleteNotificationSubscription':
      if (contact) {
        const { notification_subscription: notificataionsSubscriptionToDleteNs } = contact;
        if (notificataionsSubscriptionToDleteNs) {
          const { id: nsIdToDelete } = notificationSubscriptionSelected;

          const indexToRemoveNs = notificataionsSubscriptionToDleteNs.findIndex((x) => x.id === nsIdToDelete);

          if (indexToRemoveNs !== -1) {
            notificataionsSubscriptionToDleteNs.splice(indexToRemoveNs, 1);
          }

          return {
            ...state,
            contact: {
              ...contact,
              notification_subscription: JSON.parse(JSON.stringify(notificataionsSubscriptionToDleteNs)),
            },
            modified: JSON.parse(JSON.stringify(contact)) !== JSON.parse(JSON.stringify(originalContact)),
            openDeleteNotificationSubscriptionDialog: false,
          };
        }
      }
      return state;

    case 'discardEdition':
      if (originalContact) {
        return {
          ...state,
          contact: JSON.parse(JSON.stringify(originalContact)),
          modified: false,
        };
      }
      // Invalid state
      return {
        ...state,
        modified: false,
      };

    case 'updateOriginalData':
      return {
        ...state,
        originalContact: JSON.parse(JSON.stringify(contact)),
      };

    case 'contactLevelUpdate':
      const { event: contactLevelUpdateEvent } = action;
      const contactLevelUpdateNewValue = contactLevelUpdateEvent.target.value;
      return contactLevelUpdateDispatchHandler(state, contactLevelUpdateNewValue);

    case 'savingContact':
      return {
        ...state,
        saving: true,
      };

    case 'contactSaved':
      if (contact) {
        if (action.saved) {
          return {
            ...state,
            saving: false,
            originalContact: JSON.parse(JSON.stringify(contact)),
            contact,
            modified: false,
            panelTitle: 'Contact',
            panelSubtitle: `${contact.first_name} ${contact.last_name}`,
          };
        }
        return {
          ...state,
          saving: false,
          modified: false,
        };
      }
      // Invalid state
      return state;

    case 'contactTypeChange':
      return contactTypeChangeDispatchHandler(state, action);

    case 'priorityLevelsClick':
      return {
        ...state,
        openManageContactTypesLevelsDialog: true,
      };

    case 'managePriorityLevelsClose':
      if (action.save) {
        const { mappedExternalContacts, selections } = action;
        return handleSavePriorityLevelsChange(
          state,
          mappedExternalContacts,
          externalContactsWithRoleAreaReassigned,
          selections,
        );
      }
      return {
        ...state,
        openManageContactTypesLevelsDialog: false,
        locationsFilters: new Set(),
        saveOnCloseManageContactTypes: false,
        avoidWarningUnassignAfterManage: false,
      };

    case 'handleReassignContactTypesClose':
      const { unassignLocalRoleLocationComboSet } = action;
      if (action.save) {
        if (contact && unassignPriorityLevel && unassignPriorityLevel.onReassign) {
          const newContactReassigned = unassignPriorityLevel.onReassign();
          Array.from(action.contactSelectedReassignRoleAreaLocation.values()).forEach((x) => {
            const { level, location, roleArea } = x;
            externalContactsWithRoleAreaReassigned.set(roleArea + location + level, x);
          });

          // Set Reassigned Role Areas as General.
          if (newContactReassigned.contact_types) {
            newContactReassigned.contact_types.forEach((contactType) => {
              const { role_areas: roleAreas } = contactType;
              if (!roleAreas) return;
              roleAreas.forEach((contactTypeRoleArea) => {
                const { levels, role_area: roleArea } = contactTypeRoleArea;
                levels.forEach((contactTypeLevel) => {
                  const { level, location } = contactTypeLevel;
                  if (externalContactsWithRoleAreaReassigned.has(roleArea + location + level)) {
                    contactTypeLevel.level = GENERAL_LEVEL_VALUE;
                  }
                });
              });
            });
          }

          return {
            ...state,
            contact: JSON.parse(JSON.stringify(newContactReassigned)),
            openReassignContactTypesDialog: false,
            modified: true,
            externalContactsWithRoleAreaReassigned,
            locationsFilters: new Set(),
          };
        }
      }

      if (unassignLocalRoleLocationComboSet.size > 0) {
        // Clean contact
        if (typeof contact !== 'undefined' && contact.contact_types) {
          contact.contact_types.forEach((contactType) => {
            const { role_areas: roleAreas } = contactType;
            if (!roleAreas) return;
            roleAreas.forEach((contactTypeRoleArea) => {
              const { levels, role_area: roleArea } = contactTypeRoleArea;
              levels.forEach((contactTypeLevel) => {
                const { location } = contactTypeLevel;
                if (unassignLocalRoleLocationComboSet.has(roleArea + location)) {
                  contactTypeLevel.level = GENERAL_LEVEL_VALUE;
                }
              });
            });
          });
        }

        if (typeof originalContact !== 'undefined' && originalContact.contact_types) {
          originalContact.contact_types.forEach((contactType) => {
            const { role_areas: roleAreas } = contactType;
            if (!roleAreas) return;
            roleAreas.forEach((contactTypeRoleArea) => {
              const { levels, role_area: roleArea } = contactTypeRoleArea;
              levels.forEach((contactTypeLevel) => {
                const { location } = contactTypeLevel;
                if (unassignLocalRoleLocationComboSet.has(roleArea + location)) {
                  contactTypeLevel.level = GENERAL_LEVEL_VALUE;
                }
              });
            });
          });
        }

        return {
          ...state,
          contact: JSON.parse(JSON.stringify(contact)),
          originalContact: JSON.parse(JSON.stringify(originalContact)),
          modified: true,
          openReassignContactTypesDialog: false,
          locationsFilters: new Set(),
        };
      }

      return {
        ...state,
        openReassignContactTypesDialog: false,
        locationsFilters: new Set(),
      };

    case 'reassignContactTypes':
      if (unassignPriorityLevel) {
        return {
          ...state,
          openReassignContactTypesDialog: true,
          unassignPriorityLevel: {
            ...unassignPriorityLevel,
            open: false,
            secondaryDependencies: action.conflicts,
          },
        };
      }
      return {
        ...state,
        unassignPriorityLevel: undefined,
      };

    case 'continueWarningPriorityLevelUnassign':
      if (action.confirm && unassignPriorityLevel) {
        const contactPriorityLevelsChanged = unassignPriorityLevel.onContinue();
        return {
          ...state,
          contact: JSON.parse(JSON.stringify(contactPriorityLevelsChanged)),
          unassignPriorityLevel: undefined,
          modified: true,
        };
      }
      return {
        ...state,
        unassignPriorityLevel: undefined,
      };

    case 'firstTimeAssign':
      return {
        ...state,
        openManageContactTypesLevelsDialog: true,
        saveOnCloseManageContactTypes: true,
      };

    case 'firstTimeAssignSaving':
      return {
        ...state,
        executeSaving: false,
      };

    case 'errorField':
      errorFields.add(action.field);

      return {
        ...state,
        errorFields: new Set(errorFields),
      };

    case 'newLocationsContactTypesAssign':
      const { newLocations } = action;
      const newLocationsFilter = new Set<string>();

      newLocations.forEach((x) => {
        newLocationsFilter.add(x.hash_key.toString());
      });

      return {
        ...state,
        openManageContactTypesLevelsDialog: true,
        saveOnCloseManageContactTypes: true,
        avoidWarningUnassignAfterManage: true,
        locationsFilters: newLocationsFilter,
      };

    case 'swapContactType':
      const newSwapState = handleSwap(state, action.roleArea, action.level, action.locationHashKey);
      return newSwapState;

    default:
      throw new Error();
  }
}

export interface DependencyConflict {
  role_area: string;
  service: string;
  location_hash_key: string;
}

interface UnassignPriorityLevelState {
  open: boolean;
  onContinue: () => Contact;
  onReassign: () => Contact;
  dialogTitle: string;
  dialogContent: string;
  possiblePrimaryDependencyConflictList: Array<DependencyConflict>;
  onlyReassign?: boolean;
  specificRoleArea?: string;
  secondaryDependencies?: Array<DependencyConflict>;
}

export interface State {
  contact: Contact | undefined;
  originalContact: Contact | undefined;

  panelTitle: string;
  panelSubtitle: string;
  contactId: string;
  loading: boolean;
  modified: boolean;
  saving: boolean;
  errorFields: Set<string>;

  // Notification Subscription
  notificationSubscriptionSelected: NotificationSubscription;

  // Contact Types Priority Levels
  unassignPriorityLevel: UnassignPriorityLevelState | undefined;
  externalLevelDeassignation: Map<string, ExternalLevelDeassignation>;
  externalContactsWithRoleAreaReassigned: Map<string, ReassignSelection>;
  locationsFilters: Set<string>;
  saveOnCloseManageContactTypes: boolean;
  avoidWarningUnassignAfterManage: boolean;
  executeSaving: boolean;

  // Dialogs
  openManageContactTypesLevelsDialog: boolean;
  openNotificationsSubscriptionDialog: boolean;
  openDeleteNotificationSubscriptionDialog: boolean;
  openReassignContactTypesDialog: boolean;

  // Others

  t: TranslateCallback;
}

export type Action =
  | {
      type: 'contactCreation';
      contact: Contact;
      originalContact: Contact;
      contactId: string;
    }
  | {
      type: 'contactDataRetrieved';
      contact: Contact;
      originalContact: Contact;
      contactId: string;
    }
  | {
      type: 'fieldUpdated';
      event: any;
      newValue?: any;
      errorField?: string;
      validateFunction?: (field: any) => boolean;
    }
  | {
      type: 'workHoursUpdated';
      contact: Contact;
    }
  | {
      type: 'onSubscriptionEdited';
      notificationSubscription: any;
    }
  | {
      type: 'onSubscriptionDeleted';
      notificationSubscription: any;
    }
  | {
      type: 'handleAddNotification';
    }
  | {
      type: 'notificationSubscriptionOnCreateSubscription';
    }
  | {
      type: 'closeNotificationsSubscriptionDialog';
    }
  | {
      type: 'locationsAssigned';
      relationshipsToSave: Array<ContactLocationRelationship>;
    }
  | {
      type: 'deleteNotificationSubscription';
    }
  | {
      type: 'closeDeleteNotificationsSubscriptionDialog';
    }
  | {
      type: 'discardEdition';
    }
  | {
      type: 'updateOriginalData';
    }
  | {
      type: 'contactLevelUpdate';
      event: any;
    }
  | {
      type: 'savingContact';
    }
  | {
      type: 'contactSaved';
      saved: boolean;
    }
  | {
      type: 'contactTypeChange';
      event: SelectorChangeEvent;
    }
  | {
      type: 'priorityLevelsClick';
    }
  | {
      type: 'managePriorityLevelsClose';
      save: boolean;
      mappedExternalContacts: Map<string, Contact>;
      selections?: Set<string>;
      dialogs: WithDialogsProps;
    }
  | {
      type: 'handleReassignContactTypesClose';
      save: boolean;
      contactSelectedReassignRoleAreaLocation: Map<string, ReassignSelection>;
      unassignLocalRoleLocationComboSet: Set<string>;
    }
  | {
      type: 'reassignContactTypes';
      conflicts: Array<DependencyConflict>;
    }
  | {
      type: 'continueWarningPriorityLevelUnassign';
      confirm: boolean;
    }
  | {
      type: 'firstTimeAssign';
    }
  | {
      type: 'firstTimeAssignSaving';
    }
  | {
      type: 'errorField';
      field: string;
    }
  | {
      type: 'newLocationsContactTypesAssign';
      newLocations: Array<ContactLocationRelationship>;
    }
  | {
      type: 'swapContactType';
      roleArea: string;
      level: string;
      locationHashKey: string;
    };

export interface ContactControlDelegator {
  save?: () => void;
}

interface ContactDetailsProps {
  action: 'edit' | 'view' | 'create';
  avoidFirstTimeAssign?: boolean;
  disablePriorityLevels?: boolean;
  disableContactTypes?: boolean;
  disableAssignLocations?: boolean;
  disableContactLevel?: boolean;
  blockReassign?: boolean;
  hideHeader?: boolean;
  contactTypes?: Array<ContactType>;
  locations?: Array<Customer>;
  delegateControl?: ContactControlDelegator;
  onAfterBackHandler?: () => void;
  onAfterSaveHandler?: (contact: Contact) => void;
}

export function ContactDetails(props: ContactDetailsProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch<{ contactId: string }>();
  const permissions = usePermissions();
  const params = useParams<{ selectedAccount: string; contactId: string }>();
  const {
    action,
    contactTypes: preAssignedContactTypes,
    locations: preAssignedLocations,
    hideHeader,
    onAfterSaveHandler,
    disablePriorityLevels,
    disableContactTypes,
    disableAssignLocations,
    disableContactLevel,
    blockReassign,
  } = props;

  const accountType = auth.getAccountTypeShort();
  const filterLevelOptionsByUser = (option: any): boolean => {
    // Only filter options if is edit or create
    if (action === 'edit' || action === 'create') {
      return isHierarchyHigher(accountType, option.value);
    }
    return true;
  };

  const contactLevelOptions = getOptions('sorted_account_types').filter(filterLevelOptionsByUser);
  const preferredLanguageOptions = getOptions('preferred_languages');
  const preferredCommunicationOptions = getOptions('core_profile_preferred_communications', 'none', t('None'));

  const readPermission = permissions.allowsAction('contacts.read');
  const updatePermission = permissions.allowsAction('contacts.update');
  const createPermission = permissions.allowsAction('contacts.create');

  const { enqueueSnackbar } = useSnackbar();

  const [state, dispatch] = useReducer(useContactsReducer, createInitState(t));

  // If isEdit = true then the user is editing an already created contact.
  // If isEdit = false the the user is creating a contact.
  const isEdit = !!(action === 'edit' && updatePermission);
  const hierarchyEditable = canUserEditByHierarchy(state.contact, updatePermission);
  const viewMode = action === 'view' || state.saving;

  const dialogs: WithDialogsProps = useDialogs();

  const customerData = auth.getCustomerData();

  useEffect(() => {
    const contactId = match.params.contactId;

    if (!state.contact) {
      if (isEdit === false && action === 'create' && createPermission) {
        // Create a new Contact
        const handleNewContactCreation = (contact: Contact, originalContact: Contact, cId: string) => {
          if (auth.getAccountTypeShort() === 'ST') {
            const autoselectShipTo = async () => {
              if (customerData) {
                dispatch({
                  contact,
                  originalContact,
                  contactId: cId,
                  type: 'contactCreation',
                });

                const {
                  hash_key: autoSelectedLocationHashKey,
                  parent_company_number: autoSelectedParentCompanyNumber,
                  home_office_number: autoSelectedHomeOfficeNumber,
                  bill_to_customer: autoSelectedBillToNmber,
                  relationship: autoSelectedLocationRelationship,
                } = customerData;
                const higerLocationParentAutoSelected =
                  autoSelectedParentCompanyNumber || autoSelectedHomeOfficeNumber || autoSelectedBillToNmber;
                if (
                  autoSelectedLocationHashKey &&
                  autoSelectedLocationRelationship &&
                  higerLocationParentAutoSelected
                ) {
                  const autoselectedRels: Array<ContactLocationRelationship> = [
                    {
                      hash_key: autoSelectedLocationHashKey,
                      range_key: `v0_${contact.hash_key}`,
                      gsi1_hash_key: contact.hash_key,
                      gsi1_range_key: autoSelectedLocationHashKey,
                      gsi2_hash_key: `2~${higerLocationParentAutoSelected}`,
                      gsi2_range_key: autoSelectedLocationRelationship,
                      is_deleted: false,
                      contact,
                      // TODO: Control DATA - DANGER - Component assign locations is still in WIP.
                      location: customerData as any,
                    },
                  ];
                  dispatch({ type: 'locationsAssigned', relationshipsToSave: autoselectedRels });
                }
              }
            };
            autoselectShipTo();
          } else {
            dispatch({
              contact,
              originalContact,
              contactId: cId,
              type: 'contactCreation',
            });
          }
        };

        const newContactCreationParams: ContactCreationParams = {
          contactLevelOptions,
          preferredLanguageOptions,
          preAssignedContactTypes,
          preAssignedLocations,
          handleNewContactCreation,
        };

        getNewContact(newContactCreationParams);
      } else if (typeof contactId === 'string') {
        // Retrieve a Contact Data
        const newContactDataRetrievedHandler = (contact: Contact, originalContact: Contact) => {
          dispatch({
            contact,
            originalContact,
            contactId: contactId || '',
            type: 'contactDataRetrieved',
          });
        };
        getContactData(contactId, newContactDataRetrievedHandler);
      } else {
        // Invalid state message to user TODO
      }
    }
  }, [
    action,
    contactLevelOptions,
    createPermission,
    isEdit,
    preAssignedContactTypes,
    preAssignedLocations,
    preferredLanguageOptions,
    customerData,
    state,
    match.params.contactId,
  ]);

  const {
    contact,
    originalContact,
    loading,
    panelTitle,
    panelSubtitle,
    modified,
    errorFields,
    saving,
    unassignPriorityLevel,
    openManageContactTypesLevelsDialog,
    openReassignContactTypesDialog,
    externalContactsWithRoleAreaReassigned,
    locationsFilters,
    executeSaving,
  } = state;

  useEffect(() => {
    if (executeSaving && saving === false) {
      dispatch({ type: 'firstTimeAssignSaving' });
      dispatch({ type: 'savingContact' });
      const handleNewLocationsAssign = () => {};
      const handleFirstTimeAssign = () => {};
      const handleErrorField = (errorField: string) => {
        dispatch({ type: 'errorField', field: errorField });
      };
      saveContact(
        state,
        dialogs,
        isEdit,
        onAfterSaveHandler,
        props.avoidFirstTimeAssign || false,
        handleFirstTimeAssign,
        handleNewLocationsAssign,
        handleErrorField,
        enqueueSnackbar,
        t,
        history,
      ).then((saved: boolean) => {
        dispatch({ type: 'contactSaved', saved });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executeSaving]);

  useEffect(
    () => () => {
      if (props.delegateControl) delete props.delegateControl.save;
    },
    [props.delegateControl],
  );

  // Read
  if (action === 'view' && readPermission === false) {
    enqueueSnackbar(t('Your user does not have enough permissions to read the information about this contact.'), {
      variant: 'warning',
    });
    history.push(getBaseUrl());
  }

  // Update
  // if (action === "edit" && (updatePermission === false || hierarchyEditable === false)) {
  if (action === 'edit' && updatePermission === false) {
    enqueueSnackbar('Your user does not have enough permissions to edit the information about this contact.', {
      variant: 'warning',
    });
    history.push(getBaseUrl());
  }

  // Create
  if (action === 'create' && createPermission === false) {
    enqueueSnackbar('Your user does not have enough permissions to create a contact.', { variant: 'warning' });
    history.push(getBaseUrl());
  }
  const header = !hideHeader ? (
    <PageTitle
      title={panelTitle || t('Contact')}
      subtitle={loading ? t('loading...') : panelSubtitle}
      rightItems={<LastModified entity="contact" identifier={params.contactId || ''} />}
    />
  ) : null;

  if (loading) {
    return (
      <>
        {header}
        <LoadingBounce style={{ height: CONTENT_AREA_HEIGHT }} />
      </>
    );
  }

  if (!contact || !originalContact || !readPermission) return null;

  // START: EDIT / CANCEL / BACK / SAVE BUTTONS LOGIC
  const handleCancelEditionClick = (pathname: string, act: string) => {
    history.push(`${getBaseUrl()}${pathname}`);
  };

  const handleDiscardEditionClick = () => {
    dispatch({
      type: 'discardEdition',
    });
  };

  const onEditClickHandler = () => {
    history.push(`${getBaseUrl()}/contacts/${state.contactId}/edit`);
  };

  const onClickSaveHandler = () => {
    if (!modified) return;
    dispatch({ type: 'savingContact' });
    const handleNewLocationsAssign = (newLocations: Array<ContactLocationRelationship>) => {
      dispatch({ type: 'newLocationsContactTypesAssign', newLocations });
    };
    const handleFirstTimeAssign = () => {
      dispatch({ type: 'firstTimeAssign' });
    };
    const handleErrorField = (errorField: string) => {
      dispatch({ type: 'errorField', field: errorField });
    };
    saveContact(
      state,
      dialogs,
      isEdit,
      onAfterSaveHandler,
      props.avoidFirstTimeAssign || false,
      handleFirstTimeAssign,
      handleNewLocationsAssign,
      handleErrorField,
      enqueueSnackbar,
      t,
      history,
    ).then((saved: boolean) => {
      dispatch({ type: 'contactSaved', saved });
    });
  };

  if (props.delegateControl) {
    props.delegateControl.save = viewMode ? onEditClickHandler : onClickSaveHandler;
  }

  if (contact.locations && openManageContactTypesLevelsDialog) {
    contact.locations.forEach((x) => {
      if (auth.isLocationAllowed(x.hash_key.toString()) && !locationsFilters.has('immutable')) {
        locationsFilters.add(x.hash_key.toString());
      }
    });
  }

  let secondBottomButton;
  if (saving) {
    secondBottomButton = (
      <Fab color="inherit" aria-label={t('Saving')} id="savingContactButton">
        <Timelapse />
      </Fab>
    );
  } else if (viewMode) {
    secondBottomButton = (
      <Fab
        color="primary"
        aria-label={t('Edit')}
        disabled={!hierarchyEditable || !updatePermission}
        onClick={onEditClickHandler}
        id="editContactButton"
      >
        <Edit />
      </Fab>
    );
  } else {
    secondBottomButton = (
      <Fab
        color={modified ? 'primary' : 'inherit'}
        aria-label={t('Save')}
        onClick={onClickSaveHandler}
        id="saveContactButton"
      >
        <Save />
      </Fab>
    );
  }

  /**
   * END: EDIT / CANCEL / BACK / SAVE BUTTONS LOGIC
   */

  const updateValueHandler =
    (validateFunction?: (field: any) => boolean, errorField?: string): ((event: any, newValue?: any) => void) =>
    (event: any, newValue: any = undefined) => {
      dispatch({
        type: 'fieldUpdated',
        event,
        newValue,
        validateFunction,
        errorField,
      });
    };

  const contactLevelUpdateValueHandler = (event: any) => {
    const { locations } = contact;
    if (locations && locations.length > 0) {
      if (locations.some((x) => auth.isLocationAllowed(x.hash_key.toString()) === false)) {
        dialogs.alertDialog(contactLevelNotAuthorizedByHierarchyMessage, notAuthorizedByHierarchyTitle, 'Ok');
      } else {
        dialogs.confirmDialog(
          t('Do you wish to continue?'),
          t('This action will unassign all the locations.'),
          t('Yes'),
          t('No'),
          () => {
            dispatch({
              type: 'contactLevelUpdate',
              event,
            });
          },
          undefined,
        );
      }
    } else {
      dispatch({
        type: 'contactLevelUpdate',
        event,
      });
    }
  };

  return (
    <>
      {header}
      <Container spacing={hideHeader ? 0 : undefined}>
        <Panel>
          <Grid container spacing={2} style={{ marginBottom: 16 }}>
            <Grid item xs={12} md={8}>
              <GeneralInformation
                viewMode={viewMode}
                errorFields={errorFields}
                contact={contact}
                updateValueHandler={updateValueHandler}
                contactLevelUpdateValueHandler={contactLevelUpdateValueHandler}
                preferredCommunicationOptions={preferredCommunicationOptions}
                preferredLanguageOptions={preferredLanguageOptions}
                contactLevelOptions={contactLevelOptions}
                disableAssignLocations={disableAssignLocations}
                disableContactLevel={disableContactLevel}
                locationsUpdateHandler={(locations: AssigneeCustomerType[]) => {
                  if (locations) {
                    const locationsMappedRelationships: ContactLocationRelationship[] = locations.map(
                      (locationToSave) => ({
                        hash_key: locationToSave.hash_key,
                        range_key: `v0_${contact.hash_key}`,
                        gsi1_hash_key: contact.hash_key,
                        gsi1_range_key: locationToSave.hash_key,
                        gsi2_hash_key: `2~${
                          locationToSave.parent_company_number ||
                          locationToSave.home_office_number ||
                          locationToSave.bill_to_customer
                        }`,
                        gsi2_range_key: locationToSave.relationship,
                        is_deleted: false,
                        contact,
                        location: locationToSave,
                      }),
                    );
                    dispatch({ type: 'locationsAssigned', relationshipsToSave: locationsMappedRelationships });
                  } else {
                    dispatch({ type: 'locationsAssigned', relationshipsToSave: [] });
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Panel title={t('Working Hours')}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {viewMode ? (
                      <HoursOfOperationView hoursOfOperations={contact.work_hours} mode="contact" />
                    ) : (
                      <HoursOfOperationEdit
                        hoursOfOperations={contact.work_hours}
                        mode="contact"
                        onChange={(hoo) => {
                          contact.work_hours = hoo;
                          dispatch({ type: 'workHoursUpdated', contact });
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Panel>
            </Grid>
            <ContactTypes
              contact={contact}
              disablePriorityLevels={disablePriorityLevels}
              viewMode={viewMode || disableContactTypes === true}
              onContactTypeChange={(event: SelectorChangeEvent) => {
                const { locations } = contact;
                if (locations && locations.some((x) => auth.isLocationAllowed(x.hash_key.toString()) === false)) {
                  dialogs.alertDialog(
                    contactTypeRoleDeleteNotAuthorizedByHierarchyMessage,
                    notAuthorizedByHierarchyTitle,
                    'Ok',
                  );
                } else {
                  dispatch({ type: 'contactTypeChange', event });
                }
              }}
              onPriorityLevelsClick={() => dispatch({ type: 'priorityLevelsClick' })}
            />
            <ManageContactTypesLevelsDialog
              contact={contact}
              handleClose={(save: boolean, mappedExternalContacts: Map<string, Contact>, selections?: Set<string>) => {
                dispatch({
                  type: 'managePriorityLevelsClose',
                  save,
                  mappedExternalContacts,
                  selections,
                  dialogs,
                });
              }}
              open={openManageContactTypesLevelsDialog}
              viewMode={viewMode}
              externalContactsWithRoleAreaReassigned={externalContactsWithRoleAreaReassigned}
              locationsFilters={locationsFilters}
              swapContactType={(roleArea: string, level: string, locationHashKey: string) => {
                dispatch({
                  type: 'swapContactType',
                  roleArea,
                  level,
                  locationHashKey,
                });
              }}
              avoidWarningUnassignAfterManage={state.avoidWarningUnassignAfterManage}
            />
            <ReassignContactTypesDialog
              contact={contact}
              open={openReassignContactTypesDialog}
              handleClose={(
                save: boolean,
                contactSelectedReassignRoleAreaLocation: Map<string, ReassignSelection>,
                unassignLocalRoleLocationComboSet: Set<string>,
              ) => {
                dispatch({
                  type: 'handleReassignContactTypesClose',
                  save,
                  contactSelectedReassignRoleAreaLocation,
                  unassignLocalRoleLocationComboSet,
                });
              }}
              alreadySelected={externalContactsWithRoleAreaReassigned}
              locationsFilters={locationsFilters}
              specificRoleAreaToReassign={unassignPriorityLevel ? unassignPriorityLevel.specificRoleArea : undefined}
              secondaryDependencies={unassignPriorityLevel ? unassignPriorityLevel.secondaryDependencies : []}
            />
            <UnassignPriorityLevelsDialog
              open={(unassignPriorityLevel && unassignPriorityLevel.open) || false}
              onClose={(confirm: boolean) => {
                dispatch({ type: 'continueWarningPriorityLevelUnassign', confirm });
              }}
              onReassign={(conflicts: Array<DependencyConflict>) => {
                dispatch({ type: 'reassignContactTypes', conflicts });
              }}
              content={
                unassignPriorityLevel
                  ? t(unassignPriorityLevel.dialogContent, {
                      firstName: contact.first_name,
                      lastName: contact.last_name,
                    })
                  : ''
              }
              title={
                unassignPriorityLevel
                  ? t(unassignPriorityLevel.dialogTitle, { firstName: contact.first_name, lastName: contact.last_name })
                  : ''
              }
              onlyReassign={unassignPriorityLevel ? unassignPriorityLevel.onlyReassign : false}
              blockReassign={blockReassign}
              possiblePrimaryDependencyConflictList={
                unassignPriorityLevel ? unassignPriorityLevel.possiblePrimaryDependencyConflictList : []
              }
            />
            <Grid item xs={12}>
              <ApprovalLimits
                contact={contact}
                viewMode={viewMode}
                errorFields={state.errorFields}
                updateValueHandler={updateValueHandler}
              />
            </Grid>
            <Grid item xs={12}>
              <NotificationSubscriptions
                viewMode={viewMode}
                notificationSubscriptions={contact.notification_subscription ? contact.notification_subscription : []}
                handleAddNotificationButton={() => {
                  dispatch({ type: 'handleAddNotification' });
                }}
                onSubscriptionEdited={(rowData: any) => {
                  dispatch({ type: 'onSubscriptionEdited', notificationSubscription: rowData });
                }}
                onSubscriptionDelete={(rowData: any) => {
                  dispatch({ type: 'onSubscriptionDeleted', notificationSubscription: rowData });
                }}
                messageDialog={dialogs.messageDialog}
              />
              {state.openNotificationsSubscriptionDialog && (
                <NotificationSubscriptionsDialog
                  open={state.openNotificationsSubscriptionDialog}
                  contact={contact}
                  notificationSubscriptionSelected={state.notificationSubscriptionSelected}
                  isEdit={!viewMode}
                  onClose={() => {
                    dispatch({ type: 'closeNotificationsSubscriptionDialog' });
                  }}
                  onCreateSubscription={() => {
                    dispatch({ type: 'notificationSubscriptionOnCreateSubscription' });
                  }}
                />
              )}
            </Grid>
          </Grid>
          <PreventTransitionPrompt
            when={modified && saving === false}
            handleDiscard={() => {
              if (typeof props.onAfterBackHandler === 'function') {
                props.onAfterBackHandler();
              } else if (props.action === 'edit') {
                handleDiscardEditionClick();
              }
            }}
          />
          {state.openDeleteNotificationSubscriptionDialog
            ? dialogs.confirmDialog(
                t('Do you want to delete this subscription?'),
                t('Delete Notification Subscription'),
                t('Yes'),
                t('No'),
                () => dispatch({ type: 'deleteNotificationSubscription' }),
                () => {
                  dispatch({ type: 'closeDeleteNotificationsSubscriptionDialog' });
                },
              )
            : undefined}
        </Panel>
      </Container>
      {!hideHeader ? (
        <GlobalActions>
          {secondBottomButton}
          <Fab
            color="primary"
            aria-label={t('Back')}
            onClick={() => {
              if (modified) {
                dialogs.confirmDialog(
                  t('Do you want to discard those changes?'),
                  t('You have unsaved changes...'),
                  t('Discard changes'),
                  t('Stay here'),
                  () => {
                    handleDiscardEditionClick();
                    setTimeout(() => {
                      switch (action) {
                        case 'edit':
                          handleCancelEditionClick(`/contacts/${state.contactId}/view`, 'view');
                          break;
                        case 'create':
                          handleCancelEditionClick('/contacts', 'view');
                          break;
                        default:
                          break;
                      }
                    }, 100);
                  },
                  undefined,
                );
              } else {
                setTimeout(() => {
                  switch (action) {
                    case 'view':
                      handleCancelEditionClick('/contacts', 'view');
                      break;
                    case 'edit':
                      handleCancelEditionClick(`/contacts/${state.contactId}/view`, 'view');
                      break;
                    case 'create':
                      handleCancelEditionClick('/contacts', 'view');
                      break;
                    default:
                      break;
                  }
                }, 100);
              }
            }}
            id="backButton"
          >
            <ArrowBack />
          </Fab>
        </GlobalActions>
      ) : null}
    </>
  );
}

/** CONTACT GENERATION (RETRIEVE AND CREATE) */

// This function assigns the locations that has been preassigned by parameter.
function processPreAssignedLocations(contact_id: string, preAssignedLocations: Array<Customer>) {
  let contactLevel: string | undefined;
  const locationRelationshipsAssigned: ContactLocationRelationship[] = [];

  (preAssignedLocations || []).forEach((location) => {
    // Define contact level from Location
    if (location.customer_type && !contactLevel) {
      contactLevel = location.customer_type;
    }

    locationRelationshipsAssigned.push({
      contact: undefined,
      hash_key: location.hash_key,
      range_key: `2~${contact_id}`,
      gsi1_hash_key: `2~${contact_id}`,
      gsi1_range_key: location.hash_key,
      gsi2_hash_key: obtainGsi2RelationHashKey(location),
      gsi2_range_key: obtainGsi2RelationRangeKey(location),
      is_deleted: false,
      location,
    });
  });

  return { contactLevel, locationRelationshipsAssigned };
}

interface ContactCreationParams {
  contactLevelOptions: Array<SelectOption>;
  preferredLanguageOptions: Array<SelectOption>;
  preAssignedContactTypes: Array<ContactType> | undefined;
  preAssignedLocations: Array<Customer> | undefined;
  handleNewContactCreation: (contact: Contact, originalContact: Contact, contactId: string) => void;
}

// Creates a new contact
function getNewContact(contactCreationParams: ContactCreationParams) {
  const {
    contactLevelOptions,
    preferredLanguageOptions,
    preAssignedContactTypes,
    preAssignedLocations,
    handleNewContactCreation,
  } = contactCreationParams;
  const contactId: string = uuid.v4();

  const contact: Contact = createInitialEmptyContact(contactId, contactLevelOptions, preferredLanguageOptions);
  contact.contact_types = preAssignedContactTypes || [];

  const originalContact: Contact = JSON.parse(JSON.stringify(contact));

  // Work out PreAssigned Locations
  if (preAssignedLocations) {
    const { locationRelationshipsAssigned, contactLevel } = processPreAssignedLocations(
      contactId,
      preAssignedLocations,
    );
    const assignedContactLevel = contactLevel || originalContact.contact_level;

    contact.locations = locationRelationshipsAssigned;
    contact.contact_level = assignedContactLevel;
    originalContact.contact_level = assignedContactLevel;
  }

  handleNewContactCreation(contact, originalContact, contactId);
}

// Retrieve Data of a Contact
async function getContactData(
  contactId: string,
  newContactDataRetrievedHandler: (contact: Contact, originalContact: Contact) => void,
) {
  if (!auth.apolloClient) {
    console.error('There is no apollo client set up.');
    return;
  }
  try {
    const data = await auth.apolloClient.query({
      query: contactQuery,
      variables: { hash_key: `2~${contactId}`, range_key: 'v0_contact' },
      fetchPolicy: 'no-cache',
    });

    const originalContact: Contact = data.data.getContactByKey;
    newContactDataRetrievedHandler(JSON.parse(JSON.stringify(originalContact)), originalContact);
  } catch (e) {
    console.error(e);
  }
}

function duplicatedDataInfoChange(contact: Contact, originalContact: Contact): boolean {
  return (
    contact.first_name !== originalContact.first_name ||
    contact.last_name !== originalContact.last_name ||
    contact.email_address !== originalContact.email_address ||
    contact.work_phone !== originalContact.work_phone
  );
}

// SAVE CONTACT
async function saveContact(
  state: State,
  dialogs: WithDialogsProps,
  isEdit: boolean,
  onAfterSaveHandler: ((contact: Contact) => void) | undefined,
  avoidFirstTimeAssign: boolean,
  handleFirstTimeAssign: () => void,
  handleNewLocationsAssign: (newLocations: Array<ContactLocationRelationship>) => void,
  handleErrorField: (errorField: string) => void,
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey,
  t: Function,
  history: ReturnType<typeof useHistory>,
): Promise<boolean> {
  const { modified, contact, originalContact, errorFields } = state;
  const { apolloClient } = auth;

  if (!modified) return false;
  if (!apolloClient) return false;
  if (!contact || !originalContact) return false;

  try {
    let newData = JSON.parse(JSON.stringify(contact));
    const error: InvalidContactResult | null = getContactIsValid(errorFields, contact, t);

    if (!error) {
      newData.gsi1_hash_key = createContactHash(newData.first_name, newData.last_name);
      newData = deepOmit(newData, ['__typename', 'tableData', 'locations']);

      // If locations changes the update.
      const { newRels, modifiedRels } = getDifferencesBetweenLocations(originalContact.locations, contact.locations);
      let firstTimeAssignExecuted = false;

      // It's an update to an already created contact.
      if (isEdit) {
        // Check that if the first name or last name changed it's not one already created.
        if (duplicatedDataInfoChange(contact, originalContact)) {
          const alreadyExistContact: boolean = await checkExistingContact(newData);
          if (alreadyExistContact) {
            dialogs.alertDialog(
              t('Already exists a Contact with the same first and last name. Please select another one to continue.'),
              t('Duplicated Contact'),
              t('Ok'),
              undefined,
            );
            return false;
          }
        }

        // Delete unmutable fields.
        delete newData.hash_key;
        delete newData.range_key;
        delete newData.locations;

        if (newData.email_address) newData.email_address = newData.email_address.trim();
        if (newData.email_address === '') newData.email_address = null;
        newData.tire_dollar_amount =
          newData.tire_dollar_amount && newData.tire_dollar_amount.toString().length > 0
            ? newData.tire_dollar_amount
            : 0;
        newData.mechanical_dollar_amount =
          newData.mechanical_dollar_amount && newData.mechanical_dollar_amount.toString().length > 0
            ? newData.mechanical_dollar_amount
            : 0;

        // Save new values
        await buildAndRunSaveMutationQueries(
          newData,
          contact,
          modifiedRels,
          state.externalLevelDeassignation,
          state.externalContactsWithRoleAreaReassigned,
          apolloClient,
          true,
        );

        isEdit = true;
        history.push(`${getBaseUrl()}/contacts/${contact.hash_key.slice(2, contact.hash_key.length)}/edit`);

        // Only do first time assign if the contact does not have any priority level.
        if (contactNeedsFirstTimeAssign(contact, originalContact, new Set<string>())) {
          firstTimeAssignExecuted = true;
          const firstNameLastName = `${contact ? contact.first_name : ''} ${contact ? contact.last_name : ''}`;
          const dialogTitle = t('Viewing Contact Type Priority Levels for: {{firstNameLastName}}', {
            firstNameLastName,
          });
          const dialogAskMessage = t(
            "Do you want to set contact type priority levels at assigned locations for {{firstNameLastName}}'s?",
            { firstNameLastName },
          );
          dialogs.confirmDialog(
            dialogAskMessage,
            dialogTitle,
            t('Ok'),
            t('Cancel'),
            () => {
              handleFirstTimeAssign();
            },
            undefined,
          );
        }
      } else {
        // It's a contact creation.
        const alreadyExistContact: boolean = await checkExistingContact(newData);
        if (alreadyExistContact) {
          dialogs.alertDialog(t('The Contact already exists.'), t('Duplicated Contact'), t('Ok'), undefined);
          return false;
        }
        // Delete unmutable fields.
        delete newData.hash_key;
        delete newData.range_key;
        delete newData.locations;

        if (newData.email_address) newData.email_address = newData.email_address.trim();

        await buildAndRunSaveMutationQueries(
          newData,
          contact,
          modifiedRels,
          state.externalLevelDeassignation,
          state.externalContactsWithRoleAreaReassigned,
          apolloClient,
          false,
        );
        if (typeof onAfterSaveHandler === 'function') {
          onAfterSaveHandler(contact);
        }
      }

      if (newRels.length > 0 && firstTimeAssignExecuted === false) {
        const newFilteredLocations = new Set<string>();
        newRels.forEach((x) => {
          newFilteredLocations.add(x.hash_key.toString());
        });
        // Only show if there is some location where there is no contact type assigned.
        if (!avoidFirstTimeAssign && contactNeedsFirstTimeAssign(contact, originalContact, newFilteredLocations)) {
          const firstNameLastName = `${contact ? contact.first_name : ''} ${contact ? contact.last_name : ''}`;
          const dialogTitle = t('Viewing Contact Type Priority Levels for: {{firstNameLastName}}', {
            firstNameLastName,
          });
          const dialogAskMessage = t(
            "Do you want to set contact type priority levels at the new assigned locations for {{firstNameLastName}}'s?",
            { firstNameLastName },
          );
          dialogs.confirmDialog(
            dialogAskMessage,
            dialogTitle,
            t('Ok'),
            t('Cancel'),
            () => {
              handleNewLocationsAssign(newRels);
            },
            undefined,
          );
        }
      }
      enqueueSnackbar(t('Successfully saved contact !'), { variant: 'success' });
      history.push(`${getBaseUrl()}/contacts/${contact.hash_key.slice(2, contact.hash_key.length)}/view`);
      return true;
    }
    const { errorField } = error;
    if (errorField) {
      handleErrorField(errorField);
    }

    dialogs.errorDialog(error.message, t('Missing Required Information.'), t('Ok'), undefined);
    scrollToDisplayFirstError();
    return false;
  } catch (e) {
    console.log(JSON.stringify(e, null, 4));
    if (e instanceof Error) {
      dialogs.alertDialog(e.message, t('Error saving Contact Data.'), t('Ok'), undefined);
    } else {
      dialogs.alertDialog(t('An unknown error occurred!'), t('Error saving Contact Data.'), t('Ok'), undefined);
    }
    return false;
  }
}

type InvalidContactResult = {
  title: string;
  message: string;
  errorField?: string;
} | null;

function getContactIsValid(errorFields: Set<string>, contact: Contact, t: Function): InvalidContactResult {
  const changeCouldNotBeApplied = t('Changes could not be applied');
  if (errorFields.has('email')) {
    return { title: changeCouldNotBeApplied, message: t('The email address is not valid.') };
  }
  if (errorFields.has('workPhone')) {
    const inputPhone = contact ? contact.work_phone : null;
    if (inputPhone) {
      return {
        title: changeCouldNotBeApplied,
        message: t('{{phoneNumber}} is not a valid phone number. Please enter a valid work phone number.', {
          phoneNumber: inputPhone,
        }),
      };
    }
    return {
      title: changeCouldNotBeApplied,
      message: t("Work phone number can't be blank. Please enter a valid work phone number."),
    };
  }
  if (errorFields.has('cellPhone')) {
    const inputPhone = contact ? contact.cell_phone : null;
    if (inputPhone) {
      return {
        title: changeCouldNotBeApplied,
        message: t('{{phoneNumber}} is not a valid phone number. Please enter a valid cell phone number.', {
          phoneNumber: inputPhone,
        }),
      };
    }
    return {
      title: changeCouldNotBeApplied,
      message: t("Cell phone number can't be blank. Please enter a valid cell phone number."),
    };
  }
  if (errorFields.has('fax')) {
    const inputPhone = contact ? contact.fax : null;
    if (inputPhone) {
      return {
        title: changeCouldNotBeApplied,
        message: t('{{phoneNumber}} is not a valid phone number. Please enter a valid fax number.', {
          phoneNumber: inputPhone,
        }),
      };
    }
    return {
      title: changeCouldNotBeApplied,
      message: t("Fax number can't be blank. Please enter a valid fax number."),
    };
  }
  if (errorFields.has('email')) {
    return { title: changeCouldNotBeApplied, message: t('The email address you entered is not valid.') };
  }
  if (errorFields.has('firstName')) {
    return { title: changeCouldNotBeApplied, message: t('First name is required.') };
  }
  if (errorFields.has('lastName')) {
    return { title: changeCouldNotBeApplied, message: t('Last name is required.') };
  }
  if (errorFields.has('jobTitle')) {
    return { title: changeCouldNotBeApplied, message: t('Job title is required.') };
  }
  if (errorFields.has('ext')) {
    return { title: changeCouldNotBeApplied, message: t('The ext number is not valid.') };
  }
  if (errorFields.has('tireDollarAmount')) {
    return { title: changeCouldNotBeApplied, message: t('Tire Dollar Amount is not valid.') };
  }
  if (errorFields.has('mechanicalDollarAmount')) {
    return { title: changeCouldNotBeApplied, message: t('Mechanical Dollar Amount is not valid.') };
  }
  if (errorFields.has('numOfTires')) {
    return { title: changeCouldNotBeApplied, message: t('The Number Of Tires is not valid.') };
  }
  if (errorFields.has('numOfWheels')) {
    return { title: changeCouldNotBeApplied, message: t('The Number Of Wheels is not valid.') };
  }

  if (contact.preferred_method && contact.preferred_method !== '') {
    switch (contact.preferred_method) {
      default:
        break;
      case 'fax':
        if (!contact.fax || contact.fax === '') {
          return {
            title: changeCouldNotBeApplied,
            message: t('Please enter a fax number or change the preferred communication method.'),
          };
        }
        break;
      case 'text':
        if (!contact.cell_phone || contact.cell_phone === '') {
          return {
            title: changeCouldNotBeApplied,
            message: t('Please enter a cell phone number or change the preferred communication method.'),
          };
        }
        break;
      case 'work':
        if (!contact.work_phone || contact.work_phone === '') {
          return {
            title: changeCouldNotBeApplied,
            message: t('Please enter a work phone number or change the preferred communication method.'),
          };
        }
        break;
      case 'cell':
        if (!contact.cell_phone || contact.cell_phone === '') {
          return {
            title: changeCouldNotBeApplied,
            message: t('Please enter a cell phone number or change the preferred communication method.'),
          };
        }
        break;
      case 'email':
        if (!contact.email_address || contact.email_address === '') {
          return {
            title: changeCouldNotBeApplied,
            message: t('Please enter an email address or change the preferred communication method.'),
          };
        }
        break;
    }
  }

  // Required Fields:
  if (!contact.first_name || contact.first_name === '') {
    return { title: changeCouldNotBeApplied, message: t('First name is required.'), errorField: 'firstName' };
  }
  if (!contact.last_name || contact.last_name === '') {
    return { title: changeCouldNotBeApplied, message: t('Last name is required.'), errorField: 'lastName' };
  }
  if (!contact.job_title || contact.job_title === '') {
    return { title: changeCouldNotBeApplied, message: t('Job title is required.'), errorField: 'jobTitle' };
  }
  if (
    !contact.contact_types ||
    (contact.contact_types && contact.contact_types.length === 0) ||
    (contact.contact_types && !contact.contact_types.some((x) => x.role_areas && x.role_areas.length > 0))
  ) {
    return {
      title: changeCouldNotBeApplied,
      message: t('At least one Role/Area must be selected for a contact type.'),
      errorField: 'contactTypes',
    };
  }
  if (
    contact.contact_types &&
    contact.contact_types &&
    contact.contact_types.length > 0 &&
    contact.contact_types.some((x) => x.role_areas && x.role_areas.length > 0)
  ) {
    const { daysOfTheWeekSelected, startEndTimeSelected } = parseDaysAndLocation(contact);
    if ((daysOfTheWeekSelected && startEndTimeSelected) === false) {
      return {
        title: changeCouldNotBeApplied,
        message: t("The contact's work schedule above must be updated before you can manage Contact Types."),
        errorField: 'contactTypes',
      };
    }
  }

  if (
    (!contact.work_phone || contact!.work_phone === '') &&
    (!contact.cell_phone || contact!.cell_phone === '') &&
    (!contact.email_address || contact.email_address === '')
  ) {
    return { title: changeCouldNotBeApplied, message: t('Please enter a work/cell phone or an email address.') };
  }

  if (!contact.locations || contact.locations.length < 1 || !contact.locations.some((x) => x.is_deleted === false)) {
    return { title: changeCouldNotBeApplied, message: t('Please assign at least one Location.') };
  }

  if (contact.notification_subscription && contact.notification_subscription.length > 0) {
    for (const item of contact.notification_subscription) {
      for (const method of item!.method!) {
        switch (method) {
          default:
            break;
          case 'text':
            if (!contact.cell_phone || contact.cell_phone === '') {
              return {
                title: changeCouldNotBeApplied,
                message:
                  t('You are subscribed to a notification using a contact method that is not defined. ') +
                  t('Please remove the subscription or add a cell phone number.'),
              };
            }
            break;
          case 'email':
            if (!contact.email_address || contact.email_address === '') {
              return {
                title: changeCouldNotBeApplied,
                message:
                  t('You are subscribed to a notification using a contact method that is not defined. ') +
                  t('Please remove the subscription or add an email address.'),
              };
            }
            break;
          case 'call_cell':
            if (!contact.cell_phone || contact.cell_phone === '') {
              return {
                title: changeCouldNotBeApplied,
                message:
                  t('You are subscribed to a notification using a contact method that is not defined. ') +
                  t('Please remove the subscription or add a cell phone number.'),
              };
            }
            break;
        }
      }
    }
  }

  return null;
}

async function checkExistingContact(contact: Contact) {
  if (!auth.apolloClient) return true;
  try {
    const { hash_key } = contact;
    const gsi1_hash_key = createContactHash(contact.first_name, contact.last_name);
    const { cell_phone } = contact;
    const { work_phone } = contact;
    const email = contact.email_address;

    const data = await auth.apolloClient.query({
      query: getExistingContactQuery,
      variables: {
        hash_key,
        gsi1_hash_key,
        gsi1_range_key: '2~',
        cell_phone,
        work_phone,
        email,
      },
    });
    return data.data.getExistingContact !== null;
  } catch (e) {
    console.log(e);
    return true;
  }
}
