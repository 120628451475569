import { michelinOffersMap } from 'components/Contact/ContactDetails/ContactTypes/tables/utils';
import { MichelinOffer } from 'components/Contact/ContactDetails/ContactTypes/utils';
import { GENERAL_LEVEL_VALUE, updateContactContactTypes } from 'components/Contact/ContactDetails/utils';
import { Contact, ContactType, ContactTypeLevels } from 'components/Contact/utils';
import { v4 as uuidv4 } from 'uuid';
import { AuthorizationService, BillingProfileTypes, BillingPurchasingProcedures, MethodOfSignedOrder } from '../query';
import { PurchasingProcValidations } from '../utils';
import { SelectedContactToReassign } from './Tables/poRequiredReassignTable';

export function getMichelinOffer(level: string, service: string, roleArea: string): MichelinOffer | undefined {
  const offer = michelinOffersMap.get(level + service + roleArea);
  if (typeof offer === 'string') {
    return {
      level,
      service,
      roleArea,
      offer,
    };
  }
  return undefined;
}

export function getLocationsFromContactType(contact_type: ContactType): Array<string> {
  const locations = new Array<string>();

  if (contact_type && contact_type.role_areas && contact_type.role_areas[0]) {
    contact_type.role_areas[0].levels.forEach((level) => {
      locations.push(level.location);
    });
  }

  return locations;
}

export function createLevelsForLocations(
  locations: Array<string>,
  location: string,
  level: string,
): Array<ContactTypeLevels> {
  return locations.map((x) => {
    if (x === location) {
      return {
        level,
        location: x,
      };
    }
    return {
      level: GENERAL_LEVEL_VALUE,
      location: x,
    };
  });
}

export function applySelectedToReassignToContact(
  selectedToReassign: Map<string, Array<SelectedContactToReassign>>,
): Map<string, Contact> {
  const contacts: Map<string, Contact> = new Map<string, Contact>();
  const hash_keys = Array.from(selectedToReassign.keys());

  hash_keys.forEach((hashKey) => {
    // Get Contact to reassign
    const contactAssignations = selectedToReassign.get(hashKey);

    if (contactAssignations) {
      contactAssignations.forEach((contactAssignation) => {
        const { level, location, contact: contactToAssign, role_area, service } = contactAssignation;
        let contact = contacts.get(contactToAssign.hash_key);

        if (!contact && contactToAssign) {
          contact = contactToAssign;
        }

        if (contact) {
          const { contact_types } = contact;
          if (contact_types) {
            const contactTypeWithServiceFind = contact_types.find((x) => x.service === service);

            if (contactTypeWithServiceFind) {
              // Service already exist.
              const { role_areas } = contactTypeWithServiceFind;
              if (role_areas) {
                const contactTypeRoleAreaFind = role_areas.find((x) => x.role_area === role_area);
                if (contactTypeRoleAreaFind) {
                  // Role Area exist. Add level to location
                  const { levels } = contactTypeRoleAreaFind;
                  const contactTypeRoleLocationFind = levels.find((x) => x.location === location.hash_key);
                  if (contactTypeRoleLocationFind) {
                    contactTypeRoleLocationFind.level = level;
                  } else {
                    // Location is not assigned to this contact. Wrong state.
                    console.warn(`Contact ${contact.hash_key} does not have the location ${location.hash_key}.`);
                  }
                } else {
                  // Role Area does not exist. Add role area to role areas.
                  const locations = getLocationsFromContactType(contact_types[0]);
                  const levels = createLevelsForLocations(locations, location.hash_key, level);
                  role_areas.push({
                    role_area,
                    levels,
                  });
                }
              } else {
                // No role areas. Wrong state on contact.
                console.warn(`Contact ${contact.hash_key} has no role role areas.`);
              }
            } else {
              // Service does not exist. Create one.
              const locations = getLocationsFromContactType(contact_types[0]);
              const levels = createLevelsForLocations(locations, location.hash_key, level);
              contact_types.push({
                id: uuidv4(),
                role_areas: [
                  {
                    role_area,
                    levels,
                  },
                ],
                service,
              });
            }
          } else {
            // No Contact Types. Wrong State on contact.
            console.warn(`Contact ${contact.hash_key} has no Contact Types.`);
          }

          contacts.set(contactToAssign.hash_key, contact);
        }
      });
    }
  });

  return contacts;
}

export async function saveReassignations(selectedToReassign?: Map<string, SelectedContactToReassign[]>) {
  if (typeof selectedToReassign !== 'undefined') {
    const contacts = applySelectedToReassignToContact(selectedToReassign);
    const contact_hash_keys = Array.from(contacts.keys());
    const updateContactMutationsResults = new Array<Promise<any>>();

    contact_hash_keys.forEach((contactHashKey) => {
      const contactToUpdate = contacts.get(contactHashKey);
      if (typeof contactToUpdate !== 'undefined') {
        const { contact_types, email_address, work_phone, cell_phone } = contactToUpdate;
        if (typeof contact_types !== 'undefined') {
          const mutationResult = updateContactContactTypes(
            contactHashKey,
            contact_types,
            email_address,
            work_phone,
            cell_phone,
          );
          if (typeof mutationResult !== 'undefined') {
            updateContactMutationsResults.push(mutationResult);
          }
        }
      }
    });
    await Promise.all(updateContactMutationsResults);
  }
}

export const checkPurchasingProcedures = (
  purchasing_procedure: BillingPurchasingProcedures | undefined,
  isErs: boolean,
  profileType: string,
  validationErrors: PurchasingProcValidations,
) => {
  let failed = false;

  if (isErs && profileType.toUpperCase() === BillingProfileTypes.onsite.toUpperCase()) {
    return failed;
  }

  if (!isErs && profileType.toUpperCase() === BillingProfileTypes.ers.toUpperCase()) {
    return failed;
  }

  if (purchasing_procedure) {
    if (!isErs) {
      purchasing_procedure.ers_ship_to_hash_key = undefined;
      purchasing_procedure.required_for_ers_event = undefined;
    }

    if (purchasing_procedure.primary_method_to_request_service !== AuthorizationService.break_down_phone_number) {
      purchasing_procedure.pri_phone_number = undefined;
    } else if (!purchasing_procedure.pri_phone_number || purchasing_procedure.pri_phone_number === '') {
      validationErrors.primary_contact_phone = true;
    }

    if (purchasing_procedure.secondary_method_to_request_service !== 'break_down_phone_number') {
      purchasing_procedure.sec_phone_number = undefined;
    } else if (!purchasing_procedure.sec_phone_number || purchasing_procedure.sec_phone_number === '') {
      validationErrors.secondary_contact_phone = true;
      failed = true;
    }

    if (
      !purchasing_procedure.required_authorization_to_begin_service ||
      purchasing_procedure.required_authorization_to_begin_service === AuthorizationService.Select ||
      purchasing_procedure.required_authorization_to_begin_service === ''
    ) {
      validationErrors.required_authorization = true;
      failed = true;
    }

    if (
      !purchasing_procedure.primary_method_to_request_service ||
      purchasing_procedure.primary_method_to_request_service === MethodOfSignedOrder.Select ||
      purchasing_procedure.primary_method_to_request_service === ''
    ) {
      validationErrors.primary_contact_request_authorization = true;
      failed = true;
    }

    if (purchasing_procedure.primary_method_to_request_service === AuthorizationService.fleet_contact) {
      if (
        !purchasing_procedure.pri_ers_authorized_contact_type ||
        (purchasing_procedure.pri_ers_authorized_contact_type &&
          purchasing_procedure.pri_ers_authorized_contact_type.length === 0)
      ) {
        validationErrors.primary_contact_types = true;
        failed = true;
      } else if (!purchasing_procedure.pri_ers_authorized_contact_level) {
        validationErrors.primary_contact_level = true;
        failed = true;
      }
    }

    if (purchasing_procedure.secondary_method_to_request_service === AuthorizationService.fleet_contact) {
      if (
        !purchasing_procedure.sec_ers_authorized_contact_type ||
        (purchasing_procedure.sec_ers_authorized_contact_type &&
          purchasing_procedure.sec_ers_authorized_contact_type.length === 0)
      ) {
        validationErrors.sec_contact_types = true;
        failed = true;
      } else if (!purchasing_procedure.sec_ers_authorized_contact_level) {
        validationErrors.sec_contact_level = true;
        failed = true;
      }
    }

    if (
      !purchasing_procedure.secondary_method_to_request_service ||
      purchasing_procedure.secondary_method_to_request_service === MethodOfSignedOrder.Select ||
      purchasing_procedure.secondary_method_to_request_service === ''
    ) {
      validationErrors.sec_contact_request_authorization = true;
      failed = true;
    }

    if (
      purchasing_procedure.method_to_receive_signed_order &&
      (purchasing_procedure.method_to_receive_signed_order === MethodOfSignedOrder.email ||
        purchasing_procedure.method_to_receive_signed_order === MethodOfSignedOrder.fax) &&
      !purchasing_procedure.signed_order_receiver_contact
    ) {
      validationErrors.method_to_receive_signed_order = true;
      failed = true;
    }

    if (
      purchasing_procedure.method_to_receive_signed_order &&
      purchasing_procedure.method_to_receive_signed_order === MethodOfSignedOrder.mail &&
      !purchasing_procedure.signed_order_receiver_location
    ) {
      validationErrors.method_to_receive_signed_order = true;
      failed = true;
    }

    if (
      purchasing_procedure.method_to_receive_signed_order &&
      purchasing_procedure.method_to_receive_signed_order === MethodOfSignedOrder.other &&
      !purchasing_procedure.signed_order_receiver_other
    ) {
      validationErrors.method_to_receive_signed_order = true;
      failed = true;
    }
  }

  return failed;
};

export const initPurchasingProcedure: (ers: boolean, poRequired: boolean) => BillingPurchasingProcedures = (
  ers: boolean,
  poRequired: boolean,
) => ({
  required_authorization_to_begin_service: poRequired ? 'po' : 'Select',
  primary_method_to_request_service: poRequired ? AuthorizationService.fleet_contact : 'Select',
  secondary_method_to_request_service: 'Select',
  method_to_receive_signed_order: 'Select',
  pri_phone_number: '',
  sec_phone_number: '',
  pri_tire_po_issuers: undefined,
  sec_tire_po_issuers: undefined,
  ers_ship_to_hash_key: undefined,
  required_for_ers_event: ers ? false : undefined,
  signed_order_receiver_other: '',
  pri_ers_authorized_contact_type: poRequired ? ['po_issuer'] : [],
  pri_ers_authorized_contact_level: '',
  sec_ers_authorized_contact_type: [],
  sec_ers_authorized_contact_level: '',
  ers_st_location: {
    customer_name: '',
    customer_city: '',
    customer_state: '',
    parent_company_number: '',
    home_office_number: '',
    bill_to_customer: '',
    ship_to_customer: '',
  },
  receiver_location: {
    customer_name: '',
    customer_city: '',
    customer_state: '',
    parent_company_number: '',
    home_office_number: '',
    bill_to_customer: '',
    ship_to_customer: '',
  },
  receiver_contact: {
    email_address: '',
    fax: '',
  },
});
