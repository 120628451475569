import React from 'react';
import { ArrowBack, Edit, Save, Timelapse } from '@material-ui/icons';
import { Fab, GlobalActions } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { getBaseUrl } from 'prefs-and-service-offers';
import { useHistory } from 'react-router-dom';

interface Props {
  savingFlag: boolean;
  editFlag: boolean;
  modifiedFlag: boolean;
  profileId: string;
  updatePermission: boolean;
  handleSaveClick: () => void;
}

export default function BillingFabs(props: Props): JSX.Element {
  const { savingFlag, editFlag, modifiedFlag, updatePermission, handleSaveClick, profileId } = props;
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <GlobalActions>
      <Fab color="inherit" aria-label={t('Saving')} disabled={!savingFlag}>
        <Timelapse />
      </Fab>
      <Fab
        color={modifiedFlag ? 'primary' : 'inherit'}
        aria-label={t('Save')}
        disabled={savingFlag || !editFlag || updatePermission === false}
        onClick={() => {
          if (modifiedFlag) handleSaveClick();
        }}
      >
        <Save />
      </Fab>
      <Fab
        color="primary"
        aria-label={t('Edit')}
        disabled={savingFlag !== false || editFlag || !updatePermission}
        onClick={() => {
          history.push(`${getBaseUrl()}/billing/${profileId}/edit`);
        }}
      >
        <Edit />
      </Fab>
      <Fab
        color="primary"
        label={t('Back')}
        onClick={() => {
          const path = editFlag && profileId ? `/billing/${profileId}` : '/billings';
          history.push(`${getBaseUrl()}${path}`);
        }}
      >
        <ArrowBack />
      </Fab>
    </GlobalActions>
  );
}
