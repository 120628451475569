import React, { useEffect, useMemo, useState } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { Add, Delete as DeleteIcon } from '@material-ui/icons';
import {
  Fab,
  FilterGroup,
  GlobalActions,
  IconButton,
  Panel,
  useDialogs,
  useListSelectFilter,
} from '@michelin/acid-components';
import {
  clearMaestroDataSourceCache,
  isFeatureEnabled,
  useMaestroDataSource,
  usePermissions,
  useSnackbar,
  useTranslation,
} from '@michelin/central-provider';
import { BillingProfileOVM } from '@michelin/fcp-view-models/dist/models/billing';
import { getOptions } from '@michelin/select-options-provider';
import { auth } from 'Auth';
import { fixApprovals } from 'components/Billing/Preferences/Approvals';
import {
  BillingPreferences,
  BillingPreferencesInput,
  BillingProfileTypes,
  GET_BILLING_LOCATIONS,
  GET_BILLING_PREFERENCES,
  UPDATE_BILLING_PROFILE,
} from 'components/Billing/Preferences/query';
import { tableQuery } from 'components/Billing/UpdateLink/profileQuery';
import { PlainMessage } from 'components/Messages';
import { SpeedDial } from 'components/SpeedDial';
import { profileList } from 'components/Types/APITypes';
import { CONTENT_AREA_HEIGHT } from 'components/Util';
import { DataGrid, Template } from 'devextreme-react';
import { Column, Scrolling, SearchPanel } from 'devextreme-react/data-grid';
import _ from 'lodash';
import { SelectedServiceOffer, ServiceOfferIds, getBaseUrl, getSelectedServiceOffer } from 'prefs-and-service-offers';
import { useHistory } from 'react-router-dom';
import { getListProfileType } from 'utils';
import { CreatedByRender, LocationsRender, OptionRender, ProfileNameRender } from './Columns';
import { useGetViewActions } from './Components/SpeedDialActions';

export interface BillingProfileProps {
  onCallFlag: boolean;
  setDataAndLoading: Function;
  enableLocation: boolean;
}

export function BillingProfilesTable(props: BillingProfileProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dialogs = useDialogs();
  const { allowsAction, location } = usePermissions();
  const theme = useTheme();
  const history = useHistory();
  const tabletView = useMediaQuery(theme.breakpoints.down(800));
  const cellView = useMediaQuery(theme.breakpoints.down(500));
  const [warningFlag, setWarningFlag] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState<BillingPreferences | null>(null);
  const [error, setError] = useState(false);
  const [creatorFilterValue, setCreatorFilterValue] = useState('all');
  const [profileFilterValue, setProfileFilterValue] = useState('all');
  const [serviceFilterValue, setServiceFilterValue] = useState('all');
  const profileType = getListProfileType();
  const locSelectedServiceOffer: SelectedServiceOffer | undefined = getSelectedServiceOffer() || undefined;
  const notAllPreferences = locSelectedServiceOffer?.id !== ServiceOfferIds.allPreferences;
  const serviceTypeServiceOffer = notAllPreferences ? profileType : '';

  const creatorFilter = useListSelectFilter({
    label: t('Creator'),
    options: getOptions('account_types', 'all', t('Any Level')),
    defaultValue: creatorFilterValue,
    hashParam: 'creator',
  });

  const profileTypeFilter = useListSelectFilter({
    label: t('Profile Type'),
    options: getOptions('profile_types', 'all', t('Any Profile Type')),
    defaultValue: profileFilterValue,
    hashParam: notAllPreferences ? '' : 'profile_type',
  });

  const serviceTypeFilter = useListSelectFilter({
    label: t('Service Type'),
    options: getOptions('service_types', 'all', t('Any Service Type')),
    defaultValue: serviceFilterValue,
    hashParam: 'service_type',
  });

  const filterGroup = useMemo(() => {
    let filter = [];
    if (notAllPreferences) {
      filter = [creatorFilter.filter, serviceTypeFilter.filter];
    } else {
      filter = [creatorFilter.filter, profileTypeFilter.filter, serviceTypeFilter.filter];
    }
    return new FilterGroup([...filter]);
  }, [creatorFilter.filter, profileTypeFilter.filter, serviceTypeFilter.filter, notAllPreferences]);

  useEffect(() => {
    if (!allowsAction('billing.list')) {
      enqueueSnackbar(t('User has no permissions to list billing profiles.'), { variant: 'warning' });
      history.push(getBaseUrl());
    } else if (!allowsAction('billing.read')) {
      enqueueSnackbar(t('User has no permissions to read billing profiles.'), { variant: 'warning' });
      history.push(getBaseUrl());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (creatorFilter.value) {
      setCreatorFilterValue(creatorFilter.value);
    }
  }, [creatorFilter.value]);

  useEffect(() => {
    if (profileTypeFilter.value) {
      setProfileFilterValue(profileTypeFilter.value);
    }
  }, [profileTypeFilter.value]);

  useEffect(() => {
    if (serviceTypeFilter.value) {
      setServiceFilterValue(serviceTypeFilter.value);
    }
  }, [serviceTypeFilter.value]);

  useEffect(() => {
    clearMaestroDataSourceCache('billing-profile-list');
    setCreatorFilterValue('all');
    setProfileFilterValue('all');
    setServiceFilterValue('all');
  }, [locSelectedServiceOffer.id]);

  const ds = useMaestroDataSource({
    API: profileList(location?.customer_number || ''),
    reqOptions: {
      filters: {
        creator: creatorFilter.value === 'all' ? '' : creatorFilter.value?.toLowerCase(),
        profile_type: serviceTypeServiceOffer
          ? serviceTypeServiceOffer
          : profileTypeFilter.value === 'all'
          ? ''
          : profileTypeFilter.value?.toLowerCase(),
        service_type: serviceTypeFilter.value === 'all' ? '' : serviceTypeFilter.value?.toLowerCase(),
      },
    },
    componentPlacements: {
      filtersPlacement: tabletView ? 'outside' : 'embeded',
      searchPlacement: cellView ? 'outside' : 'embeded',
    },
    filterGroup,
    cacheKey: 'billing-profile-list',
  });

  const reloadAfterAction = () => {
    async function reload() {
      clearMaestroDataSourceCache('billing-profile-list');
      await ds.dataSource.reload();
    }
    reload();
  };

  const readPermission = allowsAction('billing.read');
  const deletePermission = allowsAction('billing.delete');

  const renderActionButtons = (
    row: { data: BillingProfileOVM },
    setWarningFlag: Function,
    setSelectedProfile: Function,
  ) => {
    // As Terry pointed out in some ticket/slack message delete permission are from the login account and not
    // from the selected location.
    const canDelete = deletePermission && !!row.data.allowedActions?.delete;
    if (!canDelete) return null;
    return (
      <IconButton
        aria-label="delete"
        style={{ margin: '8px' }}
        onClick={() => {
          setWarningFlag(true);
          setSelectedProfile(row.data);
        }}
        size="small"
      >
        <DeleteIcon fontSize="small" color="primary" />
      </IconButton>
    );
  };

  const handleRemoveConfirmed = async (billingProfile: any, setError: Function) => {
    try {
      if (!auth || !auth.apolloClient) return;

      const { data: profileData } = await auth.apolloClient.query({
        query: GET_BILLING_PREFERENCES,
        variables: { hash_key: `7~${billingProfile.hash_key}`, range_key: 'v0_billing' },
        fetchPolicy: 'no-cache',
      });

      const { data: locationData } = await auth.apolloClient.query({
        query: GET_BILLING_LOCATIONS,
        variables: { profile_id: `7~${billingProfile.hash_key}`, relationship: auth.getRelationship(), slim: true },
        fetchPolicy: 'no-cache',
      });

      const removeExtraFields = (parentObj: any, typePP: string) => {
        if (parentObj[typePP]) {
          delete parentObj[typePP].ers_st_location;
          delete parentObj[typePP].receiver_location;
          delete parentObj[typePP].receiver_contact;
        }
      };

      const oldData: BillingPreferences = profileData.getBillingPrefs;

      const profileInput: BillingPreferencesInput = {
        is_deleted: true,
        general_information: _.omit(oldData.general_information, ['__typename']) as any,
        ers_purchasing_procedures:
          oldData.general_information.profile_type !== BillingProfileTypes.onsite
            ? (_.omit(oldData.ers_purchasing_procedures, ['__typename']) as any)
            : undefined,
        onsite_purchasing_procedures:
          oldData.general_information.profile_type !== BillingProfileTypes.ers
            ? (_.omit(oldData.onsite_purchasing_procedures, ['__typename']) as any)
            : undefined,
        approvals: fixApprovals(oldData.approvals),
        tire_details: oldData.tire_details
          ? (oldData.tire_details.map((x: any) => _.omit(x, ['__typename'])) as any)
          : oldData.tire_details,
        wheel_details: oldData.wheel_details
          ? (oldData.wheel_details.map((x: any) => _.omit(x, ['__typename'])) as any)
          : oldData.wheel_details,
        requested_photos: oldData.requested_photos
          ? _.omit(oldData.requested_photos, ['__typename'])
          : oldData.requested_photos,
        hash_key: `7~${billingProfile.hash_key}`,
        range_key: 'v0_billing',
        owner_key: oldData.owner_key || '',
        owner_relationship: oldData.owner_relationship || '',
        gsi4_hash_key: `7~${auth.getUltimateParent().customerNumber}`,
        gsi4_range_key: `${auth.getRelationship()}`,
      };

      removeExtraFields(profileInput, 'ers_purchasing_procedures');
      removeExtraFields(profileInput, 'onsite_purchasing_procedures');

      if (profileInput.general_information.set_all_locations) {
        // This is used when the user wants to delete a Billing Profile with selected all locations.
        // There is no need to send a locations list.
        await auth.apolloClient.mutate({
          mutation: UPDATE_BILLING_PROFILE,
          variables: {
            profile: profileInput,
            locations: [],
          },
        });
      } else if (locationData) {
        const locations = locationData.getAssignedBillingProfileLocations;
        await auth.apolloClient.mutate({
          mutation: UPDATE_BILLING_PROFILE,
          variables: {
            profile: profileInput,
            locations: locations.map((x: any) => {
              const loc = _.omit(x, ['__typename', 'tableData']);
              if (!loc) return null;
              if (loc.assigned) loc.assigned = false;
              return loc;
            }),
          },
        });
      }
    } catch (e) {
      setError(true);
    }
    reloadAfterAction();
  };

  if (error) {
    return (
      <Panel>
        <PlainMessage
          title={t('Database Error')}
          messages={[
            t('Application could not load billing profile data.'),
            t('Please try again later or contact support if the error persists'),
          ]}
        />
      </Panel>
    );
  }

  return (
    <>
      <DataGrid
        style={{ height: CONTENT_AREA_HEIGHT }}
        noDataText={t('No billing profiles found...')}
        className="freespaced-table"
        dataSource={ds.dataSource}
        allowColumnReordering
        wordWrapEnabled
        remoteOperations
        onToolbarPreparing={ds.onToolbarPreparing}
      >
        <SearchPanel visible highlightSearchText={false} placeholder={t('Search...')} />
        <Scrolling mode="virtual" />
        <Template name="filterFields" render={ds.renderFilterFields} />
        <Template name="resultsCounter" render={ds.renderResultsCounter} />
        <Column
          caption={t('Creator')}
          dataType="string"
          dataField="creator"
          alignment="center"
          allowSorting
          allowSearch={false}
          cellRender={CreatedByRender}
        />
        <Column
          caption={t('Profile Name')}
          dataType="string"
          dataField="profile_name"
          allowSorting
          allowSearch
          cellRender={({ row }) => <ProfileNameRender row={row} readPermission={readPermission} />}
        />
        <Column
          caption={t('Profile Type')}
          dataType="string"
          dataField="profile_type"
          allowSorting
          allowSearch
          cellRender={({ row }) => OptionRender('profile_types', row?.data?.general_information?.profile_type || '')}
        />
        <Column
          caption={t('Service Type')}
          dataType="string"
          dataField="service_type"
          allowSorting
          allowSearch
          cellRender={({ row }) => OptionRender('service_types', row?.data?.general_information?.service_type || '')}
        />
        {props.enableLocation && (
          <Column
            visible={location?.customer_type !== 'ST'}
            caption={t('Locations')}
            allowSorting={false}
            width={150}
            cellRender={LocationsRender}
            dataField="locationAssignments"
            allowSearch={false}
          />
        )}

        <Column
          visible={(row: BillingPreferences) => row.general_information.default}
          allowFiltering={false}
          allowSearch={false}
          cellRender={({ row }) => renderActionButtons(row, setWarningFlag, setSelectedProfile)}
        />
      </DataGrid>
      {warningFlag
        ? dialogs.confirmDialog(
            t('Do you want to delete the profile?'),
            t('Warning'),
            t('Yes'),
            t('No'),
            async () => {
              setWarningFlag(false);
              props.setDataAndLoading({ data: [1], loading: true });
              await handleRemoveConfirmed(selectedProfile, setError);
              setTimeout(async () => {
                if (auth && auth.apolloClient) {
                  const data = await auth.apolloClient.query({
                    query: tableQuery,
                    variables: {
                      relationship: auth.getRelationship(),
                      queryName: 'billingProfileLog',
                      anyRecord: true,
                      dateString: (() => {
                        const a = new Date();
                        a.setMonth(a.getMonth() - 3);
                        return a.toISOString().replace(/t.+$/i, '');
                      })(),
                    },
                    fetchPolicy: 'no-cache',
                  });
                  props.setDataAndLoading({ loading: false, data: data.data });
                }
              }, 2000);
            },
            () => setWarningFlag(false),
          )
        : undefined}
    </>
  );
}

export function BillingProfilesList(props: BillingProfileProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const { allowsAction } = usePermissions();

  const addClick = () => history.push(`${getBaseUrl()}/billing/create`);

  const actions = useGetViewActions();
  return (
    <>
      <BillingProfilesTable
        onCallFlag={props.onCallFlag}
        setDataAndLoading={props.setDataAndLoading}
        enableLocation={props.enableLocation}
      />
      <GlobalActions>
        {allowsAction('billing.create') && !isFeatureEnabled('billing-profile') && (
          <Fab color="primary" label={t('Add')} onClick={addClick}>
            <Add />
          </Fab>
        )}
        {allowsAction('billing.create') && isFeatureEnabled('billing-profile') && actions.length > 0 && (
          <SpeedDial direction="up" actions={actions} />
        )}
      </GlobalActions>
    </>
  );
}
