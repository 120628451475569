import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { ArrowBack, Edit, RestoreFromTrash, Save, StoreMallDirectoryOutlined, Timelapse } from '@material-ui/icons';
import SendIcon from '@material-ui/icons/Send';
import {
  AccountAvatar,
  Container,
  Fab,
  GlobalActions,
  LoadingBounce,
  Panel,
  PreventTransitionPrompt,
  Switch,
  useDialogs,
} from '@michelin/acid-components';
import { usePermissions, useTranslation } from '@michelin/central-provider';
import { UFOVehicleDetailsForm, UFOVehicleDetailsFormProvider } from '@michelin/core-profile-ui-tools';
import { FormHandles } from '@michelin/core-profile-ui-tools/build/components/Form/Form';
import { UFOVehicleDetailsFormData } from '@michelin/core-profile-ui-tools/build/components/UFOVehicleDetailsForm/types';
import { CountryCode, VehicleType } from '@michelin/core-profile-ui-tools/build/types';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { useSnackbar } from 'notistack';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { useHistory, useParams } from 'react-router-dom';
import { getBaseUrl, getHomeUrl } from '../../prefs-and-service-offers';
import { Customer } from '../Location';
import { AssigneeCustomerType, getCustomers } from '../LocationAssignments/queries';
import { PlainMessage } from '../Messages';
import { vehiclesDetailAPIType } from '../Types/APITypes';
import { scrollToDisplayFirstError } from '../Util';
import { useAPICall } from '../Util/useAPICall';
import { VehicleUpdateLink } from './ChangeLog/UpdateLink';
import { ConfirmTransferDialog, ConfirmTransferDialogProps, useConfirmTransferDialog } from './ConfirmTransferDialog';
import { LocationSelectorProvider, useLocationSelector } from './LocationSelectorProvider';
import { VehiclesDetailProps, saveVehicleAPICall } from './Utils';
import {
  useGetCustomAspectRatios,
  useGetCustomDiameters,
  useGetCustomLoads,
  useGetCustomSizes,
  useGetCustomSpeedRatings,
} from './hooks/customTireSize';
import useVehicleActions from './hooks/useVehicleActions';
import {
  useGetMakes,
  useGetModels,
  useGetOptions,
  useGetStates,
  useGetTireSizes,
  useGetYears,
} from './hooks/vehiclesInformation';

export function VehiclesDetail(props: VehiclesDetailProps) {
  const { t } = useTranslation();
  const history = useHistory();
  let { vehicleId } = useParams<{ [key: string]: string }>();
  const ref = useRef<FormHandles>(null);
  const getYears = useGetYears('urban');
  const getMakes = useGetMakes('urban');
  const getModels = useGetModels('urban');
  const getVehicleOptions = useGetOptions('urban');
  const getTireSizes = useGetTireSizes('urban');
  const getStates = useGetStates();
  const getCustomSizes = useGetCustomSizes();
  const getCustomAspectRatios = useGetCustomAspectRatios();
  const getCustomDiameters = useGetCustomDiameters();
  const getCustomLoads = useGetCustomLoads();
  const getCustomSpeedRatings = useGetCustomSpeedRatings();
  const { activate } = useVehicleActions();
  const { allowsAction, location } = usePermissions();
  const { enqueueSnackbar } = useSnackbar();

  const readPermission = allowsAction('vehicles.read');
  const addPermission = allowsAction('vehicles.create');
  const updatePermission = allowsAction('vehicles.update');
  const transferPermission = allowsAction('vehicles.transfer');
  const deletePermission = allowsAction('vehicles.delete');
  const urbanPermission = allowsAction('vehicles.urban');

  const [showConfirmTransferDialog, setShowConfirmTransferDialog] = useState(false);
  const locationSelector = useLocationSelector();
  const confirmTransferDialog = useConfirmTransferDialog();

  vehicleId = props.action !== 'add' ? vehicleId : '';

  if (!location?.is_urban || location?.customer_country.toLowerCase() !== 'us' || !urbanPermission) {
    // Has no permissions manage vehicles
    enqueueSnackbar('User has no permissions to manage vehicles.', { variant: 'warning' });
    history.push(getHomeUrl());
  }

  if (props.action === 'edit' && !updatePermission) {
    // Has no permissions to edit
    enqueueSnackbar(t('User has no permissions to modify vehicles.'), { variant: 'warning' });
    history.push('view');
  } else if (props.action === 'view' && !readPermission) {
    // Has no permissions to read
    enqueueSnackbar(t('User has no permissions to read vehicles.'), { variant: 'warning' });
    history.push(`${getBaseUrl()}/urban-vehicles`);
  } else if (props.action === 'add' && !addPermission) {
    // Has no permissions to create
    enqueueSnackbar(t('User has no permissions to create vehicles.'), { variant: 'warning' });
    history.push(`${getBaseUrl()}/urban-vehicles`);
  }

  // Detail
  const [originalData, setOriginalData] = useState<VehicleType>({} as VehicleType);
  const [assignedLocation, setAssignedLocation] = useState<any>(props.action === 'add' ? location : ({} as Customer));
  const [originalAssignedLocation, setOriginalAssignedLocation] = useState<any>(
    props.action === 'add' ? location : ({} as Customer),
  );
  const [loading, setLoading] = useState<boolean>(props.action !== 'add');
  const [modified, setModified] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const { errorDialog, confirmDialog } = useDialogs();

  const [, setLocationAssignmentError] = useState<boolean>(false);

  // Load Vehicle Data
  const vehiclesDetail: any = useAPICall(vehiclesDetailAPIType(location?.customer_number || '', vehicleId), {
    reloads: 0,
  });

  useEffect(() => {
    async function fetchData() {
      if (vehicleId && !vehiclesDetail.loading && vehiclesDetail.data) {
        if (vehiclesDetail.error) {
          enqueueSnackbar(t(vehiclesDetail.error), { variant: 'error' });
          history.push(`${getBaseUrl()}/urban-vehicles`);
          return;
        }
        const data: VehicleType = vehiclesDetail?.data;
        if (data) {
          data.tire_size_id = data.tire_size_id ?? 'custom';
          data.model_id = data.model_id ?? 'unknown';
        }
        setOriginalData(data);
        setOriginalData(vehiclesDetail.data);
        setLoading(false);
        const custNumber: string = (
          vehiclesDetail.data?.st ||
          vehiclesDetail.data?.bt ||
          vehiclesDetail.data?.ho ||
          vehiclesDetail.data?.pc
        )?.toString();
        if (custNumber === location?.customer_number) {
          setAssignedLocation(location);
          setOriginalAssignedLocation(location);
        } else {
          const cust = await getCustomers([custNumber]);
          const c = cust.pop() || ({} as Customer);
          setAssignedLocation(c);
          setOriginalAssignedLocation(c);
        }
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehiclesDetail]);

  /* ************************** */
  /* ************************** */
  /* Data Input and Validation  */
  /* ************************** */
  /* ************************** */

  function showValidationErrors(
    errors: FieldErrors<UFOVehicleDetailsFormData> = {} as FieldErrors<UFOVehicleDetailsFormData>,
  ) {
    const msg: string[] = [];

    if (errors.year) msg.push(`• ${t('Year is required')}`);
    if (errors.make_id) msg.push(`• ${t('Make is required')}`);
    if (errors.model_id) msg.push(`• ${t('Model is required')}`);
    if (errors.option_id) msg.push(`• ${t('Option is required')}`);
    if (errors.tire_size_id) msg.push(`• ${t('Tire Size is required')}`);
    if (errors.fleet_id_no) msg.push(`• ${t('Vehicle Unit Number is required')}`);
    if (errors.vin) msg.push(`• ${t('VIN is required and should be 17 characters long')}`);
    if (errors.license_plate) msg.push(`• ${t('License Plate is required and should be 7 characters or shorter')}`);
    if (errors.license_state_abbreviation) msg.push(`• ${t('License Plate State is required')}`);
    if (!assignedLocation.customer_number) msg.push(`• ${t('Location Assignment is required')}`);

    if (errors.tire_sw) msg.push(`• ${t('Custom tire size is required')}`);
    if (errors.tire_aspect) msg.push(`• ${t('Custom tire size aspect ratio is required')}`);
    if (errors.tire_rim) msg.push(`• ${t('Custom tire size diameter is required')}`);
    if (errors.load_index) msg.push(`• ${t('Custom tire size load is required')}`);
    if (errors.speed_rating) msg.push(`• ${t('Custom tire size speed rating is required')}`);

    if (msg.length) {
      msg.unshift(t('The following occurred before saving:'), ' ');
      errorDialog(msg, t('Missing required fields'), t('Ok'));
      scrollToDisplayFirstError();
    }
  }

  async function saveVehicle(vehicleData: VehicleType) {
    setSaving(true);
    const valid: boolean = !!assignedLocation.customer_number;
    setLocationAssignmentError(!assignedLocation.customer_number);

    if (valid) {
      const response = await saveVehicleAPICall(
        { ...vehicleData, type: 'urban' },
        assignedLocation.customer_number,
        vehicleId,
      );
      if (!response || response.status !== 200) {
        errorDialog(
          [t(response.body.error) || t('Unhandled error')],
          t('Error while saving vehicle'),
          t('Ok'),
          undefined,
          {
            disableBackdropClick: true,
          },
        );
      } else if (response.body && response.body.error) {
        errorDialog(t(response.body.error), t('Error while saving vehicle'), t('Ok'), undefined, {
          disableBackdropClick: true,
        });
      } else if (response.body.is_archived) {
        activate(vehicleData);
        setModified(false);
        ref.current?.reset();
      } else {
        enqueueSnackbar(t('Successfully saved vehicle !'), { variant: 'success' });
        setModified(false);
        ref.current?.reset();
        if (!vehicleId) {
          // Adding a Vehicle
          confirmDialog(
            t('Do you want to add another vehicle?'),
            t('Successfully saved vehicle !'),
            t('Yes'),
            t('No'),
            async () => {
              const dataCopy = JSON.parse(JSON.stringify(vehicleData));
              dataCopy.fleet_id_no = '';
              dataCopy.vin = '';
              dataCopy.license_plate = '';
              dataCopy.license_state_abbreviation = 'defaultOption';
              dataCopy.color = '';
              await setLoading(true);
              setOriginalData(dataCopy);
              setAssignedLocation(assignedLocation);
              setOriginalAssignedLocation(assignedLocation);
              await setLoading(false);
            },
            () => {
              setOriginalData(vehicleData);
              setOriginalAssignedLocation(assignedLocation);
              history.push(`${getBaseUrl()}/urban-vehicles/${response.body.id}/view`);
            },
            {
              disableBackdropClick: true,
            },
          );
        } else {
          // Modifying a Vehicle
          history.push('view');
        }
      }
    } else {
      showValidationErrors();
    }
    setSaving(false);
  }

  const cancelEdition = async (mode: 'ask' | 'stay' | 'discard') => {
    switch (mode) {
      case 'ask':
        confirmDialog(
          t('Are you sure you want to discard all the changes you have made?'),
          t('Discard Changes'),
          t('Yes'),
          t('No'),
          () => {
            cancelEdition('discard');
          },
          () => {},
        );
        break;
      case 'discard':
        setAssignedLocation(originalAssignedLocation);
        await setModified(false);
        if (ref.current) ref.current.reset();
        if (props.action === 'edit') history.push(`${getBaseUrl()}/urban-vehicles/${vehicleId}/view`);
        else if (props.action === 'view')
          history.push(`${getBaseUrl()}/urban-vehicles#active=${!vehiclesDetail.data.is_archived}`);
        else history.push(`${getBaseUrl()}/urban-vehicles`);
        break;
      default:
        break;
    }
  };

  async function activateVehicle(data: VehicleType) {
    // setReloads(reloads + 1);
    const hasBlankFields: boolean = !data.fleet_id_no || !data.license_plate || !data.license_state_abbreviation;
    if (hasBlankFields) {
      history.push(`${getBaseUrl()}/urban-vehicles/${data.id}/edit`);
    } else activate(data);
  }

  function getHeader() {
    if (props.action === 'add') return t('Add Urban Vehicle');
    if (!vehiclesDetail.data.is_archived) return originalData.fleet_id_no;
    if (vehiclesDetail.data.transfer_to) return `[${t('Transferred').toLocaleUpperCase()}]`;
    if (vehiclesDetail.data.transfer_source) return `[${t('Incoming Transfer').toLocaleUpperCase()}]`;
    return `${originalData.fleet_id_no} [${t('Inactive').toLocaleUpperCase()}]`;
  }

  if (loading) return <LoadingBounce style={{ height: '70vh' }} />;

  if (vehiclesDetail.error) {
    return (
      <Container>
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Grid item xs={12}>
            <PlainMessage
              title={t('API Error')}
              messages={[
                t('Application could not load vehicles data.'),
                t('Please try again later or contact support if the error persists.'),
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <>
      <LocationSelectorProvider />
      <ConfirmTransferDialog
        originalLocation={assignedLocation}
        vehicleId={vehicleId}
        data={undefined}
        openProp={showConfirmTransferDialog}
      />
      <PageTitle title={t('Vehicle Management')} subtitle={getHeader()} rightItems={<VehicleUpdateLink />} />
      <Container>
        <Panel spacing={0}>
          <>
            <Container>
              <UFOVehicleDetailsFormProvider
                countryCode={location?.customer_country.toLowerCase() === 'us' ? CountryCode.US : CountryCode.CA}
                fetchYears={getYears}
                fetchMakes={getMakes}
                fetchModels={getModels}
                fetchOptions={getVehicleOptions}
                fetchTireSizes={getTireSizes}
                fetchStates={getStates}
                fetchCustomTireSizeSizes={getCustomSizes}
                fetchCustomTireSizeAspectRatios={getCustomAspectRatios}
                fetchCustomTireSizeDiameters={getCustomDiameters}
                fetchCustomTireSizeLoads={getCustomLoads}
                fetchCustomTireSizeSpeedRatings={getCustomSpeedRatings}
              >
                <UFOVehicleDetailsForm
                  ref={ref}
                  readOnly={props.action === 'view'}
                  mode={props.action}
                  optionalTireSizes
                  optionalOption
                  onSubmit={async (data) => {
                    const newData = data as VehicleType;
                    newData.vin = data.vin ?? originalData.vin;
                    saveVehicle(newData);
                  }}
                  onDirtyStateChanged={(dirtyState: boolean) => {
                    setModified(modified || dirtyState);
                  }}
                  onValidationFailed={showValidationErrors}
                  defaultValues={{
                    ...originalData,
                    tire_size_id: originalData.tire_size_id || (originalData.custom_tire_size_id ? 'custom' : null),
                    speed_rating: originalData.custom_tire_size_id,
                  }}
                />
              </UFOVehicleDetailsFormProvider>
              <Grid container spacing={2} style={{ marginBottom: 16 }}>
                <Grid item xs={12}>
                  <Panel title={t('Location Assignment')}>
                    <Grid container spacing={2}>
                      <Grid item xs={10} sm={8} md={6} lg={5}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Switch
                            checked={assignedLocation.customer_number === location?.customer_number}
                            label={t('Assign to Current Location')}
                            labelPosition="left"
                            onChange={(event: ChangeEvent<any>) => {
                              const newLocation = event.target.checked
                                ? location || ({} as Customer)
                                : ({} as Customer);
                              setAssignedLocation(newLocation);
                              setLocationAssignmentError(false);
                              const changed =
                                modified || originalAssignedLocation.customer_number !== newLocation.customer_number;
                              setModified(changed);
                            }}
                            readOnly={props.action === 'view'}
                          />
                          &nbsp;
                          <Button
                            onClick={() => {
                              locationSelector.selectLocation(
                                t('Select location assignments'),
                                location,
                                () => {},
                                (loc: AssigneeCustomerType) => {
                                  if (loc) {
                                    setLocationAssignmentError(false);
                                  }
                                  const customerLocation = loc || ({} as Customer);
                                  setAssignedLocation(customerLocation);
                                  const changed =
                                    modified ||
                                    originalAssignedLocation.customer_number !== customerLocation.customer_number;
                                  setModified(changed);
                                },
                              );
                            }}
                            color="primary"
                            disabled={
                              assignedLocation.customer_number === location?.customer_number || props.action === 'view'
                            }
                            style={{ margin: '0 0 0 auto' }}
                          >
                            <StoreMallDirectoryOutlined />
                          </Button>
                        </div>
                      </Grid>
                      <Grid item xs={2} sm={3} md={6} lg={7}>
                        {assignedLocation.customer_type ? (
                          <AccountAvatar
                            accountType={assignedLocation.customer_type}
                            name={assignedLocation.customer_name}
                            accountDisplay="short"
                            addressDisplay="cityState"
                            style={{ cursor: 'pointer' }}
                            address={{
                              city: assignedLocation.customer_city,
                              state: assignedLocation.customer_state,
                            }}
                            accountNumbers={{
                              parentCompany: assignedLocation.parent_company_number,
                              homeOffice: assignedLocation.home_office_number,
                              billTo: assignedLocation.bill_to_customer,
                              shipTo: assignedLocation.ship_to_customer,
                            }}
                          />
                        ) : null}
                      </Grid>
                    </Grid>
                  </Panel>
                </Grid>
              </Grid>
            </Container>
          </>
          <GlobalActions>
            <Fab color="inherit" label={t('Saving')} disabled={!saving}>
              <Timelapse />
            </Fab>
            <Fab
              color={modified ? 'primary' : 'secondary'}
              label={t('Save')}
              onClick={
                modified
                  ? () => {
                      if (ref?.current) ref.current.submit();
                    }
                  : undefined
              }
              disabled={props.action === 'view' || saving}
            >
              <Save />
            </Fab>
            <Fab
              color="primary"
              label={t('Complete Transfer')}
              onClick={() => {
                activateVehicle(vehiclesDetail.data as VehicleType);
              }}
              disabled={
                props.action === 'edit' ||
                !transferPermission ||
                !vehiclesDetail.data.is_archived ||
                !!vehiclesDetail.data.is_transferred ||
                !vehiclesDetail.data.transfer_source
              }
            >
              <SendIcon style={{ transform: 'rotate(180deg)' }} />
            </Fab>
            <Fab
              color="primary"
              label={t('Transfer')}
              onClick={() => {
                locationSelector.selectLocation(
                  t('Select Location to transfer to'),
                  undefined,
                  () => {},
                  (loc: AssigneeCustomerType) => {
                    const customerLocation = loc || ({} as AssigneeCustomerType);
                    setShowConfirmTransferDialog(true);
                    confirmTransferDialog.transferDialog({
                      originalLocation: assignedLocation.customer_number,
                      vehicleId,
                      data: customerLocation,
                      openProp: showConfirmTransferDialog,
                    } as unknown as ConfirmTransferDialogProps);
                  },
                );
              }}
              disabled={
                props.action === 'edit' ||
                !transferPermission ||
                !vehiclesDetail.data.is_archived ||
                !!vehiclesDetail.data.is_transferred
              }
            >
              <SendIcon />
            </Fab>
            <Fab
              color="primary"
              label={t('Edit')}
              onClick={() => {
                history.push(`${getBaseUrl()}/urban-vehicles/${vehicleId}/edit`);
              }}
              disabled={props.action !== 'view' || !!vehiclesDetail.data.is_archived}
            >
              <Edit />
            </Fab>
            <Fab
              color="primary"
              label={t('Activate')}
              onClick={async () => {
                activateVehicle(vehiclesDetail.data as VehicleType);
              }}
              disabled={
                props.action === 'edit' ||
                !deletePermission ||
                !vehiclesDetail.data.is_archived ||
                !!vehiclesDetail.data.is_transferred ||
                !!vehiclesDetail.data.transfer_source ||
                String(vehiclesDetail.data.reason_text).includes('sold_')
              }
            >
              <RestoreFromTrash />
            </Fab>
            <Fab
              color="primary"
              label={props.action === 'view' ? t('Back') : t('Discard')}
              onClick={() => {
                if (!modified) cancelEdition('discard');
                else cancelEdition('ask');
              }}
              disabled={saving}
            >
              <ArrowBack />
            </Fab>
          </GlobalActions>
          <PreventTransitionPrompt when={modified} />
        </Panel>
      </Container>
    </>
  );
}

export default VehiclesDetail;
