import gql from 'graphql-tag';

export const tableQuery = gql`
  query getBillingProfileChangeLog(
    $relationship: String!
    $dateString: String!
    $queryName: String!
    $anyRecord: Boolean
  ) {
    getBillingProfileChangeLog(
      relationship: $relationship
      dateString: $dateString
      queryName: $queryName
      anyRecord: $anyRecord
    ) {
      update_user
      profile_type
      profile_name
      section
      service_type
      update_date
      changes {
        updated_field
        old_value
        new_value
      }
    }
  }
`;

export const tableRequirementsQuery = gql`
  query getBillingRequirementChangeLog($accountNumber: String!, $dateString: String!) {
    getBillingRequirementChangeLog(accountNumber: $accountNumber, dateString: $dateString) {
      update_user
      update_date
      changes {
        updated_field
        old_value
        new_value
      }
    }
  }
`;
