import React from 'react';
import { Grid } from '@material-ui/core';
import { Autocomplete, Panel, Select, Switch } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { TireProfileOVM } from '@michelin/fcp-view-models';
import { getOptions } from '@michelin/select-options-provider';
import { Actions, AxleName } from 'modules/Tires/types';
import { Controller, useFormContext } from 'react-hook-form';
import { getAutocompleteValues } from '../../utils';

interface Props {
  axleName: AxleName;
  action: Actions;
}

const TirePressureSensor = (props: Props) => {
  const { t } = useTranslation();
  const { control, watch, setValue } = useFormContext<TireProfileOVM>();

  // Watch's
  const installed = watch(`axles.${props.axleName}.pressure_sensor.installed`);
  const disposition = watch(`axles.${props.axleName}.pressure_sensor.disposition`);
  const location = watch(`axles.${props.axleName}.pressure_sensor.location`);

  return (
    <Panel
      title={t('Tire Pressure Sensor')}
      control={
        <span style={{ fontSize: '0.75rem' }}>
          <Controller
            name={`axles.${props.axleName}.pressure_sensor.installed`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Switch
                fullWidth
                label={t('Installed')}
                checked={value || false}
                disabled={props.action === 'view'}
                onChange={(e: any) => {
                  onChange(e.target.checked);
                  if (e.target.checked === false) {
                    setValue(`axles.${props.axleName}.pressure_sensor.disposition`, null, { shouldDirty: true });
                    setValue(`axles.${props.axleName}.pressure_sensor.location`, null, { shouldDirty: true });
                    setValue(`axles.${props.axleName}.pressure_sensor.mounting`, null, { shouldDirty: true });
                    setValue(`axles.${props.axleName}.pressure_sensor.types`, [], { shouldDirty: true });
                  }
                }}
              />
            )}
          />
        </span>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          {installed && (
            <Controller
              name={`axles.${props.axleName}.pressure_sensor.disposition`}
              control={control}
              rules={{
                required: t('Tire Sensor Disposition is required').toString(),
              }}
              render={({ fieldState, field: { onChange, value } }) => (
                <Select
                  fullWidth
                  label={t('Tire Sensor Disposition')}
                  value={value ? value : ''}
                  options={getOptions('tire_sensor_disposition')}
                  disabled={props.action === 'view'}
                  onChange={(e: any) => {
                    onChange(e.target.value);
                    if (e.target.checked === false) {
                    setValue(`axles.${props.axleName}.pressure_sensor.location`, null, { shouldDirty: true });
                    setValue(`axles.${props.axleName}.pressure_sensor.mounting`, null, { shouldDirty: true });
                    setValue(`axles.${props.axleName}.pressure_sensor.types`, [], { shouldDirty: true });
                    }
                  }}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          {disposition === 're_install' && (
            <Controller
              name={`axles.${props.axleName}.pressure_sensor.location`}
              control={control}
              rules={{
                required: t('Tire Sensor Location is required').toString(),
              }}
              render={({ fieldState, field: { onChange, value } }) => (
                <Select
                  fullWidth
                  label={t('Sensor Location')}
                  value={value || ''}
                  onChange={(e: any) => {
                    onChange(e.target.value);
                    if (e.target.value === 'inside') {
                      setValue(`axles.${props.axleName}.pressure_sensor.types`, [], { shouldDirty: true });
                    }
                    if (e.target.value === 'outside') {
                      setValue(`axles.${props.axleName}.pressure_sensor.mounting`, null, { shouldDirty: true });
                    }
                  }}
                  options={getOptions('tire_sensor_location')}
                  disabled={props.action === 'view'}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          )}
        </Grid>
        {installed && (location === 'inside' || location === 'both') && (
          <Grid item xs={12} lg={12}>
            <Controller
              name={`axles.${props.axleName}.pressure_sensor.mounting`}
              control={control}
              rules={{
                required: t('Tire Sensor Mounting Type is required').toString(),
              }}
              render={({ fieldState, field: { onChange, value } }) => (
                <Select
                  fullWidth
                  label={t('Mounting Type')}
                  value={value || ''}
                  options={getOptions('tire_sensor_mounting_type')}
                  onChange={(e: any) => onChange(e.target.value)}
                  disabled={props.action === 'view'}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        )}
        {installed && (location === 'outside' || location === 'both') && (
          <Grid item xs={12} lg={12}>
            <Controller
              name={`axles.${props.axleName}.pressure_sensor.types`}
              control={control}
              rules={{
                required: t('Tire Sensor Types is required').toString(),
              }}
              render={({ fieldState, field }) => (
                <Autocomplete
                  id="tire_sensor_type"
                  disableCloseOnSelect
                  label={t('Sensor Types')}
                  options={getOptions('tire_sensor_type')}
                  placeholder={t('Select sensor types')}
                  value={getAutocompleteValues(field.value || [], getOptions('tire_sensor_type'))}
                  disabled={props.action === 'view'}
                  onChange={(e, values) => {
                    field.onChange(values.map((v) => v.value) || []);
                  }}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
    </Panel>
  );
};

export { TirePressureSensor };
