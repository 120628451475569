import React from 'react';
import { Grid } from '@material-ui/core';
import { AccountAvatar, Tooltip } from '@michelin/acid-components';

interface CreatedByAvatarProps {
  customerType: string | null | undefined;
  customerNumber?: string | null;
}

export default function CreatedByAvatar({ customerType, customerNumber }: CreatedByAvatarProps) {
  if (!customerType) return null;

  type CustomerType = 'PC' | 'HO' | 'BT' | 'ST';
  let ct: CustomerType;

  switch (customerType.toLowerCase().replace(' ', '').replace('_', '')) {
    case 'pc':
      ct = 'PC';
      break;
    case 'ho':
      ct = 'HO';
      break;
    case 'bt':
      ct = 'BT';
      break;
    case 'st':
      ct = 'ST';
      break;
    case 'parentcompany':
      ct = 'PC';
      break;
    case 'homeoffice':
      ct = 'HO';
      break;
    case 'billto':
      ct = 'BT';
      break;
    case 'shipto':
      ct = 'ST';
      break;
    default:
      return null;
  }

  const avatar = (
    <div style={{ fontSize: '0', width: '100%' }}>
      <Grid container justify="center" alignItems="center">
        <Grid item>
          <AccountAvatar style={{ marginLeft: '7px' }} size="small" accountType={ct} name="" address="" />
        </Grid>
      </Grid>
    </div>
  );

  if (!customerNumber) return avatar;
  return (
    <Tooltip arrow title={`${ct} ${customerNumber.split('~').pop()}`}>
      {avatar}
    </Tooltip>
  );
}
