import React, { useEffect, useState } from 'react';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { Panel, Select, SelectOption, TextField } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { getOptionLabel, getOptions } from '@michelin/select-options-provider';
import { Controller, useFormContext } from 'react-hook-form';
import { SOPTireProfilesSelectList } from '../../../hooks/useSOP';
import DealerAutocomplete from '../../Autocomplete';
import { Actions } from '../Types';

interface SOPGeneralInfoProps {
  action: Actions;
  tireProfilesList: any;
}

function SOPGeneralInfo({ action, tireProfilesList }: SOPGeneralInfoProps) {
  const { t } = useTranslation();
  const { control, formState, watch } = useFormContext();
  const theme = useTheme();
  const cellView = useMediaQuery(theme.breakpoints.down(500));

  const [tireProfileData, setTireProfileData] = useState<SOPTireProfilesSelectList[]>([]);
  const [tireProfileOptions, setTireProfileOptions] = useState<SelectOption[]>([]);
  const [disabledTireProfiles, setDisabledTireProfiles] = useState<string[]>([]);

  const serviceTypes: SelectOption[] = getOptions('core_profile_sop_service_types');
  const selectedTireProfiles = watch('general_information.tire_profile');

  useEffect(() => {
    if (tireProfilesList) {
      setTireProfileData(tireProfilesList);
      const tireProfiles: SelectOption[] = [];
      const disabledProfiles: string[] = [];
      tireProfilesList.forEach((e: SOPTireProfilesSelectList) => {
        const locationsCount = e.assign_to_all_locations
          ? t('All Locations')
          : t('{{locationCount}} Locations', { locationCount: e.locations.length });
        tireProfiles.push({
          value: e.id,
          // eslint-disable-next-line max-len
          label: `${e.profile_name} (${getOptionLabel('application_types', e.application_type)}, ${getOptionLabel(
            'vehicle_types_onsite',
            e.vehicle_type,
          )}, ${locationsCount})`,
        });
        if (e.disabled) disabledProfiles.push(e.id);
      });
      setTireProfileOptions(tireProfiles);
      setDisabledTireProfiles(disabledProfiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tireProfilesList]);

  useEffect(() => {
    const assignedProfiles = tireProfileData.filter((tireProfile) => selectedTireProfiles.includes(tireProfile.id));
    const locations: any[] = [];
    assignedProfiles.forEach((prof) => {
      prof.locations.forEach((location) => {
        if (!locations.some((l) => l.range_key === location.range_key)) locations.push(location);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTireProfiles]);

  return (
    <Panel title={cellView ? '' : t('General Information')}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} xl={4}>
          <Controller
            name="service_type"
            control={control}
            render={(props: any) => (
              <Select
                fullWidth
                required
                value={props.field.value || ''}
                defaultValue="tires_wheels"
                label={t('Service Type')}
                options={serviceTypes}
                onChange={(e: any) => props.field.onChange(e.target.value)}
                error={!!formState.errors.service_type}
                disabled={action === 'view'}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} xl={4}>
          <Controller
            name="profile_name"
            control={control}
            rules={{ required: true }}
            render={(props: any) => (
              <TextField
                label={t('Profile Name')}
                value={props.field.value || ''}
                onChange={(e: any) => props.field.onChange(e.target.value)}
                error={!!formState.errors.profile_name}
                disabled={action === 'view'}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} xl={4}>
          <Controller
            name="tire_profiles"
            control={control}
            rules={{ required: true }}
            render={(myProps) => (
              <DealerAutocomplete
                id="tire_profiles"
                variant="standard"
                multiple
                disableClearable
                suggestions={tireProfileOptions}
                placeholder={t('Link this SOP Profile to one or more Onsite Tire profiles')}
                label={t('Tire Profile')}
                values={myProps.field.value || []}
                disabledOptions={disabledTireProfiles}
                disableCloseOnSelect
                onChange={(v: any) => myProps.field.onChange(v)}
                error={!!formState.errors.tire_profiles}
                readOnly={action === 'view'}
              />
            )}
          />
        </Grid>
      </Grid>
    </Panel>
  );
}

export default SOPGeneralInfo;
