import { gql } from 'apollo-boost';
import {
  Contact,
  ContactLocationRelationship,
  ContactType,
  ContactTypeLevels,
  ContactTypeRole,
} from 'components/Contact/utils';
import uuid from 'uuid';
import { Customer } from '../../../../Auth';
import { State as ContactDetailsState, DependencyConflict } from '../index';
import { GENERAL_LEVEL_VALUE, PRIMARY_LEVEL_VALUE, SECONDARY_LEVEL_VALUE, TIRE_SERVICE_VALUE } from '../utils';
import { ChangeEvent as SelectorChangeEvent } from './Selector';
import { michelinOffersMap } from './tables/utils';

interface ParsedDayAndLocation {
  locationAssigned: boolean;
  daysOfTheWeekSelected: boolean;
  startEndTimeSelected: boolean;
}

export function parseDaysAndLocation(contact: Contact | null): ParsedDayAndLocation {
  if (contact !== null) {
    const { work_hours, locations } = contact;
    let daysOfTheWeekSelected = false;
    let startEndTimeSelected = false;
    const locationAssigned = !!locations;

    if (work_hours) {
      const { monday, thursday, wednesday, tuesday, friday, saturday, sunday } = work_hours;
      if (monday) {
        daysOfTheWeekSelected = true;
        startEndTimeSelected = !!(monday.begin && monday.end);
        if (daysOfTheWeekSelected && startEndTimeSelected) {
          return { locationAssigned, daysOfTheWeekSelected, startEndTimeSelected };
        }
      }
      if (thursday) {
        daysOfTheWeekSelected = true;
        startEndTimeSelected = !!(thursday.begin && thursday.end);
        if (daysOfTheWeekSelected && startEndTimeSelected) {
          return { locationAssigned, daysOfTheWeekSelected, startEndTimeSelected };
        }
      }
      if (wednesday) {
        daysOfTheWeekSelected = true;
        startEndTimeSelected = !!(wednesday.begin && wednesday.end);
        if (daysOfTheWeekSelected && startEndTimeSelected) {
          return { locationAssigned, daysOfTheWeekSelected, startEndTimeSelected };
        }
      }
      if (tuesday) {
        daysOfTheWeekSelected = true;
        startEndTimeSelected = !!(tuesday.begin && tuesday.end);
        if (daysOfTheWeekSelected && startEndTimeSelected) {
          return { locationAssigned, daysOfTheWeekSelected, startEndTimeSelected };
        }
      }
      if (friday) {
        daysOfTheWeekSelected = true;
        startEndTimeSelected = !!(friday.begin && friday.end);
        if (daysOfTheWeekSelected && startEndTimeSelected) {
          return { locationAssigned, daysOfTheWeekSelected, startEndTimeSelected };
        }
      }
      if (saturday) {
        daysOfTheWeekSelected = true;
        startEndTimeSelected = !!(saturday.begin && saturday.end);
        if (daysOfTheWeekSelected && startEndTimeSelected) {
          return { locationAssigned, daysOfTheWeekSelected, startEndTimeSelected };
        }
      }
      if (sunday) {
        daysOfTheWeekSelected = true;
        startEndTimeSelected = !!(sunday.begin && sunday.end);
        if (daysOfTheWeekSelected && startEndTimeSelected) {
          return { locationAssigned, daysOfTheWeekSelected, startEndTimeSelected };
        }
      } else {
        return { locationAssigned, daysOfTheWeekSelected, startEndTimeSelected };
      }
    }

    return { locationAssigned, daysOfTheWeekSelected, startEndTimeSelected };
  }
  return { locationAssigned: false, daysOfTheWeekSelected: false, startEndTimeSelected: false };
}

export function contactTypeChangeDispatchHandler(
  state: ContactDetailsState,
  action: { type: 'contactTypeChange'; event: SelectorChangeEvent },
): ContactDetailsState {
  const { contact } = state;
  const { event: contactTypeChangeEvent } = action;
  const { id, newValue: newRoleAreasValues, service: newService } = contactTypeChangeEvent;
  const possiblePrimaryDependencyConflictList: Array<DependencyConflict> = [];

  if (contact) {
    const { contact_types, locations } = contact;
    if (contact_types) {
      if (id) {
        const contact_type_index = contact_types.findIndex((x) => x.id === id);

        if (contact_type_index >= 0) {
          const contact_type = contact_types[contact_type_index];
          const { role_areas: oldRoleAreas, service } = contact_type;
          const newRoleAreasSet = new Set<string>();
          const oldRoleAreasSet = new Set<string>();
          const newRoleAreas: Array<ContactTypeRole> = [];
          let priorityLevelConflict: boolean = false;
          let michelinOfferConflict: boolean = false;
          let removedCount = 0;
          const locationsFilters = new Set<string>();

          if (newRoleAreasValues) {
            newRoleAreasValues.forEach((newRoleAreaValue) => {
              newRoleAreasSet.add(newRoleAreaValue);
            });
          }

          if (oldRoleAreas) {
            oldRoleAreas.forEach((oldContactTypeRoleArea) => {
              const { role_area: oldRoleArea, levels: oldLevels } = oldContactTypeRoleArea;
              oldRoleAreasSet.add(oldRoleArea);

              if (!newRoleAreasSet.has(oldRoleArea)) {
                // RoleArea Removed
                removedCount += 1;
                oldLevels.forEach((contactTypeOldLevel) => {
                  const { level: oldLevel, location: oldLocation } = contactTypeOldLevel;
                  if (oldLevel === PRIMARY_LEVEL_VALUE || oldLevel === SECONDARY_LEVEL_VALUE) {
                    // Conflict. If it's michelin offer Reassign. Else warning.
                    priorityLevelConflict = true;
                    locationsFilters.add(oldLocation);
                    if (service) {
                      const michelinOffer = getMichelinOffer(oldLevel, service, oldRoleArea);

                      if (michelinOffer) {
                        michelinOfferConflict = true;
                      } else if (oldLevel === PRIMARY_LEVEL_VALUE) {
                        possiblePrimaryDependencyConflictList.push({
                          service,
                          role_area: oldRoleArea,
                          location_hash_key: oldLocation,
                        });
                      }
                    }
                  }
                });
              } else {
                oldRoleAreasSet.delete(oldRoleArea);
                newRoleAreas.push({
                  role_area: oldRoleArea,
                  levels: oldLevels,
                });
              }

              newRoleAreasSet.delete(oldRoleArea);
            });
          }

          // Everything ok.
          // Add new role areas:
          newRoleAreasSet.forEach((x) => {
            // Add all the locations and levels to the new RoleAreas
            if (locations && locations.length > 0) {
              newRoleAreas.push({
                levels: locations.map((x) => ({
                  level: GENERAL_LEVEL_VALUE,
                  location: x.hash_key.toString(),
                })),
                role_area: x,
              });
            } else {
              newRoleAreas.push({
                levels: [],
                role_area: x,
              });
            }
          });

          if (priorityLevelConflict) {
            const specificRoleArea =
              removedCount === 1 && oldRoleAreasSet.size === 1 ? Array.from(oldRoleAreasSet.values())[0] : undefined;
            return {
              ...state,
              locationsFilters,
              unassignPriorityLevel: {
                open: true,
                onContinue: (): Contact => {
                  contact_type.role_areas = newRoleAreas;
                  return contact;
                },
                onReassign: (): Contact => {
                  contact_type.role_areas = newRoleAreas;
                  return contact;
                },
                // Translations for dialog Content and Title are added in the dialog itself.
                dialogContent:
                  "Do you want to manage {{firstName}} {{lastName}}'s contact type priority level(s) for the impacted locations?",
                dialogTitle:
                  'Changes made to this contact affect contact type priority levels at one or more locations.',
                onlyReassign: michelinOfferConflict,
                possiblePrimaryDependencyConflictList,
                specificRoleArea,
              },
            };
          }
          contact_type.role_areas = newRoleAreas;
          return {
            ...state,
            contact: {
              ...contact,
            },
            modified: true,
          };
        }
        // New Contact Type
        const newLevels: Array<ContactTypeLevels> = locations
          ? locations.map((x) => ({
              location: x.hash_key.toString(),
              level: GENERAL_LEVEL_VALUE,
            }))
          : [];
        const newRoleAreaAdded = newRoleAreasValues && newRoleAreasValues.pop();

        if (newRoleAreaAdded && typeof newRoleAreaAdded === 'string') {
          const newRoleAreas: ContactTypeRole = {
            role_area: newRoleAreaAdded,
            levels: newLevels,
          };
          const newContactType: ContactType = {
            id: uuid.v4(),
            role_areas: [newRoleAreas],
            service: newService,
          };

          if (contact.contact_types) {
            contact.contact_types.push(newContactType);
          }

          return {
            ...state,
            contact: {
              ...contact,
            },
            modified: true,
          };
        }
      }
    } else {
      // Invalid state
    }
  } else {
    // Invalid state
  }
  return state;
}

export function contactLevelUpdateDispatchHandler(
  state: ContactDetailsState,
  contactLevelUpdateNewValue: string,
): ContactDetailsState {
  const { contact } = state;
  if (contact && contact.contact_level !== contactLevelUpdateNewValue) {
    const { contact_types } = contact;
    let priorityLevelConflict: boolean = false;
    let michelinOfferConflict: boolean = false;
    const locationsFilters = new Set<string>();
    const possiblePrimaryDependencyConflictList: Array<DependencyConflict> = [];

    if (contact_types) {
      contact_types.forEach((contactType) => {
        const { role_areas, service } = contactType;
        if (role_areas) {
          role_areas.forEach((contactTypeRoleArea) => {
            const { role_area, levels } = contactTypeRoleArea;
            if (levels) {
              levels.forEach((contactTypeLevel) => {
                const { level, location } = contactTypeLevel;
                if (level === PRIMARY_LEVEL_VALUE || level === SECONDARY_LEVEL_VALUE) {
                  priorityLevelConflict = true;
                  locationsFilters.add(location);
                  if (service) {
                    const michelinOffer = getMichelinOffer(level, service, role_area);

                    if (michelinOffer) {
                      michelinOfferConflict = true;
                    } else if (level === PRIMARY_LEVEL_VALUE) {
                      possiblePrimaryDependencyConflictList.push({
                        service,
                        role_area,
                        location_hash_key: location,
                      });
                    }
                  }
                }
              });
            }
          });
        }
      });
    }

    if (priorityLevelConflict) {
      return {
        ...state,
        locationsFilters,
        unassignPriorityLevel: {
          open: true,
          onContinue: (): Contact => {
            contact.contact_level = contactLevelUpdateNewValue;
            contact.locations = [];
            if (contact.contact_types) {
              contact.contact_types.forEach(
                (x) =>
                  x.role_areas &&
                  x.role_areas.forEach((y) => {
                    y.levels = [];
                  }),
              );
            }
            return {
              ...contact,
              contact_level: contactLevelUpdateNewValue,
              locations: [],
            };
          },
          onReassign: () => {
            contact.contact_level = contactLevelUpdateNewValue;
            contact.locations = [];
            if (contact.contact_types) {
              contact.contact_types.forEach(
                (x) =>
                  x.role_areas &&
                  x.role_areas.forEach((y) => {
                    y.levels = [];
                  }),
              );
            }
            return {
              ...contact,
              contact_level: contactLevelUpdateNewValue,
              locations: [],
            };
          },
          onlyReassign: michelinOfferConflict,
          possiblePrimaryDependencyConflictList,
          // Translations for dialog Content and Title are added in the dialog itself.
          dialogTitle: 'Changes made to this contact affect contact type priority levels at one or more locations.',
          dialogContent:
            "Do you want to manage {{firstName}} {{lastName}}'s contact type priority level(s) for the impacted locations?",
        },
      };
    }

    if (contact.contact_types) {
      contact.contact_types.forEach(
        (x) =>
          x.role_areas &&
          x.role_areas.forEach((y) => {
            y.levels = [];
          }),
      );
    }

    // Contact Level changed. Then relationships are removed.
    return {
      ...state,
      contact: {
        ...contact,
        contact_level: contactLevelUpdateNewValue,
        locations: [],
      },
      modified: true,
    };
  }
  return state;
}

interface ContactLocationRelationshipDifference {
  modifiedRels: Array<ContactLocationRelationship>;
  newRels: Array<ContactLocationRelationship>;
  deletedRels: Array<ContactLocationRelationship>;
}

export function getDifferencesBetweenLocations(
  oldRelationships: Array<ContactLocationRelationship> | undefined,
  newRelationships: Array<ContactLocationRelationship> | undefined,
): ContactLocationRelationshipDifference {
  const modifiedRels = new Array<ContactLocationRelationship>();
  const newRels = new Array<ContactLocationRelationship>();
  const deletedRels = new Array<ContactLocationRelationship>();
  const oldLocationsMap = new Map<String, ContactLocationRelationship>();

  if (oldRelationships) {
    oldRelationships.forEach((x: ContactLocationRelationship) => {
      oldLocationsMap.set(x.hash_key, x);
    });
  }

  if (newRelationships) {
    newRelationships.forEach((x: ContactLocationRelationship) => {
      const alreadyExistedLocation = oldLocationsMap.get(x.hash_key);
      if (alreadyExistedLocation) {
        if (alreadyExistedLocation.is_deleted !== x.is_deleted) {
          // Modified location
          modifiedRels.push(x);
          oldLocationsMap.delete(x.hash_key);
          if (x.is_deleted) {
            deletedRels.push(x);
          } else {
            newRels.push(x);
          }
        } else {
          oldLocationsMap.delete(x.hash_key);
        }
      } else {
        // New Location
        modifiedRels.push(x);
        newRels.push(x);
      }
    });
  }

  // Not deleted key is a Removed Location
  oldLocationsMap.forEach((x: ContactLocationRelationship) => {
    const relX = {
      ...x,
      is_deleted: true,
    };
    modifiedRels.push(relX);
    deletedRels.push(relX);
  });

  return {
    deletedRels,
    modifiedRels,
    newRels,
  };
}

export function locationsUpdateDispatchHandler(
  state: ContactDetailsState,
  relationshipsToSave: Array<ContactLocationRelationship>,
): ContactDetailsState {
  const { contact } = state;

  if (contact) {
    const { contact_types, locations } = contact;
    const { newRels, deletedRels } = getDifferencesBetweenLocations(locations, relationshipsToSave);
    let priorityLevelConflict: boolean = false;
    let michelinOfferConflict: boolean = false;
    const possiblePrimaryDependencyConflictList = new Array<DependencyConflict>();
    const locationsFilters = new Set<string>();
    const deletedRelsSet = new Set<string>(deletedRels.map((x) => x.hash_key.toString()));

    if (contact_types) {
      contact_types.forEach((contactType) => {
        const { role_areas, service } = contactType;
        if (role_areas) {
          role_areas.forEach((contactTypeRoleArea) => {
            const { role_area, levels } = contactTypeRoleArea;
            if (levels) {
              levels.forEach((contactTypeLevel) => {
                const { level, location } = contactTypeLevel;
                if (deletedRelsSet.has(location)) {
                  if (level === PRIMARY_LEVEL_VALUE || level === SECONDARY_LEVEL_VALUE) {
                    locationsFilters.add(location.toString());
                    priorityLevelConflict = true;
                    if (service) {
                      const michelinOffer = getMichelinOffer(level, service, role_area);
                      if (michelinOffer) {
                        michelinOfferConflict = true;
                      } else if (level === PRIMARY_LEVEL_VALUE) {
                        possiblePrimaryDependencyConflictList.push({
                          role_area,
                          location_hash_key: location,
                          service,
                        });
                      }
                    }
                  }
                }
              });
            }
          });
        }
      });
    }

    if (priorityLevelConflict) {
      return {
        ...state,
        locationsFilters,
        unassignPriorityLevel: {
          open: true,
          onContinue: (): Contact => {
            if (contact.contact_types) {
              contact.contact_types.forEach(
                (x) =>
                  x.role_areas &&
                  x.role_areas.forEach((y) => {
                    // Remove levels with unassigned locations
                    const newLevels: Array<ContactTypeLevels> = [];
                    y.levels.forEach((level: ContactTypeLevels) => {
                      if (!deletedRelsSet.has(level.location)) {
                        newLevels.push(level);
                      }
                    });

                    y.levels = newLevels;

                    // Add level to assigned locations
                    newRels.forEach((newRelationship) => {
                      y.levels.push({ location: newRelationship.hash_key.toString(), level: GENERAL_LEVEL_VALUE });
                    });
                  }),
              );
            }
            return {
              ...contact,
              locations: relationshipsToSave,
            };
          },
          onReassign: (): Contact => {
            if (contact.contact_types) {
              contact.contact_types.forEach(
                (x) =>
                  x.role_areas &&
                  x.role_areas.forEach((y) => {
                    // Remove levels with unassigned locations
                    const newLevels: Array<ContactTypeLevels> = [];
                    y.levels.forEach((level: ContactTypeLevels) => {
                      if (!deletedRelsSet.has(level.location)) {
                        newLevels.push(level);
                      }
                    });

                    y.levels = newLevels;
                    // Add level to assigned locations
                    newRels.forEach((newRelationship) => {
                      y.levels.push({ location: newRelationship.hash_key.toString(), level: GENERAL_LEVEL_VALUE });
                    });
                  }),
              );
            }
            return {
              ...contact,
              locations: relationshipsToSave,
            };
          },
          onlyReassign: michelinOfferConflict,
          possiblePrimaryDependencyConflictList,
          // Translations for dialog Content and Title are added in the dialog itself.
          dialogTitle: 'Changes made to this contact affect contact type priority levels at one or more locations.',
          dialogContent:
            "Do you want to manage {{firstName}} {{lastName}}'s contact type priority level(s) for the impacted locations?",
        },
      };
    }
    if (contact.contact_types) {
      contact.contact_types.forEach(
        (x) =>
          x.role_areas &&
          x.role_areas.forEach((y) => {
            // Remove levels with unassigned locations
            const newLevels: Array<ContactTypeLevels> = [];
            y.levels.forEach((level: ContactTypeLevels) => {
              if (!deletedRelsSet.has(level.location)) {
                newLevels.push(level);
              }
            });

            y.levels = newLevels;
            // Add level to assigned locations
            newRels.forEach((newRelationship) => {
              y.levels.push({ location: newRelationship.hash_key.toString(), level: GENERAL_LEVEL_VALUE });
            });
          }),
      );
    }

    return {
      ...state,
      contact: {
        ...contact,
        locations: relationshipsToSave,
      },
      modified: true,
    };
  }
  // TODO: Invalid state
  return state;
}

export function fillContactTypesSet(contact: Contact): Set<string> {
  const contactTypesSet: Set<string> = new Set<string>();

  if (contact.contact_types) {
    contact.contact_types.forEach((contactType) => {
      const { role_areas } = contactType;
      if (role_areas) {
        role_areas.forEach((contactTypeRoleArea) => {
          const { levels, role_area } = contactTypeRoleArea;
          levels.forEach((contactTypeLevel) => {
            const { level, location } = contactTypeLevel;
            contactTypesSet.add(role_area + location + level);
          });
        });
      }
    });
  }

  return contactTypesSet;
}

export const contactRelationshipsQuery = gql`
  query ContactLocationRelationship($locations: [String]!) {
    getContactLocationsRelationships(locations: $locations) {
      hash_key
      range_key
      is_deleted
      contact {
        hash_key
        first_name
        last_name
        contact_types {
          service
          role_areas {
            role_area
            levels {
              location
              level
            }
          }
          id
        }
        cell_phone
        work_phone
        ext
        email_address
        preferred_method
      }
    }
  }
`;

export const contactRelationshipsWithLocationsQuery = gql`
  query ContactLocationRelationship($locations: [String]!) {
    getContactLocationsRelationships(locations: $locations) {
      hash_key
      range_key
      is_deleted
      contact {
        hash_key
        first_name
        last_name
        contact_level
        contact_types {
          service
          role_areas {
            role_area
            levels {
              location
              level
            }
          }
          id
        }
        cell_phone
        work_phone
        ext
        email_address
        preferred_method
        locations {
          hash_key
          is_deleted
          location {
            hash_key
            customer_name
            customer_number
            customer_type
            customer_zip
            customer_state
            customer_city
            customer_addr1
            customer_addr2
            extrnl_cust_id
            parent_company_number
            bill_to_customer
            home_office_number
            ship_to_customer
          }
        }
      }
    }
  }
`;

export function processLocationsContactsWithPriorityLevels(
  contact: Contact,
  locations: Array<ContactLocationRelationship>,
) {
  const { hash_key } = contact;
  const mappedExternalContacts = new Map();

  if (locations) {
    locations.forEach((contactLocationRelationship) => {
      const { contact: externalContact, is_deleted } = contactLocationRelationship;

      if (is_deleted === false && externalContact && externalContact.hash_key !== hash_key) {
        const { contact_types } = externalContact;
        if (contact_types) {
          contact_types.forEach((contactType) => {
            const { role_areas, service } = contactType;

            if (service === TIRE_SERVICE_VALUE) {
              if (role_areas) {
                role_areas.forEach((contactTypeRoleArea) => {
                  const { levels, role_area } = contactTypeRoleArea;
                  levels.forEach((contactTypeLevel) => {
                    const { level, location } = contactTypeLevel;
                    if (level === PRIMARY_LEVEL_VALUE || level === SECONDARY_LEVEL_VALUE) {
                      mappedExternalContacts.set(role_area + location + level, externalContact);
                    }
                  });
                });
              }
            }
          });
        }
      }
    });
  }
  return mappedExternalContacts;
}

// Checks if a contacts is valid to be assigned a Contact Type
export function validToAssign(localContactHashKey: string, contact: Contact): boolean {
  const { locationAssigned, daysOfTheWeekSelected, startEndTimeSelected } = parseDaysAndLocation(contact);

  if (localContactHashKey === contact.hash_key) {
    return false;
  }
  if (locationAssigned && daysOfTheWeekSelected && startEndTimeSelected) {
    return true;
  }
  return false;
}

export function buildContactTypesForNewContact(
  selectedRoleAreas: Array<{ roleArea: string; level: string }>,
  selectedLocation: Customer,
): Array<ContactType> {
  const newRoleAreas: Array<ContactTypeRole> = selectedRoleAreas.map((selectedRoleAreaWithLevel) => {
    const { roleArea, level } = selectedRoleAreaWithLevel;
    const contactTypeRoleArea: ContactTypeRole = {
      role_area: roleArea,
      levels: [
        {
          level,
          location: selectedLocation.hash_key.toString(),
        },
      ],
    };
    return contactTypeRoleArea;
  });
  const contactType: ContactType = {
    id: uuid.v4(),
    service: TIRE_SERVICE_VALUE,
    role_areas: newRoleAreas,
  };

  return [contactType];
}
export interface LocatedMichelinOffer {
  locationHashKey: string;
  michelinOffer: MichelinOffer;
}

export interface MichelinOffer {
  level: string;
  service: string;
  roleArea: string;
  offer: string;
}

export interface ContactTypeToReassign {
  location: Pick<Customer, 'hash_key'>;
  service: string;
  role_area: string;
  level: string;
}

export function getMichelinOffer(level: string, service: string, roleArea: string): MichelinOffer | undefined {
  const offer = michelinOffersMap.get(level + service + roleArea);
  if (typeof offer === 'string') {
    return {
      level,
      service,
      roleArea,
      offer,
    };
  }
  return undefined;
}

export function handleSwap(
  state: ContactDetailsState,
  roleArea: string,
  level: string,
  locationHashKey: string,
): ContactDetailsState {
  const isMichelinOffer = !!getMichelinOffer(level, TIRE_SERVICE_VALUE, roleArea);
  const possiblePrimaryDependencyConflictList: Array<DependencyConflict> = [];

  const { contact } = state;
  if (contact) {
    const { contact_types } = contact;
    if (contact_types) {
      const newContactTypes: ContactType[] = JSON.parse(JSON.stringify(contact_types));
      newContactTypes.forEach((contactType) => {
        if (contactType.role_areas) {
          contactType.role_areas.forEach((contactTypeRoleArea) => {
            const { levels, role_area } = contactTypeRoleArea;
            if (role_area === roleArea) {
              levels.forEach((contactTypeLevel) => {
                const { location } = contactTypeLevel;
                if (location === locationHashKey) {
                  // eslint-disable-next-line no-param-reassign
                  level = GENERAL_LEVEL_VALUE;
                }
              });
            }
          });
        }
      });

      const locationsFilterSet = new Set<string>();
      locationsFilterSet.add(locationHashKey);
      locationsFilterSet.add('immutable');
      return {
        ...state,
        locationsFilters: locationsFilterSet,
        unassignPriorityLevel: {
          open: false,
          onContinue: (): Contact => contact,
          onReassign: (): Contact => {
            contact.contact_types = newContactTypes;
            return contact;
          },
          dialogContent:
            "Do you want to manage {{firstName}} {{lastName}}'s contact type priority level(s) for the impacted locations?",
          // { firstName: contact.first_name, lastName: contact.last_name }),
          dialogTitle: 'Changes made to this contact affect contact type priority levels at one or more locations.',
          onlyReassign: isMichelinOffer,
          specificRoleArea: roleArea,
          possiblePrimaryDependencyConflictList,
        },
        openReassignContactTypesDialog: true,
      };
    }
  }
  return state;
}
