/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Panel, Select, SelectOption, TextField } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { getOptions } from '@michelin/select-options-provider';
import Autocomplete from 'components/Autocomplete';
import {
  BillingPreferences,
  BillingRequestedPhotosTypes,
  BillingServiceTypes,
} from 'components/Billing/Preferences/query';
import { PlainMessage } from 'components/Messages';
import { Control, Controller, useForm } from 'react-hook-form';

/* It can be called from src/components/Billing/Preferences/index.tsx as follows:
    <RequestedPhotosDetail
      setModifiedFlag={setModifiedFlag}
      profile={profile}
      editFlag={editFlag}
      editFunction={setProfile} 
      modifiedFlag={modifiedFlag}
    />
 */
interface PhotoTypesProps {
  control: Control<BillingPreferences, object>;
  profile: BillingPreferences;
  editFlag: boolean;
}

export function PhotoTypes(props: PhotoTypesProps) {
  const { t } = useTranslation();
  const { editFlag, control, profile } = props;
  const [photoTypes, setPhotoTypes] = useState<SelectOption[]>([]);
  useEffect(() => {
    const opts: SelectOption[] = getOptions('photo_types').filter((x) => {
      if (
        profile.general_information.service_type === BillingServiceTypes.MECHANICAL &&
        (x.value === 'asset_before' || x.value === 'asset_after')
      ) {
        return true;
      }
      if (
        profile.general_information.service_type === BillingServiceTypes.TIRE_WHEEL &&
        x.value !== 'asset_before' &&
        x.value !== 'asset_after'
      ) {
        return true;
      }
      return false;
    });
    setPhotoTypes(opts);
  }, [profile.general_information.service_type]);
  const displayTireIdentifierType =
    profile.requested_photos.types &&
    profile.requested_photos.types.includes(BillingRequestedPhotosTypes.TIRE_IDENTIFIER);

  const graphqlErrorTitle = 'Database Error';
  const graphqlFirstErrorMessage = 'Application could not load billing requested photos data.';
  const graphqlSecondErrorMessage = 'Please try again later or contact support if the error persists';

  return (
    <>
      {!profile?.requested_photos ? (
        <PlainMessage title={graphqlErrorTitle} messages={[graphqlFirstErrorMessage, graphqlSecondErrorMessage]} />
      ) : (
        <Grid container spacing={2} style={{ paddingTop: 5 }}>
          <Grid item sm={12} md={displayTireIdentifierType ? 6 : 8}>
            <Controller
              name="requested_photos.types"
              control={control}
              render={(item) => (
                <Autocomplete
                  id={item.field.name}
                  data-testid="requested-photos-test"
                  multiple
                  variant="standard"
                  label={t('Requested Types of Photos')}
                  disableClearable
                  disableCloseOnSelect
                  placeholder=""
                  onChange={(value) => item.field.onChange(value)}
                  suggestions={photoTypes}
                  values={item.field.value}
                  readOnly={!editFlag}
                />
              )}
            />
          </Grid>
          {displayTireIdentifierType ? (
            <Grid item sm={12} md={3}>
              <Controller
                name="requested_photos.identifier_type"
                control={control}
                render={(item) => (
                  <Select
                    id={item.field.name}
                    name="identifier_type"
                    data-testid="identifier-type-test"
                    label={t('Tire Identifier Type')}
                    value={item.field.value}
                    style={{ width: '100%' }}
                    readOnly={!editFlag}
                    options={getOptions('photo_identifier_types')}
                    onChange={(e) => item.field.onChange(e.target.value)}
                  />
                )}
              />
            </Grid>
          ) : null}
          <Grid item sm={12} md={displayTireIdentifierType ? 3 : 4}>
            <Controller
              name="requested_photos.other_photos"
              control={control}
              render={(item) => (
                <TextField
                  id={item.field.name}
                  name="other_photos"
                  value={item.field.value}
                  label={t('Other Photos')}
                  inputProps={{
                    'aria-label': 'other-photos-textfield',
                  }}
                  readOnly={!editFlag}
                  onChange={(e) => item.field.onChange(e.target.value)}
                />
              )}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}

interface RequestedPhotosDetailProps {
  modifiedFlag: boolean;
  profile: BillingPreferences;
  editFunction: Function;
  setModifiedFlag: (modifiedFlag: boolean) => void;
  editFlag: boolean;
}

export function RequestedPhotosDetail(props: RequestedPhotosDetailProps) {
  const { t } = useTranslation();
  const { profile, editFlag, editFunction, modifiedFlag, setModifiedFlag } = props;
  const {
    control,
    reset,
    getValues,
    formState: { isDirty },
  } = useForm<BillingPreferences>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: profile,
  });
  useEffect(() => {
    if (isDirty) {
      setModifiedFlag(true);
      editFunction(getValues());
      reset(getValues());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);
  useEffect(() => {
    if (!modifiedFlag) {
      editFunction(profile);
      reset(profile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiedFlag]);
  const title = t('Requested Photos');

  return (
    <Panel
      title={title}
      subtitle={
        <span style={{ fontSize: '0.8em' }}>
          <Alert severity="warning">
            {t(
              'Michelin does not guarantee the applicable dealer from its independent dealer network will provide these Requested Details.',
            )}
          </Alert>
        </span>
      }
      style={{ marginBottom: 15 }}
    >
      <PhotoTypes control={control} profile={getValues()} editFlag={editFlag} />
    </Panel>
  );
}
