import { APPS } from '@michelin/central-provider';

interface GlobalConfig {
  responseType: string;
  scope: string;
  pollingInterval: number;
  clientID: string;
  graphQlUrl: string;
  externalAPIUrl: string;
  env: 'local' | 'development' | 'testing' | 'staging' | 'production';
  /**
   * This does not bind appId to the APPS enum from central-provider, but maybe it should.
   * At time of writing, this decision was made to leave room for custom app ids outside
   * of central-provider's limitations as this file would optimally not depend on
   * central-provider at all
   */
  appId: string;
}

const DEFAULT_RESPONSE_TYPE: GlobalConfig['responseType'] = 'token id_token';
const DEFAULT_SCOPE: GlobalConfig['scope'] = 'openid email profile';
const DEFAULT_POLLING_INTERVAL: GlobalConfig['pollingInterval'] = 10;
const APP_ID: string = APPS.CORE_PROFILE;

/** Implicit typing wouldn't ensure our defaults fit the constraints of GlobalConfig */
const AUTH_DEFAULTS: Pick<GlobalConfig, 'responseType' | 'scope' | 'pollingInterval' | 'appId'> = {
  responseType: DEFAULT_RESPONSE_TYPE,
  scope: DEFAULT_SCOPE,
  pollingInterval: DEFAULT_POLLING_INTERVAL,
  appId: APP_ID,
};

const localEnv: string = 'dev';
let localClientID: string = 'kWOuv7BZMQKzGZGzDD46tHUSDMx4SHO7';
let localGraphQlUrl: string = 'https://h8wm1jltuk.execute-api.us-east-1.amazonaws.com/main/proxy';

if (localEnv === 'test') {
  localClientID = '4JCZXCrftW6LiM3wEGdFXV3nMAjchS9B';
  localGraphQlUrl = 'https://5qou7eczwfamfakye6424v6nhm.appsync-api.us-east-1.amazonaws.com/graphql';
}

const allConfigs: {
  localhost: GlobalConfig;
  dev: GlobalConfig;
  test: GlobalConfig;
  staging: GlobalConfig;
  prod: GlobalConfig;
} = {
  localhost: {
    ...AUTH_DEFAULTS,
    clientID: localClientID,
    graphQlUrl: localGraphQlUrl,
    externalAPIUrl: 'https://api.core-profile-dev.misp-solutions.com',
    env: 'local',
  },
  dev: {
    ...AUTH_DEFAULTS,
    clientID: 'rQGKim8Y7S3QvGYWxqH4KiedAFeu2mz2',
    graphQlUrl: 'https://h8wm1jltuk.execute-api.us-east-1.amazonaws.com/main/proxy',
    externalAPIUrl: 'https://api.core-profile-dev.misp-solutions.com',
    env: 'development',
  },
  test: {
    ...AUTH_DEFAULTS,
    clientID: '4JCZXCrftW6LiM3wEGdFXV3nMAjchS9B',
    graphQlUrl: 'https://5qou7eczwfamfakye6424v6nhm.appsync-api.us-east-1.amazonaws.com/graphql',
    externalAPIUrl: 'https://api.core-profile-test.misp-solutions.com',
    env: 'testing',
  },
  staging: {
    ...AUTH_DEFAULTS,
    clientID: 'E0xrhvEclB4dXptgsCbijLv1XM90W9JD',
    graphQlUrl: 'https://3b3lx46pxa.execute-api.us-east-1.amazonaws.com/main/proxy',
    externalAPIUrl: 'https://api.core-profile-staging.misp-solutions.com',
    env: 'staging',
  },
  prod: {
    ...AUTH_DEFAULTS,
    clientID: '86VD2wPmhu0pb1cjk5RiokVBs6uOWmx0',
    pollingInterval: 15,
    graphQlUrl: 'https://tmkqhmtavh.execute-api.us-east-1.amazonaws.com/main/proxy',
    externalAPIUrl: 'https://api.core-profile.misp-solutions.com',
    env: 'production',
  },
};

let config: GlobalConfig;
if (window.location.hostname.includes('localhost') || window.location.hostname.includes('.chaos')) {
  config = allConfigs.localhost;
} else if (window.location.hostname.includes('-dev.')) {
  config = allConfigs.dev;
} else if (window.location.hostname.includes('-test.')) {
  config = allConfigs.test;
} else if (window.location.hostname.includes('-staging.')) {
  config = allConfigs.staging;
} else {
  config = allConfigs.prod;
}

const defaultConfig = config;
export default defaultConfig;
