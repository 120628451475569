import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Panel, Select } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { getOptions } from '@michelin/select-options-provider';
import { DataGrid, Template } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import { useFormContext } from 'react-hook-form';
import { DealerProvider, LocationDetailsDataProvider } from '../../types';
import { DealerDialog } from './dealerDialogs';

export type ServiceProviderComponentProps = {
  editFlag: boolean;
  isCommercial: boolean;
  isUrban: boolean;
  state: string;
  city: string;
  updateState: number;
  forceUpdate: () => void;
};

const useStyles = makeStyles((theme: any) => ({
  panel: {
    marginBottom: '20px',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  selectContainer: {
    marginRight: theme.spacing(2),
  },
  textField: {
    width: '100%',
  },
}));

const serviceProvidersFilter = (serviceProviders: DealerProvider[], isCommercial: boolean, isUrban: boolean) => {
  const toReturn = serviceProviders.filter((d) => {
    if (d.flag === 'D') {
      return false;
    }
    if (isCommercial && (d.is_fleet_ordering || d.is_scrap || d.is_mechanical_services)) {
      return d.is_commercial;
    }
    if (isUrban && (d.is_fleet_ordering || d.is_scrap)) {
      return d.is_urban;
    }
    return false;
  });
  return toReturn;
};

export const ServiceProvider: FunctionComponent<ServiceProviderComponentProps> = (props) => {
  const classes = useStyles();
  const { isUrban, editFlag, isCommercial, state, city, updateState, forceUpdate } = props;

  const { getValues, setValue } = useFormContext<LocationDetailsDataProvider>();
  const { serviceProviders } = getValues();
  const { t } = useTranslation();
  const [dialogFlag, setDialogFlag] = useState(false);

  const [myFilterValue, setMyFilterValue] = useState('all');

  const [filteredRows, setFilteredRows] = useState<DealerProvider[]>(
    serviceProviders ? serviceProvidersFilter(serviceProviders, isCommercial, isUrban) : [],
  );

  const button = (
    <span style={{ position: 'relative', top: '4px' }}>
      <Button
        onClick={() => {
          setDialogFlag(true);
        }}
        size="small"
        color="secondary"
        disabled={!editFlag}
      >
        {t('Manage')}
      </Button>
    </span>
  );

  const ResultsCounter = ({
    count,
    label,
  }: {
    count: string | number | null | undefined;
    label: string | null | undefined;
  }) => (
    <span>
      {count} {label || t('Results')}
    </span>
  );

  const onToolbarPreparing = (e: any) => {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        template: 'filterFields',
      },
      {},
    );
    e.toolbarOptions.items.unshift({
      location: 'after',
      template: 'resultsCounter',
      component: e.component,
    });
  };

  useEffect(() => {
    if (serviceProviders) {
      setFilteredRows(serviceProvidersFilter(serviceProviders, isCommercial, isUrban));
    }
  }, [serviceProviders, isCommercial, updateState, isUrban]);

  function nully(str: string | undefined) {
    if (!str) return true;
    return str === 'null';
  }

  function RenderName(dealer: DealerProvider) {
    return (
      <>
        <div>{dealer.customer_dba_name ? dealer.customer_dba_name : dealer.customer_name}</div>
        <div>
          {t('Account ID:')}
          <b>{` ${dealer.customer_type} ${dealer.customer_number}`}</b>
        </div>
        {!nully(dealer.extrnl_cust_id) ? <div>{dealer.extrnl_cust_id}</div> : null}
      </>
    );
  }

  function accountLocationCellValue(dealer: DealerProvider) {
    return dealer.customer_dba_name ? dealer.customer_dba_name : dealer.customer_name;
  }

  function addressCellValue(dealer: DealerProvider) {
    return (
      `${dealer.customer_addr1} ${dealer.customer_addr2} ${dealer.customer_zip} ${dealer.customer_city} ` +
      `\u00A0${dealer.customer_state}`
    )
      .toLowerCase()
      .replace(/ (null )+/g, ' ');
  }

  function RenderAddress(dealer: DealerProvider) {
    return (
      <>
        {!nully(dealer.customer_addr1) ? <div>{dealer.customer_addr1}</div> : null}
        {!nully(dealer.customer_addr2) ? <div>{dealer.customer_addr2}</div> : null}
        {`${dealer.customer_city}, ${dealer.customer_state} ${dealer.customer_zip}`}
      </>
    );
  }

  function RenderPreferred(dealer: DealerProvider) {
    return (
      <ul>
        {dealer.is_fleet_ordering ? <li>{isUrban ? t('Fleet Ordering') : t('Tire Orders')}</li> : null}
        {isCommercial && dealer.is_mechanical_services ? <li>{t('Non-ERS Mechanical Services')}</li> : null}
        {dealer.is_scrap ? <li>{t('Scrap')}</li> : null}
      </ul>
    );
  }

  return (
    <>
      <Panel
        className={classes.panel}
        title={isUrban ? t('Preferred Urban Service Providers') : t('Preferred Commercial Service Providers')}
        control={button}
      >
        <DataGrid
          id="dgfilterTable"
          showBorders
          dataSource={filteredRows}
          keyExpr="customer_number"
          onToolbarPreparing={onToolbarPreparing}
          style={{ height: 440 }}
          showColumnHeaders
          scrolling={{ mode: 'virtual' }}
        >
          <Template
            name="filterFields"
            render={() => (
              <Select
                id="option-preferences-select"
                test-id="option-preferences-select"
                value={myFilterValue}
                variant="standard"
                defaultValue="all"
                options={getOptions(
                  isUrban ? 'service_provider_urban_types' : 'service_provider_commercial_types',
                  'all',
                  t('All Preference Type'),
                )}
                onChange={(event) => {
                  const v = event.target.value as string;
                  setFilteredRows(
                    serviceProviders
                      ? serviceProviders.filter((d) => {
                          if (v === 'mechanical_services' && !d.is_mechanical_services) return false;
                          if (v === 'scrap' && !d.is_scrap) return false;
                          if (v === 'fleet_ordering' && !d.is_fleet_ordering) return false;
                          return setFilteredRows(serviceProvidersFilter(serviceProviders, isCommercial, isUrban));
                        })
                      : [],
                  );
                  setMyFilterValue(v);
                }}
              />
            )}
          />
          <Template
            name="resultsCounter"
            render={() => (
              <ResultsCounter
                count={filteredRows.length}
                label={filteredRows.length === 1 ? t('record') : t('records')}
              />
            )}
          />
          <Column
            key="accoundLocationColumn"
            caption={t('Account / Location')}
            cellRender={({ data: d }) => RenderName(d)}
            defaultSortOrder="asc"
            allowFiltering
            allowSorting
            calculateCellValue={accountLocationCellValue}
          />
          <Column
            key="addressColumn"
            caption={t('Address')}
            cellRender={({ data: d }) => RenderAddress(d)}
            allowFiltering
            allowSorting
            calculateCellValue={addressCellValue}
          />
          <Column
            key="preferredColumn"
            caption={t('Services Provided')}
            cellRender={({ data: d }) => RenderPreferred(d)}
            allowFiltering
            allowSorting
          />
        </DataGrid>
        <DealerDialog
          openFlag={dialogFlag}
          controlDialog={setDialogFlag}
          selectedDealers={serviceProviders || []}
          setSelectedDealers={(dealers: DealerProvider[]) => {
            setValue('serviceProviders', dealers, { shouldDirty: true });
            forceUpdate();
          }}
          state={state}
          city={city}
          isUrban={isUrban}
          updateState={updateState}
        />
      </Panel>
    </>
  );
};
