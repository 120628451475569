import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, WithDialogsProps, withDialogs } from '@michelin/acid-components';
import { WithTranslation, withTranslation } from '@michelin/central-provider';
import { SelectOption } from '@michelin/select-options-provider';

interface AutocompleteOption {
  label: string;
  value: string;
  disabled?: boolean;
}

interface AutocompleteProps extends WithDialogsProps, WithTranslation {
  id: string;
  variant?: string;
  multiple?: true | false | undefined;
  disableClearable?: boolean;
  suggestions: SelectOption[];
  values?: string[];
  disabledOptions?: string[];
  placeholder: string;
  label: string;
  readOnly?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  disableCloseOnSelect?: boolean;
  onChange: (value: string[]) => void;
}

interface AutocompleteState {
  options: AutocompleteOption[];
  value: AutocompleteOption[];
  disabled: boolean;
  readOnly: boolean;
}

function parseOptions(options: SelectOption[], disabledOptions: string[] = []): AutocompleteOption[] {
  return options.map((v: any) => {
    const elem: AutocompleteOption = {
      label: v.label,
      value: v.value,
      disabled: disabledOptions.includes(v.value),
    };
    return elem;
  });
}

function parseValues(values: string[], options: AutocompleteOption[]): AutocompleteOption[] {
  return options.filter((opt: AutocompleteOption) => values.includes(opt.value));
}

class DealerAutocomplete extends React.Component<AutocompleteProps, AutocompleteState> {
  constructor(props: AutocompleteProps) {
    super(props);
    const options: AutocompleteOption[] = parseOptions(this.props.suggestions, this.props.disabledOptions);
    const value: AutocompleteOption[] = parseValues(this.props.values || [], options);
    this.state = {
      options,
      value,
      disabled: this.props.disabled || false,
      readOnly: this.props.readOnly || false,
    };
  }

  shouldComponentUpdate(nextProps: AutocompleteProps) {
    const suggestionsChanged = JSON.stringify(this.props.suggestions) !== JSON.stringify(nextProps.suggestions);
    const valuesChanged = JSON.stringify(this.props.values) !== JSON.stringify(nextProps.values);
    const disabledOptionsChanged =
      JSON.stringify(this.props.disabledOptions) !== JSON.stringify(nextProps.disabledOptions);
    const disabledChanged = this.props.readOnly !== nextProps.readOnly;
    const errordChanged = this.props.error !== nextProps.error;
    if (suggestionsChanged || valuesChanged || disabledChanged || disabledOptionsChanged) {
      const options: AutocompleteOption[] = parseOptions(nextProps.suggestions, nextProps.disabledOptions);
      const value: AutocompleteOption[] = parseValues(nextProps.values || [], options);
      this.setState({
        options,
        value,
        disabled: nextProps.disabled || false,
        readOnly: nextProps.readOnly || false,
      });
      this.forceUpdate();
      return true;
    }
    if (errordChanged) this.forceUpdate();
    return false;
  }

  render() {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    return (
      <>
        <Autocomplete
          id={this.props.id}
          multiple
          disableClearable={this.props.disableClearable}
          disabled={this.state.disabled || this.state.readOnly}
          options={this.state.options}
          getOptionLabel={(option: any) => option.label}
          value={this.state.value}
          disableCloseOnSelect={this.props.disableCloseOnSelect}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                size="small"
                label={option.label}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...getTagProps({ index })}
                disabled={option.disabled || this.props.disabled}
                deleteIcon={this.props.readOnly ? <></> : undefined}
              />
            ))
          }
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
                color="primary"
                disabled={option.disabled}
              />
              {option.label}
            </>
          )}
          getOptionDisabled={(option) => !!option.disabled}
          style={{ width: '100%' }}
          onChange={(event: any, newValue: any) => {
            if (this.state.readOnly) return;
            const response: string[] = newValue.map((element: AutocompleteOption) => element.value);
            this.props.onChange(response);
          }}
          renderInput={(params: any) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              variant={this.props.variant || 'standard'}
              label={this.props.label}
              placeholder={this.props.placeholder}
              style={{ width: '100%' }}
              error={this.props.error}
              helperText={this.props.helperText}
            />
          )}
        />
      </>
    );
  }
}

export default withTranslation()(withDialogs(DealerAutocomplete));
