import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { Button, Panel } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { ProductOVM, TireProfileOVM, TireProfilePriorities } from '@michelin/fcp-view-models';
import { SelectOption, getOptions } from '@michelin/select-options-provider';
import { cloneDeep } from 'lodash';
import { AXLES, Actions, AxleName } from 'modules/Tires/types';
import { useFormContext } from 'react-hook-form';
import { doSizesEqual, getAxle, getTheOtherEqualSize } from '../../utils';
import { PriorityTable } from './PriorityTable';

type Props = {
  axleName: AxleName;
  action: Actions;
  products: ProductOVM[];
  selectedTab: number;
  addPriority: () => void;
};

function findAxleIndex(axleName: AxleName): number | undefined {
  const axle = AXLES.find((axle) => axle.name === axleName);
  return axle ? axle.index : undefined;
}

export function TireReplacementPriority({ axleName, action, products, selectedTab, addPriority }: Props) {
  const { getValues } = useFormContext<TireProfileOVM>();
  const axle = getAxle(axleName, getValues());
  const [addWasOpen, setAddWasOpen] = useState(false);
  const tabIndex = findAxleIndex(axleName);
  const { t } = useTranslation();

  if (!axle) return <></>;
  const tireObjectsPriorityOne = axle.tire_replacement_priority
    ?.map((item) => (item.priority === 1 ? item.tire_size : undefined))
    .filter((v) => v !== undefined);
  const equalSizes = getOptions('equal_sizes').map((option: SelectOption) => option.value);
  if (tireObjectsPriorityOne?.length === 0 && selectedTab === tabIndex) {
    if (!addWasOpen) {
      addPriority();
      setAddWasOpen(true);
    }
    return (
      <>
        <Panel
          title={`${t('Tire Replacement Priority')}`}
          spacing={0}
          control={
            <span style={{ display: 'flex', alignContent: 'center' }}>
              <div>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={addPriority ? () => addPriority() : () => {}}
                  disabled={action === 'view'}
                  style={{ padding: '1px 10px', margin: '5px' }}
                >
                  {t('Add Tire Replacement Priority')}
                </Button>
              </div>
            </span>
          }
        />
      </>
    );
  }

  return (
    <>
      {tireObjectsPriorityOne?.map((tireSize, index) => {
        if (!tireSize) return <></>;
        const axleCloned = cloneDeep(axle);
        let axleTireSizeReplaData: { data: TireProfilePriorities[]; first: number; items: number } = {
          data: [],
          first: 0,
          items: 0,
        };
        axleTireSizeReplaData.data =
          axleCloned.tire_replacement_priority?.filter((value) =>
            doSizesEqual(value.tire_size || '', tireSize, equalSizes),
          ) || [];
        axleTireSizeReplaData.first = axleCloned.tire_replacement_priority?.findIndex(
          (tire) => tire.tire_size === tireSize,
        )!;
        axleTireSizeReplaData.items = axleTireSizeReplaData.data.length;
        const otherEqualSize: string | undefined = getTheOtherEqualSize(tireSize, equalSizes);
        const lastPriority = tireObjectsPriorityOne.length === index + 1;
        return (
          <Grid item xs={12} key={`${index}-${tireSize}`}>
            <TireReplacementPriorityDetail
              axleName={axleName}
              action={action}
              tireSize={tireSize}
              otherEqualSize={otherEqualSize}
              axleTireSizeReplaData={axleTireSizeReplaData}
              products={products}
              addPriority={addPriority}
              lastPriority={lastPriority}
            />
          </Grid>
        );
      })}
    </>
  );
}

type PropsDetail = {
  axleName: AxleName;
  tireSize: string;
  action: Actions;
  axleTireSizeReplaData: { data: TireProfilePriorities[]; first: number; items: number };
  otherEqualSize: string | undefined;
  products: ProductOVM[];
  addPriority: () => void;
  lastPriority: boolean;
};

function TireReplacementPriorityDetail({
  axleName,
  tireSize,
  action,
  axleTireSizeReplaData,
  otherEqualSize,
  products,
  addPriority,
  lastPriority,
}: PropsDetail) {
  return (
    <PriorityTable
      axleName={axleName}
      action={action}
      tireSize={tireSize}
      products={products || []}
      axleTireSizeReplaData={axleTireSizeReplaData}
      otherEqualSize={otherEqualSize}
      addPriority={addPriority}
      lastPriority={lastPriority}
    />
  );
}
