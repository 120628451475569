import { useQuery } from '@tanstack/react-query';
import { auth } from '../Auth';
import { CustomerData } from '../AuthUtils';

function useGetLocationByNumber(locationNumber?: string) {
  return useQuery<CustomerData | null>(
    ['customer_data', locationNumber],
    () => {
      if (!locationNumber) return null;
      return auth.getAccountData(locationNumber);
    },
    {
      cacheTime: 1000 * 60 * 10,
      keepPreviousData: true,
      refetchInterval: 10000,
      staleTime: 1000 * 60 * 1,
    },
  );
}

export { useGetLocationByNumber };
