import React, { useEffect, useState } from 'react';
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { Button, LoadingBounce } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { ServiceProviderOVM } from '@michelin/fcp-view-models';
import DataGrid, { Column, Scrolling, SearchPanel } from 'devextreme-react/data-grid';
import { useGetServiceProvidersOptionsList } from 'hooks/useLocations';
import _ from 'lodash';
import { DealerProvider } from 'modules/Locations/types';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'utils/reusableMethods';
import { LocationDetailParams } from '../../../../components/Location/detailsIndex';
import './infiniteScroll.css';

export type DealerDialogProps = {
  openFlag: boolean;
  controlDialog: Function;
  selectedDealers: DealerProvider[];
  setSelectedDealers: Function;
  state: string;
  city: string;
  isUrban: boolean;
  updateState: number;
};

export type DealerMap = {
  [key: string]: DealerProvider;
};

const makeMap = (dealers: DealerProvider[]): DealerMap => {
  const map: DealerMap = {};
  for (let i = 0; i < dealers.length; i++) {
    if (typeof dealers[i].customer_number === 'string') {
      const index: string = dealers[i].customer_number as string;
      map[index] = dealers[i];
    }
  }
  return map;
};

function dealerHasOnlyOneType(d: DealerProvider) {
  let count = 0;
  if (d.is_fleet_ordering) count++;
  if (d.is_scrap) count++;
  if (d.is_mechanical_services) count++;
  return count === 1;
}

export function DealerDialog(props: DealerDialogProps) {
  const { openFlag, controlDialog, state, city, selectedDealers, setSelectedDealers, updateState } = props;
  const isUrban = !!props.isUrban;
  const [searchText, setSearchText] = useState('');
  const [allDealers, setAllDealers] = useState<ServiceProviderOVM[]>([]);
  const { t } = useTranslation();
  const params = useParams<LocationDetailParams>();
  const methods = useForm<{ map: DealerMap; selectedDealers: DealerProvider[] }>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      map: makeMap(selectedDealers || []),
      selectedDealers: selectedDealers || [],
    },
  });
  const isDirty = Object.keys(methods.formState.dirtyFields).length > 0;

  const debounce = useDebounce(2000);

  const orderDealerz = (localMap: DealerMap) => (a: DealerProvider, b: DealerProvider) => {
    const aIndex = a.customer_number as string;
    const bIndex = b.customer_number as string;
    const aExists = localMap[aIndex];
    const bExists = localMap[bIndex];
    if (
      /s|a|u/i.test(aExists ? (aExists.flag ? aExists.flag : '') : '') &&
      !/s|a|u/i.test(bExists ? (bExists.flag ? bExists.flag : '') : '')
    )
      return -4;
    if (
      /s|a|u/i.test(bExists ? (bExists.flag ? bExists.flag : '') : '') &&
      !/s|a|u/i.test(aExists ? (aExists.flag ? aExists.flag : '') : '')
    )
      return 4;
    if (a.customer_city && b.customer_city) {
      if (a.customer_city < b.customer_city) return -3;
      if (a.customer_city > b.customer_city) return 3;
    }
    if (a.customer_state && b.customer_state) {
      if (a.customer_state < b.customer_state) return -2;
      if (a.customer_state > b.customer_state) return 2;
    }
    if (a.customer_name && b.customer_name) {
      if (a.customer_name < b.customer_name) return -1;
      if (a.customer_name > b.customer_name) return 1;
    }
    return 0;
  };

  const serviceProvidersOptionsList = useGetServiceProvidersOptionsList({
    fleetId: params.selectedAccount,
    state,
    city,
    query: searchText,
    isUrban: !!props.isUrban,
    isCommercial: !props.isUrban,
    openFlag: openFlag,
  });

  useEffect(() => {
    if (openFlag) {
      serviceProvidersOptionsList.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openFlag, searchText]);

  useEffect(() => {
    methods.reset({
      map: makeMap(selectedDealers || []),
      selectedDealers: selectedDealers || [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateState]);
  useEffect(() => {
    if (serviceProvidersOptionsList.data && !serviceProvidersOptionsList.isRefetching) {
      const selectedDealers = methods.getValues('selectedDealers');
      const map = methods.getValues('map');
      if (searchText.length <= 1) {
        let customers = serviceProvidersOptionsList.data.data;
        const selectedDealersMap: any = {};
        if (selectedDealers)
          customers = selectedDealers
            .filter((d) =>
              isUrban ? d.is_urban && d.flag?.toLowerCase() !== 'd' : d.is_commercial && d.flag?.toLowerCase() !== 'd',
            )
            .map((d) => {
              const index = d.customer_number as string;
              selectedDealersMap[index] = 1;
              return d;
            })
            .concat(
              customers.filter((c: any) => {
                if (selectedDealersMap[c.customer_number]) return false;
                return true;
              }),
            );

        if (selectedDealers && selectedDealers.length !== 0) {
          customers.sort(orderDealerz(map));
        }
        setAllDealers(customers);
      } else {
        let customers = serviceProvidersOptionsList.data.data;
        if (selectedDealers && selectedDealers.length !== 0) {
          customers.sort(orderDealerz(map));
        }
        setAllDealers(customers);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceProvidersOptionsList.data, serviceProvidersOptionsList.isRefetching]);

  useEffect(() => {
    if (serviceProvidersOptionsList.isError) {
      let customers = methods.getValues('selectedDealers');
      const map = methods.getValues('map');
      if (customers && customers.length !== 0) {
        customers = customers.filter((d) =>
          isUrban ? d.is_urban && d.flag?.toLowerCase() !== 'd' : d.is_commercial && d.flag?.toLowerCase() !== 'd',
        );
        customers.sort(orderDealerz(map));
      }
      setAllDealers(customers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceProvidersOptionsList.isError]);

  const clickPreferred = (
    hash_key: string,
    dealerObject: DealerProvider,
    type: 'is_scrap' | 'is_fleet_ordering' | 'is_mechanical_services',
  ) => (
    <Checkbox
      defaultChecked={methods.getValues().map[hash_key]?.[type] || false}
      onChange={() => {
        const localMap = _.cloneDeep(methods.getValues().map);
        const exists = localMap[hash_key];
        if (exists) {
          if (exists.flag === 'S' || exists.flag === 'D') exists.flag = 'U';
          if (exists[type] && dealerHasOnlyOneType(exists)) {
            if (exists.flag === 'U') {
              exists.flag = 'D';
            } else {
              delete localMap[hash_key];
            }
          }
          exists[type] = !exists[type];
        } else {
          const tempDealer = _.cloneDeep(dealerObject);
          tempDealer.is_scrap = false;
          tempDealer.is_fleet_ordering = false;
          tempDealer.is_mechanical_services = false;
          tempDealer[type] = true;
          tempDealer.flag = 'A';
          localMap[hash_key] = tempDealer;
        }
        methods.setValue('map', localMap, { shouldDirty: true });
        const keys = Object.keys(localMap);
        const newDealers: DealerProvider[] = [];
        keys.forEach((key: string) => {
          newDealers.push(localMap[key]);
        });
        methods.setValue('selectedDealers', newDealers, { shouldDirty: true });
      }}
      color="primary"
    />
  );

  const TitleLink = ({ records }: { records: number }) => (
    <Grid container alignItems="baseline">
      <Grid item xs={12} md={6}>
        {t('Service Providers Selection')}
      </Grid>
      <Grid item xs={12} md={6} style={{ textAlign: 'right', fontSize: '.8em' }}>
        {t('{{recordsCount}} records for selection', { recordsCount: records })}
      </Grid>
    </Grid>
  );
  return (
    <Dialog
      open={openFlag}
      onClose={() => {
        setSearchText('');
        controlDialog(false);
      }}
      aria-labelledby="form-dialog-title"
      maxWidth="xl"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        <TitleLink records={serviceProvidersOptionsList.isFetching ? 0 : allDealers.length} />
      </DialogTitle>
      <DialogContent id="dialog-content" dividers style={{ padding: 0 }}>
        {serviceProvidersOptionsList.isLoading && <LoadingBounce style={{ height: '440px' }} />}
        {!serviceProvidersOptionsList.isLoading && (
          <DataGrid
            elementAttr={{
              id: 'gridContainer',
            }}
            noDataText={
              serviceProvidersOptionsList.isFetching ? t('Loading...') : t('No results found matching search criteria.')
            }
            id="locationList"
            keyExpr="customer_number"
            dataSource={serviceProvidersOptionsList.isFetching ? [] : allDealers}
            paging={{ enabled: false }}
            showBorders
            allowColumnResizing
            columnAutoWidth
            wordWrapEnabled
            onEditorPreparing={(e: any) => {
              if (e.parentType === 'searchPanel') {
                e.editorOptions.onValueChanged = (arg: any) => {
                  debounce(() => {
                    setSearchText(arg.value.toLowerCase());
                  });
                };
              }
            }}
          >
            <SearchPanel visible highlightCaseSensitive={false} highlightSearchText={false} />
            <Scrolling mode="virtual" />
            {isUrban && (
              <Column
                caption={t('Fleet Ordering')}
                width={100}
                alignment="center"
                cellRender={({ row }) => clickPreferred(row.data.customer_number, row.data, 'is_fleet_ordering')}
              />
            )}
            {!isUrban && (
              <Column
                caption={t('Tire Orders')}
                width={100}
                alignment="center"
                cellRender={({ row }) => clickPreferred(row.data.customer_number, row.data, 'is_fleet_ordering')}
              />
            )}
            {!isUrban && (
              <Column
                caption={t('Non-ERS Mechanical Services')}
                width={120}
                alignment="center"
                cellRender={({ row }) => clickPreferred(row.data.customer_number, row.data, 'is_mechanical_services')}
              />
            )}
            <Column
              caption={t('Scrap')}
              width={100}
              alignment="center"
              cellRender={({ row }) => clickPreferred(row.data.customer_number, row.data, 'is_scrap')}
            />
            <Column caption={t('Michelin Account Name')} minWidth={300} dataField="customer_name" />
            <Column
              caption={t('Customer Number')}
              calculateCellValue={(row: DealerProvider) => `ST: ${row.customer_number}`}
              allowSorting
            />
            <Column caption={t('Store Number')} dataField="extrnl_cust_id" />
            <Column
              caption={t('Address')}
              minWidth={250}
              calculateCellValue={(row: DealerProvider) => `${row.customer_addr1} ${row.customer_addr2}`}
              allowSorting
              cellRender={({ row }: { row: { data: DealerProvider } }) => (
                <div style={{ whiteSpace: 'normal' }}>
                  {row.data.customer_addr1}
                  {row.data.customer_addr1 && row.data.customer_addr2 ? <br /> : null}
                  {row.data.customer_addr2 || ''}
                </div>
              )}
            />
            <Column caption={t('City')} dataField="customer_city" />
            <Column caption={t('State / Province')} dataField="customer_state" />
            <Column caption={t('Zip / Postal Code')} dataField="customer_zip" />
          </DataGrid>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!isDirty}
          onClick={() => {
            controlDialog(false);
            setSelectedDealers(methods.getValues('selectedDealers'));
          }}
          color="primary"
        >
          {t('Done')}
        </Button>
        <Button
          onClick={() => {
            setSearchText('');
            controlDialog(false);
            methods.reset();
          }}
          color="primary"
        >
          {t('Cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
