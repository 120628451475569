import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Link, useMediaQuery, useTheme } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { FilterGroup, IconButton, Panel, useDialogs, useListSelectFilter } from '@michelin/acid-components';
import {
  clearMaestroDataSourceCache,
  useMaestroDataSource,
  usePermissions,
  useTranslation,
} from '@michelin/central-provider';
import { ContactListingOVM } from '@michelin/fcp-view-models';
import { LocationOVM } from '@michelin/fcp-view-models/dist/models/location';
import { getOptionLabel, getOptions } from '@michelin/select-options-provider';
import { auth } from 'Auth';
import { gql } from 'apollo-boost';
import { DependencyConflict } from 'components/Contact/ContactDetails';
import ReassignContactTypesDialog, {
  ReassignSelection,
} from 'components/Contact/ContactDetails/ContactTypes/dialogs/ReassignContactTypesDialog';
import { getMichelinOffer } from 'components/Contact/ContactDetails/ContactTypes/utils';
import { mutateExteranContactsReassign, updateContactContactTypes } from 'components/Contact/ContactDetails/utils';
import { upsertRelationshipMutation } from 'components/Contact/ContactList/utils';
import { Contact, contactQuery, getIfDR, updateCustomerContactRAW } from 'components/Contact/utils';
import { Contact as ContactReassign } from 'components/Contact/utils';
import CreatedByAvatar from 'components/CreatedByAvatar';
import { CONTENT_AREA_HEIGHT } from 'components/Util';
import { Template } from 'devextreme-react';
import DataGrid, { Column, SearchPanel } from 'devextreme-react/data-grid';
import GLOBAL_CONFIG from 'global-variables';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { getBaseUrl } from '../../../prefs-and-service-offers';
import { ContactGlobalActions, RenderNameAndAccountInfo } from '../Components/Components';
import { DeactivateContactDialog } from '../Components/DeactivateContactDialog';
import { RenderActionCheckbox, RenderContactTypes, RenderPriorityLevelAndLocations } from '../Components/TableColumns';
import { LocatedMichelinOffer } from '../type';
import {
  GENERAL_LEVEL_VALUE,
  PRIMARY_LEVEL_VALUE,
  SECONDARY_LEVEL_VALUE,
  addCountry,
  deleteNotAuthorizedByHierarchyMessage,
  getHighestLocationType,
  notAuthorizedByHierarchyTitle,
} from '../utils';

interface ContactListProps {
  specificCustomer?: LocationOVM;
  popupMode?: boolean;
  hideActions?: boolean;
  additionalColumns?: Array<JSX.Element>;
}

const baseURL = GLOBAL_CONFIG.externalAPIUrl;

export function ContactsTable({
  specificCustomer,
  popupMode,
  hideActions = false,
  additionalColumns,
}: ContactListProps) {
  /* **************** */
  /* Local Variables */
  /* *************** */
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const permissions = usePermissions();
  const theme = useTheme();
  const cellView = useMediaQuery(theme.breakpoints.down(500));

  const [massiveDelete, setMassiveDelete] = useState(false);
  const [deactivateDialogOpen, setDeactivateDialogOpen] = useState(false);
  const [selectedRowToDeactivate, setSelectedRowToDeactivate] = useState<ContactListingOVM | undefined>(undefined);
  const [selectedContactToReassign, setSelectedContactToReassign] = useState<ContactReassign | undefined>(undefined);
  const [reassignDialogOpen, setReassignDialogOpen] = useState(false);
  const [locatedMichelinOffersToDeactivate, setLocatedMichelinOffersToDeactivate] = useState<
    Array<LocatedMichelinOffer>
  >([]);
  const [primaryOrSecondaryLevelToDeactivate, setPrimaryOrSecondaryLevelToDeactivate] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [secondaryConflicts, setSecondaryConflicts] = useState<Array<DependencyConflict>>([]);
  const [selectedRows, setSelectedRows] = useState<ContactListingOVM[]>([]);
  const { alertDialog, confirmDialog, errorDialog } = useDialogs();

  const [accountTypeValue, setAccountTypeValue] = useState('all');
  const [statesLocationValue, setStatesLocationValue] = useState('all');
  const [contactRoleValue, setContactRoleValue] = useState('all');
  const [allContactRoleValue, setAllContactRoleValue] = useState('all');
  const [tireRelateRoleValue, setTireRelateRoleValue] = useState('all');
  const [mechanicalRelateRoleValue, setMechanicalRelateRoleValue] = useState('all');

  /* *********** */
  /* Permissions */
  /* *********** */
  const listPermission = permissions.allowsAction('contacts.list');
  const readPermission = permissions.allowsAction('contacts.read');
  const createPermission = permissions.allowsAction('contacts.create');
  const deletePermission = permissions.allowsAction('contacts.delete');
  const updatePermission = permissions.allowsAction('contacts.update');
  const overrideDeletePermission = permissions.allowsAction('contacts.override_delete');

  if (!listPermission) {
    enqueueSnackbar(t('User has no permissions to list Contacts.'), {
      variant: 'warning',
    });
    history.push(getBaseUrl());
  } else if (!readPermission) {
    enqueueSnackbar(t('User has no permissions to read Contacts.'), {
      variant: 'warning',
    });
    history.push(getBaseUrl());
  }

  /* ********************** */
  /* Filters and BE calls   */
  /* ********************** */

  const customerData = specificCustomer ? specificCustomer : permissions.location;
  const locationCustomerType = permissions?.location?.customer_type || '';
  const accountType = useMemo(() => {
    return getOptions('location_only_and_sorted_account_types', 'all', t('All Contact Levels')).filter((elem) => {
      const highest = customerData ? getHighestLocationType(customerData) : 'ST';
      if (highest === 'PC') return true;
      if (highest === 'HO' && elem.value !== 'PC') return true;
      if (highest === 'BT' && elem.value !== 'PC' && elem.value !== 'HO') return true;
      return highest === 'ST' && elem.value !== 'PC' && elem.value !== 'HO' && elem.value !== 'BT';
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationCustomerType, t]);

  const accountTypeFilter = useListSelectFilter({
    label: t('Account Type'),
    options: accountType,
    defaultValue: accountTypeValue,
    hashParam: specificCustomer ? '' : 'accountType',
  });
  const statesLocation = useMemo(() => {
    const statesAux = () => {
      let aux = getOptions('us_states', 'all', t('All States/Provinces'));
      aux = [...addCountry(aux, 'US'), ...addCountry(getOptions('ca_provinces'), 'CA')];
      return aux;
    };
    return statesAux();
  }, [t]);
  const stateLocationFilter = useListSelectFilter({
    label: t('State / Province'),
    options: statesLocation,
    defaultValue: statesLocationValue,
    hashParam: specificCustomer ? '' : 'state',
  });

  const contactRoleTypeFilter = useListSelectFilter({
    label: t('Contact Type'),
    options: getOptions('contact_types_services_filter'),
    defaultValue: contactRoleValue,
    hashParam: '',
  });

  const allContactRolesFilter = useListSelectFilter({
    label: t('All Roles'),
    options: getOptions('contact_types', 'all', t('All Contact Types')),
    defaultValue: allContactRoleValue,
    hashParam: '',
  });

  const filterLists = { tires: [], mechanicals: [] };
  const tireRelateRoles = useMemo(() => {
    const list = filterLists.tires.map((item: string) => ({
      label: getOptionLabel('contact_types', item),
      value: item,
    }));
    return list.length > 0
      ? [{ label: t('All Contact Types'), value: 'all', order: 0 }, ...list]
      : getOptions('contact_types', 'all', t('All Contact Types'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const tireRelateRolesFilter = useListSelectFilter({
    label: t('Tire Related Roles'),
    options: tireRelateRoles,
    defaultValue: tireRelateRoleValue,
    hashParam: '',
  });

  const mechanicalRelateRoles = useMemo(() => {
    const list = filterLists.mechanicals.map((item: string) => ({
      label: getOptionLabel('contact_types', item),
      value: item,
    }));
    return list.length > 0
      ? [{ label: t('All Contact Types'), value: 'all', order: 0 }, ...list]
      : getOptions('contact_types', 'all', t('All Contact Types'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mechanicalRelateRolesFilter = useListSelectFilter({
    label: t('Mechanical Related Roles'),
    options: mechanicalRelateRoles,
    defaultValue: mechanicalRelateRoleValue,
    hashParam: '',
  });

  const filterContactGroup = useMemo(() => {
    const filters = [];
    switch (contactRoleTypeFilter.value) {
      case 'tire':
        filters.push(tireRelateRolesFilter.filter);
        break;
      case 'mechanical':
        filters.push(mechanicalRelateRolesFilter.filter);
        break;
      default:
        filters.push(allContactRolesFilter.filter);
        break;
    }
    return new FilterGroup([
      accountTypeFilter.filter,
      stateLocationFilter.filter,
      // contactRoleTypeFilter.filter,
      // ...filters,
      allContactRolesFilter.filter,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accountTypeFilter.filter,
    stateLocationFilter.filter,
    contactRoleTypeFilter.filter,
    allContactRolesFilter.filter,
    tireRelateRolesFilter.filter,
    mechanicalRelateRolesFilter.filter,
  ]);

  let roleToBE: string = 'all';
  switch (contactRoleTypeFilter.value) {
    case 'tire':
      roleToBE = tireRelateRolesFilter.value || 'all';
      break;
    case 'mechanical':
      roleToBE = mechanicalRelateRolesFilter.value || 'all';
      break;
    default:
      roleToBE = allContactRolesFilter.value || 'all';
      break;
  }

  const ds = useMaestroDataSource({
    API: {
      method: 'GET',
      endpoint: `cp-be/contacts/${customerData?.customer_number}`,
      baseURL,
    },
    reqOptions: {
      filters: {
        contact_level: accountTypeFilter.value === 'all' ? '' : accountTypeFilter.value?.toUpperCase(),
        state: stateLocationFilter.value === 'all' ? '' : stateLocationFilter.value?.toUpperCase(),
        service: contactRoleTypeFilter.value === 'all' ? '' : contactRoleTypeFilter.value?.toLowerCase(),
        role: roleToBE === 'all' ? '' : roleToBE,
        onlyForCustomer: specificCustomer ? true : false,
      },
    },
    componentPlacements: {
      searchPlacement: cellView ? 'outside' : 'embeded',
    },
    filterGroup: filterContactGroup,
    cacheKey: `contacts-${customerData?.customer_number || ''}`,
  });

  /* ************* */
  /* Functions     */
  /* ************ */

  const reloadAfterAction = () => {
    async function reload() {
      clearMaestroDataSourceCache(`contacts-${customerData?.customer_number || ''}`);
      await ds.dataSource.reload();
    }
    reload();
  };

  function deleteContact(row: ContactListingOVM) {
    const { contact_types } = row;
    const locatedMichelinOffers = new Array<LocatedMichelinOffer>();
    let primaryOrSecondaryLevel = false;
    if (typeof contact_types !== 'undefined') {
      contact_types.forEach((contact_type) => {
        const { role_areas, service } = contact_type;
        if (role_areas && service) {
          role_areas.forEach((contactType_role_area) => {
            const { role_area, levels } = contactType_role_area;
            if (levels) {
              levels.forEach((contactType_level) => {
                const { level, location } = contactType_level;
                const michelinOffer = getMichelinOffer(level || '', service, role_area || '');
                if (typeof michelinOffer !== 'undefined') {
                  locatedMichelinOffers.push({
                    michelinOffer,
                    locationHashKey: location || '',
                  });
                } else if (level === PRIMARY_LEVEL_VALUE || level === SECONDARY_LEVEL_VALUE) {
                  primaryOrSecondaryLevel = true;
                }
              });
            }
          });
        }
      });
    }
    setSelectedRowToDeactivate(row);
    setDeactivateDialogOpen(true);
    setLocatedMichelinOffersToDeactivate(locatedMichelinOffers);
    setPrimaryOrSecondaryLevelToDeactivate(primaryOrSecondaryLevel);
  }

  async function deactivateContactDialogHandler(
    deactivate: boolean,
    reassign: boolean,
    secondaryConflicts?: Array<DependencyConflict>,
  ) {
    if (deactivate === true && reassign === true) {
      setLoading(true);
      setDeactivateDialogOpen(false);

      if (auth.apolloClient && selectedRowToDeactivate) {
        // Query Contact and Locations Data
        const data = await auth.apolloClient.query({
          query: contactQuery,
          variables: { hash_key: `2~${selectedRowToDeactivate.id}`, range_key: 'v0_contact' },
          fetchPolicy: 'no-cache',
        });

        const queriedContact: ContactReassign = data.data.getContactByKey;

        setSecondaryConflicts(secondaryConflicts || []);
        setSelectedContactToReassign(queriedContact);
        setReassignDialogOpen(true);
        setLoading(false);
        return;
      }
      setLoading(false);
    } else if (deactivate === true && reassign === false) {
      const client = auth.apolloClient;
      let stateChanged = false;
      if (client !== null) {
        const contactToDeactivate = selectedRowToDeactivate;
        if (typeof contactToDeactivate !== 'undefined') {
          const { id, locations } = contactToDeactivate;
          setLoading(true);
          if (locations) {
            let n = 0;
            const mutations: Array<Array<string>> = [];
            let tempMutations: Array<string> = [];

            // Build mutations
            locations.forEach((location) => {
              n += 1;
              const mutation = `m${n}: ${upsertRelationshipMutation(
                location?.hash_key?.toString() || '',
                `2~${id}` || '',
                true,
                location?.gsi2_hash_key?.toString() || '',
                location?.gsi2_range_key?.toString() || '',
              )}`;
              if (n === 999) {
                mutations.push([...tempMutations]);
                tempMutations = [mutation];
              } else {
                tempMutations.push(mutation);
              }
            });
            mutations.push([...tempMutations]);
            // Run mutations:
            const contactsRelationshipResolvedPromises: Array<Promise<any>> = [];
            const rows: Array<Contact> = [];
            mutations.forEach((tempMutation) => {
              // eslint-disable-next-line no-async-promise-executor
              const mutationPromise = new Promise(async (res, rej) => {
                try {
                  if (tempMutation && tempMutation.length > 0) {
                    const fullMutation = gql`mutation Mutation{${tempMutation}}`;
                    const mutationResults = await client.mutate({
                      mutation: fullMutation,
                    });
                    const { data } = mutationResults;
                    if (data) {
                      Object.keys(data).forEach((key: string) => {
                        rows.push(data[key]);
                      });
                    }
                  }
                  res([]);
                } catch (e) {
                  rej(e);
                }
              });
              contactsRelationshipResolvedPromises.push(mutationPromise);
            });
            await Promise.all(contactsRelationshipResolvedPromises);
          }

          const contactIsDeletedMutation = client.mutate({
            mutation: gql`
            mutation Mutation($hash_key: String!, $input: UpdateContactInput!) {
              ${updateCustomerContactRAW}
            }`,
            variables: {
              hash_key: `2~${contactToDeactivate.id}`,
              input: {
                updateContactFields: {
                  is_deleted: true,
                  cell_phone: contactToDeactivate.cell_phone,
                  work_phone: contactToDeactivate.work_phone,
                  email_address: contactToDeactivate.email_address,
                },
              },
            },
          });
          await contactIsDeletedMutation;

          setLoading(false);
          setSelectedRowToDeactivate(undefined);
          setDeactivateDialogOpen(false);
          reloadAfterAction();
          stateChanged = true;
        }
      }
      if (!stateChanged) {
        setSelectedRowToDeactivate(undefined);
        setDeactivateDialogOpen(false);
        setLoading(false);
      }
    } else {
      setSelectedRowToDeactivate(undefined);
      setDeactivateDialogOpen(false);
      setLoading(false);
    }
  }

  async function reassignSelectedDialogHandler(
    save: boolean,
    contactSelectedReassignRoleAreaLocation: Map<string, ReassignSelection>,
    unassignLocalRoleLocationComboSet: Set<string>,
  ) {
    if (save) {
      setReassignDialogOpen(false);
      setLoading(true);
      // Reassign
      await mutateExteranContactsReassign(contactSelectedReassignRoleAreaLocation);

      // Clean contact
      if (selectedContactToReassign) {
        const { hash_key, contact_types, email_address, work_phone, cell_phone } = selectedContactToReassign;

        if (contact_types) {
          contact_types.forEach((contactType) => {
            const { role_areas } = contactType;
            if (role_areas) {
              role_areas.forEach((contactTypeRoleArea) => {
                const { levels, role_area } = contactTypeRoleArea;
                levels.forEach((contactTypeLevel) => {
                  const { location } = contactTypeLevel;
                  if (unassignLocalRoleLocationComboSet.has(role_area + location)) {
                    contactTypeLevel.level = GENERAL_LEVEL_VALUE;
                  }
                });
              });
            }
          });
        }

        if (contact_types) {
          await updateContactContactTypes(hash_key, contact_types, email_address, work_phone, cell_phone);
        }
      }
      setSelectedContactToReassign(undefined);
      deactivateContactDialogHandler(true, false);
    } else {
      setSelectedRowToDeactivate(undefined);
      setReassignDialogOpen(false);
    }
  }

  function createContact() {
    history.push(`${getBaseUrl()}/contacts/create`);
  }

  /* *********************** */
  /* Render Columns Methods  */
  /* *********************** */

  function RenderDeleteButton(row: ContactListingOVM, deletePermission: boolean): JSX.Element {
    const canDelete = deletePermission && !!row.allowedActions?.delete;
    if (!canDelete) return <></>;
    return (
      <div>
        {deletePermission && canDelete ? (
          <IconButton
            aria-label="delete"
            style={{ margin: '8px' }}
            disabled={loading}
            onClick={async () => {
              // check to see it's not important bro
              if (auth && auth.apolloClient) {
                const data = await auth.apolloClient.query({
                  query: getIfDR,
                  variables: { hash_key: `PURCHASING_CONTACT~2~${row.id}`, range_key: '7', index: 'gsi4' },
                  fetchPolicy: 'no-cache',
                });
                const response = JSON.parse(data.data.getEntityPaginated.items);
                if (response.length === 0) {
                  if (
                    row.locations &&
                    row.locations.some((x) => !auth.isLocationAllowed(x?.hash_key?.toString() || ''))
                  ) {
                    alertDialog(deleteNotAuthorizedByHierarchyMessage, notAuthorizedByHierarchyTitle, 'OK');
                  } else {
                    confirmDialog(
                      t('Confirm deletion of {{firstName}} {{lastName}} from {{locations}} locations?', {
                        firstName: row.first_name,
                        lastName: row.last_name,
                        locations: row.locations ? row.locations.length : '-',
                      }),
                      t("You're about to delete a contact."),
                      t('Continue'),
                      t('Cancel'),
                      () => {
                        deleteContact(row);
                      },
                      undefined,
                      undefined,
                    );
                  }
                } else {
                  const map: any = {};
                  errorDialog(
                    <Grid container>
                      <Grid item md={12}>
                        {t(
                          'This contact is assigned as the Receiver of DR/Work Order for the following Billing Profiles:',
                        )}
                      </Grid>

                      {response
                        .filter((result: any) => {
                          if (map[result.gsi4_range_key]) return false;
                          map[result.gsi4_range_key] = 1;
                          return true;
                        })
                        .map((result: any) => {
                          // eslint-disable-next-line max-len
                          return (
                            <Grid item md={12} key={result.hash_key}>
                              <Link
                                style={{
                                  textDecoration: 'underline',
                                  fontWeight: 700,
                                  cursor: 'pointer',
                                }}
                                onClick={() =>
                                  history.push(
                                    `${getBaseUrl()}/billing/${result.hash_key.replace(/(ONSITE|ERS)~RECEIVER~/, '')}`,
                                  )
                                }
                              >
                                <b>{result.range_key}</b>
                              </Link>
                            </Grid>
                          );
                        })}

                      <Grid item md={12} />
                      <Grid item md={12}>
                        {t(
                          'Please reassign the Receiver of DR/Work Order to another contact for the above billing profiles',
                        )}
                      </Grid>
                    </Grid>,
                    `${t('Unable to Deactivate')} ${`${row.first_name} ${row.last_name}`}`,
                    t('Ok'),
                  );
                }
              }
            }}
            size="small"
          >
            <Icons.Delete fontSize="small" color="primary" />
          </IconButton>
        ) : undefined}
      </div>
    );
  }

  /* *********** */
  /* USEEFFECTS  */
  /* *********** */

  useEffect(
    () => () => {
      clearMaestroDataSourceCache(`contacts-${permissions.location?.customer_number || ''}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    setAccountTypeValue(accountTypeFilter.value || 'all');
    setStatesLocationValue(stateLocationFilter.value || 'all');
    setContactRoleValue(contactRoleTypeFilter.value || 'all');
    setAllContactRoleValue(allContactRolesFilter.value || 'all');
    setTireRelateRoleValue(tireRelateRolesFilter.value || 'all');
    setMechanicalRelateRoleValue(mechanicalRelateRolesFilter.value || 'all');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactRoleTypeFilter.value]);

  /* ************** */
  /* Render Table   */
  /* ************** */
  return (
    <Panel spacing={0}>
      <DataGrid
        id="locationsTable"
        // style={{ height: CONTENT_AREA_HEIGHT }}
        noDataText={t('No data found...')}
        scrolling={{ mode: 'virtual', rowRenderingMode: 'virtual' }}
        dataSource={ds.dataSource}
        allowColumnResizing
        wordWrapEnabled
        remoteOperations
        columnAutoWidth
        onToolbarPreparing={ds.onToolbarPreparing}
        className={!popupMode ? 'freespaced-table' : undefined}
        style={!popupMode ? { height: CONTENT_AREA_HEIGHT } : { maxHeight: 'calc(100vh - 188px)' }}
      >
        <Template name="filterFields" render={ds.renderFilterFields} />
        <Template name="resultsCounter" render={ds.renderResultsCounter} />
        <SearchPanel visible highlightSearchText={false} placeholder={t('Search...')} />
        <Column
          caption={t('Level')}
          dataField="level"
          cellRender={({ row }) => <CreatedByAvatar customerType={row.data.contact_level} />}
          alignment="center"
          width={150}
          allowSorting
        />
        <Column
          caption={t('Name & Account Info')}
          dataField="name"
          cellRender={({ row }) => <RenderNameAndAccountInfo row={row.data as ContactListingOVM} />}
          allowSorting
          width={400}
        />
        <Column
          caption={t('Contact Types')}
          minWidth={250}
          cellRender={({ row }) => RenderContactTypes(row.data as ContactListingOVM, t)}
        />
        <Column
          caption={t('Assigned Locations & Priority Level(s)')}
          width={200}
          cellRender={({ row }) => RenderPriorityLevelAndLocations(row.data as ContactListingOVM, t)}
        />
        {!hideActions ? (
          <Column
            caption=""
            visible={!massiveDelete}
            alignment="left"
            width={120}
            cellRender={({ row }) => RenderDeleteButton(row.data, deletePermission)}
          />
        ) : null}
        {!hideActions ? (
          <Column
            caption=""
            visible={massiveDelete}
            alignment="left"
            width={120}
            cellRender={({ row }) =>
              RenderActionCheckbox(
                row.data,
                deletePermission,
                updatePermission,
                selectedRows,
                massiveDelete,
                setSelectedRows,
              )
            }
          />
        ) : null}
        {additionalColumns}
      </DataGrid>
      {/* Once the Contact clicks on the Delete/Remove button */}
      <DeactivateContactDialog
        open={deactivateDialogOpen}
        row={selectedRowToDeactivate}
        handleClose={deactivateContactDialogHandler}
        michelinOffersToDeactivate={locatedMichelinOffersToDeactivate}
        primaryOrSecondaryLevelToDeactivate={primaryOrSecondaryLevelToDeactivate}
      />
      {/* Select contact types to Reassign */}
      {typeof selectedContactToReassign !== 'undefined' ? (
        <ReassignContactTypesDialog
          alreadySelected={new Map()}
          open={reassignDialogOpen}
          locationsFilters={new Set()}
          handleClose={reassignSelectedDialogHandler}
          contact={selectedContactToReassign}
          secondaryDependencies={secondaryConflicts}
        />
      ) : undefined}

      {!hideActions ? (
        <ContactGlobalActions
          fleetId={permissions?.location?.customer_number || ''}
          selectedRows={selectedRows}
          createPermission={createPermission}
          deletePermission={deletePermission}
          massiveDelete={massiveDelete}
          overrideDeletePermission={overrideDeletePermission}
          handleClickDelete={() => setMassiveDelete(!massiveDelete)}
          createContact={createContact}
          clearRows={() => setSelectedRows([])}
          loadTable={reloadAfterAction}
        />
      ) : null}
    </Panel>
  );
}
