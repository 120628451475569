import React, { useEffect, useMemo, useState } from 'react';
import { IconButton, Paper } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { Container, FilterGroup, PageTitle, useListDateFilter, useListSelectFilter } from '@michelin/acid-components';
import { usePermissions, useTranslation } from '@michelin/central-provider';
import { ChangeLogTableConfig, ChangelogsTable } from '@michelin/changelogs-ui';
import defaultConfig from 'global-variables';
import { useHistory, useParams } from 'react-router-dom';
import { renderRowData, renderValues } from './Format';
import './style.css';

export const changeLogFilters = {
  sop: ' Standard Operating Procedures',
  sop_notes: 'Tire Service Procedures',
  account: 'Accounts',
  wheel_profile: 'Wheels',
  contact: 'Contacts',
  location: 'Locations',
  billing: 'Billing',
  tire_profile: 'Tires',
};

function ChangeLog() {
  const { t } = useTranslation();
  const { selectedAccount } = useParams<{ selectedAccount: string }>();
  const history = useHistory();
  const options: { label: string; value: string }[] = [];
  const [config, setConfig] = useState<ChangeLogTableConfig>({} as ChangeLogTableConfig);
  const changeLogsEndpoint: string = `${defaultConfig.externalAPIUrl}/cp-be/changelogs-ms/${selectedAccount}/`;
  const permissions = usePermissions();
  const countryCode = permissions.location?.customer_country || 'us';

  const dictionary: {
    [entity: string]: { [key: string]: string };
  } = {
    dealer_profile_ers_services: {
      ers_tire_services: t('ERS Tire Services'),
      ers_mechanical_services: t('ERS Mechanical Services'),
      rv_motor_coach: t('RV/Motor Coach'),
      tire_services_hours_of_operation: t('Tire Services Hours of Operations'),
      mechanical_hours_of_operation: t('Mechanical Services Hours of Operations'),
      retail_services: t('Services'),
    },
  };

  Object.entries(changeLogFilters).forEach(([key, value]) => {
    options.push({
      label: t(value),
      value: key,
    });
  });

  const sectionFilter = useListSelectFilter({
    label: t('Module'),
    options,
    hashParam: 'entity',
    defaultValue: 'sops',
  });

  const fromFilter = useListDateFilter({
    label: t('From'),
    hashParam: 'from',
    disableFutureDates: true,
  });

  const toFilter = useListDateFilter({
    label: t('To'),
    hashParam: 'to',
    disableFutureDates: true,
  });

  const filterGroup = useMemo(
    () => new FilterGroup([sectionFilter.filter, fromFilter.filter, toFilter.filter]),
    [sectionFilter.filter, fromFilter.filter, toFilter.filter],
  );

  const metadata = {
    0: {
      '*': {
        callback: (value: string, data: any) => renderRowData(data, sectionFilter.value, t),
      },
    },
    1: {
      '*': {
        callback: (value: any, data: any, field: any) => renderValues(value, data, field, countryCode, t),
      },
    },
    2: {
      '*': {
        callback: (value: any, data: any, field: any) => renderValues(value, data, field, countryCode, t),
      },
    },
    3: {
      '*': {
        callback: (value: string) => <span>{value.split('~')[0]}</span>,
      },
    },
  };

  useEffect(() => {
    setConfig({
      metadata,
      columns: [
        {
          caption: t('Identity'),
          field: 'identifier',
          cellType: 'custom',
          width: 'auto',
          allowSearch: true,
        },
        {
          caption: t('Original Value'),
          field: 'origin',
          cellType: 'custom',
          allowSearch: true,
        },
        {
          caption: t('New Value'),
          field: 'diff',
          cellType: 'custom',
          allowSearch: true,
        },
      ],
      // ignore: fieldsToIgnore,
      filterGroup,
      filters: {
        entity: sectionFilter.value,
        from: fromFilter.value ? `${fromFilter.value.replaceAll('/', '-')}T00:00:00.000Z` : undefined,
        to: toFilter.value ? `${toFilter.value.replaceAll('/', '-')}T23:59:59.999Z` : undefined,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionFilter.value, fromFilter.value, toFilter.value]);
  return (
    <>
      <PageTitle
        leftItems={
          <IconButton onClick={() => history.goBack()}>
            <ArrowBack fontSize="small" color="primary" />
          </IconButton>
        }
        title={t('Change History')}
      />
      <Container>
        <Paper>
          <ChangelogsTable
            config={config}
            entity={sectionFilter.value || ''}
            api={changeLogsEndpoint}
            dictionary={dictionary}
            // height={TABBED_CONTENT_HEIGHT}
          />
        </Paper>
      </Container>
    </>
  );
}

export default ChangeLog;
