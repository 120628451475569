import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Button } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { ContactControlDelegator } from 'components/Contact/ContactDetails';
import { Contact } from 'components/Contact/utils';
import { BillingProfileLocation } from '../../query';
import { LocationsGrid } from '../LocationGrid';
import { BillingReassignContact, missingCriticalRolesTitle, resetState } from '../utils';
import AddContact from './AddContact';
import AssignContactTypes from './AssignContactTypes';

export interface BillingReassignContactType {
  contact: BillingReassignContact;
  location: BillingProfileLocation;
  roleArea: string;
  level: string;
}

interface Props {
  open: boolean;
  locations: BillingProfileLocation[];
  alreadyAssignedContactTypes: Map<string, BillingReassignContactType>;
  contactTypes: Array<string>;
  contactTypeLevel: string;
  onCancel: () => void;
  onSave: (asignedContactTypes: Map<string, BillingReassignContactType>) => void;
}

export interface State {
  firstSelectedLocationIndex: number | undefined;
  currentIndex: number | undefined;
  newAssignedContactTypes: Map<string, BillingReassignContactType>;
  removedAssignedContactTypes: Set<string>;
  createContactMode: boolean;
}

export default function MissingCriticalRolesDialog(props: Props): JSX.Element {
  const { open, locations, onCancel, onSave, alreadyAssignedContactTypes } = props;

  const { t } = useTranslation();

  const contactController: ContactControlDelegator = {};

  const [
    {
      firstSelectedLocationIndex,
      currentIndex,
      newAssignedContactTypes,
      removedAssignedContactTypes,
      createContactMode,
    },
    setState,
  ] = useState<State>({
    firstSelectedLocationIndex: undefined,
    currentIndex: undefined,
    newAssignedContactTypes: new Map(),
    removedAssignedContactTypes: new Set(),
    createContactMode: false,
  });

  const sortedLocationsAfterSelect = locations
    .slice(firstSelectedLocationIndex, locations.length)
    .concat(locations.slice(0, firstSelectedLocationIndex));
  const currentLocation =
    typeof currentIndex === 'number' && currentIndex >= 0 && currentIndex < sortedLocationsAfterSelect.length
      ? sortedLocationsAfterSelect[currentIndex]
      : undefined;
  const currentAssignedContactTypes = new Map([...alreadyAssignedContactTypes].concat([...newAssignedContactTypes]));
  const contactTypesSet = new Set(props.contactTypes);
  const disableBack: boolean = currentIndex === 0;
  const disableNext: boolean = currentIndex === sortedLocationsAfterSelect.length - 1;

  /* DEFINE DIALOG CONTENT */
  let dialogTitle;
  let dialogContent;
  const dialogActions = [];

  /* SET ACTION HANDLERS */
  const onCancelHandler = () => {
    setState(resetState());
    onCancel();
  };

  const onSaveHandler = () => {
    removedAssignedContactTypes.forEach((x) => {
      currentAssignedContactTypes.delete(x);
    });
    onSave(currentAssignedContactTypes);
    setState(resetState());
  };

  const onAddContactHandler = () => {
    setState({
      currentIndex,
      firstSelectedLocationIndex,
      newAssignedContactTypes,
      createContactMode: true,
      removedAssignedContactTypes,
    });
  };

  if (currentLocation) {
    /* ASSIGN CONTACT TYPES TO CONTACTS */
    dialogTitle = missingCriticalRolesTitle;

    const onNextHandler = () => {
      if (typeof currentIndex === 'number' && currentIndex < locations.length) {
        const newIndex = currentIndex + 1;
        setState({
          firstSelectedLocationIndex,
          currentIndex: newIndex,
          newAssignedContactTypes,
          createContactMode: false,
          removedAssignedContactTypes,
        });
      }
    };

    const onBackHandler = () => {
      if (typeof currentIndex === 'number' && currentIndex > 0) {
        const newIndex = currentIndex - 1;
        setState({
          firstSelectedLocationIndex,
          currentIndex: newIndex,
          newAssignedContactTypes,
          createContactMode: false,
          removedAssignedContactTypes,
        });
      }
    };

    const onContactTypeChangeHandler = (
      level: string,
      roleArea: string,
      contact: BillingReassignContact,
      checked: boolean,
    ) => {
      const { customer_number: currentLocationCustomerNumber } = currentLocation;
      const billingReassignContactType: BillingReassignContactType = {
        contact,
        location: currentLocation,
        roleArea,
        level,
      };
      if (checked) {
        newAssignedContactTypes.set(
          `${currentLocationCustomerNumber}~${level}~${roleArea}`,
          billingReassignContactType,
        );
        removedAssignedContactTypes.delete(`${currentLocationCustomerNumber}~${level}~${roleArea}`);
      } else {
        newAssignedContactTypes.delete(`${currentLocationCustomerNumber}~${level}~${roleArea}`);
        removedAssignedContactTypes.add(`${currentLocationCustomerNumber}~${level}~${roleArea}`);
      }
      setState({
        currentIndex,
        firstSelectedLocationIndex,
        newAssignedContactTypes,
        createContactMode: false,
        removedAssignedContactTypes,
      });
    };

    if (createContactMode) {
      const onAfterSaveHandler = (contact: Contact) => {
        if (contact.contact_types) {
          contact.contact_types.forEach((contactType) => {
            if (contactType.role_areas) {
              contactType.role_areas.forEach((contactTypeRoleArea) => {
                if (contactTypesSet.has(contactTypeRoleArea.role_area)) {
                  contactTypeRoleArea.levels.forEach((contactTypeLevel) => {
                    if (
                      props.contactTypeLevel === contactTypeLevel.level &&
                      contactTypeLevel.location === currentLocation.hash_key
                    ) {
                      const newBillingReassignContactType: BillingReassignContactType = {
                        location: currentLocation,
                        contact,
                        roleArea: contactTypeRoleArea.role_area,
                        level: contactTypeLevel.level,
                      };
                      newAssignedContactTypes.set(
                        `${currentLocation.customer_number}~${contactTypeLevel.level}~${contactTypeRoleArea.role_area}`,
                        newBillingReassignContactType,
                      );
                      removedAssignedContactTypes.delete(
                        `${currentLocation.customer_number}~${contactTypeLevel.level}~${contactTypeRoleArea.role_area}`,
                      );
                    }
                  });
                }
              });
            }
          });
        }

        setState({
          createContactMode: false,
          currentIndex,
          firstSelectedLocationIndex,
          newAssignedContactTypes,
          removedAssignedContactTypes,
        });
      };
      const onAfterBackHandler = () => {
        setState({
          createContactMode: false,
          currentIndex,
          firstSelectedLocationIndex,
          newAssignedContactTypes,
          removedAssignedContactTypes,
        });
      };

      dialogContent = (
        <AddContact
          currentAssignedContactTypes={currentAssignedContactTypes}
          contactTypeLevels={[props.contactTypeLevel]}
          contactTypes={props.contactTypes}
          location={currentLocation}
          delegateControl={contactController}
          onAfterBackHandler={onAfterBackHandler}
          onAfterSaveHandler={onAfterSaveHandler}
        />
      );

      dialogActions.push(
        <Button
          color="success"
          onClick={() => {
            if (contactController.save) contactController.save();
          }}
        >
          {t('Save')}
        </Button>,
      );
      dialogActions.push(
        <Button key="backBtn" onClick={onAfterBackHandler}>
          {t('Back')}
        </Button>,
      );
    } else {
      dialogContent = (
        <AssignContactTypes
          location={currentLocation}
          index={currentIndex || 0}
          locationsNumber={locations.length}
          assignedContactTypes={currentAssignedContactTypes}
          removedAssignedContactTypes={removedAssignedContactTypes}
          contactTypes={props.contactTypes}
          contactTypeLevels={[props.contactTypeLevel]}
          onNext={onNextHandler}
          onBack={onBackHandler}
          onChange={onContactTypeChangeHandler}
          disableBack={disableBack}
          disableNext={disableNext}
        />
      );
      dialogActions.push(
        <Button key="addBtn" color="secondary" onClick={onAddContactHandler}>
          {t('Create new Contact')}
        </Button>,
      );
      dialogActions.push(
        <Button key="saveBtn" color="primary" onClick={onSaveHandler}>
          {t('Save & Close')}
        </Button>,
      );
      dialogActions.push(
        <Button key="cancelBtn" color="danger" onClick={onCancelHandler}>
          {t('Cancel')}
        </Button>,
      );
    }
  } else {
    /* SELECT LOCATION TO ASSIGN CONTACTS */
    const onLocationSelectedHandler = (location: BillingProfileLocation, index: number) => {
      setState({
        currentIndex: 0,
        firstSelectedLocationIndex: index,
        newAssignedContactTypes,
        createContactMode: false,
        removedAssignedContactTypes,
      });
    };
    dialogTitle = missingCriticalRolesTitle;
    dialogContent = <LocationsGrid open={open} locations={locations} onLocationSelected={onLocationSelectedHandler} />;
    dialogActions.push(
      <Button color="secondary" key="cancelBtn" onClick={onCancelHandler}>
        {t('Cancel')}
      </Button>,
    );
  }

  return (
    <Dialog open={open} fullWidth maxWidth="xl">
      <DialogTitle>{t(dialogTitle)}</DialogTitle>
      <DialogContent dividers style={{ padding: 0 }}>
        {dialogContent}
      </DialogContent>
      <DialogActions>{dialogActions}</DialogActions>
    </Dialog>
  );
}
