import { MaestroAPI } from '@michelin/central-provider';
import { auth } from 'Auth';
import {
  sopValidationTireAxleDelete,
  sopValidationTireProfileDelete,
  sopValidationTireSizeDelete,
} from 'components/Types/APITypes';
import { apiBaseRoute } from 'components/Types/Api';

export interface SOPValidationResult {
  valid: boolean;
  sopName: string;
  axles?: string[];
}

type ValidationTypes = 'axle' | 'tire_size' | 'sop';

interface ValidationOptions {
  id: string;
  axleTypes?: string[];
  tireSize?: string;
}

const headers: { [key: string]: string } = { 'Content-Type': 'application/json' };

export const validate = async (endpoint: string): Promise<SOPValidationResult> => {
  try {
    headers.Authorization = `Bearer ${auth.getAuthResultTokenAccess()}`;

    const responseData = await fetch(endpoint, {
      cache: 'no-cache',
      headers,
      method: 'GET',
      signal: undefined,
    });

    if (responseData.status >= 300) {
      const jsonError = await responseData.json();
      throw Error(JSON.stringify(jsonError));
    }

    const jsonResult = await responseData.json();
    return { valid: jsonResult.result, sopName: jsonResult.sop, axles: jsonResult.axles };
  } catch (error) {
    return { valid: false, sopName: '' };
  }
};

export const sopValidation = async (
  type: ValidationTypes,
  options: ValidationOptions,
): Promise<SOPValidationResult> => {
  if (!auth || !auth.apolloClient) return { valid: false, sopName: '' };

  let valReturn: SOPValidationResult = { valid: false, sopName: '' };

  try {
    if (!auth.getCustomerNumber() || auth.getCustomerNumber().length <= 0) {
      throw new Error('Location hash_key is invalid');
    }

    if (!options.id || options.id.length <= 0) {
      throw new Error('Tire Profile id is invalid');
    }

    switch (type) {
      case 'sop':
        // eslint-disable-next-line no-case-declarations
        const apiTypeSOP: MaestroAPI = sopValidationTireProfileDelete(auth.getCustomerNumber(), options.id);
        // eslint-disable-next-line no-case-declarations
        const endpointSOP: string = `${apiBaseRoute}/${apiTypeSOP.endpoint}`;
        valReturn = await validate(endpointSOP);
        break;
      case 'axle':
        // eslint-disable-next-line no-case-declarations
        const apiTypeAxle: MaestroAPI = sopValidationTireAxleDelete(
          auth.getCustomerNumber(),
          options.id,
          options.axleTypes,
        );
        // eslint-disable-next-line no-case-declarations
        const endpointAxle: string = `${apiBaseRoute}/${apiTypeAxle.endpoint}`;
        valReturn = await validate(endpointAxle);
        break;
      case 'tire_size':
        // eslint-disable-next-line no-case-declarations
        const apiTypeTireSize: MaestroAPI = sopValidationTireSizeDelete(
          auth.getCustomerNumber(),
          options.id,
          options.axleTypes,
          options.tireSize,
        );
        // eslint-disable-next-line no-case-declarations
        const endpointTireSize: string = `${apiBaseRoute}/${apiTypeTireSize.endpoint}`;
        valReturn = await validate(endpointTireSize);
        break;
      default:
        break;
    }
  } catch (err) {
    return { valid: false, sopName: '' };
  }

  return valReturn;
};
