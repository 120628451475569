import { gql } from 'apollo-boost';
import { checkPurchasingProcedures } from './PurchasingProcedures/utils';
import { BillingPreferences, BillingServiceTypes } from './query';
import { ValidationErrors } from './utils';

/*
    TODO: Checks ERS & OnSite Purchasing Procedures
*/
export const checkBothPurchasingProcedures = (profile: BillingPreferences, validationErrors: ValidationErrors) => {
  const valErrors = JSON.parse(JSON.stringify(validationErrors)) as ValidationErrors;
  valErrors.gi_profile_name = !profile.general_information.profile_name;

  let ersPurchasingProceduresFailed = false;
  if (profile.general_information.service_type !== BillingServiceTypes.MECHANICAL) {
    ersPurchasingProceduresFailed = checkPurchasingProcedures(
      profile.ers_purchasing_procedures,
      true,
      profile.general_information.profile_type,
      valErrors.pp_ers,
    );
  }

  const onsitePurchasingProceduresFailed = checkPurchasingProcedures(
    profile.onsite_purchasing_procedures,
    false,
    profile.general_information.profile_type,
    valErrors.pp_os,
  );

  return {
    valErrors,
    check: ersPurchasingProceduresFailed || onsitePurchasingProceduresFailed,
  };
};

export const removeExtraFields = (parentObj: any, typePP: string) => {
  if (parentObj[typePP]) {
    delete parentObj[typePP].ers_st_location;
    delete parentObj[typePP].receiver_location;
    delete parentObj[typePP].receiver_contact;
  }
};

export const GET_UNIQUE_PROFILE = gql`
  query getUniqueBillingProfile(
    $relationship: String!
    $profile_id: String!
    $profile_type: String!
    $service_type: String!
    $set_all_locations: Boolean
  ) {
    getUniqueBillingProfile(
      relationship: $relationship
      profile_id: $profile_id
      profile_type: $profile_type
      service_type: $service_type
      set_all_locations: $set_all_locations
    ) {
      matchFound
      locationKeys
    }
  }
`;
