import React from 'react';
import { Grid } from '@material-ui/core';
import { ProductOVM } from '@michelin/fcp-view-models';
import { Actions, AxleName } from 'modules/Tires/types';
import { PassengerLtTirePriority } from './Axle/PassengerLtTirePriority';
import { TirePressureRegulator } from './Axle/TirePressureRegulator';
import { TirePressureSensor } from './Axle/TirePressureSensor';
import { TireReplacementPriority } from './Axle/TireReplacementPriority';
import { TireServiceSpecifications } from './Axle/TireServiceSpecifications';

interface props {
  axleName: AxleName;
  action: Actions;
  products: ProductOVM[];
  selectedTab: number;
  addPriority: () => void;
}

function AxleTab({ axleName, action, products, selectedTab, addPriority }: props) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TireReplacementPriority
            axleName={axleName}
            action={action}
            products={products}
            selectedTab={selectedTab}
            addPriority={addPriority}
          />
        </Grid>
        {axleName === 'all_axles' && (
          <Grid item xs={12}>
            <PassengerLtTirePriority action={action} />
          </Grid>
        )}
        <Grid item xs={12}>
          <TireServiceSpecifications axleName={axleName} action={action} />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <TirePressureSensor axleName={axleName} action={action} />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <TirePressureRegulator axleName={axleName} action={action} />
        </Grid>
      </Grid>
    </>
  );
}

export { AxleTab };
