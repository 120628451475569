import { Location } from '@michelin/central-provider';

const getLocationsCount = (customer_type: string, customer_number: string, locations: Location[]) => {
  let filteredLocations: Location[] = [];
  if (customer_type === 'PC') {
    filteredLocations = locations.filter(
      (location) => location.parent_company_number === customer_number && location.customer_type === 'ST',
    );
  }
  if (customer_type === 'HO') {
    filteredLocations = locations.filter(
      (location) => location.home_office_number === customer_number && location.customer_type === 'ST',
    );
  }
  if (customer_type === 'BT') {
    filteredLocations = locations.filter(
      (location) => location.bill_to_customer === customer_number && location.customer_type === 'ST',
    );
  }
  if (customer_type === 'ST') {
    filteredLocations = locations.filter(
      (location) => location.ship_to_customer === customer_number && location.customer_type === 'ST',
    );
  }

  return filteredLocations.length.toString();
};

export { getLocationsCount };
