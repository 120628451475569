import { SelectedServiceOffer, ServiceOfferIds, getSelectedServiceOffer } from 'prefs-and-service-offers';

export const getListProfileType = (): string => {
  let listProfileType = '';

  const locSelectedServiceOffer: SelectedServiceOffer | undefined = getSelectedServiceOffer() || undefined;
  switch (locSelectedServiceOffer && locSelectedServiceOffer.id) {
    case ServiceOfferIds.allPreferences:
      listProfileType = 'all';
      break;
    case ServiceOfferIds.onCallTire:
      listProfileType = 'ers';
      break;
    case ServiceOfferIds.bulkOrder:
      listProfileType = 'onsite';
      break;
    default:
      listProfileType = '';
      break;
  }

  return listProfileType;
};

export const addPrefixToArray = (prefix: string, arr: string[]) => {
  return arr.map((item) => (item.startsWith(prefix) ? item : prefix + item));
};

export function setPageTitle(section: string | null | undefined) {
  window.document.title = `${section ? `${section} - ` : ''}Fleet Profiles - Michelin`;
  // @ts-ignore
  if (window.onScroll) [5, 10, 50, 100, 500].forEach((t) => setTimeout(window.onScroll, t));
}
