import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { LoadingBounce, Panel, Select } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { getOptions } from '@michelin/select-options-provider';
import { initPurchasingProcedure } from 'components/Billing/Preferences/PurchasingProcedures/utils';
import {
  AuthorizationService,
  BillingPreferences,
  BillingProfileLocation,
  BillingPurchasingProcedures,
} from 'components/Billing/Preferences/query';
import { Contact } from 'components/Contact/utils';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { ContactToRequireAuth } from '../Components/ContactToRequireAuth';
import { DrWorkOrder } from '../Components/DrWorkOrder';
import { PurchasingProcValidations } from '../types';

/* It can be called from src/components/Billing/Preferences/index.tsx as follows:
    <OnSitePurchasingProcedures
      loading={loadingContacts || loadingLocations}
      modifiedFlag={modifiedFlag}
      setModifiedFlag={setModifiedFlag}
      profile={profile}
      editProfile={setProfile}
      profileType={profileType}
      editFlag={editFlag}
      locations={locations}
      setLocations={setLocations}
      contacts={allContacts || []}
      validationErrors={validationErrors.pp_os}
      handleContactLevelChange={handleContactLevelChange}
    />
 */

export interface OnSitePurchasingProceduresProps {
  profile: BillingPreferences;
  editFlag: boolean;
  loading: boolean;
  editProfile: (profile: BillingPreferences) => void;
  modifiedFlag: boolean;
  setModifiedFlag: (modifiedFlag: boolean) => void;
  profileType: string;
  locations: BillingProfileLocation[];
  setLocations: (locations: BillingProfileLocation[]) => void;
  contacts: Array<Contact>;
  handleContactLevelChange: (
    contactLevel: string,
    contactType: string[],
    primary: boolean,
    manualClick?: boolean,
  ) => void;
  validationErrors: PurchasingProcValidations;
}

export function OnSitePurchasingProcedures(props: OnSitePurchasingProceduresProps) {
  const { t } = useTranslation();
  const options_required_authorization_to_begin_service = getOptions(
    'required_authorization_to_begin_service_onsite',
    'Select',
    t('Select'),
  );
  const authorized_tire_po_issuer = { label: 'Tire PO Issuer', value: 'po_issuer' };
  const {
    profile,
    editFlag,
    editProfile,
    modifiedFlag,
    setModifiedFlag,
    validationErrors,
    handleContactLevelChange,
    contacts,
    setLocations,
    locations,
    loading,
  } = props;
  const methods = useForm<BillingPreferences>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: profile,
  });
  useEffect(() => {
    if (methods.formState.isDirty) {
      setModifiedFlag(true);
      editProfile(methods.getValues());
      methods.reset(methods.getValues());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.formState.isDirty]);
  useEffect(() => {
    if (!modifiedFlag) {
      editProfile(profile);
      methods.reset(profile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiedFlag]);

  const title = t('On-Site Purchasing Procedures');
  const poRequired =
    methods.getValues() &&
    methods.getValues().general_information &&
    methods.getValues().general_information.po_required_for_service
      ? methods.getValues().general_information.po_required_for_service
      : false;
  let purchasing_procedure: BillingPurchasingProcedures = initPurchasingProcedure(false, poRequired);

  // If the profile is not created because it depends on the profile type then is created.
  const ospp = methods.watch('onsite_purchasing_procedures');
  useEffect(() => {
    if (methods.getValues() && !ospp) {
      methods.setValue('onsite_purchasing_procedures', purchasing_procedure, { shouldDirty: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ospp, methods.setValue]);

  const updateReqAuth = (newValue: string) => {
    purchasing_procedure.required_authorization_to_begin_service = newValue;
    if (newValue === 'po') {
      purchasing_procedure.primary_method_to_request_service = AuthorizationService.fleet_contact;
      if (purchasing_procedure.pri_ers_authorized_contact_type) {
        if (!purchasing_procedure.pri_ers_authorized_contact_type.includes(authorized_tire_po_issuer.value)) {
          purchasing_procedure.pri_ers_authorized_contact_type.push(authorized_tire_po_issuer.value);
        }
      } else {
        purchasing_procedure.pri_ers_authorized_contact_type = [authorized_tire_po_issuer.value];
      }
    }
    validationErrors.required_authorization = false;
    methods.setValue('onsite_purchasing_procedures', purchasing_procedure, { shouldDirty: true });
  };

  return (
    <Panel title={title} style={{ marginBottom: 15 }}>
      {loading ? (
        <LoadingBounce style={{ height: 300 }} />
      ) : (
        <Grid container spacing={2} style={{ paddingTop: 5 }} id="poProc">
          <FormProvider {...methods}>
            <Grid item sm={12} md={12}>
              <Grid container spacing={2} style={{ paddingBottom: 20 }}>
                <Grid item sm={12} lg={4} style={{ marginTop: '1.25rem' }}>
                  <Controller
                    name="onsite_purchasing_procedures.required_authorization_to_begin_service"
                    control={methods.control}
                    render={(item) => (
                      <Select
                        id={item.field.name}
                        name="required_authorization_to_begin_service"
                        data-testid="requiredAuthToBeginService"
                        style={{ minWidth: '100px', width: '100%' }}
                        label={t('Required Authorization for Billing Purposes')}
                        value={item.field.value || ''}
                        required={validationErrors.required_authorization}
                        readOnly={!editFlag || poRequired}
                        options={options_required_authorization_to_begin_service}
                        onChange={(e) => {
                          item.field.onChange(e.target.value);
                          updateReqAuth(e.target.value as string);
                        }}
                        inputProps={{
                          'aria-label': 'required_authorization_to_begin_service-select',
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12} md={12}>
              <Grid container spacing={2} style={{ paddingBottom: 20 }}>
                <Controller
                  name="onsite_purchasing_procedures"
                  control={methods.control}
                  render={(item) => (
                    <ContactToRequireAuth
                      editFlag={editFlag}
                      profile={methods.getValues()}
                      ers={false}
                      purchasing_procedure={purchasing_procedure}
                      validationErrors={validationErrors}
                      editProfile={editProfile}
                      setModifiedFlag={setModifiedFlag}
                      primaryContactRequired
                      handleContactLevelChange={handleContactLevelChange}
                      poRequired={poRequired}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item sm={12} md={12}>
              <Grid container spacing={2} style={{ paddingBottom: 20 }}>
                <Controller
                  name="onsite_purchasing_procedures"
                  control={methods.control}
                  render={(item) => (
                    <ContactToRequireAuth
                      editFlag={editFlag}
                      profile={profile}
                      ers={false}
                      purchasing_procedure={purchasing_procedure}
                      validationErrors={validationErrors}
                      editProfile={editProfile}
                      setModifiedFlag={setModifiedFlag}
                      primaryContactRequired={false}
                      handleContactLevelChange={handleContactLevelChange}
                      poRequired={poRequired}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item sm={12} md={12}>
              <Controller
                name="onsite_purchasing_procedures"
                control={methods.control}
                render={(item) => (
                  <DrWorkOrder
                    editFlag={editFlag}
                    ers={false}
                    validationErrors={validationErrors}
                    profile={profile}
                    editProfile={editProfile}
                    modifiedFlag={modifiedFlag}
                    setModifiedFlag={setModifiedFlag}
                    locations={locations}
                    setLocations={setLocations}
                    contacts={contacts}
                  />
                )}
              />
            </Grid>
          </FormProvider>
        </Grid>
      )}
    </Panel>
  );
}
