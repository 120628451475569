import React, { ChangeEvent, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { Button, TextField } from '@michelin/acid-components';
import { useSnackbar, useTranslation } from '@michelin/central-provider';
import { PassengerLtPriority, ProductOVM, TireProfileOVM } from '@michelin/fcp-view-models';
import { SelectOption } from '@michelin/select-options-provider';
import { getMspnData } from 'hooks/useTireProfile';
import { useFormContext } from 'react-hook-form';
import { getAxle } from '../utils';

interface props {
  open: boolean;
  onClose: () => void;
  mspnIndex: number;
  mspnPriority: number;
  products: ProductOVM[];
  onSave: (row: PassengerLtPriority) => void;
  brandsOptions: SelectOption[];
}

export function LTMspnSearch({ open, onClose, mspnIndex, mspnPriority, onSave, brandsOptions }: props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [getMspnsText, setGetMspnsText] = useState('');
  const { getValues } = useFormContext<TireProfileOVM>();
  let axle = getAxle('all_axles', getValues());

  const checkMspn = (mspn: string) => {
    const axle = getAxle('all_axles', getValues());
    if (!axle) return true;
    const priorities = axle.passenger_lt_priority || [];
    const duplicate = priorities.filter((priority) => priority.mspn === mspn);
    if (duplicate.length > 0) return false;
    return true;
  };

  const checkUnique = (index: number, newRow: any): boolean => {
    const axle = getAxle('all_axles', getValues());
    if (!axle) return true;
    const priorities = axle.passenger_lt_priority || [];
    const duplicate = priorities.filter(
      (priority) =>
        priority.priority !== newRow.priority &&
        priority.brand === newRow.brand &&
        priority.tire_size === newRow.tire_size &&
        priority.tread_design === newRow.tread_design &&
        priority.speed_rating === newRow.speed_rating,
    );
    if (duplicate.length > 0) return false;
    return true;
  };

  const getMspnTireData = async () => {
    if (!checkMspn(getMspnsText)) {
      enqueueSnackbar(t('That tire type already exists'), { variant: 'error' });
      return;
    }
    const data = await getMspnData(getMspnsText, '002');
    setGetMspnsText('');
    const response = data || '';
    if (response && axle && axle.passenger_lt_priority) {
      const { note } = axle.passenger_lt_priority[mspnIndex];

      const newRow: PassengerLtPriority = {
        priority: mspnPriority,
        brand: response.brand,
        tire_size: response.tire_size,
        tread_design: response.tread_design || '',
        speed_rating: response.speed_rating || '',
        mspn: getMspnsText,
        note: note ? String(note) : undefined,
      };

      if (mspnPriority === 1) {
        if (!/michelin|bfgoodrich|uniroyal/gi.test(response.brand)) {
          enqueueSnackbar(t('The first priority must be a Michelin, Uniroyal or BFGoodrich.'), {
            variant: 'error',
          });
          return;
        }
      }

      const isABrandOption = brandsOptions.find((option) => option.value === response.brand);

      if (!isABrandOption) {
        enqueueSnackbar(
          t('The brand {{brand}} for the MSPN {{mspn}} is not a Passenger/LT Tire Replacement option', {
            brand: response.brand,
            mspn: getMspnsText,
          }),
          {
            variant: 'error',
          },
        );
        return;
      }
      if (!checkUnique(mspnIndex, newRow)) {
        enqueueSnackbar(t('That tire type already exists'), { variant: 'error' });
        return;
      }
      setGetMspnsText('');
      onSave(newRow);
    } else {
      enqueueSnackbar(t('The MSPN {{mspn}} was not found', { mspn: getMspnsText }), { variant: 'error' });
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={() => {
        onClose();
        setGetMspnsText('');
      }}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle>{t('Key in the MSPN of the tire to add it to the list')}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item md={3} />
          <Grid item md={4}>
            <TextField
              value={getMspnsText}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setGetMspnsText(event.target.value);
              }}
              label={t('MSPN')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item md={3}>
            <Button
              onClick={() => {
                getMspnTireData();
              }}
            >
              {t('Search')}
            </Button>
          </Grid>
          <Grid item md={2} />
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
