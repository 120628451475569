import React from 'react';
import { ExpansionPanelDetails, ExpansionPanelSummary, Typography, capitalize } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Panel } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { Contact } from 'components/Contact/utils';
import { TranslateCallback } from 'components/Util';
import { CheckBox, DataGrid } from 'devextreme-react';
import { Column, Pager, Paging, Scrolling } from 'devextreme-react/data-grid';
import { Customer } from '../../../../../Auth';
import {
  GENERAL_LEVEL_VALUE,
  PRIMARY_LEVEL_VALUE,
  SECONDARY_LEVEL_VALUE,
  TIRE_SERVICE_VALUE,
  getContactTypesRoleAreaLabelFromValue,
} from '../../utils';
import { ReassignSelection } from '../dialogs/ReassignContactTypesDialog';

interface Props {
  contact: Contact;
  disabledPanelContact: Contact | undefined;
  selectedRoleAreas: Array<{ roleArea: string; level: string }>;
  selectedLocation: Customer;
  locationContacts: Array<Contact>;
  checkHandler: (value: boolean, contact: Contact, roleArea: string, level: string, location: string) => void;
  contactSelectedReassignRoleAreaLocation: Map<string, ReassignSelection>;
}

function renderCity(row: Contact): JSX.Element {
  const { locations } = row;
  if (typeof locations !== 'undefined' && locations.length > 0) {
    const { location } = locations[0];
    if (location) {
      const { customer_state, customer_city } = location;
      return (
        <div>
          <span style={{ display: 'none' }}>{customer_state}</span>
          <b>{customer_city}</b>
          <br />
          {customer_state}
        </div>
      );
    }
  }
  return <span />;
}

export function renderContactInformation(row: Contact): JSX.Element {
  const icnStyle = { height: 14 };
  const { preferred_method } = row;

  const contactBlock = (data: string | null | undefined, Icon: any, defaultValue: string) => {
    if (!data || !data.trim()) return undefined;
    return (
      <span style={{ whiteSpace: 'nowrap' }}>
        <Icon style={icnStyle} />
        {data}
        {preferred_method && preferred_method.toLowerCase() === defaultValue ? (
          <Icons.Star style={icnStyle} />
        ) : undefined}
        <br />
      </span>
    );
  };

  const { ext, cell_phone, email_address } = row;
  let { work_phone: workPhone } = row;
  if (workPhone && ext) {
    workPhone += ` ext. ${ext}`;
  }
  return (
    <div>
      {contactBlock(cell_phone, Icons.PhoneAndroid, 'cell')}
      {contactBlock(workPhone, Icons.Phone, 'work')}
      {contactBlock(email_address, Icons.Email, 'email')}
    </div>
  );
}

export function renderContactTypes(row: Contact, t: TranslateCallback): JSX.Element {
  const { contact_types } = row;
  const contactTypesRows: Array<JSX.Element> = new Array<JSX.Element>();

  if (contact_types) {
    contact_types.forEach((contact_type) => {
      const { role_areas, service } = contact_type;
      if (!role_areas) return;
      role_areas.forEach((contactTypeRoleArea) => {
        const { role_area } = contactTypeRoleArea;
        contactTypesRows.push(
          <li>{`${t(capitalize(service || ''))} ${getContactTypesRoleAreaLabelFromValue(role_area, t)}`}</li>,
        );
      });
    });
  }

  return <ul style={{ fontSize: '0.9em', whiteSpace: 'nowrap' }}>{contactTypesRows}</ul>;
}

export function renderNameAndAccountInfo(row: Contact): JSX.Element {
  const { first_name, last_name } = row;
  return (
    <span>
      <b>
        {last_name}, {first_name}
      </b>
    </span>
  );
}

function renderAssign(contact: Contact, params: CheckParams): JSX.Element {
  const { level, location, roleArea, checkHandler, contactSelectedReassignRoleAreaLocation } = params;

  const onCheckBoxValueChange = (e: any) => {
    const { value } = e;
    checkHandler(value, contact, roleArea, level, location.hash_key);
  };

  const contactCheck = contactSelectedReassignRoleAreaLocation.get(roleArea + location.hash_key + level);
  const value = typeof contactCheck !== 'undefined' && contactCheck.contact.hash_key === contact.hash_key;

  return <CheckBox value={value} onValueChanged={onCheckBoxValueChange} />;
}

function hasRoleAreaWithLevel(
  contact: Contact,
  roleAreaToFind: string,
  levelToFind: string,
  locationToFind: string,
): boolean {
  const { contact_types } = contact;

  return (
    typeof contact_types !== 'undefined' &&
    contact_types.some((contactType) => {
      const { role_areas, service } = contactType;
      if (role_areas && role_areas.length > 0) {
        return (
          typeof role_areas !== 'undefined' &&
          role_areas.some((contactTypeRoleArea) => {
            const { levels, role_area } = contactTypeRoleArea;
            if (levels && levels.length > 0) {
              return (
                role_area === roleAreaToFind &&
                typeof levels !== 'undefined' &&
                levels.some((contactTypeLevel) => {
                  const { location, level } = contactTypeLevel;
                  if (location !== locationToFind) return false;
                  if (levelToFind === PRIMARY_LEVEL_VALUE) return false;
                  if (levelToFind === SECONDARY_LEVEL_VALUE) {
                    return (
                      location === locationToFind && level === PRIMARY_LEVEL_VALUE && service === TIRE_SERVICE_VALUE
                    );
                  }
                  return (
                    location === locationToFind &&
                    (level === PRIMARY_LEVEL_VALUE || level === SECONDARY_LEVEL_VALUE) &&
                    service === TIRE_SERVICE_VALUE
                  );
                })
              );
            }
            return false;
          })
        );
      }
      return false;
    })
  );
}

interface CheckParams {
  level: string;
  location: Customer;
  roleArea: string;
  checkHandler: (value: boolean, contact: Contact, roleArea: string, level: string, location: string) => void;
  contactSelectedReassignRoleAreaLocation: Map<string, ReassignSelection>;
}

export default function ReassignSelectedRoleAreasTable(props: Props): JSX.Element {
  const {
    locationContacts,
    selectedRoleAreas,
    selectedLocation,
    disabledPanelContact,
    checkHandler,
    contactSelectedReassignRoleAreaLocation,
  } = props;

  const { t } = useTranslation();

  // For each contact type there is going to be a table
  const tablesToShow = new Array<JSX.Element>();
  selectedRoleAreas.forEach((selectedRoleArea) => {
    const { level, roleArea } = selectedRoleArea;
    const checkParams: CheckParams = {
      level,
      location: selectedLocation,
      roleArea,
      checkHandler,
      contactSelectedReassignRoleAreaLocation,
    };
    const filteredContacts = locationContacts.filter(
      (x) => !hasRoleAreaWithLevel(x, roleArea, level, selectedLocation.hash_key.toString()),
    );

    tablesToShow.push(
      <div style={{ marginBottom: '0.3rem' }}>
        <Panel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography>
              <b>{t('Contact Type')}:</b> {level !== GENERAL_LEVEL_VALUE ? t(capitalize(level)) : ' '} {t('Tire')}{' '}
              {getContactTypesRoleAreaLabelFromValue(roleArea, t)}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <DataGrid
              dataSource={filteredContacts}
              allowColumnReordering
              allowColumnResizing
              keyExpr="hash_key"
              style={{ maxHeight: '75vh' }}
              noDataText={t('No available contacts to select for this location.')}
            >
              <Scrolling mode="virtual" />
              <Pager allowedPageSizes={[5, 10, 20, 30]} showPageSizeSelector />
              <Paging defaultPageSize={10} enabled />

              <Column caption={t('Name & Account Info')} cellRender={({ row }) => renderNameAndAccountInfo(row.data)} />
              <Column caption={t('City / State')} cellRender={({ row }) => renderCity(row.data)} />
              <Column caption={t('Email / Phone')} cellRender={({ row }) => renderContactInformation(row.data)} />
              <Column caption={t('Contact types')} cellRender={({ row }) => renderContactTypes(row.data, t)} />
              <Column caption={t('Assign')} cellRender={({ row }) => renderAssign(row.data, checkParams)} />
            </DataGrid>
          </ExpansionPanelDetails>
        </Panel>
      </div>,
    );
  });

  return (
    <>
      {disabledPanelContact ? (
        <div style={{ marginBottom: '1rem' }}>
          <h3>
            {t('All Role/Areas have been assigned to {{firstName}} {{lastName}}', {
              firstName: disabledPanelContact.first_name,
              lastName: disabledPanelContact.last_name,
            })}
          </h3>
        </div>
      ) : undefined}
      {tablesToShow}
    </>
  );
}
