export type AxleName = 'all_axles' | 'steer' | 'drive' | 'trailer' | 'carry' | 'lift' | 'pusher' | 'tag';

interface AxleTabType {
  name: AxleName;
  label: string;
  index: number;
}

export const AXLES: AxleTabType[] = [
  { name: 'all_axles', label: 'All', index: 1 },
  { name: 'steer', label: 'Steer', index: 2 },
  { name: 'drive', label: 'Drive', index: 3 },
  { name: 'trailer', label: 'Trailer', index: 4 },
  { name: 'carry', label: 'Carry', index: 5 },
  { name: 'lift', label: 'Lift', index: 6 },
  { name: 'pusher', label: 'Pusher', index: 7 },
  { name: 'tag', label: 'Tag', index: 8 },
];

export interface TireProfileParams {
  selectedAccount: string;
  profileId: string;
}

export interface AxleUpdate {
  value: boolean;
  oldValue: boolean;
  dirty: boolean;
}
export interface Axles {
  steer: AxleUpdate;
  drive: AxleUpdate;
  trailer: AxleUpdate;
  carry: AxleUpdate;
  lift: AxleUpdate;
  pusher: AxleUpdate;
  tag: AxleUpdate;
  all_axles: AxleUpdate;
}

export interface IPermissions {
  create: boolean;
  update: boolean;
  read: boolean;
  delete: boolean;
  list: boolean;
  override_delete?: boolean;
}

export type Actions = 'add' | 'edit' | 'view' | '';

export interface IAttributeError {
  isError: boolean;
  message: string;
}

export interface IAxleErrors {
  [key: string]: {
    hasErrors: boolean;
    pressure_sensor?: {
      disposition: IAttributeError;
      location: IAttributeError;
      mounting: IAttributeError;
      types: IAttributeError;
    };
  };
}
