import React, { useEffect, useState } from 'react';
import { StoreMallDirectoryOutlined } from '@material-ui/icons';
import { CSSProperties } from '@material-ui/styles';
import { Button } from '@michelin/acid-components';
import { usePermissions } from '@michelin/central-provider';
import { auth } from '../../Auth';
import { getAllResultsPaginated } from '../../graphql/utils';
import AssignDialog from './AssignDialog';
import { AssigneeCustomerType, getChildrenForAssignments } from './queries';

interface LocationAssignmentsProps {
  mode: 'view' | 'edit';
  all: boolean;
  locations: { hash_key: string }[];
  disabled?: boolean;
  owner?: string;
  isMST?: boolean;
  onUpdate?: (locations: AssigneeCustomerType[]) => void;
  additionalColumns?: JSX.Element | JSX.Element[];
  displayCurrentLocation?: boolean;
  filterByCustomerType?: string; // It can be: [PC, HO, BT, ST]
  style?: CSSProperties;
  plainView?: boolean;
  singleSelection?: boolean;
  hideCount?: boolean;
  error?: boolean;
  subTitle?: string;
}

export const locationsCountMap = new Map<string, string>();
export const locationsMap = new Map<string, AssigneeCustomerType>();
let cachedForLocation = '';

export function getLowestLocation(
  hierarchy: string,
  location1: string | undefined,
  location2: string | undefined,
): string {
  const loc1 = location1 || '';
  const loc2 = location2 || '';
  const pos1 = hierarchy.indexOf(loc1);
  const pos2 = hierarchy.indexOf(loc2);
  if (pos1 === -1) return loc1;
  if (pos2 === -1) return loc2;
  if (pos1 < pos2) return loc2;
  return loc1;
}

export default function LocationAssignments(props: LocationAssignmentsProps) {
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<boolean>(false);
  const [locationsCount, setLocationsCount] = useState<string>('...');
  const [assignedLocationsDialogOpen, setAssignedLocationsDialogOpen] = useState(false);
  const [workingLocation, setWorkingLocation] = useState<string>('');
  const { location } = usePermissions();
  const currentLocation = location?.customer_number || '';

  const customerNumber = auth.getCustomerNumber();
  const relationship = auth.getRelationship();
  const relationshipLocations = relationship.split('~');

  // Set Icon Count
  useEffect(() => {
    if (loading || !locationsCountMap.size) return;

    // If no locations are assigned, display 0
    if (!props.locations.length && !props.all) {
      setLocationsCount('0');
      return;
    }

    // If all locations flag is true, we should take into account
    // the amount of ship to's of the lowest location in the hierarchy
    // between the owner of the profile and the current user customer number
    // or the amount of ship to's in the MST Group if the user is MST - 1283092 1335826
    if (props.all) {
      const count = props.filterByCustomerType ? '1' : locationsCountMap.get(workingLocation) || '...';
      setLocationsCount(count);
      return;
    }

    let count = 0;
    let assignedLocations = false;
    props.locations.forEach((loc) => {
      const locNumber = loc.hash_key.split('1~').pop();
      const retrieved = locationsCountMap.get(locNumber || '');
      if (retrieved !== undefined) {
        // This location is down in the hierarchy
        count += parseInt(props.filterByCustomerType ? '1' : retrieved, 10);
        assignedLocations = true;
      } else {
        // This location may be up in the hierarchy
        // eslint-disable-next-line no-lonely-if
        if (relationshipLocations.includes(locNumber || '')) {
          const relCount = locationsCountMap.get(customerNumber) || '1';
          count += parseInt(props.filterByCustomerType ? '1' : relCount, 10);
          assignedLocations = true;
        }
      }
    });
    if (assignedLocations) setLocationsCount(count.toString(10));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.all, props.locations, loading]);

  // Set Working Location
  useEffect(() => {
    // Owner does not make sense for MST Profiles
    setWorkingLocation(getLowestLocation(relationship, customerNumber, props.isMST ? 'NotApplicable' : props.owner));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.owner]);

  useEffect(() => {
    if (cachedForLocation !== currentLocation) {
      locationsCountMap.clear();
      locationsMap.clear();
      cachedForLocation = currentLocation;
    }
  }, [currentLocation]);

  // Fetch Data
  useEffect(() => {
    async function fetchData(retry: boolean = false) {
      const queryVars = { accountNumber: auth.getCustomerNumber() };

      let data: AssigneeCustomerType[] = [];
      try {
        const locationsProm: Promise<AssigneeCustomerType[] | null> | any[] = getAllResultsPaginated(
          getChildrenForAssignments,
          queryVars,
          750,
          'getCustomerSelectableHierarchy',
          'customers',
          true,
          'cache-first',
        );
        data = (await locationsProm) || [];
      } catch (e) {
        if (!retry) fetchData(true);
        else {
          setLoading(false);
          setLoadingError(true);
          setLocationsCount('!');
        }
        return;
      }

      if (!locationsCountMap.size) {
        data.forEach((location: AssigneeCustomerType) => {
          locationsMap.set(location.customer_number, location);
          if (
            location.parent_company_number === customerNumber ||
            location.home_office_number === customerNumber ||
            location.bill_to_customer === customerNumber ||
            location.ship_to_customer === customerNumber
          ) {
            locationsCountMap.set(location.customer_number, location.children_count.st || '1');
          }
        });
      }
      setLoading(false);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let locations: any = [];
  if (props.all) {
    locations = [workingLocation];
  } else if (props.filterByCustomerType) {
    locations = props.locations.map((loc: any) => loc.hash_key.split('1~').pop());
  } else {
    let assignedToParent = false;
    props.locations.forEach((x) => {
      const loc = x.hash_key.split('~').pop();
      const lowest = getLowestLocation(relationship, loc, workingLocation);
      if (lowest === workingLocation) assignedToParent = true;
    });
    if (assignedToParent) {
      locations = [workingLocation];
    } else {
      locations = props.locations.map((loc: any) => loc.hash_key.split('1~').pop());
    }
  }

  return (
    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
      <Button
        size="small"
        variant="text"
        color={loadingError || props.error ? 'danger' : 'primary'}
        style={props.style}
        disabled={props.disabled}
        onClick={() => (props.all && props.mode === 'edit' ? undefined : setAssignedLocationsDialogOpen(true))}
      >
        <StoreMallDirectoryOutlined />
        {!props.hideCount ? <div style={{ width: 50 }}>{locationsCount}</div> : null}
      </Button>

      {assignedLocationsDialogOpen && (
        <AssignDialog
          mode={props.mode}
          locations={locations}
          workingLocation={workingLocation}
          filterByCustomerType={props.filterByCustomerType}
          isMST={props.isMST}
          additionalColumns={props.additionalColumns}
          onClose={() => {
            setAssignedLocationsDialogOpen(false);
          }}
          onUpdate={(locs: AssigneeCustomerType[]) => {
            setAssignedLocationsDialogOpen(false);
            if (props.onUpdate) props.onUpdate(locs);
          }}
          displayCurrentLocation={props.displayCurrentLocation || props.mode === 'view' || props.all}
          plainView={props.plainView}
          singleSelection={props.singleSelection}
          subTitle={props.subTitle}
        />
      )}
    </div>
  );
}
