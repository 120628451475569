/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { Button, Switch, useDialogs } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { TireProfileAxle, TireProfileOVM } from '@michelin/fcp-view-models';
import { cloneDeep } from 'lodash';
import { SOPValidationResult, sopValidation } from 'modules/Tires/sopValidations';
import { AXLES, AxleName, Axles } from 'modules/Tires/types';
import { useFormContext } from 'react-hook-form';

interface props {
  axles: Axles | null;
  axlesOnChange: (axles: Axles | null) => void;
  open: boolean;
  close: () => void;
  setSelectedTab: (tab: number) => void;
  selectedTab: number;
}

export const AssignAxlePositions = ({ axles, axlesOnChange, open, close, setSelectedTab, selectedTab }: props) => {
  const { t } = useTranslation();
  const { confirmDialog } = useDialogs();
  const { getValues, setValue, clearErrors } = useFormContext<TireProfileOVM>();
  const [cloneAxles, setCloneAxles] = useState<Axles | null>(null);

  const [newAxleArray, setNewAxleArray] = useState<string[]>([]);
  const [deleteAxleArray, setDeleteAxleArray] = useState<string[]>([]);

  useEffect(() => {
    if (open) {
      const auxCloneAxles = cloneDeep(axles);
      setCloneAxles(auxCloneAxles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const updateAxles = () => {
    const cloneTireProfile: TireProfileOVM = cloneDeep(getValues());

    if (!cloneTireProfile.axles) return;
    for (const axle of newAxleArray) {
      cloneTireProfile.axles[axle as AxleName] = {
        activity_if_pref_unavailable:
          axle === 'steer'
            ? ['accept_new_tires', 'accept_off_brand_tires']
            : ['accept_new_tires', 'accept_off_brand_tires', 'accept_retreads'],
        pressure_sensor: {
          installed: false,
        },
        tire_pressure_regulator:
          axle !== 'steer'
            ? {
                pressure_regulation_device_installed: false,
              }
            : undefined,
        tire_service_specifications:
          axle === 'steer'
            ? { first_choice: 'replace', second_choice: null, third_choice: null }
            : {
                first_choice: 'repair',
                second_choice: 'replace',
                third_choice: null,
              },
        tire_replacement_priority: [],
        passenger_lt_priority: axle === 'all_axles' ? [] : undefined,
      } as TireProfileAxle;
    }

    for (const axle of deleteAxleArray) {
      cloneTireProfile.axles[axle as AxleName] = undefined;
      clearErrors(`axles.${axle as AxleName}`);
    }
    setValue('axles', cloneTireProfile.axles, { shouldDirty: true });
    let isTabDeleted = false;
    if (cloneAxles) {
      for (const [key] of Object.entries(cloneAxles)) {
        if (cloneAxles[key as keyof Axles].value !== true) {
          const aux = AXLES.find((axle) => axle.name === key);
          if (aux?.index === selectedTab) {
            isTabDeleted = true;
          }
        }
      }
    }
    if (isTabDeleted) setSelectedTab(0);
    setNewAxleArray([]);
    setDeleteAxleArray([]);
    close();
  };

  const onDeleteAxleNoClick = () => {};
  const handleOnClose = () => {
    setNewAxleArray([]);
    setDeleteAxleArray([]);
    close();
  };

  const handleDialogUpdate = async () => {
    if (!cloneAxles) return;

    if (deleteAxleArray.length === 0) {
      updateAxles();
      axlesOnChange(cloneAxles);
      return;
    }

    const svr: SOPValidationResult = await sopValidation('axle', {
      id: getValues().id || '',
      axleTypes: deleteAxleArray,
    });

    const axleList: string[] = [];
    let sopMsg: string = '';
    const confMsg: string = t('Are you sure you want to remove the following axle position(s) from this tire profile?');

    for (let i = 0; i < deleteAxleArray.length; i++) {
      const axleName: string =
        deleteAxleArray[i].toLowerCase() === 'all_axles'
          ? t('All Axles')
          : deleteAxleArray[i].charAt(0).toUpperCase() + deleteAxleArray[i].slice(1);
      axleList.push(t(axleName));
    }

    if (!svr.valid && svr.sopName && svr.sopName.length > 0 && svr.axles && svr.axles.length > 0) {
      const sopAxles: string[] = svr.axles.map((axle) => {
        if (Object.keys(axle)[0] === 'all_axles') return t('All Axles');
        return t(Object.keys(axle)[0].charAt(0).toUpperCase() + Object.keys(axle)[0].slice(1));
      });
      sopMsg = t(
        'An SOP Profile must contain at least one SOP preference within any one of the following SOP Profile pages:  Inflation Pressures, Tread Depth Pull Points, Wheel Balancing.  Un-assigning the {{Axles}} axle position(s) will cause its related SOP Profile titled {{SOPName}} to be invalid.',
        { Axles: sopAxles.join(', '), SOPName: svr.sopName },
      );
    }

    confirmDialog(
      <DialogContent dividers>
        <div>
          <p>{sopMsg}</p>
          <p>{confMsg}</p>
          <p>
            <ul>
              {axleList.map((axle) => (
                <li>{axle}</li>
              ))}
            </ul>
          </p>
        </div>
      </DialogContent>,
      t('Delete Axle(s)'),
      t('Yes'),
      t('No'),
      () => {
        updateAxles();
        axlesOnChange(cloneAxles);
      },
      onDeleteAxleNoClick,
    );
  };

  const handleAxleChange = (axleName: AxleName, value: boolean) => {
    if (!cloneAxles) return;
    const auxCloneAxles: Axles = cloneDeep(cloneAxles);
    let auxNewAxleArray = [...newAxleArray];
    let auxDeleteAxleArray = [...deleteAxleArray];
    auxCloneAxles[axleName as AxleName].dirty = value !== auxCloneAxles[axleName as AxleName].oldValue ? true : false;
    auxCloneAxles[axleName as AxleName].value = value;

    if (value === true) {
      if (deleteAxleArray.includes(axleName)) {
        auxDeleteAxleArray = auxDeleteAxleArray.filter((item) => item !== axleName);
      } else {
        auxNewAxleArray.push(axleName);
      }
      if (axleName === 'all_axles') {
        for (const [key] of Object.entries(cloneAxles)) {
          if (key !== 'all_axles' && auxCloneAxles[key as AxleName].value === true) {
            auxCloneAxles[key as AxleName].dirty =
              auxCloneAxles[key as AxleName].value === auxCloneAxles[key as AxleName].oldValue ? true : false;
            auxCloneAxles[key as AxleName].value = false;
            if (auxNewAxleArray.includes(key)) {
              auxNewAxleArray = auxNewAxleArray.filter((item) => item !== key);
            } else {
              auxDeleteAxleArray.push(key);
            }
          }
        }
      }
      if (axleName !== 'all_axles' && auxCloneAxles.all_axles.value === true) {
        auxCloneAxles.all_axles.dirty =
          auxCloneAxles.all_axles.value === auxCloneAxles.all_axles.oldValue ? true : false;
        auxCloneAxles.all_axles.value = false;
        if (auxNewAxleArray.includes('all_axles')) {
          auxNewAxleArray = auxNewAxleArray.filter((item) => item !== 'all_axles');
        } else {
          auxDeleteAxleArray.push('all_axles');
        }
      }
    }
    if (value === false) {
      if (newAxleArray.includes(axleName)) {
        auxNewAxleArray = newAxleArray.filter((item) => item !== axleName);
      } else {
        auxDeleteAxleArray.push(axleName);
      }
    }
    setNewAxleArray(auxNewAxleArray);
    setDeleteAxleArray(auxDeleteAxleArray);
    setCloneAxles(auxCloneAxles);
  };
  if (!axles) return null;
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={() => handleOnClose()}
      aria-labelledby="customized-dialog-title"
      open={open}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle id="form-dialog-title">{t('Assign Axle Positions')}</DialogTitle>
      <DialogContent dividers>
        <Grid container>
          <Grid item sm={6} lg={6} style={{ paddingRight: 50 }}>
            <Switch
              id="steer"
              checked={cloneAxles?.steer.value}
              checkedLabel=" "
              onChange={(e, v) => handleAxleChange(e.target.id as AxleName, v)}
              label={t('Steer')}
            />
          </Grid>
          <Grid item sm={6} lg={6} style={{ paddingRight: 50 }}>
            <Switch
              id="drive"
              checked={cloneAxles?.drive.value}
              checkedLabel=" "
              onChange={(e, v) => handleAxleChange(e.target.id as AxleName, v)}
              label={t('Drive')}
            />
          </Grid>
          <Grid item sm={6} lg={6} style={{ paddingRight: 50 }}>
            <Switch
              checked={cloneAxles?.trailer.value}
              id="trailer"
              checkedLabel=" "
              onChange={(e, v) => handleAxleChange(e.target.id as AxleName, v)}
              label={t('Trailer')}
            />
          </Grid>
          <Grid item sm={6} lg={6} style={{ paddingRight: 50 }}>
            <Switch
              checked={cloneAxles?.carry.value}
              id="carry"
              checkedLabel=" "
              onChange={(e, v) => handleAxleChange(e.target.id as AxleName, v)}
              label={t('Carry')}
            />
          </Grid>
          <Grid item sm={6} lg={6} style={{ paddingRight: 50 }}>
            <Switch
              checked={cloneAxles?.lift.value}
              id="lift"
              checkedLabel=" "
              onChange={(e, v) => handleAxleChange(e.target.id as AxleName, v)}
              label={t('Lift')}
            />
          </Grid>
          <Grid item sm={6} lg={6} style={{ paddingRight: 50 }}>
            <Switch
              checked={cloneAxles?.pusher.value}
              id="pusher"
              checkedLabel=" "
              onChange={(e, v) => handleAxleChange(e.target.id as AxleName, v)}
              label={t('Pusher')}
            />
          </Grid>
          <Grid item sm={6} lg={6} style={{ paddingRight: 50 }}>
            <Switch
              checked={cloneAxles?.tag.value}
              id="tag"
              checkedLabel=" "
              onChange={(e, v) => handleAxleChange(e.target.id as AxleName, v)}
              label={t('Tag')}
            />
          </Grid>
          <Grid item sm={6} lg={6} style={{ paddingRight: 50 }}>
            <Switch
              checked={cloneAxles?.all_axles.value}
              id="all_axles"
              checkedLabel=" "
              onChange={(e, v) => handleAxleChange(e.target.id as AxleName, v)}
              label={t('All Axles')}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button size="small" style={{ margin: '8px' }} onClick={() => handleDialogUpdate()}>
          {t('Update')}
        </Button>
        <Button size="small" color="danger" style={{ margin: '8px' }} onClick={() => handleOnClose()}>
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
