/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Button, Panel } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { BillingWheelOffInstalled, BillingWheelRequestedInstalledDetails } from 'components/Billing/Preferences/query';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { WheelList } from '../Components/Wheels';
import { BillingProfileContextData } from '../DetailPage';

// TODO This fn is still used by old details page src/components/Billing/Preferences/index.tsx. Deprecate along with it.
export const checkWheelDetails = (t: Function, wheel_details?: BillingWheelRequestedInstalledDetails[]) => {
  let errorMessage;
  if (wheel_details && wheel_details.length > 0) {
    wheel_details.forEach((obj) => {
      if (!obj.requested_details || obj.requested_details.length === 0) {
        errorMessage = t('Requested Details is required for Requested Off/Installed Wheel Details');
      }
    });
  }
  if (errorMessage) {
    return { errorMessage };
  }
  return false;
};

export interface RequestedWheelsDetailProps {
  action: string;
}

export function RequestedWheelsDetail(props: RequestedWheelsDetailProps) {
  const { t } = useTranslation();
  const { action } = props;
  const editFlag = action === 'edit' || action === 'create';
  const [clickedAdd, setClickedAdd] = useState(false);
  const { control } = useFormContext<BillingProfileContextData>();
  const { fields, append, update, remove } = useFieldArray<BillingProfileContextData, 'profile.wheel_details', 'id'>({
    control,
    name: 'profile.wheel_details',
    keyName: 'id',
  });
  const title = t('Requested Off/Installed Wheel Details');
  let isOffAssigned = false;
  let isInstalledAssigned = false;
  if (fields) {
    fields.forEach((wheelDetail: BillingWheelRequestedInstalledDetails) => {
      if (wheelDetail.off_installed === BillingWheelOffInstalled.OFF_WHEELS) {
        isOffAssigned = true;
      } else {
        isInstalledAssigned = true;
      }
    });
  }

  return (
    <Panel
      title={title}
      subtitle={
        <span style={{ fontSize: '0.8em' }}>
          <Alert severity="warning">
            {t(
              'Michelin does not guarantee the applicable dealer from its independent dealer network will provide these Requested Details.',
            )}
          </Alert>
        </span>
      }
      style={{ marginBottom: 15 }}
      control={
        !editFlag || (isOffAssigned && isInstalledAssigned) ? (
          <></>
        ) : (
          <Button color="default" variant="default" onClick={() => setClickedAdd(true)}>
            {t('Add')}
          </Button>
        )
      }
      id="requestedOffInstalled"
    >
      <Grid container>
        <Grid container spacing={2}>
          <WheelList
            list={fields as Array<BillingWheelRequestedInstalledDetails>}
            control={control}
            editFlag={editFlag}
            bothAssigned={isOffAssigned && isInstalledAssigned}
            clickedAdd={clickedAdd}
            setClickedAdd={setClickedAdd}
            onAdd={(data) => {
              append(data);
            }}
            onUpdate={(index, data) => update(index, data)}
            onRemove={remove}
          />
        </Grid>
      </Grid>
    </Panel>
  );
}