/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/styles';
import { LoadingBounce } from '@michelin/acid-components';
import {
  CentralProviderContext,
  SelectedAccountWatchdog,
  usePermissions,
  useTranslation,
} from '@michelin/central-provider';
import { runningOnCanvas } from '@michelin/central-provider/dist/utils/canvas';
import { auth } from 'Auth';
import ErrorCatch from 'ErrorCatch';
import ChangeLog from 'components/ChangeLog';
import { Heading } from 'components/Heading';
import { LocationDetails } from 'components/Location/detailsIndex';
import SOPMain from 'components/SOPs';
import SOPStepper from 'components/SOPs/SOPStepper';
import SideBar from 'components/SideBar';
import { Footer } from 'components/SideBar/Footer';
import CommercialVehiclesDetail from 'components/Vehicles/CommercialVehiclesDetail';
import VehiclesDetail from 'components/Vehicles/VehiclesDetail';
import VehiclesList from 'components/Vehicles/VehiclesList';
import { AccountsModule } from 'modules/Accounts/List';
import { BillingProfileDetailPage } from 'modules/Billing/Profiles/DetailPage';
import { BillingsModule } from 'modules/Billing/index';
import { ContactsModule } from 'modules/Contacts/List';
import { LocationsModule } from 'modules/Locations/List';
import { TireProfileDetails } from 'modules/Tires/Details';
import { TireProfileList } from 'modules/Tires/List/List';
import { WheelProfileDetails } from 'modules/Wheels/Detail';
import { WheelsModule } from 'modules/Wheels/List';
import pns, { PreferenceType, ServiceOfferIds } from 'prefs-and-service-offers';
import { BrowserRouter, Route, RouteComponentProps, Switch, useHistory, useParams } from 'react-router-dom';
import uuid from 'uuid';
import { ContactDetails } from './components/Contact/ContactDetails';
import { BillingProfileDetails } from './modules/Billing/Profiles/Detail';
import Main from './screens/Main';

type ServiceOfferKeys = keyof typeof ServiceOfferIds;

let nextRedirect: string | null = null;
function FixOldURLs() {
  const history = useHistory();
  const permissions = usePermissions();
  const { hasFullAccountAccess } = useContext(CentralProviderContext);
  useParams();
  const oldURLs = (Object.keys(ServiceOfferIds) as ServiceOfferKeys[]).map(
    (k: ServiceOfferKeys) => `/${ServiceOfferIds[k]}/${ServiceOfferIds[k]}`,
  );
  // https://servicesolutions.atlassian.net/browse/GSDTSK-2800
  // Fixes to Prevent B2B Users to end up in a blank screen
  const urlAccountNumber = window.location.pathname.split('/').filter((e) => e)[0];
  const b2bOldURLs = oldURLs.map((e) => `/${urlAccountNumber}${e}`);
  if (urlAccountNumber && urlAccountNumber.length === 7) {
    if (b2bOldURLs.includes(window.location.pathname)) {
      let urlStr = window.location.pathname;
      const urlArray = urlStr.split('/');
      urlArray.pop();
      urlArray.push(PreferenceType.locations);
      urlStr = urlArray.join('/');
      history.push(urlStr);
      nextRedirect = null;
    }
  }
  if (!oldURLs.includes(window.location.pathname)) {
    const accountNumber = window.location.pathname.split('/').join('');
    if (nextRedirect && /^\d+$/.test(accountNumber) && accountNumber.length === 7) {
      history.push(`/${accountNumber}${nextRedirect}`);
      nextRedirect = null;
    }
    return <></>;
  }
  if (hasFullAccountAccess('fleets')) {
    nextRedirect = window.location.pathname;
    history.push('/');
    return <></>;
  }
  const grants = permissions.profile?.grants;
  if (!grants || !grants[0]) return null;
  const accountNumber = grants[0].accounts[0].customer_number;
  history.push(`/${accountNumber}${window.location.pathname}`);
  return <></>;
}

const drawerWidth = 240;

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      zIndex: 1,
      overflow: 'hidden',
    },
    appFrame: {
      position: 'relative',
      display: 'flex',
      width: '100%',
      height: '100%',
    },
    navMenu: {
      display: 'flex',
      flex: '1 1 0%',
      height: '100%',
      maxHeight: 'calc(100vh - 5rem)',
    },
    navBar: {
      height: '100%',
      borderRight: '1px solid rgb(231,231,231)',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    drawerPaper: {
      width: drawerWidth,
    },

    privateRouteContainer: {
      display: 'flex',
      height: '100%',
      paddingTop: !runningOnCanvas() ? 65 : undefined,
    },
    content: {
      width: '100%',
      height: '100%',
    },
    mainContent: {
      flexGrow: 1,
      paddingTop: 0,
      overflowX: 'auto',
    },
  }),
);

export interface ICustomerBreadcrumbPathData {
  order: number;
  hash_key: string;
  range_key: string;
  customer_name: string;
  customer_type: string;
  extrnl_cust_id: string;
  customer_addr1: string;
  customer_addr2: string;
  customer_country: string;
  customer_city: string;
  customer_state: string;
  customer_zip: string;
  parent_company_number: string;
  home_office_number: string;
  bill_to_customer: string;
  ship_to_customer: string;
  phone_number: string;
}

interface MichelinRouteProps {
  path: string;
  sidebar: boolean;
  component: any;
  subscriptionId?: string;
  props?: any;
  customerNumber?: string;
  customerType?: string;
  label?: string;
  location?: {
    state?: any;
  };
  key?: string;
  requirePermission?: string;
}

function MichelinRoute(props: MichelinRouteProps) {
  const classes = useStyles();
  const permissions = usePermissions();
  const { accountNumber } = useParams<{ accountNumber: string }>();
  const history = useHistory();
  const [authInitialized, setAuthInitialized] = useState(!!auth.getAccountTypeShort());

  useEffect(() => {
    const callback = () => {
      if (authInitialized) return;
      if (!permissions.location?.customer_number) return;
      setAuthInitialized(!!auth.getAccountTypeShort());
    };
    auth.suscribeUpdates(callback);
    return () => auth.unSuscribeUpdates(callback);
  }, [setAuthInitialized, authInitialized, permissions.location]);

  if (props.requirePermission && permissions.location && !permissions.allowsAction(props.requirePermission)) {
    history.push(`/${accountNumber || ''}`);
    return null;
  }

  return (
    <Route
      key={props.key}
      exact
      path={props.path}
      render={(routerProps: RouteComponentProps) => {
        const componentProps = props.props || {};
        return (
          <div className={classes.root}>
            <div className={classes.appFrame}>
              <div className={classes.content}>
                <div className={classes.privateRouteContainer}>
                  <Heading />
                  {!authInitialized && <LoadingBounce style={{ height: '80vh' }} />}
                  {authInitialized && permissions.location && props.sidebar ? <SideBar /> : <Footer />}
                  <ErrorCatch>
                    <main className={classes.mainContent}>
                      {authInitialized && permissions.location && props.component ? (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <props.component {...routerProps} {...componentProps} />
                      ) : null}
                    </main>
                  </ErrorCatch>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}

export default function Routes() {
  const { t } = useTranslation();
  const serviceOffers = pns.GetServiceOffers();

  return (
    <SelectedAccountWatchdog>
      <BrowserRouter>
        <FixOldURLs />
        <Switch>
          <MichelinRoute
            path={`/:selectedAccount${serviceOffers[0].path}`}
            label={`${serviceOffers[0].shortTitle}`}
            component={Main}
            props={{ routeOfferData: serviceOffers[0] }}
            sidebar={false}
          />
          <MichelinRoute
            path={`/:selectedAccount${serviceOffers[1].path}`}
            label={`${serviceOffers[1].shortTitle}`}
            component={Main}
            props={{ routeOfferData: serviceOffers[1] }}
            sidebar={false}
          />
          <MichelinRoute
            path={`/:selectedAccount${serviceOffers[2].path}`}
            label={`${serviceOffers[2].shortTitle}`}
            component={Main}
            props={{ routeOfferData: serviceOffers[2] }}
            sidebar={false}
          />

          {/* ALL PREFERENCES */}
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/locations`}
            label={t('Locations List')}
            component={LocationsModule}
            sidebar
          />
          <MichelinRoute
            key={`1${uuid()}`}
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/locationDetails/:locationId/:tab/:action/`}
            label={t('View Location')}
            component={LocationDetails}
            sidebar
          />
          <MichelinRoute
            key={`2${uuid()}`}
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/locationDetail/:locationId/:tab/:action/`}
            label={t('View Location')}
            component={LocationDetails}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/contacts/:contactId/edit`}
            label={t('Edit Contact')}
            component={ContactDetails}
            props={{ action: 'edit' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/contacts/:contactId/view`}
            label={t('View Contact')}
            component={ContactDetails}
            props={{ action: 'view', disableAssignLocations: false }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/contacts/create`}
            label={t('Create Contact')}
            component={ContactDetails}
            props={{ action: 'create' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/contacts`}
            label={t('Contacts List')}
            component={ContactsModule}
            sidebar
          />

          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/accounts/add`}
            label={t('Add Account')}
            component={AccountsModule}
            props={{ action: 'add', serviceOfferIds: ServiceOfferIds.allPreferences }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/accounts/edit/:accountId`}
            label={t('Edit Account')}
            component={AccountsModule}
            props={{ action: 'edit', serviceOfferIds: ServiceOfferIds.allPreferences }}
            sidebar
          />

          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/accounts`}
            label={t('Accounts List')}
            component={AccountsModule}
            props={{ action: 'list', serviceOfferIds: ServiceOfferIds.allPreferences }}
            sidebar
          />

          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/billings-playground`}
            label={t('Billing Playground')}
            component={BillingProfileDetails}
            props={{ selectedTab: 0 }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/billing-future/:service/:profile/create`}
            label={t('Create Billing Profile')}
            component={BillingProfileDetailPage}
            props={{ action: 'create' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/billing-future/:profileId/view`}
            label={t('View Billing Profile')}
            component={BillingProfileDetailPage}
            props={{ action: 'view' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/billing-future/:profileId/edit`}
            label={t('Edit Billing Profile')}
            component={BillingProfileDetailPage}
            props={{ action: 'edit' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/billings/profiles`}
            label={t('Billing List')}
            component={BillingsModule}
            props={{ selectedTab: 0 }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/billings/requirements`}
            label={t('View Billing Requirements')}
            component={BillingsModule}
            props={{ selectedTab: 1 }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/billings/receiptnotes`}
            label={t('View Billing Receipt Notes')}
            component={BillingsModule}
            props={{ selectedTab: 2 }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/billing/create`}
            label={t('Create Billing Profile')}
            component={BillingsModule}
            props={{ selectedTab: 0, action: 'create' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/billing/:profileId/edit`}
            label={t('Edit Billing Profile')}
            component={BillingsModule}
            props={{ selectedTab: 0, action: 'edit' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/billing/:profileId`}
            label={t('View Billing Profile')}
            component={BillingsModule}
            props={{ selectedTab: 0, action: 'view' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/billings`}
            label={t('Billing List')}
            component={BillingsModule}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/tire-profile/create`}
            props={{ action: 'add' }}
            label={t('Create Tire Profile')}
            component={TireProfileDetails}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/tire-profile/:profileId/edit`}
            props={{ action: 'edit' }}
            label={t('Edit Tire Profile')}
            component={TireProfileDetails}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/tire-profile/:profileId/view`}
            props={{ action: 'view' }}
            label={t('View Tire Profile')}
            component={TireProfileDetails}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/tire-profile/:breadcrumbCustomerNumber1/:breadcrumbCustomerNumber2/:breadcrumbCustomerNumber3/:breadcrumbCustomerNumber4`}
            props={{ action: 'list' }}
            label={t('Tire Profile List')}
            component={TireProfileList}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/tire-profile/:breadcrumbCustomerNumber1/:breadcrumbCustomerNumber2/:breadcrumbCustomerNumber3`}
            props={{ action: 'list' }}
            label={t('Tire Profile List')}
            component={TireProfileList}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/tire-profile/:breadcrumbCustomerNumber1/:breadcrumbCustomerNumber2`}
            props={{ action: 'list' }}
            label={t('Tire Profile List')}
            component={TireProfileList}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/tire-profile/:breadcrumbCustomerNumber1`}
            props={{ action: 'list' }}
            label={t('Tire Profile List')}
            component={TireProfileList}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/tire-profile`}
            props={{ action: 'list' }}
            label={t('Tire Profile List')}
            component={TireProfileList}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/wheel-details/create`}
            label={t('Create Wheel Profile')}
            component={WheelProfileDetails}
            props={{ action: 'create' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/wheel-details/view/:profileId`}
            label={t('View Wheel Details')}
            component={WheelProfileDetails}
            props={{ action: 'view' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/wheel-details/edit/:profileId`}
            label={t('Edit Wheel Details')}
            component={WheelProfileDetails}
            props={{ action: 'edit' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/wheels`}
            label={t('Wheel Profile List')}
            component={WheelsModule}
            props={{ action: 'list' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/sop/procedures/view`}
            props={{ action: 'procedures-view' }}
            label={t('Standard Operating Procedures')}
            component={SOPMain}
            sidebar
          />

          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/sop/procedures/edit`}
            props={{ action: 'procedures-edit' }}
            label={t('Standard Operating Procedures')}
            component={SOPMain}
            sidebar
          />

          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/sop/list`}
            props={{ action: 'list' }}
            label={t('Standard Operating Procedures')}
            component={SOPMain}
            sidebar
          />

          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/sop/general-info/add`}
            props={{ action: 'add', step: 0 }}
            label={t('Add Standard Operating Procedure')}
            component={SOPStepper}
            sidebar
          />

          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/sop/inflation-pressures/add`}
            props={{ action: 'add', step: 1 }}
            label={t('Add Standard Operating Procedure')}
            component={SOPStepper}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/sop/tread-depth-pull-points/add`}
            props={{ action: 'add', step: 2 }}
            label={t('Add Standard Operating Procedure')}
            component={SOPStepper}
            sidebar
          />

          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/sop/wheel-balancing/add`}
            props={{ action: 'add', step: 3 }}
            label={t('Add Standard Operating Procedure')}
            component={SOPStepper}
            sidebar
          />

          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/sop/brand-preferences/add`}
            props={{ action: 'add', step: 4 }}
            label={t('Add Standard Operating Procedure')}
            component={SOPStepper}
            sidebar
          />

          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/sop/:profileId/general-info/edit`}
            props={{ action: 'edit', step: 0 }}
            label={t('Add Standard Operating Procedure')}
            component={SOPStepper}
            sidebar
          />

          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/sop/:profileId/inflation-pressures/edit`}
            props={{ action: 'edit', step: 1 }}
            label={t('Add Standard Operating Procedure')}
            component={SOPStepper}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/sop/:profileId/tread-depth-pull-points/edit`}
            props={{ action: 'edit', step: 2 }}
            label={t('Add Standard Operating Procedure')}
            component={SOPStepper}
            sidebar
          />

          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/sop/:profileId/wheel-balancing/edit`}
            props={{ action: 'edit', step: 3 }}
            label={t('Add Standard Operating Procedure')}
            component={SOPStepper}
            sidebar
          />

          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/sop/:profileId/brand-preferences/edit`}
            props={{ action: 'edit', step: 4 }}
            label={t('Add Standard Operating Procedure')}
            component={SOPStepper}
            sidebar
          />

          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/sop/:profileId/general-info/view`}
            props={{ action: 'view', step: 0 }}
            label={t('Add Standard Operating Procedure')}
            component={SOPStepper}
            sidebar
          />

          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/sop/:profileId/inflation-pressures/view`}
            props={{ action: 'view', step: 1 }}
            label={t('Add Standard Operating Procedure')}
            component={SOPStepper}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/sop/:profileId/tread-depth-pull-points/view`}
            props={{ action: 'view', step: 2 }}
            label={t('Add Standard Operating Procedure')}
            component={SOPStepper}
            sidebar
          />

          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/sop/:profileId/wheel-balancing/view`}
            props={{ action: 'view', step: 3 }}
            label={t('Add Standard Operating Procedure')}
            component={SOPStepper}
            sidebar
          />

          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/sop/:profileId/brand-preferences/view`}
            props={{ action: 'view', step: 4 }}
            label={t('Add Standard Operating Procedure')}
            component={SOPStepper}
            sidebar
          />

          <MichelinRoute
            path={`/${ServiceOfferIds.allPreferences}/vehicles/add`}
            label={t('Add Vehicle')}
            component={VehiclesDetail}
            props={{ action: 'add', type: 'urban' }}
            sidebar
          />
          <MichelinRoute
            path={`/${ServiceOfferIds.allPreferences}/vehicles/:vehicleId/view`}
            label={t('View Vehicle')}
            component={VehiclesDetail}
            props={{ action: 'view', type: 'urban' }}
            sidebar
          />
          <MichelinRoute
            path={`/${ServiceOfferIds.allPreferences}/vehicles/:vehicleId/edit`}
            label={t('Edit Vehicle')}
            component={VehiclesDetail}
            props={{ action: 'edit', type: 'urban' }}
            sidebar
          />
          <MichelinRoute
            path={`/${ServiceOfferIds.allPreferences}/vehicles`}
            label={t('Vehicles')}
            component={VehiclesList}
            props={{ type: 'urban' }}
            sidebar
          />

          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/urban-vehicles/add`}
            label={t('Add Vehicle')}
            component={VehiclesDetail}
            props={{ action: 'add', type: 'urban' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/urban-vehicles/:vehicleId/view`}
            label={t('View Vehicle')}
            component={VehiclesDetail}
            props={{ action: 'view', type: 'urban' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/urban-vehicles/:vehicleId/edit`}
            label={t('Edit Vehicle')}
            component={VehiclesDetail}
            props={{ action: 'edit', type: 'urban' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/urban-vehicles`}
            label={t('Vehicles')}
            component={VehiclesList}
            props={{ type: 'urban' }}
            sidebar
          />

          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/commercial-vehicles/add`}
            label={t('Add Vehicle')}
            component={CommercialVehiclesDetail}
            props={{ action: 'add', type: 'commercial' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/commercial-vehicles/:vehicleId/view`}
            label={t('View Vehicle')}
            component={CommercialVehiclesDetail}
            props={{ action: 'view', type: 'commercial' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/commercial-vehicles/:vehicleId/edit`}
            label={t('Edit Vehicle')}
            component={CommercialVehiclesDetail}
            props={{ action: 'edit', type: 'commercial' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/commercial-vehicles`}
            label={t('Vehicles')}
            component={VehiclesList}
            props={{ type: 'commercial' }}
            sidebar
          />

          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.allPreferences}/change-history/list`}
            label={t('Change Log')}
            component={ChangeLog}
            props={{ type: 'changelog' }}
            sidebar
          />

          {/* ONCALL-TIRE PREFERENCES */}
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/locations`}
            label={t('Locations List')}
            component={LocationsModule}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/locationDetails/:locationId/:tab/:action`}
            label={t('View Location')}
            component={LocationDetails}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/locationDetail/:locationId/:tab/:action`}
            label={t('View Location')}
            component={LocationDetails}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/contacts/:contactId/edit`}
            label={t('Edit Contact')}
            component={ContactDetails}
            props={{ action: 'edit' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/contacts/:contactId/view`}
            label={t('View Contact')}
            component={ContactDetails}
            props={{ action: 'view' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/contacts/create`}
            label={t('Create Contact')}
            component={ContactDetails}
            props={{ action: 'create' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/contacts`}
            label={t('Contacts List')}
            component={ContactsModule}
            sidebar
          />

          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/accounts/add`}
            label={t('Add Account')}
            component={AccountsModule}
            props={{ action: 'add', serviceOfferIds: ServiceOfferIds.onCallTire }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/accounts/edit/:accountId`}
            label={t('Edit Account')}
            component={AccountsModule}
            props={{ action: 'edit', serviceOfferIds: ServiceOfferIds.onCallTire }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/accounts`}
            label={t('Accounts List')}
            component={AccountsModule}
            props={{ action: 'list', serviceOfferIds: ServiceOfferIds.onCallTire }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/billing-future/:service/:profile/create`}
            label={t('Create Billing Profile')}
            component={BillingProfileDetailPage}
            props={{ action: 'create' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/billing-future/:profileId/view`}
            label={t('View Billing Profile')}
            component={BillingProfileDetailPage}
            props={{ action: 'view' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/billing-future/:profileId/edit`}
            label={t('Edit Billing Profile')}
            component={BillingProfileDetailPage}
            props={{ action: 'edit' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/billings/profiles`}
            label={t('Billing List')}
            component={BillingsModule}
            props={{ selectedTab: 0 }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/billings/requirements`}
            label={t('View Billing Requirements')}
            component={BillingsModule}
            props={{ selectedTab: 1 }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/billings/receiptnotes`}
            label={t('View Billing Receipt Notes')}
            component={BillingsModule}
            props={{ selectedTab: 2 }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/billing/create`}
            label={t('Create Billing Profile')}
            component={BillingsModule}
            props={{ selectedTab: 0, action: 'create' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/billing/:profileId/edit`}
            label={t('Edit Billing Profile')}
            component={BillingsModule}
            props={{ selectedTab: 0, action: 'edit' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/billing/:profileId`}
            label={t('View Billing Profile')}
            component={BillingsModule}
            props={{ selectedTab: 0, action: 'view' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/billings`}
            label={t('Billing List')}
            component={BillingsModule}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/tire-profile/create`}
            props={{ action: 'add' }}
            label={t('Create Tire Profile')}
            component={TireProfileDetails}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/tire-profile/:profileId/edit`}
            props={{ action: 'edit' }}
            label={t('Edit Tire Profile')}
            component={TireProfileDetails}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/tire-profile/:profileId/view`}
            props={{ action: 'view' }}
            label={t('View Tire Profile')}
            component={TireProfileDetails}
            sidebar
          />

          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/tire-profile/:breadcrumbCustomerNumber1/:breadcrumbCustomerNumber2/:breadcrumbCustomerNumber3/:breadcrumbCustomerNumber4`}
            props={{ action: 'list' }}
            label={t('Tire Profile List')}
            component={TireProfileList}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/tire-profile/:breadcrumbCustomerNumber1/:breadcrumbCustomerNumber2/:breadcrumbCustomerNumber3`}
            props={{ action: 'list' }}
            label={t('Tire Profile List')}
            component={TireProfileList}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/tire-profile/:breadcrumbCustomerNumber1/:breadcrumbCustomerNumber2`}
            props={{ action: 'list' }}
            label={t('Tire Profile List')}
            component={TireProfileList}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/tire-profile/:breadcrumbCustomerNumber1`}
            props={{ action: 'list' }}
            label={t('Tire Profile List')}
            component={TireProfileList}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/tire-profile`}
            props={{ action: 'list' }}
            label={t('Tire Profile List')}
            component={TireProfileList}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/wheel-details/create`}
            label={t('Create Wheel Profile')}
            component={WheelProfileDetails}
            props={{ action: 'create' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/wheel-details/view/:profileId`}
            label={t('View Wheel Details')}
            component={WheelProfileDetails}
            props={{ action: 'view' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/wheel-details/edit/:profileId`}
            label={t('Edit Wheel Details')}
            component={WheelProfileDetails}
            props={{ action: 'edit' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/wheels`}
            label={t('Wheel Profile List')}
            component={WheelsModule}
            props={{ action: 'list' }}
            sidebar
          />

          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.onCallTire}/change-history/list`}
            label={t('Change Log')}
            component={ChangeLog}
            props={{ type: 'changelog' }}
            sidebar
          />

          {/* BULK-ORDER PREFERENCES */}
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/locations`}
            label={t('Locations List')}
            component={LocationsModule}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/locationDetails/:locationId/:tab/:action`}
            label={t('View Location')}
            component={LocationDetails}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/locationDetail/:locationId/:tab/:action`}
            label={t('View Location')}
            component={LocationDetails}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/contacts/:contactId/edit`}
            label={t('Edit Contact')}
            component={ContactDetails}
            props={{ action: 'edit' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/contacts/:contactId/view`}
            label={t('View Contact')}
            component={ContactDetails}
            props={{ action: 'view' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/contacts/create`}
            label={t('Create Contact')}
            component={ContactDetails}
            props={{ action: 'create' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/contacts`}
            label={t('Contacts List')}
            component={ContactsModule}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/billing-future/:service/:profile/create`}
            label={t('Create Billing Profile')}
            component={BillingProfileDetailPage}
            props={{ action: 'create' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/billing-future/:profileId/view`}
            label={t('View Billing Profile')}
            component={BillingProfileDetailPage}
            props={{ action: 'view' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/billing-future/:profileId/edit`}
            label={t('Edit Billing Profile')}
            component={BillingProfileDetailPage}
            props={{ action: 'edit' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/billings/profiles`}
            label={t('Billing List')}
            component={BillingsModule}
            props={{ selectedTab: 0 }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/billings/requirements`}
            label={t('View Billing Requirements')}
            component={BillingsModule}
            props={{ selectedTab: 1 }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/billings/receiptnotes`}
            label={t('View Billing Receipt Notes')}
            component={BillingsModule}
            props={{ selectedTab: 2 }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/billing/create`}
            label={t('Create Billing Profile')}
            component={BillingsModule}
            props={{ selectedTab: 0, action: 'create' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/billing/:profileId/edit`}
            label={t('Edit Billing Profile')}
            component={BillingsModule}
            props={{ selectedTab: 0, action: 'edit' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/billing/:profileId`}
            label={t('View Billing Profile')}
            component={BillingsModule}
            props={{ selectedTab: 0, action: 'view' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/billings`}
            label={t('Billing List')}
            component={BillingsModule}
            sidebar
          />

          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/tire-profile/create`}
            props={{ action: 'add' }}
            label={t('Create Tire Profile')}
            component={TireProfileDetails}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/tire-profile/:profileId/edit`}
            props={{ action: 'edit' }}
            label={t('Edit Tire Profile')}
            component={TireProfileDetails}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/tire-profile/:profileId/view`}
            props={{ action: 'view' }}
            label={t('View Tire Profile')}
            component={TireProfileDetails}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/tire-profile/:breadcrumbCustomerNumber1/:breadcrumbCustomerNumber2/:breadcrumbCustomerNumber3/:breadcrumbCustomerNumber4`}
            props={{ action: 'list' }}
            label={t('Tire Profile List')}
            component={TireProfileList}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/tire-profile/:breadcrumbCustomerNumber1/:breadcrumbCustomerNumber2/:breadcrumbCustomerNumber3`}
            props={{ action: 'list' }}
            label={t('Tire Profile List')}
            component={TireProfileList}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/tire-profile/:breadcrumbCustomerNumber1/:breadcrumbCustomerNumber2`}
            props={{ action: 'list' }}
            label={t('Tire Profile List')}
            component={TireProfileList}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/tire-profile/:breadcrumbCustomerNumber1`}
            props={{ action: 'list' }}
            label={t('Tire Profile List')}
            component={TireProfileList}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/tire-profile`}
            props={{ action: 'list' }}
            label={t('Tire Profile List')}
            component={TireProfileList}
            sidebar
          />

          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/commercial-vehicles/add`}
            label={t('Add Vehicle')}
            component={CommercialVehiclesDetail}
            props={{ action: 'add', type: 'commercial' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/commercial-vehicles/:vehicleId/view`}
            label={t('View Vehicle')}
            component={CommercialVehiclesDetail}
            props={{ action: 'view', type: 'commercial' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/commercial-vehicles/:vehicleId/edit`}
            label={t('Edit Vehicle')}
            component={CommercialVehiclesDetail}
            props={{ action: 'edit', type: 'commercial' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/commercial-vehicles`}
            label={t('Vehicles')}
            component={VehiclesList}
            props={{ type: 'commercial' }}
            sidebar
          />

          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/urban-vehicles/add`}
            label={t('Add Vehicle')}
            component={VehiclesDetail}
            props={{ action: 'add', type: 'urban' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/urban-vehicles/:vehicleId/view`}
            label={t('View Vehicle')}
            component={VehiclesDetail}
            props={{ action: 'view', type: 'urban' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/urban-vehicles/:vehicleId/edit`}
            label={t('Edit Vehicle')}
            component={VehiclesDetail}
            props={{ action: 'edit', type: 'urban' }}
            sidebar
          />
          <MichelinRoute
            path={`/:selectedAccount/${ServiceOfferIds.bulkOrder}/urban-vehicles`}
            label={t('Vehicles')}
            component={VehiclesList}
            props={{ type: 'urban' }}
            sidebar
          />

          {/* COMMON */}
          <MichelinRoute path="/:selectedAccount" label={t('Home')} component={Main} sidebar={false} />
          <MichelinRoute path="/" label={t('Home')} component={Main} sidebar={false} />
          <MichelinRoute path="" label={t('Home')} component={Main} sidebar={false} />
        </Switch>
      </BrowserRouter>
    </SelectedAccountWatchdog>
  );
}
