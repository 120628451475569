/* eslint-disable no-param-reassign */
import React, { ChangeEvent } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { Button, IconButton, Panel, Select, Switch, TextField } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { getOptions } from '@michelin/select-options-provider';
import { TranslateCallback } from 'components/Util';
import uuid from 'uuid';
import Autocomplete from '../../../Autocomplete';
import {
  BillingPreferences,
  BillingTireOffInstalled,
  BillingTireRequestedDetails,
  BillingTireRequestedInstalledDetails,
  BillingWheelOffInstalled,
  BillingWheelRequestedDetails,
  BillingWheelRequestedInstalledDetails,
} from '../query';

interface RequestedOffInstalledDetailsProps {
  isTire: boolean;
  profile: BillingPreferences;
  editFunction: Function;
  setModifiedFlag: (modifiedFlag: boolean) => void;
  editFlag: boolean;
}

function createRow(
  options_requested_details: Array<Select.Option>,
  key: number,
  isTire: boolean,
  setModifiedFlag: Function,
  changeProfile: Function,
  profile: BillingPreferences,
  isEdit: boolean,
  canEdit: boolean,
  t: TranslateCallback,
) {
  const details = isTire ? profile.tire_details : profile.wheel_details;
  const workingDetail = details && details.length > key ? details[key] : undefined;
  const seletedCasingAge =
    isTire && workingDetail && workingDetail.requested_details ? workingDetail.requested_details : [];

  const valueCheck = (p: BillingPreferences, isATire: boolean, k: number) => {
    if (isATire && p.tire_details) {
      return p.tire_details[k];
    }
    if (p.wheel_details) {
      return p.wheel_details[k];
    }
    return { id: uuid.v4() };
  };

  const valueCheckTire = (p: BillingPreferences, isATire: boolean, k: number) => {
    if (isATire && p.tire_details) {
      return p.tire_details[k];
    }
    return { id: uuid.v4() };
  };

  const checkMe = (detailValue: any, isATire: boolean, editing: boolean) => {
    if (isATire) {
      return !editing || !detailValue.includes(BillingTireRequestedDetails.OTHER);
    }
    return !editing || !detailValue.includes(BillingWheelRequestedDetails.OTHER);
  };

  const deleteMe = (index: number, p: BillingPreferences, isATire: boolean, setProfile: Function) => {
    if (isATire && p.tire_details) {
      p.tire_details.splice(index, 1);
      setModifiedFlag(true);
      setProfile({ ...p });
    } else if (p.wheel_details) {
      p.wheel_details.splice(index, 1);
      setModifiedFlag(true);
      setProfile({ ...p });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item sm={12} md={2} style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Switch
          checked={
            valueCheck(profile, isTire, key).off_installed === BillingTireOffInstalled.INSTALLED_TIRES ||
            valueCheck(profile, isTire, key).off_installed === BillingWheelOffInstalled.INSTALLED_WHEELS
          }
          disabled={!isEdit || !canEdit}
          label=""
          checkedLabel={t('Installed')}
          uncheckedLabel={t('Off')}
          labelPosition="top"
          onChange={(event: ChangeEvent<any>) => {
            if (isTire && profile.tire_details) {
              profile.tire_details[key].off_installed = event.target.checked
                ? BillingTireOffInstalled.INSTALLED_TIRES
                : BillingTireOffInstalled.OFF_TIRES;
              setModifiedFlag(true);
              changeProfile({ ...profile });
            } else if (profile.wheel_details) {
              profile.wheel_details[key].off_installed = event.target.checked
                ? BillingWheelOffInstalled.INSTALLED_WHEELS
                : BillingWheelOffInstalled.OFF_WHEELS;
              setModifiedFlag(true);
              changeProfile({ ...profile });
            }
          }}
        />
      </Grid>

      <Grid item sm={12} md={5} style={{ display: 'flex', alignItems: 'flex-end' }}>
        <div style={{ width: '100%' }}>
          <Autocomplete
            id="autocompletepp"
            multiple
            variant="standard"
            label={t('Requested Details')}
            disableClearable
            disableCloseOnSelect
            placeholder=""
            onChange={(value: any) => {
              if (isTire && profile.tire_details) {
                profile.tire_details[key].requested_details = value;
                setModifiedFlag(true);
                changeProfile({ ...profile });
              } else if (profile.wheel_details) {
                profile.wheel_details[key].requested_details = value;
                setModifiedFlag(true);
                changeProfile({ ...profile });
              }
            }}
            suggestions={options_requested_details}
            values={valueCheck(profile, isTire, key).requested_details}
            readOnly={!isEdit}
          />
        </div>
      </Grid>
      <Grid item sm={12} md={2} style={{ display: 'flex', alignItems: 'flex-end' }}>
        <TextField
          value={valueCheck(profile, isTire, key).other}
          label={t('Other')}
          key={workingDetail && workingDetail.id && `${workingDetail.id}OTHER`}
          readOnly={checkMe(valueCheck(profile, isTire, key).requested_details, isTire, isEdit)}
          onChange={(event: ChangeEvent<any>) => {
            if (isTire && profile.tire_details) {
              profile.tire_details[key].other = event.target.value;
              setModifiedFlag(true);
              changeProfile({ ...profile });
            } else if (profile.wheel_details) {
              profile.wheel_details[key].other = event.target.value;
              setModifiedFlag(true);
              changeProfile({ ...profile });
            }
          }}
        />
      </Grid>
      <Grid item sm={12} md={2} style={{ display: 'flex', alignItems: 'flex-end' }}>
        {seletedCasingAge && seletedCasingAge.includes(BillingTireRequestedDetails.TIRE_CASING_TYPE as never) && (
          <Select
            key={workingDetail && workingDetail.id && `${workingDetail.id}CASING_TYPE`}
            style={{ minWidth: '100px', width: '100%' }}
            value={valueCheckTire(profile, isTire, key).billing_tire_casing_age}
            onChange={(event: ChangeEvent<any>) => {
              if (isTire && profile.tire_details) {
                profile.tire_details[key].billing_tire_casing_age = event.target.value;
                setModifiedFlag(true);
                changeProfile({ ...profile });
              }
            }}
            label={t('Casing Details')}
            options={getOptions('tire_casing_age')}
            readOnly={!isEdit}
          />
        )}
      </Grid>
      <Grid item sm={12} md={1} style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          aria-label="delete"
          onClick={() => {
            deleteMe(key, profile, isTire, changeProfile);
          }}
          size="small"
          variant="default"
          color="primary"
          disabled={!isEdit}
        >
          <Delete fontSize="small" />
        </IconButton>
      </Grid>
    </Grid>
  );
}

export default function RequestedOffInstalledDetails(props: RequestedOffInstalledDetailsProps) {
  const { isTire, profile, editFlag, editFunction, setModifiedFlag } = props;
  const { t } = useTranslation();
  const title = isTire ? t('Requested Off/Installed Tire Details') : t('Requested Off/Installed Wheel Details');
  const options_requested_details = isTire
    ? getOptions('tire_requested_details')
    : getOptions('wheel_requested_details');
  let isOffAssinged: boolean = false;
  let isInstalledAssigned: boolean = false;
  const rows = Array<JSX.Element>();

  if (isTire) {
    if (profile.tire_details) {
      // Check already assigned
      profile.tire_details.forEach((tireDetail: BillingTireRequestedInstalledDetails) => {
        if (tireDetail.off_installed === BillingTireOffInstalled.OFF_TIRES) {
          isOffAssinged = true;
        } else {
          isInstalledAssigned = true;
        }
      });
      // Create rows
      profile.tire_details.forEach((tireDetail: BillingTireRequestedInstalledDetails, index: number) =>
        rows.push(
          createRow(
            options_requested_details,
            index,
            isTire,
            setModifiedFlag,
            editFunction,
            profile,
            editFlag,
            !isOffAssinged || !isInstalledAssigned,
            t,
          ),
        ),
      );
    }
  } else if (profile.wheel_details) {
    profile.wheel_details.forEach((wheelDetail: BillingWheelRequestedInstalledDetails) => {
      if (wheelDetail.off_installed === BillingWheelOffInstalled.OFF_WHEELS) {
        isOffAssinged = true;
      } else {
        isInstalledAssigned = true;
      }
    });

    profile.wheel_details.forEach((wheelDetail: BillingWheelRequestedInstalledDetails, index: number) => {
      rows.push(
        createRow(
          options_requested_details,
          index,
          isTire,
          setModifiedFlag,
          editFunction,
          profile,
          editFlag,
          !isOffAssinged || !isInstalledAssigned,
          t,
        ),
      );
    });
  }

  const addRow = () => {
    if (isTire) {
      if (!profile.tire_details || profile.tire_details.length === 0) {
        profile.tire_details = [];
        profile.tire_details.push({
          id: uuid.v4(),
          requested_details: [],
          off_installed: BillingTireOffInstalled.OFF_TIRES,
        });
        setModifiedFlag(true);
        editFunction({ ...profile });
      } else if (profile.tire_details) {
        const lastTireDetails = profile.tire_details[profile.tire_details.length - 1];
        if (lastTireDetails && lastTireDetails.requested_details && lastTireDetails.requested_details.length > 0) {
          if (
            lastTireDetails.off_installed === BillingTireOffInstalled.OFF_TIRES ||
            lastTireDetails.off_installed === undefined
          ) {
            profile.tire_details.push({
              id: uuid.v4(),
              requested_details: [],
              off_installed: BillingTireOffInstalled.INSTALLED_TIRES,
            });
          } else {
            profile.tire_details.push({
              id: uuid.v4(),
              requested_details: [],
              off_installed: BillingTireOffInstalled.OFF_TIRES,
            });
          }
          setModifiedFlag(true);
          editFunction({ ...profile });
        }
      }
    } else if (!profile.wheel_details || profile.wheel_details.length === 0) {
      profile.wheel_details = [];
      profile.wheel_details.push({
        id: uuid.v4(),
        requested_details: [],
        off_installed: BillingWheelOffInstalled.OFF_WHEELS,
      });
      setModifiedFlag(true);
      editFunction({ ...profile });
    } else if (profile.wheel_details) {
      const lastWheelDetails = profile.wheel_details[profile.wheel_details.length - 1];
      if (lastWheelDetails && lastWheelDetails.requested_details && lastWheelDetails.requested_details.length > 0) {
        if (
          lastWheelDetails.off_installed === BillingWheelOffInstalled.OFF_WHEELS ||
          lastWheelDetails.off_installed === undefined
        ) {
          profile.wheel_details.push({
            id: uuid.v4(),
            requested_details: [],
            off_installed: BillingWheelOffInstalled.INSTALLED_WHEELS,
          });
        } else {
          profile.wheel_details.push({
            id: uuid.v4(),
            requested_details: [],
            off_installed: BillingWheelOffInstalled.OFF_WHEELS,
          });
        }
        setModifiedFlag(true);
        editFunction({ ...profile });
      }
    }
  };

  return (
    <Panel
      title={title}
      subtitle={
        <span style={{ fontSize: '0.8em' }}>
          <Alert severity="warning">
            {t(
              'Michelin does not guarantee the applicable dealer from its independent dealer network will provide these Requested Details.',
            )}
          </Alert>
        </span>
      }
      style={{ marginBottom: 15 }}
      control={
        !editFlag || (isOffAssinged && isInstalledAssigned) ? (
          <></>
        ) : (
          <Button color="default" variant="default" onClick={addRow}>
            {t('Add')}
          </Button>
        )
      }
      id="requestedOffInstalled"
    >
      <Grid container>
        {!rows || !rows.length ? (
          <Typography style={{ color: '#999999', width: '100%', textAlign: 'center' }}>{t('No data...')}</Typography>
        ) : (
          rows
        )}
      </Grid>
    </Panel>
  );
}
