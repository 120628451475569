import React from 'react';
import { Container } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import LastModified from 'components/ChangeLog/LastModified';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { LocationsTable } from './Tables/LocationsTable';

export function LocationsModule() {
  const { t } = useTranslation();
  return (
    <>
      <PageTitle rightItems={<LastModified entity="location" />} title={t('Location List')} />
      <Container>
        <LocationsTable />
      </Container>
    </>
  );
}
