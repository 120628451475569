import React, { ChangeEvent, FunctionComponent, useMemo, useState } from 'react';
import {
  Checkbox,
  Dialog,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Switch,
  TextField as TField,
  Typography,
} from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { Panel, TextField } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { getOptions } from '@michelin/select-options-provider';
import { ClosableDialogContent } from 'components/ClosableDialog/ClosableDialogContent';
import { ClosableDialogTitle } from 'components/ClosableDialog/ClosableDialogTitle';
import EditDelivery from 'components/HoursOfOperation/Edit';
import ViewDelivery from 'components/HoursOfOperation/View';
import { ReactQuillEditor } from 'components/ReactQuillEditor/ReactQuillEditor';
import { HoursOfOperation } from 'components/Types';
import * as Utils from '../../../Util';
import { localize } from '../../../Util/Locatization';
import { Customer, SaveError } from '../../LocationTypes';
import { TireOrderingInformation } from '../../detailsIndex';
import { Inventory, TireOrderingMap } from './Inventory';
import OrderingProcedures from './OrderingProcedures';

type DeliveryProps = {
  editFlag: boolean;
  customer: Customer;
  onChange: Function;
  saveError: SaveError;
  tireOrderingInfo: TireOrderingInformation;
  setTireOrderingInfo: Function;
  customerNumber: string;
};

const useStyles = makeStyles((theme: any) => ({
  header: {},
  quill: {
    height: '300px',
  },
}));

export const TireOrderingProc: FunctionComponent<DeliveryProps> = (props) => {
  const classes = useStyles();
  const { customer, editFlag, onChange, saveError, tireOrderingInfo, setTireOrderingInfo, customerNumber } = props;
  const { t } = useTranslation();

  const [updateStateCounter, setUpdateStateCounter] = React.useState(0);
  const forceUpdate = () => setUpdateStateCounter(updateStateCounter + 1);
  const [deliveryDialog, setDeliveryDialog] = useState<boolean>(false);
  const stateOptions = customer.customer_country === 'US' ? getOptions('us_states') : getOptions('ca_provinces');
  const disableForDealer = customer.method_of_delivery === 'Pickup at Service Provider Location';
  const readonly = disableForDealer || !editFlag || customer.delivery_address_same_as_physical;
  const openMe = () => {
    setDeliveryDialog(true);
  };

  const editorConfig = {
    readOnly: !editFlag,
    isEditing: editFlag,
  };

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tempV = customer;
    tempV.delivery_address = event.currentTarget.value;
    onChange(tempV);
    forceUpdate();
  };

  const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tempV = customer;
    tempV.delivery_city = event.currentTarget.value;
    onChange(tempV);
    forceUpdate();
  };

  const handleZipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tempV = customer;
    tempV.delivery_zip = event.currentTarget.value;
    onChange(tempV);
    forceUpdate();
  };

  const handleMethodOfDelivery = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!editFlag) return;
    const tempV = customer;
    tempV.method_of_delivery = event.target.checked ? 'Pickup at Service Provider Location' : 'Deliver to Location';
    tempV.delivery_address = '';
    tempV.delivery_city = '';
    tempV.delivery_state = '';
    tempV.delivery_zip = '';
    tempV.delivery_address_same_as_physical = false;
    onChange(tempV);
    forceUpdate();
  };

  const timeChange = (hoursOfOperations: HoursOfOperation) => {
    customer.hoursOfOperations = hoursOfOperations;
    onChange(customer);
    forceUpdate();
  };

  const handleMapChange = (key: string, newValue: string, map: TireOrderingMap) => {
    setTireOrderingInfo({
      ...tireOrderingInfo,
      levelMap: {
        ...tireOrderingInfo.levelMap,
        [key]: newValue,
      },
    });
  };

  const checkMe = (cust: Customer) =>
    cust.delivery_address === cust.customer_addr1 &&
    cust.delivery_city === cust.customer_city &&
    cust.delivery_state === cust.customer_state &&
    cust.delivery_zip === cust.customer_zip;

  if (customer.method_of_delivery && customer.method_of_delivery === 'Pickup at Service Provider Location') {
    customer.delivery_address = '';
    customer.delivery_city = '';
    customer.delivery_zip = '';
    customer.delivery_state = '';
  }

  const countryCode = useMemo(() => Utils.getCountryCode(customer), [customer]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Panel title={t('Pickup & Delivery Preferences')}>
            <Grid container spacing={1}>
              <Grid item md={2}>
                <span>{t('Method of Delivery')}</span>
              </Grid>
              <Grid item md={6}>
                {t('Deliver to Location')}
                <Switch
                  disabled={!editFlag}
                  checked={customer.method_of_delivery === 'Pickup at Service Provider Location'}
                  onChange={handleMethodOfDelivery}
                  color="primary"
                />
                {t('Pickup at Service Provider Location')}
              </Grid>
            </Grid>
            {customer.method_of_delivery !== 'Pickup at Service Provider Location' && (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={2} className={classes.header}>
                    <span>{t('Delivery Address')}</span>
                  </Grid>
                  <Grid item xs={3} className={classes.header}>
                    <Checkbox
                      disabled={!editFlag || disableForDealer}
                      checked={
                        customer.method_of_delivery &&
                        customer.method_of_delivery === 'Pickup at Service Provider Location'
                          ? false
                          : customer.delivery_address_same_as_physical || checkMe(customer)
                      }
                      onChange={(event: ChangeEvent<any>) => {
                        customer.delivery_address_same_as_physical = event.target.checked;
                        if (!customer.delivery_address_same_as_physical) {
                          customer.delivery_address = '';
                          customer.delivery_city = '';
                          customer.delivery_state = '';
                          customer.delivery_zip = '';
                        } else {
                          customer.delivery_address = customer.customer_addr1;
                          customer.delivery_city = customer.customer_city;
                          customer.delivery_state = customer.customer_state;
                          customer.delivery_zip = customer.customer_zip;
                        }
                        forceUpdate();
                      }}
                      color="primary"
                    />
                    <span>{t('Same as Physical Address')}</span>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={2} className={classes.header} />
                  <Grid item xs={3} className={classes.header}>
                    <TextField
                      label={t('Address 1')}
                      error={saveError.errors.includes('delivery_address')}
                      value={customer.delivery_address}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleAddressChange(e)}
                      readOnly={readonly}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={3} className={classes.header}>
                    <TextField
                      label={t('City')}
                      error={saveError.errors.includes('delivery_city')}
                      value={customer.delivery_city}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleCityChange(e)}
                      readOnly={readonly}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.header}>
                    <TField
                      select
                      label={t(localize('State', customer.customer_country))}
                      error={saveError.errors.includes('delivery_state')}
                      value={customer.delivery_state}
                      disabled={readonly}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        customer.delivery_state = event.target.value;
                        onChange(customer);
                        forceUpdate();
                      }}
                      margin="normal"
                      style={{ display: 'flex', flexWrap: 'wrap' }}
                    >
                      {stateOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TField>
                  </Grid>
                  <Grid item xs={2} className={classes.header}>
                    <TextField
                      label={t(localize('Zip', customer.customer_country))}
                      error={saveError.errors.includes('delivery_zip')}
                      value={customer.delivery_zip}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleZipChange(e)}
                      readOnly={readonly}
                      margin="normal"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={2} className={classes.header}>
                    <span>{t('Delivery Hours')}</span>
                  </Grid>
                  <Grid item xs={6}>
                    {editFlag && !disableForDealer && (
                      <EditDelivery
                        holidays={countryCode}
                        hoursOfOperations={customer.hoursOfOperations}
                        onChange={timeChange}
                      />
                    )}
                    {editFlag && disableForDealer && (
                      <Fab
                        variant="extended"
                        size="medium"
                        color="primary"
                        aria-label="Add"
                        style={{ margin: 8 }}
                        onClick={() => {
                          openMe();
                        }}
                      >
                        <IconButton muiiconbutton-label style={{ padding: 3, color: 'white' }}>
                          <CalendarToday />
                        </IconButton>
                        {t('Delivery Hours')}
                      </Fab>
                    )}
                    {!editFlag && (
                      <Fab
                        variant="extended"
                        size="medium"
                        color="primary"
                        aria-label="Add"
                        style={{ margin: 8 }}
                        onClick={() => {
                          openMe();
                        }}
                      >
                        <IconButton muiiconbutton-label style={{ padding: 3, color: 'white' }}>
                          <CalendarToday />
                        </IconButton>
                        {t('Delivery Hours')}
                      </Fab>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
            <Grid container spacing={1}>
              <Grid item xs={2} className={classes.header}>
                {t('Signatures Required')}
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!editFlag}
                      checked={customer.recipient_signature_required}
                      color="primary"
                      onChange={(event: ChangeEvent<any>) => {
                        customer.recipient_signature_required = event.target.checked;
                        forceUpdate();
                      }}
                    />
                  }
                  label={t('Recipient Signature Required')}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!editFlag}
                      checked={customer.driver_signature_required}
                      color="primary"
                      onChange={(event: ChangeEvent<any>) => {
                        customer.driver_signature_required = event.target.checked;
                        forceUpdate();
                      }}
                    />
                  }
                  label={t('Driver Signature Required')}
                />
              </Grid>
              <Grid item xs={12} className={classes.quill}>
                <Typography variant="subtitle1">{t('Pickup & Delivery Instructions')}</Typography>
                <ReactQuillEditor
                  config={editorConfig}
                  id="main.pickup_delivery_instructions"
                  content={customer.pickup_delivery_instructions || ''}
                  onUpdateEditorValue={(id: string, content: string) => {
                    customer.pickup_delivery_instructions = content;
                  }}
                />
              </Grid>
            </Grid>
          </Panel>
        </Grid>
        <Grid item xs={12}>
          <OrderingProcedures
            countryCode={countryCode}
            editFlag={editFlag}
            tireOrderingInfo={tireOrderingInfo}
            setTireOrderingInfo={setTireOrderingInfo}
            saveErrors={saveError}
          />
        </Grid>
        <Grid item xs={12}>
          <Panel title={t('Minimum Inventory Levels')}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ul>
                  <li>
                    {t('The products contained in this section are populated by the onsite tire preferences.')} <br />
                    {t('If any products are missing, please update in your Tire and Retread Onsite Profile')}
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12}>
                <Inventory
                  editFlag={editFlag}
                  map={tireOrderingInfo.levelMap}
                  editMap={handleMapChange}
                  customerNumber={customerNumber}
                />
              </Grid>
            </Grid>
          </Panel>
        </Grid>
      </Grid>
      <Dialog
        onClose={() => setDeliveryDialog(false)}
        aria-labelledby="customized-dialog-title"
        open={deliveryDialog}
        fullWidth
        maxWidth="sm"
      >
        <ClosableDialogTitle onClose={() => setDeliveryDialog(false)}>{t('Delivery Hours')}</ClosableDialogTitle>
        <ClosableDialogContent dividers>
          <ViewDelivery holidays={Utils.getCountryCode(customer)} hoursOfOperations={customer.hoursOfOperations} />
        </ClosableDialogContent>
      </Dialog>
    </>
  );
};
