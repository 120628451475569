/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { Button, Switch, Tooltip, useDialogs } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { TireProfileAxle, TireProfileOVM } from '@michelin/fcp-view-models';
import { getOptionLabel } from '@michelin/select-options-provider';
import { cloneDeep } from 'lodash';
import { AxleName, Axles } from 'modules/Tires/types';
import { useFormContext } from 'react-hook-form';
import { getAxle } from '../utils';

interface props {
  open: boolean;
  axleName: AxleName;
  onClose: () => void;
  axles: Axles | null;
  onClone: (axles: Axles) => void;
}

interface CloneAxle {
  steer: boolean;
  drive: boolean;
  trailer: boolean;
  carry: boolean;
  lift: boolean;
  pusher: boolean;
  tag: boolean;
}

export const CloneAxlePositions = ({ open, axleName, onClose, onClone, axles }: props) => {
  const { t } = useTranslation();
  const { confirmDialog } = useDialogs();
  const { getValues, setValue } = useFormContext<TireProfileOVM>();
  const [retreadSteer, setRetreadSteer] = useState<boolean>(false);

  const [cloneAxle, setCloneAxle] = useState<CloneAxle>({
    steer: false,
    drive: false,
    trailer: false,
    carry: false,
    lift: false,
    pusher: false,
    tag: false,
  });

  const title = t('Clone your {{axleName}} axle position', {
    axleName: getOptionLabel('axle_types', axleName || ''),
  }).toString();

  useEffect(() => {
    setCloneAxle({
      steer: false,
      drive: false,
      trailer: false,
      carry: false,
      lift: false,
      pusher: false,
      tag: false,
    });

    // if the axle to clone contains a tire_replacement_priority with a tire_type === 'retread'
    // then disable steer switch and add tooltip
    setRetreadSteer(false);
    let values: TireProfileAxle = [] as unknown as TireProfileAxle;
    if (getValues()?.axles?.[axleName]) {
      values = getValues()?.axles?.[axleName] ?? ([] as unknown as TireProfileAxle);
    }
    if (values.tire_replacement_priority) {
      values.tire_replacement_priority?.forEach((trp: any) => {
        if (trp.tire_type.toLowerCase() === 'retread') setRetreadSteer(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleAxlePositionClone = () => {
    const overrideAxles: AxleName[] = [];
    const dialogMessage: string[] = [];
    const axlesToAdd: string[] = [];
    const axlesToOverWrite: string[] = [];
    for (const [key, value] of Object.entries(cloneAxle)) {
      if (value) {
        overrideAxles.push(key as AxleName);
        if (axles && axles[key as AxleName].value) {
          axlesToOverWrite.push(t(key));
        } else {
          axlesToAdd.push(t(key));
        }
      }
    }
    if (axlesToOverWrite.length > 0) {
      dialogMessage.push(t('Do you want to override the following axle definitions?'));
      axlesToOverWrite.forEach((axle) => {
        dialogMessage.push(`• ${t(axle)}`);
      });
    }
    if (axlesToAdd.length > 0) {
      dialogMessage.push(t('Do you want to add the following axle definitions?'));
      axlesToAdd.forEach((axle) => {
        dialogMessage.push(`• ${t(axle)}`);
      });
    }
    if (overrideAxles.length > 0) {
      confirmDialog(dialogMessage, t('Overwrite axle definitions'), t('Yes'), t('No'), () => {
        overwriteAxles(overrideAxles);
      });
    }

    onClose();
  };

  const overwriteAxles = async (overrideAxles: AxleName[]) => {
    if (!axles) return;
    const axleToClone = getAxle(axleName, getValues());
    if (!axleToClone) return;
    const cloneAxles: Axles = cloneDeep(axles);

    overrideAxles.forEach((axle) => {
      cloneAxles[axle] = { ...cloneAxles[axle], dirty: true, value: true };
      const auxAxleToClone = cloneDeep(axleToClone);
      if (axle === 'steer') {
        auxAxleToClone.tire_pressure_regulator = undefined;
        auxAxleToClone.activity_if_pref_unavailable = auxAxleToClone.activity_if_pref_unavailable
          ? auxAxleToClone.activity_if_pref_unavailable.filter(
              (act) => act.toLowerCase() === 'accept_new_tires' || act.toLowerCase() === 'accept_off_brand_tires',
            )
          : [];
        if (auxAxleToClone.tire_service_specifications) {
          if (auxAxleToClone.tire_service_specifications?.first_choice === 'repair') {
            auxAxleToClone.tire_service_specifications.first_choice = null;
            auxAxleToClone.tire_service_specifications.second_choice = null;
          }
          if (auxAxleToClone.tire_service_specifications?.second_choice === 'repair') {
            auxAxleToClone.tire_service_specifications.second_choice = null;
          }
          auxAxleToClone.tire_service_specifications.third_choice = null;
        }
      }
      setValue(`axles.${axle}`, auxAxleToClone, { shouldDirty: true });
    });
    onClone(cloneAxles);
  };

  const handleAxleChange = (axleName: AxleName, value: boolean) => {
    if (axleName === 'all_axles') return;
    cloneAxle[axleName] = value;
    setCloneAxle({ ...cloneAxle, [axleName]: value });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>
        <Grid container>
          <Grid item sm={6} lg={6} style={{ paddingRight: 50 }}>
            {retreadSteer ? (
              <Tooltip
                arrow
                title={t(
                  `Unable to clone to the steer axle as the tire replacement priority list for the ${t(
                    axleName || '',
                  )} axle includes one or more tire types which are retreads`,
                ).toString()}
              >
                <div>
                  <Switch
                    id="steer"
                    checked={cloneAxle.steer || axleName === 'steer'}
                    checkedLabel=" "
                    onChange={(e, v) => handleAxleChange(e.target.id as AxleName, v)}
                    label={t('Steer')}
                    disabled={axleName === 'steer' || retreadSteer}
                  />
                </div>
              </Tooltip>
            ) : (
              <Switch
                checked={cloneAxle.steer || axleName === 'steer'}
                disabled={axleName === 'steer' || retreadSteer}
                id="steer"
                checkedLabel=" "
                onChange={(e, v) => handleAxleChange(e.target.id as AxleName, v)}
                label={t('Steer')}
              />
            )}
          </Grid>
          <Grid item sm={6} lg={6} style={{ paddingRight: 50 }}>
            <Switch
              id="drive"
              checked={cloneAxle.drive || axleName === 'drive'}
              checkedLabel=" "
              onChange={(e, v) => handleAxleChange(e.target.id as AxleName, v)}
              label={t('Drive')}
              disabled={axleName === 'drive'}
            />
          </Grid>
          <Grid item sm={6} lg={6} style={{ paddingRight: 50 }}>
            <Switch
              checked={cloneAxle.trailer || axleName === 'trailer'}
              id="trailer"
              checkedLabel=" "
              onChange={(e, v) => handleAxleChange(e.target.id as AxleName, v)}
              label={t('Trailer')}
            />
          </Grid>
          <Grid item sm={6} lg={6} style={{ paddingRight: 50 }}>
            <Switch
              checked={cloneAxle.carry || axleName === 'carry'}
              id="carry"
              checkedLabel=" "
              onChange={(e, v) => handleAxleChange(e.target.id as AxleName, v)}
              label={t('Carry')}
              disabled={axleName === 'carry'}
            />
          </Grid>
          <Grid item sm={6} lg={6} style={{ paddingRight: 50 }}>
            <Switch
              checked={cloneAxle.lift || axleName === 'lift'}
              id="lift"
              checkedLabel=" "
              onChange={(e, v) => handleAxleChange(e.target.id as AxleName, v)}
              label={t('Lift')}
              disabled={axleName === 'lift'}
            />
          </Grid>
          <Grid item sm={6} lg={6} style={{ paddingRight: 50 }}>
            <Switch
              checked={cloneAxle.pusher || axleName === 'pusher'}
              id="pusher"
              checkedLabel=" "
              onChange={(e, v) => handleAxleChange(e.target.id as AxleName, v)}
              label={t('Pusher')}
              disabled={axleName === 'pusher'}
            />
          </Grid>
          <Grid item sm={6} lg={6} style={{ paddingRight: 50 }}>
            <Switch
              checked={cloneAxle.tag || axleName === 'tag'}
              id="tag"
              checkedLabel=" "
              onChange={(e, v) => handleAxleChange(e.target.id as AxleName, v)}
              label={t('Tag')}
              disabled={axleName === 'tag'}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button size="small" style={{ margin: '8px' }} onClick={() => handleAxlePositionClone()}>
          {t('Update')}
        </Button>
        <Button size="small" color="danger" style={{ margin: '8px' }} onClick={onClose}>
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
