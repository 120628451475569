import React, { ChangeEvent, FunctionComponent, memo } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { TextField } from '@michelin/acid-components';
import { TirePriorities } from './Inventory';

type InventoryItemProps = {
  editFlag: boolean;
  priority: TirePriorities;
  inventoryLevel: string;
  onChange: Function;
};

const InventoryItem: FunctionComponent<InventoryItemProps> = (props) => {
  const { editFlag, priority, inventoryLevel, onChange } = props;

  return (
    <TableRow>
      <TableCell>
        <TextField
          style={{ width: 100 }}
          disabled={!editFlag}
          value={inventoryLevel || ''}
          onChange={(event: ChangeEvent<any>) => {
            const newVal = event.target.value;
            onChange(newVal);
          }}
        />
      </TableCell>
      <TableCell>
        <span>{priority.mspn}</span>
      </TableCell>
      <TableCell>
        <span>{priority.tire_type}</span>
      </TableCell>
      <TableCell>
        <span>{priority.brand}</span>
      </TableCell>
      <TableCell>
        <span>{priority.tire_size}</span>
      </TableCell>
      <TableCell>
        <span>{priority.tread_design}</span>
      </TableCell>
      <TableCell>
        <span>{priority.load_range}</span>
      </TableCell>
    </TableRow>
  );
};

export default memo(InventoryItem);
