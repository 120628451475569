import React, { ErrorInfo, FunctionComponent } from 'react';
import { Divider } from '@material-ui/core';
import { MichelinLogo } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';

interface Props {
  children: any;
}

interface ErrorState {
  hasError: any;
}

type InsideProps = {};

export const InsideError: FunctionComponent<InsideProps> = () => {
  const { t } = useTranslation();

  return (
    <div style={{ textAlign: 'center' }}>
      <div id="errorHeader">
        <h3 style={{ fontSize: '24px', opacity: '0.8' }}>{t('Oops!, something went wrong')}</h3>
      </div>
      <div>
        <p>
          {t(
            'There could be a misconfiguration in the system or a service outage. We track these errors automatically, please try again.',
          )}
        </p>
      </div>
      <Divider style={{ width: '50%', margin: 'auto' }} />
      <div>
        <span>
          <span
            style={{
              width: '90px',
              height: '90px',
              backgroundColor: 'transparent',
              display: 'inline-block',
            }}
          >
            <MichelinLogo direction="stacked" />
          </span>
        </span>
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
class ErrorCatch<MyStuff> extends React.Component<Props, ErrorState> {
  constructor(props: Props) {
    super(props);

    this.state = { hasError: false };
  }

  componentDidMount() {
    window.addEventListener('error', (e) => {
      // prevent React's listener from firing
      e.stopImmediatePropagation();
      // prevent the browser's
      e.preventDefault();
      // Set Error
      this.setState({
        hasError: true,
      });
    });
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  shouldComponentUpdate(nextProps: any, nextState: any) {
    if (nextState.hasError) {
      return false;
    }
    return true;
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <InsideError />;
    }
    return <>{this.props.children}</>;
  }
}

export default ErrorCatch;
