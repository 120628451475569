import React from 'react';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { Button } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { SelectOption, getOptions } from '@michelin/select-options-provider';
import { BillingPreferences, BillingProfileLocation } from '../query';

interface Props {
  profile: BillingPreferences;
  profileTypes: SelectOption[];
  open: boolean;
  duplicatedLocations: number | undefined;
  totalLocations: number | undefined;
  availableLocations: BillingProfileLocation[];
  handleClose: () => void;
  handleSave: () => void;
}

export default function UniqueBillingProfileModal(props: Props): JSX.Element {
  const {
    profile,
    profileTypes,
    duplicatedLocations,
    totalLocations,
    availableLocations,
    open,
    handleClose,
    handleSave,
  } = props;
  const { t } = useTranslation();
  let serviceType = '';
  let localProfileType = '';

  const serviceTypes: SelectOption[] = getOptions('billing_service_types');

  if (profile && profile.general_information) {
    serviceType =
      serviceTypes.filter((st) => st.value.toUpperCase() === profile.general_information.service_type.toUpperCase())[0]
        ?.label || '';
    localProfileType =
      profileTypes.filter((st) => st.value.toUpperCase() === profile.general_information.profile_type.toUpperCase())[0]
        ?.label || '';
  }
  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogContent dividers>
        <div>
          <span>
            {t(
              'A duplicate Billing profile already exists at {{duplicatesCount}} out of {{profilesTotal}} ' +
                'which you selected for assignment. Only one Billing profile having both of the same field values are permitted ' +
                'at any one location. The duplicated values in the tire profile at those locations are:',
              {
                duplicatesCount: profile.general_information.set_all_locations ? t('All') : duplicatedLocations,
                profilesTotal: totalLocations,
              },
            )}
          </span>
          <br />
          <span>{`${t('Profile Type')} = ${localProfileType}`}</span>
          <br />
          <span>{`${t('Service Type')} = ${serviceType}`}</span>
          <br />
          <span>
            {t(
              'You may continue with assigning to the {{amountOfLocations}} locations ' +
                'where there was no exact match or return to the page to update one or more of the fields shown above',
              { amountOfLocations: availableLocations.length },
            )}
          </span>
        </div>
      </DialogContent>
      <DialogActions>
        <Button size="small" disabled={availableLocations.length === 0} style={{ margin: '8px' }} onClick={handleSave}>
          {t('Save & Assign to {{amountOfLocations}} locations', { amountOfLocations: availableLocations.length })}
        </Button>
        <Button size="small" color="danger" style={{ margin: '8px' }} onClick={handleClose}>
          {t('Cancel & Return to Page')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
