import React, { useEffect } from 'react';
import { Accordion, AccordionDetails, Grid, Typography, createStyles, makeStyles, withStyles } from '@material-ui/core';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { Theme } from '@material-ui/core/styles';
import { ArrowBack, Edit, Save, Timelapse } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Container,
  Fab,
  GlobalActions,
  LoadingBounce,
  PreventTransitionPrompt,
  useDialogs,
} from '@michelin/acid-components';
import { usePermissions, useSnackbar, useTranslation } from '@michelin/central-provider';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { SOPProceduresData, useSOPProcedures, useUpdateSOPProcedures } from '../../../hooks/useSOP';
import { getBaseUrl } from '../../../prefs-and-service-offers';
import { PlainMessage } from '../../Messages';
import { ReactQuillEditor } from '../../ReactQuillEditor/ReactQuillEditor';
import { TABBED_CONTENT_HEIGHT } from '../../Util';

interface SOPProceduresProps {
  mode: 'view' | 'edit';
}

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: 0,
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    headingError: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.error.dark,
    },
    error: {
      color: theme.palette.error.dark,
      fontSize: theme.typography.pxToRem(12),
    },
  }),
);

function SOPProcedures(props: SOPProceduresProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const permissions = usePermissions();
  const { errorDialog } = useDialogs();
  const { enqueueSnackbar } = useSnackbar();

  const sopProceduresData: any = useSOPProcedures({ fleetId: permissions.location?.customer_number });
  const updateSOPProcedures = useUpdateSOPProcedures({ fleetId: permissions.location?.customer_number });

  if (!permissions.allowsAction('sop.list')) {
    enqueueSnackbar(t('User has no permissions to list SOPs.'), { variant: 'warning' });
    history.push(getBaseUrl() + '/all-preferences');
  }

  if (props.mode === 'edit' && !permissions.allowsAction('sop.update')) {
    enqueueSnackbar(t('User has no permissions to update SOPs.'), { variant: 'warning' });
    history.push('view');
  }

  const { formState, control, reset, handleSubmit } = useForm<SOPProceduresData>({
    defaultValues: {
      inspection_requirements: '',
      service_installation_notes: '',
      pickup_and_delivery: '',
      proof_of_delivery: '',
      scrap_only: '',
      scrap_pending: '',
      adjustment_notes: '',
      wheel_instructions: '',
      additional_instructions: '',
    },
  });

  useEffect(() => {
    if (sopProceduresData.data) reset(sopProceduresData.data);
  }, [sopProceduresData.data, reset]);

  const onSave = async (data: SOPProceduresData) => {
    try {
      const response = await updateSOPProcedures.mutateAsync({ fleetId: permissions.location?.customer_number, data });
      reset(response || ({} as SOPProceduresData));
      enqueueSnackbar(t('Data saved.'), {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
      history.push(`view`);
    } catch (err) {
      onError();
    }
  };

  const onError = () => {
    if (formState?.errors) {
      errorDialog([...t('Please check the marked fields.').split('\n')], t('Error saving data.'));
    } else {
      errorDialog(
        [...t('Please check your connection and try again.\nIf the issue persist, please contact support').split('\n')],
        t('Error saving data.'),
      );
    }
  };

  if (sopProceduresData.isLoading) return <LoadingBounce style={{ height: TABBED_CONTENT_HEIGHT }} />;
  if (sopProceduresData.isError && false) {
    const errorMessage: string = sopProceduresData.error.message || '';
    return (
      <PlainMessage
        title={t('Database Error')}
        messages={[t('Please try again later or contact support if the error persists'), errorMessage]}
      />
    );
  }

  return (
    <>
      <Container className={classes.root}>
        <Accordion key={`Accordon-inspection_requirements`}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-inspection_requirements-content`}
            id={`panel-inspection_requirements-header`}
          >
            <Typography
              className={formState?.errors?.inspection_requirements?.message ? classes.headingError : classes.heading}
            >
              {t('Inspection Requirements')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <>
              <Controller
                name={'inspection_requirements'}
                control={control}
                rules={{ maxLength: { value: 2200, message: 'This field cannot exceed 2000 characters long' } }}
                render={(item) => (
                  <Grid container>
                    <Grid item xs={12}>
                      <ReactQuillEditor
                        content={item.field.value}
                        id={item.field.name}
                        readOnly={props.mode !== 'edit'}
                        disabled={props.mode !== 'edit'}
                        onUpdateEditorValue={(id: string, value: string) => {
                          item.field.onChange(value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {formState?.errors?.inspection_requirements?.message ? (
                        <Typography className={classes.error}>
                          {t(formState?.errors?.inspection_requirements?.message)}
                        </Typography>
                      ) : null}
                    </Grid>
                  </Grid>
                )}
              />
            </>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              className={
                formState?.errors?.service_installation_notes?.message ? classes.headingError : classes.heading
              }
            >
              {t('Service and Installation')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Controller
              name={'service_installation_notes'}
              control={control}
              rules={{ maxLength: { value: 2200, message: 'This field cannot exceed 2000 characters long' } }}
              render={(item) => (
                <Grid container>
                  <Grid item xs={12}>
                    <ReactQuillEditor
                      content={item.field.value}
                      id={item.field.name}
                      readOnly={props.mode !== 'edit'}
                      disabled={props.mode !== 'edit'}
                      onUpdateEditorValue={(id: string, value: string) => {
                        item.field.onChange(value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {formState?.errors?.service_installation_notes?.message ? (
                      <Typography className={classes.error}>
                        {t(formState?.errors?.service_installation_notes?.message)}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion key={`Accordon-pickup_and_delivery`}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-pickup_and_delivery-content`}
            id={`panel-pickup_and_delivery-header`}
          >
            <Typography
              className={formState?.errors?.pickup_and_delivery?.message ? classes.headingError : classes.heading}
            >
              {t('Pickup and Delivery')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Controller
              name={'pickup_and_delivery'}
              control={control}
              rules={{ maxLength: { value: 2200, message: 'This field cannot exceed 2000 characters long' } }}
              render={(item) => (
                <Grid container>
                  <Grid item xs={12}>
                    <ReactQuillEditor
                      content={item.field.value}
                      id={item.field.name}
                      readOnly={props.mode !== 'edit'}
                      disabled={props.mode !== 'edit'}
                      onUpdateEditorValue={(id: string, value: string) => {
                        item.field.onChange(value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {formState?.errors?.pickup_and_delivery?.message ? (
                      <Typography className={classes.error}>
                        {t(formState?.errors?.pickup_and_delivery?.message)}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion key={`Accordon-proof_of_delivery`}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-proof_of_delivery-content`}
            id={`panel-proof_of_delivery-header`}
          >
            <Typography
              className={formState?.errors?.proof_of_delivery?.message ? classes.headingError : classes.heading}
            >
              {t('Proof of Delivery')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Controller
              name={'proof_of_delivery'}
              control={control}
              rules={{ maxLength: { value: 2200, message: 'This field cannot exceed 2000 characters long' } }}
              render={(item) => (
                <Grid container>
                  <Grid item xs={12}>
                    <ReactQuillEditor
                      content={item.field.value}
                      id={item.field.name}
                      readOnly={props.mode !== 'edit'}
                      disabled={props.mode !== 'edit'}
                      onUpdateEditorValue={(id: string, value: string) => {
                        item.field.onChange(value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {formState?.errors?.proof_of_delivery?.message ? (
                      <Typography className={classes.error}>
                        {t(formState?.errors?.proof_of_delivery?.message)}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion key={`Accordon-scrap_only`}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-scrap_only-content`}
            id={`panel-scrap_only-header`}
          >
            <Typography className={formState?.errors?.scrap_only?.message ? classes.headingError : classes.heading}>
              {t('Scrap Only')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Controller
              name={'scrap_only'}
              control={control}
              rules={{ maxLength: { value: 2200, message: 'This field cannot exceed 2000 characters long' } }}
              render={(item) => (
                <Grid container>
                  <Grid item xs={12}>
                    <ReactQuillEditor
                      content={item.field.value}
                      id={item.field.name}
                      readOnly={props.mode !== 'edit'}
                      disabled={props.mode !== 'edit'}
                      onUpdateEditorValue={(id: string, value: string) => {
                        item.field.onChange(value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {formState?.errors?.scrap_only?.message ? (
                      <Typography className={classes.error}>{t(formState?.errors?.scrap_only?.message)}</Typography>
                    ) : null}
                  </Grid>
                </Grid>
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion key={`Accordon-scrap_pending`}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-scrap_pending-content`}
            id={`panel-scrap_pending-header`}
          >
            <Typography className={formState?.errors?.scrap_pending?.message ? classes.headingError : classes.heading}>
              {t('Scrap Pending')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Controller
              name={'scrap_pending'}
              control={control}
              rules={{ maxLength: { value: 2200, message: 'This field cannot exceed 2000 characters long' } }}
              render={(item) => (
                <Grid container>
                  <Grid item xs={12}>
                    <ReactQuillEditor
                      content={item.field.value}
                      id={item.field.name}
                      readOnly={props.mode !== 'edit'}
                      disabled={props.mode !== 'edit'}
                      onUpdateEditorValue={(id: string, value: string) => {
                        item.field.onChange(value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {formState?.errors?.scrap_pending?.message ? (
                      <Typography className={classes.error}>{t(formState?.errors?.scrap_pending?.message)}</Typography>
                    ) : null}
                  </Grid>
                </Grid>
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion key={`Accordon-adjustment_notes`}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-adjustment_notes-content`}
            id={`panel-adjustment_notes-header`}
          >
            <Typography
              className={formState?.errors?.adjustment_notes?.message ? classes.headingError : classes.heading}
            >
              {t('Adjustment Notes')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Controller
              name={'adjustment_notes'}
              control={control}
              rules={{ maxLength: { value: 2200, message: 'This field cannot exceed 2000 characters long' } }}
              render={(item) => (
                <Grid container>
                  <Grid item xs={12}>
                    <ReactQuillEditor
                      content={item.field.value}
                      id={item.field.name}
                      readOnly={props.mode !== 'edit'}
                      disabled={props.mode !== 'edit'}
                      onUpdateEditorValue={(id: string, value: string) => {
                        item.field.onChange(value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {formState?.errors?.adjustment_notes?.message ? (
                      <Typography className={classes.error}>
                        {t(formState?.errors?.adjustment_notes?.message)}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion key={`Accordon-wheel_instructions`}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-wheel_instructions-content`}
            id={`panel-wheel_instructions-header`}
          >
            <Typography
              className={formState?.errors?.wheel_instructions?.message ? classes.headingError : classes.heading}
            >
              {t('Wheel Instructions')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Controller
              name={'wheel_instructions'}
              control={control}
              rules={{ maxLength: { value: 2200, message: 'This field cannot exceed 2000 characters long' } }}
              render={(item) => (
                <Grid container>
                  <Grid item xs={12}>
                    <ReactQuillEditor
                      content={item.field.value}
                      id={item.field.name}
                      readOnly={props.mode !== 'edit'}
                      disabled={props.mode !== 'edit'}
                      onUpdateEditorValue={(id: string, value: string) => {
                        item.field.onChange(value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {formState?.errors?.wheel_instructions?.message ? (
                      <Typography className={classes.error}>
                        {t(formState?.errors?.wheel_instructions?.message)}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
              )}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion key={`Accordon-additional_instructions`}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-additional_instructions-content`}
            id={`panel-additional_instructions-header`}
          >
            <Typography
              className={formState?.errors?.additional_instructions?.message ? classes.headingError : classes.heading}
            >
              {t('Additional Instructions')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Controller
              name={'additional_instructions'}
              control={control}
              rules={{ maxLength: { value: 2200, message: 'This field cannot exceed 2000 characters long' } }}
              render={(item) => (
                <Grid container>
                  <Grid item xs={12}>
                    <ReactQuillEditor
                      content={item.field.value}
                      id={item.field.name}
                      readOnly={props.mode !== 'edit'}
                      disabled={props.mode !== 'edit'}
                      onUpdateEditorValue={(id: string, value: string) => {
                        item.field.onChange(value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {formState?.errors?.additional_instructions?.message ? (
                      <Typography className={classes.error}>
                        {t(formState?.errors?.additional_instructions?.message)}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
              )}
            />
          </AccordionDetails>
        </Accordion>
      </Container>
      {permissions.allowsAction('sop.update') ? (
        <GlobalActions>
          <Fab
            color="primary"
            aria-label={t('Edit')}
            onClick={() => history.push(`edit`)}
            disabled={props.mode === 'edit'}
          >
            <Edit />
          </Fab>
          <Fab color="inherit" aria-label={t('Saving')} disabled={!formState.isSubmitting}>
            <Timelapse />
          </Fab>
          <Fab
            color={formState.isDirty ? 'primary' : 'inherit'}
            aria-label={t('Save')}
            onClick={formState.isDirty ? handleSubmit(onSave, onError) : undefined}
            disabled={props.mode === 'view' || formState.isSubmitting}
          >
            <Save />
          </Fab>
          <Fab
            color="primary"
            aria-label={t('Back')}
            onClick={() => {
              history.push(`view`);
            }}
            disabled={props.mode === 'view'}
          >
            <ArrowBack />
          </Fab>
        </GlobalActions>
      ) : null}
      <PreventTransitionPrompt when={formState.isDirty} handleDiscard={() => reset()} />
    </>
  );
}

export { SOPProcedures };
