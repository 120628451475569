export const typeCustomer = `
  customer {
    customer_name
    customer_type
    customer_number
    bill_to_customer
    cust_bus_code
    customer_addr1
    customer_addr2
    customer_city
    customer_country
    customer_state
    customer_zip
    extrnl_cust_id
    parent_company_number
    home_office_number
    ship_to_customer
  }
`;

export const typeOwner = `
  owner {
    customer_name
    customer_type
    customer_number
    bill_to_customer
    cust_bus_code
    customer_addr1
    customer_addr2
    customer_city
    customer_country
    customer_state
    customer_zip
    extrnl_cust_id
    parent_company_number
    home_office_number
    ship_to_customer
  }
`;

export const typeLocation = `
  location {
    customer_name
    customer_type
    customer_number
    bill_to_customer
    cust_bus_code
    customer_addr1
    customer_addr2
    customer_city
    customer_country
    customer_state
    customer_zip
    extrnl_cust_id
    parent_company_number
    home_office_number
    ship_to_customer
  }
`;

export const typeTireProfileTireReplacementPriority = `
  tire_replacement_priority {
    priority
    brand
    tire_size
    tire_type
    tread_design
    load_range
    mspn
  }`;

export const typeTireProfileMain = `
  main {
    default
    profile_name
    profile_type
    application_type
    vehicle_type
    vehicle_type_vmrs_code
    vehicle_attrs_tractor
    vehicle_attrs_trailer
    assign_to_all_locations
  }`;

export const typeTireProfileAxles = `
  axles {
    steer {
      ${typeTireProfileTireReplacementPriority}
    }
    drive {
      ${typeTireProfileTireReplacementPriority}
    }
    trailer {
      ${typeTireProfileTireReplacementPriority}
    }
    tag {
      ${typeTireProfileTireReplacementPriority}
    }
    lift {
      ${typeTireProfileTireReplacementPriority}
    }
    carry {
      ${typeTireProfileTireReplacementPriority}
    }
    pusher {
      ${typeTireProfileTireReplacementPriority}
    }
    all_axles {
      ${typeTireProfileTireReplacementPriority}
    }
  }`;

export const typeTireProfileBasics = `
  hash_key
  range_key
  gsi1_hash_key
  gsi1_range_key
  is_deleted
  ${typeOwner}
  ${typeTireProfileMain}
  ${typeTireProfileAxles}
`;

export const typeTireProfile = `
  ${typeTireProfileBasics}
  locations {
    hash_key
    range_key
    gsi1_hash_key
    gsi1_range_key
    is_deleted
    ${typeLocation}
  }
`;

export const typeTireProfilesOwned = `
  owned {
      ${typeTireProfile}
    }
  `;

export const typeTireProfilesAssigned = `
  assigned {
    gsi1_hash_key
    gsi1_range_key
    hash_key
    is_deleted
    range_key
    tire_profile {
      ${typeTireProfile}
    }
  }
`;
