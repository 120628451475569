import React from 'react';
import { Grid } from '@material-ui/core';
import { Panel, Select, Switch, TextField } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { getOptions } from '@michelin/select-options-provider';
import { NewLocationAssignments } from 'components/NewLocationAssignments';
import { transformAsigneeToBillingLocation } from 'modules/Billing/utils';
import { Controller, useFormContext } from 'react-hook-form';
import { BillingProfileContextData } from '../DetailPage';

export interface IGeneralInformationComponent {
  action: string;
}
export function GeneralInformation(props: IGeneralInformationComponent) {
  const { action } = props;
  const editFlag = action === 'edit' || action === 'create';
  const { getValues, control, trigger } = useFormContext<BillingProfileContextData>();
  const { general_information, owner } = getValues().profile;
  const { t } = useTranslation();
  const isShipTo = owner?.customer_type.toLowerCase() === 'st';

  return (
    <Panel title={t('General Info')} style={{ marginBottom: 15 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Select
            value={general_information?.service_type || ''}
            label={t('Service Type')}
            readOnly
            style={{ width: '100%' }}
            options={getOptions('billing_service_types')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            value={general_information?.profile_type || ''}
            label={t('Profile Type')}
            readOnly
            style={{ width: '100%' }}
            options={getOptions('profile_types')}
          />
        </Grid>
        <Grid item xs={12} sm={!isShipTo ? 6 : 12}>
          <Controller
            name="profile.general_information.profile_name"
            control={control}
            rules={{
              required: { value: true, message: t('Billing Profile Name') },
            }}
            render={(item) => (
              <TextField
                id={item.field.name}
                name="billingProfileName"
                label={t('Billing Profile Name')}
                type="string"
                variant="standard"
                value={item.field.value || ''}
                error={!!item.formState.errors.profile?.general_information?.profile_name}
                onChange={(e) => item.field.onChange(e.target.value)}
                readOnly={props.action === 'view'}
                required
                helperText={
                  !!item.formState.errors.profile?.general_information?.profile_name
                    ? t('Profile name is required').toString()
                    : ''
                }
              />
            )}
          />
        </Grid>
        {!isShipTo ? (
          <Grid item xs={12} sm={6}>
            <Grid container alignItems="center" style={{ paddingTop: 16 }}>
              <Grid item xs={9} style={{ textAlign: 'left' }}>
                <Controller
                  name="profile.general_information.set_all_locations"
                  defaultValue={general_information?.set_all_locations || false}
                  control={control}
                  rules={{
                    required: false,
                    validate: {
                      value: (value) =>
                        (getValues().locations?.length > 0 && value === false) ||
                        value === true ||
                        t('You must assign to All or selected ship to accounts').toString(),
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Switch
                      checked={value}
                      id="allLocationsSwitch"
                      readOnly={!editFlag}
                      onChange={(e) => {
                        onChange(e.target.checked);
                      }}
                      color="primary"
                      disabled={!editFlag}
                      label={t('Assign to all locations')}
                      labelPosition="left"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="locations"
                  control={control}
                  render={({ field: { onChange, value }, formState }) => (
                    <NewLocationAssignments
                      owner={owner?.customer_number}
                      mode={!isShipTo && editFlag ? 'edit' : 'view'}
                      locations={(value || []).map((x) => ({ hash_key: x.hash_key }))}
                      all={general_information?.set_all_locations || false}
                      onUpdate={(newAssignments) => {
                        const locations = transformAsigneeToBillingLocation(newAssignments);
                        onChange(locations);
                        trigger('profile.general_information.set_all_locations');
                      }}
                      error={!!formState.errors.profile?.general_information?.set_all_locations}
                      style={{ margin: '0 0 0 auto' }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Switch
            label={t('Participates in Approve Orders Program?')}
            readOnly
            checked={general_information?.participates_in_approve_orders || false}
            color="primary"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="profile.general_information.driver_signature_required"
            defaultValue={general_information?.driver_signature_required || false}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Switch
                checked={value}
                id="driverSignatureRequiredSwitch"
                readOnly={!editFlag}
                onChange={(e) => {
                  onChange(e.target.checked);
                }}
                color="primary"
                disabled={!editFlag}
                label={t('Driver Signature Required?')}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            name="profile.general_information.driver_name_required"
            defaultValue={general_information?.driver_name_required || false}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Switch
                checked={value}
                id="driverNameRequiredSwitch"
                readOnly={!editFlag}
                onChange={(e) => {
                  onChange(e.target.checked);
                }}
                color="primary"
                disabled={!editFlag}
                label={t('Driver Name Required?')}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Switch
            label={t('PO Required for Invoicing?')}
            readOnly
            checked={general_information?.po_required_for_service || false}
            color="primary"
          />
        </Grid>
      </Grid>
    </Panel>
  );
}
