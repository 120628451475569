import React from 'react';
import { Grid } from '@material-ui/core';
import { Chip } from '@michelin/acid-components';
import { WithTranslation, withTranslation } from '@michelin/central-provider';
import { HoursOfOperation } from '../Types';
import { getTimeString } from '../Util';
import { getDates } from './Types';

interface HoursOfOperationProps extends WithTranslation {
  hoursOfOperations?: HoursOfOperation;
  holidays?: string;
  mode?: string;
}

function HoursOfOperationView(props: HoursOfOperationProps) {
  const { t } = props;

  const hoo: any = props.hoursOfOperations || ({} as HoursOfOperation);
  const dates = getDates(props.holidays);
  const closed = (
    <span style={{ color: '#999', fontStyle: 'italic' }}>{props.mode === 'contact' ? '-' : t('Closed')}</span>
  );
  return (
    <Grid container spacing={2} style={{ marginBottom: '16px', textAlign: 'center' }}>
      <Grid item xs={6}>
        <Chip square label={t('Day')} style={{ margin: '8px' }} color="primary" />
        {dates.map((d) => (
          <Grid item xs={12} key={d.key}>
            {t(d.label)}
          </Grid>
        ))}
      </Grid>
      <Grid item xs={6} title={t('Hours')}>
        <Chip square label={t('Hours')} style={{ margin: '8px' }} color="primary" />
        {dates.map((d) => (
          <Grid item xs={12} key={d.key}>
            {getTimeString(hoo[d.key]) || closed}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default withTranslation()(HoursOfOperationView);
