import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Button, LoadingBounce, Message } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { auth } from 'Auth';
import { gql } from 'apollo-boost';
import { DependencyConflict } from '../..';

interface UnassignPriorityLevelsDialogProps {
  open: boolean;
  onClose: (confirm: boolean) => void;
  onReassign: (conflicts: Array<DependencyConflict>) => void;
  content: string;
  title: string;
  onlyReassign?: boolean;
  blockReassign?: boolean;
  possiblePrimaryDependencyConflictList: Array<DependencyConflict>;
}

export const primaryDependenciesConflictsQuery = gql`
  query SecondaryContactTypeDependency($dependencies: AWSJSON) {
    getSecondaryContactTypes(dependencies: $dependencies) {
      contactTypeDependencies {
        role_area
        service
        location_hash_key
      }
    }
  }
`;

// Confirm Reassign Priority Level Unassigned
export const unassignedPriorityTitleMultipleLocations =
  'Multiple locations and their priority levels are going to be lost.';
export const unassignedPriorityTitleRoleAreaChange =
  'This role/area contains priority levels assigned and are going to be lost.';
export const unassignedPriorityTitleServiceChange =
  'This service contains priority levels assigned and are going to be lost.';
export const unassignedPriorityContent = 'Do you wish to reassign them before continue?';
export const unassignedPriorityContentBlocked = 'Reasssign is unavailable during a reassignment.';

export default function UnassignPriorityLevelsDialog(props: UnassignPriorityLevelsDialogProps) {
  const {
    open,
    onClose,
    onReassign,
    content,
    title,
    onlyReassign,
    blockReassign,
    possiblePrimaryDependencyConflictList,
  } = props;
  const { t } = useTranslation();
  const [{ loading, data, errors }, setState] = useState<{ loading: boolean; data: any; errors: any }>({
    loading: true,
    data: undefined,
    errors: undefined,
  });
  let blockSkip: boolean = onlyReassign || false;
  const secondaryConflicts: Array<DependencyConflict> = [];
  let dialogActions;

  useEffect(() => {
    const asyncReq = async () => {
      const { apolloClient } = auth;
      setState({
        data: undefined,
        errors: undefined,
        loading: true,
      });
      if (apolloClient && possiblePrimaryDependencyConflictList && possiblePrimaryDependencyConflictList.length > 0) {
        try {
          const queryRes = await apolloClient.query({
            query: primaryDependenciesConflictsQuery,
            variables: {
              dependencies: JSON.stringify(possiblePrimaryDependencyConflictList),
            },
            fetchPolicy: 'network-only',
          });
          setState({
            data: queryRes.data,
            errors: queryRes.errors,
            loading: false,
          });
        } catch (e) {
          console.error(e);
        }
        return;
      }
      setState({
        data: undefined,
        errors: undefined,
        loading: false,
      });
    };
    asyncReq();
  }, [possiblePrimaryDependencyConflictList]);

  let dialogContent =
    blockReassign === true ? (
      <>
        {content}
        <p style={{ color: 'red' }}>{t(unassignedPriorityContentBlocked)}</p>
      </>
    ) : (
      content
    );

  if (loading) {
    dialogContent = <LoadingBounce />;
    dialogActions = (
      <Button onClick={() => onClose(false)} color="danger">
        {t('Cancel')}
      </Button>
    );
  } else if (data && data.getSecondaryContactTypes) {
    const { getSecondaryContactTypes } = data;
    if (getSecondaryContactTypes.length > 0) {
      blockSkip = true;
      getSecondaryContactTypes.forEach((x: { contactTypeDependencies: Array<DependencyConflict> }) =>
        x.contactTypeDependencies.forEach((secondaryDependency) => {
          secondaryConflicts.push(secondaryDependency);
        }),
      );
    }
  }

  if (errors) {
    dialogContent = <Message message="There was an error processing your request. Please try again later." />;
    dialogActions = (
      <Button onClick={() => onClose(false)} color="danger">
        {t('Cancel')}
      </Button>
    );
  } else if (loading === false) {
    dialogActions = (
      <>
        <Button disabled={blockReassign === true} onClick={() => onReassign(secondaryConflicts)}>
          {t('Reassign')}
        </Button>
        <Button disabled={blockSkip === true} onClick={() => onClose(true)}>
          {t('Skip')}
        </Button>
        <Button onClick={() => onClose(false)} color="danger">
          {t('Cancel')}
        </Button>
      </>
    );
  }

  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
      <DialogActions>{dialogActions}</DialogActions>
    </Dialog>
  );
}
