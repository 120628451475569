import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Dialog, Fab, FormControlLabel, IconButton, Switch as MUISwitch } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { CalendarToday } from '@material-ui/icons';
import { LoadingBounce, Panel, TextField } from '@michelin/acid-components';
import { usePermissions, useTranslation } from '@michelin/central-provider';
import { getOptions } from '@michelin/select-options-provider';
import { auth } from 'Auth';
import { gql } from 'apollo-boost';
import { ClosableDialogContent } from 'components/ClosableDialog/ClosableDialogContent';
import { ClosableDialogTitle } from 'components/ClosableDialog/ClosableDialogTitle';
import EditDelivery from 'components/HoursOfOperation/Edit';
import ViewDelivery from 'components/HoursOfOperation/View';
import Select from 'components/Select';
import { HoursOfOperation } from 'components/Types';
import { TABBED_CONTENT_HEIGHT } from 'components/Util';
import { useRouteMatch } from 'react-router-dom';
import * as Utils from '../../Util';
import { localize } from '../../Util/Locatization';
import { Customer, SaveError } from '../LocationTypes';
import { getAssets } from '../Utils';

const GET_VOCATIONS = gql`
  query GET_VOCATIONS($hash_key: String!) {
    getVocations(hash_key: $hash_key) {
      range_key
      description
    }
  }
`;

type LocationGeneralDetailsProps = {
  customer: Customer;
  setCustomer: Function;
  isEdit: boolean;
  saveError: SaveError;
};

type Vocations = {
  getVocations: Vocation[];
};

type Vocation = {
  range_key: string;
};

type TransportActivity = {
  id: string;
  name: string;
  vocation: Transport[];
};

type Transport = {
  id: string;
  name: string;
};

export const GeneralInfo = (props: LocationGeneralDetailsProps) => {
  const { customer, setCustomer, isEdit, saveError } = props;
  const permissions = usePermissions();
  const match = useRouteMatch<{ locationId: string }>();
  const { t } = useTranslation();
  const [updateStateCounter, setUpdateStateCounter] = React.useState(0);
  const forceUpdate = () => setUpdateStateCounter(updateStateCounter + 1);
  const [assetCount, setAssetCount] = useState(-1);
  const [deliveryDialog, setDeliveryDialog] = useState<boolean>(false);
  const timezoneOptions = getOptions('timezones');
  const { loading, data } = useQuery<Vocations>(GET_VOCATIONS, {
    variables: { hash_key: 'Vocations' },
  });

  useEffect(() => {
    if (auth && auth.getCustomerData()) {
      getAssets(match.params.locationId, String(auth.getCustomerData()!.customer_type))
        .then((r) => {
          setAssetCount(r.body.total);
        })
        .catch(() => {});
    } else {
      setAssetCount(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!customer || loading || !data) {
    return <LoadingBounce style={{ height: TABBED_CONTENT_HEIGHT }} />;
  }

  const emptyVocation: Transport[] = [];
  const transportAcitivity: TransportActivity[] = [{ id: 'None', name: '', vocation: emptyVocation }];

  data.getVocations.forEach((d) => {
    const main = d.range_key.replace(/~.+$/, '');
    const descrip = d.range_key.replace(/^.+~/, '');
    const doesExist = transportAcitivity.filter((x) => x.id === main);
    if (!doesExist || doesExist.length === 0) {
      transportAcitivity.push({
        id: main,
        name: main,
        vocation: [
          {
            id: descrip,
            name: descrip,
          },
        ],
      });
    } else {
      doesExist[0].vocation.push({
        id: descrip,
        name: descrip,
      });
    }
  });

  const filteredTransportActivities = transportAcitivity
    .filter((x) => customer.primaryTransportActivity === x.id)
    .slice(0, 1)
    .pop();
  const primaryVocationOptions = [
    { value: 'None', label: t('') },
    // eslint-disable-next-line max-len
    ...(filteredTransportActivities && filteredTransportActivities.vocation
      ? filteredTransportActivities.vocation.map((v) => ({ label: t(v.name), value: v.id }))
      : []),
  ];

  const handleNumberOfFleetTrucks = (event: React.ChangeEvent<HTMLInputElement>) => {
    customer.numberOfFleetTrucks = event.currentTarget.value;
    setCustomer(customer);
    forceUpdate();
  };

  const timeChange = (hoursOfOperations: HoursOfOperation) => {
    customer.operationHours = hoursOfOperations;
    setCustomer(customer);
    forceUpdate();
  };

  const mechanicalServicesEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    customer.mechanical_services_enabled = event.target.checked;
    setCustomer(customer);
    forceUpdate();
  };

  const handlenNmberOfFleetTrailers = (event: React.ChangeEvent<HTMLInputElement>) => {
    customer.numberOfFleetTrailers = event.currentTarget.value;
    setCustomer(customer);
    forceUpdate();
  };
  const handlePrimaryTransportActivity = (event: React.ChangeEvent<HTMLInputElement>) => {
    customer.primaryTransportActivity = event.target.value;
    customer.primaryVocation = 'None';
    setCustomer(customer);
    forceUpdate();
  };
  const handleTimezone = (event: React.ChangeEvent<HTMLInputElement>) => {
    customer.cust_timezone = event.target.value;
    setCustomer(customer);
    forceUpdate();
  };
  const handlePrimaryVocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    customer.primaryVocation = event.target.value;
    setCustomer(customer);
    forceUpdate();
  };
  const handleSecondaryTransportActivity = (event: React.ChangeEvent<HTMLInputElement>) => {
    customer.secondaryTransportActivity = event.target.value;
    customer.secondaryVocation = 'None';
    setCustomer(customer);
    forceUpdate();
  };
  const handleSecondaryVocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    customer.secondaryVocation = event.target.value;
    setCustomer(customer);
    forceUpdate();
  };

  return (
    <Grid container spacing={2} style={{ paddingBottom: 32 }}>
      <Grid item xs={12}>
        <Panel title={t('General Info')}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <TextField label={t('Address')} readOnly value={customer.customer_addr1} margin="normal" />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField label={t('Address 2')} readOnly value={customer.customer_addr2} margin="normal" />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField label={t('City')} readOnly value={customer.customer_city} margin="normal" />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                label={t(localize('State', customer.customer_country))}
                readOnly
                value={customer.customer_state}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                label={t(localize('Zip', customer.customer_country))}
                readOnly
                value={customer.customer_zip}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField label={t('Country')} readOnly value={customer.customer_country} margin="normal" />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField label={t('Main Phone')} readOnly value={customer.phone_number} margin="normal" />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField label={t('Account Type')} readOnly value={customer.customer_type} margin="normal" />
            </Grid>
          </Grid>
        </Panel>
      </Grid>
      <Grid item xs={12}>
        <Panel title={t('Associated Accounts')}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label={t('Parent Company Number')}
                value={customer.parent_company_number}
                margin="normal"
                readOnly
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField label={t('Home Office Number')} value={customer.home_office_number} margin="normal" readOnly />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField label={t('Bill To Number')} value={customer.bill_to_customer} margin="normal" readOnly />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField label={t('Ship To Number')} value={customer.ship_to_customer} margin="normal" readOnly />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField label={t('Terminal/Location Id')} value={customer.extrnl_cust_id} margin="normal" readOnly />
            </Grid>
          </Grid>
        </Panel>
      </Grid>
      <Grid item xs={12}>
        <Panel title={t('Hours of Operation')}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              {isEdit && (
                <EditDelivery
                  holidays={Utils.getCountryCode(customer)}
                  hoursOfOperations={customer.operationHours}
                  onChange={timeChange}
                />
              )}
              {!isEdit && (
                <Fab
                  variant="extended"
                  size="medium"
                  color="primary"
                  aria-label="Add"
                  style={{ margin: 8 }}
                  onClick={() => {
                    setDeliveryDialog(true);
                  }}
                >
                  <IconButton muiiconbutton-label style={{ padding: 3, color: 'white' }}>
                    <CalendarToday />
                  </IconButton>
                  {t('Hours')}
                </Fab>
              )}
            </Grid>
            <Grid item xs={12} md={5}>
              <Select
                title={t('Time Zone')}
                disabled={!isEdit}
                value={customer.cust_timezone}
                onChange={handleTimezone}
                unselectedValue="None"
                options={timezoneOptions}
              />
            </Grid>
          </Grid>
        </Panel>
        <Dialog
          onClose={() => setDeliveryDialog(false)}
          aria-labelledby="customized-dialog-title"
          open={deliveryDialog}
          fullWidth
          maxWidth="sm"
        >
          <ClosableDialogTitle onClose={() => setDeliveryDialog(false)}>{t('Delivery Hours')}</ClosableDialogTitle>
          <ClosableDialogContent dividers>
            <ViewDelivery holidays={Utils.getCountryCode(customer)} hoursOfOperations={customer.operationHours} />
          </ClosableDialogContent>
        </Dialog>
      </Grid>
      <Grid item xs={12}>
        <Panel title={t('Fleet Vocations and Activities')}>
          <Grid container spacing={2}>
            {customer.is_commercial && (
              <>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label={t('# of Fleet Trucks')}
                    value={customer.numberOfFleetTrucks}
                    type="number"
                    inputProps={{ min: 0, step: 1 }}
                    readOnly
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNumberOfFleetTrucks(e)}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label={t('# of Fleet Trailers')}
                    value={customer.numberOfFleetTrailers}
                    type="number"
                    inputProps={{ min: 0, step: 1 }}
                    readOnly
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlenNmberOfFleetTrailers(e)}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={4}>
              {assetCount !== -1 && (
                <TextField
                  label={t('# of Vehicles')}
                  value={assetCount}
                  type="number"
                  inputProps={{ min: 0, step: 1 }}
                  readOnly
                />
              )}
            </Grid>
            {(
              <Grid item xs={12} sm={3}>
                <FormControlLabel
                  control={
                    <MUISwitch
                      checked={!!customer.mechanical_services_enabled}
                      onChange={(e) => isEdit && mechanicalServicesEnabledChange(e)}
                      disabled={isEdit && !permissions.allowsAction('locations.update_mechanical_services_enabled')}
                      color="primary"
                    />
                  }
                  labelPlacement="start"
                  label={t('Mechanical Services Enabled')}
                />
              </Grid>
            ) &&
              customer.customer_type === 'ST' &&
              false}
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={12} sm={6}>
                <Select
                  title={t('Primary Transport Activity')}
                  readOnly
                  error={saveError.errors.includes('primaryTransportActivity')}
                  value={customer.primaryTransportActivity}
                  onChange={handlePrimaryTransportActivity}
                  unselectedValue="None"
                  options={transportAcitivity.map((a) => ({ label: t(a.name), value: a.id }))}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  title={t('Primary Vocation')}
                  readOnly
                  error={saveError.errors.includes('primaryVocation')}
                  value={customer.primaryVocation}
                  onChange={handlePrimaryVocation}
                  unselectedValue="None"
                  options={primaryVocationOptions}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={12} sm={6}>
                <Select
                  title={t('Secondary Transport Activity')}
                  readOnly
                  error={saveError.errors.includes('secondaryTransportActivity')}
                  value={customer.secondaryTransportActivity}
                  onChange={handleSecondaryTransportActivity}
                  options={transportAcitivity.map((a) => ({ label: t(a.name), value: a.id }))}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  title={t('Secondary Vocation')}
                  readOnly
                  error={saveError.errors.includes('secondaryVocation')}
                  value={customer.secondaryVocation}
                  onChange={handleSecondaryVocation}
                  options={[
                    { value: 'None', label: t('') },
                    ...transportAcitivity
                      .filter((x) => customer.secondaryTransportActivity === x.id)[0]
                      .vocation.filter(
                        (x) =>
                          customer.primaryTransportActivity !== customer.secondaryTransportActivity ||
                          x.id !== customer.primaryVocation,
                      )
                      .map((v) => ({ label: t(v.name), value: v.id })),
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Panel>
      </Grid>
      <Grid item xs={12}>
        <div style={{ height: 50 }} />
      </Grid>
    </Grid>
  );
};
