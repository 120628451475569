export const typeCustomer = `
  customer {
    customer_name
    customer_type
    customer_number
    ship_to_customer
    children_count {
      total
      ho
      bt
      st
    }
  }`;

export const billingPreferences = `
      hash_key
      range_key
      gsi1_hash_key
      gsi1_range_key
      owner_key
      owner_relationship
      assigned_locations
      general_information{
        profile_name
        profile_type
        service_type
        default
        set_all_locations
      }
  `;

export const typeBillingProfilesOwned = `
  owned {
    ${billingPreferences}
  }`;

export const typeBillingProfilesAssigned = `assigned {
  	billing_profile{
      ${billingPreferences}
    }
  }`;
