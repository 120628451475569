/* eslint-disable max-classes-per-file */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import Select, { SelectOption } from '../Select';

function defaultOnExecute() {
  return true;
}
export class Filter {
  label: string;

  options: SelectOption[];

  onFilter: (elem: any, value: string) => boolean;

  defaultOption?: SelectOption;

  onExecute: (value: string) => boolean;

  visible: boolean;

  constructor(
    label: string,
    options: SelectOption[],
    onFilter: (elem: any, value: string) => boolean,
    defaultValue?: string,
    onExecute?: (value: string) => boolean,
    visible?: boolean,
  ) {
    this.label = label;
    this.options = options;
    this.onFilter = onFilter;
    this.onExecute = onExecute || defaultOnExecute;
    this.visible = visible ?? true;
    if (defaultValue) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].value === defaultValue) {
          this.defaultOption = options[i];
          break;
        }
      }
    }
    if (this.defaultOption == null && options.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      this.defaultOption = options[0];
    }
  }

  execute(value: string, rows: any[], callback: (filteredRows: any[]) => void): void {
    callback(rows.filter((e) => this.onFilter(e, value)));
    this.onExecute(value);
  }
}

export class FilterGroup {
  filters: Filter[];

  values: Map<Filter, string>;

  constructor(filters: Filter[]) {
    this.filters = filters;
    this.values = new Map();
    this.filters.forEach((f) => {
      if (!f.defaultOption) return;
      this.values.set(f, f.defaultOption.value);
    });
  }

  filter(rows: any[], callback: (filteredRows: any[]) => void) {
    let res = rows;
    this.filters.forEach((f) => {
      if (!this.values.has(f)) return;
      f.execute(this.values.get(f) || '', res, (filteredRows) => {
        res = filteredRows;
      });
    });
    callback(res);
  }

  execute(filter: Filter, value: string, rows: any[], callback: (filteredRows: any[]) => void) {
    this.values.set(filter, value);
    this.filter(rows, callback);
  }
}

interface FilterGroupRenderProps {
  rows: any[];
  filteredRows: any[];
  setFilteredRows: (rows: any[]) => void;
  filterGroup: FilterGroup;
}

export function FilterGroupRenderer(props: FilterGroupRenderProps) {
  const useStyles = makeStyles(() => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    filter: {
      width: 200,
      padding: 4,
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    if (props.filteredRows !== props.rows) return;
    props.filterGroup.filter(props.rows, props.setFilteredRows);
  }, [props.filterGroup, props.filteredRows, props.rows, props.setFilteredRows]);

  return (
    <div className={classes.container}>
      {props.filterGroup.filters
        .filter((f) => f.visible)
        .map((filter, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className={classes.filter} key={`listFilter-${index}`}>
            <Select
              title={filter.label}
              value={props.filterGroup.values.get(filter) || (filter.defaultOption || { value: undefined }).value}
              variant="standard"
              unselectedValue="All"
              options={filter.options}
              onChange={(event) => {
                props.filterGroup.execute(filter, event.target.value, props.rows, props.setFilteredRows);
              }}
            />
          </div>
        ))}
    </div>
  );
}
