import React, { useEffect, useRef, useState } from 'react';
import { Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, LoadingBounce } from '@michelin/acid-components';
import { Location, useTranslation } from '@michelin/central-provider';
import { getOptions } from '@michelin/select-options-provider';
import { Template } from 'devextreme-react';
import { Column, SearchPanel, Selection, TreeList } from 'devextreme-react/tree-list';
import _ from 'lodash';
import { auth } from '../../Auth';
import { Filter, FilterGroup, FilterGroupRenderer } from '../FilterFieldTable/Filter';
import { PlainMessage } from '../Messages';
import { getLowestLocation, locationsCountMap, locationsMap } from './index';
import { useGetAllCustomers } from './useGetAllCustomers';

interface AssignDialogProps {
  mode: 'view' | 'edit';
  locations: any[];
  workingLocation: string;
  onClose: Function;
  onUpdate?: (locations: Location[]) => void;
  additionalColumns?: JSX.Element | JSX.Element[];
  displayCurrentLocation?: boolean;
  filterByCustomerType?: string; // It can be: [PC, HO, BT, ST]
  singleSelection?: boolean;
  plainView?: boolean;
  subTitle?: string;
}

let globalCurrentSelectedKeys: string[] = [];
let alreadySelectedKeys: string[] = [];

export default function AssignDialog(props: AssignDialogProps) {
  const { t } = useTranslation();

  const dataGrid = useRef(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<boolean>(false);
  const [locationsCount, setLocationsCount] = useState<string>('...');
  const [allTreeData, setAllTreeData] = useState<Location[]>([]);
  const [viewTreeData, setViewTreeData] = useState<Location[]>([]);
  const [filteredTreeData, setFilteredTreeData] = useState<Location[]>([]);
  const [originalSelectedKeys, setOriginalSelectedKeys] = useState<any[]>([]);
  const [currentSelectedKeys, setCurrentSelectedKeys] = useState<string[]>([]);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [modified, setModified] = useState<boolean>(false);
  const [filterGroup, setFilterGroup] = useState<FilterGroup | undefined>();

  const { data, isLoading, isError } = useGetAllCustomers();

  useEffect(() => {
    globalCurrentSelectedKeys = currentSelectedKeys;
  }, [currentSelectedKeys]);

  // Set the filter group
  useEffect(() => {
    setFilterGroup(
      new FilterGroup([
        new Filter(
          t('Assignment'),
          getOptions('assignment_status', 'all', t('All')),
          (row: any, status: string) => {
            if (status === 'all') return true;
            if (status === 'assigned') {
              // I'm selected, so I need to be on the screen
              if (globalCurrentSelectedKeys.includes(row.customer_number)) return true;
              // Any of my parents are selected, so I need to be on the screen
              if (
                (row.bill_to_customer && globalCurrentSelectedKeys.includes(row.bill_to_customer)) ||
                (row.home_office_number && globalCurrentSelectedKeys.includes(row.home_office_number)) ||
                (row.parent_company_number && globalCurrentSelectedKeys.includes(row.parent_company_number))
              )
                return true;
              // Any of my children may be selected, so I have to check all of the selected items
              for (let i = 0; i < globalCurrentSelectedKeys.length; i++) {
                const customerNumber = globalCurrentSelectedKeys[i];
                const customer = locationsMap.get(customerNumber) || ({} as Location);
                if (customer.bill_to_customer && customer.bill_to_customer === row.customer_number) return true;
                if (customer.home_office_number && customer.home_office_number === row.customer_number) return true;
                if (customer.parent_company_number && customer.parent_company_number === row.customer_number)
                  return true;
              }
              return false;
            }
            return status === 'unassigned' && !globalCurrentSelectedKeys.includes(row.customer_number);
          },
          'all',
          (status: string) => {
            alreadySelectedKeys = status === 'unassigned' ? globalCurrentSelectedKeys : [];
            return true;
          },
        ),
      ]),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set the upper count
  useEffect(() => {
    const relationship = auth.getRelationship();

    let count: number = 0;
    currentSelectedKeys.forEach((locNumber) => {
      const lowestLocation = getLowestLocation(relationship, locNumber, props.workingLocation);
      const retrieved = props.filterByCustomerType ? '1' : locationsCountMap.get(lowestLocation);
      if (retrieved !== undefined) count += parseInt(retrieved || '1', 10);
    });
    // @ts-ignore
    setLocationsCount(count.toString(10));
  }, [currentSelectedKeys, props.filterByCustomerType, props.workingLocation]);

  // Build view tree
  useEffect(() => {
    if (props.mode === 'edit') return;

    const removeUnassignedLocations = (locs: any[]) => {
      const res: any[] = [];
      const included: Map<string, boolean> = new Map();

      // Leave only the assigned locations
      locs.forEach((l) => {
        if (!currentSelectedKeys.includes(l.customer_number)) return;
        res.push({ ...l });
        included.set(l.customer_number || '', true);
      });
      res.forEach((l) => {
        if (!l.parent_id || included.has(l.parent_id)) return;
        // eslint-disable-next-line no-param-reassign
        l.parent_id = null;
      });

      // Include children in the list
      locs.forEach((l) => {
        if (included.has(l.customer_number)) return;
        if (included.has(l.parent_id)) {
          res.push({ ...l });
          included.set(l.customer_number || '', true);
        }
      });

      // Include children of children in the list
      locs.forEach((l) => {
        if (included.has(l.customer_number)) return;
        if (included.has(l.parent_id)) {
          res.push({ ...l });
          included.set(l.customer_number || '', true);
        }
      });

      // Include children of children of children in the list
      locs.forEach((l) => {
        if (included.has(l.customer_number)) return;
        if (included.has(l.parent_id)) {
          res.push({ ...l });
          included.set(l.customer_number || '', true);
        }
      });

      return res;
    };

    const assignedLocations: any = removeUnassignedLocations(allTreeData);
    setViewTreeData(assignedLocations);
  }, [allTreeData, currentSelectedKeys, props.mode]);

  // Fetch data
  useEffect(() => {
    setLoading(isLoading);
    setLoadingError(isError);
    if (!data?.data) return;

    if (isError) {
      setLocationsCount('!');
      return;
    }

    const relationshipArray = auth.getRelationship().split('~');

    setOriginalSelectedKeys(props.locations);
    setCurrentSelectedKeys(props.locations);

    const treeData = data.data
      .filter((elem: Location) => {
        const downInTheHierarchy = locationsCountMap.get(elem.customer_number);
        if (!downInTheHierarchy) {
          // We should only allow up in the hierarchy locations if we are filtering by customerType and view mode
          return !!(
            props.mode === 'view' &&
            props.filterByCustomerType &&
            relationshipArray.includes(elem.customer_number)
          );
        }
        if (elem.customer_number === props.workingLocation && !props.displayCurrentLocation) return false;
        if (props.filterByCustomerType && props.filterByCustomerType !== elem.customer_type) return false;
        return elem?.relationship?.includes(props.workingLocation);
      })
      .map((elem: Location) => {
        const item = JSON.parse(JSON.stringify(elem));
        if (props.filterByCustomerType || props.plainView) {
          item.parent_id = null;
          return item;
        }

        switch (item.customer_type) {
          case 'HO':
            item.parent_id =
              props.workingLocation === item.parent_company_number && !props.displayCurrentLocation
                ? null
                : item.parent_company_number;
            break;
          case 'BT':
            item.parent_id =
              props.workingLocation === item.home_office_number && !props.displayCurrentLocation
                ? null
                : item.home_office_number;
            break;
          case 'ST':
            item.parent_id =
              props.workingLocation === item.bill_to_customer && !props.displayCurrentLocation
                ? null
                : item.bill_to_customer;
            break;
          case 'PC':
          default:
            item.parent_id = null;
            break;
        }

        return item;
      })
      .sort((l1, l2) => {
        const l3 = props.locations.includes(l1.customer_number);
        const l4 = props.locations.includes(l2.customer_number);
        if (l3 && !l4) return -1;
        if (!l3 && l4) return 1;
        return 0;
      });

    setAllTreeData(treeData);
    setFilteredTreeData(treeData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.displayCurrentLocation, props.filterByCustomerType, props.locations, props.workingLocation]);

  const onSelectionChange = (selectedRows: any) => {
    let arr: string[] = selectedRows.selectedRowKeys;
    if (props.singleSelection) {
      arr = arr.length === 0 ? arr : _.xor(arr, currentSelectedKeys);
    } else {
      alreadySelectedKeys.forEach((k) => !arr.includes(k) && arr.push(k));
    }
    setCurrentSelectedKeys(arr);
    const changed = JSON.stringify(arr.sort()) !== JSON.stringify(originalSelectedKeys.sort());
    setModified(changed);
  };

  /* *************** */
  /* *************** */
  /* Render Methods  */
  /* *************** */
  /* *************** */

  const renderFilterFields = () =>
    !filterGroup ? null : (
      <FilterGroupRenderer
        filterGroup={filterGroup}
        rows={allTreeData}
        filteredRows={filteredTreeData}
        setFilteredRows={setFilteredTreeData}
      />
    );

  const TitleLink = ({ filterByCustomerType, mode, singleSelection, subTitle }: any) => {
    let titleText: string;
    if (loading) titleText = t('loading...');
    else if (loadingError) titleText = t('loading error...');
    // eslint-disable-next-line max-len
    else if ((filterByCustomerType || singleSelection) && filterByCustomerType?.toLowerCase() !== 'st')
      titleText = t('{{locationsCount}} Location(s)', { locationsCount });
    else titleText = t('{{locationsCount}} Ship To(s)', { locationsCount });
    return (
      <Grid container alignItems="baseline">
        <Grid item xs={12} md={6}>
          {mode === 'view' ? t('Assigned locations') : t('Select location assignments')}
        </Grid>
        <Grid item xs={12} md={6} style={{ textAlign: 'right', fontSize: '.8em' }}>
          {titleText}
        </Grid>
        {subTitle && (
          <Grid item xs={12} md={6}>
            <span
              style={{
                color: '#999999',
                fontSize: '0.85em',
                marginLeft: 8,
              }}
            >
              {subTitle}
            </span>
          </Grid>
        )}
      </Grid>
    );
  };

  const ErrorMessage = () => (
    <Grid container alignItems="baseline">
      <Grid item xs={12}>
        <PlainMessage title={t('Error loading data')} messages={[t('Data object is empty...')]} />
      </Grid>
    </Grid>
  );

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xl"
        onClose={() => props.onClose()}
        aria-labelledby="customized-dialog-title"
        open
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle id="form-dialog-title">
          <TitleLink
            subTitle={props.subTitle}
            filterByCustomerType={props.filterByCustomerType}
            mode={props.mode}
            singleSelection={props.singleSelection}
          />
        </DialogTitle>

        <DialogContent id="dialog-content" dividers style={{ padding: loadingError ? 30 : 0 }}>
          {loading ? <LoadingBounce style={{ minHeight: '30vh' }} /> : null}
          {loadingError ? <ErrorMessage /> : null}
          {!loading && !loadingError ? (
            <TreeList
              ref={dataGrid}
              dataSource={props.mode === 'edit' ? filteredTreeData : viewTreeData}
              onSelectionChanged={(selectedRows: any) => onSelectionChange(selectedRows)}
              selectedRowKeys={currentSelectedKeys}
              showRowLines
              showBorders
              columnAutoWidth
              autoExpandAll={props.mode === 'edit'}
              expandedRowKeys={expanded}
              onRowExpanding={(e) => {
                setExpanded([...expanded, e.key]);
              }}
              onRowCollapsing={(e) => {
                setExpanded(expanded.filter((key) => key !== e.key));
              }}
              defaultSelectedRowKeys={originalSelectedKeys}
              keyExpr="customer_number"
              parentIdExpr="parent_id"
              onToolbarPreparing={(e: any) =>
                e.toolbarOptions.items.unshift({
                  location: 'before',
                  template: 'filterFields',
                })
              }
              id="assignListDevXTreeTable"
            >
              {props.mode === 'edit' ? <Selection recursive mode="multiple" allowSelectAll={false} /> : null}
              <Template name="filterFields" render={props.mode === 'edit' ? renderFilterFields : () => null} />
              <SearchPanel visible highlightCaseSensitive />
              <Column caption={t('Assigned')} dataField="assigned" visible={false} allowSearch={false} />
              <Column
                caption={t('Location')}
                calculateCellValue={(row: Location) => `${row.customer_type}: ${row.customer_number}`}
                allowSearch
                allowSorting
                width={200}
              />
              <Column caption={t('Terminal Id')} dataField="extrnl_cust_id" allowSearch />
              <Column caption={t('Location Name')} dataField="customer_name" allowSearch />
              <Column
                caption={t('Address')}
                allowSearch
                allowSorting
                minWidth={300}
                calculateCellValue={(row: Location) => `${row.customer_addr1} ${row.customer_addr2}`}
                cellRender={(row: any) => {
                  const { data } = row;
                  return (
                    <div style={{ whiteSpace: 'normal' }}>
                      {data.customer_addr1}
                      {data.customer_addr1 && data.customer_addr2 ? <br /> : null}
                      {data.customer_addr2 || ''}
                    </div>
                  );
                }}
              />

              <Column caption={t('City')} dataField="customer_city" />
              <Column
                caption={t('State / Province')}
                calculateCellValue={(row: Location) => `\u00A0${row.customer_state}`}
                allowSearch
                allowSorting
              />
              <Column caption={t('Zip / Postal Code')} dataField="customer_zip" />
              {props.additionalColumns}
            </TreeList>
          ) : null}
        </DialogContent>
        <DialogActions>
          {props.mode === 'edit' && (
            <Button
              id="assignLocationsSaveButton"
              size="small"
              color="success"
              style={{ margin: '8px' }}
              disabled={!modified}
              onClick={() => {
                if (props.onUpdate) {
                  const locations: Location[] = [];
                  currentSelectedKeys.forEach((loc: string) => {
                    const location: Location | undefined = locationsMap.get(loc);
                    if (location) locations.push(location);
                  });
                  props.onUpdate(locations);
                }
              }}
            >
              {t('Update')}
            </Button>
          )}
          <Button
            id="assignLocationsCloseButton"
            size="small"
            color="default"
            style={{ margin: '8px' }}
            onClick={() => props.onClose()}
          >
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
