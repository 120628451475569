import React from 'react';
import { useTranslation } from '@michelin/central-provider';
import { Contact, ContactType } from 'components/Contact/utils';
import { DataGrid } from 'devextreme-react';
import { Column, Scrolling } from 'devextreme-react/data-grid';
import { AccountLocationRender, Row, accountInfoRender, contactTypesRender, renderAddress } from './utils';

interface ManageContactTypesLevelsTableProps {
  contact: Contact;
  contactType: ContactType | undefined;
  contactTypePerLocation: Map<string, { roleArea: string; level: string }[]>;
  actionColumns: JSX.Element[] | undefined;
  locationsFilters: Set<string>;
  viewMode: boolean;
}

export default function ManageContactTypesLevelsTable(props: ManageContactTypesLevelsTableProps): JSX.Element {
  const { contact, contactType, actionColumns, contactTypePerLocation, locationsFilters, viewMode } = props;
  const { t } = useTranslation();

  const rows =
    contact.locations &&
    contact.locations
      .filter(
        (x) => x.is_deleted === false && (locationsFilters.size === 0 || locationsFilters.has(x.hash_key.toString())),
      )
      .map((x) => ({
        location: x.location,
        contactType,
      }));

  return (
    <DataGrid
      dataSource={rows}
      showBorders
      repaintChangesOnly
      noDataText={t(
        'At least one Tire contact type and a location must be added prior to viewing or managing priority levels for this contact.',
      )}
    >
      <Scrolling mode="virtual" />
      <Column
        calculateSortValue={(row: Row) => row.location.customer_name}
        allowSorting
        caption={t('Account/Location')}
        dataField="account_location"
        cellRender={(row: any) => <AccountLocationRender location={row.data.location} />}
      />
      <Column
        calculateSortValue={(row: Row) => `${row.location.customer_addr1} ${row.location.customer_addr2}`}
        allowSorting
        caption={t('Address')}
        dataField="address"
        cellRender={(row: any) => renderAddress(row.data.location)}
      />
      <Column
        allowSorting={false}
        caption={t('Account Info')}
        dataField="account_info"
        cellRender={(row: any) => accountInfoRender(row.data)}
      />
      <Column
        allowSorting={false}
        caption={viewMode ? t('Priority Levels') : t('Contact Types')}
        dataField="contact_types"
        cellRender={(row: any) => contactTypesRender(row.data, contactTypePerLocation, t)}
      />
      {actionColumns}
    </DataGrid>
  );
}
