/* eslint-disable react-hooks/exhaustive-deps */
// External Lib
import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Grid, InputAdornment } from '@material-ui/core';
// Central provider
import {
  Autocomplete,
  Button,
  Chip,
  Panel,
  PreventTransitionPrompt,
  Select,
  Switch,
  TextField,
  useDialogs,
} from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { SelectOption, getOptionLabel, getOptions } from '@michelin/select-options-provider';
// Internal Lib
import { scrollToDisplayFirstError } from 'components/Util';
import { isEqual } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { Actions, SOPTireProfile, SOPTireProfileFilter, SOPWheelBalance as SOPWheelBalanceType } from '../Types';
import { filterTireProfile } from '../utils';

interface WheelBalanceFormProps {
  action: Actions;
  data: SOPWheelBalanceType;
  list: SOPWheelBalanceType[];
  tireProfile: SOPTireProfile[] | null;
  onSave: (data: SOPWheelBalanceType) => void;
  onCancel: () => void;
}

function WheelBalanceForm({ action, data, list, tireProfile, onSave, onCancel }: WheelBalanceFormProps): JSX.Element {
  const { t } = useTranslation();
  const { errorDialog, confirmDialog } = useDialogs();
  const { control, reset, watch, handleSubmit, setValue, getValues, formState, trigger } = useForm<SOPWheelBalanceType>(
    { defaultValues: data },
  );

  const [isReseted, setReset] = useState(false);
  useEffect(() => {
    const cloneData = { ...data };
    if (action === 'add') cloneData.id = (list.length === 0 ? 0 : Math.max(...list.map((v) => v.id))) + 1;
    reset(cloneData);
    setReset(true);
  }, [data, reset]);

  useEffect(() => {
    if (isReseted && action === 'edit') trigger();
  }, [trigger, isReseted]);
  const axleOptions: SelectOption[] = getOptions('axle_types');
  const applicationOptions: SelectOption[] = getOptions('application_types');
  const vehicleOptions: SelectOption[] = getOptions('vehicle_types_onsite');

  const unitOfMeasurements: SelectOption[] = getOptions('weight_types');
  const preferredTireRrotationPatterns: SelectOption[] = getOptions('preferred_tire_rotation_patterns');
  const balanceOptions: SelectOption[] = getOptions('balance_options');

  // Complete the values to get the available info for Add or Edit.
  const uom = watch('uom');
  const axleWatch = action === 'add' ? watch('axle_types') : [watch('axle_type') || ''];
  const appWatch = action === 'add' ? watch('application_types') : [watch('application_type') || ''];
  const vehWatch = action === 'add' ? watch('vehicle_types') : [watch('vehicle_type') || ''];

  const filteredOptions: SOPTireProfileFilter = filterTireProfile(
    tireProfile,
    null,
    null,
    axleWatch,
    appWatch,
    vehWatch,
  );

  useEffect(() => {
    if (!data) return;
    if (isEqual(action === 'add' ? data.axle_types : [data.axle_type], axleWatch)) return;

    if (action === 'add') {
      setValue('application_types', undefined, { shouldDirty: true });
      setValue('vehicle_types', undefined, { shouldDirty: true });
    }

    if (action === 'edit') {
      setValue('application_type', null, { shouldDirty: true });
      setValue('vehicle_type', null, { shouldDirty: true });
    }
  }, [JSON.stringify(axleWatch)]);

  useEffect(() => {
    if (!data) return;
    if (isEqual(action === 'add' ? data.application_types : [data.application_type], appWatch)) return;
    if (action === 'add') setValue('vehicle_types', undefined, { shouldDirty: true });
    if (action === 'edit') setValue('vehicle_type', null, { shouldDirty: true });
  }, [JSON.stringify(appWatch)]);

  const additionalProps = { min: 0, max: 999, maxLength: 3 };
  const adornment = {
    endAdornment: <InputAdornment position="end">{getOptionLabel('pressure_types', uom || '')}</InputAdornment>,
    inputProps: additionalProps,
  };

  const onError = () => {
    errorDialog(t('Please check the marked fields.').split('\n'), t('Error saving data.'));
    scrollToDisplayFirstError();
  };

  const validateDuplicates = (): boolean | string => {
    const formValues = getValues();
    const duplicates =
      list.filter(
        (v: any) =>
          v.axle_type === formValues.axle_type &&
          v.application_type === formValues.application_type &&
          v.vehicle_type === formValues.vehicle_type &&
          v.id !== formValues.id,
      ).length !== 0 || false;
    return duplicates ? t('No duplicates allowed').toString() : true;
  };

  const validateOption = (type: string, value: string): boolean | string => {
    const formValues = getValues();
    let error = false;

    switch (type) {
      case 'axle': {
        error = !tireProfile?.map((i) => i.axle).includes(value);
        break;
      }
      case 'application': {
        error = !tireProfile
          ?.filter((i) => i.axle === formValues.axle_type)
          .map((i) => i.application_type)
          .includes(value);
        break;
      }
      case 'vehicle': {
        error = !tireProfile
          ?.filter((i) => i.axle === formValues.axle_type && i.application_type === formValues.application_type)
          .map((i) => i.vehicle_type)
          .includes(value);
        break;
      }
      default:
        break;
    }

    return error ? t('Choose an option from the tire profile(s) linked in Step 1').toString() : true;
  };

  const getAutocompleteValues = (values: any[], allValue: string) => {
    const val = values.map((v: any) => v.value || v);
    return val.includes(allValue) ? [allValue] : val;
  };

  return (
    <Panel>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          {action === 'add' && (
            <Controller
              name="axle_types"
              control={control}
              rules={{ required: true }}
              render={(props: any) => (
                <Autocomplete
                  label={t('Axle Type')}
                  variant="standard"
                  value={props.field.value || []}
                  onChange={(e: any, values: any[]) => props.field.onChange(getAutocompleteValues(values, 'all_axles'))}
                  getOptionSelected={(option: any, value: any) => option.value === value}
                  getOptionDisabled={(option: any) => list.map((v: any) => v.axle_type).includes(option.value)}
                  error={!!props.formState.errors.axle_types}
                  options={filteredOptions.axleTypesList
                    .map((axle) => axleOptions.filter((a) => a.value === axle))
                    .flat()}
                  disableCloseOnSelect
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        size="small"
                        label={getOptionLabel('axle_types', option)}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  size="small"
                  margin="normal"
                  fullWidth
                  required
                />
              )}
            />
          )}
          {(action === 'edit' || action === 'view') && (
            <Controller
              name="axle_type"
              rules={{
                required: { value: true, message: t('Please fill out this field.') },
                validate: {
                  duplicates: validateDuplicates,
                  validOption: (v) => validateOption('axle', v || ''),
                },
              }}
              control={control}
              render={(props: any) => (
                <Select
                  label={t('Axle Type')}
                  value={props.field.value || ''}
                  options={filteredOptions.axleTypesList
                    .map((axle) => axleOptions.filter((a) => a.value === axle))
                    .flat()}
                  variant="standard"
                  onChange={(e: any) => {
                    props.field.onChange(e.target.value);
                    trigger('axle_type');
                  }}
                  error={!!props.formState.errors.axle_type}
                  helperText={props.formState.errors.axle_type?.message}
                  disabled={action === 'view'}
                  required
                  fullWidth
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          {action === 'add' && (
            <Controller
              name="application_types"
              control={control}
              rules={{ required: true }}
              render={(props: any) => (
                <Autocomplete
                  label={t('Application Type')}
                  variant="standard"
                  value={props.field.value || []}
                  onChange={(e: any, values: any[]) => props.field.onChange(getAutocompleteValues(values, 'all'))}
                  getOptionSelected={(option: any, value: any) => option.value === value}
                  getOptionDisabled={(option: any) =>
                    list
                      .filter((f) => axleWatch?.includes(f.axle_type || ''))
                      .map((v: any) => v.application_type)
                      .includes(option.value)
                  }
                  error={!!props.formState.errors.application_types}
                  options={filteredOptions.applicationTypesList
                    .map((app) => applicationOptions.filter((a) => a.value === app))
                    .flat()}
                  disableCloseOnSelect
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        size="small"
                        label={getOptionLabel('application_types', option)}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  size="small"
                  margin="normal"
                  fullWidth
                  required
                />
              )}
            />
          )}
          {(action === 'edit' || action === 'view') && (
            <Controller
              name="application_type"
              rules={{
                required: { value: true, message: t('Please fill out this field.') },
                validate: {
                  duplicates: validateDuplicates,
                  validOption: (v) => validateOption('application', v || ''),
                },
              }}
              control={control}
              render={(props: any) => (
                <Select
                  label={t('Application Type')}
                  value={props.field.value || ''}
                  options={filteredOptions.applicationTypesList
                    .map((app) => applicationOptions.filter((a) => a.value === app))
                    .flat()}
                  variant="standard"
                  onChange={(e: any) => {
                    props.field.onChange(e.target.value);
                    trigger('application_type');
                  }}
                  error={!!props.formState.errors.application_type}
                  helperText={props.formState.errors.application_type?.message}
                  disabled={action === 'view'}
                  required
                  fullWidth
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          {action === 'add' && (
            <Controller
              name="vehicle_types"
              rules={{ required: true }}
              control={control}
              render={(props: any) => (
                <Autocomplete
                  label={t('Vehicle Type')}
                  variant="standard"
                  value={props.field.value || []}
                  onChange={(e: any, values: any[]) => props.field.onChange(getAutocompleteValues(values, 'ALL'))}
                  getOptionSelected={(option: any, value: any) => option.value === value}
                  getOptionDisabled={(option: any) =>
                    list
                      .filter(
                        (f) => axleWatch?.includes(f.axle_type || '') && appWatch?.includes(f.application_type || ''),
                      )
                      .map((v: any) => v.vehicle_type)
                      .includes(option.value)
                  }
                  error={!!props.formState.errors.vehicle_types}
                  options={filteredOptions.vehicleTypesList
                    .map((vehicle) => vehicleOptions.filter((v) => v.value === vehicle))
                    .flat()}
                  disableCloseOnSelect
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        size="small"
                        label={getOptionLabel('vehicle_types_onsite', option)}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  size="small"
                  margin="normal"
                  fullWidth
                  // readOnly={action === 'view'}
                />
              )}
            />
          )}
          {(action === 'edit' || action === 'view') && (
            <Controller
              name="vehicle_type"
              rules={{
                required: { value: true, message: t('Please fill out this field.') },
                validate: {
                  duplicates: validateDuplicates,
                  validOption: (v) => validateOption('vehicle', v || ''),
                },
              }}
              control={control}
              render={(props: any) => (
                <Select
                  label={t('Vehicle Type')}
                  value={props.field.value || ''}
                  options={filteredOptions.vehicleTypesList
                    .map((vehicle) => vehicleOptions.filter((v) => v.value === vehicle))
                    .flat()}
                  variant="standard"
                  onChange={(e: any) => {
                    props.field.onChange(e.target.value);
                    trigger('vehicle_type');
                  }}
                  error={!!props.formState.errors.vehicle_type}
                  helperText={props.formState.errors.vehicle_type?.message}
                  disabled={action === 'view'}
                  required
                  fullWidth
                />
              )}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="uom"
            control={control}
            rules={{ required: { value: true, message: t('Please fill out this field.') } }}
            render={(props: any) => (
              <Select
                label={t('Unit of Measurement')}
                value={props.field.value?.toLowerCase() || ''}
                options={unitOfMeasurements}
                variant="standard"
                onChange={(e: any) => {
                  props.field.onChange(e.target.value);
                  trigger('uom');
                }}
                error={!!props.formState.errors.uom}
                helperText={props.formState.errors.uom?.message}
                disabled={action === 'view'}
                required
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="max_weight"
            control={control}
            render={(props: any) => (
              <TextField
                type="number"
                label={t('Max Weight')}
                value={props.field.value || ''}
                variant="standard"
                onChange={(e: any) => props.field.onChange(parseInt(e.target.value, 0))}
                InputProps={adornment}
                onKeyDown={(e: any) => {
                  if (e.key === '-' || e.key === 'v') {
                    e.preventDefault();
                  }
                }}
                disabled={action === 'view'}
                maxLength={3}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="preferred_rotation_pattern"
            control={control}
            render={(props: any) => (
              <Select
                label={t('Preferred Rotation Pattern')}
                // error={validationErrors.preferred_rotation_pattern}
                value={props.field.value || ''}
                options={preferredTireRrotationPatterns}
                variant="standard"
                onChange={(e: any) => props.field.onChange(e.target.value)}
                disabled={action === 'view'}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="balancing_preference"
            control={control}
            render={(props: any) => (
              <Select
                label={t('Balancing Preference')}
                // error={validationErrors.preferred_rotation_pattern}
                value={props.field.value || ''}
                options={balanceOptions}
                variant="standard"
                onChange={(e: any) => props.field.onChange(e.target.value)}
                disabled={action === 'view'}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Controller
            name="require_radial_runout_match_mounting"
            control={control}
            render={(props: any) => (
              <Switch
                checked={props.field.value || false}
                onChange={(event, newValue) => props.field.onChange(newValue)}
                label={t('Require Radial Runout Match Mounting')}
                labelPosition="left"
                disabled={action === 'view'}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="comments"
            control={control}
            render={(props: any) => (
              <TextField
                label={t('Comments')}
                value={props.field.value || ''}
                multiline
                variant="standard"
                onChange={(e: any) => props.field.onChange(e.target.value)}
                disabled={action === 'view'}
                maxLength={50}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Grid container justify="flex-end">
            <Button
              size="small"
              color="primary"
              style={{ margin: '8px' }}
              disabled={action === 'view'}
              onClick={handleSubmit(onSave, onError)}
            >
              {t('Save')}
            </Button>
            <Button
              size="small"
              color="danger"
              style={{ margin: '8px' }}
              onClick={() => {
                if (formState.isDirty) {
                  confirmDialog(
                    t('Are you sure you want to discard all the changes you have made?'),
                    t('Discard Changes'),
                    t('Yes'),
                    t('No'),
                    onCancel,
                  );
                } else {
                  onCancel();
                }
              }}
            >
              {t('Cancel')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <PreventTransitionPrompt when={formState.isDirty} />
    </Panel>
  );
}

interface WheelBalanceFormModalProps {
  action: Actions;
  data: SOPWheelBalanceType;
  list: SOPWheelBalanceType[];
  tireProfile: SOPTireProfile[] | null;
  open: boolean;
  handleOpen: (value: boolean) => void;
  onSave: (e: SOPWheelBalanceType) => void;
}

function WheelBalanceFormModal(props: WheelBalanceFormModalProps) {
  const { t } = useTranslation();
  const action = props.action.charAt(0).toUpperCase() + props.action.slice(1);
  const title = `${t(action)} - ${t('Wheel Balancing')}`;

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      aria-labelledby="customized-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      open={props.open}
    >
      <DialogTitle>{title}</DialogTitle>

      <DialogContent dividers style={{ padding: 0 }}>
        <WheelBalanceForm
          action={props.action}
          list={props.list}
          data={props.data}
          onSave={props.onSave}
          onCancel={() => props.handleOpen(false)}
          tireProfile={props.tireProfile}
        />
      </DialogContent>
    </Dialog>
  );
}

export default WheelBalanceForm;
export { WheelBalanceFormModal };
