import gql from 'graphql-tag';
import { assigneeType } from '../../../Assignee/AssigneeType';

export const createManyAssignees = gql`
mutation CreateManyAssignees($input: [AssigneeInput!]) {
  createManyAssignees(input: $input) {
  	${assigneeType}
  }
}
`;

export const deleteManyAssignees = gql`
mutation DeleteManyAssignees($input: [HashKeyAndRangeKeyInput!]) {
  deleteManyAssignees(input: $input) {
  	${assigneeType}
  }
}
`;
