import { amber, cyan, green, grey, red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    fontSize: 14,
  },
  palette: {
    default: {
      tint: 'rgba(4, 145, 210, .1)',
      light: 'rgba(4, 145, 210, .6)',
      main: 'rgba(4, 145, 210, .8)',
      dark: 'rgba(4, 145, 210, 1)',
      contrastText: '#ffffff',
    },
    success: {
      tint: green[50],
      light: green[300],
      main: green[500],
      dark: green[700],
      contrastText: '#ffffff',
    },
    warning: {
      tint: amber[50],
      light: amber[300],
      main: amber[500],
      dark: amber[700],
      contrastText: '#ffffff',
    },
    info: {
      tint: cyan[50],
      light: cyan[300],
      main: cyan[500],
      dark: cyan[700],
      contrastText: '#ffffff',
    },
    danger: {
      tint: red[50],
      light: red[300],
      main: red[500],
      dark: red[700],
      contrastText: '#ffffff',
    },
    disabled: {
      tint: grey[50],
      light: grey[300],
      main: grey[500],
      dark: grey[700],
      contrastText: '#ffffff',
    },
  },
});
