const mainType = `
main {
  application_type
  default
  driver_can_override
  profile_name
  off_tire_disposition
  off_tire_disposition_reason
  single_call_indicator
  special_instructions
  profile_type
  vehicle_type
  vehicle_type_vmrs_code
  vehicle_attrs_tractor
  vehicle_attrs_trailer
  assign_to_all_locations
}
`;

const tireSpecificationsType = `
tire_service_specifications {
  first_choice
  second_choice
  third_choice
}
`;

const tirePressureRegulatorType = `
tire_pressure_regulator {
  driver_has_special_tools
  pressure_regulation_device_installed
  should_device_be_reinstalled
  special_tools_required
}
`;

const tirePressureSensorType = `
pressure_sensor {
  disposition
  installed
  location
  mounting
  types
}
`;

const tireReplacementPriorityType = `
tire_replacement_priority {
  priority
  brand
  load_range
  mspn
  note
  original_tire_depth
  tire_size
  tire_type
  tread_design
}
`;

const axlesType = `
axles {
  steer {
    ${tireSpecificationsType}
    ${tirePressureRegulatorType}
    ${tirePressureSensorType}
    ${tireReplacementPriorityType}
    activity_if_pref_unavailable
  }
  drive {
    ${tireSpecificationsType}
    ${tirePressureRegulatorType}
    ${tirePressureSensorType}
    ${tireReplacementPriorityType}
    activity_if_pref_unavailable
  }
  trailer {
    ${tireSpecificationsType}
    ${tirePressureRegulatorType}
    ${tirePressureSensorType}
    ${tireReplacementPriorityType}
    activity_if_pref_unavailable
  }
  tag {
    ${tireSpecificationsType}
    ${tirePressureRegulatorType}
    ${tirePressureSensorType}
    ${tireReplacementPriorityType}
    activity_if_pref_unavailable
  }
  lift {
    ${tireSpecificationsType}
    ${tirePressureRegulatorType}
    ${tirePressureSensorType}
    ${tireReplacementPriorityType}
    activity_if_pref_unavailable
  }
  carry {
    ${tireSpecificationsType}
    ${tirePressureRegulatorType}
    ${tirePressureSensorType}
    ${tireReplacementPriorityType}
    activity_if_pref_unavailable
  }
  pusher {
    ${tireSpecificationsType}
    ${tirePressureRegulatorType}
    ${tirePressureSensorType}
    ${tireReplacementPriorityType}
    activity_if_pref_unavailable
  }
  all_axles {
    ${tireSpecificationsType}
    ${tirePressureRegulatorType}
    ${tirePressureSensorType}
    ${tireReplacementPriorityType}
    activity_if_pref_unavailable
  }
}
`;

const schemaTireProfile = `
{
  hash_key
  range_key
  gsi1_hash_key
  gsi1_range_key
  ${mainType}
  ${axlesType}
}
`;

const schemaTireProfileMain = `
{
  application_type
  default
  driver_can_override
  profile_name
  off_tire_disposition
  off_tire_disposition_reason
  single_call_indicator
  special_instructions
  profile_type
  vehicle_type
  vehicle_type_vmrs_code
  vehicle_attrs_tractor
  vehicle_attrs_trailer
  assign_to_all_locations
}
`;

const schemaTireProfileAxles = `
{
  steer {
    ${tireSpecificationsType}
    ${tirePressureRegulatorType}
    ${tirePressureSensorType}
    ${tireReplacementPriorityType}
    activity_if_pref_unavailable
  }
  drive {
    ${tireSpecificationsType}
    ${tirePressureRegulatorType}
    ${tirePressureSensorType}
    ${tireReplacementPriorityType}
    activity_if_pref_unavailable
  }
  trailer {
    ${tireSpecificationsType}
    ${tirePressureRegulatorType}
    ${tirePressureSensorType}
    ${tireReplacementPriorityType}
    activity_if_pref_unavailable
  }
  tag {
    ${tireSpecificationsType}
    ${tirePressureRegulatorType}
    ${tirePressureSensorType}
    ${tireReplacementPriorityType}
    activity_if_pref_unavailable
  }
  lift {
    ${tireSpecificationsType}
    ${tirePressureRegulatorType}
    ${tirePressureSensorType}
    ${tireReplacementPriorityType}
    activity_if_pref_unavailable
  }
  carry {
    ${tireSpecificationsType}
    ${tirePressureRegulatorType}
    ${tirePressureSensorType}
    ${tireReplacementPriorityType}
    activity_if_pref_unavailable
  }
  pusher {
    ${tireSpecificationsType}
    ${tirePressureRegulatorType}
    ${tirePressureSensorType}
    ${tireReplacementPriorityType}
    activity_if_pref_unavailable
  }
  all_axles {
    ${tireSpecificationsType}
    ${tirePressureRegulatorType}
    ${tirePressureSensorType}
    ${tireReplacementPriorityType}
    activity_if_pref_unavailable
  }
}
`;

export { schemaTireProfile, schemaTireProfileMain, schemaTireProfileAxles };
