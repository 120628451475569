import { MaestroAPI, maestroApiCall } from '@michelin/central-provider';
import { MethodType } from '@michelin/central-provider/dist/helpers/createAPICall';
import { WheelProfileOVM } from '@michelin/fcp-view-models/dist/models/wheel-profile';
import { SelectOption } from '@michelin/select-options-provider';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { auth } from 'Auth';
import GLOBAL_CONFIG from 'global-variables';
import { TFunction } from 'i18next';
import { createWheelBrand, getAllWheelBrands, getWheelDiameters } from 'modules/Wheels/graphql';

const baseURL = GLOBAL_CONFIG.externalAPIUrl;

interface wheelProfileDeleteResult {
  id: string;
}
const deleteWheelProfile = async ({
  fleetId,
  wheelId,
}: {
  fleetId: string;
  wheelId: string;
}): Promise<wheelProfileDeleteResult | null> => {
  if (!fleetId || !wheelId) return null;
  const apiType: MaestroAPI = {
    method: 'DELETE',
    endpoint: `cp-be/wheels/${fleetId}/${wheelId}`,
    baseURL,
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return maestroApiCall(apiType);
};
export function useDeleteWheelProfile() {
  return useMutation(deleteWheelProfile);
}

// Get Wheel Profile detail data
const getWheelProfile = async (fleetId: string, wheelProfileId: string): Promise<WheelProfileOVM | null> => {
  if (!fleetId || !wheelProfileId) return null;
  const apiType: MaestroAPI = {
    method: MethodType.GET,
    endpoint: `/cp-be/wheels/${fleetId}/${wheelProfileId}`,
    baseURL: baseURL || '',
  };
  const response = await maestroApiCall<WheelProfileOVM>(apiType);
  return response;
};
export function useGetWheelProfile({ fleetId, wheelProfileId }: { fleetId: string; wheelProfileId: string }) {
  return useQuery(['wheelProfile', fleetId || '', wheelProfileId || ''], () => {
    return getWheelProfile(fleetId, wheelProfileId);
  });
}

// Update/Add Wheel Profile
const updateWheelProfile = async ({
  fleetId,
  wheelProfileId,
  wheelProfile,
  action,
}: {
  fleetId: string;
  wheelProfileId?: string;
  wheelProfile: WheelProfileOVM;
  action: 'edit' | 'add';
}): Promise<WheelProfileOVM | null> => {
  if (!fleetId) return null;
  const apiType: MaestroAPI = {
    method: action === 'edit' ? MethodType.PUT : MethodType.POST,
    endpoint: action === 'edit' ? `/cp-be/wheels/${fleetId}/${wheelProfileId}` : `/cp-be/wheels/${fleetId}/`,
    baseURL: baseURL || '',
  };
  const response = await maestroApiCall<WheelProfileOVM | { hash_key: string }>(apiType, wheelProfile);

  return response;
};
export function useUpdateWheelProfile({ fleetId, wheelProfileId }: { fleetId: string; wheelProfileId: string }) {
  const queryClient = useQueryClient();
  return useMutation(updateWheelProfile, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['wheelProfile', fleetId || '', wheelProfileId || '']);
    },
  });
}

// Get wheel diameters for the wheel diameter list on the wheel profile detail page
const getWheelDiametersData = async (): Promise<SelectOption[] | null> => {
  if (!auth || !auth.apolloClient) return null;
  let resultWheelDiameters: any = {};
  try {
    resultWheelDiameters = await auth.apolloClient.query({
      query: getWheelDiameters,
      variables: { hash_key: 'P~WheelDiameters' },
      fetchPolicy: 'network-only',
    });
    const wheelDiameters: SelectOption[] = resultWheelDiameters.data.getRimOrWheelDiameters.map((obj: any) => ({
      value: Object.values(obj)[0],
      label: Object.values(obj)[0],
    }));
    wheelDiameters.sort((a: SelectOption, b: SelectOption) => parseInt(a.label, 10) - parseInt(b.label, 10));
    return wheelDiameters;
  } catch (e) {
    console.log('Error: Wheel Profile Details: could not retrieve wheel diameters: error:', JSON.stringify(e, null, 7));
  }
  return null;
};
export function useGetWheelDiameters() {
  return useQuery(['rimWheelDiameters'], () => {
    return getWheelDiametersData();
  });
}

// Get rim widths for the rim width list on the wheel profile detail page
const getRimWidthsData = async (): Promise<SelectOption[] | null> => {
  if (!auth || !auth.apolloClient) return null;

  try {
    const resultRimWidths = await auth.apolloClient.query({
      query: getWheelDiameters,
      variables: { hash_key: 'P~RimWidths' },
      fetchPolicy: 'network-only',
    });
    const rimWidths: SelectOption[] = resultRimWidths.data.getRimOrWheelDiameters.map((obj: any) => ({
      value: Object.values(obj)[0],
      label: Object.values(obj)[0],
    }));
    rimWidths.sort((a: SelectOption, b: SelectOption) => parseInt(a.label, 10) - parseInt(b.label, 10));
    return rimWidths;
  } catch (e) {
    console.log('Error: Wheel Profile Details: could not retrieve rim widths: error:', JSON.stringify(e, null, 7));
  }
  return null;
};
export function useGetRimWidths() {
  return useQuery(['rimWidths'], () => {
    return getRimWidthsData();
  });
}

// Get wheel brands for the wheel brand list on the wheel profile detail page
interface WheelBrand {
  store: {
    type: string;
    data: SelectOption[];
    key: string;
  };
}
const getWheelBrandsData = async (t: TFunction): Promise<WheelBrand | null> => {
  if (!auth || !auth.apolloClient) return null;

  let resultWheelBrands: any = {};
  try {
    resultWheelBrands = await auth.apolloClient.query({
      query: getAllWheelBrands,
      variables: { hash_key: 'W~WheelBrand' },
      fetchPolicy: 'network-only',
    });
    const wheelBrands: SelectOption[] = resultWheelBrands.data.getAllWheelBrands.map((obj: any) => ({
      value: obj.range_key,
      label: obj.range_key,
    }));

    // splice out other if someone put it in - shouldn't really have this happen though
    if (wheelBrands.findIndex((v: SelectOption) => v.value.toLowerCase() === 'other') !== -1) {
      wheelBrands.splice(
        wheelBrands.findIndex((v: SelectOption) => v.value.toLowerCase() === 'other'),
        1,
      ); // get rid of other in list
    }

    wheelBrands.push({ label: t('Other'), value: 'other' }); // add it back in at the bottom
    return { store: { type: 'array', data: wheelBrands, key: 'brand' } };
  } catch (e) {
    console.log('Error: Wheel Profile Details: could not retrieve wheel brands: error:', e);
  }
  return null;
};
export function useGetWheelBrands(t: TFunction) {
  return useQuery(['getWheelBrands'], () => {
    return getWheelBrandsData(t);
  });
}

// Add unlisted brand to the wheel brands list on the wheel profile detail page
const updateWheelBrand = async ({ brand }: { brand: string }): Promise<boolean | null> => {
  if (!auth || !auth.apolloClient) return null;
  try {
    await auth.apolloClient.mutate({
      mutation: createWheelBrand,
      variables: {
        range_key: brand,
      },
    });
    return true;
  } catch (e) {
    console.log('Error: Add unlisted brand: could not add wheel brand: error:', JSON.stringify(e, null, 7));
    return null;
  }
};
export function useUpdateWheelBrand() {
  const queryClient = useQueryClient();
  return useMutation(updateWheelBrand, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['getWheelBrands']);
    },
  });
}
