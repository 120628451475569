import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { withTheme } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  summaryContainer: {
    borderRadius: '5px',
    width: '600px',
    padding: '5px',
  },
}));

const WheelListComments = (props: any) => {
  const classes = useStyles(props);
  if (!props.detail) return <></>;

  return (
    <Grid container className={classes.summaryContainer}>
      <Grid item xs={12} lg={12}>
        {props.detail.comments}
      </Grid>
    </Grid>
  );
};

export default withTheme(WheelListComments);
