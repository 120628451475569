/* eslint-disable no-param-reassign */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable max-len */

/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { ArrowBack, ArrowForward, Cancel, Edit, Save, Timelapse } from '@material-ui/icons';
import { Container, LoadingBounce, Panel, PreventTransitionPrompt, useDialogs } from '@michelin/acid-components';
import { usePermissions, useSnackbar, useTranslation } from '@michelin/central-provider';
import LastModified from 'components/ChangeLog/LastModified';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { StepperActions, StepperComponent, StepperStep } from 'components/Stepper';
import { CONTENT_AREA_HEIGHT } from 'components/Util';
import { getAssociateTireProfiles, useCreateSOP, useGetTireProfiles, useSOP, useUpdateSOP } from 'hooks/useSOP';
import { cloneDeep, isEqual, uniq, uniqWith } from 'lodash';
import { getBaseUrl } from 'prefs-and-service-offers';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import SOPBrandPreferences from './BrandPreferences';
import SOPGeneralInfo from './GeneralInfo';
import SOPInflation from './InflationPressures';
import SOPPullPoint from './PullPoints';
import {
  Actions,
  SOPInflation as SOPInflationType,
  SOPProfile,
  SOPPullPoint as SOPPullPointType,
  SOPTireProfile,
  SOPWheelBalance as SOPWheelBalanceType,
} from './Types';
import SOPWheelBalance from './WheelBalancing';

interface StepperConentProps {
  step: number;
  title: string;
  tireProfilesList: any;
  tireProfile: SOPTireProfile[] | null;
  action: Actions;
  modalState: { action: Actions; open: boolean };
  handleModalState: (action: Actions, open: boolean) => void;
}

function StepperContent(props: StepperConentProps) {
  switch (props.step) {
    case 0:
      return <SOPGeneralInfo action={props.action} tireProfilesList={props.tireProfilesList} />;
    case 1:
      return (
        <SOPInflation
          action={props.action}
          modalState={props.modalState}
          handleModalState={props.handleModalState}
          tireProfile={props.tireProfile}
        />
      );
    case 2:
      return (
        <SOPPullPoint
          action={props.action}
          modalState={props.modalState}
          handleModalState={props.handleModalState}
          tireProfile={props.tireProfile}
        />
      );
    case 3:
      return (
        <SOPWheelBalance
          action={props.action}
          modalState={props.modalState}
          handleModalState={props.handleModalState}
          tireProfile={props.tireProfile}
        />
      );
    case 4:
      return (
        <SOPBrandPreferences
          action={props.action}
          modalState={props.modalState}
          handleModalState={props.handleModalState}
        />
      );
    default:
      return <div>{`${props.step} - ${props.title}`}</div>;
  }
}

interface SOPStepperProps {
  step: number;
  action: Actions;
}

function SOPStepper(props: SOPStepperProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const permissions = usePermissions();
  const { enqueueSnackbar } = useSnackbar();
  const { alertDialog, errorDialog } = useDialogs();
  const { profileId } = useParams<{ profileId: string }>();

  const sopData = useSOP({ fleetId: permissions.location?.customer_number, profileId });
  const createSOP = useCreateSOP((permissions.location?.customer_number || '').toString());
  const updateSOP = useUpdateSOP((permissions.location?.customer_number || '').toString(), profileId);
  const tireProfilesList = useGetTireProfiles({ fleetId: permissions.location?.customer_number || '' });

  const methods = useForm<SOPProfile>({ defaultValues: {} });

  const [avoidTransition, setAvoidTransition] = useState(false);
  const [oldProfile, setOldProfile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState<number>(props.step);
  const [tireProfile, setTireProfile] = useState<SOPTireProfile[] | null>(null);
  const [modalState, setModalState] = useState<{ action: Actions; open: boolean }>({ action: '', open: false });
  const [steps, setSteps] = useState<StepperStep[]>([
    { label: t('General Information'), error: false },
    { label: t('Inflation Pressures'), error: false },
    { label: t('Tread Depth Pull Points'), error: false },
    { label: t('Wheel Balancing'), error: false },
    { label: t('Brand Preferences'), error: false },
  ]);

  const getStepUrl = (stepIndex: number): string => {
    let step = 'general-info';
    switch (stepIndex) {
      case 0:
        step = 'general-info';
        break;
      case 1:
        step = 'inflation-pressures';
        break;
      case 2:
        step = 'tread-depth-pull-points';
        break;
      case 3:
        step = 'wheel-balancing';
        break;
      case 4:
        step = 'brand-preferences';
        break;
      default:
        step = 'general-info';
        break;
    }

    if (props.action === 'add') return `${getBaseUrl()}/sop/${step}/add`;
    if (!profileId) return `${getBaseUrl()}/sop/list`;
    if (props.action === 'edit') return `${getBaseUrl()}/sop/${profileId}/${step}/edit`;
    return `${getBaseUrl()}/sop/${profileId}/${step}/view`;
  };

  const checkErrors = (tp: SOPTireProfile[], sopItem: SOPInflationType | SOPPullPointType): string[] => {
    const errors = [];
    // -------------------------- TIRE SIZE
    const validOptionsList = tp.filter((item) => item.tire_size === sopItem.size);
    if (!tp.map((item) => item.tire_size).includes(sopItem.size || '')) errors.push(sopItem.size || '');
    // -------------------------- AXLE
    const diffAxle = sopItem.axle_types?.filter((x) => !validOptionsList.map((item) => item.axle).includes(x)) || [];
    errors.push(...diffAxle);
    // -------------------------- APPLICATION
    const diffApp =
      sopItem.application_types?.filter((x) => !validOptionsList.map((item) => item.application_type).includes(x)) ||
      [];
    errors.push(...diffApp);
    // -------------------------- VEHICLE
    const diffVei =
      sopItem.vehicle_types?.filter((x) => !validOptionsList.map((item) => item.vehicle_type).includes(x)) || [];
    errors.push(...diffVei);

    switch (sopItem.type) {
      case 'inflation': {
        const cloneSopItem = sopItem as SOPInflationType;
        if (!cloneSopItem.uom) errors.push('uom');
        break;
      }
      case 'pull_point': {
        const cloneSopItem = sopItem as SOPPullPointType;
        if (!cloneSopItem.uom) errors.push('uom');
        if (!cloneSopItem.dual_tread_depth_matching) errors.push('dual_tread_depth_matching');
        break;
      }
      default:
        break;
    }

    return errors;
  };

  const checkErrorsWheel = (tp: SOPTireProfile[], sopItem: SOPWheelBalanceType): string[] => {
    const errors = [];
    const vo = uniq(tp.map((item) => item.axle));
    if (!vo.includes(sopItem.axle_type || '')) errors.push(sopItem.axle_type || '');

    const axleList = tp.filter((item) => item.axle === sopItem.axle_type || '');
    if (!axleList.map((item) => item.application_type).includes(sopItem.application_type || ''))
      errors.push(sopItem.application_type || '');

    const applicationList = axleList.filter((item) => item.application_type === sopItem.application_type);
    if (!applicationList.map((item) => item.vehicle_type).includes(sopItem.vehicle_type || ''))
      errors.push(sopItem.vehicle_type || '');
    if (!sopItem.uom) errors.push('uom');

    return errors;
  };

  const checkStepInfo = (tp: SOPTireProfile[]) => {
    const cloneSopProfile = cloneDeep(methods.getValues());

    cloneSopProfile.inflation?.inflations?.forEach((i) => {
      i.tire_profile_errors = [];
      i.tire_profile_errors?.push(...checkErrors(tp, i));
    });

    if (cloneSopProfile.inflation && cloneSopProfile.inflation?.inflations)
      methods.setValue('inflation.inflations', cloneSopProfile.inflation.inflations);

    cloneSopProfile.pull_point?.pull_points?.forEach((i) => {
      i.tire_profile_errors = [];
      i.tire_profile_errors?.push(...checkErrors(tp, i));
    });

    if (cloneSopProfile.pull_point && cloneSopProfile.pull_point?.pull_points)
      methods.setValue('pull_point.pull_points', cloneSopProfile.pull_point.pull_points);

    cloneSopProfile.wheel_balance?.wheel_balances?.forEach((i) => {
      i.tire_profile_errors = [];
      i.tire_profile_errors?.push(...checkErrorsWheel(tp, i));
    });

    if (cloneSopProfile.wheel_balance && cloneSopProfile.wheel_balance?.wheel_balances)
      methods.setValue('wheel_balance.wheel_balances', cloneSopProfile.wheel_balance.wheel_balances);
  };

  const checkAllOption = (value: string | undefined | null) => (value || '').toLocaleLowerCase().indexOf('all') > -1;

  const clearStepInfo = (tp: SOPTireProfile[]) => {
    if (!oldProfile) return;
    const cloneSopProfile = cloneDeep(methods.getValues());
    let newId = 0;
    let newInflations: boolean = false;
    let newPullPoints: boolean = false;
    let newWheelBalance: boolean = false;

    const uniqSize = uniq(tp.map((item) => item.tire_size));

    const inflationAllOptions = cloneSopProfile.inflation?.inflations.filter((i) => checkAllOption(i.size));
    const pullAllOptions = cloneSopProfile.pull_point?.pull_points.filter((i) => checkAllOption(i.size));
    const wheelAllOptions = cloneSopProfile.wheel_balance?.wheel_balances.filter((i) => checkAllOption(i.axle_type));

    if (methods.formState.dirtyFields.tire_profiles && props.action !== 'view') {
      newId =
        (cloneSopProfile.inflation?.inflations.map((v) => v.id).filter(Boolean).length === 0
          ? 0
          : Math.max(...cloneSopProfile.inflation?.inflations.map((v) => v.id))) + 1;
      inflationAllOptions.forEach((inflation) => {
        uniqSize.forEach((size) => {
          const tireSize = tp.filter((item) => item.tire_size === size);
          const isAll = { axle: false, app: false, vehi: false };

          tireSize.forEach((item) => {
            isAll.axle = checkAllOption(item.axle) ? true : isAll.axle;
            isAll.app = checkAllOption(item.application_type) ? true : isAll.app;
            isAll.vehi = checkAllOption(item.vehicle_type) ? true : isAll.vehi;
          });

          const uniqAxle = uniq(tireSize.map((item) => item.axle).concat(inflation.axle_types || []));
          const uniqApp = uniq(tireSize.map((item) => item.application_type).concat(inflation.application_types || []));
          const uniqVehi = uniq(tireSize.map((item) => item.vehicle_type).concat(inflation.vehicle_types || []));

          newInflations = true;
          cloneSopProfile.inflation?.inflations.push({
            ...inflation,
            id: newId++,
            size,
            axle_types: isAll.axle ? ['all_axles'] : uniqAxle,
            application_types: isAll.app ? ['all'] : uniqApp,
            vehicle_types: isAll.vehi ? ['ALL'] : uniqVehi,
          });
        });
      });

      newId =
        (cloneSopProfile.pull_point?.pull_points.map((v) => v.id).filter(Boolean).length === 0
          ? 0
          : Math.max(...cloneSopProfile.pull_point?.pull_points.map((v) => v.id))) + 1;
      pullAllOptions.forEach((pullPoint) => {
        uniqSize.forEach((size) => {
          const tireSize = tp.filter((item) => item.tire_size === size);
          const isAll = { axle: false, app: false, vehi: false };

          tireSize.forEach((item) => {
            isAll.axle = checkAllOption(item.axle) ? true : isAll.axle;
            isAll.app = checkAllOption(item.application_type) ? true : isAll.app;
            isAll.vehi = checkAllOption(item.vehicle_type) ? true : isAll.vehi;
          });

          const uniqAxle = uniq(tireSize.map((item) => item.axle).concat(pullPoint.axle_types || []));
          const uniqApp = uniq(tireSize.map((item) => item.application_type).concat(pullPoint.application_types || []));
          const uniqVehi = uniq(tireSize.map((item) => item.vehicle_type).concat(pullPoint.vehicle_types || []));

          newPullPoints = true;
          cloneSopProfile.pull_point?.pull_points.push({
            ...pullPoint,
            id: newId++,
            size,
            axle_types: isAll.axle ? ['all_axles'] : uniqAxle,
            application_types: isAll.app ? ['all'] : uniqApp,
            vehicle_types: isAll.vehi ? ['ALL'] : uniqVehi,
          });
        });
      });

      newId =
        (cloneSopProfile.wheel_balance?.wheel_balances.map((v) => v.id).filter(Boolean).length === 0
          ? 0
          : Math.max(...cloneSopProfile.wheel_balance?.wheel_balances.map((v) => v.id))) + 1;
      wheelAllOptions.forEach((wheel) => {
        const uniqAxle = uniqWith(
          tp.map((item) => ({
            axle: item.axle,
            application: item.application_type,
            vehicle: item.vehicle_type,
          })),
          isEqual,
        );

        uniqAxle.forEach((axle) => {
          newWheelBalance = true;
          cloneSopProfile.wheel_balance?.wheel_balances.push({
            ...wheel,
            id: newId++,
            axle_type: axle.axle,
            application_type: checkAllOption(axle.application) ? 'all' : wheel.application_type,
            vehicle_type: checkAllOption(axle.vehicle) ? 'ALL' : wheel.vehicle_type,
          });
        });
      });
    }

    if (newInflations || newPullPoints || newWheelBalance) {
      alertDialog(
        t(
          'Since you have modify the tire profile, changes may happen on the other steps, please review them carefully.',
        ),
        t('SOP Changes.'),
      );

      if (newInflations) {
        const newItems = cloneSopProfile.inflation?.inflations.filter(
          (i) => !(checkAllOption(i.size) || checkAllOption(i.axle_type)),
        );
        methods.setValue('inflation.inflations', newItems, { shouldDirty: true });
      }
      if (newPullPoints) {
        const newItems = cloneSopProfile.pull_point?.pull_points.filter(
          (i) => !(checkAllOption(i.size) || checkAllOption(i.axle_type)),
        );
        methods.setValue('pull_point.pull_points', newItems, { shouldDirty: true });
      }
      if (newWheelBalance) {
        const newItems = cloneSopProfile.wheel_balance?.wheel_balances.filter((i) => !checkAllOption(i.axle_type));
        methods.setValue('wheel_balance.wheel_balances', newItems, { shouldDirty: true });
      }
      setOldProfile(false);
    }
  };

  const fetchData = async (tire_profiles: string[]) => {
    setIsLoading(true);
    const tp = await getAssociateTireProfiles(permissions.location?.customer_number, tire_profiles);

    if (tp) {
      setTireProfile(tp);
      clearStepInfo(tp);
      checkStepInfo(tp);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (sopData.isFetching || !sopData.data) return;
    methods.reset(sopData.data);
  }, [sopData.isFetching]);

  useEffect(() => {
    if (!sopData.data) return;
    if (currentStep !== 0 && sopData.data.tire_profiles) fetchData(sopData.data.tire_profiles || []);
    if (!sopData.data.tire_profiles || sopData.data.tire_profiles.length === 0) setOldProfile(true);
    if (props.action === 'view') {
      const cloneSteps = steps;
      cloneSteps.forEach((s) => {
        s.error = false;
      });
      setSteps(cloneSteps);
    }
  }, [sopData.isLoading, props.action]);

  useEffect(() => {
    setAvoidTransition(false);
    setCurrentStep(props.step);
  }, [props.step]);

  useEffect(() => {
    if (props.action === 'view' || !tireProfilesList.data) return;

    const list = tireProfilesList.data
      .filter((item: any) => methods.getValues().tire_profiles?.includes(item.id))
      .map((i: any) => i.id);

    if (
      !isEqual(list, methods.getValues().tire_profiles) ||
      methods.getValues().tire_profiles?.length === 0 ||
      list.length === 0
    ) {
      if (currentStep !== 0) history.push(getStepUrl(0));
      methods.setValue('tire_profiles', list);
    }
  }, [props.action, tireProfilesList.data]);

  const validateSteps = async (noMessage: boolean = false) => {
    const messages: string[] = [];
    const cloneSteps = cloneDeep(steps);
    const clonseSopProfile = cloneDeep(methods.getValues());
    const emptySteps =
      !(clonseSopProfile.inflation?.inflations.length > 0) &&
      !(clonseSopProfile.pull_point?.pull_points.length > 0) &&
      !(clonseSopProfile.wheel_balance?.wheel_balances.length > 0);

    // Validate only Inflation or Wheel balancing or Pull points Data steps, Since only one of then is required
    cloneSteps[0].error = !(await methods.trigger(['profile_name', 'tire_profiles']));
    cloneSteps[1].error = clonseSopProfile.inflation?.inflations?.some((i) => (i.tire_profile_errors || []).length > 0);
    cloneSteps[2].error = clonseSopProfile.pull_point?.pull_points?.some(
      (i) => (i.tire_profile_errors || []).length > 0,
    );
    cloneSteps[3].error = clonseSopProfile.wheel_balance?.wheel_balances?.some(
      (i) => (i.tire_profile_errors || []).length > 0,
    );

    cloneSteps.forEach((s, i) => {
      const message = i === 0 ? '' : `: ${t('You need at least one')} ${steps[i].label}`;

      if (s.error) messages.push(`${t('Step')} ${i + 1} - ${steps[i].label}`);
      // if user has not entered any of the mandotory Data( Inflation/Wheel Balancing/pull points Data )
      if (emptySteps && (i === 1 || i === 2 || i === 3)) {
        messages.push(`${t('Step')} ${i + 1} - ${steps[i].label}${message}`);
        // To add OR in b/w error msgs
        if (steps.length !== i + 2 || i === 1) messages.push(t('OR'));
        cloneSteps[i].error = true;
      }
    });

    setSteps(cloneSteps);
    if (messages.length > 0 && !noMessage) {
      messages.push(t('Please review them and try again.'));
      errorDialog(messages, t('Errors found on different steps.'));
      return false;
    }

    return true;
  };

  const handleModalState = (action: Actions, open: boolean) => {
    setModalState({ action, open });
  };

  const handleStep = async (step: number, targetStep: number) => {
    const clonseSopProfile = cloneDeep(methods.getValues());
    let isValid = true;

    switch (step) {
      case 0: {
        if (props.action !== 'view') isValid = await methods.trigger(['profile_name', 'tire_profiles']);
        if (props.action === 'view') isValid = true;
        if (isValid && (clonseSopProfile.tire_profiles || []).length > 0)
          await fetchData(clonseSopProfile.tire_profiles || []);
        break;
      }
      default:
        break;
    }

    if (isValid) {
      if (props.action === 'add' && methods.formState.isSubmitted) await validateSteps(true);
      if (props.action === 'edit') await validateSteps(true);
      await setAvoidTransition(true);
      history.push(getStepUrl(targetStep));
    }
  };

  const onError = () => {
    errorDialog(t('Please check the marked fields.').split('\n'), t('Error saving SOP Profile.'));
  };

  const save = async (data: SOPProfile) => {
    if (!methods.formState.isDirty) return;
    setAvoidTransition(true);

    if (await validateSteps()) {
      if (props.action === 'add') {
        createSOP
          .mutateAsync({ fleetId: permissions.location?.customer_number, data })
          .then((value: any) => {
            // methods.reset(data);
            history.push(`${getBaseUrl()}/sop/${value?.profile.hash_key?.split('~')[1]}/general-info/view`);
            enqueueSnackbar(t('SOP Profile Saved.'), {
              variant: 'success',
              anchorOrigin: { vertical: 'top', horizontal: 'center' },
            });
          })
          .catch((err) => {
            errorDialog(
              [
                ...((err as any).body && typeof (err as any).body === 'string'
                  ? [`${t((err as any).body)}.`, <br />]
                  : []),
                ...'Please check your connection and try again.\nIf the issue persist, please contact support'.split(
                  '\n',
                ),
              ],
              t('Error saving SOP Profile.'),
            );
          });
      }
      if (props.action === 'edit') {
        updateSOP
          .mutateAsync({ fleetId: permissions.location?.customer_number, profileId, data })
          .then(() => {
            // methods.reset(data);
            history.push('view');
            enqueueSnackbar(t('SOP Profile Updated.'), {
              variant: 'success',
              anchorOrigin: { vertical: 'top', horizontal: 'center' },
            });
          })
          .catch((err) => {
            errorDialog(
              [
                ...((err as any).body && typeof (err as any).body === 'string'
                  ? [`${t((err as any).body)}.`, <br />]
                  : []),
                ...'Please check your connection and try again.\nIf the issue persist, please contact support'.split(
                  '\n',
                ),
              ],
              t('Error updating SOP Profile.'),
            );
          });
      }
    }
  };

  const stepperActions: StepperActions[] = [
    {
      label: t('Save'),
      disabled: currentStep + 1 < steps.length || props.action === 'view' || !methods.formState.isDirty,
      displayDisabled: currentStep === 4,
      onClick: () => methods.handleSubmit(save, onError)(),
      icon: isLoading || createSOP.isLoading || updateSOP.isLoading ? <Timelapse /> : <Save />,
    },
    {
      label: t('Edit'),
      disabled: props.action !== 'view' || isLoading,
      onClick: () => history.push('edit'),
      displayDisabled: props.action === 'view' && isLoading,
      icon: isLoading ? <Timelapse /> : <Edit />,
    },
    {
      label: t('Cancel'),
      disabled: props.action !== 'edit' || isLoading,
      onClick: () => history.push('view'),
      displayDisabled: props.action === 'edit' && isLoading,
      icon: <Cancel />,
    },
    {
      label: isLoading ? t('Loading...') : t('Next'),
      disabled: currentStep + 1 === steps.length || isLoading,
      displayDisabled: true,
      onClick: () => handleStep(currentStep, currentStep + 1),
      icon: <ArrowForward />,
    },
    {
      label: t('Back'),
      disabled: isLoading,
      displayDisabled: true,
      onClick: () =>
        currentStep === 0 ? history.push(`${getBaseUrl()}/sop/list`) : handleStep(currentStep, currentStep - 1),
      icon: <ArrowBack />,
    },
  ];

  if (sopData.isLoading || tireProfilesList.isLoading) return <LoadingBounce style={{ height: CONTENT_AREA_HEIGHT }} />;

  return (
    <>
      <PageTitle title={t('Standard Operating Procedures')} rightItems={<LastModified entity="service_pricing" />} />
      <Container height={CONTENT_AREA_HEIGHT}>
        <Panel>
          <StepperComponent
            activeStep={currentStep}
            steps={steps}
            setStepperStep={(targetStep: number) => handleStep(currentStep, targetStep)}
            stepperActions={stepperActions}
          >
            <FormProvider {...methods}>
              <StepperContent
                step={currentStep}
                title={steps[currentStep].label}
                action={props.action}
                modalState={modalState}
                handleModalState={handleModalState}
                tireProfile={tireProfile}
                tireProfilesList={tireProfilesList.data}
              />
            </FormProvider>
          </StepperComponent>
        </Panel>
        <PreventTransitionPrompt
          when={avoidTransition ? false : methods.formState.isDirty}
          handleDiscard={() => methods.reset()}
        />
      </Container>
    </>
  );
}
export default SOPStepper;
