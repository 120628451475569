import { auth } from 'Auth';
import { FieldArrayWithId } from 'react-hook-form';
import { SOPInflation, SOPProfile, SOPPullPoint, SOPTireProfile, SOPTireProfileFilter, SOPWheelBalance } from './Types';

export const filterTireProfile = (
  tireProfile: SOPTireProfile[] | null,
  size: string | null,
  sizeList: string[] | null,
  axleType: string[] | undefined,
  applicationType: string[] | undefined,
  vehicleType: string[] | undefined,
): SOPTireProfileFilter => {
  if (!tireProfile) {
    return {
      tireSizesList: [{ value: size || '', label: size || '' }],
      axleTypesList: [axleType?.pop() || ''],
      applicationTypesList: [applicationType?.pop() || ''],
      vehicleTypesList: [vehicleType?.pop() || ''],
    };
  }

  const tireSizes = [...new Set(tireProfile?.map((row) => row.tire_size))].map((v) => ({
    label: v,
    value: v,
    disabled: sizeList?.includes(v),
  }));
  const axleTypes = size ? tireProfile?.filter((tp) => tp.tire_size === size) : tireProfile;
  const applicationTypes = axleTypes?.filter((tp) => axleType?.includes(tp.axle));
  const vehicleTypes = applicationTypes?.filter((tp) => applicationType?.includes(tp.application_type));

  // Adding the not valid options when is a old profile.
  if (size && !tireSizes.find((i) => i.value === size)) tireSizes.push({ value: size, label: size, disabled: true });

  axleType?.forEach((a) => {
    if (!axleTypes?.find((item) => item.axle === a)) {
      axleTypes?.push({
        vehicle_type: '',
        application_type: '',
        axle: a,
        tire_size: '',
      });
    }
  });

  applicationType?.forEach((a) => {
    if (!applicationTypes?.find((item) => item.application_type === a)) {
      applicationTypes?.push({
        vehicle_type: '',
        application_type: a,
        axle: '',
        tire_size: '',
      });
    }
  });

  vehicleType?.forEach((v) => {
    if (!vehicleTypes?.find((item) => item.vehicle_type === v)) {
      vehicleTypes?.push({
        vehicle_type: v,
        application_type: '',
        axle: '',
        tire_size: '',
      });
    }
  });

  return {
    tireSizesList: tireSizes,
    axleTypesList: [...new Set(axleTypes.map((tp) => tp.axle))],
    applicationTypesList: [...new Set(applicationTypes.map((tp) => tp.application_type))],
    vehicleTypesList: [...new Set(vehicleTypes.map((tp) => tp.vehicle_type))],
  };
};

export const saveUseFieldArray = (
  e: SOPInflation | SOPPullPoint | SOPWheelBalance,
  tireProfile: SOPTireProfile[] | null,
  fields: FieldArrayWithId<
    SOPProfile,
    'inflation.inflations' | 'pull_point.pull_points' | 'wheel_balance.wheel_balances',
    'key'
  >[],
  // append: UseFieldArrayAppend<SOPProfile, 'inflation.inflations' | 'pull_point.pull_points' | 'wheel_balance.wheel_balances'>,
  append: any,
) => {
  let newId = (fields.length === 0 ? 0 : Math.max(...fields.map((v) => v.id))) + 1;

  e.vehicle_types?.forEach((vehicle: string) => {
    e.application_types?.forEach((application: string) => {
      e.axle_types?.forEach((axle: string) => {
        const cloneData = { ...e };

        append({
          ...cloneData,
          id: newId++,
          axle_types: undefined,
          application_types: undefined,
          vehicle_types: undefined,
          axle_type: axle,
          application_type: application,
          vehicle_type: vehicle,
        });
      });
    });
  });
};

export function levelAllowed(customer_type: string): boolean {
  const account_level = auth.getAccountTypeShort();
  let response = false;
  switch (account_level) {
    case 'PC':
      response = true;
      break;
    case 'HO':
      response = customer_type !== 'PC';
      break;
    case 'BT':
      response = customer_type !== 'PC' && customer_type !== 'HO';
      break;
    default:
      response = customer_type === 'ST';
      break;
  }
  return response;
}
