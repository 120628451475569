import React from 'react';
import { useTranslation } from '@michelin/central-provider';
import {
  ERS_MANAGER_ROLE_AREA_VALUE,
  PO_ISSUER_ROLE_AREA_VALUE,
  PRIMARY_LEVEL_VALUE,
  SECONDARY_LEVEL_VALUE,
} from 'components/Contact/ContactDetails/utils';
import { renderContactInformation } from 'components/Contact/ContactList';
import { CheckBox, DataGrid } from 'devextreme-react';
import { Column } from 'devextreme-react/data-grid';
import { BillingProfileLocation } from '../../query';
import { BillingReassignContactType } from '../dialogs';
import { BillingReassignContact } from '../utils';

interface Props {
  location: BillingProfileLocation;
  contacts: Array<BillingReassignContact> | undefined;
  assignedContactTypes: Map<string, BillingReassignContactType>;
  removedAssignedContactTypes: Set<string>;
  contactTypesFromContacts: Map<string, BillingReassignContact>;
  contactTypeLevels: Array<string>;
  contactTypes: Array<string>;
  onChange: (level: string, roleArea: string, contact: BillingReassignContact, checked: boolean) => void;
}

function selectorRender(
  location: BillingProfileLocation,
  level: string,
  roleArea: string,
  onChange: (level: string, roleArea: string, contact: BillingReassignContact, checked: boolean) => void,
  assignedContactTypes: Map<string, BillingReassignContactType>,
  removedAssignedContactTypes: Set<string>,
  contactTypesFromContacts: Map<string, BillingReassignContact>,
) {
  const alreadyAssignedContact = assignedContactTypes.get(`${location.customer_number}~${level}~${roleArea}`);
  const alreadySavedContact = contactTypesFromContacts.get(`${roleArea}~${level}`);

  // If level === Secondary check that already exists the Primary level
  if (level === SECONDARY_LEVEL_VALUE) {
    const primaryContactTypeInContacts = contactTypesFromContacts.get(`${roleArea}~${PRIMARY_LEVEL_VALUE}`);
    const assignedPrimaryContactTypeInContacts = assignedContactTypes.get(
      `${location.customer_number}~${level}~${PRIMARY_LEVEL_VALUE}`,
    );
    return (row: any) => {
      const { data } = row;
      const checked =
        ((alreadyAssignedContact && alreadyAssignedContact.contact.hash_key === data.hash_key) ||
          (alreadySavedContact && alreadySavedContact.hash_key === data.hash_key)) &&
        !removedAssignedContactTypes.has(`${location.customer_number}~${level}~${roleArea}`);

      // Disable if is already assigned in some contact or if is a secondary contact type assign without a primary already assigned
      const disable =
        contactTypesFromContacts.has(`${roleArea}~${level}`) ||
        (primaryContactTypeInContacts === undefined && assignedPrimaryContactTypeInContacts === undefined) ||
        (primaryContactTypeInContacts && primaryContactTypeInContacts.hash_key === data.hash_key) ||
        (assignedPrimaryContactTypeInContacts &&
          assignedPrimaryContactTypeInContacts.contact.hash_key === data.hash_key);

      return (
        <CheckBox
          disabled={disable}
          value={checked || false}
          onValueChanged={(e) => {
            onChange(level, roleArea, data, e.value);
          }}
        />
      );
    };
  }
  return (row: any) => {
    const { data } = row;
    const checked =
      ((alreadyAssignedContact && alreadyAssignedContact.contact.hash_key === data.hash_key) ||
        (alreadySavedContact && alreadySavedContact.hash_key === data.hash_key)) &&
      !removedAssignedContactTypes.has(`${location.customer_number}~${level}~${roleArea}`);

    const disable = contactTypesFromContacts.has(`${roleArea}~${level}`);

    return (
      <CheckBox
        disabled={disable}
        value={checked || false}
        onValueChanged={(e) => {
          onChange(level, roleArea, data, e.value);
        }}
      />
    );
  };
}

function contactNameRender(row: any) {
  const { data } = row;
  const { first_name, last_name } = data;
  return `${first_name} ${last_name}`;
}

function emailPhoneRender(row: any) {
  const { data } = row;
  return renderContactInformation(data);
}

export default function SelectContactTypesTable(props: Props): JSX.Element {
  const { location, contacts, assignedContactTypes, removedAssignedContactTypes, contactTypesFromContacts, onChange } =
    props;
  const { t } = useTranslation();
  const contactTypeLevelsSet = new Set(props.contactTypeLevels);
  const contactTypesSet = new Set(props.contactTypes);

  return (
    <DataGrid
      dataSource={contacts}
      repaintChangesOnly
      columnAutoWidth
      style={{ minHeight: '50vh' }}
      noDataText={t('There are no contacts at this location. Create a new contact by clicking the add button.')}
    >
      <Column
        visible={contactTypeLevelsSet.has(PRIMARY_LEVEL_VALUE) && contactTypesSet.has(PO_ISSUER_ROLE_AREA_VALUE)}
        buttons
        alignment="center"
        caption={t('Primary Tire PO Issuer')}
        cellRender={selectorRender(
          location,
          PRIMARY_LEVEL_VALUE,
          PO_ISSUER_ROLE_AREA_VALUE,
          onChange,
          assignedContactTypes,
          removedAssignedContactTypes,
          contactTypesFromContacts,
        )}
      />
      <Column
        visible={contactTypeLevelsSet.has(PRIMARY_LEVEL_VALUE) && contactTypesSet.has(ERS_MANAGER_ROLE_AREA_VALUE)}
        buttons
        alignment="center"
        caption={t('Primary Tire ERS Manager')}
        cellRender={selectorRender(
          location,
          PRIMARY_LEVEL_VALUE,
          ERS_MANAGER_ROLE_AREA_VALUE,
          onChange,
          assignedContactTypes,
          removedAssignedContactTypes,
          contactTypesFromContacts,
        )}
      />
      <Column
        visible={contactTypeLevelsSet.has(SECONDARY_LEVEL_VALUE) && contactTypesSet.has(PO_ISSUER_ROLE_AREA_VALUE)}
        buttons
        alignment="center"
        caption={t('Secondary Tire PO Issuer')}
        cellRender={selectorRender(
          location,
          SECONDARY_LEVEL_VALUE,
          PO_ISSUER_ROLE_AREA_VALUE,
          onChange,
          assignedContactTypes,
          removedAssignedContactTypes,
          contactTypesFromContacts,
        )}
      />
      <Column
        visible={contactTypeLevelsSet.has(SECONDARY_LEVEL_VALUE) && contactTypesSet.has(ERS_MANAGER_ROLE_AREA_VALUE)}
        buttons
        alignment="center"
        caption={t('Secondary Tire ERS Manager')}
        cellRender={selectorRender(
          location,
          SECONDARY_LEVEL_VALUE,
          ERS_MANAGER_ROLE_AREA_VALUE,
          onChange,
          assignedContactTypes,
          removedAssignedContactTypes,
          contactTypesFromContacts,
        )}
      />
      <Column caption={t('Contact Name')} cellRender={contactNameRender} />
      <Column caption={t('Job Title')} dataField="job_title" />
      <Column caption={t('Email/Phone')} cellRender={emailPhoneRender} />
    </DataGrid>
  );
}
