import { MaestroAPI, maestroApiCall } from '@michelin/central-provider';
import { MethodType } from '@michelin/central-provider/dist/helpers/createAPICall';
import { ProductOVM, ResponseOVM, TireProfileDefaultOVM, TireProfileOVM } from '@michelin/fcp-view-models';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Actions } from 'components/SOPs/Types';
import GLOBAL_CONFIG from 'global-variables';
import _ from 'lodash';
import { ErrorHandler, sortedAxlesBySizeAndPriority } from 'modules/Tires/Details/utils';

const baseURL = GLOBAL_CONFIG.externalAPIUrl;

export interface VehicleTypeList {
  id: string;
  profile_name: string;
  application_type: string;
  vehicle_type: string;
  assign_to_all_locations: boolean;
  locations: { hash_key: string; range_key: string }[];
  disabled: boolean;
}
export function useGetVehicleTypeList({ fleetId }: { fleetId: string }) {
  return useQuery(['get-vehicle-type-list', fleetId], () => getVehicleTypeList({ fleetId }));
}

const getVehicleTypeList = async ({ fleetId }: { fleetId: string }): Promise<string[]> => {
  if (!fleetId) throw new Error('Invalid params');
  const apiType: MaestroAPI = { method: 'GET', endpoint: `cp-be/tires/${fleetId}/list-vehicle-types`, baseURL };
  const res = await maestroApiCall(apiType);
  return res;
};

interface TireProfileDeleteResult {
  hash_key: string;
  range_key: string;
  is_deleted: boolean;
}
const deleteTireProfile = async ({
  fleetId,
  tireId,
}: {
  fleetId: string;
  tireId: string;
}): Promise<TireProfileDeleteResult | null> => {
  if (!fleetId || !tireId) return null;
  const apiType: MaestroAPI = {
    method: 'DELETE',
    endpoint: `cp-be/tires/${fleetId}/${tireId}`,
    baseURL,
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return maestroApiCall(apiType);
};
export function useDeleteTireProfile() {
  return useMutation(deleteTireProfile);
}

// ||--------------------------------------------------------------------------------------|| //
// ||    NEW ENDPOINTS                                                                     || //
// ||--------------------------------------------------------------------------------------|| //
async function getTireProfile(profileId: string, accountNumber: string, action: Actions) {
  if (action === 'add')
    return {
      id: '',
      owner: {},
      main: {
        profile_type: '',
        profile_name: '',
        default: false,
        application_type: '',
        vehicle_type_vmrs_code: '',
        vehicle_attrs_tractor: '',
        vehicle_attrs_trailer: '',
        off_tire_disposition: '',
        off_tire_disposition_reason: '',
        driver_can_override: false,
        single_call_indicator: false,
        assigned_locations: [],
        assign_to_all_locations: false,
      },
      axles: {
        steer: null,
        drive: null,
        trailer: null,
        tag: null,
        lift: null,
        carry: null,
        pusher: null,
        all_axles: null,
      },
    };

  if (!profileId || !accountNumber) return null;

  const res = await maestroApiCall({ method: 'GET', endpoint: `cp-be/tires/${accountNumber}/${profileId}`, baseURL });
  const tireProfileData = _.cloneDeep(res);
  const axles = tireProfileData.axles;
  delete tireProfileData.axles;
  const sortedAxles = sortedAxlesBySizeAndPriority(axles);
  const data = { ...tireProfileData, axles: sortedAxles };
  return data;
}

export function useGetTireProfile(profileId: string, accountNumber: string, action: Actions) {
  return useQuery<TireProfileOVM>(['tire_profile', profileId, accountNumber], () =>
    getTireProfile(profileId, accountNumber, action),
  );
}

async function getProducts(accountNumber: string) {
  if (!accountNumber) return null;

  const res = await maestroApiCall({ method: 'GET', endpoint: `cp-be/tires/${accountNumber}/products`, baseURL });
  return res;
}

export function useGetProducts(accountNumber: string) {
  return useQuery<ProductOVM[]>(['products', accountNumber], () => getProducts(accountNumber));
}

async function getDefaults(accountNumber: string) {
  if (!accountNumber) return null;

  const res = await maestroApiCall({ method: 'GET', endpoint: `cp-be/tires/${accountNumber}/defaults`, baseURL });
  return res;
}

export function useGetDefaults(accountNumber: string) {
  return useQuery<TireProfileDefaultOVM>(['tire-profile-default', accountNumber], () => getDefaults(accountNumber));
}

async function saveTireProfile({
  accountNumber,
  data,
  action,
}: {
  accountNumber: string;
  data: TireProfileOVM;
  action: string;
}) {
  const endpoint = `cp-be/tires/${accountNumber}`;

  const apiType: MaestroAPI = {
    method: action === 'edit' ? MethodType.PUT : MethodType.POST,
    endpoint: action === 'edit' ? `${endpoint}/${data.id}` : endpoint,
    baseURL,
  };
  const newData: TireProfileOVM = { ...data };

  const res = await maestroApiCall<ResponseOVM<TireProfileOVM>>(apiType, newData);
  return ErrorHandler(res);
}
export function useSaveTireProfile(accountNumber: string, profileId: string | undefined) {
  const queryClient = useQueryClient();
  return useMutation(saveTireProfile, {
    onSuccess: () => {
      queryClient.invalidateQueries(['tire_profile', profileId, accountNumber]);
    },
  });
}

export interface MspnData {
  tire_size: string;
  brand: string;
  tread_design: string;
  speed_rating?: string;
  tire_type?: string;
  load_range?: string;
  original_tread_depth?: string;
  width?: string;
}
export const getMspnData = async (mspn: string, type: 'all' | '002'): Promise<MspnData | null> => {
  try {
    const res = await maestroApiCall({
      method: 'GET',
      endpoint: `cp-be/tires/mspn?mspn=${mspn}&type=${type}`,
      baseURL,
    });
    return res;
  } catch (e) {
    console.log('Error: could not retrieve mspn data', e);
  }
  return null;
};
export function useGetMspnData(mspn: string, type: 'all' | '002') {
  return useQuery(['getMspnData', mspn], () => {
    return getMspnData(mspn, type);
  });
}

export interface PassengerLtProducts {
  type: string;
  sizes: Size[];
}
export interface Size {
  name?: string;
  treads?: Treads[];
}
export interface Treads {
  name?: string;
  speeds?: Speeds[];
}
export interface Speeds {
  name: string;
  mspns: string[];
}
async function getPassengerLtProducts(country: string): Promise<PassengerLtProducts[] | null> {
  if (!country) return null;
  const res = await maestroApiCall({ method: 'GET', endpoint: `cp-be/tires/products?countryCode=${country}`, baseURL });
  return res;
}

export function useGetPassengerLTProducts(country: string) {
  return useQuery(['productsLt', country], () => getPassengerLtProducts(country));
}
