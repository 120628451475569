import React from 'react';
import { Grid, Popover, Theme, createStyles, makeStyles } from '@material-ui/core';
import { IconButton } from '@michelin/acid-components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
    margin: {
      margin: 2,
    },
    summaryContainer: {
      borderRadius: '5px',
      width: '370px',
      padding: '5px',
    },
  }),
);

interface IProps {
  comments: string | React.ReactElement;
  icon: any;
  color?: 'primary' | 'secondary' | 'warning' | 'inherit' | 'danger';
}

export default function Comments({ comments, icon, color }: IProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (!comments) return null;
  return (
    <IconButton
      aria-label="comments"
      className={classes.margin}
      style={{ cursor: 'pointer', textDecoration: 'none' }}
      color={color || 'primary'}
      onClick={(event: any) => handlePopoverOpen(event)}
      onMouseOver={(event: any) => handlePopoverOpen(event)}
      onMouseLeave={handlePopoverClose}
      size="small"
    >
      {icon}
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Grid container className={classes.summaryContainer}>
          <Grid item xs={12}>
            {comments}
          </Grid>
        </Grid>
      </Popover>
    </IconButton>
  );
}
