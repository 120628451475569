/* eslint-disable @typescript-eslint/no-use-before-define */

/* eslint-disable no-param-reassign */
import { SelectOption } from '@michelin/select-options-provider';
import { Customer, auth } from 'Auth';
import DefaultClient, { gql } from 'apollo-boost';
import { TranslateCallback } from 'components/Util';
import uuid from 'uuid';
import { State } from '.';
import {
  Contact,
  ContactLocationRelationship,
  ContactType,
  createCustomerContactRAW,
  deepOmit,
  updateCustomerContact,
  updateCustomerContactRAW,
  upsertContactLocationRelationshipRAW,
} from '../utils';
import { ReassignSelection } from './ContactTypes/dialogs/ReassignContactTypesDialog';

export const PRIMARY_LEVEL_VALUE = 'primary';
export const SECONDARY_LEVEL_VALUE = 'secondary';
export const GENERAL_LEVEL_VALUE = 'general';
export const ERS_MANAGER_ROLE_AREA_VALUE = 'ers_manager';
export const PO_ISSUER_ROLE_AREA_VALUE = 'po_issuer';
export const TIRE_SERVICE_VALUE = 'tire';
export const MECH_SERVICE_VALUE = 'mechanical';

export const getContactTypesRoleAreaLabelFromValue = (roleAreaValue: string, t: Function): string => {
  const mappedLabel = contactTypesRoleAreaValueToLabelMap[roleAreaValue];
  if (mappedLabel) {
    return t(mappedLabel);
  }
  return t(roleAreaValue);
};

// TODO: Retrieve labels from the SelecOptionsProvider package
export const contactTypesRoleAreaValueToLabelMap: { [roleAreaLabel: string]: string } = {
  accounts_payable: 'Accounts Payable',
  administrative: 'Administrative',
  credit_manager: 'Credit Manager',
  dispatch: 'Dispatch',
  driver: 'Driver',
  edi_admin: 'EDI Administrator',
  ers_manager: 'ERS Manager',
  fleet_operations_mamager: 'Fleet Operations Manager',
  invoicing: 'Invoicing',
  michelin_b2b_admin: 'Michelin B2B Admin',
  inventory_casings: 'Inventory/Casings',
  it_admin: 'IT Systems Admin',
  po_issuer: 'PO Issuer',
  president_ceo: 'President/CEO',
  purchaising_manager: 'Purchasing Manager',
  purchasing_lead: 'Purchasing Lead',
  safety_compliance_manager: 'Safety/Compliance Manager',
  services_approver: 'Services Approver',
  service_manager: 'Service Manager',
};

export const getNotificationSubscriptionLabelFromValue = (
  notificationSubscriptionValue: string,
  t: TranslateCallback,
) => {
  const mappedLabel = notificationSubscriptionValueToLabelMap[notificationSubscriptionValue];
  if (mappedLabel) {
    return t(mappedLabel);
  }
  return t(notificationSubscriptionValue);
};

export const notificationSubscriptionValueToLabelMap: { [notificationSubscriptionValue: string]: string } = {
  select: 'Select',
  profile: 'Profile',
  tire_ers: 'Tire ERS',
  bulk_order: 'Fleet Order',
  text: 'Text',
  email: 'Email',
  call_cell: 'Call (Cell)',
  fleet_profile_changes: 'Fleet Profile Changes',
  new_profile_features: 'New Profile Features',
  dispatched: 'Dispatched',
  eta_changes: 'ETA Changes',
  vehicle_rolling: 'Vehicle Rolling',
  new_note: 'New Note',
  order_submitted: 'Order Submitted',
  order_accepted: 'Order Accepted',
  order_declined: 'Order Declined',
  order_cancelled: 'Order Cancelled',
  order_status_change: 'Order Status Change',
  order_customer_approval_needed: 'Order Customer Approval Needed',
  order_customer_approval_received: 'Order Customer Approval Received',
  order_assigned: 'Order Assigned',
  order_reminder_fleet_approval: 'Order Reminder Fleet Approval',
  order_reminder_provider_approval: 'Order Reminder Provider Approval',
  order_request_po: 'Order Request PO',
};

// Returns the default index in an select options array
export function getDefaultIndex(list: Array<SelectOption>, value?: string | null): number {
  if (value) {
    for (let i = 0; i < list.length; i++) {
      if (list[i].value === value) {
        return i;
      }
    }
  }
  return 0;
}

export function createInitialEmptyContact(
  contact_id: string,
  contactLevelOptions: Array<SelectOption>,
  preferredLanguageOptions: Array<SelectOption>,
): Contact {
  return {
    hash_key: `2~${contact_id}`,
    gsi1_hash_key: '',
    gsi1_range_key: `2~${contact_id}`,
    range_key: '',
    first_name: '',
    last_name: '',
    job_title: '',
    contact_level: contactLevelOptions[getDefaultIndex(contactLevelOptions, auth.getAccountTypeShort())].value,
    // Null's because Apollo will send these values like that and are needed to check if the contact already exists.
    cell_phone: null,
    work_phone: null,
    ext: undefined,
    email_address: undefined,
    fax: undefined,
    preferred_method: 'none',
    preferred_language: preferredLanguageOptions[0].value, // In the future we will get this value from User metadata
    group: false,
    visible_externally: true,
    num_of_tires: undefined,
    num_of_wheels: undefined,
    tire_dollar_amount: undefined,
    work_hours: {
      // Null's because Apollo will retrieve these values as Null and the changelog will detect a change if they are undefined.
      monday: {
        begin: null,
        end: null,
      },
      tuesday: {
        begin: null,
        end: null,
      },
      wednesday: {
        begin: null,
        end: null,
      },
      thursday: {
        begin: null,
        end: null,
      },
      friday: {
        begin: null,
        end: null,
      },
      saturday: {
        begin: null,
        end: null,
      },
      sunday: {
        begin: null,
        end: null,
      },
    },
    locations: undefined,
    contact_types: undefined,
  };
}

export function getLocationsFromRelationships(
  relationships: Array<ContactLocationRelationship> | undefined,
): Array<Customer> {
  if (relationships) {
    const cleanLocations: Array<Customer> = [];

    relationships.forEach((x) => {
      if (x.location && x.is_deleted === false) {
        cleanLocations.push(x.location);
      }
    });

    return cleanLocations;
  }
  return [];
}

export function createInitState(t: TranslateCallback): State {
  return {
    contact: undefined,
    originalContact: undefined,
    panelTitle: '',
    panelSubtitle: '',
    loading: true,
    errorFields: new Set<string>(),
    modified: false,
    notificationSubscriptionSelected: {
      event: undefined,
      type: undefined,
      method: undefined,
      cc_emails: [],
      id: uuid.v4(),
    },
    openNotificationsSubscriptionDialog: false,
    openDeleteNotificationSubscriptionDialog: false,
    saving: false,
    contactId: '',
    unassignPriorityLevel: undefined,
    openManageContactTypesLevelsDialog: false,
    externalLevelDeassignation: new Map(),
    openReassignContactTypesDialog: false,
    externalContactsWithRoleAreaReassigned: new Map(),
    locationsFilters: new Set(),
    saveOnCloseManageContactTypes: false,
    executeSaving: false,
    avoidWarningUnassignAfterManage: false,
    t,
  };
}

export function createContactHash(first_name: string, last_name: string): string {
  let hash = `${first_name.replace('|', '')}|${last_name.replace('|', '')}`;
  hash = hash
    .toLowerCase()
    .trim()
    .replace(/ /g, '')
    .replace("'", '')
    .replace('`', '')
    .replace('´', '')
    .replace('"', '');
  return hash;
}

export interface ExternalLevelDeassignation {
  externalContact: Contact;
  roleAreaLocationCombo: Set<string>;
}

export function handleSavePriorityLevelsChange(
  state: State,
  mappedExternalContacts: Map<string, Contact>,
  externalContactsWithRoleAreaReassigned: Map<string, ReassignSelection>,
  selections: Set<string> | undefined,
): State {
  const { externalLevelDeassignation, saveOnCloseManageContactTypes } = state;
  const locationsFilters = new Set<string>();

  // Inefficient but does the job in a simple way.
  if (selections) {
    const { contact } = state;

    if (contact) {
      const { contact_types } = contact;
      if (contact_types) {
        contact_types.forEach((contactType) => {
          const { role_areas, service } = contactType;
          if (role_areas && service === TIRE_SERVICE_VALUE) {
            role_areas.forEach((contactTypeRoleArea) => {
              const { levels, role_area } = contactTypeRoleArea;
              levels.forEach((contactTypeLevel) => {
                const { location } = contactTypeLevel;
                let externalContact;
                let levelDeassignation;

                if (selections.has(`${role_area + location}${PRIMARY_LEVEL_VALUE}`)) {
                  contactTypeLevel.level = PRIMARY_LEVEL_VALUE;

                  externalContact = mappedExternalContacts.get(`${role_area + location}${PRIMARY_LEVEL_VALUE}`);
                  if (!externalContact) {
                    if (externalContactsWithRoleAreaReassigned.has(`${role_area + location}${PRIMARY_LEVEL_VALUE}`)) {
                      externalContactsWithRoleAreaReassigned.delete(`${role_area + location}${PRIMARY_LEVEL_VALUE}`);
                    }
                  }
                } else if (selections.has(`${role_area + location}${SECONDARY_LEVEL_VALUE}`)) {
                  contactTypeLevel.level = SECONDARY_LEVEL_VALUE;

                  externalContact = mappedExternalContacts.get(`${role_area + location}${SECONDARY_LEVEL_VALUE}`);
                  if (!externalContact) {
                    if (externalContactsWithRoleAreaReassigned.has(`${role_area + location}${SECONDARY_LEVEL_VALUE}`)) {
                      externalContactsWithRoleAreaReassigned.delete(`${role_area + location}${SECONDARY_LEVEL_VALUE}`);
                    }
                  }
                } else if (selections.has(`${role_area + location}${GENERAL_LEVEL_VALUE}`)) {
                  // Unassign
                  contactTypeLevel.level = GENERAL_LEVEL_VALUE;
                  externalContactsWithRoleAreaReassigned.delete(`${role_area + location}${PRIMARY_LEVEL_VALUE}`);
                  externalContactsWithRoleAreaReassigned.delete(`${role_area + location}${SECONDARY_LEVEL_VALUE}`);
                }

                if (externalContact) {
                  levelDeassignation = externalLevelDeassignation.get(externalContact.hash_key);
                  locationsFilters.add(location.toString());
                  if (levelDeassignation) {
                    levelDeassignation.roleAreaLocationCombo.add(role_area + location.toString());
                  } else {
                    const combo = role_area + location.toString();
                    const newSet = new Set<string>();
                    newSet.add(combo);
                    externalLevelDeassignation.set(externalContact.hash_key, {
                      externalContact,
                      roleAreaLocationCombo: newSet,
                    });
                  }
                }
              });
            });
          }
        });
      }
    }
  }

  return {
    ...state,
    openManageContactTypesLevelsDialog: false,
    externalLevelDeassignation: new Map(externalLevelDeassignation),
    modified: JSON.stringify(state.contact) !== JSON.stringify(state.originalContact),
    locationsFilters: new Set(),
    saveOnCloseManageContactTypes: false,
    executeSaving: !!saveOnCloseManageContactTypes,
  };
}

export function mutateExternalContactsUnassign(
  externalLevelDeassignation: Map<string, ExternalLevelDeassignation>,
): Array<any> {
  const mutations: Array<any> = [];

  Array.from(externalLevelDeassignation.values()).forEach((deassignation) => {
    const { roleAreaLocationCombo, externalContact } = deassignation;
    // This is to avoid local contact
    const cloneContact: Contact = JSON.parse(JSON.stringify(externalContact));
    const { hash_key, contact_types, email_address, work_phone, cell_phone } = cloneContact;
    if (contact_types) {
      contact_types.forEach((contactType) => {
        const { role_areas, service } = contactType;

        if (service === TIRE_SERVICE_VALUE) {
          if (role_areas) {
            role_areas.forEach((contactTypeRoleArea) => {
              const { levels, role_area } = contactTypeRoleArea;
              levels.forEach((contactTypeLevel) => {
                const { location } = contactTypeLevel;

                if (roleAreaLocationCombo.has(role_area + location)) {
                  contactTypeLevel.level = GENERAL_LEVEL_VALUE;
                }
              });
            });
          }
        }
      });
    }

    if (contact_types) {
      const mutation = updateContactContactTypes(hash_key, contact_types, email_address, work_phone, cell_phone);
      mutations.push(mutation);
    }
  });

  return mutations;
}

interface NotExistingCombo {
  roleArea: string;
  location: string;
  hash_key: string;
}

export function mutateExteranContactsReassign(
  externalContactsWithRoleAreaReassigned: Map<string, ReassignSelection>,
): Array<Promise<any>> {
  const mutations: Array<any> = [];
  const comboToReassign = new Map<string, string>();
  const notExistedCombos = new Map<string, NotExistingCombo>();
  const contactMap = new Map<string, Contact>();

  Array.from(externalContactsWithRoleAreaReassigned.values()).forEach((x) => {
    const { contact, location, level, roleArea } = x;
    const { hash_key } = contact;

    comboToReassign.set(hash_key + roleArea + location, level);
    notExistedCombos.set(hash_key + roleArea + location, {
      hash_key,
      roleArea,
      location,
    });
    contactMap.set(contact.hash_key, contact);
  });

  Array.from(contactMap.values()).forEach((contactToReassign) => {
    const { hash_key, contact_types, email_address, work_phone, cell_phone } = contactToReassign;

    if (contact_types) {
      // Reassign and delete combo
      contact_types.forEach((contactType) => {
        const { role_areas, service } = contactType;

        if (role_areas && service === TIRE_SERVICE_VALUE) {
          role_areas.forEach((contactTypeRoleArea) => {
            const { role_area, levels } = contactTypeRoleArea;

            levels.forEach((contactTypeLevel) => {
              const { location } = contactTypeLevel;
              const newLevelToReassign = comboToReassign.get(hash_key + role_area + location);

              if (newLevelToReassign) {
                notExistedCombos.delete(hash_key + role_area + location);
                comboToReassign.delete(hash_key + role_area + location);
                contactTypeLevel.level = newLevelToReassign;
              }
            });
          });
        }
      });

      // Not deleted combos = not exists in selected contact;
      Array.from(notExistedCombos.values()).forEach((i) => {
        const { roleArea, location, hash_key: notDeletedHashKey } = i;
        if (hash_key === notDeletedHashKey) {
          const tireContactType = contact_types.find((x) => x.service === TIRE_SERVICE_VALUE);
          if (tireContactType && tireContactType.role_areas && tireContactType.role_areas.length > 0) {
            const firstRoleAreaLevels = tireContactType.role_areas[0].levels;
            const locations = firstRoleAreaLevels.map((x) => x.location);
            const newLevelToReassign = comboToReassign.get(hash_key + roleArea + location);
            const mappedLevels = locations.map((x) => {
              if (x === location && newLevelToReassign) {
                return {
                  level: newLevelToReassign,
                  location,
                };
              }
              return {
                level: GENERAL_LEVEL_VALUE,
                location,
              };
            });
            if (tireContactType.role_areas) {
              tireContactType.role_areas.push({
                levels: mappedLevels,
                role_area: roleArea,
              });
            }
          } else {
            const mechanicalContactType = contact_types.find((x) => x.service === MECH_SERVICE_VALUE);
            if (mechanicalContactType) {
              if (mechanicalContactType.role_areas && mechanicalContactType.role_areas.length > 0) {
                const firstRoleAreaLevels = mechanicalContactType.role_areas[0].levels;
                const locations = firstRoleAreaLevels.map((x) => x.location);
                const newLevelToReassign = comboToReassign.get(hash_key + roleArea + location);
                const mappedLevels = locations.map((x) => {
                  if (x === location && newLevelToReassign) {
                    return {
                      level: newLevelToReassign,
                      location,
                    };
                  }
                  return {
                    level: GENERAL_LEVEL_VALUE,
                    location: x,
                  };
                });

                contact_types.push({
                  id: uuid.v4(),
                  service: TIRE_SERVICE_VALUE,
                  role_areas: [
                    {
                      role_area: roleArea,
                      levels: mappedLevels,
                    },
                  ],
                });
              }
            }
          }
        }
      });

      const mutation = updateContactContactTypes(hash_key, contact_types, email_address, work_phone, cell_phone);
      mutations.push(mutation);
    }
  });

  return mutations;
}

export function contactNeedsFirstTimeAssign(
  contact: Contact,
  originalContact: Contact | undefined,
  filteredLocations: Set<string>,
): boolean {
  const { contact_types } = contact;
  const oldContactTypes = new Set<string>();
  const newContactTypes = new Set<string>();
  const foundedLocations = new Set<string>();
  const foundedContactTypes = new Set<string>();

  if (originalContact && originalContact.contact_types) {
    originalContact.contact_types.forEach((contactType) => {
      const { service, role_areas } = contactType;
      if (role_areas) {
        role_areas.forEach((contactTypeRoleArea) => {
          const { role_area } = contactTypeRoleArea;
          oldContactTypes.add(service + role_area);
        });
      }
    });
  }

  if (typeof contact_types !== 'undefined') {
    contact_types.forEach((contactType) => {
      const { role_areas, service } = contactType;
      if (typeof role_areas !== 'undefined') {
        role_areas.forEach((contactTypeRoleArea) => {
          const { role_area, levels } = contactTypeRoleArea;
          levels.forEach((contactTypeLevel) => {
            const { level, location } = contactTypeLevel;

            // Check if some new location has some contact type assigned
            if (filteredLocations.size > 0) {
              if (filteredLocations.has(location)) {
                if (level === PRIMARY_LEVEL_VALUE || level === SECONDARY_LEVEL_VALUE) {
                  foundedLocations.add(location);
                }
              }
            }

            if (!oldContactTypes.has(service + role_area)) {
              newContactTypes.add(service + role_area);
              // It's a new Contact Type
              if (level === PRIMARY_LEVEL_VALUE || level === SECONDARY_LEVEL_VALUE) {
                foundedContactTypes.add(service + role_area);
              }
            }
          });
        });
      }
    });
  }

  return (
    (foundedLocations.size !== filteredLocations.size && filteredLocations.size > 0) ||
    (foundedContactTypes.size !== newContactTypes.size && newContactTypes.size > 0)
  );
}

export function updateContactContactTypes(
  hash_key: string,
  contact_types: Array<ContactType>,
  email_address: string | undefined,
  work_phone: string | null | undefined,
  cell_phone: string | null | undefined,
): undefined | Promise<any> {
  const newContactTypes = deepOmit(contact_types, ['__typename']);
  const client = auth.apolloClient;

  let mutationResult;

  if (client !== null) {
    let input;
    if (typeof email_address === 'string') {
      input = {
        updateContactFields: {
          email_address,
          contact_types: newContactTypes,
        },
      };
    } else if (typeof work_phone === 'string') {
      input = {
        updateContactFields: {
          work_phone,
          contact_types: newContactTypes,
        },
      };
    } else if (typeof cell_phone === 'string') {
      input = {
        updateContactFields: {
          cell_phone,
          contact_types: newContactTypes,
        },
      };
    }
    mutationResult = client.mutate({
      mutation: updateCustomerContact,
      variables: {
        hash_key,
        input,
      },
    });
  }

  return mutationResult;
}

export async function buildAndRunSaveMutationQueries(
  newData: any,
  contact: Contact,
  modifiedRels: Array<ContactLocationRelationship>,
  externalLevelDeassignation: Map<string, ExternalLevelDeassignation>,
  externalContactsWithRoleAreaReassigned: Map<string, ReassignSelection>,
  apolloClient: DefaultClient<any>,
  updateContact: boolean,
) {
  const mutations: Array<Array<string>> = [];
  let tempMutations: Array<string> = [];

  let n = 0;
  if (updateContact) {
    tempMutations.push(`m${n}: ${updateCustomerContactRAW}`);
  } else {
    tempMutations.push(`m${n}: ${createCustomerContactRAW}`);
  }

  modifiedRels.forEach((relationshipToSave) => {
    n += 1;
    const { hash_key, gsi2_hash_key, gsi2_range_key, is_deleted } = relationshipToSave;
    const relationshipMutation = `m${n}: ${upsertContactLocationRelationshipRAW
      .replace('$location_hash_key', hash_key.toString())
      .replace('$contact_hash_key', contact.hash_key)
      .replace('$is_deleted', is_deleted ? 'true' : 'false')
      .replace('$gsi2_hash_key', gsi2_hash_key.toString())
      .replace('$gsi2_range_key', gsi2_range_key.toString())}`;

    if (n === 998) {
      mutations.push([...tempMutations]);
      tempMutations = [relationshipMutation];
    } else {
      tempMutations.push(relationshipMutation);
    }
  });
  mutations.push(tempMutations);

  const contactsResolvedPromises: Array<Promise<any>> = [];
  mutations.forEach((tempMutation, index) => {
    // eslint-disable-next-line no-async-promise-executor
    const mutationPromise = new Promise(async (res, rej) => {
      try {
        if (tempMutation && tempMutation.length > 0) {
          let fullMutation = gql`
            mutation Mutation($hash_key: String!, ${
              updateContact ? '$input: UpdateContactInput!' : '$contact: ContactInput!'
            }) {
              ${tempMutation}
            }`;
          if (index === 0) {
            await apolloClient.mutate({
              mutation: fullMutation,
              variables: updateContact
                ? { hash_key: contact.hash_key, input: { updateContactFields: newData } }
                : { hash_key: contact.hash_key, contact: newData },
            });
          } else {
            // The second mutations in the array will never use variables since are only relationships changes
            fullMutation = gql`mutation Mutation {${tempMutation}}`;
            await apolloClient.mutate({
              mutation: fullMutation,
            });
          }
        }
        res([]);
      } catch (e) {
        rej(e);
      }
    });
    contactsResolvedPromises.push(mutationPromise);
  });

  await Promise.all(contactsResolvedPromises);
  // There could be some reassign that later is unassigned
  const externalUnassignMutations = mutateExternalContactsUnassign(externalLevelDeassignation);
  await Promise.all(externalUnassignMutations);
  mutateExteranContactsReassign(externalContactsWithRoleAreaReassigned);
}

export function canUserEditByHierarchy(
  contact: Pick<Contact, 'contact_level'> | undefined,
  updatePermission: boolean,
): boolean {
  if (contact && updatePermission === true) {
    const accountType = auth.getMainAccountTypeShort();
    return isHierarchyHigher(accountType, contact.contact_level);
  }

  return false;
}

export function isHierarchyHigher(hierarchyLevel: string | null, hierarchyLevelToCompare: string): boolean {
  switch (hierarchyLevel) {
    case 'PC':
      return true;
    case 'HO':
      return hierarchyLevelToCompare === 'HO' || hierarchyLevelToCompare === 'BT' || hierarchyLevelToCompare === 'ST';
    case 'BT':
      return hierarchyLevelToCompare === 'BT' || hierarchyLevelToCompare === 'ST';
    case 'ST':
      return hierarchyLevelToCompare === 'ST';
    default:
      return false;
  }
}
