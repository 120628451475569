import { MaestroAPI, maestroApiCall } from '@michelin/central-provider';
import { useMutation } from '@tanstack/react-query';
import GLOBAL_CONFIG from 'global-variables';

const baseURL = GLOBAL_CONFIG.externalAPIUrl;

interface AccountDeleteResult {
  hash_key: string;
  range_key: string;
  is_deleted: boolean;
  __typename: string;
}

const deleteAccount = async ({
  fleetId,
  accountKey,
}: {
  fleetId: string;
  accountKey: string;
}): Promise<AccountDeleteResult | null> => {
  if (!fleetId || !accountKey) return null;

  const apiType: MaestroAPI = {
    method: 'DELETE',
    endpoint: `cp-be/accounts/${fleetId}/${accountKey}`,
    baseURL,
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return maestroApiCall(apiType);
};

export function useDeleteAccount() {
  return useMutation(deleteAccount);
}
