/* eslint-disable global-require */
import React, { useContext, useEffect, useState } from 'react';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DialogProvider, LoadingBounce } from '@michelin/acid-components';
import {
  CentralProvider,
  CentralProviderContext,
  Languages,
  THEMES,
  initReactI18next,
  usePermissions,
  useTranslation,
} from '@michelin/central-provider';
import i18n from '@michelin/central-provider/dist/translations/CentralTranslation';
// import { getVersion } from 'components/Util/version';
import { runningOnCanvas } from '@michelin/central-provider/dist/utils/canvas';
import {
  initMaestroTranslations,
  prefixedTranslations,
  sharedTranslations,
} from '@michelin/maestro-common-translations';
import { initOptionsAndValuesTranslations } from '@michelin/select-options-provider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Heading } from 'components/Heading';
import { PlainMessage } from 'components/Messages';
import { loadMessages as devExLoadMessages, locale as devExSetLocale } from 'devextreme/localization';
import frMessages from 'devextreme/localization/messages/fr.json';
import { SnackbarProvider } from 'notistack';
import { ApolloProvider } from 'react-apollo';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { AuthUpdateEvent, AuthUpdateSubscriber, auth } from './Auth';
import ErrorCatch from './ErrorCatch';
import Routes, { useStyles } from './Routes';
import defaultConfig from './global-variables';
import './index.css';
import * as serviceWorker from './serviceWorker';

/**
 * ensure we capture the api version once it's available via `Auth` by subscribing
 * to the updates `Auth` pushes
 *
 */
const onAuthSessionLoadedSubscriber: AuthUpdateSubscriber = (event) => {
  if (event !== AuthUpdateEvent.SESSION_LOADED) return;

  /* unsubscribe from any further updates */
  auth.unSuscribeUpdates(onAuthSessionLoadedSubscriber);
};
auth.suscribeUpdates(onAuthSessionLoadedSubscriber);

initOptionsAndValuesTranslations(i18n);

const history = require('history').createBrowserHistory();

const devexLight = `${process.env.PUBLIC_URL}/css/dx.material.brand-center.css`;
const devexDark = `${process.env.PUBLIC_URL}/css/dx.material.brand-center.css`;

initMaestroTranslations(
  i18n,
  initReactI18next,
  {
    en_US: {
      ...require('./i18n/en_US.json'),
      ...require('./i18n/sop_en_US.json'),
    },
    fr_CA: {
      ...require('./i18n/fr_CA.json'),
      ...require('./i18n/sop_fr_CA.json'),
    },
  },
  [sharedTranslations.dates, prefixedTranslations(sharedTranslations.country_codes, 'COUNTRY_CODE|')],
);

devExLoadMessages(frMessages);

interface RenderLoggedAppProps {
  setLanguages: any;
  languages: Languages[];
}

const RenderLoggedApp = (props: RenderLoggedAppProps): JSX.Element => {
  // eslint-disable-next-line no-shadow
  const { location } = usePermissions();
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  auth.init();
  const permissions = usePermissions();
  const { setCurrentTheme, currentTheme, currentLanguage, setCurrentLanguage } = useContext(CentralProviderContext);
  useEffect(() => setCurrentTheme('michelinLightBC'), [setCurrentTheme, currentTheme]);

  useEffect(() => {
    if (runningOnCanvas() && currentLanguage !== permissions.profile?.profile.language)
      setCurrentLanguage(permissions.profile?.profile.language as Languages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, currentLanguage]);

  useEffect(() => setCurrentTheme('michelinLightBC' as any), [setCurrentTheme, currentTheme]);
  const customerNumber = location?.customer_number || null;

  useEffect(() => {
    if (!customerNumber) return;
    auth.setCustomerNumber(customerNumber, setLoading);
  }, [customerNumber, setLoading]);

  devExSetLocale(i18n.language.split('_')[0]);

  if (!auth.apolloClient) {
    return (
      <PlainMessage
        title={t('Login Error')}
        messages={[
          t('There was an error while creating apollo client using auth token.'),
          t('Please try again later or contact support for assistance...'),
        ]}
      />
    );
  }

  const error = auth.getError();

  if (error) {
    return (
      <SnackbarProvider anchorOrigin={{ vertical: 'top', horizontal: 'center' }} maxSnack={5}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ErrorCatch>
            <DialogProvider>
              <BrowserRouter>
                <Heading />
                <section className={classes.content}>
                  <PlainMessage title={t(error.title)} messages={[t(error.msg)]} />
                </section>
              </BrowserRouter>
            </DialogProvider>
          </ErrorCatch>
        </MuiPickersUtilsProvider>
      </SnackbarProvider>
    );
  }

  if (loading) return <LoadingBounce style={{ height: '100vh' }} />;

  const availLanguages = [Languages.EN_US, Languages.FR_CA];

  if (props.languages.sort().join('.') !== availLanguages.sort().join('.')) {
    props.setLanguages(availLanguages);
    return <LoadingBounce style={{ height: '100vh' }} />;
  }

  devExSetLocale(i18n.language.split('_')[0]);

  return (
    <ApolloProvider client={auth.apolloClient}>
      <ApolloHooksProvider client={auth.apolloClient}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DialogProvider>
            <SnackbarProvider anchorOrigin={{ vertical: 'top', horizontal: 'center' }} maxSnack={5}>
              <Routes key={`lang-${i18n.language}`} />
            </SnackbarProvider>
          </DialogProvider>
        </MuiPickersUtilsProvider>
      </ApolloHooksProvider>
    </ApolloProvider>
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const RenderProvider = React.memo(() => {
  const [languages, setLanguages] = useState([Languages.EN_US, Languages.FR_CA]);

  return (
    <CentralProvider
      application={defaultConfig.appId}
      languages={languages}
      redirectToPreviousRoute
      redirect="/"
      onLogin={(previous: string) => history.push(previous)}
      useCentralProviderDebugger={false}
      useTranslationDebugger={false}
      themes={{
        on: THEMES.michelinLightBC,
      }}
      allowGrantLocationSelector={false}
      disableTranslationsManager
      devexLightThemeCSS={devexLight}
      devexDarkThemeCSS={devexDark}
    >
      <QueryClientProvider client={queryClient}>
        <RenderLoggedApp setLanguages={setLanguages} languages={languages} />
      </QueryClientProvider>
    </CentralProvider>
  );
});

render(<RenderProvider />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
