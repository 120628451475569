import React, { ReactNode, useEffect, useState } from 'react';
import { ClickAwayListener, Paper, Typography, useMediaQuery } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import { Fab, GlobalActions } from '@michelin/acid-components';
import theme from 'theme';

const size = '60px';
const useStyles = makeStyles((t: any) => ({
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flex: '0 1',
    alignItems: 'center',
  },
  steps: {
    position: 'relative',
    height: size,
    width: size,
  },
  svg: {
    display: 'inline-block',
    position: 'absolute',
    top: 0,
    left: 0,
    width: `${size}`,
    height: `${size}`,
  },
  circle: {
    fill: 'none',
    strokeWidth: 10,
    strokeLinecap: 'round',
    stroke: t.palette.secondary.dark,
  },
  innerCircle: {
    fill: 'none',
    strokeWidth: 10,
    strokeLinecap: 'round',
    stroke: t.palette.primary.main,
    transformOrigin: '50px 50px',
    transform: 'rotate(180deg)',
  },
  number: {
    position: 'relative',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.8rem',
  },
  title: {
    flexGrow: 1,
    paddingLeft: '10px',
    paddingBottom: '0px',
    display: 'flex',
    borderBottom: `1px solid ${t.palette.primary.main}`,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: '2px',
  },
  actionButton: {
    color: `${t.palette.primary.contrastText}`,
    backgroundColor: `${t.palette.primary.main}`,
    borderRadius: '20%',
    width: '30px',
    height: '20px',
    marginLeft: '10px',
    textAlign: 'center',
    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    '& .MuiSvgIcon-root': {
      fontSize: '20px',
    },
  },
  disabled: {
    backgroundColor: `${t.palette.secondary.main}`,
  },
  menu: {
    position: 'absolute',
    zIndex: 1,
    margin: '0px',
    padding: '0px',
    backgroundColor: 'transparent',
  },
  menuItem: {
    opacity: 0,
    height: 'auto',
    padding: '5px',
    margin: '5px',
    backdropFilter: 'blur(0.8px)',
    whiteSpace: 'nowrap',
    animation: '$menu 200ms ease-in-out',
    animationFillMode: 'forwards',
    '&:nth-last-child': {
      padding: '5px 0px 0px 0px',
    },
  },
  stepperItself: {
    top: 0,
    position: 'sticky',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  '@keyframes menu': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
}));

export interface StepperStep {
  label: string;
  error?: boolean;
}

export interface StepperActions {
  label: string;
  icon: JSX.Element;
  disabled: boolean;
  displayDisabled?: boolean;
  onClick: () => void;
}

interface StepperComponentProps {
  activeStep: number;
  steps: StepperStep[];
  children?: ReactNode;
  setStepperStep: Function;
  stepperActions?: StepperActions[];
}

export function StepperComponent({
  activeStep,
  steps,
  children,
  setStepperStep,
  stepperActions,
}: StepperComponentProps) {
  const classes = useStyles();
  const cellView = useMediaQuery(theme.breakpoints.down(500));
  const [dashoffset, setDashoffset] = useState(0);
  const dasharray = 251;
  const [visibility, setVisibility] = useState<'visible' | 'hidden'>('hidden');

  useEffect(() => {
    const percentage = (activeStep + 1) / steps.length;
    setDashoffset(dasharray - dasharray * percentage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  const getKey = (prefix: string, value: string) => `${prefix}-${value.replace(' ', '-').toLowerCase()}`;

  const StepperMenu = () => (
    <ClickAwayListener onClickAway={() => setVisibility('hidden')}>
      <div className={classes.menu} style={{ visibility }}>
        {steps.map((step, index) => (
          <Paper
            elevation={3}
            key={getKey('paper', step.label)}
            className={classes.menuItem}
            style={{ animationDelay: `calc(${index} * 50ms)` }}
          >
            <div
              onClick={() => {
                setStepperStep(index);
                setVisibility('hidden');
              }}
            >
              {step.label}
            </div>
          </Paper>
        ))}
      </div>
    </ClickAwayListener>
  );

  // Stepper mobile view
  if (cellView) {
    return (
      <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <div className={classes.content}>
          <div className={classes.steps}>
            <svg className={classes.svg} viewBox="0 0 100 100">
              <circle className={classes.circle} strokeDasharray={dasharray} r="40" cx="50" cy="50" />
              <circle
                className={classes.innerCircle}
                strokeDasharray={dasharray}
                r="40"
                cx="50"
                cy="50"
                strokeDashoffset={dashoffset}
              />
            </svg>
            <span
              className={classes.number}
              onClick={() => {
                setVisibility(visibility === 'hidden' ? 'visible' : 'hidden');
              }}
            >
              {`${activeStep + 1} of ${steps.length}`}
            </span>
            <StepperMenu />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <div className={classes.title}>
              <Typography variant="h6" component="span">
                {steps[activeStep].label}
              </Typography>
            </div>
            {stepperActions && stepperActions.length > 0 && (
              <div className={classes.actions}>
                {stepperActions.map((action: StepperActions) => {
                  if (action.displayDisabled || (!action.disabled && !action.displayDisabled)) {
                    return (
                      <div
                        key={getKey('action', action.label)}
                        className={`${classes.actionButton} ${action.disabled && classes.disabled}`}
                        onClick={action.disabled ? () => {} : action.onClick}
                      >
                        {action.icon}
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            )}
          </div>
        </div>
        {children || null}
      </div>
    );
  }
  return (
    <>
      <div className={classes.stepperItself}>
        <Stepper nonLinear activeStep={activeStep} alternativeLabel style={{ padding: '5px' }}>
          {steps.map((step: StepperStep, index: number) => (
            <Step
              style={{ cursor: 'pointer' }}
              key={getKey('step', step.label)}
              onClick={() => {
                setStepperStep(index);
              }}
            >
              <StepLabel error={step.error}>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      {children || null}
      {stepperActions && stepperActions.length > 0 && (
        <GlobalActions horizontal={cellView}>
          {stepperActions.map((action: StepperActions) => (
            <Fab
              key={getKey('action', action.label)}
              color="primary"
              disabled={action.disabled}
              displayDisabled={action.displayDisabled}
              onClick={action.onClick}
              label={action.label}
            >
              {action.icon}
            </Fab>
          ))}
        </GlobalActions>
      )}
    </>
  );
}
