/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable max-len */

/* eslint-disable react-hooks/exhaustive-deps */
// External Lib
import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Grid, InputAdornment } from '@material-ui/core';
// Central provider
import {
  Autocomplete,
  Button,
  Chip,
  Panel,
  PreventTransitionPrompt,
  Select,
  TextField,
  useDialogs,
} from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { SelectOption, getOptionLabel, getOptions } from '@michelin/select-options-provider';
// Internal Lib
import { scrollToDisplayFirstError } from 'components/Util';
import { isEqual } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import { Actions, SOPInflation as SOPInflationType, SOPTireProfile, SOPTireProfileFilter } from '../Types';
import { filterTireProfile } from '../utils';

interface InflationFormProps {
  action: Actions;
  data: SOPInflationType;
  list: SOPInflationType[];
  tireProfile: SOPTireProfile[] | null;
  onSave: (data: SOPInflationType) => void;
  onCancel: () => void;
}

function InflationForm({ action, data, list, tireProfile, onSave, onCancel }: InflationFormProps): JSX.Element {
  const { t } = useTranslation();
  const { errorDialog, confirmDialog } = useDialogs();
  const { control, reset, watch, handleSubmit, setValue, getValues, formState, trigger } = useForm<SOPInflationType>({
    defaultValues: data,
    reValidateMode: 'onChange',
  });

  const [isReseted, setReset] = useState(false);
  useEffect(() => {
    const cloneData = { ...data };
    if (action === 'add') cloneData.id = (list.length === 0 ? 0 : Math.max(...list.map((v) => v.id))) + 1;
    reset(cloneData);
    setReset(true);
  }, [data, reset]);

  useEffect(() => {
    if (isReseted && action === 'edit') trigger();
  }, [trigger, isReseted]);

  const axleOptions: SelectOption[] = getOptions('axle_types');
  const applicationOptions: SelectOption[] = getOptions('application_types');
  const vehicleOptions: SelectOption[] = getOptions('vehicle_types_onsite');
  const unitOfMeasurements: SelectOption[] = getOptions('pressure_types');
  // Complete the values to get the available info for Add or Edit.
  const uom = watch('uom');
  const sizeWatch = watch('size');
  const axleWatch = watch('axle_types');
  const appWatch = watch('application_types');
  const vehWatch = watch('vehicle_types');
  const filteredOptions: SOPTireProfileFilter = filterTireProfile(
    tireProfile,
    sizeWatch,
    list.map((i) => i.size || ''),
    axleWatch,
    appWatch,
    vehWatch,
  );

  useEffect(() => {
    if (!data) return;
    if (data.size === sizeWatch) return;

    setValue('axle_types', [], { shouldDirty: true });
    setValue('application_types', [], { shouldDirty: true });
    setValue('vehicle_types', [], { shouldDirty: true });
  }, [sizeWatch]);

  useEffect(() => {
    if (!data) return;
    if (isEqual(data.axle_types, axleWatch)) return;

    setValue('application_types', [], { shouldDirty: true });
    setValue('vehicle_types', [], { shouldDirty: true });
  }, [JSON.stringify(axleWatch)]);

  useEffect(() => {
    if (!data) return;
    if (isEqual(data.application_types, appWatch)) return;

    setValue('vehicle_types', [], { shouldDirty: true });
  }, [JSON.stringify(appWatch)]);

  const additionalProps = { min: 0, max: 999, maxLength: 3 };
  const adornment = {
    endAdornment: <InputAdornment position="end">{getOptionLabel('pressure_types', uom || '')}</InputAdornment>,
    inputProps: additionalProps,
  };

  const onError = () => {
    errorDialog(t('Please check the marked fields.').split('\n'), t('Error saving data.'));
    scrollToDisplayFirstError();
  };

  const validateDuplicates = (): boolean | string => {
    const formValues = getValues();
    const duplicates =
      list.filter(
        (v: any) =>
          v.size === formValues.size &&
          (v.id || 0) !== (formValues.id || 0) &&
          isEqual(v.axle_types, formValues.axle_types) &&
          isEqual(v.application_types, formValues.application_types) &&
          isEqual(v.vehicle_types, formValues.vehicle_types),
      ).length !== 0 || false;

    return duplicates ? t('No duplicates allowed').toString() : true;
  };

  const validateRequire = (value: string[] | undefined) => {
    if (!(value && value.length > 0)) return t('Please fill out this field.');
    return true;
  };

  const validateOption = (type: string, values: string[]): boolean | string => {
    const formValues = getValues();
    let error = false;

    values.some((value) => {
      switch (type) {
        case 'size': {
          error = !tireProfile?.map((i) => i.tire_size).includes(value);
          break;
        }
        case 'axle': {
          error = !tireProfile
            ?.filter((i) => i.tire_size === formValues.size)
            .map((i) => i.axle)
            .includes(value);
          break;
        }
        case 'application': {
          error = !tireProfile
            ?.filter((i) => i.tire_size === formValues.size)
            .map((i) => i.application_type)
            .includes(value);
          break;
        }
        case 'vehicle': {
          error = !tireProfile
            ?.filter((i) => i.tire_size === formValues.size)
            .map((i) => i.vehicle_type)
            .includes(value);
          break;
        }
        default:
          break;
      }
      return error;
    });

    return error ? t('Choose an option from the tire profile(s) linked in Step 1').toString() : true;
  };

  const validateInflations = () => {
    const formValues = getValues();

    if (
      !formValues.min_pressure &&
      !formValues.max_pressure &&
      !formValues.target_pressure &&
      !formValues.under_inflation_removal &&
      !formValues.over_inflation_removal
    )
      return t('You must specify at least one inflation pressures.');

    return true;
  };

  const getAutocompleteValues = (values: any[], allValue: string) => {
    const val = values.map((v: any) => v.value || v);
    return val.includes(allValue) ? [allValue] : val;
  };

  return (
    <Panel>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Controller
            name="size"
            control={control}
            rules={{
              required: { value: true, message: t('Please fill out this field.') },
              validate: {
                duplicates: validateDuplicates,
                validOption: (v) => validateOption('size', [v || '']),
              },
            }}
            render={(props: any) => (
              <Select
                label={t('Tire Size')}
                value={props.field.value || ''}
                options={filteredOptions.tireSizesList}
                variant="standard"
                onChange={(e: any) => {
                  props.field.onChange(e.target.value);
                  trigger('size');
                }}
                error={!!props.formState.errors.size}
                helperText={props.formState.errors.size?.message}
                disabled={action === 'view'}
                required
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="uom"
            control={control}
            rules={{ required: { value: true, message: t('Please fill out this field.') } }}
            render={(props: any) => (
              <Select
                label={t('Unit of Measurement')}
                value={props.field.value?.toLowerCase() || ''}
                options={unitOfMeasurements}
                variant="standard"
                onChange={(e: any) => {
                  props.field.onChange(e.target.value);
                  trigger('uom');
                }}
                error={!!props.formState.errors.uom}
                helperText={props.formState.errors.uom?.message}
                disabled={action === 'view'}
                required
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          &nbsp;
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="axle_types"
            control={control}
            rules={{
              validate: {
                require: (v) => validateRequire(v),
                validOption: (v) => validateOption('axle', v || ['']),
              },
            }}
            render={(props: any) => (
              <Autocomplete
                label={t('Axle Type')}
                variant="standard"
                value={props.field.value || []}
                onChange={(e: any, values: any[]) => {
                  props.field.onChange(getAutocompleteValues(values, 'all_axles'));
                  trigger('axle_types');
                }}
                getOptionSelected={(option: any, value: any) => option.value === value}
                error={!!props.formState.errors.axle_types}
                helperText={props.formState.errors.axle_types?.message}
                options={filteredOptions.axleTypesList
                  .map((axle) => axleOptions.filter((a) => a.value === axle))
                  .flat()}
                disableCloseOnSelect
                disabled={action === 'view'}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      size="small"
                      label={getOptionLabel('axle_types', option)}
                      color={data && data.tire_profile_errors?.includes(option) ? 'danger' : 'default'}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                size="small"
                margin="normal"
                fullWidth
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="application_types"
            control={control}
            rules={{
              validate: {
                require: (v) => validateRequire(v),
                validOption: (v) => validateOption('application', v || ['']),
              },
            }}
            render={(props: any) => (
              <Autocomplete
                label={t('Application Type')}
                variant="standard"
                value={props.field.value || []}
                onChange={(e: any, values: any[]) => {
                  props.field.onChange(getAutocompleteValues(values, 'all'));
                  trigger('application_types');
                }}
                getOptionSelected={(option: any, value: any) => option.value === value}
                error={!!props.formState.errors.application_types}
                helperText={props.formState.errors.application_types?.message}
                options={filteredOptions.applicationTypesList
                  .map((app) => applicationOptions.filter((a) => a.value === app))
                  .flat()}
                disableCloseOnSelect
                disabled={action === 'view'}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      size="small"
                      label={getOptionLabel('application_types', option)}
                      color={data && data.tire_profile_errors?.includes(option) ? 'danger' : 'default'}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                size="small"
                margin="normal"
                fullWidth
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Controller
            name="vehicle_types"
            rules={{
              validate: {
                require: (v) => validateRequire(v),
                validOption: (v) => validateOption('vehicle', v || ['']),
              },
            }}
            control={control}
            render={(props: any) => (
              <Autocomplete
                label={t('Vehicle Type')}
                variant="standard"
                value={props.field.value || []}
                onChange={(e: any, values: any[]) => {
                  props.field.onChange(getAutocompleteValues(values, 'ALL'));
                  trigger('vehicle_types');
                }}
                getOptionSelected={(option: any, value: any) => option.value === value}
                error={!!props.formState.errors.vehicle_types}
                helperText={props.formState.errors.vehicle_types?.message}
                options={filteredOptions.vehicleTypesList
                  .map((vehicle) => vehicleOptions.filter((v) => v.value === vehicle))
                  .flat()}
                disableCloseOnSelect
                disabled={action === 'view'}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      size="small"
                      label={getOptionLabel('vehicle_types_onsite', option)}
                      color={data && data.tire_profile_errors?.includes(option) ? 'danger' : 'default'}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                size="small"
                margin="normal"
                fullWidth
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="min_pressure"
            control={control}
            rules={{
              ...additionalProps,
              validate: validateInflations,
            }}
            render={(props: any) => (
              <TextField
                label={t('Min Pressure')}
                value={props.field.value || ''}
                variant="standard"
                onChange={(e: any) => {
                  props.field.onChange(parseInt(e.target.value, 0));
                  trigger();
                }}
                error={!!props.formState.errors.min_pressure}
                helperText={props.formState.errors.min_pressure?.message}
                InputProps={adornment}
                disabled={action === 'view'}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="target_pressure"
            control={control}
            rules={{
              ...additionalProps,
              validate: validateInflations,
            }}
            render={(props: any) => (
              <TextField
                label={t('Target Pressure')}
                value={props.field.value || ''}
                variant="standard"
                onChange={(e: any) => {
                  props.field.onChange(parseInt(e.target.value, 0));
                  trigger();
                }}
                error={!!props.formState.errors.target_pressure}
                helperText={props.formState.errors.target_pressure?.message}
                InputProps={adornment}
                disabled={action === 'view'}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Controller
            name="max_pressure"
            control={control}
            rules={{
              ...additionalProps,
              validate: validateInflations,
            }}
            render={(props: any) => (
              <TextField
                label={t('Max Pressure')}
                value={props.field.value || ''}
                variant="standard"
                onChange={(e: any) => props.field.onChange(parseInt(e.target.value, 0))}
                error={!!props.formState.errors.max_pressure}
                helperText={props.formState.errors.max_pressure?.message}
                InputProps={adornment}
                disabled={action === 'view'}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="under_inflation_removal"
            control={control}
            rules={{
              ...additionalProps,
              validate: validateInflations,
            }}
            render={(props: any) => (
              <TextField
                label={t('Removal for Under Inflation')}
                value={props.field.value || ''}
                variant="standard"
                onChange={(e: any) => {
                  props.field.onChange(parseInt(e.target.value, 0));
                  trigger();
                }}
                error={!!props.formState.errors.under_inflation_removal}
                helperText={props.formState.errors.under_inflation_removal?.message}
                InputProps={adornment}
                disabled={action === 'view'}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Controller
            name="over_inflation_removal"
            control={control}
            rules={{
              ...additionalProps,
              validate: validateInflations,
            }}
            render={(props: any) => (
              <TextField
                label={t('Removal for Over Inflation')}
                value={props.field.value || ''}
                variant="standard"
                onChange={(e: any) => {
                  props.field.onChange(parseInt(e.target.value, 0));
                  trigger();
                }}
                error={!!props.formState.errors.over_inflation_removal}
                helperText={props.formState.errors.over_inflation_removal?.message}
                InputProps={adornment}
                disabled={action === 'view'}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="comments"
            control={control}
            render={(props: any) => (
              <TextField
                label={t('Comments')}
                value={props.field.value || ''}
                multiline
                variant="standard"
                onChange={(e: any) => props.field.onChange(e.target.value)}
                disabled={action === 'view'}
                maxLength={50}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Grid container justify="flex-end">
            <Button
              size="small"
              color="primary"
              style={{ margin: '8px' }}
              onClick={handleSubmit(onSave, onError)}
              disabled={action === 'view'}
            >
              {t('Save')}
            </Button>
            <Button
              size="small"
              color="danger"
              style={{ margin: '8px' }}
              // disabled={saving}
              onClick={() => {
                if (formState.isDirty) {
                  confirmDialog(
                    t('Are you sure you want to discard all the changes you have made?'),
                    t('Discard Changes'),
                    t('Yes'),
                    t('No'),
                    onCancel,
                  );
                } else {
                  onCancel();
                }
              }}
            >
              {t('Cancel')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <PreventTransitionPrompt when={formState.isDirty} />
    </Panel>
  );
}

interface InflationFormModalProps {
  action: Actions;
  data: SOPInflationType;
  list: SOPInflationType[];
  tireProfile: SOPTireProfile[] | null;
  open: boolean;
  handleOpen: (value: boolean) => void;
  onSave: (e: SOPInflationType) => void;
}

function InflationFormModal(props: InflationFormModalProps) {
  const { t } = useTranslation();
  const action = props.action.charAt(0).toUpperCase() + props.action.slice(1);
  const title = `${t(action)} - ${t('Tire Inflation Pressure')}`;

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      aria-labelledby="customized-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      open={props.open}
    >
      <DialogTitle>{title}</DialogTitle>

      <DialogContent dividers style={{ padding: 0 }}>
        <InflationForm
          action={props.action}
          list={props.list}
          data={props.data}
          onSave={props.onSave}
          onCancel={() => props.handleOpen(false)}
          tireProfile={props.tireProfile}
        />
      </DialogContent>
    </Dialog>
  );
}

export default InflationForm;
export { InflationFormModal };
