/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { AccountAvatar, AccountAvatarProps, Button, TextField, useDialogs } from '@michelin/acid-components';
import { AssigneeCustomerType } from 'components/LocationAssignments/queries';
import { useSnackbar } from 'notistack';
import { getBaseUrl } from 'prefs-and-service-offers';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { transferVehicleAPICall } from './Utils';

export interface ConfirmTransferDialogProps {
  originalLocation: string;
  vehicleId: string;
  data: AssigneeCustomerType | undefined;
  openProp: boolean;
  onSelectedCallback?: (data: any) => void;
  onCloseCallback?: () => void;
}

let openConfirmTransferDialog: ({
  originalLocation,
  vehicleId,
  data,
  openProp,
  onSelectedCallback,
  onCloseCallback,
}: ConfirmTransferDialogProps) => void = () => {};

export function useConfirmTransferDialog() {
  return {
    transferDialog: openConfirmTransferDialog,
  };
}

const transferReasonItems = [
  { id: 'sold', label: 'Vehicle Sold' },
  { id: 'other', label: 'Other' },
];

export interface GlobalTextInputProps {
  variant?: 'standard';
  InputProps: any;
  style: any;
  onChange?: (event: React.ChangeEvent<any> | null, value?: any) => void;
}

const textInputProps: GlobalTextInputProps = {
  variant: 'standard',
  InputProps: { readOnly: false },
  style: { display: 'flex', flexWrap: 'wrap' },
};

export function ConfirmTransferDialog({
  originalLocation,
  vehicleId,
  data,
  openProp,
  onSelectedCallback,
  onCloseCallback,
}: ConfirmTransferDialogProps) {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { errorDialog } = useDialogs();
  const [open, setOpen] = useState(openProp);
  const { control, setValue, handleSubmit, watch, unregister, reset } = useForm<any>({
    defaultValues: {
      originalLocation,
      vehicleId,
      data,
      id: 'other',
      value: '',
    },
  });

  const id = watch('id');
  const value = watch('value');
  const sourceLocation = watch('originalLocation');
  const sourceVehicleId = watch('vehicleId');
  const targetLocationData = watch('data');

  const [onSelected, setOnSelected] = useState<undefined | ((data: any) => void)>(onSelectedCallback);
  const [onClose, setOnClose] = useState<undefined | (() => void)>(onCloseCallback);

  async function transferVehicle() {
    const response = await transferVehicleAPICall(
      sourceLocation,
      sourceVehicleId,
      targetLocationData.customer_number,
      id,
      value,
    );
    if (!response || response.status !== 200) {
      errorDialog(
        [t(response.body.error) || t('Unhandled error')],
        t('Error while transferring vehicle'),
        t('Ok'),
        undefined,
        {
          disableBackdropClick: true,
        },
      );
    } else if (response.body && response.body.error) {
      errorDialog(t(response.body.error), t('Error while transferring vehicle'), t('Ok'), undefined, {
        disableBackdropClick: true,
      });
    } else {
      history.push(`${getBaseUrl()}/urban-vehicles`);
      enqueueSnackbar(t('Successfully transferred vehicle !'), { variant: 'success' });
    }
  }

  const closeFn = () => {
    setOpen(false);
    reset();
    setOnSelected(undefined);
    setOnClose(undefined);
    if (onClose) onClose();
  };

  const onSave = async () => {
    transferVehicle();
    setOpen(false);
    reset();
    if (onSelected) {
      onSelected({
        sourceLocation,
        targetLocationData,
        id,
        value,
      });
    }
    closeFn();
  };

  useEffect(() => {
    openConfirmTransferDialog = ({
      originalLocation,
      vehicleId,
      data,
      openProp,
      onSelectedCallback,
      onCloseCallback,
    }: ConfirmTransferDialogProps) => {
      setOpen(true);
      reset();
      setValue('originalLocation', originalLocation);
      setValue('vehicleId', vehicleId);
      setValue('data', data);
      setOnSelected(onSelectedCallback);
      setOnClose(onCloseCallback);
    };
  }, [setOpen, reset, setValue]);

  return (
    <>
      {open && (
        <Dialog
          open
          onClose={() => setOpen(false)}
          aria-labelledby="customized-dialog-title"
          fullWidth
          maxWidth="sm"
          scroll="paper"
        >
          <DialogTitle>{t('Transfer vehicle')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('Are you sure you want to transfer the vehicle to the following location?')}
            </DialogContentText>
            {targetLocationData?.customer_type ? (
              <AccountAvatar
                accountType={targetLocationData?.customer_type as AccountAvatarProps['accountType']}
                name={targetLocationData?.customer_name}
                accountDisplay="short"
                addressDisplay="cityState"
                style={{ cursor: 'pointer' }}
                address={{
                  city: targetLocationData?.customer_city,
                  state: targetLocationData?.customer_state,
                }}
                accountNumbers={{
                  parentCompany: targetLocationData?.parent_company_number,
                  homeOffice: targetLocationData?.home_office_number,
                  billTo: targetLocationData?.bill_to_customer,
                  shipTo: targetLocationData?.ship_to_customer,
                }}
              />
            ) : null}
            <br />
            <RadioGroup
              aria-label="transfer-reason"
              name="transfer-reason"
              defaultValue={id}
              onChange={(e) => {
                unregister('value');
                setValue('id', e.target.value);
              }}
            >
              {transferReasonItems.map((item) => (
                <FormControlLabel
                  key={item.id}
                  value={item.id}
                  control={<Radio color="primary" value={item.id} />}
                  label={t(item.label)}
                />
              ))}
            </RadioGroup>
            {id === 'other' && (
              <Controller
                name="value"
                control={control}
                rules={{ required: true }}
                render={(item: any) => (
                  <TextField
                    label={t('Specify a custom reason...')}
                    value={item.field.value || ''}
                    variant={textInputProps.variant}
                    InputProps={textInputProps.InputProps}
                    style={textInputProps.style}
                    required
                    onChange={(e) => item.field.onChange(e.target.value)}
                    error={!!item.formState.errors.value}
                  />
                )}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleSubmit(onSave)} disabled={!id}>
              {t('Confirm')}
            </Button>
            <Button autoFocus color="danger" onClick={closeFn}>
              {t('Cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
