/* eslint-disable react/sort-comp */
import React from 'react';
import { CentralProviderHeader, CurrentLocationAvatar } from '@michelin/central-provider';
import { runningOnCanvas } from '@michelin/central-provider/dist/utils/canvas';

export function Heading() {
  return (
    <>
      <div style={{ display: runningOnCanvas() ? 'none' : undefined }}>
        <CentralProviderHeader
          appTitle="Michelin"
          logoType="michelin"
          customLink={
            <CurrentLocationAvatar
              allowedAccountTypes="fleet"
              allowedCountryCodes={['US', 'CA']}
              enableStateFiltering
            />
          }
          profileMenuItems={[]}
        />
      </div>
    </>
  );
}
