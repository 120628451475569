import gql from 'graphql-tag';
import { assigneeType } from '../../AssigneeType';

const queryStartingCustomer = gql`
  query GetCustomerAssets($hash_key: String!) {
    getCustomerAssets(hash_key: $hash_key, range_key: "v0_customer") {
      customer {
        bill_to_customer
        customer_addr1
        customer_addr2
        customer_city
        customer_country
        customer_name
        customer_number
        customer_state
        customer_type
        customer_zip
        extrnl_cust_id
        hash_key
        home_office_number
        logo_url
        parent_company_number
        relationship
        ship_to_customer
      }
    }
  }
`;

const queryAssigneeList = gql`
  query GetAssignees($hash_key: String!) {
    items: getAssignees(hash_key: $hash_key, range_key: "1~") {
      ${assigneeType}
    }
  }
`;

const getChildrenForAssignments = gql`
  query getChildren($hash_key: String!, $range_key: String!, $nextToken: String) {
    getChildren(hash_key: $hash_key, range_key: $range_key, nextToken: $nextToken) {
      nextToken
      customers {
        hash_key
        range_key
        customer_name
        customer_type
        extrnl_cust_id
        customer_addr1
        customer_addr2
        customer_city
        customer_state
        customer_zip
        customer_number
        parent_company_number
        home_office_number
        bill_to_customer
        ship_to_customer
        relationship
      }
    }
  }
`;

export { queryAssigneeList, getChildrenForAssignments, queryStartingCustomer };
