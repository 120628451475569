import { MaestroAPI, maestroApiCall } from '@michelin/central-provider';
import { MethodType } from '@michelin/central-provider/dist/helpers/createAPICall';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import GLOBAL_CONFIG from 'global-variables';
import { AccountRequest, LocalDealerAndNationAccount } from '../modules/Accounts/types';


const baseURL = GLOBAL_CONFIG.externalAPIUrl;

const updateAccount = async ({
  selectedAccount,
  hashKey,
  account,
  action,
}: {
  selectedAccount: string;
  hashKey: string;
  account: AccountRequest;
  action: 'edit' | 'add';
}): Promise<LocalDealerAndNationAccount | null> => {
  if (!selectedAccount) return null;
  const apiType: MaestroAPI = {
    method: action === 'edit' ? MethodType.PUT : MethodType.POST,
    endpoint: `/cp-be/accounts/${selectedAccount}/${hashKey}`,
    baseURL: baseURL || '',
  };
  const response = await maestroApiCall<LocalDealerAndNationAccount>(apiType, account);

  return response;
};

export function useUpdateAccount({
  selectedAccount,
  hashKey,
}: {
  selectedAccount: string;
  hashKey: string;
  action: 'edit' | 'add';
}) {
  const queryClient = useQueryClient();
  return useMutation(updateAccount, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['account', selectedAccount || '', hashKey || '']);
    },
  });
}