/* eslint-disable max-classes-per-file */
import { HoursOfOperation } from 'components/Types';
import { DealerProvider } from 'modules/Locations/types';

export interface UpdateInput {
  hash_key: string;
  input: InputObject;
}

export interface Data {
  loading: boolean;
  customer: Customer;
}

export interface UpdateDealerLocationsInput {
  dealers: DealerCustomerRecordInput[];
  queryName: string;
}

export interface TireOrderingInput {
  input: any;
}
export interface DealerCustomerRecordInput {
  hash_key: string;
  range_key: string;
  flag: string;
  dealer_name: string;
  is_urban: boolean;
  is_commercial: boolean;
}

export interface Customer {
  hash_key: string;
  range_key: string;
  customer_name: string;
  pickup_delivery_instructions: string;
  customer_type: string;
  extrnl_cust_id: string;
  customer_addr1: string;
  customer_addr2: string;
  customer_country: string;
  customer_city: string;
  customer_state: string;
  customer_zip: string;
  parent_company_number: string;
  home_office_number: string;
  bill_to_customer: string;
  ship_to_customer: string;
  phone_number: string;
  location: string;
  numberOfFleetTrucks: string;
  relationship?: string;
  numberOfFleetTrailers: string;
  primaryTransportActivity: string;
  primaryVocation: string;
  secondaryTransportActivity: string;
  secondaryVocation: string;
  method_of_delivery?: string;
  delivery_address_same_as_physical?: boolean;
  delivery_address?: string;
  delivery_city?: string;
  delivery_state?: string;
  delivery_zip?: string;
  hoursOfOperations?: HoursOfOperation;
  operationHours?: HoursOfOperation;
  recipient_signature_required?: boolean;
  driver_signature_required?: boolean;
  update_date: string;
  cust_timezone: string;
  is_urban: boolean;
  urban_admin_allow_create_vehicle: boolean;
  urban_admin_allow_preapproved_txs: boolean;
  urban_admin_preapproved_txs_limit_amount: string;
  urban_admin_preapproved_txs_no_limits: boolean;
  oncall_sds_dashboard: boolean;
  is_commercial: boolean;
  mechanical_services_enabled: boolean;
}

export interface CustomerData {
  getCustomerAssets: CustomerFacade;
}

export interface CustomerChildAssets {
  customer: {
    hash_key: string;
    is_urban: boolean;
    customer_type: string;
    oncall_sds_dashboard: boolean;
    urban_admin_allow_create_vehicle: boolean;
    urban_admin_allow_preapproved_txs: boolean;
    urban_admin_preapproved_txs_limit_amount: string;
    urban_admin_preapproved_txs_no_limits: boolean;
    is_commercial: boolean;
  };
}

export interface CustomerFacade {
  customer: Customer;
  childAssets: CustomerChildAssets[];
  loading: boolean;
}

type TireOrderingInfoProp = {
  getEntityPaginated: EntityPaginated;
  loading: boolean;
};

type EntityPaginated = {
  items: any;
};

export interface DetailsProps {
  details: QueryProps;
  letItBe: (input: InputObject) => {};
  getDealerz: DealerProp;
  updateDealerLocations: (input: DealerMutationInput) => {};
  getTireOrderingInformation: TireOrderingInfoProp;
}

export type DealerMutationInput = {
  variables: UpdateDealerLocationsInput;
};

export type DealerData = {
  loading: boolean;
  getDealers: DealerProvider[];
};

export interface DealerProp {
  getDealers: DealerProvider[];
  loading: boolean;
}

export interface InputObject {
  variables: UpdateInput;
}
export interface letItBeRequest {
  variables: UpdateInput;
}

export interface StateProp {
  state: InnerStateProp;
}

export interface InnerStateProp {
  id: string;
}

export interface QueryProps {
  getCustomerAssets: CustomerFacade;
  loading: boolean;
}

export interface UpdateProps {}
export interface ChildenProps {
  location: Location;
}

export interface Location {
  state: StateInterface;
}

export interface StateInterface {
  id: string;
}

export interface updateFunction {}

export class SaveError {
  errors: string[] = [];

  errorMessage: string = '';
}

export class Vocation {
  numberOfFleetTrucks: string = '0';

  numberOfFleetTrailers: string = '0';

  primaryTransportActivity: string = 'None';

  primaryVocation: string = 'None';

  secondaryTransportActivity: string = 'None';

  secondaryVocation: string = 'None';

  method_of_delivery?: string;

  delivery_address_same_as_physical?: boolean;

  delivery_address?: string;

  delivery_city?: string;

  delivery_state?: string;

  delivery_zip?: string;

  hoursOfOperations?: HoursOfOperation;

  recipient_signature_required?: boolean;

  driver_signature_required?: boolean;

  checkMyStuff() {
    if (!this.numberOfFleetTrucks) {
      this.numberOfFleetTrucks = ' ';
    }
    if (!this.numberOfFleetTrailers) {
      this.numberOfFleetTrailers = ' ';
    }
    if (!this.primaryTransportActivity) {
      this.primaryTransportActivity = ' ';
    }
    if (!this.primaryVocation) {
      this.primaryVocation = ' ';
    }
    if (!this.secondaryTransportActivity) {
      this.secondaryTransportActivity = ' ';
    }
    if (!this.secondaryVocation) {
      this.secondaryVocation = ' ';
    }
  }
}
