import { makeStyles } from '@material-ui/core';
import { CustomerData } from 'AuthUtils';
import { gql } from 'apollo-boost';
import { Customer as LocationCustomer } from 'components/Location';
import _ from 'lodash';
import { Customer } from '../../Auth';

export const notAuthorizedByHierarchyTitle = 'Not enough permissions.';
export const deleteNotAuthorizedByHierarchyMessage =
  'This account does not have enough permissions to delete this contact from all the locations attached to it.';
export const contactLevelNotAuthorizedByHierarchyMessage =
  'This account does not have enough access to change the level since there are other locations attached to it.';
export const contactTypeRoleDeleteNotAuthorizedByHierarchyMessage =
  'This contact type is used in other locations where this account does not have permissions to modify.';

export const contactQuery = gql`
  query Contact($hash_key: String!, $range_key: String!) {
    getContactByKey(hash_key: $hash_key, range_key: $range_key) {
      hash_key
      range_key
      gsi1_hash_key
      gsi1_range_key
      first_name
      last_name
      cell_phone
      work_phone
      ext
      email_address
      preferred_method
      contact_level
      contact_types {
        service
        role_areas {
          role_area
          levels {
            location
            level
          }
        }
        id
      }
      job_title
      fax
      preferred_language
      group
      visible_externally
      num_of_tires
      num_of_wheels
      tire_dollar_amount
      mechanical_dollar_amount
      work_hours {
        monday {
          begin
          end
        }
        tuesday {
          begin
          end
        }
        wednesday {
          begin
          end
        }
        thursday {
          begin
          end
        }
        friday {
          begin
          end
        }
        saturday {
          begin
          end
        }
        sunday {
          begin
          end
        }
      }
      notification_subscription {
        event
        type
        method
        cc_emails
        id
      }
      locations {
        is_deleted
        hash_key
        gsi2_hash_key
        gsi2_range_key
        location {
          hash_key
          customer_name
          customer_number
          customer_type
          customer_zip
          customer_state
          customer_city
          customer_addr1
          customer_addr2
          relationship
          extrnl_cust_id
          parent_company_number
          bill_to_customer
          home_office_number
          ship_to_customer
        }
      }
    }
  }
`;

const contactAttributes = `
  hash_key
`;

export const updateCustomerContact = gql`
  mutation UpdateCustomerContact($hash_key: String!, $input: UpdateContactInput!) {
    updateCustomerContact(hash_key: $hash_key, input: $input) {
      ${contactAttributes}
    }
  }
`;

export const updateCustomerContactRAW = `
  updateCustomerContact(hash_key: $hash_key, input: $input) {
    ${contactAttributes}
  }
`;

export const updateCustomerContactAlt = gql`
  mutation UpdateCustomerContact($hash_key: String!, $input: UpdateContactInput!) {
    updateCustomerContact(hash_key: $hash_key, input: $input) {
      ${contactAttributes}
    }
  }
`;

export const createCustomerContact = gql`
  mutation CreateCustomerContact($hash_key: String!, $contact: ContactInput!) {
    createCustomerContact(hash_key: $hash_key, contact: $contact) {
      ${contactAttributes}
    }  
  }
`;

export const createCustomerContactRAW = `
  createCustomerContact(hash_key: $hash_key, contact: $contact) {
    ${contactAttributes}
  }
`;

const customerType: string = `
  customer {
    hash_key
    range_key  
    customer_number
    extrnl_cust_id
    customer_name
    customer_addr1
    customer_addr2
    customer_city
    customer_state
    customer_zip
    customer_type
    parent_company_number
    bill_to_customer
    home_office_number
    ship_to_customer
  }
`;

export const queryCustomerList = gql`
  query CustomerList($hash_key: String!, $range_key: String!) {
      getCustomerAssets(hash_key: $hash_key, range_key: $range_key) {
          ${customerType}
          childAssets {
            ${customerType}
             childAssets {
                 ${customerType}
                 childAssets {
                   ${customerType}
          }
        }
      }
    }
  }
`;

const contactType: string = `
  contact {
    hash_key,
    range_key,
    contact_id,
    first_name,
    last_name,
    cell_phone,
    work_phone,
    contact_level,
    ext,
    email_address,
    preferred_method,      
    contact_types {
      service
      role_areas {
        role_area
        levels {
          location
          level
        }
      }
      id
    }
  }
`;

export const queryContactBygetContactByKey = `
  getContactByKey(hash_key: "$hash_key", range_key: "v0_contact") {
    hash_key,
    range_key,
    contact_id,
    first_name,
    last_name,
    cell_phone,
    work_phone,
    contact_level,
    ext,
    email_address,
    preferred_method,
    create_user,
    created_date,      
    contact_types {
      service
      role_areas {
        role_area
        levels {
          location
          level
        }
      }
      id
    }
    locations {
      hash_key
      gsi2_hash_key
      gsi2_range_key
      is_deleted
        location {
          hash_key
          customer_name
          customer_number
          customer_type
          customer_zip
          customer_state
          customer_city
          customer_addr1
          customer_addr2
          extrnl_cust_id
          parent_company_number
          bill_to_customer
          home_office_number
          ship_to_customer
        }
    }
  }
`;

export const queryContactListPaginated = gql`
  query Paginated_ContactLocationRelationships(
    $location: String!
    $chainedHierarchy: String!
    $limit: Int
    $nextToken: String
    $filterDeleted: Boolean
  ) {
    getContactLocationsRelationshipsPaginated(
      location: $location
      chainedHierarchy: $chainedHierarchy
      limit: $limit
      nextToken: $nextToken
      filterDeleted: $filterDeleted
    ) {
      items {
        hash_key
        is_deleted
        range_key
        gsi1_hash_key
        gsi2_range_key
      }
      nextToken
    }
  }
`;

export const queryContactList = gql`
  query ContactList($hash_key: String!, $range_key: String!) {
      getCustomerAssets(hash_key: $hash_key, range_key: $range_key) {
          ${customerType}
      contactLocationRelationship {
        ${contactType}
      }
          childAssets {
            ${customerType}
        contactLocationRelationship {
          ${contactType}
        }
             childAssets {
                 ${customerType}
          contactLocationRelationship {
            ${contactType}
          }
                 childAssets {
                   ${customerType}
            contactLocationRelationship {
              ${contactType}
            }
          }
        }
      }
    }
  }
`;

export interface ContactTypeLevels {
  level: string;
  location: string;
}

export interface ContactTypeRole {
  role_area: string;
  levels: Array<ContactTypeLevels>;
}

export interface ContactType {
  id: string;
  service?: string;
  role_areas?: Array<ContactTypeRole>;
}

export interface NotificationSubscription {
  event?: string;
  type?: string;
  method?: string[];
  cc_emails?: string[];
  id: string;
}

export interface Contact {
  hash_key: string;
  range_key: string;
  gsi1_hash_key: string;
  gsi1_range_key: string;
  contact_level: string;
  first_name: string;
  last_name: string;
  cell_phone?: string | null;
  work_phone?: string | null;
  ext?: string;
  email_address?: string;
  preferred_method?: string;
  contact_types?: Array<ContactType>;
  job_title: string;
  fax?: string;
  preferred_language: string;
  group?: boolean;
  visible_externally?: boolean;
  num_of_tires?: number;
  num_of_wheels?: number;
  tire_dollar_amount?: number;
  mechanical_dollar_amount?: number;
  work_hours: WorkHours;
  notification_subscription?: NotificationSubscription[];
  locations?: ContactLocationRelationship[];
}

export const getIfDR = gql`
  query getEntityPaginated($hash_key: String!, $range_key: String!, $index: String) {
    getEntityPaginated(hash_key: $hash_key, range_key: $range_key, index: $index) {
      items
    }
  }
`;

export interface ContactForList {
  hash_key: string;
  is_deleted?: boolean;
  range_key: string;
  contact_id: string;
  contact_level: string;
  first_name: string;
  last_name: string;
  account_type: string;
  account_number: string;
  city: string;
  state: string;
  cell_phone: string;
  work_phone: string;
  ext: string;
  email_address: string;
  preferred_method: string;
  specialSauce?: boolean;
  contact_types?: ContactType[];
  locations?: [CustomerForList];
  num_of_tires?: number;
  num_of_wheels?: number;
  tire_dollar_amount?: string;
}

export interface ContactLocationRelationshipForList {
  hash_key: string;
  range_key: string;
  gsi1_hash_key: string;
  gsi1_range_key: string;
  is_deleted?: boolean;
  contact: ContactForList;
}

export interface CustomerForList {
  hash_key: string;
  customer_name: string;
  customer_type: string;
  customer_number: string;
  customer_city: string;
  customer_state: string;
  parent_company_number: string;
  bill_to_customer: string;
  home_office_number: string;
  ship_to_customer: string;
}

export interface RecursiveCustomerContact {
  contactLocationRelationship?: Array<ContactLocationRelationshipForList>;
  childAssets?: Array<RecursiveCustomerContact>;
  customer: CustomerForList;
}

export interface Location {
  hash_key: string;
  customer_name: string;
  customer_number: string;
}

export type ContactLocationRelationship = {
  hash_key: string;
  range_key: string;
  gsi1_hash_key: string;
  gsi1_range_key: string;
  gsi2_hash_key: string;
  gsi2_range_key: string;
  is_deleted: boolean;
  location?: Customer;
  contact?: Contact;
  created_date?: string;
  create_user?: string;
};

export type RelationshipLevels = {
  level: string;
  contact_type_id: string;
};

export type Relationship = {
  hash_key: string;
  range_key: string;
  gsi1_hash_key: string;
  gsi1_range_key: string;
  gsi2_hash_key: string;
  gsi2_range_key: string;
  is_deleted: boolean;
  levels?: Array<RelationshipLevels>;
};

export interface WorkHours {
  monday?: DayWorkHours;
  tuesday?: DayWorkHours;
  wednesday?: DayWorkHours;
  thursday?: DayWorkHours;
  friday?: DayWorkHours;
  saturday?: DayWorkHours;
  sunday?: DayWorkHours;
}

export interface DayWorkHours {
  begin?: string | null;
  end?: string | null;
}

export interface Variables {
  hash_key: string;
  range_key: string;
}

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: 250,
  },
  width250: {
    width: 250,
  },
  width50: {
    width: 50,
  },
  width150: {
    width: 150,
  },
  width300: {
    width: 300,
  },
  timePicker: {
    width: 130,
  },
  workHoursLabel: {
    paddingTop: 10,
  },
  workHoursPanel: {
    textAlign: 'center',
  },
  workHoursDays: {
    textAlign: 'left',
  },
  menuItem: {
    fontSize: 'inherit',
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: '32px',
  },
}));

export interface DropDownElement {
  label: string;
  value: string;
}

export const getExistingContactQuery = gql`
  query Query(
    $hash_key: String!
    $gsi1_hash_key: String!
    $gsi1_range_key: String!
    $cell_phone: String
    $work_phone: String
    $email: String
  ) {
    getExistingContact(
      hash_key: $hash_key
      gsi1_hash_key: $gsi1_hash_key
      gsi1_range_key: $gsi1_range_key
      cell_phone: $cell_phone
      work_phone: $work_phone
      email: $email
    ) {
      hash_key
      range_key
      first_name
      last_name
    }
  }
`;

export const upsertContactLocationRelationship = gql`
  mutation Mutation(
    $location_hash_key: String!
    $contact_hash_key: String!
    $is_deleted: Boolean!
    $gsi2_hash_key: String
    $gsi2_range_key: String
  ) {
    upsertContactLocationRelationship(
      location_hash_key: $location_hash_key
      contact_hash_key: $contact_hash_key
      is_deleted: $is_deleted
      gsi2_hash_key: $gsi2_hash_key
      gsi2_range_key: $gsi2_range_key
    ) {
      hash_key # Location hash_key | Location = Customer = Fleet = Dealer
      range_key # Contact hash_key
      gsi1_hash_key # Contact hash_key
      gsi1_range_key # Location hash_key | Location = Customer = Fleet = Dealer
      gsi2_hash_key # Upper location hash_key
      gsi2_range_key # Hierarchy chain
      is_deleted
    }
  }
`;

export const upsertContactLocationRelationshipRAW = `
  upsertContactLocationRelationship(location_hash_key: "$location_hash_key", contact_hash_key: "$contact_hash_key", is_deleted: $is_deleted, gsi2_hash_key: "$gsi2_hash_key", gsi2_range_key: "$gsi2_range_key") {
    hash_key
  }
`;

export function deepOmit(obj: any, keysToOmit: string[]) {
  const keysToOmitIndex = _.keyBy(Array.isArray(keysToOmit) ? keysToOmit : [keysToOmit]); // create an index object of the keys that should be omitted

  function omitFromObject(obj: any) {
    // the inner function which will be called recursivley
    return _.transform(obj, (result: any, value: any, key: any) => {
      // transform to a new object
      if (key in keysToOmitIndex) {
        // if the key is in the index skip it
        return;
      }
      result[key] = _.isObject(value) ? omitFromObject(value) : value; // if the key is an object run it through the inner function - omitFromObject
    });
  }

  return omitFromObject(obj); // return the inner function result
}

export function obtainGsi2RelationHashKey(
  location: Pick<
    Customer,
    'parent_company_number' | 'bill_to_customer' | 'home_office_number' | 'ship_to_customer' | 'hash_key'
  >,
) {
  const { parent_company_number, bill_to_customer, home_office_number, ship_to_customer } = location;
  const prefix = '2~';

  if (parent_company_number) {
    return prefix + parent_company_number;
  }
  if (home_office_number) {
    return prefix + home_office_number;
  }
  if (ship_to_customer) {
    return prefix + ship_to_customer;
  }
  if (bill_to_customer) {
    return prefix + bill_to_customer;
  }
  return prefix + location.hash_key;
}

// Same function as import.
export function obtainGsi2RelationRangeKey(
  location: Pick<
    Customer,
    'parent_company_number' | 'bill_to_customer' | 'home_office_number' | 'ship_to_customer' | 'hash_key'
  >,
) {
  let relationshipkey = '';
  const pc = location.parent_company_number ? `${location.parent_company_number}~` : '';
  const ho = location.home_office_number
    ? `${location.home_office_number}~`
    : !location.home_office_number && location.parent_company_number
    ? 'null~'
    : '';
  const bt = location.bill_to_customer
    ? `${location.bill_to_customer}~`
    : (location.parent_company_number || location.home_office_number) && !location.bill_to_customer
    ? 'null~'
    : '';
  const st = location.ship_to_customer ? location.ship_to_customer : 'null';
  relationshipkey = pc + ho + bt + st;
  return relationshipkey;
}

export const addLocationToChain = (
  hierarchyChain: string | String,
  location_number: string | String | null,
): string => {
  if (location_number && location_number !== 'null' && location_number !== null) {
    if (hierarchyChain !== '') {
      return `${hierarchyChain}~${location_number}`;
    }
    return location_number.toString();
  }
  return hierarchyChain.toString();
};

export const getChainedHierarchy = (customerData: CustomerData | LocationCustomer): string => {
  const { ship_to_customer, bill_to_customer, home_office_number, parent_company_number, customer_type } = customerData;
  let hierarchyChain = '';
  switch (customer_type) {
    case 'PC':
      hierarchyChain = addLocationToChain(hierarchyChain, parent_company_number);
      return hierarchyChain;
    case 'HO':
      hierarchyChain = addLocationToChain(hierarchyChain, parent_company_number);
      hierarchyChain = addLocationToChain(hierarchyChain, home_office_number);
      return hierarchyChain;
    case 'BT':
      hierarchyChain = addLocationToChain(hierarchyChain, parent_company_number);
      hierarchyChain = addLocationToChain(hierarchyChain, home_office_number);
      hierarchyChain = addLocationToChain(hierarchyChain, bill_to_customer);
      return hierarchyChain;
    case 'ST':
      hierarchyChain = addLocationToChain(hierarchyChain, parent_company_number);
      hierarchyChain = addLocationToChain(hierarchyChain, home_office_number);
      hierarchyChain = addLocationToChain(hierarchyChain, bill_to_customer);
      hierarchyChain = addLocationToChain(hierarchyChain, ship_to_customer);
      return hierarchyChain;
    default:
      return hierarchyChain;
  }
};

export const getHighestLocation = (
  customerData: Pick<
    CustomerData | LocationCustomer,
    'ship_to_customer' | 'bill_to_customer' | 'home_office_number' | 'parent_company_number'
  >,
) => {
  const { ship_to_customer, bill_to_customer, home_office_number, parent_company_number } = customerData;
  if (parent_company_number) {
    return parent_company_number;
  }
  if (home_office_number) {
    return home_office_number;
  }
  if (bill_to_customer) {
    return bill_to_customer;
  }
  if (ship_to_customer) {
    return ship_to_customer;
  }
};

export const getHighestLocationType = (
  customerData: Pick<
    CustomerData | LocationCustomer,
    'bill_to_customer' | 'home_office_number' | 'parent_company_number'
  >,
) => {
  const { bill_to_customer, home_office_number, parent_company_number } = customerData;
  if (parent_company_number) return 'PC';
  if (home_office_number) return 'HO';
  if (bill_to_customer) return 'BT';
  return 'ST';
};

export const isInMyHierarchy = (
  relationship: ContactLocationRelationship,
  customerData: Pick<
    CustomerData | LocationCustomer,
    'ship_to_customer' | 'bill_to_customer' | 'home_office_number' | 'parent_company_number' | 'customer_type'
  >,
) => {
  const { ship_to_customer, bill_to_customer, home_office_number, parent_company_number, customer_type } = customerData;
  const relations = relationship.gsi2_range_key.replace(/~null/g, '');
  const locations = relations.split('~');
  switch (customer_type) {
    case 'ST':
      if (ship_to_customer && locations.includes(ship_to_customer)) return true;
      if (locations[locations.length - 1] && locations[locations.length - 1] === bill_to_customer) return true;
      if (locations[locations.length - 1] && locations[locations.length - 1] === home_office_number) return true;
      if (locations[locations.length - 1] && locations[locations.length - 1] === parent_company_number) return true;
      break;
    case 'BT':
      if (bill_to_customer && locations.includes(bill_to_customer)) return true;
      if (locations[locations.length - 1] && locations[locations.length - 1] === home_office_number) return true;
      if (locations[locations.length - 1] && locations[locations.length - 1] === parent_company_number) return true;
      break;
    case 'HO':
      if (home_office_number && locations.includes(home_office_number)) return true;
      if (locations[locations.length - 1] && locations[locations.length - 1] === parent_company_number) return true;
      break;
    case 'PC':
      return !!(parent_company_number && locations.includes(parent_company_number));
    default:
      return false;
  }

  return false;
};
