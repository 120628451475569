import React, { FunctionComponent } from 'react';
import { ServiceProvider } from './ServiceProvider';

export interface ServiceProvidersTabProps {
  editFlag: boolean;
  isUrban: boolean;
  isCommercial: boolean;
  state: string;
  city: string;
}

export const ServiceProviders: FunctionComponent<ServiceProvidersTabProps> = (props) => {
  const { isUrban, editFlag, state, city, isCommercial } = props;
  const [updateState, setUpdateState] = React.useState(0);
  const forceUpdate = () => setUpdateState(updateState + 1);
  return (
    <>
      {isCommercial && (
        <ServiceProvider
          state={state}
          city={city}
          editFlag={editFlag}
          isUrban={false}
          isCommercial={true}
          updateState={updateState}
          forceUpdate={forceUpdate}
        />
      )}
      {isUrban && (
        <ServiceProvider
          state={state}
          city={city}
          editFlag={editFlag}
          isUrban={true}
          isCommercial={false}
          updateState={updateState}
          forceUpdate={forceUpdate}
        />
      )}
    </>
  );
};
