import React from 'react';
import { Checkbox, Link, Theme, Tooltip, withStyles } from '@material-ui/core';
import { Star } from '@material-ui/icons';
import { useTranslation } from '@michelin/central-provider';
import {
  ERS_MANAGER_ROLE_AREA_VALUE,
  GENERAL_LEVEL_VALUE,
  PO_ISSUER_ROLE_AREA_VALUE,
  PRIMARY_LEVEL_VALUE,
  SECONDARY_LEVEL_VALUE,
  TIRE_SERVICE_VALUE,
  getContactTypesRoleAreaLabelFromValue,
} from 'components/Contact/ContactDetails/utils';
import { Contact, ContactType } from 'components/Contact/utils';
import { IAssigneeCustomerType } from 'components/TireProfile/Assignee/AssigneeType';
import { TranslateCallback } from 'components/Util';
import { capitalize } from 'lodash';
import { getBaseUrl } from 'prefs-and-service-offers';
import { useHistory } from 'react-router-dom';
import { Customer } from '../../../../../Auth';
import { ReassignSelection } from '../dialogs/ReassignContactTypesDialog';

export interface Row {
  location: Customer;
  contactType: ContactType;
}

const nothingAssignedMessage = 'There are no priority levels assigned for this location.';

function internalRendering(contactTypes: Array<{ level: string; roleArea: string }> | undefined, t: TranslateCallback) {
  const mappedContactTypes =
    contactTypes &&
    contactTypes.map((contactType: { level: string; roleArea: string }) => {
      const { level, roleArea } = contactType;
      return (
        <div key={`contact-type-${roleArea}`} style={{ display: 'flex', marginTop: '0.5rem', marginRight: '0.5rem' }}>
          <span>
            <b>{level !== GENERAL_LEVEL_VALUE ? t(capitalize(level)) : ''}</b> {t('Tire')}{' '}
            {getContactTypesRoleAreaLabelFromValue(roleArea, t)}
            <br />
          </span>
          <Checkbox disableFocusRipple disableRipple disableTouchRipple style={{ padding: 0, visibility: 'hidden' }} />
        </div>
      );
    });

  return mappedContactTypes && mappedContactTypes.length > 0 ? (
    <div style={{ marginTop: '0.8rem' }}>{mappedContactTypes}</div>
  ) : (
    <p>{t(nothingAssignedMessage)}</p>
  );
}

export function contactTypesRenderForTreeList(
  location: IAssigneeCustomerType,
  contactTypePerLocation: Map<string, any>,
  t: TranslateCallback,
): JSX.Element {
  if (location && location.hash_key) {
    const contactTypes: Array<{ level: string; roleArea: string }> = contactTypePerLocation.get(
      location.hash_key.toString(),
    );
    return internalRendering(contactTypes, t);
  }

  return <p>{t(nothingAssignedMessage)}</p>;
}

export function contactTypesRender(
  row: Row,
  contactTypePerLocation: Map<string, any>,
  t: TranslateCallback,
): JSX.Element {
  const { location } = row;
  const contactTypes: Array<{ level: string; roleArea: string }> = contactTypePerLocation.get(
    location.hash_key.toString(),
  );
  return internalRendering(contactTypes, t);
}

export function priorityHeaderCellRender(
  allPrimary: boolean,
  allSecondary: boolean,
  onAllPriorityLevelChanged: (level: string, checked: boolean) => void,
  t: Function,
) {
  return () => (
    <div style={{ textAlign: 'center' }}>
      <p>{t('Priority Level')}</p>
      <div style={{ display: 'flex', marginTop: '0.5rem' }}>
        <span style={{ marginRight: '0.5rem', alignSelf: 'center', fontSize: '13.5px' }}>{t('Primary')}</span>
        <Checkbox
          color="primary"
          disableTouchRipple
          disableRipple
          disableFocusRipple
          style={{ padding: 0 }}
          checked={allPrimary}
          onChange={(e) => {
            onAllPriorityLevelChanged(PRIMARY_LEVEL_VALUE, e.target.checked);
          }}
          disabled={allSecondary === true}
        />
        <span
          style={{
            marginLeft: '1rem',
            marginRight: '0.5rem',
            alignSelf: 'center',
            fontSize: '13.5px',
          }}
        >
          {t('Secondary')}
        </span>
        <Checkbox
          color="primary"
          disableTouchRipple
          disableRipple
          disableFocusRipple
          style={{ padding: 0 }}
          checked={allSecondary}
          onChange={(e) => {
            onAllPriorityLevelChanged(SECONDARY_LEVEL_VALUE, e.target.checked);
          }}
          disabled={allPrimary === true}
        />
      </div>
    </div>
  );
}

/**
 * Michelin Offer detection
 */
export interface MichelinOffer {
  level: string;
  service: string;
  roleArea: string;
  offer: string;
}

export const michelinOffersMap = new Map<string, string>();

// This should be loaded as options
const michelinOffers: Array<MichelinOffer> = [
  {
    level: PRIMARY_LEVEL_VALUE,
    service: TIRE_SERVICE_VALUE,
    roleArea: PO_ISSUER_ROLE_AREA_VALUE,
    offer: 'ONCall Tire ERS Service',
  },
  {
    level: SECONDARY_LEVEL_VALUE,
    service: TIRE_SERVICE_VALUE,
    roleArea: PO_ISSUER_ROLE_AREA_VALUE,
    offer: 'ONCall Tire ERS Service',
  },
  {
    level: PRIMARY_LEVEL_VALUE,
    service: TIRE_SERVICE_VALUE,
    roleArea: ERS_MANAGER_ROLE_AREA_VALUE,
    offer: 'ONCall Tire ERS Service',
  },
  {
    level: SECONDARY_LEVEL_VALUE,
    service: TIRE_SERVICE_VALUE,
    roleArea: ERS_MANAGER_ROLE_AREA_VALUE,
    offer: 'ONCall Tire ERS Service',
  },
];

michelinOffers.forEach((x) => {
  const { level, service, roleArea, offer } = x;
  michelinOffersMap.set(level + service + roleArea, offer);
});

export function isRoleAreaServiceLevelMichelinOffer(role_area: string, service: string, level: string): boolean {
  return michelinOffersMap.has(level + service + role_area);
}

export function primaryIsAlreadyAssigned(
  role_area: string,
  location: string,
  mappedExternalContacts: Map<string, Contact>,
  externalContactsWithRoleAreaReassigned: Map<string, ReassignSelection>,
): boolean {
  const primaryAlreadySelectedInExternal = mappedExternalContacts.get(`${role_area + location}${PRIMARY_LEVEL_VALUE}`);
  const primaryAlreadySelectedInExternalReassign = externalContactsWithRoleAreaReassigned.get(
    `${role_area + location}${PRIMARY_LEVEL_VALUE}`,
  );

  return (
    typeof primaryAlreadySelectedInExternal !== 'undefined' ||
    typeof primaryAlreadySelectedInExternalReassign !== 'undefined'
  );
}

export function hasSecondaryDependencies(
  role_area: string,
  level: string,
  location: string,
  mappedExternalContacts: Map<string, Contact>,
  externalContactsWithRoleAreaReassigned: Map<string, ReassignSelection>,
): boolean {
  if (level === PRIMARY_LEVEL_VALUE) {
    const secondaryAlreadySelectedInExternal = mappedExternalContacts.get(
      `${role_area + location}${SECONDARY_LEVEL_VALUE}`,
    );
    const secondaryAlreadySelectedInExternalReassign = externalContactsWithRoleAreaReassigned.get(
      `${role_area + location}${SECONDARY_LEVEL_VALUE}`,
    );

    return (
      typeof secondaryAlreadySelectedInExternal !== 'undefined' ||
      typeof secondaryAlreadySelectedInExternalReassign !== 'undefined'
    );
  }
  return false;
}

function trickedCheckboxRow(content: JSX.Element): JSX.Element {
  return (
    <div
      style={{
        display: 'flex',
        marginTop: '0.5rem',
        marginRight: '0.5rem',
        whiteSpace: 'nowrap',
        color: '#B22222',
      }}
    >
      {content}
      <Checkbox disableFocusRipple disableRipple disableTouchRipple style={{ padding: 0, visibility: 'hidden' }} />
    </div>
  );
}

export function currentlyAssignedRender(
  row: Row,
  contactTypePerLocation: Map<string, any>,
  assignedContactTypes: Set<string>,
  mappedExternalContacts: Map<string, Contact>,
  externalContactsWithRoleAreaReassigned: Map<string, ReassignSelection>,
): JSX.Element {
  const rowsAssigned = new Array<JSX.Element>();
  const { location } = row;
  const { hash_key } = location;

  const contactTypesInLocation = contactTypePerLocation.get(location.hash_key.toString());

  if (contactTypesInLocation) {
    contactTypesInLocation.forEach((contactType: { level: string; roleArea: string }) => {
      const { roleArea } = contactType;
      let level = GENERAL_LEVEL_VALUE;
      if (assignedContactTypes.has(`${roleArea + hash_key.toString()}${PRIMARY_LEVEL_VALUE}`)) {
        level = PRIMARY_LEVEL_VALUE;
      } else if (assignedContactTypes.has(`${roleArea + hash_key.toString()}${SECONDARY_LEVEL_VALUE}`)) {
        level = SECONDARY_LEVEL_VALUE;
      }

      const currentlyAssignedContact = mappedExternalContacts.get(roleArea + hash_key.toString() + level);
      const currentlyAssignedOnReassigned = externalContactsWithRoleAreaReassigned.get(
        roleArea + hash_key.toString() + level,
      );
      if (typeof currentlyAssignedContact !== 'undefined') {
        const { first_name: currentlyFirstName, last_name: currentlyLastName } = currentlyAssignedContact;
        rowsAssigned.push(
          trickedCheckboxRow(
            <span>
              {`${currentlyFirstName} ${currentlyLastName}`}
              <br />
            </span>,
          ),
        );
      } else if (typeof currentlyAssignedOnReassigned !== 'undefined') {
        const { first_name: currentlyReassignedFirstName, last_name: currentlyReassignedLastName } =
          currentlyAssignedOnReassigned.contact;
        rowsAssigned.push(
          trickedCheckboxRow(
            <span>
              {`${currentlyReassignedFirstName} ${currentlyReassignedLastName}`}
              <br />
            </span>,
          ),
        );
      } else {
        rowsAssigned.push(
          trickedCheckboxRow(
            <span>
              <br />
            </span>,
          ),
        );
      }
    });
  }
  return <div style={{ marginTop: '0.8rem' }}>{rowsAssigned}</div>;
}

export function currentlyAssignedSort(
  row: Row,
  contactTypePerLocation: Map<string, any>,
  assignedContactTypes: Set<string>,
  mappedExternalContacts: Map<string, Contact>,
  externalContactsWithRoleAreaReassigned: Map<string, ReassignSelection>,
): any {
  const rowsAssigned: string[] = [];
  if (!row) {
    return rowsAssigned.push('zzz');
  }
  const { location } = row;
  const { hash_key } = location;

  const contactTypesInLocation = contactTypePerLocation.get(location.hash_key.toString());

  if (contactTypesInLocation) {
    contactTypesInLocation.forEach((contactType: { level: string; roleArea: string }) => {
      const { roleArea } = contactType;
      let level = GENERAL_LEVEL_VALUE;
      if (assignedContactTypes.has(`${roleArea + hash_key.toString()}${PRIMARY_LEVEL_VALUE}`)) {
        level = PRIMARY_LEVEL_VALUE;
      } else if (assignedContactTypes.has(`${roleArea + hash_key.toString()}${SECONDARY_LEVEL_VALUE}`)) {
        level = SECONDARY_LEVEL_VALUE;
      }

      const currentlyAssignedContact = mappedExternalContacts.get(roleArea + hash_key.toString() + level);
      const currentlyAssignedOnReassigned = externalContactsWithRoleAreaReassigned.get(
        roleArea + hash_key.toString() + level,
      );
      if (typeof currentlyAssignedContact !== 'undefined') {
        const { first_name: currentlyFirstName, last_name: currentlyLastName } = currentlyAssignedContact;
        // rowsAssigned.push(trickedCheckboxRow(<span>{currentlyFirstName} {currentlyLastName}<br/></span>));
        rowsAssigned.push(`${currentlyFirstName} ${currentlyLastName}`);
      } else if (typeof currentlyAssignedOnReassigned !== 'undefined') {
        const { first_name: currentlyReassignedFirstName, last_name: currentlyReassignedLastName } =
          currentlyAssignedOnReassigned.contact;
        // rowsAssigned.push(trickedCheckboxRow(<span>{currentlyReassignedFirstName} {currentlyReassignedLastName}<br/></span>));
        rowsAssigned.push(`${currentlyReassignedFirstName} ${currentlyReassignedLastName}`);
      } else {
        rowsAssigned.push('zzz');
      }
    });
  }
  return rowsAssigned;
}

export function reassignRender(
  row: Row,
  contactTypePerLocation: Map<string, any>,
  swapContactType: (roleArea: string, level: string, locationHashKey: string, customer_name: string) => void,
  t: Function,
): JSX.Element {
  const { location } = row;
  const { hash_key, customer_name } = location;

  const contactTypesInLocation = contactTypePerLocation.get(location.hash_key.toString());
  if (contactTypesInLocation) {
    return (
      <div style={{ width: 'fit-content', marginTop: '0.8rem' }}>
        {contactTypesInLocation.map((contactType: { level: string; roleArea: string }) => {
          const { level, roleArea } = contactType;
          const linkOrEmpty =
            level === PRIMARY_LEVEL_VALUE || level === SECONDARY_LEVEL_VALUE ? (
              <Link
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  swapContactType(roleArea, level, hash_key, customer_name);
                }}
              >
                {t('Reassign')}
              </Link>
            ) : undefined;

          return (
            <div key={`swap-${roleArea}`} style={{ display: 'flex', marginTop: '0.5rem', marginRight: '0.5rem' }}>
              {linkOrEmpty}
              {/* Trick to have same height with the other columns */}
              <Checkbox
                disableFocusRipple
                disableRipple
                disableTouchRipple
                style={{ padding: 0, visibility: 'hidden' }}
              />
            </div>
          );
        })}
      </div>
    );
  }
  return <div />;
}

interface TooltipedCheckBoxParams {
  handler: any;
  value: boolean;
  alreadySelectedExternal: Contact | undefined;
  level: string;
  disabledDueToMichelinOffer: boolean;
  disableDueToPrimaryUnassigned: boolean;
  disableDuetoSecondaryDependencies: boolean;
  dynamicToolTipMsg?: string | undefined;
  t: Function;
}

export function tooltipedCheckbox(tooltipedCheckBoxParams: TooltipedCheckBoxParams) {
  const {
    handler,
    value,
    alreadySelectedExternal,
    level,
    disabledDueToMichelinOffer,
    disableDueToPrimaryUnassigned,
    disableDuetoSecondaryDependencies,
    dynamicToolTipMsg,
    t,
  } = tooltipedCheckBoxParams;

  const spanStyle =
    level === PRIMARY_LEVEL_VALUE ? { marginRight: '0.5rem' } : { marginLeft: '1rem', marginRight: '0.5rem' };

  if (disabledDueToMichelinOffer && value) {
    return (
      <div>
        <span style={spanStyle}>{t(capitalize(level))}</span>
        <CustomTooltip
          interactive
          arrow
          title={
            <p>
              {t('This is a Michelin offer contact type which is currently assigned to')}
              {dynamicToolTipMsg} .
            </p>
          }
        >
          <span>
            <Checkbox
              onChange={handler}
              disabled={disabledDueToMichelinOffer}
              checked={value}
              disableFocusRipple
              disableRipple
              disableTouchRipple
              style={{ padding: 0 }}
              color="primary"
            />
          </span>
        </CustomTooltip>
      </div>
    );
  }
  if (disableDuetoSecondaryDependencies) {
    return (
      <div>
        <span style={spanStyle}>{t(capitalize(level))}</span>
        <CustomTooltip
          interactive
          arrow
          title={<p>{t("There are secondary levels assigned in others location's contacts.")}</p>}
        >
          <span>
            <Checkbox
              onChange={handler}
              disabled={disableDuetoSecondaryDependencies}
              checked={value}
              disableFocusRipple
              disableRipple
              disableTouchRipple
              style={{ padding: 0 }}
              color="primary"
            />
          </span>
        </CustomTooltip>
      </div>
    );
  }
  if (disableDueToPrimaryUnassigned) {
    return (
      <div>
        <span style={spanStyle}>{t(capitalize(level))}</span>
        <CustomTooltip
          interactive
          arrow
          title={
            <p>
              {t("A primary level should be assigned in the location's contacts before assigning a secondary level.")}
            </p>
          }
        >
          <span>
            <Checkbox
              onChange={handler}
              disabled={disableDueToPrimaryUnassigned}
              checked={value}
              disableFocusRipple
              disableRipple
              disableTouchRipple
              style={{ padding: 0 }}
              color="primary"
            />
          </span>
        </CustomTooltip>
      </div>
    );
  }
  if (typeof alreadySelectedExternal !== 'undefined' && value) {
    const { first_name, last_name } = alreadySelectedExternal;
    return (
      <div>
        <CustomTooltip
          interactive
          arrow
          title={<p>{t('Already selected in contact: {{firstName}} {{lastName}}', { first_name, last_name })}</p>}
        >
          <span>
            <span style={spanStyle}>{t(capitalize(level))}</span>
            <Checkbox
              onChange={handler}
              disabled={disabledDueToMichelinOffer}
              checked={value}
              disableFocusRipple
              disableRipple
              disableTouchRipple
              style={{ padding: 0 }}
              color="secondary"
            />
          </span>
        </CustomTooltip>
      </div>
    );
  }
  return (
    <div>
      <span style={spanStyle}>{t(capitalize(level))}</span>
      <Checkbox
        onChange={handler}
        disabled={disabledDueToMichelinOffer}
        checked={value}
        disableFocusRipple
        disableRipple
        disableTouchRipple
        style={{ padding: 0 }}
        color="primary"
      />
    </div>
  );
}

export const CustomTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(13),
  },
}))(Tooltip);

export function priorityLevelRender(
  contactName: string,
  row: Row,
  contactTypePerLocation: Map<string, any>,
  priorityLevelsSelected: Set<string>,
  onPriorityLevelChanged: (roleArea: string, location_hash_key: string, level: string, checked: boolean) => void,
  mappedExternalContacts: Map<string, Contact>,
  externalContactsWithRoleAreaReassigned: Map<string, ReassignSelection>,
  t: Function,
): JSX.Element {
  const { location } = row;
  const { hash_key } = location;

  const contactTypesInLocation = contactTypePerLocation.get(location.hash_key.toString());
  if (contactTypesInLocation) {
    return (
      <div style={{ width: 'fit-content' }}>
        {contactTypesInLocation.map((contactType: { level: string; roleArea: string }) => {
          const { level, roleArea } = contactType;
          const disabledDueToMichelinOffer = isRoleAreaServiceLevelMichelinOffer(roleArea, TIRE_SERVICE_VALUE, level);
          const primaryValue = priorityLevelsSelected.has(`${roleArea + hash_key}${PRIMARY_LEVEL_VALUE}`);
          const secondaryValue = priorityLevelsSelected.has(`${roleArea + hash_key}${SECONDARY_LEVEL_VALUE}`);
          const primaryAlreadySelectedInExternal = mappedExternalContacts.get(
            `${roleArea + hash_key}${PRIMARY_LEVEL_VALUE}`,
          );
          const primaryAlreadySelectedInExternalReassign = externalContactsWithRoleAreaReassigned.get(
            `${roleArea + hash_key}${PRIMARY_LEVEL_VALUE}`,
          );
          const secondaryAlreadySelectedInExternal = mappedExternalContacts.get(
            `${roleArea + hash_key}${SECONDARY_LEVEL_VALUE}`,
          );
          const secondaryAlreadySelectedInExternalReassign = externalContactsWithRoleAreaReassigned.get(
            `${roleArea + hash_key}${SECONDARY_LEVEL_VALUE}`,
          );

          const primaryTooltipedParams = {
            handler: (e: any) => {
              onPriorityLevelChanged(roleArea, hash_key, PRIMARY_LEVEL_VALUE, e.target.checked);
            },
            value: primaryValue,
            alreadySelectedExternal:
              primaryAlreadySelectedInExternal ||
              (primaryAlreadySelectedInExternalReassign && primaryAlreadySelectedInExternalReassign.contact),
            level: PRIMARY_LEVEL_VALUE,
            disabledDueToMichelinOffer,
            disableDueToPrimaryUnassigned: false,
            disableDuetoSecondaryDependencies:
              primaryValue &&
              (typeof secondaryAlreadySelectedInExternal !== 'undefined' ||
                typeof secondaryAlreadySelectedInExternalReassign !== 'undefined'),
            dynamicToolTipMsg: contactName,
            t,
          };

          const secondaryTooltipedParams = {
            handler: (e: any) => {
              onPriorityLevelChanged(roleArea, hash_key, SECONDARY_LEVEL_VALUE, e.target.checked);
            },
            value: secondaryValue,
            alreadySelectedExternal:
              secondaryAlreadySelectedInExternal ||
              (secondaryAlreadySelectedInExternalReassign && secondaryAlreadySelectedInExternalReassign.contact),
            level: SECONDARY_LEVEL_VALUE,
            disabledDueToMichelinOffer,
            disableDueToPrimaryUnassigned:
              primaryValue || (!primaryAlreadySelectedInExternal && !primaryAlreadySelectedInExternalReassign),
            disableDuetoSecondaryDependencies: false,
            dynamicToolTipMsg: contactName,
            t,
          };

          return (
            <div
              key={`tooltiped-checkbox-${roleArea}`}
              style={{ display: 'flex', marginTop: '0.5rem', marginRight: '0.5rem' }}
            >
              {tooltipedCheckbox(primaryTooltipedParams)}
              {tooltipedCheckbox(secondaryTooltipedParams)}
            </div>
          );
        })}
      </div>
    );
  }
  return <div />;
}

export function reassignPriorityLevelRender(
  row: Row,
  selectedToReassign: Set<string>,
  handleCheck: (checked: boolean, role_area: string, location: string, level: string) => void,
): JSX.Element {
  const { contactType, location } = row;
  const { role_areas } = contactType;
  const checkboxes: Array<JSX.Element> = [];

  if (!role_areas) return <></>;

  role_areas.forEach((contactTypeRoleArea) => {
    const { role_area, levels } = contactTypeRoleArea;
    let level = GENERAL_LEVEL_VALUE;
    levels.some((x) => {
      if (x.location === location.hash_key) {
        level = x.level;
        return true;
      }
      return false;
    });
    const isMichelinOffer = isRoleAreaServiceLevelMichelinOffer(role_area, TIRE_SERVICE_VALUE, level);
    checkboxes.push(
      <div style={{ width: 'fit-content', margin: 'auto', marginTop: '0.5rem' }}>
        <Checkbox
          disableFocusRipple
          disableRipple
          disableTouchRipple
          style={{ padding: 0 }}
          color="primary"
          disabled={isMichelinOffer}
          checked={selectedToReassign.has(role_area + location.hash_key.toString())}
          onChange={(event) => {
            const { checked } = event.target;
            handleCheck(checked, role_area, location.hash_key.toString(), level);
          }}
        />
      </div>,
    );
  });

  return <>{checkboxes}</>;
}

export function nully(str: string) {
  if (!str) return true;
  return str === 'null';
}

export function renderAddress(cf: Customer): JSX.Element {
  return (
    <>
      {!nully(cf.customer_addr1) ? (
        <>
          {cf.customer_addr1}
          <br />
        </>
      ) : undefined}
      {!nully(cf.customer_addr2) ? (
        <>
          {cf.customer_addr2}
          <br />
        </>
      ) : undefined}
      {`${cf.customer_city}, ${cf.customer_state} ${cf.customer_zip}`}
    </>
  );
}

export function renderAccountInfo(cf: Customer): JSX.Element {
  const accounts: string[] = [];
  if (!nully(cf.parent_company_number)) accounts.push(`PC ${cf.parent_company_number}`);
  if (!nully(cf.home_office_number)) accounts.push(`HO ${cf.home_office_number}`);
  if (!nully(cf.bill_to_customer)) accounts.push(`BT ${cf.bill_to_customer}`);
  if (!nully(cf.ship_to_customer)) accounts.push(`ST ${cf.ship_to_customer}`);

  return (
    <ul style={{ padding: 0, fontSize: '0.9em', marginLeft: '1rem' }}>
      {accounts.map((s: string, i: number) => {
        if (i < accounts.length - 1) return <li key={s}>{s}</li>;
        return (
          <li key={s}>
            <b>{s}</b>
            <Star style={{ height: 12 }} />
          </li>
        );
      })}
    </ul>
  );
}

export function accountInfoRender(row: Row): JSX.Element {
  const { location } = row;
  return renderAccountInfo(location);
}

export function AccountLocationRender({ location, clickable }: { location: Customer; clickable?: boolean }) {
  const { t } = useTranslation();
  const history = useHistory();

  const handleLocationDetailsClick = (pathname: string, label: string) => {
    history.push(`${getBaseUrl()}${pathname}`);
  };

  return (
    <>
      <span>
        {clickable ? (
          <Link
            onClick={() =>
              handleLocationDetailsClick(
                `/locationDetails/1~${location.customer_number}/general-info/view`,
                location.customer_name,
              )
            }
            style={{ cursor: 'pointer', fontWeight: 'bold' }}
          >
            {location.customer_name}
          </Link>
        ) : (
          <b>{location.customer_name}</b>
        )}
        <br />
      </span>
      <span>
        {t('Michelin Id')}
        {': '}
        {location.customer_number ? location.customer_number.replace('null', ' ') : 'N/A'}
        <br />
      </span>
    </>
  );
}
