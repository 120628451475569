import gql from 'graphql-tag';

const mainType = `
main {
  application_type
  default
  driver_can_override
  profile_name
  off_tire_disposition
  off_tire_disposition_reason
  single_call_indicator
  special_instructions
  profile_type
  vehicle_type
  vehicle_type_vmrs_code
  vehicle_attrs_tractor
  vehicle_attrs_trailer
  assign_to_all_locations
}
`;

export const typeOwner = `
  owner {
    customer_name
    customer_type
    customer_number
    bill_to_customer
    cust_bus_code
    customer_addr1
    customer_addr2
    customer_city
    customer_country
    customer_state
    customer_zip
    extrnl_cust_id
    parent_company_number
    home_office_number
    ship_to_customer
  }
`;

const tireSpecificationsType = `
tire_service_specifications {
  first_choice
  second_choice
  third_choice
}
`;

const tirePressureRegulatorType = `
tire_pressure_regulator {
  driver_has_special_tools
  pressure_regulation_device_installed
  should_device_be_reinstalled
  special_tools_required
}
`;

const tirePressureSensorType = `
pressure_sensor {
  disposition
  installed
  location
  mounting
  types
}
`;

const passLTPriorityType = `
passenger_lt_priority {
  priority
  brand
  tire_size
  tread_design
  speed_rating
  mspn
  note
}
`;

const tireReplacementPriorityType = `
tire_replacement_priority {
  priority
  brand
  load_range
  mspn
  note
  original_tire_depth
  tire_size
  tire_type
  tread_design
}
`;

const axlesType = `
axles {
  steer {
    ${tireSpecificationsType}
    ${tirePressureRegulatorType}
    ${tirePressureSensorType}
    ${tireReplacementPriorityType}
    activity_if_pref_unavailable
  }
  drive {
    ${tireSpecificationsType}
    ${tirePressureRegulatorType}
    ${tirePressureSensorType}
    ${tireReplacementPriorityType}
    activity_if_pref_unavailable
  }
  trailer {
    ${tireSpecificationsType}
    ${tirePressureRegulatorType}
    ${tirePressureSensorType}
    ${tireReplacementPriorityType}
    activity_if_pref_unavailable
  }
  tag {
    ${tireSpecificationsType}
    ${tirePressureRegulatorType}
    ${tirePressureSensorType}
    ${tireReplacementPriorityType}
    activity_if_pref_unavailable
  }
  lift {
    ${tireSpecificationsType}
    ${tirePressureRegulatorType}
    ${tirePressureSensorType}
    ${tireReplacementPriorityType}
    activity_if_pref_unavailable
  }
  carry {
    ${tireSpecificationsType}
    ${tirePressureRegulatorType}
    ${tirePressureSensorType}
    ${tireReplacementPriorityType}
    activity_if_pref_unavailable
  }
  pusher {
    ${tireSpecificationsType}
    ${tirePressureRegulatorType}
    ${tirePressureSensorType}
    ${tireReplacementPriorityType}
    activity_if_pref_unavailable
  }
  all_axles {
    ${tireSpecificationsType}
    ${tirePressureRegulatorType}
    ${tirePressureSensorType}
    ${tireReplacementPriorityType}
    ${passLTPriorityType}
    activity_if_pref_unavailable
  }
}
`;

const queryTireProfileDetail = gql`
  query TireProfileDetails($hash_key: String!) {
    getTireProfileDetail(hash_key: $hash_key, range_key: "v0_tire_profile") {
      is_deleted
      hash_key
      range_key
      gsi1_hash_key
      gsi1_range_key
      locations {
        hash_key
        range_key
        gsi1_hash_key
        gsi1_range_key
      }
      ${typeOwner}
      ${mainType}
      ${axlesType}
      changes,
      update_date,
      update_user,
      last_child_update_date,
    }
  }
`;

const queryGetTireProfileUniqueness = gql`
  query GetTireProfileUniqueness($hash_key: String, $tire_profile: AWSJSON, $accounts_to_check: [String]) {
    getTireProfileUniqueness(hash_key: $hash_key, tire_profile: $tire_profile, accounts_to_check: $accounts_to_check) {
      customers
    }
  }
`;

export { queryTireProfileDetail, queryGetTireProfileUniqueness };
