import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { LoadingBounce, Panel, PreventTransitionPrompt, useDialogs } from '@michelin/acid-components';
import { usePermissions, useTranslation } from '@michelin/central-provider';
import { BillingPreferences } from 'components/Billing/Preferences/query';
import { PlainMessage } from 'components/Messages';
import { CONTENT_AREA_HEIGHT } from 'components/Util';
import { useGetMockBillingProfile } from 'hooks/useBillingProfile';
import { getBaseUrl } from 'prefs-and-service-offers';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { BillingFabs } from './Components/BillingFabs';
import { HeaderBillingProfileDetail } from './Components/HeaderBillingProfileDetail';
import { ProfileParams, ServiceParams } from './Components/SpeedDialActions';
import { GeneralInformation } from './Panels/GeneralInformation';
import { RequestedTiresDetail } from './Panels/RequestedTiresDetail';
import { RequestedWheelsDetail } from './Panels/RequestedWheelsDetail';
import { BillingProfileLocation } from './types';


type IProps = {
  action: string;
};

interface ViewCreateParams {
  selectedAccount: string;
  profileId?: string;
  service?: ServiceParams;
  profile?: ProfileParams;
}

export interface BillingProfileContextData {
  profile: BillingPreferences;
  locations: BillingProfileLocation[];
  contacts: any[];
  permission: { updatePermission: boolean; readPermission: boolean; updateByHierarchyEnabled: boolean };
}

const BillingProfileDetailPage = (props: IProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { allowsAction, location } = usePermissions();
  const params = useParams<ViewCreateParams>();
  if (props.action === 'create' && params?.profile && params?.service) {
    if (
      !Object.values(ServiceParams).includes(params?.service) ||
      !Object.values(ProfileParams).includes(params?.profile)
    ) {
      history.push(`${getBaseUrl()}/billings`);
    }
  }
  const billingProfileQuery = useGetMockBillingProfile({
    id: params?.profileId ?? '',
    action: props.action,
    service: params.service ?? '',
    profile: params.profile ?? '',
    location: location,
  });

  const [savingFlag, setSavingFlag] = useState(false);
  const { errorDialog } = useDialogs();
  const methods = useForm<BillingProfileContextData>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      profile: {} as BillingPreferences,
      locations: [] as BillingProfileLocation[],
      contacts: [],
      permission: {
        updatePermission: allowsAction('billing.update'),
        readPermission: allowsAction('billing.read'),
        updateByHierarchyEnabled: true, // CHECK THIS VALUE, FROM BE??
      },
    } as BillingProfileContextData,
  });
  const isDirty = Object.keys(methods.formState.dirtyFields).length > 0;

  useEffect(() => {
    if (billingProfileQuery.data) {
      methods.reset({
        ...methods.getValues(),
        profile: { ...billingProfileQuery.data.profile },
        locations: [...billingProfileQuery.data.locations],
        contacts: [...billingProfileQuery.data.contacts],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingProfileQuery.data]);

  const discard = () => {
    methods.reset();
    // billingProfileQuery.remove();
  };
  const save = (data: BillingProfileContextData) => {
    setSavingFlag(true);
    console.log('saving', methods.getValues());
    methods.reset({ ...methods.getValues() });
    setTimeout(() => {
      setSavingFlag(false);
    }, 2000);
    if (props.action === 'edit' && params.profileId) {
      const path = `/billing-future/${params.profileId}/view`;
      history.push(`${getBaseUrl()}${path}`);
    }
    if (props.action === 'create') {
      const path = `/billings/`;
      history.push(`${getBaseUrl()}${path}`);
    }
  };
  const onError = (errors: any) => {
    const msg = [];
    if (errors.profile?.general_information) {
      Object.keys(errors.profile.general_information).forEach((err) => {
        msg.push(`• ${t(errors['profile']['general_information'][err]['message'])}`);
      });
    }
    if (errors.profile?.tire_details) {
      msg.push(`• ${t('Requested Details is required for Requested Off/Installed Tire Details')}`);
    }
    if (errors.profile?.wheel_details) {
      msg.push(`• ${t('Requested Details is required for Requested Off/Installed Wheel Details')}`);
    }
    if (msg.length) {
      errorDialog(msg, t('Missing required fields'), t('Ok'));
    } else {
      errorDialog('Please check the required fields', t('Missing required fields'), t('Ok'));
    }
  };

  if (billingProfileQuery.isLoading || !billingProfileQuery?.data)
    return (
      <>
        <HeaderBillingProfileDetail subtitle="Loading..." action={props.action} profileId={`${params?.profileId}`} />
        <LoadingBounce style={{ height: CONTENT_AREA_HEIGHT }} />
      </>
    );

  if (billingProfileQuery?.isError) {
    return (
      <Panel variant="none" style={{ border: 0 }}>
        <Grid container>
          <Grid item xs={12} spacing={2}>
            <PlainMessage
              title={t('Database Error')}
              messages={[
                t('Application could not load wheel profile data.'),
                t('Please try again later or contact support if the error persists'),
              ]}
            />
          </Grid>
        </Grid>
      </Panel>
    );
  }

  console.log('detailsPage', methods.getValues().profile);
  return (
    <FormProvider {...methods}>
      <HeaderBillingProfileDetail
        subtitle={props.action === 'create' ? '' : methods.getValues().profile?.general_information?.profile_name || ''}
        action={props.action}
        profileId={`${params?.profileId}`}
      />
      <PreventTransitionPrompt when={isDirty} handleDiscard={discard} />

      <Panel spacing={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <GeneralInformation action={props.action} />
          </Grid>
          <Grid item xs={12}>
            <RequestedTiresDetail action={props.action} />
          </Grid>
          <Grid item xs={12}>
            <RequestedWheelsDetail action={props.action} />
          </Grid>
        </Grid>
        <BillingFabs
          editFlag={props.action === 'edit' || props.action === 'create'}
          handleSaveClick={() => methods.handleSubmit(save, onError)()}
          modifiedFlag={isDirty}
          profileId={params?.profileId || ''}
          savingFlag={savingFlag}
          action={props.action}
        />
      </Panel>
    </FormProvider>
  );
};

export { BillingProfileDetailPage };