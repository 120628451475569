import React from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { useDialogs } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { VehicleType } from '@michelin/core-profile-ui-tools/build/types';
import { useSnackbar } from 'notistack';
import { getBaseUrl } from 'prefs-and-service-offers';
import { useHistory } from 'react-router-dom';
import { activateVehicleAPICall } from '../Utils';

const useVehicleActions = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { errorDialog, confirmDialog } = useDialogs();
  const { enqueueSnackbar } = useSnackbar();

  const setActivate = () => async (data: VehicleType, reloadList?: Function) => {
    const locationId: string = data.st || data.bt || data.ho || data.pc;
    const response = await activateVehicleAPICall(true, locationId, String(data.id));

    if (!response || response.status !== 200) {
      errorDialog(
        [t(response.body.error) || t('Unhandled error')],
        t('Error while activating vehicle'),
        t('Ok'),
        undefined,
        {
          disableBackdropClick: true,
        },
      );
    } else if (response.body && response.body.error) {
      errorDialog(t(response.body.error), t('Error while activating vehicle'), t('Ok'), undefined, {
        disableBackdropClick: true,
      });
    } else {
      if (reloadList) reloadList();
      history.push(`${getBaseUrl()}/urban-vehicles#active=true`);
      enqueueSnackbar(t('Successfully activated vehicle !'), { variant: 'success' });
    }
  };

  const setDeactivate = () => async (data: VehicleType) => {
    const deletionReasonItems = [
      { id: 'sold_general_public', value: 'Vehicle Sold to general public' },
      { id: 'sold_another_fleet', value: 'Vehicle Sold to another fleet' },
      { id: 'damaged', value: 'Vehicle Damaged/Lost' },
      { id: 'other', value: 'Other' },
    ];
    const v = { current: 'other' };
    const locationId: string = data.st || data.bt || data.ho || data.pc;
    confirmDialog(
      [
        t('Are you sure you want to delete the vehicle {{vehicleUnitNumber}}?', {
          vehicleUnitNumber: data.fleet_id_no,
        }),
        <>
          <br />
          <RadioGroup
            aria-label="deletion-reason"
            name="deletion-reason"
            defaultValue={v.current}
            onChange={(e) => {
              v.current = e.target.value;
            }}
          >
            {deletionReasonItems.map((item) => (
              <FormControlLabel
                key={item.id}
                value={item.id}
                control={<Radio color="primary" value={item.id} />}
                label={t(item.value)}
              />
            ))}
          </RadioGroup>
        </>,
      ],
      t('Deactivate vehicle'),
      t('Deactivate'),
      t('Cancel'),
      async () => {
        const response = await activateVehicleAPICall(false, locationId, String(data.id), v.current);
        if (!response || response.status !== 200) {
          errorDialog([t(response.body) || t('Unhandled error')], t('Error while deactivating vehicle'), t('Ok'));
        } else if (response.body && response.body.error) {
          errorDialog(t(response.body.error), t('Error while deactivating vehicle'), t('Ok'));
        } else {
          history.push(`${getBaseUrl()}/urban-vehicles/${data.id}/view`);
          enqueueSnackbar(t('Successfully archived vehicle !'), { variant: 'success' });
        }
      },
      async () => {},
      {
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        hideCloseIcon: true,
      },
    );
  };

  const setRemove = () => async (data: VehicleType, reloadList: Function) => {
    const locationId: string = data.st || data.bt || data.ho || data.pc;
    confirmDialog(
      t('Are you sure you want to delete the vehicle {{vehicleUnitNumber}}?', { vehicleUnitNumber: data.fleet_id_no }),
      t('Delete vehicle'),
      t('Yes'),
      t('No'),
      async () => {
        const response = await activateVehicleAPICall(false, locationId, String(data.id), 'other');
        if (!response || response.status !== 200) {
          errorDialog([t(response.body) || t('Unhandled error')], t('Error while deleting vehicle'), t('Ok'));
        } else if (response.body && response.body.error) {
          errorDialog(t(response.body.error), t('Error while deleting vehicle'), t('Ok'));
        } else {
          reloadList();
          enqueueSnackbar(t('Successfully deleted vehicle !'), { variant: 'success' });
        }
      },
      () => {},
      {
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
        hideCloseIcon: true,
      },
    );
  };

  return {
    activate: setActivate(),
    deactivate: setDeactivate(),
    remove: setRemove(),
  };
};

export default useVehicleActions;
