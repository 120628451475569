import React, { useEffect } from 'react';
import {
  AccountAvatar,
  IPageTitleProps,
  LoadingBounce,
  PageTitle as RegularPageTitle,
} from '@michelin/acid-components';
import { Location, usePermissions } from '@michelin/central-provider';
import { runningOnCanvas } from '@michelin/central-provider/dist/utils/canvas';
import { CustomerData } from 'AuthUtils';
import { setPageTitle } from 'utils';
import { useGetLocationByNumber } from 'utils/useGetLocationByNumber';

type CTypes = 'ST' | 'BT' | 'HO' | 'PC';

function SimpleLocationAvatar({
  location,
  avatarOnly,
  level,
}: {
  location: any;
  avatarOnly?: boolean;
  level: CTypes | null | undefined;
}) {
  const [avatarOnlyState, setAvatarOnlyState] = React.useState(avatarOnly);
  useEffect(() => {
    setAvatarOnlyState(avatarOnly);
  }, [avatarOnly]);
  if (!location) {
    return (
      <div
        style={{
          borderRight: '1px solid #e0e0e0',
          paddingRight: 8,
          marginRight: 8,
          float: 'left',
        }}
      >
        {level && <AccountAvatar avatarOnly accountType={level} name="" />}
        {!avatarOnlyState && <LoadingBounce />}
      </div>
    );
  }

  return (
    <div
      style={{
        borderRight: '1px solid #e0e0e0',
        paddingRight: avatarOnlyState ? 8 : 16,
        marginRight: 8,
        float: 'left',
      }}
    >
      <AccountAvatar
        style={{ cursor: 'pointer', float: 'left', width: 'auto' }}
        onClick={() => setAvatarOnlyState(!avatarOnlyState)}
        avatarOnly
        accountType={location?.customer_type as any}
        name={location?.customer_dba_name || location?.customer_name || ''}
        accountNumbers={{
          shipTo: (location?.customer_type === 'ST' && location?.ship_to_customer) || undefined,
          billTo: (location?.customer_type === 'BT' && location?.bill_to_customer) || undefined,
          parentCompany: (location?.customer_type === 'PC' && location?.parent_company_number) || undefined,
          homeOffice: (location?.customer_type === 'HO' && location?.home_office_number) || undefined,
          mdm: location?.mdm?.site_id || undefined,
        }}
        addressDisplay="cityState"
        address={{
          address1: location?.customer_addr1,
          address2: location?.customer_addr2,
          city: location?.customer_city,
          state: location?.customer_state,
          zip: location?.customer_zip,
        }}
      />
      {!avatarOnlyState && (
        <AccountAvatar
          style={{ float: 'left', width: 'auto', marginLeft: 8, marginTop: 2 }}
          avatarPosition="none"
          accountType={location?.customer_type as any}
          name={location?.customer_dba_name || location?.customer_name || ''}
          accountNumbers={{
            shipTo: (location?.customer_type === 'ST' && location?.ship_to_customer) || undefined,
            billTo: (location?.customer_type === 'BT' && location?.bill_to_customer) || undefined,
            parentCompany: (location?.customer_type === 'PC' && location?.parent_company_number) || undefined,
            homeOffice: (location?.customer_type === 'HO' && location?.home_office_number) || undefined,
            mdm: location?.mdm?.site_id || undefined,
          }}
          addressDisplay="cityState"
          address={{
            address1: location?.customer_addr1,
            address2: location?.customer_addr2,
            city: location?.customer_city,
            state: location?.customer_state,
            zip: location?.customer_zip,
          }}
        />
      )}
    </div>
  );
}

function type2number(type: CTypes | null | undefined) {
  if (type === 'ST') return 0;
  if (type === 'BT') return 1;
  if (type === 'HO') return 2;
  if (type === 'PC') return 3;
  return -1;
}

interface WorkingLocations {
  level: CTypes | null | undefined;
  location: Location | CustomerData | null | undefined;
}
interface PageTitleProps extends IPageTitleProps {
  title: string | null | undefined;
  subtitle?: string | null;
  toLocation?: string;
}

function PageTitle(props: PageTitleProps) {
  const permissions = usePermissions();
  const { title, subtitle, toLocation, ...otherProps } = props;
  setPageTitle(`${title || ''}${props.subtitle ? ` - ${props.subtitle}` : ''}`);

  const finalLocation = useGetLocationByNumber(toLocation);
  const stLocation = useGetLocationByNumber(
    permissions?.location?.ship_to_customer || finalLocation.data?.ship_to_customer || undefined,
  );
  const btLocation = useGetLocationByNumber(
    permissions?.location?.bill_to_customer || finalLocation.data?.bill_to_customer || undefined,
  );
  const hoLocation = useGetLocationByNumber(
    permissions?.location?.home_office_number || finalLocation.data?.home_office_number || undefined,
  );
  const pcLocation = useGetLocationByNumber(
    permissions?.location?.parent_company_number || finalLocation.data?.parent_company_number || undefined,
  );

  const selectedLocations: WorkingLocations[] = [
    {
      level: permissions.location?.customer_type as CTypes,
      location: permissions.location,
    },
  ];
  const locations: WorkingLocations[] = [
    { location: stLocation.data, level: 'ST' },
    { location: btLocation.data, level: 'BT' },
    { location: hoLocation.data, level: 'HO' },
    { location: pcLocation.data, level: 'PC' },
  ];
  let fromLocationType = type2number(permissions.location?.customer_type as CTypes);
  const toLocationType = type2number(finalLocation.data?.customer_type as CTypes);

  if (toLocation && !finalLocation.isLoading && fromLocationType !== toLocationType) {
    fromLocationType += fromLocationType < toLocationType ? 1 : -1;
    while (fromLocationType !== toLocationType) {
      selectedLocations.push(locations[fromLocationType]);
      fromLocationType += fromLocationType < toLocationType ? 1 : -1;
    }
    selectedLocations.push(locations[fromLocationType]);
  }

  return (
    <div style={{ borderTop: runningOnCanvas() ? '1px solid rgba(0, 0, 0, 0.12)' : undefined }}>
      <RegularPageTitle
        title={
          <>
            {selectedLocations.map(({ location, level }, index) => (
              <SimpleLocationAvatar
                key={`${location?.hash_key}${index}`}
                location={location}
                level={level}
                avatarOnly={index !== selectedLocations.length - 1}
              />
            ))}
            <div style={{ float: 'left', marginRight: props.rightItems ? 140 : undefined, marginTop: 3 }}>
              {title}
              {subtitle && <span style={{ color: '#AAA' }}> {subtitle}</span>}
            </div>
          </>
        }
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
      />
    </div>
  );
}

export { PageTitle };
