import React from 'react';
import { Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Button,
  LoadingBounce,
  Panel,
  PreventTransitionPrompt,
  Switch,
  TextField,
  useDialogs,
} from '@michelin/acid-components';
import { Select } from '@michelin/acid-components';
import { Location, usePermissions, useTranslation } from '@michelin/central-provider';
import { getOptionLabel, getOptions } from '@michelin/select-options-provider';
import { NewLocationAssignments } from 'components/NewLocationAssignments';
import { useGetAccount } from 'hooks/useGetAccount';
import { useUpdateAccount } from 'hooks/useUpdateAccount';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { PlainMessage } from '../../components/Messages';
import { scrollToDisplayFirstError } from '../../components/Util';
import { getBaseUrl } from '../../prefs-and-service-offers';
import { AccountRequest, LdNaLocationRelationship, LocalDealerAndNationAccount } from './types';

export function ModalDialog({ title, action, children }: { title: string; action: string; children: React.ReactNode }) {
  const { t } = useTranslation();
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      aria-labelledby="customized-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      open
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent id="dialog-content" dividers style={{ padding: 0 }}>
        {children}
      </DialogContent>
      <DialogActions>
        <Button size="small" color="success" style={{ margin: '8px' }} disabled>
          {action === 'edit' ? t('Update') : t('Add')}
        </Button>
        <Button size="small" color="default" style={{ margin: '8px' }} disabled={false}>
          {t('Cancel')}
        </Button>
      </DialogActions>
      <PreventTransitionPrompt when={true} />
    </Dialog>
  );
}

export const updateLocationAssignments = (locs: Location[], account_hash_key: string): LdNaLocationRelationship[] => {
  const relationshipsResult: LdNaLocationRelationship[] = [];

  locs.forEach((location) => {
    const item = {} as LdNaLocationRelationship;
    item.hash_key = account_hash_key;
    item.range_key = location.hash_key;
    item.gsi1_hash_key = location.hash_key;
    item.gsi1_range_key = account_hash_key;
    item.is_deleted = false;
    relationshipsResult.push(item);
  });

  return relationshipsResult;
};

interface ContentProps {
  data: LocalDealerAndNationAccount | null;
  action: 'edit' | 'add';
  onSave: Function;
  onClose: Function;
  selectedAccount: string;
  accountId?: string;
}

const Content = ({ data, action, onSave, onClose, selectedAccount, accountId }: ContentProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { confirmDialog, errorDialog } = useDialogs();
  const { location, allowsAction } = usePermissions();
  const updatePermission = allowsAction('accounts.update');
  const createPermission = allowsAction('accounts.create');
  const { control, handleSubmit, getValues, setValue, formState, reset } = useForm<LocalDealerAndNationAccount>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: data || {
      set_all_locations: location?.customer_type === 'ST',
      locations: [],
    },
  });
  const updateAccountMutation = useUpdateAccount({
    selectedAccount: selectedAccount || '',
    hashKey: accountId || '',
    action,
  });
  const isDirty = Object.keys(formState.dirtyFields).length > 0;
  let account_types = getOptions('ld_na_account_types', 'choose', t('Choose'));
  if (action === 'edit' || getValues().account_type !== 'choose') account_types = getOptions('ld_na_account_types');
  const relationship = location?.relationship?.split('~');
  const allowLocationAssignments = location?.customer_type !== 'ST' && relationship?.pop() === 'null';

  const validateFields = (): boolean => {
    const msg: string[] = [];
    if (getValues().account_type === 'choose') msg.push(`• ${t('Account Type')}`);
    if (formState.errors.company) msg.push(`• ${t('Account Name')}`);
    if (formState.errors.ld_na_number) msg.push(`• ${t('Account Number')}`);
    if (!getValues().set_all_locations && !getValues().locations.length) msg.push(`• ${t('Locations assignments')}`);
    if (msg.length) {
      msg.unshift(t('The following fields are required:'), ' ');
      errorDialog(msg, t('Missing required fields'), t('Ok'));
      scrollToDisplayFirstError();
      return false;
    }
    return true;
  };

  const validateDuplicates = async (duplicates: string[], locs: (string | undefined)[]) => {
    const totalLocations = getValues().set_all_locations ? 1 : getValues().locations.length;
    const locsMatched = duplicates.length;
    const locsNotMatched = totalLocations - locsMatched;
    const msg: string[] = [];

    msg.push(
      t(
        'A duplicate Dealer or National Account already exists at {{locsMatched}} out of {{totalLocations}} which you selected for assignment.',
        { locsMatched, totalLocations },
      ),
    );
    msg.push(t('Only one account having both of the same field values are permitted at any one location.'));
    msg.push(t('The duplicated values in the account at those locations are:'));
    msg.push(' ');
    msg.push(
      t('Account Type = {{accountType}}', {
        accountType: getOptionLabel('ld_na_account_types', getValues().account_type),
      }),
    );
    msg.push(
      t('Company Name = {{companyName}}', {
        companyName: getValues().company,
      }),
    );

    if (locsNotMatched > 0) {
      msg.push(' ');
      msg.push(
        t(
          'You may continue with assigning to the {{locsNotMatched}} ' +
            'locations where there was no exact match or return to the page ' +
            'to update one or more of the fields shown above.',
          { locsNotMatched },
        ),
      );
      confirmDialog(
        msg,
        t('Duplicates found'),
        t('Save & Assign to {{locsNotMatched}} locations'),
        t('Cancel & Return to Page'),
        async () => {
          const data: AccountRequest = {
            locations: getValues().set_all_locations ? [] : (locs as string[]),
            set_all_locations: getValues().set_all_locations,
            add_no_duplicated: true,
            account: {
              company: getValues().company,
              account_type: getValues().account_type,
              ld_na_number: getValues().ld_na_number,
            },
          };
          try {
            await updateAccountMutation.mutateAsync({
              selectedAccount: selectedAccount || '',
              hashKey: accountId || '',
              account: data,
              action,
            });
          } catch (error) {
            enqueueSnackbar(t('Error saving account.'), {
              variant: 'error',
            });
          }
          await reset();
          history.push(`${getBaseUrl()}/accounts`);
          onClose();
        },
        undefined,
      );
    } else {
      errorDialog(msg, t('Duplicates found'), t('Ok'), undefined);
    }
  };

  const saveClick = async () => {
    if (!getValues || !isDirty) {
      return;
    }
    let validation = validateFields();
    if (!validation) {
      return;
    }
    const locs = getValues().locations?.map((rel: LdNaLocationRelationship) => rel.range_key.split('~').pop()) || [];
    const data: AccountRequest = {
      locations: getValues().set_all_locations ? [] : (locs as string[]),
      set_all_locations: getValues().set_all_locations,
      account: {
        company: getValues().company,
        account_type: getValues().account_type,
        ld_na_number: getValues().ld_na_number,
      },
    };
    try {
      const response = await updateAccountMutation.mutateAsync({
        selectedAccount: selectedAccount || '',
        hashKey: accountId || '',
        account: data,
        action,
      });
      if (!response) {
        enqueueSnackbar(t('Error saving account.'), {
          variant: 'error',
        });
        return;
      }
      if (!response.saved && response.duplicates && response.duplicates.length) {
        await validateDuplicates(response.duplicates, locs);
      }
      if (!response.saved) {
        enqueueSnackbar(t('Error saving account.'), {
          variant: 'error',
        });
        return;
      }
      enqueueSnackbar(t('Account saved successfully.'), {
        variant: 'success',
      });
      await onSave();
    } catch (error) {
      enqueueSnackbar(t('Error saving account.'), {
        variant: 'error',
      });
    }
    await reset();
    history.push(`${getBaseUrl()}/accounts`);
    onClose();
  };

  const cancelEdition = async (mode: 'ask' | 'stay' | 'discard') => {
    switch (mode) {
      case 'ask':
        confirmDialog(
          t('Are you sure you want to discard all the changes you have made?'),
          t('Discard Changes'),
          t('Yes'),
          t('No'),
          () => {
            cancelEdition('discard');
          },
          () => {},
        );
        break;
      case 'discard':
        await reset();
        history.push(`${getBaseUrl()}/accounts`);
        onClose();
        break;
      default:
        break;
    }
  };
  return (
    <>
      <DialogContent id="dialog-content" dividers style={{ padding: 0 }}>
        <Panel variant="none" style={{ border: 0 }} spacing={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                name="account_type"
                control={control}
                rules={{
                  validate: (value) => value !== 'choose',
                }}
                render={(item) => (
                  <Select
                    id={item.field.name}
                    label={t('Account Type')}
                    type="string"
                    variant="standard"
                    value={item.field.value || ''}
                    options={account_types}
                    onChange={(e) => item.field.onChange(e.target.value)}
                    fullWidth
                    required
                    disabled={updateAccountMutation.isLoading}
                    style={{ display: 'flex', flexWrap: 'wrap' }}
                    error={!!item.formState.errors.account_type}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="company"
                control={control}
                rules={{
                  required: true,
                }}
                render={(item) => (
                  <TextField
                    id={item.field.name}
                    label={t('Account Name')}
                    value={item.field.value || ''}
                    onChange={(e) => item.field.onChange(e.target.value)}
                    required
                    variant="standard"
                    style={{ display: 'flex', flexWrap: 'wrap' }}
                    disabled={updateAccountMutation.isLoading}
                    error={!!item.formState.errors.company}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="ld_na_number"
                control={control}
                rules={{
                  required: true,
                }}
                render={(item) => (
                  <TextField
                    id={item.field.name}
                    label={t('Account Number')}
                    value={item.field.value || ''}
                    onChange={(e) => item.field.onChange(e.target.value)}
                    required
                    variant="standard"
                    style={{ display: 'flex', flexWrap: 'wrap' }}
                    disabled={updateAccountMutation.isLoading}
                    error={!!item.formState.errors.ld_na_number}
                  />
                )}
              />
            </Grid>

            {!allowLocationAssignments ? null : (
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <Grid item xs={9}>
                    <Controller
                      name="set_all_locations"
                      control={control}
                      render={(item) => (
                        <Switch
                          id={item.field.name}
                          label={t('Assign to all locations')}
                          labelPosition="left"
                          disabled={updateAccountMutation.isLoading}
                          checked={item.field.value === true}
                          color="primary"
                          uncheckedLabel={t('no')}
                          checkedLabel={t('yes')}
                          onChange={(event, newValue) => item.field.onChange(newValue)}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Controller
                      name="locations"
                      control={control}
                      render={(item) => (
                        <NewLocationAssignments
                          owner={selectedAccount}
                          mode="edit"
                          all={getValues().set_all_locations}
                          locations={getValues().locations?.map((rel: any) => ({
                            hash_key: rel.range_key,
                          }))}
                          disabled={getValues().set_all_locations || updateAccountMutation.isLoading}
                          onUpdate={(locs: Location[]) => {
                            const { hash_key } = getValues();
                            const assigned: LdNaLocationRelationship[] = updateLocationAssignments(locs, hash_key);
                            setValue('locations', assigned, {
                              shouldDirty: true,
                            });
                          }}
                          style={{ margin: '0 0 0 auto' }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Panel>
      </DialogContent>
      <DialogActions>
        {updateAccountMutation.isLoading ? (
          <LoadingBounce />
        ) : (
          <>
            {action === 'edit' && updatePermission && (
              <Button
                size="small"
                color="success"
                style={{ margin: '8px' }}
                disabled={!isDirty || updateAccountMutation.isLoading}
                onClick={handleSubmit(saveClick)}
              >
                {t('Update')}
              </Button>
            )}
            {action === 'add' && createPermission && (
              <Button
                size="small"
                color="success"
                style={{ margin: '8px' }}
                disabled={!isDirty || updateAccountMutation.isLoading}
                onClick={handleSubmit(saveClick)}
              >
                {t('Add')}
              </Button>
            )}
            <Button
              size="small"
              color="default"
              style={{ margin: '8px' }}
              disabled={updateAccountMutation.isLoading}
              onClick={() => {
                if (!isDirty) cancelEdition('discard');
                else cancelEdition('ask');
              }}
            >
              {t('Cancel')}
            </Button>
          </>
        )}
      </DialogActions>
      <PreventTransitionPrompt when={isDirty} />
    </>
  );
};

interface AccountDetailProps {
  onClose: Function;
  onSave: Function;
  action: 'edit' | 'add';
  selectedAccount: string;
  accountId?: string;
}

export function AccountDetail(props: AccountDetailProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const { allowsAction } = usePermissions();
  const useAccount = useGetAccount({
    selectedAccount: props.selectedAccount || '',
    hashKey: props.accountId || '',
  });
  if (!allowsAction('accounts.read')) {
    history.push(`${getBaseUrl()}/accounts`);
    return null;
  }

  let title;
  if (useAccount.isLoading) {
    title = t('Loading...');
    return (
      <ModalDialog title={title} action={props.action}>
        <Panel variant="none" style={{ border: 0 }}>
          <Grid container>
            <Grid item xs={12}>
              <LoadingBounce
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '260px',
                }}
              />
            </Grid>
          </Grid>
        </Panel>
      </ModalDialog>
    );
  }

  if (useAccount.isError || (props.action === 'edit' && !useAccount.data)) {
    title = t('Error');
    return (
      <ModalDialog title={title} action={props.action}>
        <Panel variant="none" style={{ border: 0 }}>
          <Grid container>
            <Grid item xs={12} spacing={2}>
              <PlainMessage
                title={t('Database Error')}
                messages={[
                  t('Application could not load accounts data.'),
                  t('Please try again later or contact support if the error persists'),
                ]}
              />
            </Grid>
          </Grid>
        </Panel>
      </ModalDialog>
    );
  }
  if (props.action === 'edit' && useAccount.data) {
    title = t('Edit {{accountName}} - {{accountNumber}} ({{accountType}})', {
      accountName: useAccount.data.company,
      accountNumber: useAccount.data.ld_na_number,
      accountType: getOptionLabel('ld_na_account_types', useAccount.data.account_type),
    });
  } else if (props.action === 'add') title = t('Add a Service Provider And National Account');
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={() => props.onClose()}
      aria-labelledby="customized-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      open
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <Content
        data={useAccount.data}
        action={props.action}
        onSave={props.onSave}
        onClose={props.onClose}
        selectedAccount={props.selectedAccount}
        accountId={props.accountId}
      />
    </Dialog>
  );
}
