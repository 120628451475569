import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions } from '@material-ui/core';
import { Button, LoadingBounce } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { auth } from 'Auth';
import { ClosableDialogContent } from 'components/ClosableDialog/ClosableDialogContent';
import { ClosableDialogTitle } from 'components/ClosableDialog/ClosableDialogTitle';
import { Contact } from 'components/Contact/utils';
import { getChildrenForAssignments } from 'components/TireProfile/Assignee/GraphQL/Query/queryAssigneeList';
import { getAllResultsPaginated } from 'graphql/utils';
import { BillingProfileLocation } from '../../query';
import { PORequiredLocationTable } from '../Tables/poRequiredLocationTable';

interface Props {
  editFlag: boolean;
  open: boolean;
  locationAssigned: string | undefined;
  onCloseHandler: () => void;
  setLocation: (locationDetails: BillingProfileLocation | undefined, contactDetails: Contact | undefined) => void;
}

export function PORequiredLocationDialog(props: Props) {
  const { open, onCloseHandler, locationAssigned, setLocation, editFlag } = props;
  const { t } = useTranslation();
  const dialogTitle = t('Select a Location');
  const [loadingLocations, setLoadingLocations] = useState(true);
  const [queriedLocations, setQueriedLocations] = useState([]);

  // All locations are needed no matter what.
  // https://servicesolutions.atlassian.net/secure/RapidBoard.jspa?rapidView=8&modal=detail&selectedIssue=GSDCP-1940
  const getAllLocations = async () => {
    if (!auth || !auth.apolloClient) return;
    try {
      const relationship = auth.getMainCustomerRelationship();

      let queryVars = {
        hash_key: `1~${relationship.replace(/^(null~)*/, '').replace(/~.*/, '')}`,
        range_key: relationship.replace(/(~null)*$/, ''),
      };

      const data: any = await getAllResultsPaginated(
        getChildrenForAssignments,
        queryVars,
        750,
        'getChildren',
        'customers',
      );
      setQueriedLocations(data);
    } catch (e) {
      console.log('getCustomerData: error:', e);
    }
    setLoadingLocations(false);
  };

  useEffect(() => {
    const asyncLoad = async () => {
      await getAllLocations();
    };
    if (open === true) {
      setLoadingLocations(true);
      asyncLoad();
    }
    asyncLoad();
  }, [open]);
  if (!open) return null;
  return (
    <Dialog
      fullWidth
      maxWidth={false}
      scroll="paper"
      open={open}
      onClose={onCloseHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <ClosableDialogTitle onClose={onCloseHandler}>{dialogTitle}</ClosableDialogTitle>
      <ClosableDialogContent dividers style={{ padding: 0 }}>
        {loadingLocations ? (
          <LoadingBounce style={{ height: '70vh' }} />
        ) : (
          <PORequiredLocationTable
            editFlag={editFlag}
            locationAssigned={locationAssigned}
            locations={queriedLocations}
            setLocation={setLocation}
          />
        )}
      </ClosableDialogContent>
      <DialogActions>
        <Button onClick={onCloseHandler} color="primary">
          {t('Ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
