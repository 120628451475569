import React from 'react';
import { Add, Pageview } from '@material-ui/icons';
import { useTranslation } from '@michelin/central-provider';
import { SpeedDialActions } from 'components/SpeedDial';
import { SelectedServiceOffer, ServiceOfferIds, getBaseUrl, getSelectedServiceOffer } from 'prefs-and-service-offers';
import { useHistory } from 'react-router-dom';

export enum ServiceParams {
  MECHANICAL = 'mechanical',
  TIRE_WHEEL = 'tire-wheel',
}
export enum ProfileParams {
  ONSITE = 'onsite',
  ERS = 'ers',
  ERS_ONSITE = 'ers-onsite',
}

const useGetViewActions = () => {
  let actions: SpeedDialActions[] = [];
  const { t } = useTranslation();
  const history = useHistory();

  const selectedServiceOffer: SelectedServiceOffer | undefined = getSelectedServiceOffer() || undefined;
  const onCallFlag: boolean =
    !!selectedServiceOffer && !!selectedServiceOffer.id && selectedServiceOffer.id === ServiceOfferIds.onCallTire;
  const fleetOrderFlag: boolean =
    !!selectedServiceOffer && !!selectedServiceOffer.id && selectedServiceOffer.id === ServiceOfferIds.bulkOrder;

  actions.push({
    icon: <Add />,
    title: t('Tires and Wheels - Onsite'),
    name: 'Tires and Wheels - Onsite',
    id: 'tires-and-wheels-onsite',
    onClick: () => {
      history.push(`${getBaseUrl()}/billing-future/${ServiceParams.TIRE_WHEEL}/${ProfileParams.ONSITE}/create`);
    },
    titleOpen: true,
  });
  actions.push({
    icon: <Add />,
    title: t('Tires and Wheels - ERS'),
    name: 'Tires and Wheels - ERS',
    id: 'tires-and-wheels-ers',
    onClick: () => {
      history.push(`${getBaseUrl()}/billing-future/${ServiceParams.TIRE_WHEEL}/${ProfileParams.ERS}/create`);
    },
    titleOpen: true,
  });
  actions.push({
    icon: <Add />,
    title: t('Tires and Wheels - ERS & Onsite'),
    name: 'Tires and Wheels - ERS & Onsite',
    id: 'tires-and-wheels-ers-onsite',
    onClick: () => {
      history.push(`${getBaseUrl()}/billing-future/${ServiceParams.TIRE_WHEEL}/${ProfileParams.ERS_ONSITE}/create`);
    },
    titleOpen: true,
  });
  actions.push({
    icon: <Add />,
    title: t('Mechanical - Onsite'),
    name: 'Mechanical - Onsite',
    id: 'mechanical-onsite',
    onClick: () => {
      history.push(`${getBaseUrl()}/billing-future/${ServiceParams.MECHANICAL}/${ProfileParams.ONSITE}/create`);
    },
    titleOpen: true,
  });
  actions.push({
    icon: <Pageview />,
    title: t('See billing profile (MockData)'),
    name: 'See billing profile',
    id: 'see-billing-profile',
    onClick: () => {
      history.push(`${getBaseUrl()}/billing-future/7678726e-1a8f-4f43-bac2-ec6c97df49f1/view`);
    },
    titleOpen: true,
  });
  actions.push({
    icon: <Add />,
    title: t('Create Billing Profile - Classic / Old'),
    name: 'Create Billing Profile - Classic',
    id: 'create-billing-profile-classic',
    onClick: () => {
      history.push(`${getBaseUrl()}/billing/create`);
    },
    titleOpen: true,
  });

  if (onCallFlag) {
    actions = actions.filter((action) => action.id !== 'mechanical-onsite' && action.id !== 'tires-and-wheels-onsite');
  }
  if (fleetOrderFlag) {
    actions = actions.filter((action) => action.id !== 'tires-and-wheels-ers');
  }
  return actions;
};

export { useGetViewActions };
