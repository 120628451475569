import { auth } from 'Auth';
import { gql } from 'apollo-boost';

export const MAX_QUERIES_PER_REQUEST = 1000;

/** *
 * This function is in charge of geting all the results from a paginated query simulating a normal query.
 * @param query The query to execute
 * @param variables Variables to use in the query
 * @param rowsPerPage Number of rows to use per pagination. (If you don't know use: 500)
 * @param resultsKey The result key to access the data from the query's result.
 * @param itemsField
 * @param filterDeleted
 * @param fetchPolicy
 * @returns promise with a null or all the data in a single array.
 *
 * There is one example on how to use this method in /components/Location/index.tsx Line: ~214
 */
export async function getAllResultsPaginated(
  query: any,
  variables: any,
  rowsPerPage: number,
  resultsKey: string,
  itemsField: string = 'items',
  filterDeleted?: boolean,
  fetchPolicy: 'cache-first' | 'network-only' | 'cache-only' | 'no-cache' | 'standby' = 'network-only',
): Promise<null | any[]> {
  const apolloClient = auth.apolloClient;
  if (!apolloClient) return null;
  const res: any[] = [];
  let nextToken = '';

  do {
    // eslint-disable-next-line no-await-in-loop
    const data = await apolloClient.query({
      query,
      variables: {
        ...variables,
        limit: rowsPerPage,
        nextToken,
        filterDeleted,
      },
      fetchPolicy,
    });

    if (!data.data || !data.data[resultsKey]) return null;
    if (data.data[resultsKey][itemsField]) {
      if (Array.isArray(data.data[resultsKey][itemsField])) {
        data.data[resultsKey][itemsField].forEach((e: any) => res.push(e));
      } else {
        res.push(data.data[resultsKey][itemsField]);
      }
    }
    nextToken = data.data[resultsKey].nextToken || null;
  } while (nextToken);
  return res;
}

/** *
 * This function is in charge of geting all the results from multiple paginated query's simulating a normal and only one query.
 * @param queries Map of queries to execute
 * @param rowsPerPage Number of rows to use per pagination. (If you don't know use: 500)
 * @param refetch Fetch policy turned to network-only in case there is a need to obtain again the data.
 * @returns promise with a null or all the data mapped to every query.
 *
 * There is one example on how to use this method in the ChangeLogs.
 */
export async function paginatedMultiQuery(
  queries: Map<string, string>,
  rowsPerPage: number,
  refetch: boolean = false,
  res: Map<string, any[]> = new Map(),
): Promise<Map<string, any[]> | null> {
  const apolloClient = auth.apolloClient;
  if (!apolloClient) return null;
  if (!queries.size) return res;
  if (queries.size > MAX_QUERIES_PER_REQUEST) {
    let chunk: Map<string, string> = new Map();
    const proms = [];
    queries.forEach((v, k) => {
      chunk.set(k, v);
      if (chunk.size === MAX_QUERIES_PER_REQUEST) {
        proms.push(paginatedMultiQuery(chunk, rowsPerPage, refetch, res));
        chunk = new Map();
      }
    });
    proms.push(paginatedMultiQuery(chunk, rowsPerPage, refetch, res));
    while (proms.length) await proms.shift();
    return res;
  }
  const nextTokens: Map<string, string> = new Map();
  do {
    const queryParts: string[] = [];
    queries.forEach((part, key) => {
      const nextToken: string = nextTokens.get(key) || '';
      queryParts.push(`${key}: ${part.replace(')', `, limit: ${rowsPerPage}, nextToken: "${nextToken}" )`)}`);
    });
    // eslint-disable-next-line no-await-in-loop
    const data = await apolloClient.query({
      query: gql`query Query {
        ${queryParts.join('\n')},
      }`,
      ...(refetch ? { fetchPolicy: 'network-only' } : {}),
    });
    if (!data.data) return null;
    const nextQueries: Map<string, string> = new Map();
    queries.forEach((part, key) => {
      if (!res.has(key)) res.set(key, []);
      const accum = res.get(key);
      if (accum && data.data[key].items && Array.isArray(data.data[key].items))
        data.data[key].items.forEach((e: any) => accum.push(e));
      if (accum && data.data[key].items && typeof data.data[key].items === 'string') accum.push(data.data[key].items);
      if (!data.data[key].nextToken) return;
      nextQueries.set(key, part);
      nextTokens.set(key, data.data[key].nextToken || null);
    });
    queries = nextQueries;
  } while (queries.size);
  return res;
}
