import React from 'react';
import { useTranslation } from '@michelin/central-provider';
import LastModified from 'components/ChangeLog/LastModified';
import { PageTitle } from 'components/PageTitle/PageTitle';

const HeaderBillingProfileDetail = ({
  subtitle,
  action,
  profileId,
}: {
  subtitle: string;
  action: string;
  profileId: string;
}) => {
  const { t } = useTranslation();
  return (
    <PageTitle
      title={
        action.toLowerCase() === 'create'
          ? t('Create Billing Profile')
          : action.toLowerCase() === 'view'
          ? t('Billing Profile Details')
          : t('Edit Billing Profile')
      }
      subtitle={subtitle}
      rightItems={
        action?.toLowerCase() === 'create' ? null : <LastModified entity="billing" identifier={`${profileId}`} />
      }
    />
  );
};

export { HeaderBillingProfileDetail };
