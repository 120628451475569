import React from 'react';
import { Dialog, DialogActions } from '@material-ui/core';
import { Button } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { ClosableDialogContent } from 'components/ClosableDialog/ClosableDialogContent';
import { ClosableDialogTitle } from 'components/ClosableDialog/ClosableDialogTitle';
import { ContactsTable } from './Tables/ContactsTable';

const ContactsModal = ({
  open,
  onClose,
  dialogTitle,
  additionalColumns,
}: {
  open: boolean;
  onClose: () => void;
  dialogTitle: string;
  additionalColumns?: Array<JSX.Element>;
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      scroll="paper"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <ClosableDialogTitle onClose={onClose}>{dialogTitle}</ClosableDialogTitle>
      <ClosableDialogContent dividers style={{ padding: 0 }}>
        <ContactsTable popupMode={true} hideActions={true} additionalColumns={additionalColumns} />
      </ClosableDialogContent>
      <DialogActions>
        <Button color="danger" style={{ margin: '8px' }} onClick={onClose}>
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { ContactsModal };
