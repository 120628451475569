import { Customer } from '../Location';

export const localize = (key: string, loc: Customer | string) => {
  if (
    (typeof loc === 'string' && loc.toLowerCase() === 'ca') ||
    (typeof loc === 'object' && loc.customer_country && loc.customer_country.toLowerCase() === 'ca')
  ) {
    switch (key) {
      case 'state':
        return 'province';
      case 'State':
        return 'Province';
      case 'zip':
      case 'zip code':
        return 'postal code';
      case 'Zip':
      case 'Zip code':
      case 'Zip Code':
        return 'Postal Code';
      default:
        return key;
    }
  }
  return key;
};
