import React from 'react';
import { AvailableSelectOptions, getOptionLabel } from '@michelin/select-options-provider';
import { uniq } from 'lodash';
import { SOPTireProfile } from './Types';
import ValidatedItems from './ValidatedItems';

interface SOPCellProps {
  values: string[];
  errors: string[];
  type: string;
  axleValue?: string;
  tireProfile: SOPTireProfile[] | null;
}

export default function SOPCell(props: SOPCellProps) {
  const cellValues: any[] = [];
  let validOptions: string[] = [];
  let getOption: AvailableSelectOptions;

  switch (props.type) {
    case 'vehicle':
      getOption = 'vehicle_types_onsite';
      validOptions = uniq(props.tireProfile?.map((item) => item.vehicle_type)) || [];
      break;
    case 'application':
      getOption = 'application_types';
      validOptions = uniq(props.tireProfile?.map((item) => item.application_type)) || [];
      break;
    case 'axle':
      getOption = 'axle_types';
      validOptions = uniq(props.tireProfile?.map((item) => item.axle)) || [];
      break;
    case 'tire':
      getOption = 'USA_tire_sizes';
      validOptions = uniq(props.tireProfile?.map((item) => item.tire_size)) || [];
      break;
    default:
      break;
  }

  props.values
    ?.map((v) => ({ value: v, label: getOptionLabel(getOption, v) }))
    .sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    })
    .forEach((item) => {
      if (props.errors && props.errors.includes(item.value)) {
        cellValues.push(
          <ValidatedItems
            key={item.value}
            value={item.label}
            type={props.type}
            validOptions={validOptions || []}
            axleValue={props.axleValue}
          />,
        );
      } else {
        cellValues.push(
          <div key={item.value} style={{ marginBottom: 4, marginTop: 4 }}>
            {item.label}
          </div>,
        );
      }
    });

  return <div>{cellValues}</div>;
}
