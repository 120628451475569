import React, { ChangeEvent, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Panel, Select, TextField } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { getOptions } from '@michelin/select-options-provider';
import Messages from 'components/Messages';
import { SelectOption } from 'components/Select';
import Autocomplete from '../../../Autocomplete';
import { BillingPreferences, BillingRequestedPhotosTypes, BillingServiceTypes } from '../query';

// import BillingPreferences from '..';

interface RequestedPhotosProps {
  isEdit: boolean;
  profile: BillingPreferences;
  changeProfile: Function;
  setModifiedFlag: (modifiedFlag: boolean) => void;
}

// These values should be standars in the DB Option Select Provider.
const graphqlErrorTitle = 'Database Error';
const graphqlFirstErrorMessage = 'Application could not load billing requested photos data.';
const graphqlSecondErrorMessage = 'Please try again later or contact support if the error persists';

export default function RequestedPhotos(props: RequestedPhotosProps) {
  let finalChild: JSX.Element = (
    <Messages.PlainMessage title={graphqlErrorTitle} messages={[graphqlFirstErrorMessage, graphqlSecondErrorMessage]} />
  );

  const { isEdit, profile, changeProfile, setModifiedFlag } = props;

  const { t } = useTranslation();

  const [photoTypes, setPhotoTypes] = useState<SelectOption[]>([]);
  useEffect(() => {
    const opts: SelectOption[] = getOptions('photo_types').filter((x) => {
      if (
        profile.general_information.service_type === BillingServiceTypes.MECHANICAL &&
        (x.value === 'asset_before' || x.value === 'asset_after')
      ) {
        return true;
      }
      if (
        profile.general_information.service_type === BillingServiceTypes.TIRE_WHEEL &&
        x.value !== 'asset_before' &&
        x.value !== 'asset_after'
      ) {
        return true;
      }
      return false;
    });

    setPhotoTypes(opts);
  }, [profile.general_information.service_type]);

  const displayTireIdentifierType =
    profile.requested_photos.types &&
    profile.requested_photos.types.includes(BillingRequestedPhotosTypes.TIRE_IDENTIFIER);

  const [otherPhotos, setOtherPhotos] = useState(profile.requested_photos.other_photos || '');
  useEffect(() => {
    profile.requested_photos.other_photos = otherPhotos;
  }, [profile.requested_photos, otherPhotos]);

  finalChild = (
    <Grid container spacing={2} style={{ paddingTop: 5 }}>
      <Grid item sm={12} md={displayTireIdentifierType ? 6 : 8}>
        <Autocomplete
          id="requestedTypesofPhotos"
          multiple
          variant="standard"
          label={t('Requested Types of Photos')}
          disableClearable
          disableCloseOnSelect
          placeholder=""
          onChange={(value: any) => {
            profile.requested_photos.types = value;
            setModifiedFlag(true);
            changeProfile({ ...profile });
          }}
          suggestions={photoTypes}
          values={profile.requested_photos.types}
          readOnly={!isEdit}
        />
      </Grid>
      {displayTireIdentifierType ? (
        <Grid item sm={12} md={3}>
          <Select
            value={profile.requested_photos.identifier_type}
            label={t('Tire Identifier Type')}
            readOnly={!isEdit}
            style={{ width: '100%' }}
            onChange={(event: ChangeEvent<any>) => {
              profile.requested_photos.identifier_type = event.target.value;
              setModifiedFlag(true);
              changeProfile({ ...profile });
            }}
            options={getOptions('photo_identifier_types')}
          />
        </Grid>
      ) : null}
      <Grid item sm={12} md={displayTireIdentifierType ? 3 : 4}>
        <TextField
          value={otherPhotos}
          onChange={(e) => setOtherPhotos(e.target.value)}
          label={t('Other Photos')}
          readOnly={!isEdit}
        />
      </Grid>
    </Grid>
  );

  return (
    <Panel
      title={t('Requested Photos')}
      subtitle={
        <span style={{ fontSize: '0.8em' }}>
          <br />
          <Alert severity="warning">
            {t(
              'Michelin does not guarantee the applicable dealer from its independent dealer network will provide these Requested Details.',
            )}
          </Alert>
        </span>
      }
      style={{ marginBottom: 15 }}
    >
      {finalChild}
    </Panel>
  );
}
