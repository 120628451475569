import { useCallback } from 'react';
import { useAPICallAsync } from '@michelin/central-provider';
import { FetchAssetTypesParams } from '@michelin/core-profile-ui-tools';
import {
  FetchMakesFn,
  FetchMakesParams,
  FetchModelsFn,
  FetchModelsParams,
  FetchOptionsFn,
  FetchOptionsParams,
  FetchStatesFn,
  FetchStatesParams,
  FetchTireSizesFn,
  FetchTireSizesParams,
  FetchYearsFn,
  FetchYearsParams,
} from '@michelin/core-profile-ui-tools/build/components/UFOVehicleDetailsForm/types';
import { SelectOption, getOptions } from '@michelin/select-options-provider';
import defaultConfig from '../../../global-variables';
import { makesAPIType, modelsAPIType, optionsAPIType, tireSizesAPIType, yearsAPIType } from '../../Types/APITypes';
import { buildOptionsAndValuesFromArray, buildOptionsAndValuesFromObject } from '../Utils';

export const useGetYears = (vehicle_type: string) => {
  const { call } = useAPICallAsync<SelectOption[], FetchYearsParams>({
    api: yearsAPIType(vehicle_type),
    baseRoute: defaultConfig.externalAPIUrl,
    autoAbortKey: 'use::get::years',
  });

  const fn: FetchYearsFn = useCallback(
    async (params: FetchYearsParams) => {
      try {
        const response = await call({ ...params }, undefined, false);
        return buildOptionsAndValuesFromArray(response.data);
      } catch (e) {
        return [];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [call],
  );

  return fn;
};

export const useGetMakes = (vehicle_type: string) => {
  const { call } = useAPICallAsync<SelectOption[], FetchMakesParams>({
    api: makesAPIType(vehicle_type),
    baseRoute: defaultConfig.externalAPIUrl,
    autoAbortKey: 'use::get::makes',
  });

  const fn: FetchMakesFn = useCallback(
    async (params: FetchMakesParams) => {
      try {
        const response = await call({ ...params }, undefined, false);
        return buildOptionsAndValuesFromObject(response.data);
      } catch (e) {
        return [];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [call],
  );

  return fn;
};

export const useGetModels = (vehicle_type: string) => {
  const { call } = useAPICallAsync<SelectOption[], FetchModelsParams>({
    api: modelsAPIType(vehicle_type),
    baseRoute: defaultConfig.externalAPIUrl,
    autoAbortKey: 'use::get::models',
  });

  const fn: FetchModelsFn = useCallback(
    async (params: FetchModelsParams) => {
      try {
        const response = await call({ ...params }, undefined, false);
        return buildOptionsAndValuesFromObject(response.data);
      } catch (e) {
        return [];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [call],
  );

  return fn;
};

export const useGetOptions = (vehicle_type: string) => {
  const { call } = useAPICallAsync<SelectOption[], FetchOptionsParams>({
    api: optionsAPIType(vehicle_type),
    baseRoute: defaultConfig.externalAPIUrl,
    autoAbortKey: 'use::get::options',
  });

  const fn: FetchOptionsFn = useCallback(
    async (params: FetchOptionsParams) => {
      try {
        const response = await call({ ...params }, undefined, false);
        return buildOptionsAndValuesFromObject(response.data);
      } catch (e) {
        return [];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [call],
  );

  return fn;
};

export const useGetTireSizes = (vehicle_type: string) => {
  const { call } = useAPICallAsync<SelectOption[], FetchTireSizesParams>({
    api: tireSizesAPIType(vehicle_type),
    baseRoute: defaultConfig.externalAPIUrl,
    autoAbortKey: 'use::get::tireSize',
  });

  const fn: FetchTireSizesFn = useCallback(
    async (params: FetchTireSizesParams) => {
      try {
        const response = await call({ ...params }, undefined, false);
        return buildOptionsAndValuesFromObject(response.data);
      } catch (e) {
        return [];
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [call],
  );

  return fn;
};

export const useGetStates = () => {
  const fn: FetchStatesFn = useCallback(
    async (params: FetchStatesParams) => {
      if (params.country_code === 'ca') return getOptions('ca_provinces');
      return getOptions('us_states');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return fn;
};

export const useGetAssetTypes = () => {
  const fn: FetchStatesFn = useCallback(
    async (params: FetchAssetTypesParams) => {
      if (params.country_code === 'ca') return getOptions('asset_types');
      return getOptions('asset_types');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return fn;
};
