/* eslint-disable no-param-reassign */
import React, { ChangeEvent } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  Link,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, LoadingBounce, Panel, TextField } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { ApolloError } from 'apollo-client';
import { Contact } from 'components/Contact/utils';
import Messages from 'components/Messages';
import { DataGrid } from 'devextreme-react';
import { Column, Scrolling } from 'devextreme-react/data-grid';
import { useHistory } from 'react-router-dom';
import { getBaseUrl } from '../../../../prefs-and-service-offers';
import { BillingApprovals, BillingPreferences, BillingProfileTypes, BillingServiceTypes } from '../query';
import { ValidationErrors } from '../utils';

// These values should be standars in the DB Option Select Provider.
const graphqlErrorTitle = 'Database Error';
const graphqlFirstErrorMessage = 'Application could not load billing approvals data.';
const graphqlSecondErrorMessage = 'Please try again later or contact support if the error persists';

export interface ApprovalsProps {
  error: ApolloError | undefined;
  data: Contact[];
  loading: boolean;
  profile: BillingPreferences;
  setProfile: Function;
  setModifiedFlag: (modifiedFlag: boolean) => void;
  editFlag: boolean;
  setSpecialContacts: Function;
  specialContacts: Contact[];
  validationErrors: ValidationErrors;
}

export const fixApprovals = (approval: BillingApprovals | undefined) => {
  if (approval && approval.ers_pre_approvals_limits && approval.onsite_pre_approvals_limits) {
    const ret: BillingApprovals = {
      ers_pre_approvals_limits: {
        maximum_num_of_tires: approval.ers_pre_approvals_limits.maximum_num_of_tires,
        maxium_num_of_wheels: approval.ers_pre_approvals_limits.maxium_num_of_wheels,
        maximum_dollar_amount: approval.ers_pre_approvals_limits.maximum_dollar_amount,
      },
      onsite_pre_approvals_limits: {
        maximum_num_of_tires: approval.onsite_pre_approvals_limits.maximum_num_of_tires,
        maxium_num_of_wheels: approval.onsite_pre_approvals_limits.maxium_num_of_wheels,
        maximum_dollar_amount: approval.onsite_pre_approvals_limits.maximum_dollar_amount,
      },
    };
    return ret;
  }
  const ret: BillingApprovals = {
    ers_pre_approvals_limits: {
      maximum_num_of_tires: 0,
      maxium_num_of_wheels: 0,
      maximum_dollar_amount: 0,
    },
    onsite_pre_approvals_limits: {
      maximum_num_of_tires: 0,
      maxium_num_of_wheels: 0,
      maximum_dollar_amount: 0,
    },
  };
  return ret;
};

export const checkApprovals = (
  type: string,
  service_type: string,
  approval: BillingApprovals | undefined,
  specialContacts: Contact[],
  validationErrors: ValidationErrors,
) => {
  let fail = false;

  if (service_type === BillingServiceTypes.MECHANICAL) {
    if (approval) {
      if (type === BillingProfileTypes.onsite && approval.onsite_pre_approvals_limits) {
        if (typeof approval.onsite_pre_approvals_limits.maximum_dollar_amount !== 'number') {
          return { errorMessage: 'Approval Limits cannot be empty' };
          // return true;
        }
      }
    }
    return false;
  }

  if (approval) {
    if (type !== BillingProfileTypes.onsite && approval.ers_pre_approvals_limits) {
      if (
        typeof approval.ers_pre_approvals_limits.maximum_num_of_tires !== 'number' ||
        typeof approval.ers_pre_approvals_limits.maxium_num_of_wheels !== 'number'
      ) {
        validationErrors.ers_max_tires = typeof approval.ers_pre_approvals_limits.maximum_num_of_tires !== 'number';
        validationErrors.ers_max_wheels = typeof approval.ers_pre_approvals_limits.maxium_num_of_wheels !== 'number';
        fail = true;
      }
    } else if (type !== BillingProfileTypes.onsite) {
      fail = true;
    }
    if (type !== BillingProfileTypes.ers && approval.onsite_pre_approvals_limits) {
      if (
        typeof approval.onsite_pre_approvals_limits.maximum_dollar_amount !== 'number' ||
        typeof approval.onsite_pre_approvals_limits.maximum_num_of_tires !== 'number' ||
        typeof approval.onsite_pre_approvals_limits.maxium_num_of_wheels !== 'number'
      ) {
        validationErrors.os_max_tires = typeof approval.onsite_pre_approvals_limits.maximum_num_of_tires !== 'number';
        validationErrors.os_max_wheels = typeof approval.onsite_pre_approvals_limits.maxium_num_of_wheels !== 'number';
        validationErrors.os_max_dollars =
          typeof approval.onsite_pre_approvals_limits.maximum_dollar_amount !== 'number';
        fail = true;
      }
    } else if (type !== BillingProfileTypes.ers) {
      fail = true;
    }
  } else {
    fail = true;
  }
  if (fail) {
    return { errorMessage: 'Approval Limits cannot be empty' };
  }
  specialContacts.forEach((s) => {
    if (!s.num_of_tires && !s.num_of_wheels && !s.tire_dollar_amount && type !== BillingProfileTypes.ers) {
      fail = true;
    }
  });
  if (fail) {
    return { errorMessage: 'Approval Limits cannot be empty for Contacts' };
  }
  return false;
};

export default function Approvals(props: ApprovalsProps) {
  const {
    loading,
    error,
    data: contacts,
    profile,
    setProfile,
    setModifiedFlag,
    editFlag,
    specialContacts,
    setSpecialContacts,
    validationErrors,
  } = props;

  const { t } = useTranslation();
  const history = useHistory();

  const [updateStateCounter, setUpdateStateCounter] = React.useState(0);
  const forceUpdate = () => setUpdateStateCounter(updateStateCounter + 1);
  const [contactDialog, setContactDialog] = React.useState<boolean>(false);
  // const textInput:any = useRef(null);

  const specialContactsSet = new Set();
  if (specialContacts) {
    specialContacts.forEach((x) => specialContactsSet.add(x.hash_key));
  }

  let finalChild: JSX.Element = (
    <Messages.PlainMessage title={graphqlErrorTitle} messages={[graphqlFirstErrorMessage, graphqlSecondErrorMessage]} />
  );

  const handleDeleteContact = (row: Contact) => {
    setSpecialContacts(specialContacts.filter((c) => !(c.hash_key === row.hash_key)));
  };

  if (profile.approvals) {
    const { ers_pre_approvals_limits, onsite_pre_approvals_limits } = profile.approvals;

    let ers_maximum_num_of_tires: string;
    let ers_maxium_num_of_wheels: string;

    // Terry said it should be 0 rather than empty
    if (ers_pre_approvals_limits) {
      const { maxium_num_of_wheels, maximum_num_of_tires } = ers_pre_approvals_limits;
      ers_maxium_num_of_wheels = typeof maxium_num_of_wheels === 'number' ? String(maxium_num_of_wheels) : '';
      ers_maximum_num_of_tires = typeof maximum_num_of_tires === 'number' ? String(maximum_num_of_tires) : '';
    } else {
      ers_maxium_num_of_wheels = '';
      ers_maximum_num_of_tires = '';
    }

    let onsite_maximum_dollar_amount: string;
    let onsite_maximum_num_of_tires: string;
    let onsite_maxium_num_of_wheels: string;

    if (onsite_pre_approvals_limits) {
      const { maximum_dollar_amount, maximum_num_of_tires, maxium_num_of_wheels } = onsite_pre_approvals_limits;
      onsite_maximum_dollar_amount = typeof maximum_dollar_amount === 'number' ? String(maximum_dollar_amount) : '';
      onsite_maximum_num_of_tires = typeof maximum_num_of_tires === 'number' ? String(maximum_num_of_tires) : '';
      onsite_maxium_num_of_wheels = typeof maxium_num_of_wheels === 'number' ? String(maxium_num_of_wheels) : '';
    } else {
      onsite_maximum_dollar_amount = '';
      onsite_maximum_num_of_tires = '';
      onsite_maxium_num_of_wheels = '';
    }
    if (loading) finalChild = <LoadingBounce style={{ height: 300 }} />;
    if (error) {
      finalChild = (
        <Messages.PlainMessage
          title={graphqlErrorTitle}
          messages={[graphqlFirstErrorMessage, graphqlSecondErrorMessage]}
        />
      );
    }

    const parseStringForNumberPurposes = (input: string) => {
      if (input && input.length > 0) {
        const numbericVal = input.replace(/[^0-9]/g, '');
        return parseInt(numbericVal, 10);
      }
      return 0;
    };

    const isInSpecialContacts = (input: string) => {
      let isSpecial = false;
      for (let i = 0; i < specialContacts.length; i++) {
        if (specialContacts[i].hash_key === input) {
          isSpecial = true;
          break;
        }
      }
      return isSpecial;
    };

    if (contacts && loading === false && !error) {
      const noContactAvailableMessage = t('No records to display.');

      finalChild = (
        <Grid style={{ width: '100%' }}>
          <Grid container spacing={2} style={{ paddingTop: 5 }}>
            {profile.general_information.profile_type !== BillingProfileTypes.onsite && (
              <Grid item sm={12}>
                <Grid container spacing={2} style={{ paddingBottom: 20 }}>
                  <Grid item sm={12} md={2}>
                    <div style={{ paddingTop: 15, paddingLeft: 5 }}>{t('ERS Service')}</div>
                  </Grid>
                  <Grid item sm={12} md={3}>
                    <TextField
                      type="number"
                      required
                      label={t('Maximum # of Tires')}
                      onChange={(event: ChangeEvent<any>) => {
                        validationErrors.ers_max_tires = false;
                        const prof = profile;
                        if (prof && prof.approvals && prof.approvals.ers_pre_approvals_limits) {
                          prof.approvals.ers_pre_approvals_limits.maximum_num_of_tires = parseStringForNumberPurposes(
                            event.target.value,
                          );
                        }
                        setModifiedFlag(true);
                        setProfile(prof);
                        forceUpdate();
                      }}
                      InputProps={{ inputProps: { min: 0 }, readOnly: !editFlag }}
                      error={validationErrors.ers_max_tires}
                      value={ers_maximum_num_of_tires}
                      readOnly={!editFlag}
                    />
                  </Grid>
                  <Grid item sm={12} md={3}>
                    <TextField
                      type="number"
                      required
                      label={t('Maximum # of Wheels')}
                      onChange={(event: ChangeEvent<any>) => {
                        validationErrors.ers_max_wheels = false;
                        const prof = profile;
                        if (prof && prof.approvals && prof.approvals.ers_pre_approvals_limits) {
                          prof.approvals.ers_pre_approvals_limits.maxium_num_of_wheels = parseStringForNumberPurposes(
                            event.target.value,
                          );
                        }
                        setModifiedFlag(true);
                        setProfile(prof);
                        forceUpdate();
                      }}
                      value={ers_maxium_num_of_wheels}
                      error={validationErrors.ers_max_wheels}
                      readOnly={!editFlag}
                      InputProps={{ inputProps: { min: 0 }, readOnly: !editFlag }}
                    />
                  </Grid>
                  <Grid item sm={12} md={4} />
                </Grid>
              </Grid>
            )}
            {profile.general_information.profile_type !== BillingProfileTypes.ers && (
              <Grid item sm={12} md={12}>
                <Grid container spacing={2} style={{ paddingBottom: 20 }}>
                  <Grid item sm={12} md={2}>
                    <div style={{ paddingTop: 15, paddingLeft: 5 }}>{t('On-site Service')}</div>
                  </Grid>
                  {profile.general_information.service_type !== BillingServiceTypes.MECHANICAL && (
                    <>
                      <Grid item sm={12} md={3}>
                        <TextField
                          type="number"
                          required
                          label={t('Maximum # of Tires')}
                          onChange={(event: ChangeEvent<any>) => {
                            validationErrors.os_max_tires = false;
                            const prof = profile;
                            if (prof && prof.approvals && prof.approvals.onsite_pre_approvals_limits) {
                              prof.approvals.onsite_pre_approvals_limits.maximum_num_of_tires =
                                parseStringForNumberPurposes(event.target.value);
                            }
                            setModifiedFlag(true);
                            setProfile(prof);
                            forceUpdate();
                          }}
                          value={onsite_maximum_num_of_tires}
                          error={validationErrors.os_max_tires}
                          readOnly={!editFlag}
                          InputProps={{ inputProps: { min: 0 }, readOnly: !editFlag }}
                        />
                      </Grid>
                      <Grid item sm={12} md={3}>
                        <TextField
                          type="number"
                          required
                          label={t('Maximum # of Wheels')}
                          onChange={(event: ChangeEvent<any>) => {
                            validationErrors.os_max_wheels = false;
                            const prof = profile;
                            if (prof && prof.approvals && prof.approvals.onsite_pre_approvals_limits) {
                              prof.approvals.onsite_pre_approvals_limits.maxium_num_of_wheels =
                                parseStringForNumberPurposes(event.target.value);
                            }
                            setModifiedFlag(true);
                            setProfile(prof);
                            forceUpdate();
                          }}
                          value={onsite_maxium_num_of_wheels}
                          error={validationErrors.os_max_tires}
                          readOnly={!editFlag}
                          InputProps={{ inputProps: { min: 0 }, readOnly: !editFlag }}
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item sm={12} md={3}>
                    <TextField
                      type="number"
                      required
                      label={t('Maximum Dollar Amount')}
                      onChange={(event: ChangeEvent<any>) => {
                        validationErrors.os_max_dollars = false;
                        const prof = profile;
                        if (prof && prof.approvals && prof.approvals.onsite_pre_approvals_limits) {
                          prof.approvals.onsite_pre_approvals_limits.maximum_dollar_amount =
                            parseStringForNumberPurposes(event.target.value);
                        }
                        setModifiedFlag(true);
                        setProfile(prof);
                        forceUpdate();
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        readOnly: !editFlag,
                        inputProps: { min: 0 },
                      }}
                      value={onsite_maximum_dollar_amount}
                      error={validationErrors.os_max_dollars}
                      readOnly={!editFlag}
                    />
                  </Grid>
                  <Grid item sm={12} md={1} />
                </Grid>
              </Grid>
            )}
          </Grid>

          <Panel
            title={
              profile.general_information.service_type === BillingServiceTypes.MECHANICAL
                ? t('Authorized Approvers of Mechanical Services')
                : t('Authorized Tire and Wheel Approvers')
            }
            subtitle={t('above Pre-Approval Limits')}
            spacing={0}
            control={
              !editFlag ? (
                <></>
              ) : (
                <Button
                  color="primary"
                  size="small"
                  style={{ marginTop: 4 }}
                  onClick={() => {
                    setModifiedFlag(true);
                    setContactDialog(true);
                  }}
                >
                  {t('Add')}
                </Button>
              )
            }
          >
            <DataGrid
              style={{ maxHeigth: '20rem', marginBottom: -1 }}
              showBorders
              noDataText={noContactAvailableMessage}
              dataSource={specialContacts.concat(
                contacts.filter((row) => {
                  if (typeof row === 'undefined' || row === null) return false;
                  if (isInSpecialContacts(row.hash_key)) {
                    return false;
                  }
                  if (specialContactsSet.has(row.hash_key)) {
                    return true;
                  }
                  if (
                    profile.general_information.profile_type === BillingProfileTypes.ers ||
                    profile.general_information.profile_type === BillingProfileTypes.ers_onsite
                  ) {
                    if (
                      (row.num_of_tires && row.num_of_tires > parseStringForNumberPurposes(ers_maximum_num_of_tires)) ||
                      (row.num_of_wheels && row.num_of_wheels > parseStringForNumberPurposes(ers_maxium_num_of_wheels))
                    ) {
                      return true;
                    }
                  }

                  if (
                    profile.general_information.profile_type === BillingProfileTypes.onsite ||
                    profile.general_information.profile_type === BillingProfileTypes.ers_onsite
                  ) {
                    if (
                      (row.num_of_tires &&
                        row.num_of_tires > parseStringForNumberPurposes(onsite_maximum_num_of_tires)) ||
                      (row.num_of_wheels &&
                        row.num_of_wheels > parseStringForNumberPurposes(onsite_maxium_num_of_wheels)) ||
                      (row.tire_dollar_amount &&
                        row.tire_dollar_amount > parseStringForNumberPurposes(onsite_maximum_dollar_amount))
                    ) {
                      return true;
                    }
                  }

                  return false;
                }),
              )}
            >
              <Scrolling mode="virtual" showScrollbar />
              <Column
                caption={t('Full Name')}
                allowFiltering={false}
                cellRender={({ data: row }: any) => {
                  return (
                    <Link
                      style={{
                        textDecoration: 'underline',
                        fontWeight: 700,
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        history.push(`${getBaseUrl()}/contacts/${row.hash_key.slice(2, row.hash_key.length)}/view`)
                      }
                    >
                      <b>
                        {row.first_name} {row.last_name}
                      </b>
                    </Link>
                  );
                }}
              />
              <Column
                caption={t('Tires')}
                allowFiltering={false}
                cellRender={({ data: row }: any) =>
                  row.specialSauce && editFlag ? (
                    <TextField
                      type="number"
                      InputProps={{ inputProps: { min: 0 }, readOnly: !editFlag }}
                      value={row.num_of_tires ? row.num_of_tires : ''}
                      key={`tnot${row.hash_key}`}
                      name={`approvalsTiresInput${row.hash_key}`}
                      onChange={(event: ChangeEvent<any>) => {
                        const cont = specialContacts.find((c) => c.hash_key === row.hash_key);
                        // textInput.current = document.activeElement;
                        if (cont) {
                          setModifiedFlag(true);
                          cont.num_of_tires = event.target.value;
                          setSpecialContacts(specialContacts);
                          forceUpdate();
                        }
                      }}
                      readOnly={!editFlag}
                    />
                  ) : (
                    row.num_of_tires
                  )
                }
              />
              <Column
                caption={t('Wheels')}
                allowFiltering={false}
                cellRender={({ data: row }: any) =>
                  row.specialSauce && editFlag ? (
                    <TextField
                      type="number"
                      InputProps={{ inputProps: { min: 0 }, readOnly: !editFlag }}
                      value={row.num_of_wheels ? row.num_of_wheels : ''}
                      key={`tnot1${row.hash_key}`}
                      name={`approvalsWheelsInput${row.hash_key}`}
                      onChange={(event: ChangeEvent<any>) => {
                        const cont = specialContacts.find((c) => c.hash_key === row.hash_key);
                        // textInput.current = document.activeElement;
                        if (cont) {
                          setModifiedFlag(true);
                          cont.num_of_wheels = event.target.value;
                          setSpecialContacts(specialContacts);
                          forceUpdate();
                        }
                      }}
                      readOnly={!editFlag}
                    />
                  ) : (
                    row.num_of_wheels
                  )
                }
              />
              <Column
                visible={profile.general_information.profile_type !== BillingProfileTypes.ers}
                caption={t('Max Dollars for Tire Service')}
                allowFiltering={false}
                cellRender={({ data: row }: any) =>
                  row.specialSauce && editFlag ? (
                    <TextField
                      InputProps={{ inputProps: { min: 0 }, readOnly: !editFlag }}
                      type="number"
                      readOnly={profile.general_information.profile_type === BillingProfileTypes.ers && !editFlag}
                      value={row.tire_dollar_amount ? row.tire_dollar_amount : ''}
                      key={`tnot2${row.hash_key}`}
                      name={`approvalsMaxDollarsInput${row.hash_key}`}
                      onChange={(event: ChangeEvent<any>) => {
                        const cont = specialContacts.find((c) => c.hash_key === row.hash_key);
                        // textInput.current = document.activeElement;
                        if (cont) {
                          setModifiedFlag(true);
                          cont.tire_dollar_amount = event.target.value;
                          setSpecialContacts(specialContacts);
                          forceUpdate();
                        }
                      }}
                    />
                  ) : (
                    row.tire_dollar_amount
                  )
                }
              />
              <Column
                caption={t('Work Phone')}
                allowFiltering={false}
                cellRender={(row: any) => (row.data.work_phone ? row.data.work_phone : '-')}
              />
              <Column
                caption={t('Cell Phone')}
                allowFiltering={false}
                cellRender={(row: any) => (row.data.cell_phone ? row.data.cell_phone : '-')}
              />
              <Column
                caption={t('Email Address')}
                allowFiltering={false}
                cellRender={(row: any) => (row.data.email_address ? row.data.email_address : '-')}
              />
              <Column
                caption=""
                allowFiltering={false}
                cellRender={({ data: row }: any) =>
                  row.specialSauce && editFlag ? (
                    <DeleteIcon
                      onClick={() => {
                        handleDeleteContact(row);
                      }}
                    />
                  ) : (
                    ''
                  )
                }
              />
            </DataGrid>
            <Dialog
              open={contactDialog}
              onClose={() => {
                setContactDialog(false);
              }}
              aria-labelledby="form-dialog-title"
              maxWidth="xl"
              fullWidth
            >
              <DialogTitle id="form-dialog-title">{t('All Contacts')}</DialogTitle>
              <DialogContent>
                <DialogContentText />
                <DataGrid
                  noDataText={noContactAvailableMessage}
                  showBorders
                  style={{ maxHeight: '20rem' }}
                  dataSource={contacts.filter((c) => {
                    if (c === null || typeof c === 'undefined') return false;

                    const inSpecial = specialContacts.find((s) => s.hash_key === c.hash_key);
                    if (inSpecial) {
                      return false;
                    }

                    if (specialContactsSet.has(c.hash_key)) {
                      return false;
                    }
                    if (
                      profile.general_information.profile_type === BillingProfileTypes.ers ||
                      profile.general_information.profile_type === BillingProfileTypes.ers_onsite
                    ) {
                      if (
                        (c.num_of_tires && c.num_of_tires > parseStringForNumberPurposes(ers_maximum_num_of_tires)) ||
                        (c.num_of_wheels && c.num_of_wheels > parseStringForNumberPurposes(ers_maxium_num_of_wheels))
                      ) {
                        return false;
                      }
                    }

                    if (
                      profile.general_information.profile_type === BillingProfileTypes.onsite ||
                      profile.general_information.profile_type === BillingProfileTypes.ers_onsite
                    ) {
                      if (
                        (c.num_of_tires &&
                          c.num_of_tires > parseStringForNumberPurposes(onsite_maximum_num_of_tires)) ||
                        (c.num_of_wheels &&
                          c.num_of_wheels > parseStringForNumberPurposes(onsite_maxium_num_of_wheels)) ||
                        (c.tire_dollar_amount &&
                          c.tire_dollar_amount > parseStringForNumberPurposes(onsite_maximum_dollar_amount))
                      ) {
                        return false;
                      }
                    }
                    return true;
                  })}
                  onRowClick={({ data }) => {
                    const sC = specialContacts;
                    const newData: any = {};
                    Object.assign(newData, data);
                    newData.specialSauce = true;
                    sC.push(newData);
                    setSpecialContacts(sC);
                    setContactDialog(false);
                  }}
                >
                  <Scrolling mode="virtual" />
                  <Column
                    caption={t('Full Name')}
                    allowFiltering={false}
                    cellRender={(row: any) =>
                      row.data.first_name ? `${row.data.first_name} ${row.data.last_name}` : '-'
                    }
                  />
                  <Column
                    caption={t('Tires')}
                    allowFiltering={false}
                    cellRender={(row: any) => (row.data.num_of_tires ? row.data.num_of_tires : '-')}
                  />
                  <Column
                    caption={t('Wheels')}
                    allowFiltering={false}
                    cellRender={(row: any) => (row.data.num_of_wheels ? row.data.num_of_wheels : '-')}
                  />
                  <Column
                    caption={t('Max Dollars for Tire Service')}
                    allowFiltering={false}
                    cellRender={(row: any) => (row.data.tire_dollar_amount ? row.data.tire_dollar_amount : '-')}
                  />
                  <Column
                    caption={t('Work Phone')}
                    allowFiltering={false}
                    cellRender={(row: any) => (row.data.work_phone ? row.data.work_phone : '-')}
                  />
                  <Column
                    caption={t('Cell Phone')}
                    allowFiltering={false}
                    cellRender={(row: any) => (row.data.cell_phone ? row.data.cell_phone : '-')}
                  />
                  <Column
                    caption={t('Email Address')}
                    allowFiltering={false}
                    cellRender={(row: any) => (row.data.email_address ? row.data.email_address : '-')}
                  />
                </DataGrid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setContactDialog(false);
                  }}
                  color="default"
                  variant="default"
                >
                  {t('Ok')}
                </Button>
              </DialogActions>
            </Dialog>
          </Panel>
        </Grid>
      );
    }
  }

  return (
    <Panel title={t('Pre Approval Limits')} style={{ marginBottom: 15 }}>
      {finalChild}
    </Panel>
  );
}
