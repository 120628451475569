import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { IconButton, Select, Switch, TextField } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { getOptions } from '@michelin/select-options-provider';
import Autocomplete from 'components/Autocomplete';
import { BillingTireOffInstalled, BillingTireRequestedDetails, BillingTireRequestedInstalledDetails } from 'components/Billing/Preferences/query';
import { Control, Controller, useFormContext } from 'react-hook-form';
import uuid from 'uuid';
import { BillingProfileContextData } from '../DetailPage';


interface TireItemProps {
  row: BillingTireRequestedInstalledDetails;
  index: number;
  control: Control<BillingProfileContextData, object>;
  editFlag: boolean;
  bothAssigned: boolean;
  onUpdate: (index: number, data: BillingTireRequestedInstalledDetails) => void;
  onRemove: (index: number) => void;
}

export function TireItem({ row, index, control, editFlag, bothAssigned, onUpdate, onRemove }: TireItemProps) {
  const { t } = useTranslation();
  const { setValue } = useFormContext<BillingProfileContextData>();
  const [other, setOther] = useState<string | null>(row.other || null);

  const remove = (index: number) => {
    onRemove(index);
  };

  return (
    <React.Fragment>
      <Grid item sm={12} md={2} style={{ display: 'flex', alignItems: 'flex-end' }}>
        <Controller
          name={`profile.tire_details.${index}.off_installed`}
          control={control}
          render={(item) => (
            <Switch
              checked={item.field.value === BillingTireOffInstalled.INSTALLED_TIRES}
              disabled={!editFlag || bothAssigned}
              label=""
              checkedLabel={t('Installed')}
              uncheckedLabel={t('Off')}
              labelPosition="top"
              inputProps={{
                'aria-label': 'installed-switch',
              }}
              onChange={(e) => {
                const newValue = e.target.checked
                  ? BillingTireOffInstalled.INSTALLED_TIRES
                  : BillingTireOffInstalled.OFF_TIRES;
                const tireItem = { ...row, off_installed: newValue };
                onUpdate(index, tireItem);
              }}
            />
          )}
        />
      </Grid>
      <Grid item sm={12} md={5} style={{ display: 'flex', alignItems: 'flex-end' }}>
        <div style={{ width: '100%' }}>
          <Controller
            name={`profile.tire_details.${index}.requested_details`}
            control={control}
            rules={{ required: true }}
            render={(item) => (
              <Autocomplete
                id="requested-details-autocomplete"
                data-testid="requested-details-test"
                multiple
                variant="standard"
                label={t('Requested Details')}
                disableClearable
                disableCloseOnSelect
                placeholder=""
                onChange={(value) => {
                  const tireItem = {
                    ...row,
                    requested_details: value as BillingTireRequestedDetails[],
                    other: other || '',
                  };
                  onUpdate(index, tireItem);
                }}
                suggestions={getOptions('tire_requested_details')}
                values={row.requested_details}
                error={!item.field.value || item.field.value.length === 0}
                readOnly={!editFlag}
              />
            )}
          />
        </div>
      </Grid>
      <Grid item sm={12} md={2} style={{ display: 'flex', alignItems: 'flex-end' }}>
        <TextField
          value={other}
          label={t('Other')}
          inputProps={{
            'aria-label': 'other-textfield',
          }}
          readOnly={!editFlag || !row.requested_details?.includes(BillingTireRequestedDetails.OTHER)}
          onChange={(e) => {
            setOther(e.target.value);
            setValue(`profile.wheel_details.${index}.other`, other || '', { shouldDirty: true });
          }}
        />
      </Grid>
      <Grid item sm={12} md={2} style={{ display: 'flex', alignItems: 'flex-end' }}>
        {row.requested_details && row.requested_details.includes(BillingTireRequestedDetails.TIRE_CASING_TYPE) && (
          <Controller
            name={`profile.tire_details.${index}.billing_tire_casing_age`}
            control={control}
            render={(item) => (
              <Select
                id={item.field.name}
                name="billing_tire_casing_age"
                data-testid="casingOptionsSelect"
                style={{ minWidth: '100px', width: '100%' }}
                label={t('Casing Details')}
                value={item.field.value}
                readOnly={!editFlag}
                options={getOptions('tire_casing_age')}
                onChange={(e) => {
                  const tireItem = { ...row, billing_tire_casing_age: (e.target.value as string) || '' };
                  onUpdate(index, tireItem as BillingTireRequestedInstalledDetails);
                }}
                inputProps={{
                  'aria-label': 'casing-select',
                }}
              />
            )}
          />
        )}
      </Grid>
      <Grid item sm={12} md={1} style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          color="primary"
          size="small"
          variant="default"
          disabled={!editFlag}
          onClick={() => remove(index)}
          aria-label="remove"
        >
          <Delete fontSize="small" />
        </IconButton>
      </Grid>
    </React.Fragment>
  );
}

interface TireListProps {
  list: Array<BillingTireRequestedInstalledDetails>;
  control: Control<BillingProfileContextData, object>;
  editFlag: boolean;
  bothAssigned: boolean;
  clickedAdd: boolean;
  setClickedAdd: (clickedAdd: boolean) => void;
  onAdd: (data: BillingTireRequestedInstalledDetails) => void;
  onUpdate: (index: number, data: BillingTireRequestedInstalledDetails) => void;
  onRemove: (index: number) => void;
}

export function TireList({
  list,
  control,
  editFlag,
  bothAssigned,
  clickedAdd,
  setClickedAdd,
  onAdd,
  onUpdate,
  onRemove,
}: TireListProps) {
  const { t } = useTranslation();
  useEffect(() => {
    if (clickedAdd) add();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedAdd]);

  const add = () => {
    setClickedAdd(false);
    if (!list || list.length === 0) {
      list = [];
      onAdd({
        id: uuid.v4(),
        requested_details: [],
        off_installed: BillingTireOffInstalled.OFF_TIRES,
      });
      return;
    }
    if (!list[list.length - 1].requested_details || list[list.length - 1]?.requested_details?.length === 0) return;
    if (list) {
      const lastTireDetails = list[list.length - 1];
      if (lastTireDetails && lastTireDetails.requested_details && lastTireDetails.requested_details.length > 0) {
        if (
          lastTireDetails.off_installed === BillingTireOffInstalled.OFF_TIRES ||
          lastTireDetails.off_installed === undefined
        ) {
          onAdd({
            id: uuid.v4(),
            requested_details: [],
            off_installed: BillingTireOffInstalled.INSTALLED_TIRES,
          });
        } else {
          onAdd({
            id: uuid.v4(),
            requested_details: [],
            off_installed: BillingTireOffInstalled.OFF_TIRES,
          });
        }
        return;
      }
    }
  };

  return (
    <>
      {!list || list.length === 0 ? (
        <Typography style={{ color: '#999999', width: '100%', textAlign: 'center' }}>{t('No data...')}</Typography>
      ) : (
        <>
          {list.map((row, index) => (
            <TireItem
              key={row.id}
              row={row}
              index={index}
              control={control}
              editFlag={editFlag}
              bothAssigned={bothAssigned}
              onUpdate={onUpdate}
              onRemove={onRemove}
            />
          ))}
        </>
      )}
    </>
  );
}