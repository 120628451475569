import React from 'react';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { WatchLater } from '@material-ui/icons';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { Button, WithDialogsProps, withDialogs } from '@michelin/acid-components';
import { WithTranslation, withTranslation } from '@michelin/central-provider';
import moment from 'moment';
import 'moment/locale/en-ca';
import 'moment/locale/fr-ca';
import { HoursOfOperation } from '../Types';
import { getTimeString } from '../Util';
import { getDates } from './Types';

interface HoursOfOperationProps extends WithTranslation, WithDialogsProps {
  hoursOfOperations?: HoursOfOperation;
  holidays?: string | null;
  onChange?: (hoursOfOperations: HoursOfOperation) => void;
  mode?: string;
  differentialUpdate?: boolean;
}

interface HoursOfOperationState {
  beginTime: Date;
  endTime: Date;
  hoursOfOperations: HoursOfOperation;
  hoursOfOperationsSet: any;
  selectedDates: any;
}

class HoursOfOperationEdit extends React.Component<HoursOfOperationProps, HoursOfOperationState> {
  private originalData: HoursOfOperation;

  constructor(props: HoursOfOperationProps) {
    super(props);
    this.originalData = JSON.parse(JSON.stringify(props.hoursOfOperations));
    this.state = {
      beginTime: new Date('2014-08-18T08:00:00'),
      endTime: new Date('2014-08-18T17:00:00'),
      hoursOfOperations: JSON.parse(JSON.stringify(props.hoursOfOperations)),
      selectedDates: {},
      hoursOfOperationsSet: {},
    };
  }

  shouldComponentUpdate(nextProps: HoursOfOperationProps, nextState: HoursOfOperationState) {
    if (this.props.hoursOfOperations !== nextProps.hoursOfOperations) {
      this.setState({ hoursOfOperations: JSON.parse(JSON.stringify(nextProps.hoursOfOperations)) });
    }
    return true;
  }

  render() {
    const { t } = this.props;
    moment.locale(this.props.i18n.language.replace('_', '-').toLowerCase());

    const dates = getDates(this.props.holidays);
    const hoo: any = this.state.hoursOfOperations;
    const hooSet: any = this.state.hoursOfOperationsSet;
    const closed = (
      <span style={{ color: this.props.differentialUpdate ? '#888' : '#999', fontStyle: 'italic' }}>
        {this.props.mode === 'contact' ? '-' : t('Closed')}
      </span>
    );
    const dontUpdate = <span style={{ color: '#BBB' }}>{t('(do not modify)')}</span>;

    return (
      <Grid container spacing={2} style={{ marginBottom: 20, textAlign: 'center', marginTop: -24 }}>
        <Grid item xs={6}>
          <KeyboardTimePicker
            margin="normal"
            okLabel={t('Ok')}
            cancelLabel={t('Cancel')}
            id="begin-time-picker"
            label={t('From')}
            value={this.state.beginTime}
            onChange={(date: any) => this.setState({ beginTime: date })}
            keyboardIcon={<WatchLater />}
            KeyboardButtonProps={{ 'aria-label': t('Change time') }}
          />
        </Grid>
        <Grid item xs={6}>
          <KeyboardTimePicker
            margin="normal"
            id="end-time-picker"
            okLabel={t('Ok')}
            cancelLabel={t('Cancel')}
            label={t('To')}
            value={this.state.endTime}
            onChange={(date: any) => this.setState({ endTime: date })}
            keyboardIcon={<WatchLater />}
            KeyboardButtonProps={{ 'aria-label': t('Change time') }}
          />
        </Grid>
        <Grid xs={4} item>
          <Button
            id="applyButtonWorkHours"
            style={{ zoom: 0.8, width: '100%', minHeight: 50 }}
            onClick={this.applyChanges.bind(this)}
          >
            {t('Apply')}
          </Button>
        </Grid>
        <Grid xs={4} item>
          <Button style={{ zoom: 0.8, width: '100%', minHeight: 50 }} onClick={this.clearHours.bind(this)}>
            {this.props.mode === 'contact' ? t('Clear Hrs') : t('Set Closed')}
          </Button>
        </Grid>
        <Grid xs={4} item>
          <Button style={{ zoom: 0.8, width: '100%', minHeight: 50 }} onClick={this.resetChanges.bind(this)}>
            {t('Reset All')}
          </Button>
        </Grid>
        <Grid xs={12} item style={{ marginBottom: 5 }}>
          {this.props.mode === 'contact'
            ? t('Select days to apply selected work hours')
            : t('Select days to apply selected hours of operation')}
        </Grid>
        {dates.map((d) => (
          <Grid container key={d.key}>
            <Grid item xs={6}>
              <FormControlLabel
                style={{ textAlign: 'left', width: 175, margin: 0 }}
                control={
                  <Checkbox
                    style={{
                      height: 10,
                      width: 10,
                      marginLeft: 20,
                      marginRight: 10,
                    }}
                    id={d.key}
                    onChange={this.onDateCheck.bind(this)}
                    color="primary"
                    checked={this.state.selectedDates[d.key] || false}
                  />
                }
                label={<span style={{ fontSize: '0.8em' }}>{t(d.label)}</span>}
              />
            </Grid>
            <Grid item xs={6}>
              {getTimeString(hoo[d.key]) || (this.props.differentialUpdate && !hooSet[d.key] && dontUpdate) || closed}
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  }

  onDateCheck(e: React.ChangeEvent, value: boolean) {
    const sl = this.state.selectedDates;
    sl[e.target.id] = value;
    this.setState({ selectedDates: sl });
  }

  applyChanges() {
    const hoo: any = this.state.hoursOfOperations;
    const hooSet: any = this.state.hoursOfOperationsSet;
    const dates = getDates(this.props.holidays);
    const { t } = this.props;
    let counter = 0;
    const begin: string = moment(this.state.beginTime).format('HH:mm');
    const end: string = moment(this.state.endTime).format('HH:mm');

    if (begin.length !== 5 || end.length !== 5) {
      this.props.alertDialog(t('You must specify both (begin and end) hours.'), t('Please check the hour fields'));
      return;
    }

    dates.forEach((d) => {
      if (!this.state.selectedDates[d.key]) return;
      counter++;
      if (!hoo[d.key]) hoo[d.key] = {};
      hoo[d.key].begin = begin;
      hoo[d.key].end = end;
      hooSet[d.key] = true;
    });
    if (counter === 0) {
      this.props.alertDialog(
        t('You must select at least one day to set dates on.'),
        t('Please select at least one day'),
      );
      return;
    }
    this.setState({ selectedDates: {}, hoursOfOperationsSet: hooSet });
    setTimeout(() => {
      this.props.onChange && this.props.onChange(this.createUntouchedHours(this.state.hoursOfOperations));
    });
  }

  resetChanges() {
    const hoursOfOperations = JSON.parse(JSON.stringify(this.originalData));
    this.setState({ hoursOfOperations, selectedDates: {}, hoursOfOperationsSet: {} });
    this.forceUpdate();
    setTimeout(() => {
      this.props.onChange && this.props.onChange(this.createUntouchedHours(hoursOfOperations));
    });
  }

  clearHours() {
    const hoo: any = this.state.hoursOfOperations;
    const hooSet: any = this.state.hoursOfOperationsSet;
    const dates = getDates(this.props.holidays);
    const { t } = this.props;
    let counter = 0;
    dates.forEach((d) => {
      if (!this.state.selectedDates[d.key]) return;
      counter++;
      if (hoo[d.key]) delete hoo[d.key];
      hooSet[d.key] = true;
    });
    if (counter === 0) {
      this.props.alertDialog(
        t('You must select at least one day to mark as closed.'),
        t('Please select at least one day'),
      );
      return;
    }
    this.setState({ selectedDates: {}, hoursOfOperationsSet: hooSet });
    this.props.onChange && this.props.onChange(this.createUntouchedHours(this.state.hoursOfOperations));
  }

  createUntouchedHours(hoo: HoursOfOperation) {
    if (!this.props.differentialUpdate) return hoo;
    const res: HoursOfOperation = {};
    for (const day in this.state.hoursOfOperationsSet) {
      // @ts-ignore
      res[day] = hoo[day] || null;
    }
    return res;
  }
}

export default withTranslation()(withDialogs(HoursOfOperationEdit));
