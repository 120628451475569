import React from 'react';
import { Grid, Popover, Theme, createStyles, makeStyles } from '@material-ui/core';
import { Button } from '@michelin/acid-components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
    margin: {
      margin: 2,
    },
    summaryContainer: {
      borderRadius: '5px',
      width: '370px',
      padding: '5px',
    },
  }),
);

interface IProps {
  detail: JSX.Element | null;
  text: any;
  color?: 'primary' | 'secondary' | 'warning' | 'inherit';
}

export default function ButtonPopover({ detail, text, color }: IProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (!detail) return null;
  return (
    <>
      <Button
        size="small"
        color={color || 'primary'}
        style={{ cursor: 'pointer', textDecoration: 'none' }}
        onClick={(event: any) => handlePopoverOpen(event)}
        onMouseOver={(event: any) => handlePopoverOpen(event)}
        onMouseLeave={handlePopoverClose}
      >
        {text}
      </Button>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Grid container className={classes.summaryContainer}>
          <Grid item xs={12}>
            {detail}
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}
