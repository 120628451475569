import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { Button, LoadingBounce, Select } from '@michelin/acid-components';
import { WithTranslation, withTranslation } from '@michelin/central-provider';
import { RecursiveCustomerFleetAccount, auth } from 'Auth';
import { queryCustomerList } from 'components/Contact/utils';
import DataGrid, { Column, FilterRow, Scrolling, SearchPanel, Selection } from 'devextreme-react/data-grid';
import theme from 'theme';
import { BillingProfileLocation } from '../query';
import { getAllLocations } from '../utils';

const filterStatuses = ['all', 'assigned', 'unassigned'];

const optionsFilterStatuses = new Array<Select.Option>(
  { value: 'all', label: 'All' },
  { value: 'assigned', label: 'Assigned' },
  { value: 'unassigned', label: 'Unassigned' },
);

interface AssignShipToDialogState {
  loaded: boolean;
  selectedRows: string[];
  assignedSTHashKey: string | undefined;
  filterStatus: string;
}

interface AssignShipToDialogProps extends WithTranslation {
  selectedSTHashKey: string | undefined;
  onClose: () => void;
  onSTAssigned: (st_hash_key: string | undefined, customer_obj: any | undefined) => void;
  assignLocationDialogOpen: boolean;
}

// todo - need to add the attributes "assigned": "assigned"
// todo - and "assigned": "unassigned" to the appropriate records for filtering to work

// todo - assigned should appear at the top - don't do initial sort using table - sort yourself

// todo - in view mode should only show the assigned records - not the unassigned records

class AssignShipToDialog extends React.Component<AssignShipToDialogProps, AssignShipToDialogState> {
  locationsMap: Map<string, BillingProfileLocation>;

  _dataGrid: React.RefObject<DataGrid>;

  constructor(props: AssignShipToDialogProps) {
    super(props);
    this._dataGrid = React.createRef();
    this.state = {
      loaded: false,
      selectedRows: [],
      assignedSTHashKey: props.selectedSTHashKey,
      filterStatus: filterStatuses[0],
    };
    this.locationsMap = new Map<string, BillingProfileLocation>();

    this.getTotalLocations();
  }

  async getAllResults(listOfShiptos: any) {
    const locationsResolvedPromises: Array<Promise<any>> = [];
    listOfShiptos.forEach((shipTo: any) => {
      const queryPromise = new Promise(async (res, rej) => {
        if (auth && auth.apolloClient) {
          const data = await auth.apolloClient.query({
            query: queryCustomerList,
            variables: { hash_key: `1~${shipTo}`, range_key: 'v0_customer' },
          });
          res(data);
        }
      });
      locationsResolvedPromises.push(queryPromise);
    });
    const result = await Promise.all(locationsResolvedPromises);
    return result;
  }

  async getTotalLocations() {
    if (!auth.apolloClient) {
      return;
    }

    try {
      const allLocations = await getAllLocations(auth.getMainCustomerRelationship());
      if (allLocations) {
        allLocations.forEach((location) => {
          if (location.customer_type === 'ST') {
            this.locationsMap.set(location.hash_key, location);
          }
        });
      }
      this.setAssignedLocations();
      this.setState({ loaded: true });
    } catch (e) {
      console.log(e);
    }
  }

  getLocationsMap(customerList: RecursiveCustomerFleetAccount) {
    if (customerList.customer && customerList.customer.customer_type === 'ST') {
      this.locationsMap.set(customerList.customer.hash_key, {
        ...customerList.customer,
        assigned: false,
      } as BillingProfileLocation);
    }
    if (!customerList.childAssets) return;
    customerList.childAssets.forEach((ca) => this.getLocationsMap(ca));
  }

  setAssignedLocations() {
    if (this.state.assignedSTHashKey) {
      this.state.selectedRows.push(this.state.assignedSTHashKey);
    }
  }

  onSelectionChange = (selection: any) => {
    if (this.state.selectedRows.length === 1) this.state.selectedRows.pop();

    this.state.selectedRows.push(selection.currentSelectedRowKeys);
  };

  handleDialogClose = () => {
    this.props.onClose();
  };

  handleAssignSelections = () => {
    const selectedST = this.state.selectedRows.length === 1 ? this.state.selectedRows[0][0] : undefined;
    const rowData = this.locationsMap.get(selectedST || '');
    this.props.onSTAssigned(selectedST, rowData || undefined);
    this.props.onClose();
  };

  TitleLink = () => (
    <Grid container alignItems="baseline">
      <Grid item xs={12} md={6}>
        {this.props.t('Select a Ship To Location')}
      </Grid>
      <Grid item xs={12} md={6} style={{ textAlign: 'right', fontSize: '.8em' }}>
        {this.props.t('{{amountOfRows}} selected', { amountOfRows: this.state.selectedRows.length })}
      </Grid>
    </Grid>
  );

  onFilterValueChanged = (event?: React.ChangeEvent<HTMLSelectElement> | undefined, value?: string | undefined) => {
    let dg: any;
    let dgi: any;
    if (this._dataGrid !== undefined && this._dataGrid !== null) {
      dg = this._dataGrid.current;
      if (dg !== null && dg !== undefined) {
        dgi = dg.instance;
      }
    }

    if (value) {
      if (value === 'all') {
        dgi.clearFilter();
      } else {
        dgi.filter(['assigned', '=', value]);
      }
      this.setState({ filterStatus: value });
    }
  };

  render() {
    return (
      <>
        <Dialog
          fullWidth
          maxWidth="xl"
          onClose={() => this.handleDialogClose()}
          aria-labelledby="customized-dialog-title"
          open={this.props.assignLocationDialogOpen}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogTitle id="form-dialog-title">
            <this.TitleLink />
          </DialogTitle>

          <DialogContent dividers>
            {this.state.loaded === false ? (
              <LoadingBounce />
            ) : (
              <Grid container>
                <Grid item xs={6} md={6}>
                  <Select
                    style={{ zIndex: 1 }}
                    options={optionsFilterStatuses}
                    value={this.state.filterStatus}
                    onChange={(event: any) => {
                      this.onFilterValueChanged(event, event.target.value);
                    }}
                  />
                </Grid>

                <Grid item md={12}>
                  <DataGrid
                    ref={this._dataGrid}
                    dataSource={Array.from(this.locationsMap.values())}
                    allowColumnReordering
                    allowColumnResizing
                    columnAutoWidth
                    onSelectionChanged={(selection: any) => this.onSelectionChange(selection)}
                    headerFilter={{ visible: true }}
                    showBorders
                    defaultSelectedRowKeys={this.state.selectedRows}
                    keyExpr="hash_key"
                  >
                    <FilterRow visible />

                    {/* {this.props.isEditMode &&  */}
                    <Selection mode="single" showCheckBoxesMode="always" />
                    {/* } */}

                    <SearchPanel visible highlightCaseSensitive />
                    <Scrolling mode="infinite" />

                    <Column
                      caption={this.props.t('Assigned')}
                      dataField="assigned"
                      visible={false}
                      allowSearch={false}
                    />
                    <Column
                      caption={this.props.t('Michelin Id')}
                      dataField="customer_number"
                      allowSearch
                      allowFiltering={false}
                    />
                    <Column
                      caption={this.props.t('Terminal Id')}
                      dataField="extrnl_cust_id"
                      allowSearch
                      allowFiltering={false}
                    />
                    <Column
                      caption={this.props.t('Location Name')}
                      dataField="customer_name"
                      allowSearch
                      allowFiltering={false}
                    />
                    <Column
                      caption={this.props.t('Address 1')}
                      dataField="customer_addr1"
                      allowSearch
                      allowFiltering={false}
                    />
                    <Column
                      caption={this.props.t('Address 2')}
                      dataField="customer_addr2"
                      allowSearch
                      allowFiltering={false}
                    />
                    <Column
                      caption={this.props.t('City')}
                      dataField="customer_city"
                      allowSearch
                      allowFiltering={false}
                    />
                    <Column caption={this.props.t('State')} dataField="customer_state" allowSearch allowFiltering />
                    <Column
                      caption={this.props.t('Zip Code')}
                      dataField="customer_zip"
                      allowSearch
                      allowFiltering={false}
                    />
                    <Column
                      caption={this.props.t('Location Type')}
                      dataField="customer_type"
                      allowSearch
                      allowFiltering={false}
                    />
                  </DataGrid>
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions style={{ margin: 0, padding: theme.spacing(1) }}>
            <Button
              size="small"
              color="success"
              style={{ margin: '8px' }}
              onClick={() => this.handleAssignSelections()}
            >
              {this.props.t('Update')}
            </Button>
            <Button size="small" color="default" style={{ margin: '8px' }} onClick={() => this.handleDialogClose()}>
              {this.props.t('Close')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default withTranslation()(AssignShipToDialog);
