import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Grid } from '@material-ui/core';
import { ArrowBack, Edit, Save, Timelapse } from '@material-ui/icons';
import {
  AccountAvatar,
  Container,
  Fab,
  GlobalActions,
  LoadingBounce,
  Panel,
  PreventTransitionPrompt,
  Switch,
  useDialogs,
} from '@michelin/acid-components';
import { usePermissions, useTranslation } from '@michelin/central-provider';
import { BOVehicleDetailsForm, BOVehicleDetailsFormProvider } from '@michelin/core-profile-ui-tools';
import { FormHandles } from '@michelin/core-profile-ui-tools/build/components/Form/Form';
import { UFOVehicleDetailsFormData } from '@michelin/core-profile-ui-tools/build/components/UFOVehicleDetailsForm/types';
import { CountryCode, VehicleType } from '@michelin/core-profile-ui-tools/build/types';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { useSnackbar } from 'notistack';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { useHistory, useParams } from 'react-router-dom';
import { getBaseUrl, getHomeUrl } from '../../prefs-and-service-offers';
import { Customer } from '../Location';
import LocationAssignments from '../LocationAssignments';
import { AssigneeCustomerType, getCustomers } from '../LocationAssignments/queries';
import { PlainMessage } from '../Messages';
import { vehiclesDetailAPIType } from '../Types/APITypes';
import { scrollToDisplayFirstError } from '../Util';
import { useAPICall } from '../Util/useAPICall';
import { VehicleUpdateLink } from './ChangeLog/UpdateLink';
import { VehiclesDetailProps, saveVehicleAPICall } from './Utils';
import { useGetAssetTypes, useGetMakes, useGetModels, useGetStates, useGetYears } from './hooks/vehiclesInformation';

function CommercialVehiclesDetail(props: VehiclesDetailProps) {
  const { t } = useTranslation();
  const history = useHistory();
  let { vehicleId } = useParams<{ [key: string]: string }>();

  const ref = useRef<FormHandles>(null);

  const getYears = useGetYears('commercial');
  const getAssetTypes = useGetAssetTypes();
  const getMakes = useGetMakes('commercial');
  const getModels = useGetModels('commercial');
  const getStates = useGetStates();
  const permissions = usePermissions();

  const { enqueueSnackbar } = useSnackbar();

  const readPermission = permissions.allowsAction('vehicles.read');
  const addPermission = permissions.allowsAction('vehicles.create');
  const updatePermission = permissions.allowsAction('vehicles.update');
  const commercialPermission = permissions.allowsAction('vehicles.commercial');

  vehicleId = props.action !== 'add' ? vehicleId : '';

  if (permissions.location?.customer_country.toLowerCase() !== 'us' || !commercialPermission) {
    // Has no permissions manage vehicles
    enqueueSnackbar('User has no permissions to manage vehicles.', { variant: 'warning' });
    history.push(getHomeUrl());
  }

  if (props.action === 'edit' && !updatePermission) {
    // Has no permissions to edit
    enqueueSnackbar(t('User has no permissions to modify vehicles.'), { variant: 'warning' });
    history.push('view');
  } else if (props.action === 'view' && !readPermission) {
    // Has no permissions to read
    enqueueSnackbar(t('User has no permissions to read vehicles.'), { variant: 'warning' });
    history.push(`${getBaseUrl()}/commercial-vehicles`);
  } else if (props.action === 'add' && !addPermission) {
    // Has no permissions to create
    enqueueSnackbar(t('User has no permissions to create vehicles.'), { variant: 'warning' });
    history.push(`${getBaseUrl()}/commercial-vehicles`);
  }

  // Detail
  const [originalData, setOriginalData] = useState<VehicleType>({} as VehicleType);
  const [assignedLocation, setAssignedLocation] = useState<any>(
    props.action === 'add' ? permissions.location : ({} as Customer),
  );
  const [originalAssignedLocation, setOriginalAssignedLocation] = useState<any>(
    props.action === 'add' ? permissions.location : ({} as Customer),
  );
  const [loading, setLoading] = useState<boolean>(props.action !== 'add');
  const [modified, setModified] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [reloads, setReloads] = useState(0);

  const { errorDialog, confirmDialog } = useDialogs();

  const [locationAssignmentError, setLocationAssignmentError] = useState<boolean>(false);

  // Load Vehicle Data
  const vehiclesDetail: any = useAPICall(
    vehiclesDetailAPIType(permissions?.location?.customer_number || '', vehicleId),
    { reloads },
  );

  useEffect(() => {
    async function fetchData() {
      if (vehicleId && !vehiclesDetail.loading && vehiclesDetail.data) {
        if (vehiclesDetail.error) {
          enqueueSnackbar(t(vehiclesDetail.error), { variant: 'error' });
          history.push(`${getBaseUrl()}/commercial-vehicles`);
          return;
        }

        setOriginalData(vehiclesDetail.data);
        setLoading(false);
        const custNumber: string = (
          vehiclesDetail.data.st ||
          vehiclesDetail.data.bt ||
          vehiclesDetail.data.ho ||
          vehiclesDetail.data.pc
        ).toString();
        if (custNumber === permissions?.location?.customer_number) {
          setAssignedLocation(permissions.location);
          setOriginalAssignedLocation(permissions.location);
        } else {
          const cust = await getCustomers([custNumber]);
          const c = cust.pop() || ({} as Customer);
          setAssignedLocation(c);
          setOriginalAssignedLocation(c);
        }
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehiclesDetail]);

  useEffect(() => {
    if (saving && !modified) setReloads(reloads + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saving, modified]);

  /* ************************** */
  /* ************************** */
  /* Data Input and Validation  */
  /* ************************** */
  /* ************************** */

  function showValidationErrors(
    errors: FieldErrors<UFOVehicleDetailsFormData> = {} as FieldErrors<UFOVehicleDetailsFormData>,
  ) {
    const msg: string[] = [];

    if (errors.year) msg.push(`• ${t('Year is required')}`);
    if (errors.make_id) msg.push(`• ${t('Make is required')}`);
    if (errors.model_id) msg.push(`• ${t('Model is required')}`);
    if (errors.tire_size_id) msg.push(`• ${t('Tire Size is required')}`);
    if (errors.fleet_id_no) msg.push(`• ${t('Vehicle Unit Number is required')}`);
    if (errors.vin) msg.push(`• ${t('VIN is required and should be 17 characters long')}`);
    if (errors.license_plate) msg.push(`• ${t('License Plate is required and should be 7 characters or shorter')}`);
    if (errors.license_state_abbreviation) msg.push(`• ${t('License Plate State is required')}`);
    if (!assignedLocation.customer_number) msg.push(`• ${t('Location Assignment is required')}`);

    if (msg.length) {
      msg.unshift(t('The following occurred before saving:'), ' ');
      errorDialog(msg, t('Missing required fields'), t('Ok'));
      scrollToDisplayFirstError();
    }
  }

  async function saveVehicle(vehicleData: VehicleType) {
    setSaving(true);
    const valid: boolean = !!assignedLocation.customer_number;
    setLocationAssignmentError(!assignedLocation.customer_number);
    if (valid) {
      const response = await saveVehicleAPICall(
        { ...vehicleData, type: 'commercial' },
        assignedLocation.customer_number,
        vehicleId,
      );
      if (!response || response.status !== 200) {
        errorDialog(
          [t(response.body.error) || t('Unhandled error')],
          t('Error while saving vehicle'),
          t('Ok'),
          undefined,
          {
            disableBackdropClick: true,
          },
        );
      } else if (response.body && response.body.error) {
        errorDialog(t(response.body.error), t('Error while saving vehicle'), t('Ok'), undefined, {
          disableBackdropClick: true,
        });
      } else {
        enqueueSnackbar(t('Successfully saved vehicle !'), { variant: 'success' });
        await setModified(false);
        if (!vehicleId) {
          // Adding a Vehicle
          confirmDialog(
            t('Do you want to add another vehicle?'),
            t('Successfully saved vehicle !'),
            t('Yes'),
            t('No'),
            async () => {
              const dataCopy = JSON.parse(JSON.stringify(vehicleData));
              dataCopy.fleet_id_no = '';
              dataCopy.vin = '';
              dataCopy.license_plate = '';
              dataCopy.license_state_abbreviation = 'defaultOption';
              dataCopy.color = '';
              await setLoading(true);
              setOriginalData(dataCopy);
              setAssignedLocation(assignedLocation);
              setOriginalAssignedLocation(assignedLocation);
              await setLoading(false);
            },
            () => {
              setOriginalData(vehicleData);
              setOriginalAssignedLocation(assignedLocation);
              history.push(`${getBaseUrl()}/commercial-vehicles/${response.body.id}/view`);
            },
            {
              disableBackdropClick: true,
            },
          );
        } else {
          // Modifying a Vehicle
          history.push('view');
        }
      }
    } else {
      showValidationErrors();
    }
    setSaving(false);
  }

  const cancelEdition = async (mode: 'ask' | 'stay' | 'discard') => {
    switch (mode) {
      case 'ask':
        confirmDialog(
          t('Are you sure you want to discard all the changes you have made?'),
          t('Discard Changes'),
          t('Yes'),
          t('No'),
          () => {
            cancelEdition('discard');
          },
          () => {},
        );
        break;
      case 'discard':
        setAssignedLocation(originalAssignedLocation);
        await setModified(false);
        if (ref.current) ref.current.reset();
        if (props.action === 'edit') history.push(`${getBaseUrl()}/commercial-vehicles/${vehicleId}/view`);
        else history.push(`${getBaseUrl()}/commercial-vehicles`);
        break;
      default:
        break;
    }
  };

  if (loading) return <LoadingBounce style={{ height: '70vh' }} />;

  if (vehiclesDetail.error) {
    return (
      <Container>
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Grid item xs={12}>
            <PlainMessage
              title={t('API Error')}
              messages={[
                t('Application could not load vehicles data.'),
                t('Please try again later or contact support if the error persists.'),
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <>
      <PageTitle title={t('Vehicle Management')} rightItems={<VehicleUpdateLink />} />
      <Container>
        <Panel spacing={0}>
          <>
            <Container>
              <BOVehicleDetailsFormProvider
                countryCode={
                  permissions?.location?.customer_country.toLowerCase() === 'us' ? CountryCode.US : CountryCode.CA
                }
                fetchYears={getYears}
                fetchAssetTypes={getAssetTypes}
                fetchMakes={getMakes}
                fetchModels={getModels}
                fetchStates={getStates}
              >
                <BOVehicleDetailsForm
                  ref={ref}
                  readOnly={props.action === 'view'}
                  mode={props.action}
                  onSubmit={async (data) => {
                    const newData = data as any;
                    newData.vin = data.vin ?? originalData.vin;
                    newData.tire_size_id = null;
                    saveVehicle(newData);
                  }}
                  onDirtyStateChanged={(dirtyState: boolean) => {
                    setModified(modified || dirtyState);
                  }}
                  onValidationFailed={showValidationErrors}
                  defaultValues={originalData}
                />
              </BOVehicleDetailsFormProvider>

              <Grid container spacing={2} style={{ marginBottom: 16 }}>
                <Grid item xs={12}>
                  <Panel title={t('Location Assignment')}>
                    <Grid container spacing={2}>
                      <Grid item xs={10} sm={8} md={6} lg={5}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Switch
                            checked={assignedLocation.customer_number === permissions?.location?.customer_number}
                            label={t('Assign to Current Location')}
                            labelPosition="left"
                            onChange={(event: ChangeEvent<any>) => {
                              const newLocation = event.target.checked
                                ? permissions.location || ({} as Customer)
                                : ({} as Customer);
                              setAssignedLocation(newLocation);
                              setLocationAssignmentError(false);
                              const changed =
                                modified || originalAssignedLocation.customer_number !== newLocation.customer_number;
                              setModified(changed);
                            }}
                            readOnly={props.action === 'view'}
                          />
                          &nbsp;
                          <LocationAssignments
                            mode="edit"
                            all={false}
                            locations={[{ hash_key: `1~${assignedLocation.customer_number}` }]}
                            disabled={
                              assignedLocation.customer_number === permissions?.location?.customer_number ||
                              props.action === 'view'
                            }
                            onUpdate={(locs: AssigneeCustomerType[]) => {
                              if (locs.length) {
                                setLocationAssignmentError(false);
                              }
                              const location = locs.pop() || ({} as Customer);
                              setAssignedLocation(location);
                              const changed =
                                modified || originalAssignedLocation.customer_number !== location.customer_number;
                              setModified(changed);
                            }}
                            style={{ margin: '0 0 0 auto' }}
                            error={locationAssignmentError}
                            plainView
                            singleSelection
                            hideCount
                          />
                        </div>
                      </Grid>
                      <Grid item xs={2} sm={3} md={6} lg={7}>
                        {assignedLocation.customer_type ? (
                          <AccountAvatar
                            accountType={assignedLocation.customer_type}
                            name={assignedLocation.customer_name}
                            accountDisplay="short"
                            addressDisplay="cityState"
                            style={{ cursor: 'pointer' }}
                            address={{
                              city: assignedLocation.customer_city,
                              state: assignedLocation.customer_state,
                            }}
                            accountNumbers={{
                              parentCompany: assignedLocation.parent_company_number,
                              homeOffice: assignedLocation.home_office_number,
                              billTo: assignedLocation.bill_to_customer,
                              shipTo: assignedLocation.ship_to_customer,
                            }}
                          />
                        ) : null}
                      </Grid>
                    </Grid>
                  </Panel>
                </Grid>
              </Grid>
            </Container>
          </>
          <GlobalActions>
            <Fab color="inherit" label={t('Saving')} disabled={!saving}>
              <Timelapse />
            </Fab>
            <Fab
              color={modified ? 'primary' : 'secondary'}
              label={t('Save')}
              onClick={
                modified
                  ? () => {
                      if (ref?.current) ref.current.submit();
                    }
                  : undefined
              }
              disabled={props.action === 'view' || saving || vehiclesDetail.data.is_archived}
            >
              <Save />
            </Fab>
            <Fab
              color="primary"
              label={t('Edit')}
              onClick={() => {
                history.push(`${getBaseUrl()}/commercial-vehicles/${vehicleId}/edit`);
              }}
              disabled={props.action !== 'view' || vehiclesDetail.data.is_archived}
            >
              <Edit />
            </Fab>
            <Fab
              color="primary"
              label={props.action === 'view' ? t('Back') : t('Discard')}
              onClick={() => {
                if (!modified) cancelEdition('discard');
                else cancelEdition('ask');
              }}
              disabled={saving}
            >
              <ArrowBack />
            </Fab>
          </GlobalActions>
          <PreventTransitionPrompt when={modified} />
        </Panel>
      </Container>
    </>
  );
}

export default CommercialVehiclesDetail;
