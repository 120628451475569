import { PRIMARY_LEVEL_VALUE, SECONDARY_LEVEL_VALUE } from '../ContactDetails/utils';
import { Contact } from '../utils';

export const upsertRelationshipMutation = (
  location_hash_key: string,
  contact_hash_key: string,
  is_deleted: boolean,
  gsi2_hash_key: string,
  gsi2_range_key: string,
) => `upsertContactLocationRelationship(
    location_hash_key: "${location_hash_key}",
    contact_hash_key: "${contact_hash_key}",
    is_deleted: ${is_deleted},
    gsi2_hash_key: "${gsi2_hash_key}",
    gsi2_range_key: "${gsi2_range_key}"
  ) {
    hash_key, # Location hash_key | Location = Customer = Fleet = Dealer
    is_deleted
  }`;

export default function getContactTypesPerLocation(contact: Contact) {
  const { contact_types } = contact;
  const contactTypePerLocation = new Map();

  if (contact_types) {
    contact_types.forEach((contactType) => {
      if (contactType && contactType.role_areas) {
        contactType.role_areas.forEach((contactTypeRoleArea) => {
          contactTypeRoleArea.levels.forEach((contactTypeLevel) => {
            if (contactTypeLevel.level === PRIMARY_LEVEL_VALUE || contactTypeLevel.level === SECONDARY_LEVEL_VALUE) {
              const alreadyAddedContactTypes = contactTypePerLocation.get(contactTypeLevel.location);
              if (alreadyAddedContactTypes) {
                alreadyAddedContactTypes.push({
                  roleArea: contactTypeRoleArea.role_area,
                  level: contactTypeLevel.level,
                });
                contactTypePerLocation.set(contactTypeLevel.location, alreadyAddedContactTypes);
              } else {
                contactTypePerLocation.set(contactTypeLevel.location, [
                  {
                    roleArea: contactTypeRoleArea.role_area,
                    level: contactTypeLevel.level,
                  },
                ]);
              }
            }
          });
        });
      }
    });
  }

  return contactTypePerLocation;
}
