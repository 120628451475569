import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions } from '@material-ui/core';
import { Button, LoadingBounce, useDialogs } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { auth } from 'Auth';
import { ApolloQueryResult } from 'apollo-boost';
import { ClosableDialogContent } from 'components/ClosableDialog/ClosableDialogContent';
import { ClosableDialogTitle } from 'components/ClosableDialog/ClosableDialogTitle';
import { Contact, ContactLocationRelationship } from 'components/Contact/utils';
import Messages from 'components/Messages';
import { Column } from 'devextreme-react/data-grid';
import { capitalize } from 'lodash';
import {
  GENERAL_LEVEL_VALUE,
  PRIMARY_LEVEL_VALUE,
  SECONDARY_LEVEL_VALUE,
  TIRE_SERVICE_VALUE,
  getContactTypesRoleAreaLabelFromValue,
} from '../../utils';
import ManageContactTypesLevelsTable from '../tables/ManageContactTypesLevelsTable';
import {
  currentlyAssignedRender,
  currentlyAssignedSort,
  hasSecondaryDependencies,
  isRoleAreaServiceLevelMichelinOffer,
  primaryIsAlreadyAssigned,
  priorityHeaderCellRender,
  priorityLevelRender,
  reassignRender,
} from '../tables/utils';
import { contactRelationshipsQuery, fillContactTypesSet, processLocationsContactsWithPriorityLevels } from '../utils';
import { ReassignSelection } from './ReassignContactTypesDialog';

export interface Props {
  open: boolean;
  avoidWarningUnassignAfterManage: boolean;
  viewMode: boolean;
  contact: Contact;
  handleClose: (save: boolean, externalContactTypes: Map<string, Contact>, selections?: Set<string>) => void;
  externalContactsWithRoleAreaReassigned: Map<string, ReassignSelection>;
  locationsFilters: Set<string>;
  swapContactType: (roleArea: string, level: string, locationHashKey: string) => void;
}

interface QueryState {
  errors: any;
  loading: boolean;
  mappedExternalContacts: Map<string, Contact>;
}

export default function ManageContactTypesLevelsDialog(props: Props): JSX.Element {
  const {
    open,
    handleClose,
    viewMode,
    contact,
    externalContactsWithRoleAreaReassigned,
    locationsFilters,
    swapContactType,
  } = props;

  const { t } = useTranslation();
  const dialogTitle = (
    <>
      {viewMode ? t('Viewing') : t('Manage')}{' '}
      {t('Contact Type Priority Levels for {{firstName}} {{lastName}}', {
        fistName: contact && contact.first_name,
        lastName: contact && contact.last_name,
      })}
    </>
  );
  const [assignedContactTypes, setAssignedContactTypes] = useState(new Set<string>());
  const [allPrimary, setAllPrimary] = useState(false);
  const [allSecondary, setAllSecondary] = useState(false);
  const [queryState, setQueryState] = useState<QueryState>({
    loading: true,
    errors: undefined,
    mappedExternalContacts: new Map(),
  });
  const [warningReassign, setWarningReassing] = useState(false);
  const [alreadyWarned, setAlreadyWarned] = useState(new Set<string>());
  const { confirmDialog } = useDialogs();
  const { loading, errors, mappedExternalContacts } = queryState;

  useEffect(() => {
    setAssignedContactTypes(fillContactTypesSet(contact));
    setAllPrimary(false);
    setAllSecondary(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    const asyncQuery = async () => {
      const { apolloClient } = auth;
      if (apolloClient && open && viewMode === false) {
        setQueryState({
          loading: true,
          errors: undefined,
          mappedExternalContacts: new Map(),
        });
        const { hash_key, locations } = contact;

        if (hash_key) {
          const queryArr = [];
          if (locations) {
            const locationsClone = JSON.parse(JSON.stringify(locations));
            let queryNumber = 0;
            // This is it for a better performance
            while (locationsClone.length > 0) {
              const locationsBunch: Array<ContactLocationRelationship> = locationsClone.splice(0, 50);
              // eslint-disable-next-line no-loop-func
              const queryResponse = new Promise<Promise<ApolloQueryResult<any>>>((res) =>
                setTimeout(() => {
                  res(
                    apolloClient.query({
                      query: contactRelationshipsQuery,
                      fetchPolicy: 'no-cache',
                      variables: {
                        locations: locationsBunch
                          .filter((x) => x.is_deleted === false && typeof x.location !== 'undefined')
                          .map((x) => (x.location as any).customer_number),
                      },
                    }),
                  );
                }, 1000 * queryNumber),
              );
              queryNumber += 1;
              queryArr.push(queryResponse);
            }
          }

          const queryResponse = await Promise.all(await Promise.all(queryArr));
          const buildData: any = {
            getContactLocationsRelationships: [],
          };
          queryResponse.forEach((response) => {
            const { data } = response;
            const { getContactLocationsRelationships } = data;
            buildData.getContactLocationsRelationships.push(...getContactLocationsRelationships);
          });

          if (errors && errors.length > 0) {
            setQueryState({
              loading: false,
              errors,
              mappedExternalContacts: new Map(),
            });
          } else if (typeof buildData !== 'undefined' && buildData !== null) {
            const { getContactLocationsRelationships } = buildData;
            const mappedLocationsContacts = processLocationsContactsWithPriorityLevels(
              contact,
              getContactLocationsRelationships,
            );
            setQueryState({
              loading: false,
              errors: undefined,
              mappedExternalContacts: new Map(mappedLocationsContacts),
            });
          } else {
            setQueryState({
              loading: false,
              mappedExternalContacts: new Map(),
              errors: undefined,
            });
          }
        }
      } else {
        setQueryState({
          loading: false,
          mappedExternalContacts: new Map(),
          errors: undefined,
        });
      }
    };

    asyncQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact.locations, open, viewMode]);

  let content = <></>;
  if (loading) {
    content = <LoadingBounce style={{ height: '40vh' }} />;
  } else if (errors) {
    content = (
      <Messages.PlainMessage
        title={t('Internal Error')}
        messages={[
          t('Application could not load the required data.'),
          t('Please try saving the contact before continue or try again later. Contact support if the error persist.'),
        ]}
      />
    );
  } else {
    // Only show Contact Type with
    const contactType = contact.contact_types && contact.contact_types.find((x) => x.service === TIRE_SERVICE_VALUE);

    // Process contact types per location
    const contactTypePerLocation = new Map();

    // Process Michelin Offers for Assign All.
    const michelinOffersAssigned = new Set<string>();
    if (contactType && contactType.role_areas) {
      contactType.role_areas.forEach((contactTypeRoleArea) => {
        contactTypeRoleArea.levels.forEach((contactTypeLevel) => {
          const alreadyAdded = contactTypePerLocation.get(contactTypeLevel.location);
          if (
            isRoleAreaServiceLevelMichelinOffer(
              contactTypeRoleArea.role_area,
              TIRE_SERVICE_VALUE,
              contactTypeLevel.level,
            )
          ) {
            michelinOffersAssigned.add(
              contactTypeRoleArea.role_area + contactTypeLevel.location + contactTypeLevel.level,
            );
          }

          if (
            viewMode === false ||
            (viewMode === true && contactTypeLevel.level === PRIMARY_LEVEL_VALUE) ||
            contactTypeLevel.level === SECONDARY_LEVEL_VALUE
          ) {
            if (alreadyAdded) {
              alreadyAdded.push({
                roleArea: contactTypeRoleArea.role_area,
                level: contactTypeLevel.level,
              });
            } else {
              contactTypePerLocation.set(contactTypeLevel.location, [
                {
                  roleArea: contactTypeRoleArea.role_area,
                  level: contactTypeLevel.level,
                },
              ]);
            }
          }
        });
      });
    }

    const onPriorityLevelChanged = (roleArea: string, location_hash_key: string, level: string, checked: boolean) => {
      if (checked) {
        // Added priority level
        if (level === PRIMARY_LEVEL_VALUE) {
          assignedContactTypes.delete(`${roleArea + location_hash_key}${SECONDARY_LEVEL_VALUE}`);
        } else if (level === SECONDARY_LEVEL_VALUE) {
          assignedContactTypes.delete(`${roleArea + location_hash_key}${PRIMARY_LEVEL_VALUE}`);
        }
        assignedContactTypes.add(roleArea + location_hash_key + level);
        assignedContactTypes.delete(`${roleArea + location_hash_key}${GENERAL_LEVEL_VALUE}`);
      } else {
        // Removed priority level
        assignedContactTypes.delete(roleArea + location_hash_key + level);
        assignedContactTypes.add(`${roleArea + location_hash_key}${GENERAL_LEVEL_VALUE}`);
      }
      setAssignedContactTypes(new Set(assignedContactTypes));
    };

    const onAllPriorityLevelChanged = (level: string, checked: boolean) => {
      if (contact.contact_types) {
        contact.contact_types.forEach((contact_type) => {
          const { role_areas, service } = contact_type;

          if (service === TIRE_SERVICE_VALUE) {
            if (role_areas) {
              role_areas.forEach((contactTypeRoleArea) => {
                const { levels, role_area } = contactTypeRoleArea;
                levels.forEach((contactTypeLevel) => {
                  const { location } = contactTypeLevel;

                  if (locationsFilters.size === 0 || locationsFilters.has(location)) {
                    if (checked && !isRoleAreaServiceLevelMichelinOffer(role_area, TIRE_SERVICE_VALUE, level)) {
                      if (level === PRIMARY_LEVEL_VALUE) {
                        assignedContactTypes.add(role_area + location + level);
                        assignedContactTypes.delete(`${role_area + location}${SECONDARY_LEVEL_VALUE}`);
                      } else if (
                        level === SECONDARY_LEVEL_VALUE &&
                        !hasSecondaryDependencies(
                          role_area,
                          PRIMARY_LEVEL_VALUE,
                          location,
                          mappedExternalContacts,
                          externalContactsWithRoleAreaReassigned,
                        ) &&
                        primaryIsAlreadyAssigned(
                          role_area,
                          location,
                          mappedExternalContacts,
                          externalContactsWithRoleAreaReassigned,
                        )
                      ) {
                        assignedContactTypes.add(role_area + location + level);
                        assignedContactTypes.delete(`${role_area + location}${PRIMARY_LEVEL_VALUE}`);
                      }
                    } else if (
                      checked &&
                      isRoleAreaServiceLevelMichelinOffer(role_area, TIRE_SERVICE_VALUE, level) &&
                      primaryIsAlreadyAssigned(
                        role_area,
                        location,
                        mappedExternalContacts,
                        externalContactsWithRoleAreaReassigned,
                      )
                    ) {
                      // If has not been assigned by the user then assign it.
                      if (
                        !michelinOffersAssigned.has(`${role_area + location}${PRIMARY_LEVEL_VALUE}`) &&
                        !michelinOffersAssigned.has(`${role_area + location}${SECONDARY_LEVEL_VALUE}`)
                      ) {
                        assignedContactTypes.add(role_area + location + level);
                        if (level === PRIMARY_LEVEL_VALUE) {
                          assignedContactTypes.delete(`${role_area + location}${SECONDARY_LEVEL_VALUE}`);
                        } else if (level === SECONDARY_LEVEL_VALUE) {
                          assignedContactTypes.delete(`${role_area + location}${PRIMARY_LEVEL_VALUE}`);
                        }
                      }
                    } else if (!checked && !isRoleAreaServiceLevelMichelinOffer(role_area, TIRE_SERVICE_VALUE, level)) {
                      if (
                        level === PRIMARY_LEVEL_VALUE &&
                        !hasSecondaryDependencies(
                          role_area,
                          level,
                          location,
                          mappedExternalContacts,
                          externalContactsWithRoleAreaReassigned,
                        )
                      ) {
                        assignedContactTypes.delete(`${role_area + location}${PRIMARY_LEVEL_VALUE}`);
                      } else if (level === SECONDARY_LEVEL_VALUE) {
                        assignedContactTypes.delete(`${role_area + location}${SECONDARY_LEVEL_VALUE}`);
                      }
                    } else if (!checked && isRoleAreaServiceLevelMichelinOffer(role_area, TIRE_SERVICE_VALUE, level)) {
                      if (
                        level === PRIMARY_LEVEL_VALUE &&
                        !michelinOffersAssigned.has(`${role_area + location}${PRIMARY_LEVEL_VALUE}`)
                      ) {
                        assignedContactTypes.delete(`${role_area + location}${PRIMARY_LEVEL_VALUE}`);
                      } else if (
                        level === SECONDARY_LEVEL_VALUE &&
                        !michelinOffersAssigned.has(`${role_area + location}${SECONDARY_LEVEL_VALUE}`)
                      ) {
                        assignedContactTypes.delete(`${role_area + location}${SECONDARY_LEVEL_VALUE}`);
                      }
                    }
                  }
                });
              });
            }
          }
        });
      }

      setAssignedContactTypes(new Set(assignedContactTypes));
      if (level === PRIMARY_LEVEL_VALUE) {
        if (checked) {
          setAllPrimary(true);
        } else {
          setAllPrimary(false);
        }
      } else if (level === SECONDARY_LEVEL_VALUE) {
        if (checked) {
          setAllSecondary(true);
        } else {
          setAllSecondary(false);
        }
      }
    };

    const handleSwap = (roleArea: string, level: string, locationHashKey: string, customer_name: string) => {
      const handleOkSwap = () => {
        handleClose(false, mappedExternalContacts);
        swapContactType(roleArea, level, locationHashKey);
      };
      confirmDialog(
        t('Any change done in the current dialog will be lost.'),
        t('Do you want to reassign {{level}} Tire {{roleArea}} at {{customer_name}}?', {
          level: capitalize(level),
          roleArea: getContactTypesRoleAreaLabelFromValue(roleArea, t),
          customer_name,
        }),
        t('Ok'),
        t('Cancel'),
        handleOkSwap,
        undefined,
      );
    };

    content = (
      <ManageContactTypesLevelsTable
        viewMode={viewMode}
        contact={contact}
        contactTypePerLocation={contactTypePerLocation}
        contactType={contactType}
        locationsFilters={locationsFilters}
        actionColumns={[
          <Column
            visible={!viewMode}
            width="240px"
            caption={t('Reassign Priority Level')}
            dataField="reassign_level"
            cellRender={(row: any) => reassignRender(row.data, contactTypePerLocation, handleSwap, t)}
            allowResizing
            allowSorting={false}
            buttons
          />,
          <Column
            visible={!viewMode}
            width="240px"
            caption={t('Priority Level')}
            dataField="priority_level"
            headerCellRender={priorityHeaderCellRender(allPrimary, allSecondary, onAllPriorityLevelChanged, t)}
            cellRender={(row: any) =>
              priorityLevelRender(
                `${contact.first_name} ${contact.last_name}`,
                row.data,
                contactTypePerLocation,
                assignedContactTypes,
                onPriorityLevelChanged,
                mappedExternalContacts,
                externalContactsWithRoleAreaReassigned,
                t,
              )
            }
            allowResizing
            allowSorting={false}
            buttons
          />,
          <Column
            visible={!viewMode}
            width="240px"
            caption={t('Currently Assigned')}
            dataField="currently_assigned"
            cellRender={(row: any) =>
              currentlyAssignedRender(
                row.data,
                contactTypePerLocation,
                assignedContactTypes,
                mappedExternalContacts,
                externalContactsWithRoleAreaReassigned,
              )
            }
            allowResizing
            calculateSortValue={(row: any) =>
              currentlyAssignedSort(
                row,
                contactTypePerLocation,
                assignedContactTypes,
                mappedExternalContacts,
                externalContactsWithRoleAreaReassigned,
              )
            }
            allowSorting
          />,
        ]}
      />
    );
  }

  /*
    https://servicesolutions.atlassian.net/browse/GSDCP-1452
    There is a need to handle external reassignations. Alert the user and after that continue or keep assigning.
  */
  const handleApply = () => {
    let levelDeassignations = false;

    if (contact) {
      const { contact_types } = contact;
      if (contact_types) {
        contact_types.forEach((contactType) => {
          const { role_areas, service } = contactType;
          if (role_areas && service === TIRE_SERVICE_VALUE) {
            role_areas.forEach((contactTypeRoleArea) => {
              const { levels, role_area } = contactTypeRoleArea;
              levels.forEach((contactTypeLevel) => {
                const { location } = contactTypeLevel;
                let externalContact;

                if (assignedContactTypes.has(`${role_area + location}${PRIMARY_LEVEL_VALUE}`)) {
                  externalContact = mappedExternalContacts.get(`${role_area + location}${PRIMARY_LEVEL_VALUE}`);
                } else if (assignedContactTypes.has(`${role_area + location}${SECONDARY_LEVEL_VALUE}`)) {
                  externalContact = mappedExternalContacts.get(`${role_area + location}${SECONDARY_LEVEL_VALUE}`);
                }

                if (externalContact && alreadyWarned.has(externalContact.hash_key + role_area + location) === false) {
                  alreadyWarned.add(externalContact.hash_key + role_area + location);
                  levelDeassignations = true;
                }
              });
            });
          }
        });
      }
    }

    if (levelDeassignations && props.avoidWarningUnassignAfterManage === false) {
      setWarningReassing(true);
      setAlreadyWarned(new Set(alreadyWarned));
    } else {
      handleClose(true, mappedExternalContacts, assignedContactTypes);
    }
  };

  let actions = (
    <>
      <Button size="small" color="primary" style={{ margin: '8px' }} onClick={handleApply}>
        {t('Apply')}
      </Button>
      <Button
        size="small"
        color="danger"
        style={{ margin: '8px' }}
        onClick={() => {
          handleClose(false, mappedExternalContacts);
          setAssignedContactTypes(new Set());
        }}
      >
        {t('Cancel')}
      </Button>
    </>
  );

  if (warningReassign) {
    content = (
      <div style={{ padding: 2 }}>
        <p>
          {t(
            'You have specified priority level(s) to contact type(s) at one or more locations where' +
              ' identical contact types and priority levels are currently assigned to other contacts.',
          )}
        </p>
        <p>
          {t(
            'Click Yes to reassign those existing priority levels to {{firstName}} {{lastName}} and' +
              ' save all priority level assignments for this contact.',
            { firstName: contact.first_name, lastName: contact.last_name },
          )}
        </p>
        <p>
          {t(
            'Click No to go back and resolve priority level conflicts by removing selections for those levels where another contact is currently assigned.',
          )}
        </p>
      </div>
    );
    actions = (
      <>
        <Button
          size="small"
          color="success"
          style={{ margin: '8px' }}
          onClick={() => {
            handleClose(true, mappedExternalContacts, assignedContactTypes);
            setWarningReassing(false);
          }}
        >
          {t('Yes')}
        </Button>
        <Button size="small" color="danger" style={{ margin: '8px' }} onClick={() => setWarningReassing(false)}>
          {t('No')}
        </Button>
      </>
    );
  }

  return (
    <Dialog
      maxWidth="xl"
      open={open}
      onClose={() => handleClose(false, mappedExternalContacts)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <ClosableDialogTitle onClose={() => handleClose(false, mappedExternalContacts)}>
        {dialogTitle}
      </ClosableDialogTitle>
      <ClosableDialogContent dividers style={{ padding: 0, minWidth: '70vw' }}>
        {content}
      </ClosableDialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
}
