import React, { useState } from 'react';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { TextField } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { getOptionLabel } from '@michelin/select-options-provider';
import { getSelectedServiceOffer } from 'prefs-and-service-offers';
import { IBillingRequirement } from './interfaces.jsx';

function RenderCellView(props: any): any {
  const data: IBillingRequirement = { ...props.data.data };
  const { t } = useTranslation();
  const [selectedServiceOffer] = useState<string>(getSelectedServiceOffer().id);
  let editMask = `${data.mask}`;
  if (data.name === 'po' && data.alternate_mask) {
    editMask = `${data.mask} / ${data.alternate_mask}`;
  }
  return (
    <div className="master-detail-caption">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField
            id="requirement"
            type="string"
            label={t('Billing Requirement')}
            defaultValue={getOptionLabel('billing_requirements_names', data?.name || '')}
            readOnly
            variant="standard"
            fullWidth
          />
        </Grid>
        {selectedServiceOffer === 'oncall-tire' ? (
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              label={t('Required on Invoice')}
              labelPlacement="end"
              control={
                <Checkbox
                  id="requiredInvoice"
                  checked={data?.required_on_invoice || false}
                  name="invoice"
                  disabled
                  color="primary"
                />
              }
            />
          </Grid>
        ) : (
          <Grid item spacing={2} container>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                label={t('Stocking Order')}
                labelPlacement="end"
                control={
                  <Checkbox
                    id="stockingOrder"
                    checked={data?.stockable || false}
                    name="stock"
                    disabled
                    color="primary"
                  />
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                label={t('Non-Stocking Order')}
                labelPlacement="end"
                control={
                  <Checkbox
                    id="NonStockingOrder"
                    checked={data?.non_stockable || false}
                    name="nonStock"
                    disabled
                    color="primary"
                  />
                }
              />
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            id="editMask"
            type="string"
            label={`${t('Edit Mask')} / ${t('Alternate Edit Mask')}`}
            defaultValue={editMask || ''}
            variant="standard"
            readOnly
            multiline
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="Comments"
            type="string"
            label={t('Comments')}
            defaultValue={data?.comments || ''}
            variant="standard"
            readOnly
            multiline
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="Notes"
            type="string"
            label={t('Notes to Service Provider')}
            defaultValue={data?.notes_to_dealers || ''}
            variant="standard"
            readOnly
            multiline
            fullWidth
          />
        </Grid>
      </Grid>
    </div>
  );
}
export default RenderCellView;
