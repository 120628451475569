/* eslint-disable react-hooks/exhaustive-deps */
// External Lib
import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core';
// Central provider
import { Button, Panel, PreventTransitionPrompt, Select, useDialogs } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { SelectOption, getOptions } from '@michelin/select-options-provider';
// Internal Lib
import { scrollToDisplayFirstError } from 'components/Util';
import { Controller, useForm } from 'react-hook-form';
import { Actions, SOPBrandPreferences as SOPBrandPreferencesType } from '../Types';

interface BrandFormProps {
  action: Actions;
  data: SOPBrandPreferencesType;
  list: SOPBrandPreferencesType[];
  onSave: (data: SOPBrandPreferencesType) => void;
  onCancel: () => void;
}

function BrandForm({ action, data, list, onSave, onCancel }: BrandFormProps): JSX.Element {
  const { t } = useTranslation();

  const { errorDialog, confirmDialog } = useDialogs();
  const { control, reset, watch, handleSubmit, formState } = useForm<SOPBrandPreferencesType>({ defaultValues: data });

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  const brandTypeOptions: SelectOption[] = getOptions('brand_types');
  const [priorityOptions, setPriorityOptions] = useState<SelectOption[]>([]);
  const [brandOptions, setBrandOptions] = useState<SelectOption[]>([]);
  const type = watch('type');

  useEffect(() => {
    if (!type) return;
    const filterList = list.filter((item) => item.type === type);
    const maxPriority =
      (filterList.length > 0 ? Math.max(...filterList.map((item) => item.priority)) : 0) - (action === 'edit' ? 1 : 0);
    const priOptions: SelectOption[] = [];

    for (let i = 0; i <= maxPriority; i++) priOptions.push({ label: `${i + 1}`, value: `${i + 1}` });
    if (priOptions.length > 0) setPriorityOptions(priOptions);

    try {
      const typeSelected = `brand_${type}` as
        | 'brand_casings'
        | 'brand_valve_caps'
        | 'brand_valve_stems'
        | 'brand_mud_flaps';
      const typeOptions = getOptions(typeSelected);
      const typeListed = list.filter((v) => v.type === type).map((v) => v.brand);

      setBrandOptions(typeOptions.filter((v) => !typeListed.includes(v.value) || v.value === data.brand));
    } catch (ex) {
      setBrandOptions([]);
    }
  }, [list, type]);

  const onError = () => {
    errorDialog(t('Please check the marked fields.').split('\n'), t('Error saving data.'));
    scrollToDisplayFirstError();
  };

  return (
    <Panel>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Controller
            name="type"
            control={control}
            rules={{ required: { value: true, message: t('Please fill out this field.') } }}
            render={(props: any) => (
              <Select
                label={t('Brand Type')}
                value={props.field.value || ''}
                options={brandTypeOptions}
                variant="standard"
                onChange={(e: any) => props.field.onChange(e.target.value)}
                error={!!props.formState.errors.type}
                helperText={props.formState.errors.type?.message}
                disabled={action === 'view'}
                required
                fullWidth
                // defaultValue={userCountry === 'USA' ? 'psi' : ''}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Controller
            name="brand"
            control={control}
            rules={{ required: { value: true, message: t('Please fill out this field.') } }}
            render={(props: any) => (
              <Select
                label={t('Name')}
                value={props.field.value || ''}
                options={brandOptions}
                variant="standard"
                onChange={(e: any) => props.field.onChange(e.target.value)}
                error={!!props.formState.errors.brand}
                helperText={props.formState.errors.brand?.message}
                disabled={action === 'view'}
                required
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Controller
            name="priority"
            control={control}
            rules={{ required: true }}
            render={(props: any) => (
              <Select
                label={t('Priority')}
                value={props.field.value || ''}
                options={priorityOptions}
                variant="standard"
                onChange={(e: any) => props.field.onChange(parseInt(e.target.value, 0))}
                error={!!props.formState.errors.priority}
                helperText={props.formState.errors.priority?.message}
                disabled={action === 'view'}
                required
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Grid container justify="flex-end">
            <Button
              size="small"
              color="primary"
              style={{ margin: '8px' }}
              onClick={handleSubmit(onSave, onError)}
              disabled={action === 'view'}
            >
              {t('Save')}
            </Button>
            <Button
              size="small"
              color="danger"
              style={{ margin: '8px' }}
              onClick={() => {
                if (formState.isDirty) {
                  confirmDialog(
                    t('Are you sure you want to discard all the changes you have made?'),
                    t('Discard Changes'),
                    t('Yes'),
                    t('No'),
                    onCancel,
                  );
                } else {
                  onCancel();
                }
              }}
            >
              {t('Cancel')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <PreventTransitionPrompt when={formState.isDirty} />
    </Panel>
  );
}

interface InflationFormModalProps {
  action: Actions;
  data: SOPBrandPreferencesType;
  list: SOPBrandPreferencesType[];
  open: boolean;
  onSave: (e: SOPBrandPreferencesType) => void;
  handleOpen: (value: boolean) => void;
}

function BrandFormModal(props: InflationFormModalProps) {
  const { t } = useTranslation();
  const action = props.action.charAt(0).toUpperCase() + props.action.slice(1);
  const title = `${t(action)} - ${t('Brand Preferences')}`;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      aria-labelledby="customized-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
      open={props.open}
    >
      <DialogTitle>{title}</DialogTitle>

      <DialogContent dividers style={{ padding: 0 }}>
        <BrandForm
          action={props.action}
          list={props.list}
          data={props.data}
          onSave={props.onSave}
          onCancel={() => props.handleOpen(false)}
        />
      </DialogContent>
    </Dialog>
  );
}

export default BrandForm;
export { BrandFormModal };
