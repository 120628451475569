import { typeTireProfileAxles } from 'components/TireProfile/AppSync/Types';
import gql from 'graphql-tag';

export const update = gql`
  mutation updateCustomer($hash_key: String!, $input: CustomerInput!) {
    updateCustomer(hash_key: $hash_key, input: $input) {
      hash_key
      range_key
      customer_name
      customer_type
      extrnl_cust_id
      customer_addr1
      customer_addr2
      customer_country
      customer_city
      customer_state
      pickup_delivery_instructions
      customer_zip
      parent_company_number
      home_office_number
      bill_to_customer
      ship_to_customer
      phone_number
      location
      delivery_address
      delivery_city
      delivery_state
      delivery_zip
      numberOfFleetTrucks
      numberOfFleetTrailers
      primaryTransportActivity
      primaryVocation
      secondaryTransportActivity
      secondaryVocation
      is_urban
      urban_admin_allow_preapproved_txs
      urban_admin_preapproved_txs_limit_amount
      urban_admin_allow_create_vehicle
      urban_admin_preapproved_txs_no_limits
      oncall_sds_dashboard
    }
  }
`;

export const getChildren = gql`
  query getCustomerAssets($hash_key: String!) {
    getCustomerAssets(hash_key: $hash_key, range_key: "v0_customer") {
      customer {
        hash_key
        range_key
        customer_name
        customer_type
        relationship
        extrnl_cust_id
        customer_addr1
        customer_addr2
        customer_country
        customer_city
        customer_state
        customer_zip
        parent_company_number
        home_office_number
        bill_to_customer
        ship_to_customer
        phone_number
        location
        update_date
        numberOfFleetTrucks
        numberOfFleetTrailers
        primaryTransportActivity
        primaryVocation
        secondaryTransportActivity
        secondaryVocation
        pickup_delivery_instructions
        hoursOfOperations {
          new_years_day {
            begin
            end
          }
          memorial_day {
            begin
            end
          }
          independence_day {
            begin
            end
          }
          labor_day {
            begin
            end
          }
          thanksgiving_day {
            begin
            end
          }
          christmas_eve_day {
            begin
            end
          }
          christmas_day {
            begin
            end
          }
          new_years_eve_day {
            begin
            end
          }
          day_after_new_years_day {
            begin
            end
          }
          ns_heritage_day {
            begin
            end
          }
          good_friday {
            begin
            end
          }
          jean_baptiste_day {
            begin
            end
          }
          canada_day {
            begin
            end
          }
          labour_day {
            begin
            end
          }
          boxing_day {
            begin
            end
          }
          monday {
            begin
            end
          }
          tuesday {
            begin
            end
          }
          wednesday {
            begin
            end
          }
          thursday {
            begin
            end
          }
          friday {
            begin
            end
          }
          saturday {
            begin
            end
          }
          sunday {
            begin
            end
          }
        }
        operationHours {
          new_years_day {
            begin
            end
          }
          memorial_day {
            begin
            end
          }
          independence_day {
            begin
            end
          }
          labor_day {
            begin
            end
          }
          thanksgiving_day {
            begin
            end
          }
          christmas_eve_day {
            begin
            end
          }
          christmas_day {
            begin
            end
          }
          new_years_eve_day {
            begin
            end
          }
          day_after_new_years_day {
            begin
            end
          }
          ns_heritage_day {
            begin
            end
          }
          good_friday {
            begin
            end
          }
          jean_baptiste_day {
            begin
            end
          }
          canada_day {
            begin
            end
          }
          labour_day {
            begin
            end
          }
          boxing_day {
            begin
            end
          }
          monday {
            begin
            end
          }
          tuesday {
            begin
            end
          }
          wednesday {
            begin
            end
          }
          thursday {
            begin
            end
          }
          friday {
            begin
            end
          }
          saturday {
            begin
            end
          }
          sunday {
            begin
            end
          }
        }
        method_of_delivery
        delivery_address
        delivery_city
        delivery_state
        delivery_zip
        recipient_signature_required
        driver_signature_required
        cust_timezone
        oncall_sds_dashboard
        is_commercial
        mechanical_services_enabled
      }
      childAssets {
        customer {
          hash_key
          is_urban
          oncall_sds_dashboard
          customer_type
          urban_admin_allow_preapproved_txs
          urban_admin_preapproved_txs_limit_amount
          urban_admin_allow_create_vehicle
          urban_admin_preapproved_txs_no_limits
          is_commercial
        }
      }
    }
  }
`;

export const getDealers = gql`
  query getDealers($searchText: String!, $isSelected: Boolean, $hash_key: String, $queryName: String!) {
    getDealers(searchText: $searchText, isSelected: $isSelected, hash_key: $hash_key, queryName: $queryName) {
      customer_addr1
      customer_addr2
      customer_name
      customer_zip
      customer_city
      customer_number
      customer_state
      customer_dba_name
      customer_type
      parent_company_number
      home_office_number
      bill_to_customer
      extrnl_cust_id
      ship_to_customer
      is_urban
      is_commercial
      is_scrap
      is_fleet_ordering
      is_mechanical_services
    }
  }
`;

export const updateDealerLocations = gql`
  mutation updateCustomerDealerRelationship($dealers: [DealerCustomerRecordInput], $queryName: String!) {
    updateCustomerDealerRelationship(dealers: $dealers, queryName: $queryName)
  }
`;

export const queryTireProfileList = gql`
  query  GetCustomerTireProfiles($hashKey: String!, $filter: String) {
    getCustomerTireProfiles(hash_key: $hashKey, filter: $filter) {
      ${typeTireProfileAxles}
    }
  }
`;

export const updateTireOrdering = gql`
  mutation genericModule($input: AWSJSON, $queryName: String!) {
    genericModule(input: $input, queryName: $queryName)
  }
`;

export const getTireOrderingInformation = gql`
  query getEntityPaginated($hash_key: String!, $range_key: String!) {
    getEntityPaginated(hash_key: $hash_key, range_key: $range_key) {
      items
    }
  }
`;
