import React from 'react';
import { ArrowBack, Cancel, Edit, Save, Timelapse } from '@material-ui/icons';
import { Fab, GlobalActions } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { getBaseUrl } from 'prefs-and-service-offers';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { BillingProfileContextData } from '../DetailPage';

interface Props {
  savingFlag: boolean;
  editFlag: boolean;
  modifiedFlag: boolean;
  profileId: string;
  action: string;
  handleSaveClick: () => void;
}

function BillingFabs(props: Props): JSX.Element {
  const { savingFlag, editFlag, modifiedFlag, profileId, handleSaveClick, action } = props;
  const { getValues } = useFormContext<BillingProfileContextData>();
  const updatePermission = getValues().permission.updatePermission && getValues().permission.updateByHierarchyEnabled;
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <GlobalActions>
      <Fab color="inherit" aria-label={t('Saving')} disabled={!savingFlag}>
        <Timelapse />
      </Fab>
      <Fab
        color={modifiedFlag ? 'primary' : 'inherit'}
        aria-label={t('Save')}
        disabled={savingFlag || !editFlag || updatePermission === false}
        onClick={() => {
          if (modifiedFlag) handleSaveClick();
        }}
      >
        <Save />
      </Fab>
      <Fab
        color="primary"
        aria-label={t('Edit')}
        disabled={savingFlag !== false || editFlag || !updatePermission}
        onClick={() => {
          history.push(`${getBaseUrl()}/billing-future/${profileId}/edit`);
        }}
      >
        <Edit />
      </Fab>
      <Fab
        color="primary"
        label={t('Back')}
        disabled={action.toLowerCase() !== 'view'}
        onClick={() => {
          const path = editFlag && profileId ? `/billing-future/${profileId}/view` : '/billings';
          history.push(`${getBaseUrl()}${path}`);
        }}
      >
        <ArrowBack />
      </Fab>

      <Fab
        color={'primary'}
        disabled={action.toLowerCase() === 'view' || savingFlag}
        label={t('Cancel')}
        onClick={() => {
          const path = editFlag && profileId ? `/billing-future/${profileId}/view` : '/billings';
          history.push(`${getBaseUrl()}${path}`);
        }}
      >
        <Cancel />
      </Fab>
    </GlobalActions>
  );
}

export { BillingFabs };
