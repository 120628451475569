import { SelectOption } from '@michelin/select-options-provider';
import gql from 'graphql-tag';

export function addCountry(options: SelectOption[], country: string) {
  return options.map((option) => {
    if (option.value === 'All' || option.value === 'all') return option;
    return {
      value: option.value,
      label: `${option.label} (${country})`,
    };
  });
}

export const getChildren = gql`
  query getChildren($hash_key: String!, $range_key: String!, $nextToken: String) {
    getChildren(hash_key: $hash_key, range_key: $range_key, nextToken: $nextToken) {
      nextToken
      customers {
        hash_key
        range_key
        contacts {
          hash_key
          gsi1_hash_key
          gsi2_hash_key
          is_deleted
        }
        customer_name
        customer_type
        extrnl_cust_id
        customer_addr1
        hold_code
        customer_addr2
        customer_city
        customer_state
        customer_zip
        customer_country
        customer_number
        parent_company_number
        home_office_number
        bill_to_customer
        ship_to_customer
        relationship
      }
    }
  }
`;

export interface Customer {
  hash_key: string;
  range_key: string;
  customer_number: string;
  customer_name: string;
  customer_type: string;
  extrnl_cust_id: string;
  customer_addr1: string;
  customer_addr2: string;
  customer_city: string;
  customer_state: string;
  customer_zip: string;
  customer_country: string;
  parent_company_number: string;
  home_office_number: string;
  bill_to_customer: string;
  ship_to_customer: string;
  hold_code: string;
  contacts?: Array<{
    hash_key: string;
    gsi1_hash_key: string;
    gsi2_hash_key?: string;
    is_deleted?: boolean;
  }>;
}

export interface CustomerAssets {
  customers: Customer[];
  nextToken: string;
}
