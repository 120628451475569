import React, { useEffect, useState } from 'react';
import { Grid, Icon } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Autocomplete, IconButton, Panel, Select } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { TireProfileOVM } from '@michelin/fcp-view-models';
import { SelectOption, getOptions } from '@michelin/select-options-provider';
import { Actions, AxleName } from 'modules/Tires/types';
import { Controller, useFormContext } from 'react-hook-form';
import { getAutocompleteValues } from '../../utils';

interface Props {
  axleName: AxleName;
  action: Actions;
}

const TireServiceSpecifications = ({ axleName, action }: Props) => {
  const { t } = useTranslation();

  const [optionsSecondChoice, setOptionsSecondChoice] = useState<SelectOption[]>();
  const [optionsThirdChoice, setOptionsThirdChoice] = useState<SelectOption[]>();
  const [actIfUnavailable, setActIfUnavailable] = useState<string[]>();

  const { control, watch, setValue } = useFormContext<TireProfileOVM>();

  const firstChoice = watch(`axles.${axleName}.tire_service_specifications.first_choice`);
  const secondChoice = watch(`axles.${axleName}.tire_service_specifications.second_choice`);
  const otherPreference = watch(`axles.${axleName}.activity_if_pref_unavailable`);

  const optionsFirstChoice = getOptions('damage_tire_course_of_action');
  if (axleName === 'steer')
    optionsFirstChoice.splice(
      optionsFirstChoice.findIndex((v) => v.value.toLowerCase() === 'repair'),
      1,
    );

  const activityOptions = getOptions('activity_if_not_set_preferences_types');
  if (axleName === 'steer')
    activityOptions.splice(
      activityOptions.findIndex((v) => v.value.toLowerCase() === 'accept_retreads'),
      1,
    );

  useEffect(() => {
    filterOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);

  useEffect(() => {
    filterOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstChoice, secondChoice]);

  useEffect(() => {
    if (otherPreference) {
      setActIfUnavailable(otherPreference);
    }
  }, [otherPreference]);

  const filterOptions = () => {
    const cloneFirstOptions = [...optionsFirstChoice];
    const firstIndex = optionsFirstChoice.findIndex((v) => v.value === firstChoice);
    cloneFirstOptions.splice(firstIndex, 1);
    setOptionsSecondChoice(cloneFirstOptions);

    const cloneSecondOptions = [...cloneFirstOptions];
    const secondIndex = cloneFirstOptions.findIndex((v) => v.value === secondChoice);
    cloneSecondOptions.splice(secondIndex, 1);

    setOptionsThirdChoice(cloneSecondOptions);
  };

  const handleDelete = (choiceNumber: number) => {
    if (choiceNumber <= 3)
      setValue(`axles.${axleName}.tire_service_specifications.third_choice`, null, { shouldDirty: true });

    if (choiceNumber <= 2)
      setValue(`axles.${axleName}.tire_service_specifications.second_choice`, null, { shouldDirty: true });

    if (choiceNumber === 1)
      setValue(`axles.${axleName}.tire_service_specifications.first_choice`, null, { shouldDirty: true });
  };
  return (
    <Panel title={t('Tire Service Specifications')}>
      <Grid container spacing={2}>
        <Grid item sm={12} lg={12}>
          {t('Indicate the course of action below for the damaged tire')}
        </Grid>
        <Grid item xs={12} lg={4}>
          <Controller
            name={`axles.${axleName}.tire_service_specifications.first_choice`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                fullWidth
                value={value || ''}
                label={t('First Choice')}
                options={optionsFirstChoice}
                disabled={action === 'view'}
                onChange={(e: any) => onChange(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton
                      disabled={action === 'view'}
                      name="delete_first_choice"
                      size="small"
                      style={{ padding: 2 }}
                      onClick={() => handleDelete(1)}
                    >
                      <Icon style={{ fontSize: '1em' }}>
                        <HighlightOffIcon style={{ fontSize: '1em' }} />
                      </Icon>
                    </IconButton>
                  </InputAdornment>
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Controller
            name={`axles.${axleName}.tire_service_specifications.second_choice`}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                fullWidth
                value={value || ''}
                label={t('Second Choice')}
                options={optionsSecondChoice}
                disabled={action === 'view' || !firstChoice}
                onChange={(e: any) => onChange(e.target.value)}
                style={{ visibility: !firstChoice ? 'hidden' : 'visible' }}
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton
                      disabled={action === 'view'}
                      name="delete_second_choice"
                      size="small"
                      style={{ padding: 2 }}
                      onClick={() => handleDelete(2)}
                    >
                      <Icon style={{ fontSize: '1em' }}>
                        <HighlightOffIcon style={{ fontSize: '1em' }} />
                      </Icon>
                    </IconButton>
                  </InputAdornment>
                }
              />
            )}
          />
        </Grid>
        {axleName !== 'steer' && secondChoice && (
          <Grid item xs={12} lg={4}>
            <Controller
              name={`axles.${axleName}.tire_service_specifications.third_choice`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  fullWidth
                  value={value || ''}
                  label={t('Third Choice')}
                  name="third_choice"
                  options={optionsThirdChoice}
                  disabled={action === 'view' || !secondChoice}
                  onChange={(e: any) => onChange(e.target.value)}
                  startAdornment={
                    <InputAdornment position="start">
                      <IconButton
                        disabled={action === 'view'}
                        name="delete_third_choice"
                        size="small"
                        style={{ padding: 2 }}
                        onClick={() => handleDelete(3)}
                      >
                        <Icon style={{ fontSize: '1em' }}>
                          <HighlightOffIcon style={{ fontSize: '1em' }} />
                        </Icon>
                      </IconButton>
                    </InputAdornment>
                  }
                />
              )}
            />
          </Grid>
        )}

        <Grid item sm={12} lg={12}>
          {t('Indicate the course of action if no preference is available')}
        </Grid>
        <Grid item xs={12} lg={8}>
          <Controller
            name={`axles.${axleName}.activity_if_pref_unavailable`}
            control={control}
            render={({ field }) => (
              <>
                <Autocomplete
                  id={`axles.${axleName}.activity_if_pref_unavailable`}
                  disableCloseOnSelect
                  options={activityOptions}
                  label={t('Action if tire preferences unavailable')}
                  placeholder={t('Select action')}
                  value={getAutocompleteValues(actIfUnavailable, activityOptions)}
                  disabled={action === 'view'}
                  onChange={(e, values) => {
                    field.onChange(values.map((v) => v.value) || []);
                  }}
                />
              </>
            )}
          />
        </Grid>
      </Grid>
    </Panel>
  );
};

export { TireServiceSpecifications };
