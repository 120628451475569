import { auth } from 'Auth';
import { APITypes } from 'components/Types/APITypes';
import { apiBaseRoute } from 'components/Types/Api';

export function vehicleSaveAPIType(): APITypes {
  const method: APITypes['method'] = 'GET';
  const endpoint: string = 'cp-be/vehicles';
  return { method, endpoint };
}

export async function getAssets(fleet_id: string, account_type: string) {
  const apiType = vehicleSaveAPIType();

  try {
    const headers: { [key: string]: string } = { 'Content-Type': 'application/json' };

    headers.Authorization = `Bearer ${auth.getAuthResultTokenAccess()}`;
    const url = `${apiBaseRoute}/${apiType.endpoint}/${fleet_id.replace(/1~/, '')}?limit=0`;
    const responseData = await fetch(url, {
      body: undefined,
      cache: 'no-cache',
      headers,
      method: apiType.method,
      signal: undefined,
    });

    if (responseData.status >= 300) {
      const jsonError = await responseData.json();
      throw Error(JSON.stringify(jsonError.errors));
    }
    const result = await responseData.json();
    return { body: result, status: responseData.status, aborted: false };
  } catch (error) {
    return { body: error, status: 500, aborted: (error as { code: number }).code === 20 };
  }
}
