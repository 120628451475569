import { VehicleType } from '@michelin/core-profile-ui-tools/build/types';
import { SelectOption, getOptionLabel } from '@michelin/select-options-provider';
import { auth } from 'Auth';
import { urbanVehiclesAPIType, vehicleSaveAPIType } from '../Types/APITypes';
import { apiBaseRoute } from '../Types/Api';

export interface VehiclesDetailProps {
  type: string;
  action?: 'edit' | 'view' | 'add';
  vehicleId?: string;
}

export interface VehiclesDashboardProps {
  vehicleType: string;
  allowView: boolean;
  allowDelete: boolean;
  allowTransfer: boolean;
}

/* ******************** */
/* ******************** */
/* Fetch and Parse Data */
/* ******************** */
/* ******************** */

export function buildOptionsAndValuesFromObject(data: any): SelectOption[] {
  return data.map((elem: any) => ({
    label: elem.name,
    value: elem.id,
    order: elem.id,
  }));
}

export function buildOptionsAndValuesFromArray(data: any): SelectOption[] {
  return data.map((option: number, key: number) => ({
    label: option.toString(),
    value: option.toString(),
    order: (key + 1).toString(),
  }));
}

export function buildOptionsAndValuesFromSpeedRatings(data: any): SelectOption[] {
  return data.map((elem: any) => ({
    label: elem.speed_rating.toString(),
    value: elem.id.toString(),
    order: elem.id,
  }));
}

export async function saveVehicleAPICall(data: VehicleType, fleet_id: string, vehicle_id: string) {
  const apiType = vehicleSaveAPIType(fleet_id, vehicle_id);

  try {
    const headers: { [key: string]: string } = { 'Content-Type': 'application/json' };

    headers.Authorization = `Bearer ${auth.getAuthResultTokenAccess()}`;

    const requestData: any = {
      color: data.color || '',
      fleet_id_no: data.fleet_id_no,
      license_plate: data.license_plate,
      license_state_abbreviation: data.license_state_abbreviation,
      make_id: data.make_id,
      model_id: data.model_id,
      option_id: data.option_id,
      tire_size_id: (data.tire_size_id as unknown) === 'custom' ? null : data.tire_size_id,
      custom_tire_size_id:
        !data.tire_size_id || (data.tire_size_id as unknown) === 'custom' ? data.custom_tire_size_id : null,
      type: data.type,
      vin: data.vin,
      year: parseInt(String(data.year), 10),
      asset_type_id:
        data.type === 'urban' || (data.type === 'commercial' && data.asset_type_id === null)
          ? undefined
          : data.asset_type_id,
      asset_type_name:
        data.type === 'urban' || (data.type === 'commercial' && data.asset_type_id === null)
          ? undefined
          : getOptionLabel('asset_types', data.asset_type_id),
    };

    const responseData = await fetch(`${apiBaseRoute}/${apiType.endpoint}`, {
      body: JSON.stringify(requestData),
      cache: 'no-cache',
      headers,
      method: apiType.method,
      signal: undefined,
    });
    if (responseData.status >= 300) {
      const jsonError = await responseData.json();
      throw Error(JSON.stringify(jsonError));
    }
    const result = await responseData.json();
    return { body: result, status: responseData.status, aborted: false };
  } catch (error) {
    if (error instanceof Error) {
      return {
        body: JSON.parse(error.message),
        status: 500,
        aborted: (error as unknown as { code: number }).code === 20,
      };
    }

    return {
      body: error,
      status: 500,
    };
  }
}

export async function transferVehicleAPICall(
  fleet_id: string,
  vehicle_id: string,
  target_fleet_id: string,
  reason: string,
  reason_text?: string,
) {
  const apiType = urbanVehiclesAPIType(fleet_id, vehicle_id);

  try {
    const headers: { [key: string]: string } = {
      'Content-Type': 'application/json',
    };

    headers.Authorization = `Bearer ${auth.getAuthResultTokenAccess()}`;

    const requestData: any = {
      action: 'transfer',
      target_fleet_id,
      reason,
      reason_text,
    };

    const responseData = await fetch(`${apiBaseRoute}/${apiType.endpoint}`, {
      body: JSON.stringify(requestData),
      cache: 'no-cache',
      headers,
      method: apiType.method,
      signal: undefined,
    });
    if (responseData.status >= 300) {
      const jsonError = await responseData.json();
      throw Error(JSON.stringify(jsonError));
    }
    const result = await responseData.json();
    return { body: result, status: responseData.status, aborted: false };
  } catch (error) {
    if (error instanceof Error) {
      return {
        body: JSON.parse(error.message),
        status: 500,
        aborted: (error as unknown as { code: number }).code === 20,
      };
    }

    return {
      body: error,
      status: 500,
    };
  }
}

export async function activateVehicleAPICall(
  activate: boolean,
  fleet_id: string,
  vehicle_id: string,
  reason?: string,
  reason_text?: string,
) {
  const apiType = urbanVehiclesAPIType(fleet_id, vehicle_id);

  try {
    const headers: { [key: string]: string } = {
      'Content-Type': 'application/json',
    };

    headers.Authorization = `Bearer ${auth.getAuthResultTokenAccess()}`;

    let requestData: any;
    if (activate) {
      requestData = {
        action: 'activate',
      };
    } else {
      requestData = {
        action: 'deactivate',
        reason,
        reason_text,
      };
    }

    const responseData = await fetch(`${apiBaseRoute}/${apiType.endpoint}`, {
      body: JSON.stringify(requestData),
      cache: 'no-cache',
      headers,
      method: apiType.method,
      signal: undefined,
    });
    if (responseData.status >= 300) {
      const jsonError = await responseData.json();
      throw Error(JSON.stringify(jsonError));
    }
    const result = await responseData.json();
    return { body: result, status: responseData.status, aborted: false };
  } catch (error) {
    if (error instanceof Error) {
      return {
        body: JSON.parse(error.message),
        status: 500,
        aborted: (error as unknown as { code: number }).code === 20,
      };
    }

    return {
      body: error,
      status: 500,
    };
  }
}
