/* eslint-disable max-len */
import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from '@michelin/central-provider';
import { getOptionLabel } from '@michelin/select-options-provider';
import { SOPTireProfile } from './Types';

interface TireProfileQuickViewProps {
  tireProfile: SOPTireProfile[] | null;
}

export default function TireProfileQuickView(props: TireProfileQuickViewProps) {
  const { t } = useTranslation();

  if (!props.tireProfile) {
    return <></>;
  }

  const tp = props.tireProfile.sort((a, b) => {
    if (a.tire_size > b.tire_size) return 1;
    if (a.tire_size < b.tire_size) return -1;
    return 0;
  });

  return (
    <Grid container style={{ fontSize: '0.8em' }}>
      <Grid container>
        <Grid item xs={3}>
          {t('Tire')}
        </Grid>
        <Grid item xs={3}>
          {t('Axle')}
        </Grid>
        <Grid item xs={3}>
          {t('Application')}
        </Grid>
        <Grid item xs={3}>
          {t('Vehicle')}
        </Grid>
      </Grid>
      {tp.map((row) => (
        <Grid
          key={`${row.tire_size}-${row.axle}-${row.application_type}-${row.vehicle_type}`}
          container
          style={{ borderBottom: 'solid 1px gray' }}
        >
          <Grid item xs={3}>
            {row.tire_size}
          </Grid>
          <Grid item xs={3}>
            {getOptionLabel('axle_types', row.axle)}
          </Grid>
          <Grid item xs={3}>
            {getOptionLabel('application_types', row.application_type)}
          </Grid>
          <Grid item xs={3}>
            {getOptionLabel('vehicle_types_onsite', row.vehicle_type)}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
