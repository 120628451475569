import { MaestroAPI, maestroApiCall } from '@michelin/central-provider';
import { Location } from '@michelin/central-provider';
import { MethodType } from '@michelin/central-provider/dist/helpers/createAPICall';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { BillingPreferences, BillingProfileTypes, BillingServiceTypes, BillingWheelOffInstalled, BillingWheelRequestedDetails } from 'components/Billing/Preferences/query';
import GLOBAL_CONFIG from 'global-variables';
import { ProfileParams, ServiceParams } from 'modules/Billing/Profiles/Components/SpeedDialActions';
import { BillingProfileContextData } from 'modules/Billing/Profiles/DetailPage';
import { LOCATIONS } from 'modules/Billing/Profiles/types';
import uuid from 'uuid';


interface BillingProfileOVM {}

const baseURL = GLOBAL_CONFIG.externalAPIUrl;

interface BillingProfileDeleteResult {
  id: string;
}

const deleteBillingProfile = async ({
  fleetId,
  profileId,
}: {
  fleetId: string;
  profileId: string;
}): Promise<BillingProfileDeleteResult | null> => {
  if (!fleetId || !profileId) return null;
  const apiType: MaestroAPI = {
    method: 'DELETE',
    endpoint: `cp-be/billing/${fleetId}/${profileId}`,
    baseURL,
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return maestroApiCall(apiType);
};
function useDeleteBillingProfile() {
  return useMutation(deleteBillingProfile);
}

const getBillingProfile = async (fleetId: string, profileId: string): Promise<BillingProfileOVM | null> => {
  if (!fleetId || !profileId) return null;
  const apiType: MaestroAPI = {
    method: MethodType.GET,
    endpoint: `/cp-be/billing/${fleetId}/${profileId}`,
    baseURL: baseURL || '',
  };
  const response = await maestroApiCall<BillingProfileOVM>(apiType);
  return response;
};
function useGetBillingProfile({ fleetId, profileId }: { fleetId: string; profileId: string }) {
  return useQuery(['profile', 'billing', fleetId, profileId], () => {
    return getBillingProfile(fleetId, profileId);
  });
}

const updateBillingProfile = async ({
  fleetId,
  profileId,
  profileData,
  action,
}: {
  fleetId: string;
  profileId?: string;
  profileData: any;
  action: 'edit' | 'add';
}): Promise<BillingProfileOVM | null> => {
  if (!fleetId) return null;
  const apiType: MaestroAPI = {
    method: action === 'edit' ? MethodType.PUT : MethodType.POST,
    endpoint: action === 'edit' ? `/cp-be/billing/${fleetId}/${profileId}` : `/cp-be/billing/${fleetId}/`,
    baseURL: baseURL || '',
  };
  const response = await maestroApiCall<BillingProfileOVM>(apiType, profileData);

  return response;
};
function useUpdateBillingProfile({ fleetId, profileId }: { fleetId: string; profileId: string }) {
  const queryClient = useQueryClient();
  return useMutation(updateBillingProfile, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['profile', 'billing', fleetId, profileId]);
    },
  });
}

// Mocked version of the useGetBillingProfile hook
const getMockProfile = async (
  id: string,
  action: string,
  service?: ServiceParams | '',
  profile?: ProfileParams | '',
  location?: Location,
): Promise<BillingProfileContextData> => {
  await new Promise((resolve) => setTimeout(resolve, 2000));
  let auxBillingProfile: BillingPreferences = {} as BillingPreferences;
  if (action === 'create' && profile && service && location) {
    const auxService = service === 'tire-wheel' ? BillingServiceTypes.TIRE_WHEEL : BillingServiceTypes.MECHANICAL;
    let auxProfile = BillingProfileTypes.ers_onsite;
    if (profile === 'onsite') {
      auxProfile = BillingProfileTypes.onsite;
    } else if (profile === 'ers') {
      auxProfile = BillingProfileTypes.ers;
    }
    auxBillingProfile = {
      hash_key: `7~${uuid.v4()}`,
      range_key: 'v0_billing',
      gsi1_hash_key: location?.customer_number ? `1~${location?.customer_number}` : '',
      gsi1_range_key: '',
      owner_key: location?.customer_number ? `1~${location?.customer_number}` : '',
      owner_relationship: location?.relationship,
      owner: {
        hash_key: location?.customer_number ? `1~${location?.customer_number}` : '',
        range_key: 'v0_customer',
        customer_type: location?.customer_type || 'ST',
        customer_number: (location?.customer_number || '').toString(),
      },
      locations: [],
      general_information: {
        service_type: auxService,
        profile_type: auxProfile,
        set_all_locations: location?.customer_type.toLowerCase() === 'st' ? true : false,
        profile_name: '',
        po_required_for_service: false,
        default: false, // we shluod remove this from the interface
      },
      assigned_locations: [],
      requested_photos: { types: [] },
      approvals: { ers_pre_approvals_limits: {}, onsite_pre_approvals_limits: {} },
    };
  } else {
    auxBillingProfile = {
      assigned_locations: [],
      gsi1_hash_key: '1~1005070',
      owner_key: '1~1005070',
      owner_relationship: '1290948~1290947~1005070~null',
      owner: {
        hash_key: '1~1005070',
        range_key: 'v0_customer',
        customer_type: 'BT',
        customer_number: '1005070',
      },
      general_information: {
        default: true,
        service_type: 'mechanical',
        profile_type: 'onsite',
        participates_in_approve_orders: true,
        profile_name: 'test ariel',
        po_required_for_service: false,
        when_should_po_be_provided: null,
        driver_name_required: true,
        driver_signature_required: false,
        set_all_locations: false,
        __typename: 'BillingGeneralInformation',
      },
      ers_purchasing_procedures: {
        required_authorization_to_begin_service: 'po',
        primary_method_to_request_service: 'fleet_contact',
        secondary_method_to_request_service: 'driver',
        method_to_receive_signed_order: 'Select',
        pri_phone_number: null,
        sec_phone_number: null,
        ers_ship_to_hash_key: null,
        pri_tire_po_issuers: null,
        sec_tire_po_issuers: null,
        required_for_ers_event: null,
        signed_order_receiver_location: null,
        signed_order_receiver_contact: null,
        signed_order_receiver_other: '',
        pri_ers_authorized_contact_type: ['po_issuer'],
        pri_ers_authorized_contact_level: 'ST',
        sec_ers_authorized_contact_type: null,
        sec_ers_authorized_contact_level: null,
        ers_st_location: null,
        receiver_location: null,
        receiver_contact: null,
        __typename: 'BillingPurchasingProcedures',
      },
      onsite_purchasing_procedures: {
        required_authorization_to_begin_service: 'po',
        primary_method_to_request_service: 'fleet_contact',
        secondary_method_to_request_service: 'driver',
        method_to_receive_signed_order: 'Select',
        pri_phone_number: null,
        sec_phone_number: null,
        pri_tire_po_issuers: null,
        sec_tire_po_issuers: null,
        signed_order_receiver_location: null,
        signed_order_receiver_contact: null,
        signed_order_receiver_other: '',
        pri_ers_authorized_contact_type: ['po_issuer'],
        pri_ers_authorized_contact_level: 'BT',
        sec_ers_authorized_contact_type: null,
        sec_ers_authorized_contact_level: null,
        receiver_location: null,
        receiver_contact: null,
        __typename: 'BillingPurchasingProcedures',
      },
      approvals: {
        ers_pre_approvals_limits: {
          maximum_num_of_tires: 1,
          maxium_num_of_wheels: 1,
          maximum_dollar_amount: null,
          __typename: 'BillingApprovalsLimits',
        },
        onsite_pre_approvals_limits: {
          maximum_num_of_tires: 1,
          maxium_num_of_wheels: 1,
          maximum_dollar_amount: 1,
          __typename: 'BillingApprovalsLimits',
        },
        __typename: 'BillingApprovals',
      },
      tire_details: [
        {
          off_installed: 'installed_tires',
          requested_details: ['remaining_tread_depth', 'size'],
          other: null,
          id: '8be6ffd2-f144-406f-9b1e-463ab3525035',
          billing_tire_casing_age: null,
          __typename: 'BillingTireRequestedInstalledDetails',
        },
        {
          off_installed: 'off_tires',
          requested_details: ['dot', 'other'],
          other: 'test',
          id: '4c4cf910-8234-490b-9940-350f6bf95760',
          billing_tire_casing_age: null,
          __typename: 'BillingTireRequestedInstalledDetails',
        },
      ],
      wheel_details: [
        {
          off_installed: BillingWheelOffInstalled.OFF_WHEELS,
          requested_details: [BillingWheelRequestedDetails.OTHER],
          other: 'aaa',
          id: '4aa5b43c-5c3c-4242-9d47-c6b378ec9c25',
        },
      ],
      requested_photos: {
        types: [],
        identifier_type: null,
        other_photos: '',
        __typename: 'BillingRequestedPhotos',
      },
      __typename: 'BillingPreferences',
    } as unknown as BillingPreferences;
  }
  return {
    profile: auxBillingProfile,
    locations: action !== 'create' ? [...LOCATIONS] : [],
    contacts: [],
    permission: {
      updatePermission: true,
      readPermission: true,
      updateByHierarchyEnabled: true,
    },
  };
};

const useGetMockBillingProfile = ({
  id,
  action,
  service,
  profile,
  location,
}: {
  id: string;
  action: string;
  service: ServiceParams | '';
  profile: ProfileParams | '';
  location?: Location;
}) => {
  return useQuery(
    [`get-billing-profile`, id, service, profile],
    () => getMockProfile(id, action, service, profile, location),
    {
      cacheTime: 1000 * 60 * 10,
      keepPreviousData: true,
      staleTime: 1000 * 60 * 9,
    },
  );
};

export { useUpdateBillingProfile, useGetBillingProfile, useDeleteBillingProfile, useGetMockBillingProfile };