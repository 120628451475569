import {useQuery} from "@tanstack/react-query";
import {BillingPreferences} from "../../../../components/Billing/Preferences/query";

const getProfile = async (id: string):Promise<BillingPreferences> => {
    await new Promise(resolve => setTimeout(resolve, 2000));
    return {
        "assigned_locations": [],
        "gsi1_hash_key": "1~1005070",
        "owner_key": "1~1005070",
        "owner_relationship": "1290948~1290947~1005070~null",
        "owner": {
            "hash_key": "1~1005070",
            "range_key": "v0_customer",
            "customer_type": "BT",
            "customer_number": "1005070",
        },
        "general_information": {
            "default": true,
            "service_type": "mechanical",
            "profile_type": "onsite",
            "participates_in_approve_orders": false,
            "profile_name": "test ariel",
            "po_required_for_service": true,
            "when_should_po_be_provided": null,
            "driver_name_required": null,
            "driver_signature_required": null,
            "set_all_locations": true,
            "__typename": "BillingGeneralInformation"
        },
        "ers_purchasing_procedures": {
            "required_authorization_to_begin_service": "po",
            "primary_method_to_request_service": "fleet_contact",
            "secondary_method_to_request_service": "driver",
            "method_to_receive_signed_order": "Select",
            "pri_phone_number": null,
            "sec_phone_number": null,
            "ers_ship_to_hash_key": null,
            "pri_tire_po_issuers": null,
            "sec_tire_po_issuers": null,
            "required_for_ers_event": null,
            "signed_order_receiver_location": null,
            "signed_order_receiver_contact": null,
            "signed_order_receiver_other": "",
            "pri_ers_authorized_contact_type": [
                "po_issuer"
            ],
            "pri_ers_authorized_contact_level": "ST",
            "sec_ers_authorized_contact_type": null,
            "sec_ers_authorized_contact_level": null,
            "ers_st_location": null,
            "receiver_location": null,
            "receiver_contact": null,
            "__typename": "BillingPurchasingProcedures"
        },
        "onsite_purchasing_procedures": {
            "required_authorization_to_begin_service": "po",
            "primary_method_to_request_service": "fleet_contact",
            "secondary_method_to_request_service": "driver",
            "method_to_receive_signed_order": "Select",
            "pri_phone_number": null,
            "sec_phone_number": null,
            "pri_tire_po_issuers": null,
            "sec_tire_po_issuers": null,
            "signed_order_receiver_location": null,
            "signed_order_receiver_contact": null,
            "signed_order_receiver_other": "",
            "pri_ers_authorized_contact_type": [
                "po_issuer"
            ],
            "pri_ers_authorized_contact_level": "BT",
            "sec_ers_authorized_contact_type": null,
            "sec_ers_authorized_contact_level": null,
            "receiver_location": null,
            "receiver_contact": null,
            "__typename": "BillingPurchasingProcedures"
        },
        "approvals": {
            "ers_pre_approvals_limits": {
                "maximum_num_of_tires": 1,
                "maxium_num_of_wheels": 1,
                "maximum_dollar_amount": null,
                "__typename": "BillingApprovalsLimits"
            },
            "onsite_pre_approvals_limits": {
                "maximum_num_of_tires": 1,
                "maxium_num_of_wheels": 1,
                "maximum_dollar_amount": 1,
                "__typename": "BillingApprovalsLimits"
            },
            "__typename": "BillingApprovals"
        },
        "tire_details": null,
        "wheel_details": null,
        "requested_photos": {
            "types": [],
            "identifier_type": null,
            "other_photos": "",
            "__typename": "BillingRequestedPhotos"
        },
        "__typename": "BillingPreferences"

    } as unknown as BillingPreferences;
}

const useGetProfile = (id: string) => {
    return useQuery(
        ["get-billing-profile", id],
        () =>
            getProfile(id),
        {
            cacheTime: 1000 * 60 * 10,
            keepPreviousData: true,
            staleTime: 1000 * 60 * 9,
        }
    );
}

export {useGetProfile};
