import { MaestroAPI, maestroApiCall } from '@michelin/central-provider';
import { MethodType } from '@michelin/central-provider/dist/helpers/createAPICall';
import { useQuery } from '@tanstack/react-query';
import GLOBAL_CONFIG from 'global-variables';
import { LocalDealerAndNationAccount } from '../modules/Accounts/types';

const baseURL = GLOBAL_CONFIG.externalAPIUrl;

const getAccount = async (selectedAccount: string, hashKey: string): Promise<LocalDealerAndNationAccount | null> => {
  if (!selectedAccount || !hashKey) return null;
  const apiType: MaestroAPI = {
    method: MethodType.GET,
    endpoint: `/cp-be/accounts/${selectedAccount}/${hashKey}`,
    baseURL: baseURL || '',
  };
  const response = await maestroApiCall<LocalDealerAndNationAccount>(apiType);

  return response;
};

export function useGetAccount({ selectedAccount, hashKey }: { selectedAccount: string; hashKey: string }) {
  return useQuery(['account', selectedAccount || '', hashKey || ''], () => {
    return getAccount(selectedAccount, hashKey);
  });
}
