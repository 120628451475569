import React from 'react';
import { FormControlLabel, Grid } from '@material-ui/core';
import { Panel, PhoneInput, Switch, TextField } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { SelectOption } from '@michelin/select-options-provider';
import { auth } from 'Auth';
import { isRequired, isValidEmail, isValidPhoneNumber, isValidZeroPositiveInt } from 'components/Util/validate';
import LocationAssignments from '../../LocationAssignments';
import { AssigneeCustomerType } from '../../LocationAssignments/queries';
import { Contact } from '../utils';
import { getDefaultIndex } from './utils';

interface Props {
  viewMode: boolean;
  errorFields: Set<string>;
  contact: Contact;
  preferredCommunicationOptions: Array<SelectOption>;
  preferredLanguageOptions: Array<SelectOption>;
  contactLevelOptions: Array<SelectOption>;
  disableAssignLocations?: boolean;
  disableContactLevel?: boolean;
  updateValueHandler: (
    validateFunction?: (field: any) => boolean,
    errorField?: string,
  ) => (event: any, newValue?: any) => void;
  contactLevelUpdateValueHandler: (event: any, newValue?: any) => void;
  locationsUpdateHandler: (locations: AssigneeCustomerType[]) => void;
}

export default function GeneralInformation(props: Props): JSX.Element {
  const {
    viewMode: disabled,
    errorFields,
    contact,
    disableAssignLocations,
    disableContactLevel,
    preferredCommunicationOptions,
    preferredLanguageOptions,
    contactLevelOptions,
    updateValueHandler,
    contactLevelUpdateValueHandler,
    locationsUpdateHandler,
  } = props;

  const { t } = useTranslation();

  return (
    <Panel title={t('General Info')}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            id="first_name"
            label={t('First Name')}
            error={errorFields.has('firstName')}
            defaultValue={contact.first_name}
            onChange={updateValueHandler(isRequired, 'firstName')}
            style={{ paddingRight: '1rem' }}
            required
            readOnly={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            id="last_name"
            label={t('Last Name')}
            error={errorFields.has('lastName')}
            defaultValue={contact.last_name}
            onChange={updateValueHandler(isRequired, 'lastName')}
            style={{ paddingRight: '1rem' }}
            required
            readOnly={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            id="job_title"
            label={t('Job Title')}
            error={errorFields.has('jobTitle')}
            defaultValue={contact.job_title}
            onChange={updateValueHandler(isRequired, 'jobTitle')}
            style={{ paddingRight: '1rem' }}
            required
            readOnly={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            id="email_address"
            label={t('Email')}
            error={errorFields.has('email')}
            defaultValue={contact.email_address}
            onChange={updateValueHandler(isValidEmail, 'email')}
            style={{ paddingRight: '1rem' }}
            readOnly={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <PhoneInput
            id="cell_phone"
            label={t('Cell Phone')}
            value={contact.cell_phone ? contact.cell_phone : undefined}
            defaultCountry={auth.getCountryCode() === 'ca' ? 'CA' : 'US'}
            readOnly={disabled}
            onChange={(event: any) => {
              const updateFunction = updateValueHandler(isValidPhoneNumber, 'cellPhone');
              // eslint-disable-next-line no-param-reassign
              event.target = {
                id: event.id,
                value: event.value,
              };
              updateFunction(event);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <PhoneInput
            id="fax"
            label={t('Fax')}
            value={contact.fax}
            defaultCountry={auth.getCountryCode() === 'ca' ? 'CA' : 'US'}
            readOnly={disabled}
            onChange={(event: any) => {
              const updateFunction = updateValueHandler(isValidPhoneNumber, 'fax');
              // eslint-disable-next-line no-param-reassign
              event.target = {
                id: event.id,
                value: event.value,
              };
              updateFunction(event);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <PhoneInput
            id="work_phone"
            label={t('Work Phone')}
            value={contact.work_phone ? contact.work_phone : undefined}
            defaultCountry={auth.getCountryCode() === 'ca' ? 'CA' : 'US'}
            readOnly={disabled}
            onChange={(event: any) => {
              const updateFunction = updateValueHandler(isValidPhoneNumber, 'work_phone');
              // eslint-disable-next-line no-param-reassign
              event.target = {
                id: event.id,
                value: event.value,
              };
              updateFunction(event);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            id="ext"
            label={t('Ext')}
            defaultValue={contact.ext}
            onChange={updateValueHandler(isValidZeroPositiveInt, 'ext')}
            style={{ paddingRight: '1rem' }}
            readOnly={disabled}
            error={errorFields.has('ext')}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            id="preferred_method"
            select
            label={t('Preferred Method')}
            style={{ display: 'flex', flexWrap: 'wrap', paddingRight: '1rem' }}
            onChange={(event) => updateValueHandler()({ target: { id: 'preferred_method' } }, event.target.value)}
            readOnly={disabled}
            SelectProps={{
              options: preferredCommunicationOptions,
            }}
            value={
              preferredCommunicationOptions[getDefaultIndex(preferredCommunicationOptions, contact.preferred_method)]
                .value
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            id="preferred_language"
            select
            label={t('Preferred Language')}
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              paddingRight: '1rem',
              minWidth: 'unset',
            }}
            onChange={(event) => updateValueHandler()({ target: { id: 'preferred_language' } }, event.target.value)}
            readOnly={disabled}
            SelectProps={{
              options: preferredLanguageOptions,
            }}
            value={
              preferredLanguageOptions[getDefaultIndex(preferredLanguageOptions, contact.preferred_language)].value
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField
            id="contact_level"
            select
            label={t('Contact Level')}
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              paddingRight: '1rem',
              minWidth: 'unset',
            }}
            SelectProps={{
              options: contactLevelOptions,
            }}
            value={contactLevelOptions[getDefaultIndex(contactLevelOptions, contact.contact_level)].value}
            onChange={contactLevelUpdateValueHandler}
            required
            readOnly={disabled || disableContactLevel === true}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <div style={{ marginTop: '1rem' }}>
            <LocationAssignments
              mode={props.viewMode ? 'view' : 'edit'}
              all={false}
              locations={contact.locations ? contact.locations.map((rel) => ({ hash_key: rel.hash_key })) : []}
              disabled={disableAssignLocations === true}
              filterByCustomerType={contact.contact_level}
              displayCurrentLocation
              owner=""
              onUpdate={locationsUpdateHandler}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <FormControlLabel
            value={contact.group}
            control={
              <span>
                <Switch
                  id="group"
                  checked={contact.group}
                  onChange={updateValueHandler()}
                  color="primary"
                  readOnly={disabled}
                  checkedLabel={t('Group')}
                  uncheckedLabel={t('Individual')}
                />
              </span>
            }
            labelPlacement="start"
            label=""
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={8}>
          <FormControlLabel
            value={contact.visible_externally}
            labelPlacement="start"
            label={t('Visible to Service Provider')}
            control={
              <span style={{ marginRight: '2rem' }}>
                <Switch
                  id="visible_externally"
                  onChange={updateValueHandler()}
                  color="primary"
                  checked={contact.visible_externally}
                  readOnly={disabled}
                />
              </span>
            }
          />
        </Grid>
      </Grid>
    </Panel>
  );
}
