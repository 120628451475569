import React from 'react';
import { Button, Dialog, DialogActions } from '@material-ui/core';
import { useTranslation } from '@michelin/central-provider';
import { ClosableDialogContent } from 'components/ClosableDialog/ClosableDialogContent';
import { ClosableDialogTitle } from 'components/ClosableDialog/ClosableDialogTitle';
import { Contact } from 'components/Contact/utils';
import PORequiredManageTable from '../Tables/poRequiredManageTable';

interface Props {
  levelsPerLocationHashKey: Map<string, Map<string, Contact>>;
  comboPerLocationHashKey: Map<string, Contact>;
  open: boolean;
  contactTypeLevel: string;
  contactTypes: Array<string>;
  onCloseHandler: () => void;
}

export default function PORequiredManageDialog(props: Props): JSX.Element {
  const { levelsPerLocationHashKey, open, onCloseHandler, contactTypes, contactTypeLevel, comboPerLocationHashKey } =
    props;
  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      scroll="paper"
      open={open}
      onClose={onCloseHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <ClosableDialogTitle onClose={onCloseHandler}>{t('Contacts to Authorize Service')}</ClosableDialogTitle>
      <ClosableDialogContent dividers style={{ padding: 0 }}>
        <PORequiredManageTable
          levelsPerLocationHashKey={levelsPerLocationHashKey}
          comboPerLocationHashKey={comboPerLocationHashKey}
          contactTypes={contactTypes}
          contactTypeLevel={contactTypeLevel}
        />
      </ClosableDialogContent>
      <DialogActions>
        <Button onClick={onCloseHandler} color="primary">
          {t('Ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
