import React, { useEffect, useMemo, useState } from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { Add, Delete, Edit } from '@material-ui/icons';
import {
  Fab,
  FilterGroup,
  GlobalActions,
  IconButton,
  Panel,
  useDialogs,
  useListSelectFilter,
} from '@michelin/acid-components';
import {
  clearMaestroDataSourceCache,
  useMaestroDataSource,
  usePermissions,
  useTranslation,
} from '@michelin/central-provider';
import { getOptionLabel, getOptions } from '@michelin/select-options-provider';
import CreatedByAvatar from 'components/CreatedByAvatar';
import { NewLocationAssignments } from 'components/NewLocationAssignments';
import { CONTENT_AREA_HEIGHT } from 'components/Util';
import { Template } from 'devextreme-react';
import DataGrid, { Column, SearchPanel } from 'devextreme-react/data-grid';
import GLOBAL_CONFIG from 'global-variables';
import { useDeleteAccount } from 'hooks/useAccounts';
import { useSnackbar } from 'notistack';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getBaseUrl } from '../../../prefs-and-service-offers';
import { AccountDetail } from '../Detail';
import { LocalDealerAndNationAccount } from '../types';

type AccountListProps = {
  action: 'add' | 'edit' | 'list';
  serviceOfferIds: string;
};

const baseURL = GLOBAL_CONFIG.externalAPIUrl;

export function AccountsTable(props: AccountListProps) {
  /* **************** */
  /* Local Variables */
  /* *************** */
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch<{ accountId: string; selectedAccount: string }>();
  const { confirmDialog } = useDialogs();
  const { enqueueSnackbar } = useSnackbar();
  const permissions = usePermissions();
  const [accountFilterValue, setAccountFilterValue] = useState('all');
  const [creatorFilterValue, setCreatorFilterValue] = useState('all');
  const theme = useTheme();
  const cellView = useMediaQuery(theme.breakpoints.down(500));
  const [saving, setSaving] = useState<boolean>(false);

  /* *********** */
  /* Permissions */
  /* *********** */
  const createPermission = permissions.allowsAction('accounts.create');
  const listPermission = permissions.allowsAction('accounts.list');
  const readPermission = permissions.allowsAction('accounts.read');

  if (!listPermission) {
    enqueueSnackbar(t('User has no permissions to list accounts.'), {
      variant: 'warning',
    });
    history.push(getBaseUrl());
  } else if (!readPermission) {
    enqueueSnackbar(t('User has no permissions to read accounts.'), {
      variant: 'warning',
    });
    history.push(getBaseUrl());
  }

  /* *********** */
  /* Delete      */
  /* *********** */
  const deleteAccount = useDeleteAccount();

  /* *********** */
  /* Listing    */
  /* *********** */

  const accountTypeFilter = useListSelectFilter({
    label: t('Account Type'),
    options: getOptions('ld_na_account_types', 'all', t('All')),
    defaultValue: accountFilterValue,
    hashParam: 'accountType',
  });

  const creatorFilter = useListSelectFilter({
    label: t('Creator'),
    options: getOptions('account_types', 'all', t('All')),
    defaultValue: creatorFilterValue,
    hashParam: 'creator',
  });

  const filterGroup = useMemo(
    () => new FilterGroup([creatorFilter.filter, accountTypeFilter.filter]),
    [creatorFilter.filter, accountTypeFilter.filter],
  );

  const ds = useMaestroDataSource({
    API: {
      method: 'GET',
      endpoint: `cp-be/accounts/${permissions.location?.customer_number || ''}`,
      baseURL,
    },
    reqOptions: {
      filters: {
        accountType: accountTypeFilter.value === 'all' ? '' : accountTypeFilter.value,
        creator: creatorFilter.value === 'all' ? '' : creatorFilter.value?.toLowerCase(),
      },
    },
    componentPlacements: {
      searchPlacement: cellView ? 'outside' : 'embeded',
    },
    filterGroup,
    cacheKey: `accounts-${permissions.location?.customer_number || ''}`,
  });

  /* ******** */
  /* Functions */
  /* ******* */

  const reloadAfterAction = () => {
    async function reload() {
      clearMaestroDataSourceCache(`accounts-${permissions.location?.customer_number || ''}`);
      await ds.dataSource.reload();
    }
    reload();
  };

  /* ******************** */
  /* Render Cell Methods  */
  /* ******************** */

  // eslint-disable-next-line no-shadow
  const CreatedByCell = ({ row }: { row: LocalDealerAndNationAccount }) => {
    if (!row || !row.owner) return null;
    return <CreatedByAvatar customerType={row.owner.customer_type} customerNumber={row.owner.customer_number} />;
  };

  const LocationsCell = ({ row }: { row: LocalDealerAndNationAccount }) => (
    <NewLocationAssignments
      owner={row.gsi1_hash_key.split('~').pop() || ''}
      mode="view"
      all={row.set_all_locations}
      locations={row.locations.map((rel: any) => ({
        hash_key: rel.range_key,
      }))}
      disabled={saving}
    />
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-shadow, no-unused-vars
  function ActionsCell({ row }: { row: LocalDealerAndNationAccount }) {
    const canDelete = row.allowedActions?.delete;
    const canUpdate = row.allowedActions?.update;
    return (
      <div>
        {canUpdate ? (
          <IconButton
            aria-label="edit"
            style={{ margin: '8px' }}
            onClick={() => {
              history.push(`${getBaseUrl()}/accounts/edit/${row.hash_key.split('~').pop()}`);
            }}
            size="small"
            disabled={saving}
          >
            <Edit fontSize="small" color={saving ? 'disabled' : 'primary'} />
          </IconButton>
        ) : null}
        {canDelete ? (
          <IconButton
            aria-label="delete"
            style={{ margin: '8px' }}
            onClick={async () => {
              confirmDialog(
                t('Are you sure you want to delete this account?'),
                t('Delete Account'),
                t('Yes'),
                t('No'),
                async () => {
                  setSaving(true);
                  try {
                    const response = await deleteAccount.mutateAsync({
                      fleetId: permissions.location?.customer_number || '',
                      accountKey: row.hash_key,
                    });
                    if (!response) {
                      enqueueSnackbar(t('Error deleting account.'), {
                        variant: 'error',
                      });
                      setSaving(false);
                    } else {
                      enqueueSnackbar(t('Account Deleted'), {
                        variant: 'success',
                      });
                    }
                  } catch (error) {
                    enqueueSnackbar(t('Error deleting account.'), {
                      variant: 'error',
                    });
                  }
                  reloadAfterAction();
                  setSaving(false);
                },
                () => {
                  setSaving(false);
                },
                {
                  onClose: () => setSaving(false),
                },
              );
            }}
            size="small"
            disabled={saving}
          >
            <Delete fontSize="small" color={saving ? 'disabled' : 'primary'} />
          </IconButton>
        ) : null}
      </div>
    );
  }

  /* ********** */
  /* useEffects */
  /* ********** */
  useEffect(() => {
    if (accountTypeFilter.value) {
      setAccountFilterValue(accountTypeFilter.value);
    }
  }, [accountTypeFilter.value]);

  useEffect(() => {
    if (creatorFilter.value) {
      setCreatorFilterValue(creatorFilter.value);
    }
  }, [creatorFilter.value]);

  useEffect(() => {
    setCreatorFilterValue('all');
    setAccountFilterValue('all');
  }, [props.serviceOfferIds]);

  useEffect(
    () => () => {
      clearMaestroDataSourceCache(`accounts-${permissions.location?.customer_number || ''}`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  /* ********** */
  /* Render     */
  /* ********** */
  return (
    <Panel spacing={0}>
      {props.action === 'list' ? null : (
        <AccountDetail
          onClose={() => {}}
          onSave={() => {
            reloadAfterAction();
          }}
          action={match.params.accountId ? 'edit' : 'add'}
          selectedAccount={match.params.selectedAccount}
          accountId={match.params.accountId}
        />
      )}

      <DataGrid
        id="accountsTable"
        style={{ height: CONTENT_AREA_HEIGHT }}
        noDataText={t('No data found...')}
        scrolling={{ mode: 'virtual', rowRenderingMode: 'virtual' }}
        dataSource={ds.dataSource}
        allowColumnResizing
        wordWrapEnabled
        remoteOperations
        columnAutoWidth
        showBorders
        onToolbarPreparing={ds.onToolbarPreparing}
        className="freespaced-table"
      >
        <Template name="filterFields" render={ds.renderFilterFields} />
        <Template name="resultsCounter" render={ds.renderResultsCounter} />
        <SearchPanel visible highlightSearchText={false} placeholder={t('Search...')} />

        <Column
          caption={t('Creator')}
          dataType="string"
          dataField="owner.customer_type"
          allowSorting
          width={150}
          alignment="center"
          cellRender={({ row }) => <CreatedByCell row={row.data as LocalDealerAndNationAccount} />}
        />

        <Column
          caption={t('Account Type')}
          dataType="string"
          dataField="account_type"
          width={200}
          defaultSortOrder="asc"
          cellRender={({ row }) => <span>{getOptionLabel('ld_na_account_types', row.data.account_type)}</span>}
        />

        <Column caption={t('Account Number')} dataType="string" dataField="ld_na_number" width={200} />

        <Column caption={t('Company Name')} dataType="string" dataField="company" minWidth={200} />

        <Column
          visible={permissions.location?.customer_type !== 'ST'}
          caption={t('Locations')}
          allowSorting={false}
          width={150}
          cellRender={({ row }) => <LocationsCell row={row.data as LocalDealerAndNationAccount} />}
          dataField="locationAssignments"
          allowSearch={false}
        />
        <Column
          allowSearch={false}
          allowSorting={false}
          width={120}
          cellRender={(row) => <ActionsCell row={row.data as LocalDealerAndNationAccount} />}
        />
      </DataGrid>

      <GlobalActions>
        <Fab
          color="primary"
          label={t('Add')}
          onClick={() => history.push(`${getBaseUrl()}/accounts/add`)}
          disabled={!createPermission || saving}
        >
          <Add />
        </Fab>
      </GlobalActions>
    </Panel>
  );
}
