import gql from 'graphql-tag';
import { schemaTireProfile, schemaTireProfileAxles, schemaTireProfileMain } from '../../AppSync/schemaTireProfile';

export const updateTireProfileMutation = gql`
mutation UpdateTireProfile($hash_key: String!, $input : TireProfileInput!) {
  updateTireProfile(hash_key: $hash_key, input: $input)
    ${schemaTireProfile}
}
`;

export const createTireProfileMutation = gql`
mutation CreateTireProfile($hash_key: String!, $input : TireProfileInput!) {
  createTireProfile(hash_key: $hash_key, input: $input)
    ${schemaTireProfile}
}
`;

export const updateTireProfileAxlesMutation = gql`
mutation TireProfileAxles($hash_key: String!, $input : TireProfileAllAxlesInput!) {
  updateTireProfileAxles(hash_key: $hash_key, input: $input)
    ${schemaTireProfileAxles}
}
`;

export const updateTireProfileMainMutation = gql`
mutation TireProfileMain($hash_key: String!, $input : TireProfileMainInput!) {
  updateTireProfileMain(hash_key: $hash_key, input: $input)
    ${schemaTireProfileMain}
}
`;
