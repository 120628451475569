import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { PageTitle, Panel } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { BillingReassignContactType } from 'components/Billing/Preferences/ReassignContactTypes/dialogs';
import { getChildrenAndParentsFromArray } from 'components/Billing/Preferences/ReassignContactTypes/utils';
import { Contact } from 'components/Contact/utils';
import { useParams } from 'react-router-dom';
import LastModified from '../../../components/ChangeLog/LastModified';
import { ContactPicker } from './Components/ContactPicker';
import { OnSitePurchasingProcedures } from './Panels/OnSitePurchasingProcedures';
import { RequestedPhotosDetail } from './Panels/RequestedPhotosDetail';
import { RequestedTiresDetail } from './Panels/RequestedTiresDetail';
import { RequestedWheelsDetail } from './Panels/RequestedWheelsDetail';
import { useGetProfile } from './hooks/useProfile';
import { BillingProfileLocation, DEFAULT_ERROR_VALIDATION, LOCATIONS, ValidationErrors } from './types';

type IProps = {
  action: string;
};

interface ViewParams {
  selectedAccount: string;
  profileId: string;
}

interface IBillingProfileContext {
  contacts: Contact[];
  expLocations: BillingProfileLocation[];
  assignedContactTypes: Map<string, BillingReassignContactType>;
}
export const BillingProfileContext = React.createContext<IBillingProfileContext>({
  contacts: [],
  expLocations: [],
  assignedContactTypes: new Map(),
});

const BillingProfileDetails = (props: IProps) => {
  const { t } = useTranslation();
  const params = useParams<ViewParams>();
  const billingProfileQuery = useGetProfile(params?.profileId);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [allLocations, setAllLocations] = useState<undefined | BillingProfileLocation[]>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [locations, setLocations] = useState<undefined | BillingProfileLocation[]>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>(DEFAULT_ERROR_VALIDATION);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ loading: loadingContacts, data: contacts, allData: allContacts }, setContactsState] = useState<{
    loading: boolean;
    error: any;
    data: undefined | Contact[];
    allData: undefined | Contact[];
  }>({
    loading: true,
    error: undefined,
    data: undefined,
    allData: undefined,
  });
  const [expLocations, setExpLocations] = useState<BillingProfileLocation[]>([]);
  useEffect(() => {
    setExpLocations(getChildrenAndParentsFromArray(locations || [], allLocations || []));
  }, [locations, allLocations]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [assignedContactTypes, setAssignedContactTypes] = useState(new Map<string, BillingReassignContactType>());

  if (billingProfileQuery?.isLoading) {
    return <div>Loading...</div>;
  }
  if (billingProfileQuery?.isError || !billingProfileQuery?.data) {
    return <div>Error...</div>;
  }

  return (
    <BillingProfileContext.Provider value={{ contacts: contacts || [], expLocations, assignedContactTypes }}>
      <PageTitle
        title={props?.action?.toLowerCase() === 'create' ? t('Create Billing Profile') : t('Billing Profile Details')}
        rightItems={
          props?.action?.toLowerCase() === 'create' ? null : (
            <LastModified entity="billing_profile" identifier={`${params?.profileId}`} />
          )
        }
      />
      <Panel spacing={2}>
        <ContactPicker />
      </Panel>
      <Panel spacing={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OnSitePurchasingProcedures
              loading={false}
              modifiedFlag={true}
              setModifiedFlag={() => {}}
              profile={billingProfileQuery.data}
              editProfile={() => {}}
              profileType={'ers_onsite'}
              editFlag={false}
              locations={LOCATIONS}
              setLocations={() => {}}
              contacts={allContacts || []}
              validationErrors={validationErrors.pp_os}
              handleContactLevelChange={() => {}}
            />
          </Grid>
          <Grid item xs={12}>
            <RequestedTiresDetail action="view" />
          </Grid>
          <Grid item xs={12}>
            <RequestedWheelsDetail action="view" />
          </Grid>
          <Grid item xs={12}>
            <RequestedPhotosDetail
              modifiedFlag={true}
              profile={billingProfileQuery.data}
              editFunction={() => {}}
              setModifiedFlag={() => {}}
              editFlag={true}
            />
          </Grid>
        </Grid>
      </Panel>
    </BillingProfileContext.Provider>
  );
};

export { BillingProfileDetails };
