/* eslint-disable max-len */

/* eslint-disable prefer-destructuring */

/* eslint-disable no-param-reassign */
import { getOptionLabel } from '@michelin/select-options-provider';
import { auth } from 'Auth';
import { queryTireProfileList } from '../AppSync/Queries/queryTireProfileList';
import {
  IExistingProfile,
  IExistingTireProfiles,
  ITireProfile,
  ITireReplacementPriority,
  IUniqueTireProfile,
} from '../TireProfileTypes';

let tireProfileList: ITireProfile[] = [];

export interface Option {
  id: string;
  name: string;
  [key: string]: string;
}

export const getExistingProfiles = async (): Promise<IExistingTireProfiles | undefined> => {
  if (!auth || !auth.apolloClient) return Promise.resolve(undefined);

  // this will get all profiles at my level and below - some of which are not assigned to my children
  const myCustomerNumber = `1~${auth.getCustomerNumber()}`;

  let result: any = {};
  try {
    result = await auth.apolloClient.query({
      query: queryTireProfileList,
      variables: { hashKey: myCustomerNumber, profileType: 'all' },
      fetchPolicy: 'network-only',
    });

    tireProfileList = result.data.getCustomerTireProfiles;

    const existingTireProfileList: IExistingTireProfiles = {
      qty_profiles_owned: 0,
      has_ers_default: false,
      has_onsite_default: false,
      has_both_default: false,
      profiles: {
        owned: [],
        assigned: [],
        children: [],
      },
    };

    // get the ones I own
    const myOwnedProfiles: IExistingProfile[] = [];
    result.data.getCustomerTireProfiles.forEach((profile: ITireProfile) => {
      if (profile.owner.customer_number === myCustomerNumber.substring(2)) {
        myOwnedProfiles.push({
          locations: profile.locations || [],
          location: profile.gsi1_hash_key,
          profile_name: profile.main.profile_name,
          hash_key: profile.hash_key,
          profile_definition: {
            default: profile.main.default,
            profile_type: profile.main.profile_type,
            application_type: profile.main.application_type,
            vehicle_type: profile.main.vehicle_type,
            vehicle_attrs_tractor: profile.main.vehicle_attrs_tractor,
            vehicle_attrs_trailer: profile.main.vehicle_attrs_trailer,
          },
          axles: profile.axles,
        });
      }
    });

    // get the ones assigned to me
    const myAssignedProfiles: IExistingProfile[] = [];
    result.data.getCustomerTireProfiles.forEach((profile: ITireProfile) => {
      if (profile && profile.locations) {
        profile.locations.forEach((location: any) => {
          if (location.range_key === myCustomerNumber) {
            myAssignedProfiles.push({
              locations: profile.locations || [],
              location: profile.gsi1_hash_key,
              profile_name: profile.main.profile_name,
              hash_key: profile.hash_key,
              profile_definition: {
                default: profile.main.default,
                profile_type: profile.main.profile_type,
                application_type: profile.main.application_type,
                vehicle_type: profile.main.vehicle_type,
                vehicle_attrs_tractor: profile.main.vehicle_attrs_tractor,
                vehicle_attrs_trailer: profile.main.vehicle_attrs_trailer,
              },
              axles: profile.axles,
            });
          }
        });
      }
    });

    // get my children's profiles
    const myChildrenProfiles: IExistingProfile[] = [];
    result.data.getCustomerTireProfiles.forEach((profile: ITireProfile) => {
      if (profile) {
        // do I own this account ?
        const doIOwnThisAccountType = auth.iOwnThisAccountType(profile.owner.customer_type);
        if (doIOwnThisAccountType !== undefined && doIOwnThisAccountType > 0) {
          myChildrenProfiles.push({
            locations: profile.locations || [],
            location: profile.gsi1_hash_key,
            profile_name: profile.main.profile_name,
            hash_key: profile.hash_key,
            profile_definition: {
              default: profile.main.default,
              profile_type: profile.main.profile_type,
              application_type: profile.main.application_type,
              vehicle_type: profile.main.vehicle_type,
              vehicle_attrs_tractor: profile.main.vehicle_attrs_tractor,
              vehicle_attrs_trailer: profile.main.vehicle_attrs_trailer,
            },
            axles: profile.axles,
          });
        }
      }
    });

    myOwnedProfiles.forEach((profile: IExistingProfile) => {
      existingTireProfileList.qty_profiles_owned++; // cause if owned === 0 must do a default
      if (
        profile.profile_definition.profile_type &&
        profile.profile_definition.profile_type.toLowerCase() === 'ers_onsite' &&
        profile.profile_definition.default
      )
        existingTireProfileList.has_both_default = true;

      if (
        profile.profile_definition.profile_type &&
        profile.profile_definition.profile_type.toLowerCase() === 'ers' &&
        profile.profile_definition.default
      )
        existingTireProfileList.has_ers_default = true;

      if (
        profile.profile_definition.profile_type &&
        profile.profile_definition.profile_type.toLowerCase() === 'onsite' &&
        profile.profile_definition.default
      )
        existingTireProfileList.has_onsite_default = true;
    });

    existingTireProfileList.profiles.owned = myOwnedProfiles;
    existingTireProfileList.profiles.assigned = myAssignedProfiles;
    existingTireProfileList.profiles.children = myChildrenProfiles;

    return Promise.resolve(existingTireProfileList);
  } catch (e) {
    console.log('Error: TireProfileListHierarchy: could not retrieve data: error:', JSON.stringify(e, null, 7));
  }

  return Promise.resolve(undefined);
};

export const checkUniqueProfileAtLevel = (
  profileToCheck: ITireProfile,
  profilesToCheckAgainst: IExistingTireProfiles,
  t: any,
): IUniqueTireProfile => {
  let totalMsg: string[] = [];

  const combinedProfilesToCheckAgainst: IExistingProfile[] = profilesToCheckAgainst.profiles.owned.concat(
    profilesToCheckAgainst.profiles.assigned,
  );

  let uniqueProfile: IUniqueTireProfile = {
    isUnique: true,
    nonUniqueMessage: [],
  };

  const BreakException = {};
  combinedProfilesToCheckAgainst.forEach((profileToCheckAgainst: IExistingProfile) => {
    if (profileToCheckAgainst.hash_key !== profileToCheck.hash_key) {
      // don't check against itself
      if (
        profileToCheckAgainst.profile_definition.profile_type === profileToCheck.main.profile_type &&
        // && profileToCheckAgainst.profile_name === profileToCheck.main.profile_name // do I check for duplicate name ???
        profileToCheckAgainst.profile_definition.application_type === profileToCheck.main.application_type &&
        profileToCheckAgainst.profile_definition.vehicle_type === profileToCheck.main.vehicle_type &&
        profileToCheckAgainst.profile_definition.vehicle_attrs_tractor === profileToCheck.main.vehicle_attrs_tractor &&
        profileToCheckAgainst.profile_definition.vehicle_attrs_trailer === profileToCheck.main.vehicle_attrs_trailer
      ) {
        // check axles & tire sizes
        if (profileToCheckAgainst.axles) {
          try {
            let mainMsg: string[] = [];
            if (totalMsg.length > 0) {
              mainMsg.push('======================================================');
            }
            mainMsg.push(
              t(
                'A tire profile assigned to this location named {{profileName}} already exists with identical key values',
                { profileName: profileToCheckAgainst.profile_name && profileToCheckAgainst.profile_name.toUpperCase() },
              ),
            );
            mainMsg.push('----------------------------------------------------------------------------------');
            mainMsg.push(
              `• ${t('Profile Type')} = ${getOptionLabel(
                'profile_types',
                (profileToCheck.main.profile_type && profileToCheck.main.profile_type) || '',
              )}`,
            );
            mainMsg.push(
              `• ${t('Application Type')} = ${getOptionLabel(
                'application_types',
                (profileToCheck.main.application_type && profileToCheck.main.application_type) || '',
              )}`,
            );
            mainMsg.push(
              `• ${t('Vehicle Type')} = ${profileToCheck.main.vehicle_type && profileToCheck.main.vehicle_type}`,
            );
            mainMsg.push(
              `• ${t('Vehicle Tractor Attribute')} = ${getOptionLabel(
                'vehicle_attrs_tractor',
                (profileToCheck.main.vehicle_attrs_tractor && profileToCheck.main.vehicle_attrs_tractor) || '',
              )}`,
            );
            mainMsg.push(
              `• ${t('Vehicle Trailer Attribute')} =${getOptionLabel(
                'vehicle_attrs_trailer',
                (profileToCheck.main.vehicle_attrs_trailer && profileToCheck.main.vehicle_attrs_trailer) || '',
              )}`,
            );

            const inAxleMsg: string[] = [];
            const axleMsg: string[] = [];
            Object.entries(profileToCheckAgainst.axles).forEach(([axle, definition]) => {
              if (definition && axle !== '__typename') {
                const tireReplacementPriorities: any[] = definition.tire_replacement_priority; // ones to check against
                tireReplacementPriorities.forEach((profileToCheckTireReplacementPriority: ITireReplacementPriority) => {
                  Object.entries(profileToCheck.axles).forEach(([thisAxle, thisDefinition]) => {
                    if (thisDefinition && thisAxle !== '__typename') {
                      const thisTireReplacementPriorities: any[] = thisDefinition.tire_replacement_priority; // ones to check against
                      thisTireReplacementPriorities.forEach((thisTireReplacementPriority: ITireReplacementPriority) => {
                        if (
                          thisTireReplacementPriority.tire_size === profileToCheckTireReplacementPriority.tire_size &&
                          axle === thisAxle
                        ) {
                          if (!inAxleMsg.includes(`${thisAxle}${profileToCheckTireReplacementPriority.tire_size}`)) {
                            inAxleMsg.push(`${thisAxle}${profileToCheckTireReplacementPriority.tire_size}`);
                            axleMsg.push(
                              `• ${t('Axle Position')} = ${getOptionLabel('axle_types', thisAxle && thisAxle)}`,
                            );
                            axleMsg.push(
                              `• ${t('Tire Size')} = ${
                                profileToCheckTireReplacementPriority.tire_size &&
                                profileToCheckTireReplacementPriority.tire_size.toUpperCase()
                              }`,
                            );
                          }
                        }
                      });
                    }
                  });
                });
              }
            });

            if (axleMsg.length) {
              // have to have a dup in axles to be a true profile dup
              mainMsg = mainMsg.concat(axleMsg);
              mainMsg.push('----------------------------------------------------------------------------------');
              mainMsg.push(
                t(
                  'Please change at least one of the key values listed above for this tire profile or for the existing tire profile called {{profileName}} to prevent duplication',
                  {
                    profileName: profileToCheckAgainst.profile_name && profileToCheckAgainst.profile_name.toUpperCase(),
                  },
                ),
              );
              totalMsg = totalMsg.concat(mainMsg);

              uniqueProfile = {
                isUnique: false,
                profileName: profileToCheck.main.profile_name,
                profileType: profileToCheck.main.profile_type,
                applicationType: profileToCheck.main.application_type,
                vehicleType: profileToCheck.main.vehicle_type,
                vehicleAttrsTractor: profileToCheck.main.vehicle_attrs_tractor,
                vehicleAttrsTrailer: profileToCheck.main.vehicle_attrs_trailer,
                nonUniqueMessage: totalMsg,
              };
            }
          } catch (e) {
            if (e !== BreakException) throw e;
          }
        }
      }
    }
  });

  return uniqueProfile;
};

export const checkUniqueProfileAtAssignments = (profileToCheck: ITireProfile, locationsToCheck: string[]): string[] => {
  const conflictingLocations: string[] = [];

  const filteredTireProfiles: ITireProfile[] = [];
  tireProfileList.forEach((profile: ITireProfile) => {
    if (profile.hash_key !== profileToCheck.hash_key) {
      if (locationsToCheck.indexOf(profile.gsi1_hash_key.substring(2)) !== -1) {
        filteredTireProfiles.push(profile);
      }
      if (profile.locations) {
        profile.locations.forEach((location: any) => {
          if (locationsToCheck.indexOf(location.gsi1_hash_key.substring(2)) !== -1) {
            filteredTireProfiles.push(profile);
          }
        });
      }
    }
  });

  const BreakException = {};

  filteredTireProfiles.forEach((profileToCheckAgainst: ITireProfile) => {
    // this will get ones ASSIGNED to desired assigned locations
    if (profileToCheckAgainst.locations) {
      profileToCheckAgainst.locations.forEach((location: any) => {
        if (locationsToCheck.indexOf(location.gsi1_hash_key.substring(2)) !== -1) {
          if (profileToCheckAgainst.hash_key !== profileToCheck.hash_key) {
            // don't check against itself
            if (
              (profileToCheckAgainst.main.profile_type === profileToCheck.main.profile_type ||
                profileToCheckAgainst.main.profile_type === 'ers_onsite' ||
                profileToCheck.main.profile_type === 'ers_onsite') &&
              profileToCheckAgainst.main.application_type === profileToCheck.main.application_type &&
              profileToCheckAgainst.main.vehicle_type === profileToCheck.main.vehicle_type &&
              profileToCheckAgainst.main.vehicle_attrs_tractor === profileToCheck.main.vehicle_attrs_tractor &&
              profileToCheckAgainst.main.vehicle_attrs_trailer === profileToCheck.main.vehicle_attrs_trailer
            ) {
              // check axles & tire sizes
              if (profileToCheckAgainst.axles) {
                try {
                  Object.entries(profileToCheckAgainst.axles).forEach(([axle, definition]) => {
                    if (definition && axle !== '__typename') {
                      const tireReplacementPriorities: any[] = definition.tire_replacement_priority; // ones to check against
                      tireReplacementPriorities.forEach(
                        (profileToCheckTireReplacementPriority: ITireReplacementPriority) => {
                          Object.entries(profileToCheck.axles).forEach(([thisAxle, thisDefinition]) => {
                            if (thisDefinition && thisAxle !== '__typename') {
                              const thisTireReplacementPriorities: any[] = thisDefinition.tire_replacement_priority; // ones to check against
                              // go through each of the profile to check axle's tire replacement priorities
                              thisTireReplacementPriorities.forEach(
                                (thisTireReplacementPriority: ITireReplacementPriority) => {
                                  if (
                                    thisTireReplacementPriority.tire_size ===
                                      profileToCheckTireReplacementPriority.tire_size &&
                                    axle === thisAxle
                                  ) {
                                    conflictingLocations.push(location.gsi1_hash_key);
                                  }
                                },
                              );
                            }
                          });
                        },
                      );
                    }
                  });
                } catch (e) {
                  if (e !== BreakException) throw e;
                }
              }
            }
          }
        }
      });
    }

    if (locationsToCheck.indexOf(profileToCheckAgainst.gsi1_hash_key.substring(2)) !== -1) {
      if (profileToCheckAgainst.hash_key !== profileToCheck.hash_key) {
        // don't check against itself
        if (
          profileToCheckAgainst.main.profile_type === profileToCheck.main.profile_type &&
          // && profileToCheckAgainst.profile_name === profileToCheck.main.profile_name // do I check for duplicate name ???
          profileToCheckAgainst.main.application_type === profileToCheck.main.application_type &&
          profileToCheckAgainst.main.vehicle_type === profileToCheck.main.vehicle_type &&
          profileToCheckAgainst.main.vehicle_attrs_tractor === profileToCheck.main.vehicle_attrs_tractor &&
          profileToCheckAgainst.main.vehicle_attrs_trailer === profileToCheck.main.vehicle_attrs_trailer
        ) {
          // check axles & tire sizes
          if (profileToCheckAgainst.axles) {
            try {
              Object.entries(profileToCheckAgainst.axles).forEach(([axle, definition]) => {
                if (definition && axle !== '__typename') {
                  const tireReplacementPriorities: any[] = definition.tire_replacement_priority; // ones to check against
                  tireReplacementPriorities.forEach(
                    (profileToCheckTireReplacementPriority: ITireReplacementPriority) => {
                      Object.entries(profileToCheck.axles).forEach(([thisAxle, thisDefinition]) => {
                        if (thisDefinition && thisAxle !== '__typename') {
                          const thisTireReplacementPriorities: any[] = thisDefinition.tire_replacement_priority; // ones to check against
                          // go through each of the profile to check axle's tire replacement priorities
                          thisTireReplacementPriorities.forEach(
                            (thisTireReplacementPriority: ITireReplacementPriority) => {
                              if (
                                thisTireReplacementPriority.tire_size ===
                                  profileToCheckTireReplacementPriority.tire_size &&
                                axle === thisAxle
                              ) {
                                conflictingLocations.push(profileToCheckAgainst.gsi1_hash_key);
                              }
                            },
                          );
                        }
                      });
                    },
                  );
                }
              });
            } catch (e) {
              if (e !== BreakException) throw e;
            }
          }
        }
      }
    }
  });

  return [...new Set(conflictingLocations)]; // remove duplicates
};

export const filterTheBrand = (brands: Option[], isOnsite: boolean, myPriority: any): Option[] => {
  if (myPriority.priority < 2) {
    if (isOnsite) {
      return brands.filter((x) => /oliver|uniroyal|mrt|michelin|bfgoodrich/gi.test(x.name));
    }
    return brands.filter((x) => /mrt|michelin|bfgoodrich/gi.test(x.name));
  }

  return brands;
};

export const removeBadKeys = (newData: any) => {
  if (newData) {
    const axleKeys = Object.keys(newData);
    for (let i = 0; i < axleKeys.length; i++) {
      if (newData[axleKeys[i]]) {
        if (newData[axleKeys[i]].tire_replacement_priority) {
          for (let j = 0; j < newData[axleKeys[i]].tire_replacement_priority.length; j++) {
            delete newData[axleKeys[i]].tire_replacement_priority[j].id;
            const tirePriorityKeys = Object.keys(newData[axleKeys[i]].tire_replacement_priority[j]);
            for (let k = 0; k < tirePriorityKeys.length; k++) {
              if (!newData[axleKeys[i]].tire_replacement_priority[j][tirePriorityKeys[k]]) {
                delete newData[axleKeys[i]].tire_replacement_priority[j][tirePriorityKeys[k]];
              }
            }
          }
        }
      }
    }
  }
};

export const doesEqualSize = (size: string, comp: boolean, equalSizes: string[]) => {
  let ret = size;
  equalSizes.forEach((e) => {
    const testString = e.split('~');
    if (testString[0].toUpperCase() === size.toUpperCase() || testString[1].toUpperCase() === size.toUpperCase()) {
      if (comp) {
        ret = e.split('~')[1];
      } else {
        ret = e.split('~')[0];
      }
    }
  });
  return ret;
};

export const doesItExistInEqualityMich = (size: string, equalSizes: string[]) => {
  let ret = false;
  equalSizes.forEach((e) => {
    const testString = e.split('~');
    if (testString[0].toUpperCase() === size.toUpperCase()) {
      ret = true;
    }
  });
  return ret;
};

export const doesItExistInEquality = (size: string, equalSizes: string[]) => {
  let ret = false;
  equalSizes.forEach((e) => {
    const testString = e.split('~');
    if (testString[0].toUpperCase() === size.toUpperCase() || testString[1].toUpperCase() === size.toUpperCase()) {
      ret = true;
    }
  });
  return ret;
};

export const getThe2EqualSizes = (size: string, equalSizes: string[]) => {
  let ret = '';
  equalSizes.forEach((e) => {
    const testString = e.split('~');
    if (testString[0].toUpperCase() === size.toUpperCase() || testString[1].toUpperCase() === size.toUpperCase()) {
      ret = e;
    }
  });
  return ret;
};

export const getRegExpEqual = (size: string, equalSizes: string[]) => {
  let ret = new RegExp('this wont work');
  equalSizes.forEach((e) => {
    const testString = e.split('~');
    if (testString[0].toUpperCase() === size.toUpperCase() || testString[1].toUpperCase() === size.toUpperCase()) {
      const stringArray = e.split('~');
      const regExp = `${stringArray[0]}|${stringArray[1]}`;
      ret = new RegExp(regExp, 'ig');
    }
  });
  return ret;
};

export const doWeEqual = (a: string, b: string, equalSizes: string[]) => {
  let ret = false;

  if (a.toUpperCase() === b.toUpperCase()) {
    return true;
  }
  equalSizes.forEach((e) => {
    const testString = e.split('~');
    if (
      (testString[0].toUpperCase() === a.toUpperCase() || testString[1].toUpperCase() === a.toUpperCase()) &&
      (testString[0].toUpperCase() === b.toUpperCase() || testString[1].toUpperCase() === b.toUpperCase())
    ) {
      ret = true;
    }
  });
  return ret;
};
