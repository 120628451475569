import React, { useState } from 'react';
import { Button } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { ContactListingOVM } from '@michelin/fcp-view-models';
import { Column } from 'devextreme-react/data-grid';
import { RenderNameAndAccountInfo } from '../../../Contacts/Components/Components';
import { ContactsModal } from '../../../Contacts/Modal';

function assignRender(
  row: ContactListingOVM,
  assignedContact: ContactListingOVM | undefined,
  setContact: (contact: ContactListingOVM | undefined) => void,
) {
  return (
    <Button
      color={assignedContact?.id === row?.id ? 'danger' : 'default'}
      variant={'default'}
      onClick={() => {
        setContact(assignedContact?.id === row?.id ? undefined : row);
      }}
    >
      {assignedContact?.id === row?.id ? 'Unassign' : 'Assign'}
    </Button>
  );
}
const ContactPicker = () => {
  const { t } = useTranslation();
  const [contact, setContact] = useState<ContactListingOVM | undefined>(undefined);
  const [modalOpen, setModalOpen] = useState(false);
  React.useEffect(() => {
    if (contact) {
      setModalOpen(false);
    }
  }, [contact]);
  const additionalAssignColumns = React.useMemo(
    () => [
      <Column
        key="assign_column"
        allowFiltering={false}
        allowSorting={false}
        caption={t('Assign')}
        alignment="center"
        dataField="assign"
        cellRender={(row: any) => assignRender(row.data, contact, setContact)}
      />,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contact],
  );
  return (
    <div>
      <Button
        onClick={() => {
          setModalOpen(!modalOpen);
        }}
      >
        Open Modal
      </Button>
      {modalOpen && (
        <ContactsModal
          open={modalOpen}
          dialogTitle={'Asignar Contacto'}
          onClose={() => setModalOpen(false)}
          additionalColumns={additionalAssignColumns}
        />
      )}
      <RenderNameAndAccountInfo row={contact} contactMethod={'email'} />
    </div>
  );
};

export { ContactPicker };
