import { useState } from 'react';
import { Location, MaestroAPI, maestroApiCall, usePermissions } from '@michelin/central-provider';
import { api } from '@michelin/central-provider/dist/environment.vars';
import { useQuery } from 'react-query';
import { MethodType } from '../Types/Api';

export const baseURL = `${process.env.REACT_APP_MC_EXTERNAL_API_URL}`;

export interface APIListingResponse<T> {
  data: T[];
  total: number;
  error: string;
  loading: boolean;
}

async function listCustomers(accountId?: string, query?: string | null) {
  const apiType: MaestroAPI = {
    method: MethodType.GET,
    // eslint-disable-next-line max-len
    endpoint: `/central/users/accounts?$skip=0&$limit=1&$sort=name%20asc&app_key=core_profile&accountType=fleet&partialIds=true&partialName=true&$query=${query}`,
    baseURL: api,
  };

  apiType.endpoint += accountId ? `&root=${accountId}` : '';
  apiType.endpoint += '&parents=include';

  const firstPage = await maestroApiCall<APIListingResponse<Location>>(apiType);

  const resultsCount = firstPage?.total || 0;
  const pageSize = 9999;
  const pagesCount = Math.ceil(resultsCount / pageSize);

  const promises = [];
  for (let i = 0; i < pagesCount; i++) {
    // eslint-disable-next-line max-len
    apiType.endpoint = `/central/users/accounts?$skip=${
      i * pageSize
    }&$limit=${pageSize}&$sort=name%20asc&app_key=core_profile&accountType=fleet&partialIds=true&partialName=true&$query=${query}`;
    apiType.endpoint += accountId ? `&root=${accountId}` : '';
    apiType.endpoint += '&parents=include';
    promises.push(maestroApiCall<APIListingResponse<Location>>(apiType));
  }

  const allPages = await Promise.all(promises);
  const allResults = allPages.reduce((acc: Location[], page) => [...acc, ...page.data], []);

  return {
    total: allResults.length,
    data: allResults,
  };
}
function useListCustomers({ query, accountId }: { accountId?: string; query?: string | null }) {
  return useQuery(['customers-list', query, accountId], () => listCustomers(accountId, query));
}

const useGetAllCustomers = () => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const { location } = usePermissions();

  const { data, isLoading, isError } = useListCustomers({
    accountId: location?.customer_number,
    query: searchQuery,
  });

  const refresh = () => {
    if (searchQuery === '') {
      setSearchQuery(' ');
    } else {
      setSearchQuery('');
    }
  };

  return {
    onSearch: setSearchQuery,
    isLoading,
    isError,
    refresh,
    data,
  };
};

export { useGetAllCustomers };
