/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { FormControlLabel, Grid } from '@material-ui/core';
import { LocationAvatar, Select, TextField } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { getOptions } from '@michelin/select-options-provider';
import { BillingPreferences, BillingProfileLocation, MethodOfSignedOrder } from 'components/Billing/Preferences/query';
import { RenderNameAndAccountInfo } from 'components/Contact/ContactList';
import { Contact } from 'components/Contact/utils';
import { getCustomers } from 'components/LocationAssignments/queries';
import { Controller, useFormContext } from 'react-hook-form';
import { PurchasingProcValidations } from '../types';
import { PoRequiredDRLogic } from './PoRequiredDRLogic';

export interface DrWorkOrderProps {
  validationErrors: PurchasingProcValidations;
  contacts: Array<Contact>;
  locations: BillingProfileLocation[];
  editFlag: boolean;
  ers: boolean;
  profile: BillingPreferences;
  editProfile: (profile: BillingPreferences) => void;
  modifiedFlag: boolean;
  setModifiedFlag: (modified: boolean) => void;
  setLocations: (locations: BillingProfileLocation[]) => void;
}

export function DrWorkOrder(props: DrWorkOrderProps): JSX.Element {
  const {
    validationErrors,
    editFlag,
    ers,
    profile,
    editProfile,
    modifiedFlag,
    setModifiedFlag,
    contacts,
    setLocations,
    locations,
  } = props;
  const { t } = useTranslation();
  const { control, getValues, setValue, reset, formState, watch } = useFormContext<BillingPreferences>();
  const purchaseProcedure = ers ? watch('ers_purchasing_procedures') : watch('onsite_purchasing_procedures');
  useEffect(() => {
    if (formState.isDirty) {
      setModifiedFlag(true);
      editProfile(getValues());
      reset(getValues());
    }
  }, [formState.isDirty]);
  useEffect(() => {
    if (!modifiedFlag) {
      editProfile(profile);
      reset(profile);
    }
  }, [modifiedFlag]);
  const options_method_to_receive_signed_order = getOptions('method_to_receive_signed_order', 'Select', t('Select'));
  const ppName = ers ? 'ers' : 'onsite';
  let emailFaxLocation = '';
  if (purchaseProcedure) {
    if (
      purchaseProcedure.method_to_receive_signed_order === MethodOfSignedOrder.mail &&
      purchaseProcedure.receiver_location
    ) {
      emailFaxLocation = `${purchaseProcedure.receiver_location.customer_name}, ${purchaseProcedure.receiver_location.customer_city}`;
    }
    if (
      purchaseProcedure.method_to_receive_signed_order === MethodOfSignedOrder.email &&
      purchaseProcedure.receiver_contact
    ) {
      emailFaxLocation = purchaseProcedure.receiver_contact.email_address;
    } else if (
      purchaseProcedure.method_to_receive_signed_order === MethodOfSignedOrder.fax &&
      purchaseProcedure.receiver_contact
    ) {
      emailFaxLocation = purchaseProcedure.receiver_contact.fax;
    }
  }

  const updateSignedOrder = (newValue: string) => {
    setValue(`${ppName}_purchasing_procedures.method_to_receive_signed_order`, newValue, { shouldDirty: true });
    validationErrors.method_to_receive_signed_order = false;
  };

  const updateSignedOrderOther = (newValue: string) => {
    setValue(`${ppName}_purchasing_procedures.signed_order_receiver_other`, newValue, { shouldDirty: true });
    validationErrors.method_to_receive_signed_order = false;
  };

  const isOtherOption = purchaseProcedure?.method_to_receive_signed_order === MethodOfSignedOrder.other;
  const isNot_Select_Other_Driver =
    purchaseProcedure &&
    purchaseProcedure.method_to_receive_signed_order !== MethodOfSignedOrder.other &&
    purchaseProcedure.method_to_receive_signed_order !== MethodOfSignedOrder.Select &&
    purchaseProcedure.method_to_receive_signed_order !== MethodOfSignedOrder.provide_to_driver;
  const methodToReceive = purchaseProcedure?.method_to_receive_signed_order;
  const [receiverContact, setReceiverContact] = useState<Contact | undefined>(undefined);
  const [receiverLocation, setReceiverLocation] = useState<BillingProfileLocation | undefined>(undefined);

  async function loadLocation() {
    const signedReceiverLocation = ers
      ? profile?.ers_purchasing_procedures?.signed_order_receiver_location
      : profile?.onsite_purchasing_procedures?.signed_order_receiver_location;
    if (signedReceiverLocation) {
      const locs = await getCustomers([signedReceiverLocation]);
      setReceiverLocation(locs.pop() as unknown as BillingProfileLocation);
    }
  }

  useEffect(() => {
    const receiverContact = ers
      ? profile?.ers_purchasing_procedures?.signed_order_receiver_contact
      : profile?.onsite_purchasing_procedures?.signed_order_receiver_contact;
    if (receiverContact) setReceiverContact(contacts.find((e) => e && e.hash_key === receiverContact));
    loadLocation();
  }, [
    profile?.ers_purchasing_procedures?.signed_order_receiver_contact,
    profile?.ers_purchasing_procedures?.signed_order_receiver_location,
    profile?.onsite_purchasing_procedures?.signed_order_receiver_contact,
    profile?.onsite_purchasing_procedures?.signed_order_receiver_location,
  ]);

  return (
    <>
      <Grid container spacing={2} style={{ paddingBottom: 20 }}>
        <Grid item sm={12} md={4}>
          <Controller
            name={`${ppName}_purchasing_procedures.method_to_receive_signed_order`}
            control={control}
            render={(item) => (
              <Select
                id={item.field.name}
                label={t('Method to Receive Signed DR/Work Order')}
                data-testid="methodToReceive"
                value={item.field.value || ''}
                readOnly={!editFlag}
                onChange={(e) => {
                  item.field.onChange(e.target.value);
                  updateSignedOrder(e.target.value as string);
                }}
                options={options_method_to_receive_signed_order}
                style={{ width: '100%' }}
                error={validationErrors.method_to_receive_signed_order}
              />
            )}
          />
        </Grid>
        {!isOtherOption &&
          purchaseProcedure?.method_to_receive_signed_order !== MethodOfSignedOrder.Select &&
          purchaseProcedure?.method_to_receive_signed_order !== MethodOfSignedOrder.provide_to_driver && (
            <>
              <Grid item sm={12} md={4} style={{ paddingTop: 24 }}>
                <FormControlLabel
                  value=""
                  control={
                    <PoRequiredDRLogic
                      editProfile={editProfile}
                      setModifiedFlag={setModifiedFlag}
                      preferences={getValues()}
                      contacts={contacts}
                      locations={locations}
                      ers={ers}
                      setLocations={setLocations}
                      editFlag={editFlag}
                    />
                  }
                  label={t('Receiver of Signed DR/Work Order*')}
                  labelPlacement="start"
                  style={{ margin: 0 }}
                />
              </Grid>
              <Grid item sm={12} md={4} style={{ paddingTop: 16 }}>
                {methodToReceive === MethodOfSignedOrder.fax || methodToReceive === MethodOfSignedOrder.email ? (
                  <RenderNameAndAccountInfo row={receiverContact as any} contactMethod={methodToReceive} />
                ) : (
                  receiverLocation && (
                    <LocationAvatar
                      accountType={receiverLocation.customer_type as any}
                      name={receiverLocation.customer_name}
                      accountNumbers={{
                        parentCompany: receiverLocation.parent_company_number,
                        homeOffice: receiverLocation.home_office_number,
                        billTo: receiverLocation.bill_to_customer,
                        shipTo: receiverLocation.ship_to_customer,
                      }}
                      address={{
                        city: receiverLocation.customer_city,
                        state: receiverLocation.customer_state,
                        address1: receiverLocation.customer_addr1,
                        address2: receiverLocation.customer_addr2,
                        zip: receiverLocation.customer_zip,
                      }}
                    />
                  )
                )}
              </Grid>
            </>
          )}
        {isOtherOption && (
          <Grid item sm={12} md={4}>
            {purchaseProcedure && purchaseProcedure?.method_to_receive_signed_order === MethodOfSignedOrder.other && (
              <Controller
                name={`${ppName}_purchasing_procedures.signed_order_receiver_other`}
                control={control}
                render={(item) => (
                  <TextField
                    id={item.field.name}
                    onChange={(e) => {
                      item.field.onChange(e.target.value);
                      updateSignedOrderOther(e.target.value);
                    }}
                    label={t('Other')}
                    value={item.field.value || ''}
                    readOnly={!editFlag}
                    error={validationErrors.method_to_receive_signed_order}
                  />
                )}
              />
            )}
            {isNot_Select_Other_Driver && (
              <TextField value={emailFaxLocation.length > 0 ? emailFaxLocation : ''} readOnly />
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
}