import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { LoadingBounce } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { DataGrid } from 'devextreme-react';
import { Column, Scrolling, SearchPanel } from 'devextreme-react/data-grid';
import { BillingProfileLocation } from '../../query';
import LocationCard, { getCustomerNumbersFromRelationship } from '../LocationCard';

interface Props {
  open: boolean;
  locations: BillingProfileLocation[];
  onLocationSelected: (location: BillingProfileLocation, locationIndex: number) => void;
}

interface LocationIndexed {
  location: BillingProfileLocation;
  index: number;
}

interface State {
  loading: boolean;
  gridItems: Array<Array<LocationIndexed>>;
}

export function LocationsGrid(props: Props): JSX.Element {
  const { open, locations, onLocationSelected } = props;
  const { t } = useTranslation();
  const [{ loading, gridItems }, setState] = useState<State>({
    loading: true,
    gridItems: [],
  });

  // There can be many locations to render and it can take a very long time to render all this data.
  // Suspense or Deferred rendering should be used.
  useEffect(() => {
    setState({
      loading: true,
      gridItems: [],
    });

    if (open === true) {
      const renderAsync = async () => {
        const gridItems: Array<Array<LocationIndexed>> = [];
        let auxArr: Array<LocationIndexed> = [];
        const sortLocationsF = (a: BillingProfileLocation, b: BillingProfileLocation) => {
          if (a.customer_number === b.customer_number) {
            return 0;
          }
          if (a.customer_number > b.customer_number) {
            return 1;
          }
          return -1;
        };

        locations.sort(sortLocationsF).forEach((x, i) => {
          auxArr.push({
            location: x,
            index: i,
          });

          if (auxArr.length === 3) {
            gridItems.push(auxArr);
            auxArr = [];
          }
        });

        gridItems.push(auxArr);

        setState({
          loading: false,
          gridItems,
        });
      };

      renderAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  if (loading) {
    return <LoadingBounce />;
  }
  const cellRender = (row: any): JSX.Element => {
    const { data } = row;

    const items = data.map(({ location, index }: LocationIndexed) => {
      const onCardClick = () => {
        onLocationSelected(location, index);
      };
      return (
        <Grid item md={4} key={`gridItem-${location.hash_key}`}>
          <LocationCard location={location} onClick={onCardClick} />
        </Grid>
      );
    });

    return (
      <Grid container spacing={1}>
        {items}
      </Grid>
    );
  };

  const calculateCellValue = (row: any): string => {
    let cellValue = '';

    if (row) {
      row.forEach(({ location }: LocationIndexed) => {
        const { ship_to_customer, home_office_number, parent_company_number, bill_to_customer } =
          getCustomerNumbersFromRelationship(location.relationship);
        cellValue +=
          `${ship_to_customer} ${home_office_number} ${parent_company_number} ${bill_to_customer} ${location.customer_name} ${location.customer_addr1} ${location.customer_addr2} ${location.customer_zip} ${location.customer_city} ${location.customer_state} `
            .toLowerCase()
            .replace(/ (null )+/g, ' ');
      });
    }

    return cellValue;
  };

  return (
    <DataGrid
      dataSource={gridItems}
      showRowLines={false}
      showBorders={false}
      showColumnHeaders={false}
      showColumnLines={false}
      noDataText={t('No locations available.')}
    >
      <Column cellRender={cellRender} calculateCellValue={calculateCellValue} allowSearch allowFiltering />
      <SearchPanel visible />
      <Scrolling mode="virtual" />
    </DataGrid>
  );
}
