import React from 'react';
import { useTranslation } from '@michelin/central-provider';
import { AccountLocationRender, renderAddress } from 'components/Contact/ContactDetails/ContactTypes/tables/utils';
import { Contact } from 'components/Contact/utils';
import { CheckBox, DataGrid } from 'devextreme-react';
import { Column, Scrolling, SearchPanel } from 'devextreme-react/data-grid';
import { BillingProfileLocation } from '../../query';

interface Row {
  location: BillingProfileLocation;
}

interface Props {
  locations: BillingProfileLocation[];
  locationAssigned: string | undefined;
  setLocation: (locationDetails: BillingProfileLocation | undefined, contactDetails: Contact | undefined) => void;
  editFlag: boolean;
}

function assignRender(
  row: Row,
  setLocation: (locationDetails: BillingProfileLocation | undefined, contactDetails: Contact | undefined) => void,
  locationAssigned: string | undefined,
  editFlag: boolean,
) {
  const onCheckBoxValueChange = (e: any) => {
    const { value } = e;

    if (value === true) {
      setLocation(row.location, undefined);
    } else {
      setLocation(undefined, undefined);
    }
  };

  return (
    <CheckBox
      disabled={!editFlag}
      value={typeof locationAssigned !== 'undefined' && locationAssigned === `1~${row.location.customer_number}`}
      onValueChanged={onCheckBoxValueChange}
    />
  );
}

export function PORequiredLocationTable(props: Props): JSX.Element {
  const { locations: rows, setLocation, locationAssigned, editFlag } = props;

  const { t } = useTranslation();

  return (
    <DataGrid dataSource={rows.map((x) => ({ location: x }))} showBorders repaintChangesOnly columnAutoWidth>
      <Scrolling mode="infinite" />
      <SearchPanel visible />
      <Column
        allowSearch={false}
        allowSorting
        alignment="center"
        defaultSortOrder="desc"
        calculateSortValue={(row: any) => `1~${row.location.customer_number}` === locationAssigned}
        caption={t('Assign')}
        dataField="assign"
        cellRender={(row: any) => assignRender(row.data, setLocation, locationAssigned, editFlag)}
      />
      <Column
        calculateSortValue={(row: Row) => `${row.location.customer_state} ${row.location.customer_city}`}
        allowSorting
        allowSearch
        caption={t('Account/Location')}
        dataField="account_location"
        cellRender={(row: any) => <AccountLocationRender location={row.data.location} clickable />}
        calculateCellValue={(row: Row) =>
          `${row.location.customer_name} ${row.location.extrnl_cust_id} ${row.location.customer_number}`
        }
      />
      <Column
        calculateSortValue={(row: Row) => `${row.location.customer_addr1} ${row.location.customer_addr2}`}
        calculateCellValue={(row: Row) => `${row.location.customer_addr1} ${row.location.customer_addr2}`}
        allowSorting
        allowSearch
        caption={t('Address')}
        dataField="address"
        cellRender={(row: any) => renderAddress(row.data.location)}
      />
    </DataGrid>
  );
}
