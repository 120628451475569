import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import { AccountAvatar } from '@michelin/acid-components';
import { BillingProfileLocation } from '../../query';

interface Props {
  location: BillingProfileLocation;
  clickable?: boolean;
  onClick?: () => void;
  style?: CSSProperties;
}

export function getCustomerNumbersFromRelationship(relationship: string) {
  const locationNumbers = relationship.split('~');
  let ship_to_customer;
  let home_office_number;
  let parent_company_number;
  let bill_to_customer;

  const indexedSplit = (splittedVar: Array<string>, index: number) =>
    splittedVar.length >= index ? splittedVar[index] : '';

  parent_company_number = indexedSplit(locationNumbers, 0);
  parent_company_number =
    parent_company_number === 'null' || typeof parent_company_number !== 'string' ? undefined : parent_company_number;
  home_office_number = indexedSplit(locationNumbers, 1);
  home_office_number =
    home_office_number === 'null' || typeof home_office_number !== 'string' ? undefined : home_office_number;
  bill_to_customer = indexedSplit(locationNumbers, 2);
  bill_to_customer = bill_to_customer === 'null' || typeof bill_to_customer !== 'string' ? undefined : bill_to_customer;
  ship_to_customer = indexedSplit(locationNumbers, 3);
  ship_to_customer = ship_to_customer === 'null' || typeof ship_to_customer !== 'string' ? undefined : ship_to_customer;

  return {
    ship_to_customer,
    home_office_number,
    parent_company_number,
    bill_to_customer,
  };
}

export default function LocationCard(props: Props): JSX.Element {
  const { clickable, location, onClick, style } = props;
  const {
    customer_name,
    customer_type,
    customer_addr1,
    customer_city,
    customer_state,
    customer_zip,
    ship_to_customer,
    home_office_number,
    parent_company_number,
    bill_to_customer,
  } = location;

  const finalStyle = {
    ...style,
    cursor: clickable === false ? '' : 'pointer',
  };

  return (
    <Card onClick={onClick} style={finalStyle}>
      <CardContent>
        <AccountAvatar
          style={{ cursor: clickable === false ? '' : 'pointer' }}
          name={customer_name}
          accountType={customer_type as any}
          address={{
            address1: customer_addr1,
            city: customer_city,
            state: customer_state,
            zip: customer_zip,
          }}
          accountNumbers={{
            parentCompany: parent_company_number,
            homeOffice: home_office_number,
            billTo: bill_to_customer,
            shipTo: ship_to_customer,
          }}
        />
      </CardContent>
    </Card>
  );
}
