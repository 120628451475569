export const DEFAULT_ERROR_VALIDATION: ValidationErrors = {
  gi_profile_name: false,
  pp_ers: {
    ers_ship_to: false,
    required_authorization: false,
    primary_contact_request_authorization: false,
    primary_contact_phone: false,
    sec_contact_request_authorization: false,
    sec_contact_phone: false,
    method_to_receive_signed_order: false,
    primary_contact_types: false,
    primary_contact_level: false,
    sec_contact_types: false,
    sec_contact_level: false,
  } as PurchasingProcValidations,

  pp_os: {
    ers_ship_to: false,
    required_authorization: false,
    primary_contact_request_authorization: false,
    primary_contact_phone: false,
    sec_contact_request_authorization: false,
    sec_contact_phone: false,
    method_to_receive_signed_order: false,
    primary_contact_types: false,
    primary_contact_level: false,
    sec_contact_types: false,
    sec_contact_level: false,
  } as PurchasingProcValidations,

  ers_max_tires: false,
  ers_max_wheels: false,

  os_max_tires: false,
  os_max_wheels: false,
  os_max_dollars: false,
};

export interface ValidationErrors {
  gi_profile_name: boolean;

  pp_ers: PurchasingProcValidations;
  pp_os: PurchasingProcValidations;

  ers_max_tires: boolean;
  ers_max_wheels: boolean;

  os_max_tires: boolean;
  os_max_wheels: boolean;
  os_max_dollars: boolean;
}


export interface PurchasingProcValidations {
  ers_ship_to: boolean;
  required_authorization: boolean;
  primary_contact_request_authorization: boolean;
  primary_contact_phone: boolean;
  secondary_contact_phone: boolean;
  sec_contact_request_authorization: boolean;
  sec_contact_phone: boolean;
  method_to_receive_signed_order: boolean;
  primary_contact_types: boolean;
  primary_contact_level: boolean;
  sec_contact_types: boolean;
  sec_contact_level: boolean;
}

export type BillingProfileLocation = {
  assigned: boolean;
  relationship: string;
  hash_key: string;
  customer_number: string;
  extrnl_cust_id: string;
  customer_name: string;
  customer_addr1: string;
  customer_addr2: string;
  customer_city: string;
  customer_state: string;
  customer_zip: string;
  customer_type: string;
  ship_to_customer: string;
  home_office_number: string;
  parent_company_number: string;
  bill_to_customer: string;
};

export const LOCATIONS = 
[
{
  "hash_key": "1~1033735",
  "range_key": "v0_customer",
  "contacts": [
      {
          "hash_key": "1~1033735",
          "gsi1_hash_key": "2~2e41e7e6-7842-4fba-b44d-5f93f1c84555",
          "gsi2_hash_key": "2~1290948",
          "is_deleted": false,
          "__typename": "ContactLocationRelationship"
      },
      {
          "hash_key": "1~1033735",
          "gsi1_hash_key": "2~32762e05-5034-4131-a6ee-5783c228f924",
          "gsi2_hash_key": "2~1290948",
          "is_deleted": false,
          "__typename": "ContactLocationRelationship"
      },
      {
          "hash_key": "1~1033735",
          "gsi1_hash_key": "2~60dfc438-e78b-43f4-821a-535775181c26",
          "gsi2_hash_key": "2~1290948",
          "is_deleted": false,
          "__typename": "ContactLocationRelationship"
      },
      {
          "hash_key": "1~1033735",
          "gsi1_hash_key": "2~9e233bc5-9396-4379-aa08-a9347653aa4e",
          "gsi2_hash_key": "2~1290948",
          "is_deleted": false,
          "__typename": "ContactLocationRelationship"
      },
      {
          "hash_key": "1~1033735",
          "gsi1_hash_key": "2~febb4fc8-409c-4e4a-af9f-1caaa11778ec",
          "gsi2_hash_key": "2~1290948",
          "is_deleted": false,
          "__typename": "ContactLocationRelationship"
      }
  ],
  "customer_name": "FEDEX FREIGHT, INC.",
  "customer_type": "ST",
  "extrnl_cust_id": "120",
  "customer_addr1": "2200 FORWARD DR",
  "hold_code": null,
  "customer_addr2": null,
  "customer_city": "HARRISON",
  "customer_state": "AR",
  "customer_zip": "72601",
  "customer_country": "US",
  "customer_number": "1033735",
  "parent_company_number": "1290948",
  "home_office_number": "1290947",
  "bill_to_customer": "1005070",
  "ship_to_customer": "1033735",
  "relationship": "1290948~1290947~1005070~1033735",
  "__typename": "GsiCustomers",
  "assigned": true
},
{
  "hash_key": "1~1034825",
  "range_key": "v0_customer",
  "contacts": [
      {
          "hash_key": "1~1034825",
          "gsi1_hash_key": "2~60dfc438-e78b-43f4-821a-535775181c26",
          "gsi2_hash_key": "2~1290948",
          "is_deleted": false,
          "__typename": "ContactLocationRelationship"
      }
  ],
  "customer_name": "FEDEX FREIGHT, INC. 46593",
  "customer_type": "ST",
  "extrnl_cust_id": null,
  "customer_addr1": "2000 MANER RD SE",
  "hold_code": null,
  "customer_addr2": null,
  "customer_city": "SMYRNA",
  "customer_state": "GA",
  "customer_zip": "30082",
  "customer_country": "US",
  "customer_number": "1034825",
  "parent_company_number": "1290948",
  "home_office_number": "1290947",
  "bill_to_customer": "1005070",
  "ship_to_customer": "1034825",
  "relationship": "1290948~1290947~1005070~1034825",
  "__typename": "GsiCustomers",
  "assigned": true
}

] as unknown as BillingProfileLocation[];

export interface BillingPreferencesLocation {
  hash_key: string;
  customer_number?: number;
  customer_type?: string;
  customer_name?: string;
}

export interface BillingPreferencesLocationRelationship {
  location?: BillingPreferencesLocation;
}

