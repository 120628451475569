import React, { ChangeEvent, useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { Button, TextField } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { Actions } from 'modules/Tires/types';

type props = {
  open: boolean;
  note: string | null;
  close: () => void;
  onSave: (note: string | null) => void;
  action: Actions;
};

const Notes = ({ onSave, note, open, action, close }: props) => {
  const { t } = useTranslation();

  const [noteText, setNoteText] = useState<string | null>(note);

  useEffect(() => {
    setNoteText(note);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog disableBackdropClick disableEscapeKeyDown fullWidth maxWidth="md" open={open}>
      <DialogContent>
        <TextField
          id="outlined-multiline-static"
          label={t('Notes')}
          value={noteText}
          onInput={(event: ChangeEvent<HTMLInputElement>) => {
            setNoteText(event.target.value);
          }}
          multiline
          rows="4"
          variant="outlined"
          readOnly={action === 'view'}
        />
      </DialogContent>
      <DialogActions>
        {action !== 'view' && (
          <Button
            onClick={() => {
              onSave(noteText);
              close();
            }}
            color="primary"
          >
            {t('Update')}
          </Button>
        )}

        <Button onClick={close} color="primary">
          {action === 'view' ? t('Close') : t('Cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { Notes };
