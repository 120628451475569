import gql from 'graphql-tag';

// hash_key: 'P~WheelDiameters'
// hash_key: 'P~RimWidths'
export const getWheelDiameters = gql`
  query getRimOrWheelDiameters($hash_key: String!) {
    getRimOrWheelDiameters(hash_key: $hash_key) {
      range_key
    }
  }
`;

export const getAllWheelBrands = gql`
  query getAllWheelBrands($hash_key: String!) {
    getAllWheelBrands(hash_key: $hash_key) {
      hash_key
      range_key
    }
  }
`;

export const createWheelBrand = gql`
  mutation createWheelBrand($range_key: String!) {
    createWheelBrand(range_key: $range_key) {
      hash_key
      range_key
    }
  }
`;
