import React, { ChangeEvent, useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { Button, TextField } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { useSnackbar } from '@michelin/central-provider';
import { ProductOVM, TireProfileOVM, TireProfilePriorities } from '@michelin/fcp-view-models';
import { SelectOption, getOptions } from '@michelin/select-options-provider';
import { getMspnData } from 'hooks/useTireProfile';
import { AxleName } from 'modules/Tires/types';
import { useFormContext } from 'react-hook-form';
import { doSizesEqual, getAxle, getOtd32 } from '../utils';

interface props {
  open: boolean;
  onClose: () => void;
  tireSize: string;
  axleName: AxleName;
  mspnIndex: number;
  mspnPriority: number;
  products: ProductOVM[];
  onSave: (row: TireProfilePriorities) => void;
}

export function MspnSearch({ open, onClose, axleName, tireSize, mspnIndex, mspnPriority, products, onSave }: props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const equalSizes = getOptions('equal_sizes').map((option: SelectOption) => option.value);

  const [getMspnsText, setGetMspnsText] = useState('');
  const { getValues } = useFormContext<TireProfileOVM>();
  let axle = getAxle(axleName, getValues());

  const checkMspn = (mspn: string) => {
    const axle = getAxle(axleName, getValues());
    if (!axle) return true;
    const priorities = axle.tire_replacement_priority || [];
    const duplicate = priorities.filter((priority) => priority.mspn === mspn);
    if (duplicate.length > 0) return false;
    return true;
  };

  const checkUnique = (newRow: any): boolean => {
    const axle = getAxle(axleName, getValues());
    if (!axle) return true;
    const priorities = axle.tire_replacement_priority || [];
    const duplicate = priorities.filter(
      (priority) =>
        priority.priority !== newRow.priority &&
        priority.tire_size === newRow.tire_size &&
        priority.tire_type === newRow.tire_type &&
        priority.brand === newRow.brand &&
        priority.tread_design === newRow.tread_design,
    );
    if (duplicate.length > 0) return false;
    return true;
  };

  useEffect(() => {
    setGetMspnsText('');
  }, [open]);
  const getMspnTireData = async () => {
    if (!checkMspn(getMspnsText)) {
      enqueueSnackbar(t('That tire type already exists'), { variant: 'error' });
      return;
    }
    const data = await getMspnData(getMspnsText, 'all');
    const response = data || '';
    if (response && axle && axle.tire_replacement_priority) {
      const { note } = axle.tire_replacement_priority[mspnIndex];

      const auxTireType = response.tire_type
        ? response.tire_type
        : products.filter((x) => x.name === response.brand)[0].type;

      if (axleName === 'steer' && auxTireType === 'Retread') {
        enqueueSnackbar(t('The tire type is the type retread. The option for the steer axle should be new.'), {
          variant: 'error',
        });
        return;
      }
      const newRow: TireProfilePriorities = {
        priority: mspnPriority,
        tire_type: auxTireType,
        brand: response.brand,
        tire_size: response.tire_size,
        tread_design: response.tread_design || '',
        load_range: response.load_range || '',
        original_tire_depth: getOtd32(response.original_tread_depth || ''),
        width: response.width || '',
        mspn: getMspnsText,
        note: note ? note : undefined,
      };

      if (/^\s*\/32|^\s*$/.test(String(newRow.original_tire_depth))) {
        newRow.original_tire_depth = '';
      }
      if (!doSizesEqual(newRow.tire_size ? newRow.tire_size : '', tireSize, equalSizes)) {
        enqueueSnackbar(t('That product does not have a corresponding size for the selected tire size'), {
          variant: 'error',
        });
        return;
      }
      if (mspnPriority === 1) {
        if (/onsite/gi.test(getValues().main?.profile_type || '')) {
          if (!/megamile|oliver|uniroyal|mrt|michelin|bfgoodrich/gi.test(response.brand)) {
            enqueueSnackbar(
              t('The first priority must be a MegaMile, Oliver, Uniroyal, MRT, Michelin, or BFGoodrich.'),
              {
                variant: 'error',
              },
            );
            return;
          }
        } else {
          if (!/mrt|michelin|bfgoodrich/gi.test(response.brand)) {
            enqueueSnackbar(t('The first priority must be a MRT, Michelin, or BFGoodrich.'), { variant: 'error' });
            return;
          }
        }
      }

      const brand: ProductOVM = products.filter((x) => x.name === newRow.brand)[0];
      if (!brand) {
        enqueueSnackbar(t('The brand does not exist in the system'), { variant: 'error' });
        return;
      }
      const treads = brand.sizes?.filter((x) => x.name === newRow.tire_size)[0];
      const tread = treads?.treads?.filter((x) => x.name === newRow.tread_design)[0];
      const load = tread?.loads?.filter((x) => x.mspn === newRow.mspn)[0];
      if (load) {
        newRow.load_range = load.load ?? '';
        newRow.original_tire_depth = load.otd ?? '';
        newRow.width = load.width ?? '';
      }

      if (!checkUnique(newRow)) {
        enqueueSnackbar(t('That tire type already exists'), { variant: 'error' });
        return;
      }
      setGetMspnsText('');
      onSave(newRow);
    } else {
      enqueueSnackbar(t('The MSPN {{mspn}} was not found', { mspn: getMspnsText }), { variant: 'error' });
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={() => {
        onClose();
        setGetMspnsText('');
      }}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle>{t('Key in the MSPN of the tire to add it to the list')}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item md={3} />
          <Grid item md={4}>
            <TextField
              value={getMspnsText}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setGetMspnsText(event.target.value);
              }}
              label={t('MSPN')}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item md={3}>
            <Button
              onClick={() => {
                getMspnTireData();
              }}
            >
              {t('Search')}
            </Button>
          </Grid>
          <Grid item md={2} />
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
