/* eslint-disable react/no-danger */

/* eslint-disable max-len */

/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import { Grid, Link, useMediaQuery, useTheme } from '@material-ui/core';
import { Delete, Error, List } from '@material-ui/icons';
import { CSSProperties } from '@material-ui/styles';
import { Button, IconButton, Panel, useDialogs } from '@michelin/acid-components';
import { usePermissions, useTranslation } from '@michelin/central-provider';
import { getOptionLabel } from '@michelin/select-options-provider';
import Comments from 'components/Comments';
import ButtonPopover from 'components/Comments/ButtonPopover';
import { DataGrid } from 'devextreme-react';
import { Column, Scrolling } from 'devextreme-react/data-grid';
import { useFieldArray, useFormContext } from 'react-hook-form';
import SOPCell from '../SOPCell';
import TireProfileQuickView from '../TireProfileQuickView';
import { Actions, SOPInflation as SOPInflationType, SOPProfile, SOPTireProfile } from '../Types';
import { InflationFormModal } from './form';

interface SOPInflationProps {
  action: Actions;
  modalState: { action: Actions; open: boolean };
  handleModalState: (action: Actions, open: boolean) => void;
  tireProfile: SOPTireProfile[] | null;
}

function SOPInflation(props: SOPInflationProps) {
  const { t } = useTranslation();
  const { control } = useFormContext<SOPProfile>();
  const { confirmDialog } = useDialogs();
  const permissions = usePermissions();
  const theme = useTheme();
  const cellView = useMediaQuery(theme.breakpoints.down(500));

  const { fields, append, update, remove } = useFieldArray<SOPProfile, 'inflation.inflations', 'key'>({
    control,
    name: 'inflation.inflations',
    keyName: 'key',
  });

  const showWarning = fields.filter((i) => (i.tire_profile_errors || []).length > 0).length > 0;
  const [index, setIndex] = useState<number>(fields.length);
  const style: CSSProperties = {
    color: theme.palette.primary.main,
    fontSize: '1em',
  };

  function RenderId(id: any, colIndex: number) {
    const idFormat = (id || 0).toString().padStart(5, 'IP000');
    if (!permissions.allowsAction('sop.update')) return <div>{idFormat}</div>;

    return (
      <Link
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setIndex(colIndex);
          props.handleModalState(props.action === 'view' ? 'view' : 'edit', true);
        }}
      >
        {idFormat}
      </Link>
    );
  }

  function RenderUom(field: number, uom: string) {
    return <div>{`${field} ${getOptionLabel('pressure_types', uom)}`}</div>;
  }

  function RenderUomField(label: string, field: number, uom: string) {
    if (!field || field === 0) return <></>;

    return (
      <>
        <Grid item xs={8}>
          <span style={style}>{t(label)}:</span>
        </Grid>
        <Grid item xs={4}>
          {RenderUom(field, uom)}
        </Grid>
      </>
    );
  }

  function ExtraInfo(data: any) {
    return (
      data?.min_pressure ||
      data?.target_pressure ||
      data?.max_pressure ||
      data?.under_inflation_removal ||
      data?.over_inflation_removal ||
      data?.comments
    );
  }

  function RenderExtraInfo(data: any) {
    const headerStyle: CSSProperties = { fontWeight: 'bolder' };

    return (
      <Grid container spacing={0}>
        {(data.min_pressure || data.target_pressure || data.max_pressure) && (
          <Grid item xs={6} style={headerStyle}>
            {t('Pressure')}
          </Grid>
        )}
        {(data.under_inflation_removal || data.over_inflation_removal) && (
          <Grid item xs={6} style={headerStyle}>
            {t('Removal')}
          </Grid>
        )}
        <Grid item container xs={6}>
          {!!data.min_pressure && RenderUomField('Minimum', data.min_pressure, data.uom)}
          {!!data.target_pressure && RenderUomField('Target', data.target_pressure, data.uom)}
          {!!data.max_pressure && RenderUomField('Maximun', data.max_pressure, data.uom)}
        </Grid>
        <Grid item container xs={6}>
          {!!data.under_inflation_removal && RenderUomField('Under Inflation', data.under_inflation_removal, data.uom)}
          {!!data.over_inflation_removal && RenderUomField('Over Inflation', data.over_inflation_removal, data.uom)}
        </Grid>
        {data.comments && (
          <Grid item xs={12}>
            <span style={headerStyle}>{t('Comments')}</span>
            {`: ${data.comments}`}
          </Grid>
        )}
      </Grid>
    );
  }

  function ActionsCell({ i, data }: { i: number; data: any }) {
    const showDelete = permissions.allowsAction('sop.update') && !(props.action === 'view');
    const showErrors = data.tire_profile_errors?.includes('uom');

    return (
      <div style={{ minWidth: '100px' }}>
        {ExtraInfo(data) && <Comments comments={RenderExtraInfo(data)} icon={<List />} />}
        {showDelete && (
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={() => {
              confirmDialog(
                t('Are you sure you want to delete this Inflation Pressures?'),
                t('Delete Inflation Pressures'),
                t('Yes'),
                t('No'),
                () => {
                  remove(i);
                },
              );
            }}
            size="small"
          >
            <Delete />
          </IconButton>
        )}
        {showErrors && (
          <Comments
            color="danger"
            comments={t('Some required fields are not complete, please check them.')}
            icon={<Error />}
          />
        )}
      </div>
    );
  }

  const ActionButton = () => {
    if (props.action === 'view') return <></>;
    return (
      <>
        <ButtonPopover text={t('Tire Profile')} detail={<TireProfileQuickView tireProfile={props.tireProfile} />} />
        <Button size="small" color="primary" onClick={() => props.handleModalState('add', true)}>
          {t('Add')}
        </Button>
      </>
    );
  };

  const InflationGrid = () => (
    <Grid container spacing={2} direction="row">
      {showWarning && (
        <Grid item xs={12}>
          <div
            style={{ textAlign: 'center', color: '#f99738' }}
            dangerouslySetInnerHTML={{
              __html: t(
                '<b style="color:#eb8828">Instructions:</b> Please mouse over each item shown below <b style="color:#f16f6f">in RED font</b> for specific instructions on how to correct the data mismatch issues between the linked Onsite Tire Profile and this SOP Profile.',
              ),
            }}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <DataGrid
          dataSource={fields}
          className="freespaced-table"
          showBorders
          wordWrapEnabled
          columnAutoWidth
          style={{ maxHeight: `calc(100vh - ${showWarning ? '376px' : '349px'})` }}
        >
          <Scrolling mode="virtual" />
          <Column
            caption={t('Id')}
            dataField="id"
            allowSorting
            alignment="center"
            cellRender={(column: any) => RenderId(column.value, fields.indexOf(column.data))}
          />
          <Column
            caption={t('Tire Size')}
            dataField="size"
            allowSorting
            alignment="center"
            cellRender={(column: any) => (
              <SOPCell
                values={[column.value]}
                errors={column.data.tire_profile_errors}
                type="tire"
                axleValue={column.data.axle_type}
                tireProfile={props.tireProfile}
              />
            )}
          />
          <Column
            caption={t('Axle Position')}
            dataField="axle_types"
            allowSorting
            alignment="center"
            cellRender={(column: any) => (
              <SOPCell
                values={column.value}
                errors={column.data.tire_profile_errors}
                type="axle"
                tireProfile={props.tireProfile}
              />
            )}
          />
          <Column
            caption={t('Application Type')}
            dataField="application_types"
            allowSorting
            alignment="center"
            cellRender={(column: any) => (
              <SOPCell
                values={column.value}
                errors={column.data.tire_profile_errors}
                type="application"
                tireProfile={props.tireProfile}
              />
            )}
          />
          <Column
            caption={t('Vehicle Type')}
            dataField="vehicle_types"
            allowSorting
            alignment="left"
            cellRender={(column: any) => (
              <SOPCell
                values={column.value}
                errors={column.data.tire_profile_errors}
                type="vehicle"
                tireProfile={props.tireProfile}
              />
            )}
          />
          <Column
            allowSearch={false}
            allowSorting={false}
            cellRender={(row) => <ActionsCell i={fields.indexOf(row.data)} data={row.data} />}
          />
        </DataGrid>
      </Grid>
    </Grid>
  );

  return (
    <Panel title={cellView ? '' : t('Inflation Pressures')} control={<ActionButton />}>
      <Grid container spacing={2} direction="row">
        <InflationGrid />
        <InflationFormModal
          action={props.modalState.action}
          open={props.modalState.open}
          data={(props.modalState.action === 'add' ? {} : fields[index]) as SOPInflationType}
          list={fields}
          tireProfile={props.tireProfile}
          onSave={(e: SOPInflationType) => {
            delete e.tire_profile_errors;
            if (props.modalState.action === 'add') append(e);
            if (props.modalState.action === 'edit') update(index, e);
            // Refresh the action and form state
            props.handleModalState('', false);
          }}
          handleOpen={(value: boolean) => props.handleModalState('', value)}
        />
      </Grid>
    </Panel>
  );
}

export default SOPInflation;
