import { gql } from 'apollo-boost';

export const customerQuery = gql`
  query CustomerFleetAccounts($hash_key: String!) {
    getCustomerAssets(hash_key: $hash_key, range_key: "v0_customer") {
      customer {
        hash_key
        customer_name
        customer_addr1
        customer_addr2
        customer_city
        customer_country
        customer_number
        customer_state
        customer_zip
        parent_company_number
        home_office_number
        bill_to_customer
        ship_to_customer
        customer_type
        extrnl_cust_id
        relationship
        logo_url
        is_urban
        is_commercial
        mechanical_services_enabled
        cust_timezone
        oncall_sds_dashboard
      }
    }
    getApiVersion {
      version
    }
  }
`;

export interface CoreProfileGrant {
  accountNumber: string;
  userRole: string;
}

export interface CustomerData {
  hash_key: string | null;
  customer_name: string | null;
  customer_addr1: string | null;
  customer_addr2: string | null;
  customer_city: string | null;
  customer_country: string | null;
  customer_number: string | null;
  customer_state: string | null;
  customer_zip: string | null;
  parent_company_number: string | null;
  home_office_number: string | null;
  bill_to_customer: string | null;
  ship_to_customer: string | null;
  customer_type: string | null;
  extrnl_cust_id: string | null;
  relationship: string | null;
  logo_url: string | null;
  is_urban: boolean | null;
  is_commercial: boolean | null;
  mechanical_services_enabled: boolean | null;
  cust_timezone: string | null;
}

export interface UserProfile {
  email: string | null;
  language: string | null;
  name: string | null;
  userRole: string | null;
  userId: string | null;
  customerData: CustomerData | null;
  mainCustomerData: CustomerData | null;
  shipTos: Array<string> | null;
}

export interface IAccountType {
  hierarchyPosition: 4 | 3 | 2 | 1;
  shortName: 'pc' | 'ho' | 'bt' | 'st';
  longName: 'parentCompany' | 'homeOffice' | 'billTo' | 'shipTo';
}
