import { Location } from '@michelin/central-provider';
import { BillingProfileLocation } from './Profiles/types';

export function transformAsigneeToBillingLocation(locations: Location[]): BillingProfileLocation[] {
  const assignmentsToBillingProfileLocations = locations.map((x) => ({
    assigned: true,
    relationship: x.relationship || '',
    hash_key: x.hash_key,
    customer_number: x.customer_number,
    extrnl_cust_id: x.extrnl_cust_id || '',
    customer_name: x.customer_name,
    customer_addr1: x.customer_addr1,
    customer_addr2: x.customer_addr2 || '',
    customer_city: x.customer_city,
    customer_state: x.customer_state,
    customer_zip: x.customer_zip,
    customer_type: x.customer_type,
    ship_to_customer: x.ship_to_customer,
    bill_to_customer: x.bill_to_customer,
    home_office_number: x.home_office_number || '',
    parent_company_number: x.parent_company_number || '',
  }));
  return assignmentsToBillingProfileLocations;
}
