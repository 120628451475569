import { Location } from '@michelin/central-provider/dist/types/Location';
import { ContactListingOVM } from '@michelin/fcp-view-models';
import { LocationOVM } from '@michelin/fcp-view-models/dist/models/location';
import { SelectOption } from '@michelin/select-options-provider';
import { auth } from 'Auth';

export const PRIMARY_LEVEL_VALUE = 'primary';
export const SECONDARY_LEVEL_VALUE = 'secondary';
export const GENERAL_LEVEL_VALUE = 'general';
export const ERS_MANAGER_ROLE_AREA_VALUE = 'ers_manager';
export const PO_ISSUER_ROLE_AREA_VALUE = 'po_issuer';
export const TIRE_SERVICE_VALUE = 'tire';
export const MECH_SERVICE_VALUE = 'mechanical';

export const notAuthorizedByHierarchyTitle = 'Not enough permissions.';
export const deleteNotAuthorizedByHierarchyMessage =
  'This account does not have enough permissions to delete this contact from all the locations attached to it.';
export const contactLevelNotAuthorizedByHierarchyMessage =
  'This account does not have enough access to change the level since there are other locations attached to it.';
export const contactTypeRoleDeleteNotAuthorizedByHierarchyMessage =
  'This contact type is used in other locations where this account does not have permissions to modify.';

export function getContactTypesPerLocation(contact: ContactListingOVM) {
  const { contact_types } = contact;
  const contactTypePerLocation = new Map();

  if (contact_types) {
    contact_types.forEach((contactType) => {
      if (contactType && contactType.role_areas) {
        contactType.role_areas.forEach((contactTypeRoleArea) => {
          if (contactTypeRoleArea?.levels) {
            contactTypeRoleArea.levels.forEach((contactTypeLevel) => {
              if (contactTypeLevel.level === PRIMARY_LEVEL_VALUE || contactTypeLevel.level === SECONDARY_LEVEL_VALUE) {
                const alreadyAddedContactTypes = contactTypePerLocation.get(contactTypeLevel.location);
                if (alreadyAddedContactTypes) {
                  alreadyAddedContactTypes.push({
                    roleArea: contactTypeRoleArea.role_area,
                    level: contactTypeLevel.level,
                  });
                  contactTypePerLocation.set(contactTypeLevel.location, alreadyAddedContactTypes);
                } else {
                  contactTypePerLocation.set(contactTypeLevel.location, [
                    {
                      roleArea: contactTypeRoleArea.role_area,
                      level: contactTypeLevel.level,
                    },
                  ]);
                }
              }
            });
          }
        });
      }
    });
  }

  return contactTypePerLocation;
}

export function canUserEditByHierarchy(
  contact: Pick<ContactListingOVM, 'contact_level'> | undefined,
  updatePermission: boolean,
): boolean {
  if (contact && updatePermission === true) {
    const accountType = auth.getMainAccountTypeShort();
    return isHierarchyHigher(accountType, contact.contact_level || '');
  }
  return false;
}

export function isHierarchyHigher(hierarchyLevel: string | null, hierarchyLevelToCompare: string): boolean {
  switch (hierarchyLevel) {
    case 'PC':
      return true;
    case 'HO':
      return hierarchyLevelToCompare === 'HO' || hierarchyLevelToCompare === 'BT' || hierarchyLevelToCompare === 'ST';
    case 'BT':
      return hierarchyLevelToCompare === 'BT' || hierarchyLevelToCompare === 'ST';
    case 'ST':
      return hierarchyLevelToCompare === 'ST';
    default:
      return false;
  }
}

export const getHighestLocationType = (customerData: Location | LocationOVM) => {
  const { bill_to_customer, home_office_number, parent_company_number } = customerData;
  if (parent_company_number) return 'PC';
  if (home_office_number) return 'HO';
  if (bill_to_customer) return 'BT';
  return 'ST';
};

export function addCountry(options: SelectOption[], country: string) {
  return options.map((option) => {
    if (option.value === 'All' || option.value === 'all') return option;
    return {
      value: option.value,
      label: `${option.label} (${country})`,
    };
  });
}
