import React from 'react';
import { useTranslation } from '@michelin/central-provider';
import { APITypes } from '../Types/APITypes';
import { createApiCall } from '../Types/Api';

interface useAPICallState<T> {
  data: T;
  loading: boolean;
  error: string | null;
}

const cache = new Map<string, any>();

export function useAPICall<T = any[] | []>(
  api: APITypes,
  param: { [key: string]: any } = {},
  useCache = false,
  includeTenant = false,
  route: string = '',
): useAPICallState<T> {
  const [result, setResult] = React.useState<useAPICallState<any>>({
    data: [],
    loading: true,
    error: null,
  });
  const jsonParam = JSON.stringify(param);
  const { t } = useTranslation();

  React.useEffect(() => {
    let mounted = true;
    async function getData() {
      if (!api.endpoint) {
        setResult({ data: [], loading: false, error: null });
        return;
      }
      try {
        if (!mounted) return;
        let cacheKey = '';
        if (useCache) {
          cacheKey = `${api.method}:${api.endpoint}:${jsonParam}`;
          if (cache.has(cacheKey)) {
            setResult({
              data: cache.get(cacheKey),
              loading: false,
              error: null,
            });
            return;
          }
        }

        const d = await createApiCall({
          method: api.method,
          url: `${route}/${api.endpoint}`,
          data: JSON.parse(jsonParam),
          auth: true,
          includeTenant,
        });

        if (d.status >= 300) {
          throw Error(d.body.message);
        }

        if (useCache) cache.set(cacheKey, d.body);
        if (mounted) {
          setResult({
            data: d.body,
            loading: false,
            error: d.body.error ? JSON.parse(d.body.error) : null,
          });
        }
      } catch (e) {
        if (mounted) {
          setResult({
            data: [],
            loading: false,
            error: e instanceof Error ? e.message : t('An unknown error occurred!'),
          });
        }
      }
    }
    getData();
    return () => {
      mounted = false;
    };
  }, [api.endpoint, api.method, jsonParam, useCache, includeTenant, route, t]);
  return result as useAPICallState<T>;
}
