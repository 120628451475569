import { usePermissions } from '@michelin/central-provider';
import { auth } from 'Auth';
import DefaultClient, { gql } from 'apollo-boost';
import {
  ERS_MANAGER_ROLE_AREA_VALUE,
  PO_ISSUER_ROLE_AREA_VALUE,
  PRIMARY_LEVEL_VALUE,
  SECONDARY_LEVEL_VALUE,
  TIRE_SERVICE_VALUE,
} from 'components/Contact/ContactDetails/utils';
import {
  PaginatedContactLocationRelationship,
  getContactLocationsRelationshipsPaginated,
} from 'components/Contact/ContactLogTable';
import {
  Contact,
  ContactLocationRelationship,
  getChainedHierarchy,
  getHighestLocation,
} from 'components/Contact/utils';
import { getChildren } from 'components/Location';
import { getAllResultsPaginated } from 'graphql/utils';
import _ from 'lodash';
import { getBaseUrl } from 'prefs-and-service-offers';
import { useHistory } from 'react-router-dom';
import uuid from 'uuid';
import { fixApprovals } from './Approvals';
import { DrWorkOrderRequiredInfo } from './PurchasingProcedures';
import { SelectedContactToReassign } from './PurchasingProcedures/Tables/poRequiredReassignTable';
import { initPurchasingProcedure } from './PurchasingProcedures/utils';
import { BillingReassignContactType } from './ReassignContactTypes/dialogs';
import { removeExtraFields } from './innerUtils';
import {
  AuthorizationService,
  BillingPreferences,
  BillingPreferencesInput,
  BillingProfileLocation,
  BillingProfileTypes,
  BillingPurchasingProcedures,
  BillingServiceTypes,
  GET_BILLING_LOCATIONS,
  GET_CONTACT_BY_KEY,
  QueryBillingLocations,
  UPDATE_BILLING_PROFILE,
  UPDATE_SPECIAL_CONTACTS,
} from './query';
import { SubBillingRequirements } from './utilsRequirements';

function getContactsKeysFromRelationships(relationships: Array<any>) {
  const contactHashKeysSet = new Set<string>();
  relationships.forEach((x) => {
    contactHashKeysSet.add(x.gsi1_hash_key);
  });

  return contactHashKeysSet;
}

function buildQueries(contactHashKeys: Array<string>) {
  let n = 0;
  const queries: Array<Array<string>> = [];
  let tempQueries: Array<string> = [];
  contactHashKeys.forEach((x: string) => {
    n += 1;
    const query = `q${n}: ${GET_CONTACT_BY_KEY.replace('$hash_key', x)}`;
    if (n === 999) {
      queries.push([...tempQueries]);
      tempQueries = [query];
    } else {
      tempQueries.push(query);
    }
  });
  queries.push(tempQueries);

  return queries;
}

async function runContactsQueries(queries: Array<Array<string>>) {
  const { apolloClient } = auth;
  const contactsResolvedPromises: Array<Promise<any>> = [];
  const rows: Array<Contact> = [];
  if (apolloClient !== null) {
    queries.forEach((tempQuery) => {
      // eslint-disable-next-line no-async-promise-executor
      const queryPromise = new Promise(async (res, rej) => {
        try {
          if (tempQuery && tempQuery.length > 0) {
            const fullQuery = gql`query Query{${tempQuery}}`;
            const queryResults = await apolloClient.query({
              query: fullQuery,
              fetchPolicy: 'network-only',
            });

            const { data } = queryResults;
            if (data) {
              Object.keys(data).forEach((key: string) => {
                rows.push(data[key]);
              });
            }
          }
          res([]);
        } catch (e) {
          rej(e);
        }
      });
      contactsResolvedPromises.push(queryPromise);
    });

    await Promise.all(contactsResolvedPromises);
  }

  return rows;
}

async function getContactsFromRelationships(relationships: Array<any>) {
  const contactHashKeysSet = getContactsKeysFromRelationships(relationships);
  const contactHashKeys = Array.from(contactHashKeysSet.values());
  const queries = buildQueries(contactHashKeys);
  const contacts = await runContactsQueries(queries);

  return contacts;
}

interface RetrievedContactsAndRelationships {
  contactsFilteredByAssignedAndCurrentLocation: Array<Contact>;
  contactsFilteredByAssignedLocationsHierarchy: Array<Contact>;
}

export async function retrieveContacts(
  locationData: BillingProfileLocation[] | undefined,
  filteredAllLocations?: BillingProfileLocation[],
): Promise<RetrievedContactsAndRelationships> {
  const locationsHierarchyHashKeysSet = new Set<string>();
  const locationsAssignedHashKeysSet = new Set<string>();
  let highestLocation: string | undefined;

  if (locationData) {
    if (filteredAllLocations) {
      filteredAllLocations.forEach((x) => {
        const { relationship } = x;
        if (relationship) {
          locationsAssignedHashKeysSet.add(`1~${x.customer_number}`);
          const splittedCustomerNumbers = relationship.split('~');
          if (!highestLocation && splittedCustomerNumbers.length > 0) {
            [highestLocation] = splittedCustomerNumbers;
          }
          splittedCustomerNumbers.forEach((customerNumber) => {
            locationsHierarchyHashKeysSet.add(`1~${customerNumber}`);
          });
        }
        locationsHierarchyHashKeysSet.add(x.hash_key);
      });
    } else {
      locationData.forEach((x) => {
        const { relationship } = x;
        if (relationship) {
          locationsAssignedHashKeysSet.add(`1~${x.customer_number}`);
          const splittedCustomerNumbers = relationship.split('~');
          if (!highestLocation && splittedCustomerNumbers.length > 0) {
            [highestLocation] = splittedCustomerNumbers;
          }
          splittedCustomerNumbers.forEach((customerNumber) => {
            locationsHierarchyHashKeysSet.add(`1~${customerNumber}`);
          });
        }
        locationsHierarchyHashKeysSet.add(x.hash_key);
      });
    }

    if (highestLocation) {
      // This will retrieve all the contacts from the highest parent
      const relationships = await getContactLocationsRelationshipsPaginated(highestLocation, highestLocation);
      // This will filter all the contacts that are going to be ussed in the Approvals component (See GSDCP-1954 for more info)
      const contactsFilteredByAssignedLocationsHierarchySet = new Set();
      const contactsFilteredByAssignedAndCurrentLocationSet = new Set();
      const contactsFilteredByAssignedLocationsHierarchy: Array<Contact> = [];
      const contactsFilteredByAssignedAndCurrentLocation: Array<Contact> = [];
      const filteredRelationships: Array<PaginatedContactLocationRelationship> = [];
      const selectedCustomerNumber = auth.getCustomerNumber();

      relationships.forEach((x) => {
        if (x.is_deleted === false) {
          filteredRelationships.push(x);
          if (locationsHierarchyHashKeysSet.has(x.hash_key)) {
            contactsFilteredByAssignedLocationsHierarchySet.add(x.gsi1_hash_key);
          }

          if (locationsAssignedHashKeysSet.has(x.hash_key)) {
            contactsFilteredByAssignedAndCurrentLocationSet.add(x.gsi1_hash_key);
          }

          const splittedCustomerNumbers = x.gsi2_range_key ? x.gsi2_range_key.split('~') : [];
          splittedCustomerNumbers.forEach((customerNumber) => {
            if (customerNumber === selectedCustomerNumber) {
              contactsFilteredByAssignedAndCurrentLocationSet.add(x.gsi1_hash_key);
            }
          });
        }
      });
      // All contacts data is used by DR/Work Oder (See GSDCP-1961 for more info)
      // As a BA said:
      // "It doesn't matter which location because the Receiver of a DR/Work Order
      // may not be a contact at the location you selected (email/fax) OR may not be the location itself (Mail).
      // Basically, the Receiver of the Signed DR/Work Order may or may not be at the location you have either:
      // 1. Selected and have focus on from the Account Switcher (Using customer number)
      // 2. Assigned to the Billing Profile (contactsFilteredByAssignedLocationsHierarchySet)"
      const allContacts = await getContactsFromRelationships(filteredRelationships);

      allContacts.forEach((contact) => {
        if (contact) {
          if (contactsFilteredByAssignedLocationsHierarchySet.has(contact.hash_key)) {
            contactsFilteredByAssignedLocationsHierarchy.push(contact);
          }
          if (contactsFilteredByAssignedAndCurrentLocationSet.has(contact.hash_key)) {
            contactsFilteredByAssignedAndCurrentLocation.push(contact);
          }
        }
      });

      return {
        contactsFilteredByAssignedAndCurrentLocation,
        contactsFilteredByAssignedLocationsHierarchy,
      };
    }
  }

  return { contactsFilteredByAssignedAndCurrentLocation: [], contactsFilteredByAssignedLocationsHierarchy: [] };
}

export function obtainDrWorkOrderRequiredInfo(
  relationships: Array<ContactLocationRelationship>,
): DrWorkOrderRequiredInfo {
  const ersPrimaryLevelsPerLocationHashKey: Map<string, Contact> = new Map();
  const poPrimaryLevelsPerLocationHashKey: Map<string, Contact> = new Map();
  const contactsPerLocationHashKey: Map<string, Array<Contact>> = new Map();
  const contactsMap: Map<string, Contact> = new Map();
  const ersLocationsSet = new Set<string>();
  const poLocationsSet = new Set<string>();
  let poAssigned: number = 0;
  let ersAssigned: number = 0;

  relationships.forEach((contactLocationRelationship: ContactLocationRelationship) => {
    const { hash_key, contact } = contactLocationRelationship;
    const contactsInLocation = contactsPerLocationHashKey.get(hash_key.toString());

    if (contact) {
      contactsMap.set(contact.hash_key, contact);
      if (typeof contactsInLocation !== 'undefined' && typeof contact !== 'undefined' && contact !== null) {
        contactsInLocation.push(contact);
        contactsPerLocationHashKey.set(hash_key.toString(), contactsInLocation);
      } else {
        contactsPerLocationHashKey.set(hash_key.toString(), [contact]);
      }
    }

    // Process contact - contact types
    if (contact) {
      const { contact_types } = contact;
      if (typeof contact_types !== 'undefined') {
        contact_types.forEach((contactType) => {
          const { role_areas, service } = contactType;

          if (typeof role_areas !== 'undefined') {
            role_areas.forEach((contactTypeRoleArea) => {
              const { levels, role_area } = contactTypeRoleArea;
              levels.forEach((contactTypeLevel) => {
                const { level, location } = contactTypeLevel;
                if (ersLocationsSet.has(location) || poLocationsSet.has(location)) {
                  if (level === PRIMARY_LEVEL_VALUE && service === TIRE_SERVICE_VALUE) {
                    if (role_area === PO_ISSUER_ROLE_AREA_VALUE) {
                      poAssigned += 1;
                      poLocationsSet.delete(location);
                      poPrimaryLevelsPerLocationHashKey.set(location, contact);
                    } else if (role_area === ERS_MANAGER_ROLE_AREA_VALUE) {
                      ersAssigned += 1;
                      ersLocationsSet.delete(location);
                      ersPrimaryLevelsPerLocationHashKey.set(location, contact);
                    }
                  }
                }
              });
            });
          }
        });
      }
    }
  });

  return {
    ersPrimaryLevelsPerLocationHashKey,
    poPrimaryLevelsPerLocationHashKey,
    contactsPerLocationHashKey,
    poAssigned,
    ersAssigned,
    contactsMap,
    loading: false,
  };
}

export const newDefaultProfile: (
  hash_key: string,
  range_key: string,
  customer_type: string,
  customer_number: string,
  is_urban_location: boolean,
) => BillingPreferences = (
  hash_key: string,
  range_key: string,
  customer_type: string,
  customer_number: string,
  is_urban_location: boolean,
) => ({
  general_information: {
    default: false,
    service_type: BillingServiceTypes.TIRE_WHEEL,
    profile_type: is_urban_location ? BillingProfileTypes.onsite : BillingProfileTypes.ers_onsite,
    profile_name: '',
    po_required_for_service: false,
    set_all_locations: true,
  },
  assigned_locations: [],
  requested_photos: { types: [] },
  approvals: { ers_pre_approvals_limits: {}, onsite_pre_approvals_limits: {} },
  hash_key: `7~${uuid.v4()}`,
  range_key: 'v0_billing',
  gsi1_hash_key: '',
  gsi1_range_key: '',
  owner: {
    hash_key,
    range_key,
    customer_type,
    customer_number,
  },
});

export function adjustBillingProfile(
  profile: BillingPreferences,
  billingReq: Pick<SubBillingRequirements, 'po' | 'fleet_dr_approval'> | undefined,
  create: boolean,
  locations: BillingProfileLocation[],
  billingRequirements: Map<string, SubBillingRequirements>,
): BillingPreferences {
  const clonedProfile = JSON.parse(JSON.stringify(profile));

  if (create) {
    let po_required_for_service = false;
    let participates_in_approve_orders = false;
    locations.forEach((location) => {
      const { customer_number } = location;
      if (customer_number) {
        const billingRequirement = billingRequirements.get(`1~${customer_number}`);
        if (billingRequirement && billingRequirement.po && /y/i.test(String(billingRequirement.po.flag))) {
          po_required_for_service = true;
        }
        if (
          billingRequirement &&
          billingRequirement.fleet_dr_approval &&
          billingRequirement.fleet_dr_approval.flag === 'Y'
        ) {
          participates_in_approve_orders = true;
        }
      }
    });
    clonedProfile.general_information.po_required_for_service = po_required_for_service;
    clonedProfile.general_information.participates_in_approve_orders = participates_in_approve_orders;

    if (clonedProfile.general_information.po_required_for_service) {
      if (profile.ers_purchasing_procedures) {
        clonedProfile.ers_purchasing_procedures.required_authorization_to_begin_service = 'po';
        clonedProfile.ers_purchasing_procedures.primary_method_to_request_service = AuthorizationService.fleet_contact;
      } else {
        const ersPurchasinProcedure: BillingPurchasingProcedures = initPurchasingProcedure(true, true);
        clonedProfile.ers_purchasing_procedures = ersPurchasinProcedure;
      }
      if (profile.onsite_purchasing_procedures) {
        clonedProfile.onsite_purchasing_procedures.required_authorization_to_begin_service = 'po';
        clonedProfile.onsite_purchasing_procedures.primary_method_to_request_service =
          AuthorizationService.fleet_contact;
      } else {
        const onsitePurchasinProcedure: BillingPurchasingProcedures = initPurchasingProcedure(false, true);
        clonedProfile.ers_purchasing_procedures = onsitePurchasinProcedure;
      }
    }
  }

  if (!profile.approvals) {
    clonedProfile.approvals = { ers_pre_approvals_limits: {}, onsite_pre_approvals_limits: {} };
  }
  if (!profile.requested_photos) {
    clonedProfile.requested_photos = { types: [] };
  }

  return clonedProfile;
}

export async function getAllLocations(relationship: string): Promise<BillingProfileLocation[]> {
  const queryVars = {
    hash_key: `1~${relationship.replace(/^(null~)*/, '').replace(/~.*/, '')}`,
    range_key: relationship.replace(/(~null)*$/, ''),
  };
  const data: any = await getAllResultsPaginated(getChildren, queryVars, 750, 'getChildren', 'customers');
  return data;
}

export async function retrieveLocations(
  apolloClient: DefaultClient<any>,
  profileId: string,
  allLocations: boolean,
  ownerRelationship?: string,
): Promise<BillingProfileLocation[]> {
  const relationship: string = ownerRelationship || auth.getRelationship();
  let locations: BillingProfileLocation[] = [];
  const allLocationsData = getAllLocations(relationship);
  if (allLocations) {
    locations = await allLocationsData;
  } else {
    // Using this query has some complications:
    // getAssignedBillingProfileLocations
    // It can be very fast but if we need to add a new attribute we have to modify backend, frontend and imports.
    // And then mannually regression test.
    const fixedRelationship = relationship.replace(/^(null~)*/, '').replace(/~.*/, '');
    const locationsData = await apolloClient.query<QueryBillingLocations>({
      query: GET_BILLING_LOCATIONS,
      variables: {
        profile_id: profileId,
        relationship: fixedRelationship,
      },
      fetchPolicy: 'no-cache',
    });
    const locationsAssignedSet = new Set(
      locationsData.data.getAssignedBillingProfileLocations.map((x) => `1~${x.customer_number}`),
    );
    const allLocationsQueried = await allLocationsData;
    locations = allLocationsQueried.filter((x) => locationsAssignedSet.has(x.hash_key));
  }

  const assignedLocations = locations.map((x) => ({ ...x, assigned: true }));

  return assignedLocations;
}

export interface PurchasingProcValidations {
  ers_ship_to: boolean;
  required_authorization: boolean;
  primary_contact_request_authorization: boolean;
  primary_contact_phone: boolean;
  secondary_contact_phone: boolean;
  sec_contact_request_authorization: boolean;
  sec_contact_phone: boolean;
  method_to_receive_signed_order: boolean;
  primary_contact_types: boolean;
  primary_contact_level: boolean;
  sec_contact_types: boolean;
  sec_contact_level: boolean;
}

export interface ValidationErrors {
  gi_profile_name: boolean;

  pp_ers: PurchasingProcValidations;
  pp_os: PurchasingProcValidations;

  ers_max_tires: boolean;
  ers_max_wheels: boolean;

  os_max_tires: boolean;
  os_max_wheels: boolean;
  os_max_dollars: boolean;
}

export const inputValidationErrors: ValidationErrors = {
  gi_profile_name: false,
  pp_ers: {
    ers_ship_to: false,
    required_authorization: false,
    primary_contact_request_authorization: false,
    primary_contact_phone: false,
    sec_contact_request_authorization: false,
    sec_contact_phone: false,
    method_to_receive_signed_order: false,
    primary_contact_types: false,
    primary_contact_level: false,
    sec_contact_types: false,
    sec_contact_level: false,
  } as PurchasingProcValidations,

  pp_os: {
    ers_ship_to: false,
    required_authorization: false,
    primary_contact_request_authorization: false,
    primary_contact_phone: false,
    sec_contact_request_authorization: false,
    sec_contact_phone: false,
    method_to_receive_signed_order: false,
    primary_contact_types: false,
    primary_contact_level: false,
    sec_contact_types: false,
    sec_contact_level: false,
  } as PurchasingProcValidations,

  ers_max_tires: false,
  ers_max_wheels: false,

  os_max_tires: false,
  os_max_wheels: false,
  os_max_dollars: false,
};

export function checkUserPermissions(
  action: 'edit' | 'view' | 'create',
  enqueueSnackbar: any,
  permissions: ReturnType<typeof usePermissions>,
  history: ReturnType<typeof useHistory>,
) {
  const updatePermission = permissions.allowsAction('billing.update');
  const readPermission = permissions.allowsAction('billing.read');
  const createPermission = permissions.allowsAction('billing.create');

  if (readPermission === false) {
    enqueueSnackbar(
      'Your account does not have enough permissions to read the information about this billing profile.',
      { variant: 'warning' },
    );
    history.push(getBaseUrl());
  } else if ((action === 'edit' || action === 'create') && updatePermission === false) {
    enqueueSnackbar(
      'Your account does not have enough permissions to edit the information about this billing profile.',
      { variant: 'warning' },
    );
    history.push(getBaseUrl());
  } else if (action === 'create' && createPermission === false) {
    enqueueSnackbar('Your account does not have enough permissions to create a billing profile.', {
      variant: 'warning',
    });
    history.push(getBaseUrl());
  }
}

interface BPUniquenessStatus {
  availableLocations: BillingProfileLocation[];
  locationsCount: number;
  duplicatedCount: number;
}

export function checkUniqueness(
  billingProfile: BillingPreferences,
  profileID: string | null,
  affectedLocations: BillingProfileLocation[],
  billingProfiles: BillingPreferences[],
): BPUniquenessStatus {
  if (!auth || !auth.apolloClient || !affectedLocations)
    return { availableLocations: [], locationsCount: 0, duplicatedCount: 0 };

  function countDup(dupMap: Map<string, number>, customerNumber: string, bp: BillingPreferences) {
    const onsite = ([BillingProfileTypes.ers_onsite, BillingProfileTypes.onsite] as string[]).includes(
      bp.general_information.profile_type,
    );
    const ers = ([BillingProfileTypes.ers_onsite, BillingProfileTypes.ers] as string[]).includes(
      bp.general_information.profile_type,
    );
    const service = bp.general_information.service_type;
    const key = `${customerNumber}-${service}`;
    if (onsite) dupMap.set(`${key}-onsite`, 1 + (dupMap.get(`${key}-onsite`) || 0));
    if (ers) dupMap.set(`${key}-ers`, 1 + (dupMap.get(`${key}-ers`) || 0));
  }

  const currentProfilesAssignations: Map<string, number> = new Map();
  const newProfileAssignations: Map<string, number> = new Map();
  const owner_key =
    billingProfile.owner_key ||
    (billingProfile.owner && billingProfile.owner.hash_key) ||
    `1~${auth.getCustomerNumber()}`;

  const locationList = billingProfile.general_information.set_all_locations
    ? affectedLocations.filter((l) => l.hash_key === owner_key)
    : affectedLocations.filter((l) => l.hash_key !== owner_key);

  locationList.forEach((l) => countDup(newProfileAssignations, l.customer_number, billingProfile));

  billingProfiles.forEach((bp) => {
    if (bp.hash_key === billingProfile.hash_key) return;
    if (bp.hash_key === `7~${profileID}`) return;
    if (bp.owner && bp.general_information.set_all_locations) {
      countDup(currentProfilesAssignations, bp.owner.customer_number, bp);
    } else if (bp.assigned_locations) {
      bp.assigned_locations.forEach((locationNumber) => countDup(currentProfilesAssignations, locationNumber, bp));
    }
  });

  const dupMap: Map<string, boolean> = new Map();
  newProfileAssignations.forEach((cant, key) => {
    if (key && currentProfilesAssignations.has(key)) dupMap.set(key.split('-').shift() || '', true);
  });
  const availableLocations = locationList.filter((l) => !dupMap.has(l.customer_number));

  return {
    availableLocations,
    locationsCount: locationList.length,
    duplicatedCount: locationList.length - availableLocations.length,
  };
}

export function updateAssignedContactTypes(assignedContactTypes: Map<string, BillingReassignContactType>): any {
  const assignedSelectedToReassign = new Map<string, SelectedContactToReassign[]>();
  assignedContactTypes.forEach((x) => {
    const contactHashKey = x.contact.hash_key;
    const alreadyAddedContact = assignedSelectedToReassign.get(contactHashKey);
    if (alreadyAddedContact) {
      alreadyAddedContact.push({
        contact: x.contact as any,
        level: x.level,
        service: TIRE_SERVICE_VALUE,
        location: x.location,
        role_area: x.roleArea,
      });
      assignedSelectedToReassign.set(contactHashKey, alreadyAddedContact);
    } else {
      assignedSelectedToReassign.set(contactHashKey, [
        {
          contact: x.contact as any,
          level: x.level,
          service: TIRE_SERVICE_VALUE,
          location: x.location,
          role_area: x.roleArea,
        },
      ]);
    }
  });
  return assignedSelectedToReassign;
}

// Check if there are locations that still missing contact types
export function checkLocations(
  profile: BillingPreferences,
  contacts: Array<Contact>,
  locationList: BillingProfileLocation[],
  assignedContactTypes: Map<string, BillingReassignContactType>,
): any {
  const contactLevelsToCheck = new Set<string>();
  const contactTypesToCheck = new Set<string>();
  const combosToCheck = new Map<string, { contact_type: string; contact_level: string; contact_type_level: string }>();
  const {
    ers_purchasing_procedures: ersPurchasingProcedureToCheck,
    onsite_purchasing_procedures: onsitePurchasingProcedureToCheck,
  } = profile;

  const mapContactType: { [billingContactType: string]: string } = {
    tire_ers_manager: ERS_MANAGER_ROLE_AREA_VALUE,
    tire_po_issuer: PO_ISSUER_ROLE_AREA_VALUE,
  };

  const checkPurchasingProcedure = (purchasingProcedure: BillingPurchasingProcedures) => {
    // Check Primary
    const { pri_ers_authorized_contact_level, pri_ers_authorized_contact_type } = purchasingProcedure;
    if (pri_ers_authorized_contact_level && pri_ers_authorized_contact_type) {
      // Check contact level
      contactLevelsToCheck.add(pri_ers_authorized_contact_level);
      pri_ers_authorized_contact_type.forEach((priErsAuthorizedContactType) => {
        // Check contact types
        contactTypesToCheck.add(mapContactType[priErsAuthorizedContactType]);
        combosToCheck.set(`${PRIMARY_LEVEL_VALUE}${mapContactType[priErsAuthorizedContactType]}`, {
          contact_level: pri_ers_authorized_contact_level,
          contact_type: mapContactType[priErsAuthorizedContactType],
          contact_type_level: PRIMARY_LEVEL_VALUE,
        });
      });
    }

    // Check Secondary
    const { sec_ers_authorized_contact_level, sec_ers_authorized_contact_type } = purchasingProcedure;
    if (sec_ers_authorized_contact_level && sec_ers_authorized_contact_type) {
      // Check contact level
      contactLevelsToCheck.add(sec_ers_authorized_contact_level);
      sec_ers_authorized_contact_type.forEach((secErsAuthorizedContactType) => {
        // Check contact type
        contactTypesToCheck.add(mapContactType[secErsAuthorizedContactType]);
        combosToCheck.set(`${SECONDARY_LEVEL_VALUE}${mapContactType[secErsAuthorizedContactType]}`, {
          contact_level: sec_ers_authorized_contact_level,
          contact_type: mapContactType[secErsAuthorizedContactType],
          contact_type_level: SECONDARY_LEVEL_VALUE,
        });
      });
    }
  };

  if (profile.general_information.profile_type === BillingProfileTypes.ers_onsite) {
    if (ersPurchasingProcedureToCheck) {
      checkPurchasingProcedure(ersPurchasingProcedureToCheck);
    }

    if (onsitePurchasingProcedureToCheck) {
      checkPurchasingProcedure(onsitePurchasingProcedureToCheck);
    }
  } else if (profile.general_information.profile_type === BillingProfileTypes.ers) {
    if (ersPurchasingProcedureToCheck) {
      checkPurchasingProcedure(ersPurchasingProcedureToCheck);
    }
  } else if (profile.general_information.profile_type === BillingProfileTypes.onsite) {
    if (onsitePurchasingProcedureToCheck) {
      checkPurchasingProcedure(onsitePurchasingProcedureToCheck);
    }
  }

  interface CheckedLocation {
    location: BillingProfileLocation;
    contact_level: string;
    contact_type: string;
    contact_type_level: string;
  }
  const locationsToCheck = new Map<string, CheckedLocation>();
  locationList.forEach((locationToCheck) => {
    if (contactLevelsToCheck.has(locationToCheck.customer_type)) {
      combosToCheck.forEach((combo) => {
        locationsToCheck.set(locationToCheck.hash_key + combo.contact_type_level + combo.contact_type, {
          location: locationToCheck,
          contact_level: combo.contact_level,
          contact_type: combo.contact_type,
          contact_type_level: combo.contact_type_level,
        });
      });
    }
  });

  contacts.forEach((contactToCheck) => {
    if (!contactToCheck.contact_types) return;
    contactToCheck.contact_types.forEach((contactType) => {
      if (!contactType.role_areas) return;
      contactType.role_areas.forEach((contactTypeRoleArea) => {
        if (!contactTypeRoleArea) return;
        contactTypeRoleArea.levels.forEach((contactTypeLevels) => {
          locationsToCheck.delete(contactTypeLevels.location + contactTypeLevels.level + PO_ISSUER_ROLE_AREA_VALUE);
          locationsToCheck.delete(contactTypeLevels.location + contactTypeLevels.level + ERS_MANAGER_ROLE_AREA_VALUE);
        });
      });
    });
  });

  // Also check reassigned contact types.
  assignedContactTypes.forEach((assignedContactTypeToCheck) => {
    if (locationsToCheck.has(assignedContactTypeToCheck.location.hash_key)) {
      locationsToCheck.delete(
        assignedContactTypeToCheck.location.hash_key + assignedContactTypeToCheck.level + PO_ISSUER_ROLE_AREA_VALUE,
      );
      locationsToCheck.delete(
        assignedContactTypeToCheck.location.hash_key + assignedContactTypeToCheck.level + ERS_MANAGER_ROLE_AREA_VALUE,
      );
    }
  });

  const alertMissingContactTypes = locationsToCheck.size > 0;
  if (alertMissingContactTypes === true) {
    // Group contact types by Contact Levels & Contact Type level
    const grouppedByContactLevel = Array.from(locationsToCheck.values()).reduce((pv, x) => {
      const keyGroupedBy = x.contact_level + x.contact_type_level;
      if (pv[keyGroupedBy]) {
        pv[keyGroupedBy].push(x);
      } else {
        // eslint-disable-next-line no-param-reassign
        pv[keyGroupedBy] = [x];
      }
      return pv;
    }, {} as { [contact_level: string]: Array<CheckedLocation> });

    if (grouppedByContactLevel) {
      Object.keys(grouppedByContactLevel).forEach((key) => {
        const grouppedCase = grouppedByContactLevel[key];
        let groupContactLevel = '';
        let groupContactTypeLevel = '';
        const contactTypes = new Set<string>();
        grouppedCase.forEach((groupCase) => {
          groupContactLevel = groupCase.contact_level;
          groupContactTypeLevel = groupCase.contact_type_level;
          contactTypes.add(groupCase.contact_type);
        });

        // Each key is a contact_level
        // The value are the contact types.
        return {
          contactLevel: groupContactLevel,
          contactTypeLevel: groupContactTypeLevel,
          contactTypes: Array.from(contactTypes.values()),
          show: true,
          showAlert: true,
        };
      });
    }
  }
}

export function buildProfileInput(
  profile: BillingPreferences,
  profileId: string,
  owner_key: string | undefined,
  owner_relationship: string | undefined,
): BillingPreferencesInput {
  const profileInput: BillingPreferencesInput = {
    is_deleted: false,
    general_information: _.omit(profile.general_information, ['__typename']) as any,
    ers_purchasing_procedures:
      profile.general_information.profile_type.toUpperCase() !== 'onsite'
        ? (_.omit(profile.ers_purchasing_procedures, ['__typename']) as any)
        : undefined,
    onsite_purchasing_procedures:
      profile.general_information.profile_type !== BillingProfileTypes.ers
        ? (_.omit(profile.onsite_purchasing_procedures, ['__typename']) as any)
        : undefined,
    approvals: fixApprovals(profile.approvals),
    tire_details: profile.tire_details
      ? (profile.tire_details.map((x) => _.omit(x, ['__typename'])) as any)
      : profile.tire_details,
    wheel_details: profile.wheel_details
      ? (profile.wheel_details.map((x) => _.omit(x, ['__typename'])) as any)
      : profile.wheel_details,
    hash_key: `7~${profileId}`,
    requested_photos: profile.requested_photos
      ? _.omit(profile.requested_photos, ['__typename'])
      : profile.requested_photos,
    range_key: 'v0_billing',
    gsi4_hash_key: `7~${auth.getUltimateParent().customerNumber}`,
    gsi4_range_key: `${auth.getRelationship()}`,
    owner_key: owner_key || `1~${auth.getCustomerNumber()}`,
    owner_relationship: owner_relationship || auth.getRelationship(),
  };

  // const profileInputObj = JSON.parse(JSON.stringify(profileInput)) as BillingPreferencesInput;
  removeExtraFields(profileInput, 'ers_purchasing_procedures');
  removeExtraFields(profileInput, 'onsite_purchasing_procedures');

  return profileInput;
}

export async function updateBillingProfileMutation(
  apolloClient: DefaultClient<any>,
  profile: BillingPreferences,
  originalProfile: BillingPreferences | undefined,
  profileId: string,
  locationList: BillingProfileLocation[],
  originalLocations: BillingProfileLocation[],
) {
  const savingParsedProfile = JSON.parse(JSON.stringify(profile));
  const profileInputObj = buildProfileInput(
    savingParsedProfile,
    profileId,
    profile.owner_key,
    profile.owner_relationship,
  );
  const locationsUnassignedSet = new Set();
  const locationsUnassignedArr = new Array<any>();
  originalLocations.forEach((originalLocation) => {
    locationsUnassignedSet.add(originalLocation.hash_key);
  });

  locationList.forEach((locationAssigned) => {
    locationsUnassignedSet.delete(locationAssigned.hash_key);
  });

  // Add assigned locations
  const mappedLocationsForUpdate = locationList.map((x) => ({
    hash_key: x.hash_key,
    assigned: x.assigned,
    relationship: x.relationship,
    customer_number: x.customer_number,
    extrnl_cust_id: x.extrnl_cust_id,
    customer_name: x.customer_name,
    customer_addr1: x.customer_addr1,
    customer_addr2: x.customer_addr2,
    customer_city: x.customer_city,
    customer_state: x.customer_state,
    customer_zip: x.customer_zip,
    customer_type: x.customer_type,
  }));

  // Add unassigned locations
  originalLocations.forEach((originalLocation) => {
    if (locationsUnassignedSet.has(originalLocation.hash_key)) {
      const unassignedLocation = {
        hash_key: originalLocation.hash_key,
        assigned: false,
        relationship: originalLocation.relationship,
        customer_number: originalLocation.customer_number,
        extrnl_cust_id: originalLocation.extrnl_cust_id,
        customer_name: originalLocation.customer_name,
        customer_addr1: originalLocation.customer_addr1,
        customer_addr2: originalLocation.customer_addr2,
        customer_city: originalLocation.customer_city,
        customer_state: originalLocation.customer_state,
        customer_zip: originalLocation.customer_zip,
        customer_type: originalLocation.customer_type,
      };
      mappedLocationsForUpdate.push(unassignedLocation);
      locationsUnassignedArr.push(unassignedLocation);
    }
  });

  try {
    if (
      originalProfile &&
      originalProfile.general_information.set_all_locations === false &&
      profile.general_information.set_all_locations === true
    ) {
      await apolloClient.mutate({
        mutation: UPDATE_BILLING_PROFILE,
        variables: {
          profile: profileInputObj,
          locations: locationsUnassignedArr,
        },
      });
    } else {
      await apolloClient.mutate({
        mutation: UPDATE_BILLING_PROFILE,
        variables: {
          profile: profileInputObj,
          locations: profile.general_information.set_all_locations ? [] : mappedLocationsForUpdate,
        },
      });
    }
  } catch (e) {
    console.error(e);
    return false;
  }

  return true;
}

export async function updateContactsWheelsTiresAndDollarAmount(
  apolloClient: DefaultClient<any>,
  specialContacts: Array<Contact>,
  contacts: Array<Contact>,
): Promise<any> {
  apolloClient.mutate({
    mutation: UPDATE_SPECIAL_CONTACTS,
    variables: {
      input: specialContacts.map((c) => ({
        hash_key: c.hash_key,
        num_of_wheels: c.num_of_wheels,
        num_of_tires: c.num_of_tires,
        tire_dollar_amount: c.tire_dollar_amount,
      })),
    },
  });

  const specialContactsMap = new Map();
  specialContacts.forEach((x) => {
    specialContactsMap.set(x.hash_key, x);
  });
  contacts.forEach((x) => {
    const specialContact = specialContactsMap.get(x.hash_key);
    if (specialContact) {
      x.num_of_tires = specialContact.num_of_tires;
      x.num_of_wheels = specialContact.num_of_wheels;
      x.tire_dollar_amount = specialContact.tire_dollar_amount;
    }
  });
}

export async function getAllBillingRequirements() {
  const customerData = auth.getCustomerData();
  const mappedBillingRequirementsPerLocationHashKey = new Map<string, any>();

  if (customerData) {
    // Control PO Required for Service:
    const paginatedQuery = gql`
      query GenericPageableEntity(
        $hash_key: String!
        $range_key: String!
        $index: String!
        $limit: Int
        $nextToken: String
      ) {
        getEntityPaginated(
          hash_key: $hash_key
          range_key: $range_key
          index: $index
          limit: $limit
          nextToken: $nextToken
        ) {
          items
          nextToken
        }
      }
    `;

    const highestLocation = getHighestLocation(customerData);
    const chainedHierarchy = getChainedHierarchy(customerData);

    try {
      const allBillingRequirementsPaginated = await getAllResultsPaginated(
        paginatedQuery,
        {
          hash_key: `6~${highestLocation}`,
          range_key: chainedHierarchy,
          index: 'gsi1',
        },
        500,
        'getEntityPaginated',
      );

      if (allBillingRequirementsPaginated && Array.isArray(allBillingRequirementsPaginated)) {
        allBillingRequirementsPaginated.forEach((paginatedBillingProfiles: any) => {
          const parsedBillingRequirements = JSON.parse(paginatedBillingProfiles);
          if (parsedBillingRequirements && Array.isArray(parsedBillingRequirements)) {
            parsedBillingRequirements.forEach((billingRequirement) => {
              mappedBillingRequirementsPerLocationHashKey.set(
                `1~${billingRequirement.customer_number}`,
                billingRequirement,
              );
            });
          }
        });
      }
    } catch (e) {
      console.error('Error obtaining billing requirements.');
    }
  }
  return mappedBillingRequirementsPerLocationHashKey;
}
