import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { withTheme } from '@material-ui/styles';
import { useTranslation } from '@michelin/central-provider';
import { getOptionLabel } from '@michelin/select-options-provider';

const useStyles = makeStyles(() => ({
  summaryContainer: {
    borderRadius: '5px',
    width: '444px',
    padding: '5px',
  },
}));

const WheelListDetails = (props: any) => {
  const classes = useStyles(props);
  const { t } = useTranslation();

  if (!props.detail) return <></>;

  const labelWheelType: string = getOptionLabel('wheel_types', props.detail.wheel_type);
  const labelWheelMaterial: string = getOptionLabel('wheel_materials', props.detail.wheel_material);
  const labelWheelPosition: string = getOptionLabel('wheel_positions', props.detail.wheel_position);
  const labelWheelOffset: string = getOptionLabel('wheel_offsets', props.detail.wheel_offset);

  return (
    <Grid container className={classes.summaryContainer}>
      <Grid item xs={6} lg={6}>
        {t('Wheel Type')}
      </Grid>
      <Grid item xs={6} lg={6}>
        {labelWheelType}
      </Grid>
      <Grid item xs={6} lg={6}>
        {t('Wheel Material')}
      </Grid>
      <Grid item xs={6} lg={6}>
        {labelWheelMaterial}
      </Grid>
      <Grid item xs={6} lg={6}>
        {t('Wheel Position')}
      </Grid>
      <Grid item xs={6} lg={6}>
        {labelWheelPosition}
      </Grid>
      <Grid item xs={6} lg={6}>
        {t('Wheel Offset')}
      </Grid>
      <Grid item xs={6} lg={6}>
        {labelWheelOffset}
      </Grid>
      <Grid item xs={6} lg={6}>
        {t('Color/Finish')}
      </Grid>
      <Grid item xs={6} lg={6}>
        {props.detail.color_finish}
      </Grid>
      <Grid item xs={6} lg={6}>
        {t('Operating Company')}
      </Grid>
      <Grid item xs={6} lg={6}>
        {props.detail.operating_company}
      </Grid>
    </Grid>
  );
};

export default withTheme(WheelListDetails);
