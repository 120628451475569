export interface Date {
  key: string;
  label: string;
}

export const weekdays: Date[] = [
  { key: 'monday', label: 'Monday' },
  { key: 'tuesday', label: 'Tuesday' },
  { key: 'wednesday', label: 'Wednesday' },
  { key: 'thursday', label: 'Thursday' },
  { key: 'friday', label: 'Friday' },
  { key: 'saturday', label: 'Saturday' },
  { key: 'sunday', label: 'Sunday' },
];

export const us_holidays: Date[] = [
  { key: 'new_years_day', label: "New Year's Day" },
  { key: 'memorial_day', label: 'Memorial Day' },
  { key: 'independence_day', label: 'Independence Day' },
  { key: 'labor_day', label: 'Labor Day' },
  { key: 'thanksgiving_day', label: 'Thanksgiving' },
  { key: 'christmas_day', label: 'Christmas' },
  { key: 'christmas_eve_day', label: 'Christmas Eve' },
  { key: 'new_years_eve_day', label: "New Year's Eve" },
];

export const ca_holidays: Date[] = [
  { key: 'new_years_day', label: "New Year's Day" },
  { key: 'day_after_new_years_day', label: "Day After New Year's Day" },
  { key: 'ns_heritage_day', label: 'NS Heritage Day' },
  { key: 'good_friday', label: 'Good Friday' },
  { key: 'jean_baptiste_day', label: 'Fete Nationale-Jean Baptiste Day' },
  { key: 'canada_day', label: 'Canada Day' },
  { key: 'labour_day', label: 'Labour Day' },
  { key: 'thanksgiving_day', label: 'Thanksgiving' },
  { key: 'christmas_day', label: 'Christmas' },
  { key: 'christmas_eve_day', label: 'Christmas Eve' },
  { key: 'boxing_day', label: 'Boxing Day' },
  { key: 'new_years_eve_day', label: "New Year's Eve" },
];

export const day_labels: any = {};
us_holidays.forEach((day) => (day_labels[day.key] = day.label));
ca_holidays.forEach((day) => (day_labels[day.key] = day.label));
weekdays.forEach((day) => (day_labels[day.key] = day.label));

export function getDates(holidays?: string | null): Date[] {
  switch (holidays) {
    case 'us':
      return [...weekdays, ...us_holidays];
    case 'ca':
      return [...weekdays, ...ca_holidays];
    default:
      return [...weekdays];
  }
}
