import React from 'react';
import { CentralProviderFooter } from '@michelin/central-provider';
import { runningOnCanvas } from '@michelin/central-provider/dist/utils/canvas';
import { auth } from 'Auth';
import { getVersion } from 'components/Util/version';

export function Footer() {
  if (runningOnCanvas()) return null;
  return (
    <CentralProviderFooter
      versions={[
        {
          application: 'UI',
          version: getVersion() || '',
        },
        {
          application: 'API',
          version: auth.getApiVersion() || '...',
        },
      ]}
      showLegalInformation={false}
      showCopyright
    />
  );
}
