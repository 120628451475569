/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import { Badge, Link, useMediaQuery, useTheme } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';
import {
  Fab,
  FilterGroup,
  GlobalActions,
  IconButton,
  Panel,
  useDialogs,
  useListSelectFilter,
} from '@michelin/acid-components';
import {
  clearMaestroDataSourceCache,
  maestroApiCall,
  useMaestroDataSource,
  usePermissions,
  useTranslation,
} from '@michelin/central-provider';
import { getOptionLabel, getOptions } from '@michelin/select-options-provider';
import { ITireProfile } from 'components/TireProfile/TireProfileTypes';
import { sopDelete, sopList } from 'components/Types/APITypes';
import { TABBED_CONTENT_HEIGHT_GRID } from 'components/Util';
import { Template } from 'devextreme-react';
import DataGrid, { Column, Scrolling, SearchPanel } from 'devextreme-react/data-grid';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';
import { auth } from '../../Auth';
import { useGetTireProfiles } from '../../hooks/useSOP';
import { getBaseUrl } from '../../prefs-and-service-offers';
import CreatedByAvatar from '../CreatedByAvatar';

interface TireProfilesCellProps {
  profiles: ITireProfile[];
}

function TireProfilesCell({ profiles }: TireProfilesCellProps) {
  const { t } = useTranslation();
  const cleanProfiles = (profiles || []).filter((profile) => !!profile);
  if (!cleanProfiles.length) return <i style={{ opacity: 0.5 }}>{t('No profiles')}</i>;
  return (
    <ul style={{ paddingLeft: 12 }}>
      {cleanProfiles.map((profile) => (
        <li key={profile.hash_key}>
          <Link
            onClick={() =>
              window.open(`${getBaseUrl()}/tire-profile/${profile?.hash_key.split('~')[1]}/view`, '_blank')?.focus()
            }
            style={{ cursor: 'pointer' }}
          >
            {profile?.main?.profile_name}
          </Link>
        </li>
      ))}
    </ul>
  );
}

export default function SOPList() {
  const { t } = useTranslation();
  const { errorDialog, confirmDialog, loadingDialog } = useDialogs();
  const { selectedAccount } = useParams<{ [key: string]: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const permissions = usePermissions();
  const theme = useTheme();
  const history = useHistory();
  const tabletView = useMediaQuery(theme.breakpoints.down(800));
  const cellView = useMediaQuery(theme.breakpoints.down(500));

  const createPermission = permissions.allowsAction('sop.create');
  const listPermission = permissions.allowsAction('sop.list');
  const readPermission = permissions.allowsAction('sop.read');

  if (!listPermission) {
    enqueueSnackbar(t('User has no permissions to list SOPs.'), { variant: 'warning' });
    history.push(`${getBaseUrl()}/all-preferences`);
  } else if (!readPermission) {
    enqueueSnackbar(t('User has no permissions to read SOPs.'), { variant: 'warning' });
    history.push(`${getBaseUrl()}/all-preferences`);
  }

  const accountTypes = useMemo(() => getOptions('account_types'), []);

  const levelFilter = useListSelectFilter({
    label: t('Creator Level'),
    options: accountTypes,
    unselectedLabel: t('Any Level'),
    hashParam: 'profileLevel',
  });

  const filterGroup = useMemo(() => new FilterGroup([levelFilter.filter]), [levelFilter.filter]);
  const [hasOnsiteTireProfiles, setHasOnsiteTireProfiles] = useState<boolean>(false);
  const tireProfilesQuery = useGetTireProfiles({ fleetId: selectedAccount });

  useEffect(() => {
    clearMaestroDataSourceCache('sop-list');
  }, []);

  useEffect(() => {
    if (tireProfilesQuery.data) setHasOnsiteTireProfiles(!!tireProfilesQuery.data.length);
  }, [tireProfilesQuery.data]);

  const ds = useMaestroDataSource({
    API: sopList(auth.getCustomerNumber()),
    reqOptions: {
      filters: {
        level: levelFilter.value?.toLowerCase(),
      },
    },
    componentPlacements: {
      filtersPlacement: tabletView ? 'outside' : 'embeded',
      searchPlacement: cellView ? 'outside' : 'embeded',
    },
    filterGroup,
    cacheKey: 'sop-list',
  });

  /* ******************** */
  /* ******************** */
  /* Render Cell Methods  */
  /* ******************** */
  /* ******************** */

  const CreatedByCell = (props: any) => {
    if (!props.row || !props.row.data || !props.row.data.owner) return null;
    return (
      <CreatedByAvatar
        customerType={props.row.data.owner.customer_type}
        customerNumber={props.row.data.owner.customer_number}
      />
    );
  };

  interface apolloResult {
    success: boolean;
    message: string;
  }

  const deleteProfile = async (id: string): Promise<{ success: boolean; message: string }> => {
    try {
      const res = await maestroApiCall(sopDelete(auth.getCustomerNumber(), id));
      if (res.is_deleted) {
        enqueueSnackbar(t('You have successfully deleted the profile.'), { variant: 'success' });
        return { success: true, message: t('Succeed') };
      }
      return { success: false, message: t('Unexpected error') };
    } catch (e) {
      return { success: false, message: t('Network error') };
    }
  };

  function ActionsCell(props: any) {
    const canDelete = props.row.data.allowedActions?.delete;

    return (
      <div>
        {!canDelete ? null : (
          <IconButton
            aria-label="delete"
            style={{ margin: '8px' }}
            onClick={async () => {
              const proceed = await confirmDialog(
                t('Are you sure you want to delete this SOP Profile?'),
                t('Delete SOP Profile'),
                t('Yes'),
                t('No'),
              );
              if (!proceed) return;
              const finishLoading = loadingDialog(undefined, t('Deleting profile...'));
              const result: apolloResult = await deleteProfile(props.row.data.hash_key.split('~').pop());
              finishLoading();
              clearMaestroDataSourceCache('sop-list');
              ds.dataSource.reload();
              if (!result.success) {
                errorDialog(result.message, t('Error Deleting Profile'));
              }
            }}
            size="small"
          >
            <Delete fontSize="small" color="primary" />
          </IconButton>
        )}
      </div>
    );
  }

  const RowPrepared = (row: any): any => {
    if (!row.data || (Object.keys(row.data).length === 0 && row.data.constructor === Object)) return;
    if (
      row.rowType.toLowerCase() === 'data' &&
      (auth.iOwnThisAccountType(row.data.owner.customer_type, 'switched') || 0) < 0
    ) {
      // eslint-disable-next-line no-param-reassign
      row.rowElement.style.backgroundColor = '#DDEE9928'; // using color with alpha channel to support dark and light modes
    }
  };

  return (
    <Panel spacing={0}>
      <div>
        {tabletView ? ds.renderFilterFields(null, { margin: 10 }) : undefined}
        {cellView ? (
          <div style={{ marginBottom: -10, textAlign: 'right' }}>{ds.renderResultsCounter()}</div>
        ) : undefined}
      </div>
      <DataGrid
        showBorders
        columnAutoWidth
        onRowPrepared={RowPrepared}
        dataSource={ds.dataSource}
        wordWrapEnabled
        remoteOperations
        onToolbarPreparing={ds.onToolbarPreparing}
        className="freespaced-table"
        style={{ height: TABBED_CONTENT_HEIGHT_GRID }}
      >
        <Template name="filterFields" render={ds.renderFilterFields} />
        <Template name="resultsCounter" render={ds.renderResultsCounter} />
        <SearchPanel visible />
        <Scrolling mode="virtual" />

        <Column
          caption={t('Creator')}
          dataType="string"
          dataField="owner.customer_type"
          allowSorting
          width={100}
          alignment="center"
          cellRender={({ row }) => <CreatedByCell row={row} />}
          allowSearch={false}
        />

        <Column
          caption={t('Profile Name')}
          dataType="string"
          dataField="profile_name"
          minWidth={200}
          cellRender={({ row }) => {
            if (row.data.default) {
              return (
                <Badge color="primary" variant="dot" anchorOrigin={{ horizontal: 'left', vertical: 'top' }}>
                  <div style={{ padding: '0.3rem' }}>
                    <Link
                      onClick={() => history.push(`${row.data.hash_key.split('~').pop()}/general-info/view`)}
                      style={{ cursor: 'pointer' }}
                    >
                      {row.data.profile_name}
                    </Link>
                  </div>
                </Badge>
              );
            }
            return (
              <Link
                onClick={() => history.push(`${row.data.hash_key.split('~').pop()}/general-info/view`)}
                style={{ cursor: 'pointer' }}
              >
                {row.data.profile_name}
              </Link>
            );
          }}
          allowSearch
        />
        <Column
          caption={t('Service Type')}
          allowSorting={false}
          width={150}
          cellRender={({ row }) => t(getOptionLabel('core_profile_sop_service_types', row.data.service_type || ''))}
          dataField="tire_profiles"
          allowSearch={false}
        />
        <Column
          caption={t('Tire Profiles')}
          allowSorting={false}
          width={150}
          cellRender={({ row }) => <TireProfilesCell profiles={row.data.tire_profiles} />}
          dataField="tire_profiles"
          allowSearch={false}
        />
        <Column allowSearch={false} allowSorting={false} width={120} cellRender={(row) => <ActionsCell row={row} />} />
      </DataGrid>

      <GlobalActions>
        <Fab
          color="primary"
          label={t('Add')}
          onClick={() => {
            if (hasOnsiteTireProfiles) {
              history.push(`${getBaseUrl()}/sop/general-info/add`);
            } else {
              confirmDialog(
                <>
                  {t(
                    'At least one Tire Profile having its Profile Type equal to Onsite or ERS & Onsite must either be assigned or inherited from it’s parent location in order to create a new SOP Profile. Please do one of the following:',
                  )}
                  <ol>
                    <li>
                      {t(
                        'Update the Profile Type for an existing ERS Tire Profile assigned to or inherited by this location from ERS to ERS & Onsite.',
                      )}
                    </li>
                    <li>
                      {t(
                        'Update the Assigned Locations for an existing Onsite or ERS & Onsite Tire Profile to include this location.',
                      )}
                    </li>
                    <li>
                      {t(
                        'Create a new Onsite or ERS & Onsite Tire Profile and include this location as one being assigned. If this is done at a parent location level, be sure to either select ALL locations or to select this location manually when prompted.',
                      )}
                    </li>
                  </ol>
                </>,
                t('Missing Tire Profile'),
                t('Navigate to Tires'),
                t('Cancel'),
                () => {
                  history.push(`${getBaseUrl()}/tire-profile`);
                },
              );
            }
          }}
          disabled={!createPermission}
        >
          <Add />
        </Fab>
      </GlobalActions>
    </Panel>
  );
}
