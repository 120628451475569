import React from 'react';
import ReactQuill from 'react-quill';

interface ReactQuillEditorProps {
  id: string;
  content: string | undefined;
  disabled?: boolean;
  readOnly?: boolean;
  // This config option should not support the isEditing and readOnly props.
  config?: {
    isEditing?: boolean;
    readOnly?: boolean;
    height?: number;
  };
  onBlurEditor?: Function;
  onUpdateEditorValue?: Function;
}

const ReactQuillEditor = (props: ReactQuillEditorProps) => {
  const toolbarModules = [
    [{ header: '1' }, { header: '2' }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    [{ color: [] }, { background: [] }],
    ['clean'],
  ];

  const quillModules = {
    toolbar: props?.config?.isEditing || !props?.readOnly ? toolbarModules : null,
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const quillFormats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'color',
    'background',
  ];

  const height = props?.config?.height ? props.config.height : 200;
  const readOnly = props?.config?.readOnly || props?.readOnly;

  const change = (value: string) => {
    if ((props?.config?.isEditing || !props?.readOnly) && props.onUpdateEditorValue) {
      props.onUpdateEditorValue(props.id, value);
    }
  };

  const blur = () => {
    if (props?.config?.isEditing && props.onBlurEditor) {
      props.onBlurEditor();
    }
  };

  return (
    <ReactQuill
      style={{ height: height, width: '100%', marginBottom: readOnly ? 0 : 42 }}
      id={props.id}
      onChange={change}
      onBlur={blur}
      value={props.content || ''}
      theme="snow"
      modules={quillModules}
      formats={quillFormats}
      readOnly={readOnly}
    />
  );
};

export { ReactQuillEditor };

export type { ReactQuillEditorProps };
