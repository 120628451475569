import gql from 'graphql-tag';
import { auth } from '../../Auth';

export interface AssigneeCustomerType {
  hash_key: string;
  range_key: string;
  customer_name: string;
  customer_type: string;
  extrnl_cust_id: string;
  customer_addr1: string;
  customer_addr2: string;
  customer_city: string;
  customer_state: string;
  customer_zip: string;
  customer_country: string;
  customer_number: string;
  parent_company_number: string;
  home_office_number: string;
  bill_to_customer: string;
  ship_to_customer: string;
  relationship: string;
  children_count: {
    st: string;
  };
}

export const customerData = `
    hash_key
    range_key
    customer_name
    customer_type
    extrnl_cust_id
    customer_addr1
    customer_addr2
    customer_city
    customer_state
    customer_zip
    customer_country
    customer_number
    parent_company_number
    home_office_number
    bill_to_customer
    ship_to_customer
    relationship
    children_count {
        st
    }
`;

export const getChildrenForAssignments = gql`
  query getCustomerSelectableHierarchy($accountNumber: String!, $nextToken: String) {
    getCustomerSelectableHierarchy(accountNumber: $accountNumber, nextToken: $nextToken) {
      nextToken
      customers {
          ${customerData}
      }
    }
  }
`;

export async function getCustomers(customers: string[]) {
  const response: any = [];
  if (!auth.apolloClient) return null;
  let queryResponse: any = null;

  try {
    const variables: any = { range_key: 'v0_customer' };
    const queries: any = [];
    let header = '$range_key: String!';
    customers.forEach((location: string) => {
      if (!location || location === 'null') return;
      // eslint-disable-next-line no-param-reassign
      location = location.split('~').pop() || '';
      variables[`customer_${location}`] = `1~${location}`;
      header += `, $customer_${location}: String!`;
      const query = `
        customer_${location}:
          getCustomerAssets(hash_key: $customer_${location}, range_key: $range_key) {
            customer {
              ${customerData}
            }
          }
      `;
      queries.push(query);
    });

    if (queries.length) {
      queryResponse = await auth.apolloClient.query({
        query: gql`query GetCustomerAssets(${header}) {
          ${queries.join('\n')}
        }`,
        variables,
        fetchPolicy: 'cache-first',
      });
    }
  } catch (e) {
    return null;
  }

  if (queryResponse && queryResponse.data) {
    customers.forEach((cust: string) => {
      // eslint-disable-next-line no-param-reassign
      cust = cust.split('~').pop() || '';
      if (queryResponse.data[`customer_${cust}`]) response.push(queryResponse.data[`customer_${cust}`].customer);
    });
  }

  return response;
}
