/* eslint-disable no-param-reassign */

/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';
import { DialogContent, Grid, Table, TableBody, TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core';
import { Delete, Note } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import { Button, IconButton, LoadingBounce, Panel, Select, useDialogs } from '@michelin/acid-components';
import { usePermissions, useSnackbar, useTranslation } from '@michelin/central-provider';
import { PassengerLtPriority, TireProfileOVM, TireProfilePriorities } from '@michelin/fcp-view-models';
import { SelectOption } from '@michelin/select-options-provider';
import { PlainMessage } from 'components/Messages';
import { PassengerLtProducts, useGetPassengerLTProducts } from 'hooks/useTireProfile';
import { cloneDeep, isEqual } from 'lodash';
import { Actions } from 'modules/Tires/types';
import { DragDropContext, Draggable, DraggingStyle, Droppable, NotDraggingStyle } from 'react-beautiful-dnd';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { LTMspnSearch } from '../../Dialogs/LTMspnSearch';
import { Notes } from '../../Dialogs/Notes';
import { getAxle } from '../../utils';

type Props = {
  action: Actions;
};
const useStyles = makeStyles((theme: any) => ({
  header: {
    paddingRight: '3px',
    paddingLeft: '3px',
  },
  tableCell: {
    paddingRight: '3px',
    paddingLeft: '3px',
  },
}));

const brandSort = (a: SelectOption, b: SelectOption) => {
  if (isEqual(a.value.toUpperCase(), 'MICHELIN')) return -4;
  if (isEqual(b.value.toUpperCase(), 'MICHELIN')) return 4;
  if (isEqual(a.value.toUpperCase(), 'BFGOODRICH')) return -3;
  if (isEqual(b.value.toUpperCase(), 'BFGOODRICH')) return 3;
  if (isEqual(a.value.toUpperCase(), 'UNIROYAL')) return -2;
  if (isEqual(b.value.toUpperCase(), 'UNIROYAL')) return 2;
  if (a.value < b.value) return -1;
  if (a.value > b.value) return 1;
  return 0;
};

export function PassengerLtTirePriority({ action }: Props) {
  const { getValues } = useFormContext<TireProfileOVM>();
  const { location } = usePermissions();
  const { t } = useTranslation();

  const products = useGetPassengerLTProducts(location?.customer_country || '');
  const axle = getAxle('all_axles', getValues());
  if (!axle) return <></>;
  if (products.isLoading)
    return (
      <Panel title={t('Passenger/LT Tire Replacement Priority')} spacing={0}>
        <LoadingBounce />
      </Panel>
    );
  if (products.isError || products.data === null)
    return (
      <Panel title={t('Passenger/LT Tire Replacement Priority')} spacing={0}>
        <Grid container>
          <Grid item xs={12} spacing={2}>
            <PlainMessage
              title={t('Database Error')}
              messages={[
                t('Application could not load products data.'),
                t('Please try again later or contact support if the error persists'),
              ]}
            />
          </Grid>
        </Grid>
      </Panel>
    );
  if (products.data) return <PassengerLtTirePriorityDetail action={action} products={products.data || []} />;
  return null;
}

type PropsDetail = {
  action: Actions;
  products: PassengerLtProducts[];
};

function PassengerLtTirePriorityDetail({ action, products }: PropsDetail) {
  const { t } = useTranslation();
  const dialogs = useDialogs();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [brandOptions, setBrandOptions] = useState<SelectOption[]>([]);
  const [showNotesDialog, setShowNotesDialog] = useState(false);
  const [noteIndex, setNoteIndex] = useState<number>(0);
  const [noteText, setNoteText] = useState<string | null>(null);
  const [showMspnDialog, setShowMspnDialog] = useState(false);
  const [mspnIndex, setMspnIndex] = useState<any>(undefined);
  const [mspnPriority, setMspnPriority] = useState<any>(undefined);

  const { control, watch, getValues, setValue } = useFormContext<TireProfileOVM>();
  const { fields, append } = useFieldArray({
    control,
    name: `axles.all_axles.passenger_lt_priority`,
  });

  const watchValues = watch(`axles.all_axles.passenger_lt_priority`);

  const getPassengerLT = (index: number): PassengerLtPriority | null => {
    const axle = getAxle('all_axles', getValues());
    if (!axle) return null;
    return (axle.passenger_lt_priority || [])[index];
  };

  const getPriority = (index: number): TireProfilePriorities | null => {
    const axle = getAxle('all_axles', getValues());
    if (!axle) return null;
    return (axle.passenger_lt_priority || [])[index];
  };

  const addTirePriority = () => {
    const maxPriority = getValues().axles?.all_axles?.passenger_lt_priority?.length || 0;
    const newTireSize = {
      tire_size: '',
      priority: maxPriority + 1,
      brand: '',
      mspn: '',
      note: '',
      tread_design: '',
      speed_rating: '',
    };
    append(newTireSize);
  };

  const validateRemoveTireSize = (remove: Function) => {
    dialogs.confirmDialog(
      <DialogContent dividers>
        <div>
          <p>{t('Do you wish to proceed with removing the Passenger/LT')}</p>
        </div>
      </DialogContent>,
      t('Delete'),
      t('Yes'),
      t('Cancel'),
      () => {
        remove();
      },
    );
  };

  const deleteTireSize = (index: number) => {
    const auxAxle = cloneDeep(getAxle('all_axles', getValues()));
    if (!auxAxle) return;
    validateRemoveTireSize(() => {
      auxAxle.passenger_lt_priority?.splice(index, 1);
      if (auxAxle && auxAxle.passenger_lt_priority && auxAxle.passenger_lt_priority?.length > 1) {
        let auxPriority = 0;
        const newArray = auxAxle.passenger_lt_priority.map((item) => {
          auxPriority += 1;
          return { ...item, priority: auxPriority };
        });
        setValue(`axles.all_axles.passenger_lt_priority`, newArray, { shouldDirty: true });
      } else {
        setValue(`axles.all_axles.passenger_lt_priority`, auxAxle.passenger_lt_priority, { shouldDirty: true });
      }
    });
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;
    const indexBefore = result.source.index;
    const indexAfter = result.destination.index;

    const axle = getAxle('all_axles', getValues());
    const replacementPriority = cloneDeep(axle?.passenger_lt_priority || []);
    const itemToMoved = replacementPriority[indexBefore];

    if (indexAfter === replacementPriority.length - 1) return;
    if (indexAfter === 0) {
      if (!/michelin|bfgoodrich|uniroyal/gi.test(itemToMoved?.brand?.toString() || '')) {
        enqueueSnackbar(
          t("The brand {{brandName}} can't be in the priority {{priority}}", {
            brandName: itemToMoved?.brand?.toString(),
            priority: 1,
          }),
          {
            variant: 'error',
          },
        );
        return;
      }
    }
    if (indexBefore === 0) {
      if (!/michelin|bfgoodrich|uniroyal/gi.test(replacementPriority[1]?.brand?.toString() || '')) {
        enqueueSnackbar(
          t("The brand {{brandName}} can't be moved from priority {{priority}}", {
            brandName: itemToMoved?.brand?.toString(),
            priority: 1,
          }),
          {
            variant: 'error',
          },
        );
        return;
      }
    }
    const itemMoved = replacementPriority.splice(indexBefore, 1)[0];
    replacementPriority.splice(indexAfter, 0, itemMoved);
    let auxPriority = 0;
    const newReplacementPriority = replacementPriority.map((item) => {
      auxPriority += 1;
      return { ...item, priority: auxPriority };
    });
    setValue(`axles.all_axles.passenger_lt_priority`, newReplacementPriority, { shouldDirty: true });
  };

  const checkDrag = (item: PassengerLtPriority) => {
    const axle = getAxle('all_axles', getValues());
    if (!axle) return true;
    if (axle?.passenger_lt_priority && axle?.passenger_lt_priority?.length === 1) return true;
    if (!item.brand) return true;
    if (action === 'view') return true;
    return false;
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
    ...draggableStyle,
    ...(isDragging && {
      background: 'rgb(235,235,235)',
    }),
  });

  const checkRequired = (index: number, field: string, value: string | undefined | null): boolean => {
    const axle = getAxle('all_axles', getValues());
    if (!axle) return true;
    const newPriority = (axle.passenger_lt_priority || [])[index];

    if (field === 'tire_size' && newPriority.brand && !value) return false;
    if (field === 'tread_design' && newPriority.tire_size && !value) return false;
    if (field === 'speed_rating' && newPriority.tread_design && !value) return false;
    if (field === 'mspn' && newPriority.speed_rating && !value) return false;
    return true;
  };

  const getBrandOptions = (index: number): SelectOption[] => {
    if (brandOptions.length === 0) return [];
    return brandOptions
      .filter((x: SelectOption) => {
        if (index === 0) {
          return /michelin|bfgoodrich|uniroyal/i.test(x.value);
        }
        return true;
      })
      .sort(brandSort);
  };

  const getTireSizeOptions = (index: number): SelectOption[] => {
    if (!products || !watchValues) return [];
    const axle = getAxle('all_axles', getValues());
    const lt = axle?.passenger_lt_priority?.[index];
    if (!lt) return [];
    const { brand } = watchValues[index];
    if (!brand) return [];
    let tireSizeOptions: SelectOption[] = [];
    const brandFiltered = products.filter((prod) => prod.type === brand)[0];
    if (brandFiltered) {
      tireSizeOptions = brandFiltered.sizes.map((x) => ({ label: x.name || '', value: x.name || '' }));
      tireSizeOptions.sort((a: SelectOption, b: SelectOption) => {
        if (a.value.toUpperCase() < b.value.toUpperCase()) return -1;
        if (a.value.toUpperCase() > b.value.toUpperCase()) return 1;
        return 0;
      });
    }
    return tireSizeOptions;
  };
  const getTreadDesignOptions = (index: number): SelectOption[] => {
    if (!products || !watchValues) return [];
    const axle = getAxle('all_axles', getValues());
    const lt = axle?.passenger_lt_priority?.[index];
    if (!lt) return [];
    const { brand, tire_size } = watchValues[index];
    if (!brand || !tire_size) return [];
    let treadDesinnOptions: SelectOption[] = [];
    const brandFiltered = products.filter((prod) => prod.type === brand)[0];
    const tireSizeFiltered = brandFiltered.sizes.filter((size) => size.name === tire_size)[0];
    if (tireSizeFiltered && tireSizeFiltered.treads) {
      treadDesinnOptions = tireSizeFiltered?.treads?.map((x) => ({ label: x.name || '', value: x.name || '' }));
      treadDesinnOptions.sort((a: SelectOption, b: SelectOption) => {
        if (a.value.toUpperCase() < b.value.toUpperCase()) return -1;
        if (a.value.toUpperCase() > b.value.toUpperCase()) return 1;
        return 0;
      });
    }
    return treadDesinnOptions;
  };
  const getSpeedRatingOptions = (index: number): SelectOption[] => {
    if (!products || !watchValues) return [];
    const axle = getAxle('all_axles', getValues());
    const lt = axle?.passenger_lt_priority?.[index];
    if (!lt) return [];
    const { brand, tire_size, tread_design } = watchValues[index];
    if (!brand || !tire_size || !tread_design) return [];
    let speedRatingOptions: SelectOption[] = [];
    const brandFiltered = products.filter((prod) => prod.type === brand)[0];
    const tireSizeFiltered = brandFiltered.sizes.filter((size) => size.name === tire_size)[0];
    const treadFiltered = tireSizeFiltered?.treads?.filter((tread) => tread.name === tread_design)[0];

    if (treadFiltered && treadFiltered.speeds) {
      speedRatingOptions = treadFiltered?.speeds?.map((x) => ({ label: x.name || '', value: x.name || '' }));
      speedRatingOptions.sort((a: SelectOption, b: SelectOption) => {
        if (a.value.toUpperCase() < b.value.toUpperCase()) return -1;
        if (a.value.toUpperCase() > b.value.toUpperCase()) return 1;
        return 0;
      });
    }
    return speedRatingOptions;
  };

  const getMspnOptions = (index: number): SelectOption[] => {
    if (!products || !watchValues) return [];
    const axle = getAxle('all_axles', getValues());
    const lt = axle?.passenger_lt_priority?.[index];
    if (!lt) return [];
    const { brand, tire_size, tread_design, speed_rating } = watchValues[index];
    if (!brand || !tire_size || !tread_design || !speed_rating) return [];
    let mspnOptions: SelectOption[] = [];
    const brandFiltered = products.filter((prod) => prod.type === brand)[0];
    const tireSizeFiltered = brandFiltered.sizes.filter((size) => size.name === tire_size)[0];
    const treadFiltered = tireSizeFiltered?.treads?.filter((tread) => tread.name === tread_design)[0];
    const speedRatingFiltered = treadFiltered?.speeds?.filter((speed) => speed.name === speed_rating)[0];

    if (speedRatingFiltered && speedRatingFiltered.mspns) {
      mspnOptions = speedRatingFiltered?.mspns?.map((x) => ({ label: x || '', value: x || '' }));
      mspnOptions.sort((a: SelectOption, b: SelectOption) => {
        if (a.value.toUpperCase() < b.value.toUpperCase()) return -1;
        if (a.value.toUpperCase() > b.value.toUpperCase()) return 1;
        return 0;
      });
    }

    return mspnOptions;
  };

  const uniqueTireSizes = (index: number, value: string): boolean => {
    const axle = getAxle('all_axles', getValues());

    if (!axle) return true;
    const newPriority = (axle.passenger_lt_priority || [])[index];
    const priorities = axle.passenger_lt_priority || [];

    const duplicate = priorities.filter((priority) => {
      const check =
        priority.priority !== newPriority.priority &&
        priority.tire_size === newPriority.tire_size &&
        priority.brand === newPriority.brand &&
        priority.tread_design === newPriority.tread_design &&
        priority.speed_rating === value;
      return check;
    });
    if (duplicate.length > 0) return false;
    return true;
  };
  useEffect(() => {
    if (products.length === 0) return;
    const brands = products.map((x) => ({ label: x.type, value: x.type })).sort(brandSort);
    setBrandOptions(brands);
  }, [products]);
  return (
    <>
      <Panel
        title={t('Passenger/LT Tire Replacement Priority')}
        spacing={0}
        control={
          <span style={{ display: 'flex', alignContent: 'center' }}>
            <div style={{ display: 'flex', alignContent: 'center' }}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={addTirePriority}
                disabled={action === 'view'}
                style={{ padding: '1px 10px', margin: '5px' }}
              >
                {t('Add')}
              </Button>
            </div>
          </span>
        }
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={`passengerltdroppable`}>
            {(provided) => (
              <Table size="small" style={{ width: '100%' }} {...provided.droppableProps} ref={provided.innerRef}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" className={classes.header}>
                      {t('Priority')}
                    </TableCell>
                    <TableCell align="left" className={classes.header}>
                      {t('Brand')}
                    </TableCell>
                    <TableCell align="left" className={classes.header}>
                      {t('Tire Size')}
                    </TableCell>
                    <TableCell align="left" className={classes.header}>
                      {t('Tread Design')}
                    </TableCell>
                    <TableCell align="left" className={classes.header}>
                      {t('Speed Rating')}
                    </TableCell>
                    <TableCell align="left" className={classes.header}>
                      {t('MSPN')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields.map((item, index) => {
                    return (
                      <Draggable
                        key={item.id}
                        draggableId={String(item.id)}
                        index={index}
                        isDragDisabled={checkDrag(item)}
                      >
                        {(prov, theSnapshot) => (
                          <TableRow
                            ref={prov.innerRef}
                            {...prov.draggableProps}
                            {...prov.dragHandleProps}
                            style={getItemStyle(theSnapshot.isDragging, prov.draggableProps.style)}
                          >
                            <TableCell align="center" className={classes.tableCell}>
                              <Controller
                                name={`axles.all_axles.passenger_lt_priority.${index}.priority`}
                                control={control}
                                rules={{
                                  required: true,
                                }}
                                render={({ field }) => <span>{field.value}</span>}
                              />
                            </TableCell>
                            <TableCell align="left" className={classes.tableCell}>
                              <Controller
                                name={`axles.all_axles.passenger_lt_priority.${index}.brand`}
                                control={control}
                                rules={{
                                  required: true,
                                }}
                                render={({ field, fieldState }) => (
                                  <Select
                                    fullWidth
                                    required
                                    disableUnderline={!!!fieldState.error}
                                    value={field.value || ''}
                                    options={getBrandOptions(index) || []}
                                    onChange={(e: any) => {
                                      field.onChange(e.target.value);
                                      setValue(`axles.all_axles.passenger_lt_priority.${index}.tire_size`, '');
                                      setValue(`axles.all_axles.passenger_lt_priority.${index}.tread_design`, '');
                                      setValue(`axles.all_axles.passenger_lt_priority.${index}.speed_rating`, '');
                                      setValue(`axles.all_axles.passenger_lt_priority.${index}.mspn`, '');
                                    }}
                                    readOnly={action === 'view'}
                                    error={!!fieldState.error}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell align="left" className={classes.tableCell}>
                              <Controller
                                name={`axles.all_axles.passenger_lt_priority.${index}.tire_size`}
                                control={control}
                                rules={{
                                  validate: {
                                    required: (v) => checkRequired(index, 'tire_size', v),
                                  },
                                }}
                                render={({ field, fieldState }) => (
                                  <Select
                                    fullWidth
                                    required
                                    disableUnderline={!!!fieldState.error}
                                    value={field.value || ''}
                                    options={getTireSizeOptions(index) || []}
                                    onChange={(e: any) => {
                                      field.onChange(e.target.value);
                                      setValue(`axles.all_axles.passenger_lt_priority.${index}.tread_design`, '');
                                      setValue(`axles.all_axles.passenger_lt_priority.${index}.speed_rating`, '');
                                      setValue(`axles.all_axles.passenger_lt_priority.${index}.mspn`, '');
                                    }}
                                    readOnly={action === 'view'}
                                    error={!!fieldState.error}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell align="left" className={classes.tableCell}>
                              <Controller
                                name={`axles.all_axles.passenger_lt_priority.${index}.tread_design`}
                                control={control}
                                rules={{
                                  validate: {
                                    required: (v) => checkRequired(index, 'tread_design', v),
                                  },
                                }}
                                render={({ field, fieldState }) => (
                                  <Select
                                    fullWidth
                                    required
                                    disableUnderline={!!!fieldState.error}
                                    value={field.value || ''}
                                    options={getTreadDesignOptions(index) || []}
                                    onChange={(e: any) => {
                                      field.onChange(e.target.value);
                                      setValue(`axles.all_axles.passenger_lt_priority.${index}.speed_rating`, '');
                                      setValue(`axles.all_axles.passenger_lt_priority.${index}.mspn`, '');
                                    }}
                                    readOnly={action === 'view'}
                                    error={!!fieldState.error}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell align="left" className={classes.tableCell}>
                              <Controller
                                name={`axles.all_axles.passenger_lt_priority.${index}.speed_rating`}
                                control={control}
                                rules={{
                                  validate: {
                                    required: (v) => checkRequired(index, 'speed_rating', v),
                                  },
                                }}
                                render={({ field, fieldState }) => (
                                  <Select
                                    fullWidth
                                    required
                                    disableUnderline={!!!fieldState.error}
                                    value={field.value || ''}
                                    options={getSpeedRatingOptions(index) || []}
                                    onChange={(e: any) => {
                                      const isUniqueTire = uniqueTireSizes(index, e.target.value);
                                      if (!isUniqueTire) {
                                        enqueueSnackbar(t('That tire type already exists'), {
                                          variant: 'error',
                                        });
                                      } else {
                                        field.onChange(e.target.value);
                                        const passengerLT = getPassengerLT(index);
                                        if (passengerLT && products.length > 0) {
                                          const { brand, tire_size, tread_design, speed_rating } = passengerLT;
                                          const mspn = products
                                            ?.filter((x) => x.type === brand)[0]
                                            ?.sizes?.filter((x) => x.name === tire_size)[0]
                                            ?.treads?.filter((x) => x.name === tread_design)[0]
                                            ?.speeds?.filter((x) => x.name === speed_rating)[0]?.mspns;
                                          if (mspn && mspn.length === 1)
                                            setValue(`axles.all_axles.passenger_lt_priority.${index}.mspn`, mspn[0]);
                                          else setValue(`axles.all_axles.passenger_lt_priority.${index}.mspn`, '');
                                        }
                                      }
                                    }}
                                    readOnly={action === 'view'}
                                    error={!!fieldState.error}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell align="left" className={classes.tableCell}>
                              <Controller
                                name={`axles.all_axles.passenger_lt_priority.${index}.mspn`}
                                control={control}
                                rules={{
                                  validate: {
                                    required: (v) => checkRequired(index, 'mspn', v),
                                  },
                                }}
                                render={({ field, fieldState }) => {
                                  return (
                                    <>
                                      <Grid container spacing={0} direction="row" alignItems="flex-start">
                                        {action !== 'view' && (
                                          <Grid item>
                                            <IconButton
                                              color="primary"
                                              size="small"
                                              title={t('MSPN Search')}
                                              onClick={() => {
                                                const priority = getPriority(index);
                                                setShowMspnDialog(true);
                                                setMspnIndex(index);
                                                setMspnPriority(priority?.priority);
                                              }}
                                            >
                                              <SearchIcon fontSize="small" />
                                            </IconButton>
                                          </Grid>
                                        )}
                                        <Grid item>
                                          <Select
                                            fullWidth
                                            required
                                            disableUnderline={!!!fieldState.error}
                                            value={field.value || ''}
                                            options={getMspnOptions(index) || []}
                                            onChange={(e: any) => {
                                              field.onChange(e.target.value);
                                            }}
                                            readOnly={action === 'view'}
                                            error={!!fieldState.error}
                                          />
                                        </Grid>
                                      </Grid>
                                    </>
                                  );
                                }}
                              />
                            </TableCell>
                            <TableCell align="center" className={classes.tableCell}>
                              <IconButton
                                color="primary"
                                size="small"
                                onClick={() => {
                                  const priority = getPassengerLT(index);
                                  setNoteText(priority?.note || '');
                                  setNoteIndex(index);
                                  setShowNotesDialog(true);
                                }}
                              >
                                <Note fontSize="small" />
                              </IconButton>
                            </TableCell>
                            <TableCell align="center" className={classes.tableCell}>
                              <Controller
                                name={`axles.all_axles.passenger_lt_priority.${index}.priority`}
                                control={control}
                                render={({ field, fieldState }) => (
                                  <IconButton
                                    color="primary"
                                    size="small"
                                    disabled={
                                      action === 'view' ||
                                      (field.value === 1 &&
                                        (getAxle('all_axles', getValues())?.passenger_lt_priority?.length ?? 1) > 1)
                                    }
                                    onClick={() => {
                                      deleteTireSize(index);
                                    }}
                                  >
                                    <Delete fontSize="small" />
                                  </IconButton>
                                )}
                              />
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </Droppable>
        </DragDropContext>
      </Panel>
      <LTMspnSearch
        open={showMspnDialog}
        onClose={() => setShowMspnDialog(false)}
        mspnIndex={mspnIndex as number}
        mspnPriority={mspnPriority as number}
        products={products}
        brandsOptions={brandOptions}
        onSave={(row: PassengerLtPriority) => {
          setValue(`axles.all_axles.passenger_lt_priority.${mspnIndex}`, row, { shouldDirty: true });
          setShowMspnDialog(false);
        }}
      />
      <Notes
        open={showNotesDialog}
        close={() => setShowNotesDialog(false)}
        action={action}
        note={noteText}
        onSave={(value: string | null) => {
          setValue(`axles.all_axles.passenger_lt_priority.${noteIndex}.note`, value || '', { shouldDirty: true });
        }}
      />
    </>
  );
}
