import React, { useState } from 'react';
import { Contacts } from '@material-ui/icons';
import { ValueAvatar } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { PORequiredContactDialog } from 'components/Billing/Preferences/PurchasingProcedures/Dialogs/poRequiredContactDialog';
import { BillingPreferences, MethodOfSignedOrder } from 'components/Billing/Preferences/query';
import { Contact } from 'components/Contact/utils';
import { NewLocationAssignments } from 'components/NewLocationAssignments';
import { BillingProfileLocation } from '../types';

interface PoRequiredDRLogicProps {
  ers: boolean;
  editFlag: boolean;
  locations: BillingProfileLocation[];
  preferences: BillingPreferences;
  contacts: Array<Contact>;
  editProfile: (preferences: BillingPreferences) => void;
  setModifiedFlag: (modifiedFlag: boolean) => void;
  setLocations: (locations: BillingProfileLocation[]) => void;
}

type MethodToOpen = 'CONTACT' | 'LOCATION' | 'NONE';

function getMethodToOpen(ers: boolean, preferences: BillingPreferences): MethodToOpen {
  const { ers_purchasing_procedures, onsite_purchasing_procedures } = preferences;
  if (ers && ers_purchasing_procedures) {
    const { method_to_receive_signed_order } = ers_purchasing_procedures;
    if (
      method_to_receive_signed_order === MethodOfSignedOrder.email ||
      method_to_receive_signed_order === MethodOfSignedOrder.fax
    ) {
      return 'CONTACT';
    }
    if (method_to_receive_signed_order === MethodOfSignedOrder.mail) {
      return 'LOCATION';
    }
  } else if (onsite_purchasing_procedures) {
    const { method_to_receive_signed_order } = onsite_purchasing_procedures;
    if (
      method_to_receive_signed_order === MethodOfSignedOrder.email ||
      method_to_receive_signed_order === MethodOfSignedOrder.fax
    ) {
      return 'CONTACT';
    }
    if (method_to_receive_signed_order === MethodOfSignedOrder.mail) {
      return 'LOCATION';
    }
  }

  return 'NONE';
}

export function PoRequiredDRLogic(props: PoRequiredDRLogicProps) {
  const { locations, preferences, ers, editProfile, setModifiedFlag, contacts, setLocations, editFlag } = props;

  const [open, setOpen] = useState(false);
  const { ers_purchasing_procedures, onsite_purchasing_procedures } = preferences;
  const { t } = useTranslation();

  const purchasing_procedure = ers ? ers_purchasing_procedures : onsite_purchasing_procedures;

  const methodToOpen = getMethodToOpen(ers, preferences);

  const setLocationHandler = (locationDetails: BillingProfileLocation | undefined) => {
    const location: any = locationDetails || {};
    const {
      ers_purchasing_procedures: ersPurchasingProcedures,
      onsite_purchasing_procedures: onsitePurchasingProcedures,
    } = preferences;

    if (ers && ersPurchasingProcedures) {
      setModifiedFlag(true);
      editProfile({
        ...preferences,
        ers_purchasing_procedures: {
          ...ersPurchasingProcedures,
          signed_order_receiver_location: `1~${location.customer_number}`,
          receiver_location: {
            customer_name: location.customer_name || '',
            customer_city: location.customer_city || '',
            customer_state: location.customer_state || '',
            parent_company_number: location.parent_company_number || '',
            home_office_number: location.home_office_number || '',
            bill_to_customer: location.bill_to_customer || '',
            ship_to_customer: location.ship_to_customer || '',
            customer_type: location.customer_type || '',
            customer_addr1: location.customer_addr1 || '',
            customer_addr2: location.customer_addr2 || '',
            customer_zip: location.customer_zip || '',
          },
        },
      });
    } else if (onsitePurchasingProcedures) {
      setModifiedFlag(true);
      editProfile({
        ...preferences,
        onsite_purchasing_procedures: {
          ...onsitePurchasingProcedures,
          signed_order_receiver_location: `1~${location.customer_number}`,
          receiver_location: {
            customer_name: location.customer_name || '',
            customer_city: location.customer_city || '',
            customer_state: location.customer_state || '',
            parent_company_number: location.parent_company_number || '',
            home_office_number: location.home_office_number || '',
            bill_to_customer: location.bill_to_customer || '',
            ship_to_customer: location.ship_to_customer || '',
            customer_type: location.customer_type || '',
            customer_addr1: location.customer_addr1 || '',
            customer_addr2: location.customer_addr2 || '',
            customer_zip: location.customer_zip,
          },
        },
      });
    }
  };

  const setContactHandler = (contact: Contact | undefined) => {
    const {
      ers_purchasing_procedures: ersPurchasingProcedures,
      onsite_purchasing_procedures: onsitePurchasingProcedures,
    } = preferences;
    setModifiedFlag(true);
    const contactObj: Contact = contact || ({} as Contact);
    const contactHK = contact ? contact.hash_key : '';
    if (ers && ersPurchasingProcedures) {
      editProfile({
        ...preferences,
        ers_purchasing_procedures: {
          ...ersPurchasingProcedures,
          signed_order_receiver_contact: contactHK,
          receiver_contact: {
            email_address: contactObj.email_address || '',
            fax: contactObj.fax || '',
          },
        },
      });
    } else if (onsitePurchasingProcedures) {
      setModifiedFlag(true);
      editProfile({
        ...preferences,
        onsite_purchasing_procedures: {
          ...onsitePurchasingProcedures,
          signed_order_receiver_contact: contactHK,
          receiver_contact: {
            email_address: contactObj.email_address || '',
            fax: contactObj.fax || '',
          },
        },
      });
    }
  };

  if (!purchasing_procedure) return null;

  const {
    signed_order_receiver_location: location,
    signed_order_receiver_contact: contact,
    method_to_receive_signed_order,
  } = purchasing_procedure;
  const receiverLocation = location ? [{ hash_key: `${location}` }] : [];

  const filteredContactsByMethodAndFlag = contacts.filter((contactWithFaxOrEmail) => {
    if (editFlag === false && contactWithFaxOrEmail.hash_key !== contact) {
      return false;
    }
    if (method_to_receive_signed_order === MethodOfSignedOrder.email) {
      return contactWithFaxOrEmail.email_address;
    }
    if (method_to_receive_signed_order === MethodOfSignedOrder.fax) {
      return contactWithFaxOrEmail.fax;
    }
    return false;
  });

  return (
    <>
      {methodToOpen === 'LOCATION' && (
        <NewLocationAssignments
          owner={preferences.owner?.customer_number}
          displayCurrentLocation
          mode={editFlag ? 'edit' : 'view'}
          locations={receiverLocation}
          onUpdate={(newAssignment) => {
            if (!newAssignment || !newAssignment.length) return;
            const na = newAssignment[0];
            setLocationHandler(na as unknown as BillingProfileLocation);
          }}
          plainView
          disabled={!editFlag}
          hideCount
          singleSelection
          style={{ margin: '0 0 0 auto' }}
          all={false}
        />
      )}
      <PORequiredContactDialog
        locations={locations}
        contactAssigned={contact}
        contacts={filteredContactsByMethodAndFlag}
        setContact={setContactHandler}
        onCloseHandler={() => {
          setOpen(false);
        }}
        open={open && methodToOpen === 'CONTACT'}
        setLocations={setLocations}
        editFlag={editFlag}
      />
      <span hidden={methodToOpen !== 'CONTACT'}>
        <ValueAvatar
          color="primary"
          value=""
          onClick={() => setOpen(true)}
          icon={<Contacts />}
          tooltip={t('Receiver')}
        />
      </span>
    </>
  );
}
