import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Button, LoadingBounce } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { auth } from 'Auth';
import { DependencyConflict } from '../ContactDetails';
import { primaryDependenciesConflictsQuery } from '../ContactDetails/ContactTypes/dialogs/UnassignPriorityLevelsDialog';
import { LocatedMichelinOffer } from '../ContactDetails/ContactTypes/utils';
import { PRIMARY_LEVEL_VALUE, TIRE_SERVICE_VALUE } from '../ContactDetails/utils';
import { Contact } from '../utils';

interface DeactivateContactDialogProps {
  open: boolean;
  row: Contact | undefined;
  handleClose: (deactivate: boolean, reassign: boolean, secondaryConflicts?: Array<DependencyConflict>) => void;
  michelinOffersToDeactivate: Array<LocatedMichelinOffer>;
  primaryOrSecondaryLevelToDeactivate: boolean;
}

export default function DeactivateContactDialog(props: DeactivateContactDialogProps) {
  const { open, row, handleClose, michelinOffersToDeactivate, primaryOrSecondaryLevelToDeactivate } = props;
  const { t } = useTranslation();
  const dialogTitle = t('Delete a contact.');
  let dialogContent: JSX.Element = <div />;
  let dialogActions: Array<JSX.Element> = [];
  let maxWidth: false | 'lg' | 'xs' | 'sm' | 'md' | 'xl' | undefined = 'lg';

  const [{ loading, secondaryConflicts }, setState] = useState<{
    loading: boolean;
    secondaryConflicts: Array<DependencyConflict>;
    errors: any;
  }>({
    loading: true,
    secondaryConflicts: [],
    errors: undefined,
  });

  useEffect(() => {
    const asyncReq = async () => {
      setState({
        secondaryConflicts: [],
        errors: undefined,
        loading: true,
      });
      try {
        if (auth && auth.apolloClient) {
          const possiblePrimaryDependencyConflictList: Array<DependencyConflict> = [];
          if (row && row.contact_types) {
            row.contact_types.forEach((contactType) => {
              const { role_areas, service } = contactType;
              if (!role_areas || service !== TIRE_SERVICE_VALUE) return;
              role_areas.forEach((contactTypeRoleArea) => {
                const { levels, role_area } = contactTypeRoleArea;
                levels.forEach((contactTypeLevel) => {
                  const { level, location } = contactTypeLevel;
                  if (level === PRIMARY_LEVEL_VALUE) {
                    possiblePrimaryDependencyConflictList.push({
                      location_hash_key: location,
                      role_area,
                      service,
                    });
                  }
                });
              });
            });
          }

          const queryResSecondaryDependencies = await auth.apolloClient.query({
            query: primaryDependenciesConflictsQuery,
            variables: {
              dependencies: JSON.stringify(possiblePrimaryDependencyConflictList),
            },
            fetchPolicy: 'network-only',
          });

          const secConflicts: Array<DependencyConflict> = [];
          if (queryResSecondaryDependencies.data && queryResSecondaryDependencies.data.getSecondaryContactTypes) {
            queryResSecondaryDependencies.data.getSecondaryContactTypes.forEach(
              (x: { contactTypeDependencies: Array<DependencyConflict> }) =>
                x.contactTypeDependencies.forEach((secondaryDependency) => {
                  secConflicts.push(secondaryDependency);
                }),
            );
          }

          setState({
            secondaryConflicts: secConflicts,
            errors: queryResSecondaryDependencies.errors,
            loading: false,
          });
        }
      } catch (e) {
        setState({
          secondaryConflicts: [],
          errors: e,
          loading: false,
        });
      }
    };

    asyncReq();
  }, [row]);

  if (loading) {
    dialogContent = <LoadingBounce />;
  } else if (typeof row !== 'undefined') {
    if (michelinOffersToDeactivate.length > 0) {
      maxWidth = 'md';
      // It's possible to have multiple same offers to the same contact.
      const offersSet = new Set<string>();
      michelinOffersToDeactivate.forEach((x) => offersSet.add(x.michelinOffer.offer));
      const relevantOffers = Array.from(offersSet.values()).join(', ');
      dialogContent = (
        <div>
          <p>
            {t(
              '{{firstName}} {{lastName}} is assigned primary and/or secondary priority levels required for {{relevantOffers}} contact types ' +
                'at one or more locations that must be reassigned prior to deleting the contact.',
              { firstName: row.first_name, lastName: row.last_name, relevantOffers },
            )}
            <br />
            <br />
            {t(
              'Click yes to reassign priority levels to proceed with deactivating the contact or click no to cancel deactivating the contact.',
            )}
          </p>
        </div>
      );
      dialogActions = [
        <Button key="deactivateYesButton" onClick={() => handleClose(true, true)} color="primary">
          {t('Yes')}
        </Button>,
        <Button key="deactivateNoButton" onClick={() => handleClose(false, false)} color="primary">
          {t('No')}
        </Button>,
      ];
    } else if (secondaryConflicts.length > 0) {
      dialogContent = (
        <div>
          <p>
            {/* eslint-disable-next-line max-len */}
            {t(
              '{{firstName}} {{lastName}} is assigned primary and/or secondary priority levels required in other contact types at one or more locations that must be reassigned prior to deactivating the contact.',
              { firstName: row.first_name, lastName: row.last_name },
            )}
          </p>
        </div>
      );
      dialogActions = [
        <Button
          key="deactivateYesButton"
          onClick={() => handleClose(true, true, JSON.parse(JSON.stringify(secondaryConflicts)))}
          color="primary"
        >
          {t('Yes')}
        </Button>,
        <Button key="deactivateNoButton" onClick={() => handleClose(false, false)} color="primary">
          {t('No')}
        </Button>,
      ];
    } else if (primaryOrSecondaryLevelToDeactivate === true) {
      dialogContent = (
        <div>
          <p>
            {t(
              "Do you wish to reassign {{firstName}} {{lastName}}'s contact type priority level(s) to other contacts prior to deactivation?",
              { firstName: row.first_name, lastName: row.last_name },
            )}
          </p>
        </div>
      );
      dialogActions = [
        <Button key="deactivateYesButton" onClick={() => handleClose(true, true)} color="primary">
          {t('Yes')}
        </Button>,
        <Button key="deactivateNoButton" onClick={() => handleClose(true, false)} color="primary">
          {t('No')}
        </Button>,
      ];
    } else {
      handleClose(true, false);
    }
  }

  return (
    <Dialog
      maxWidth={maxWidth}
      open={open}
      onClose={() => handleClose(false, false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
      <DialogActions>{dialogActions}</DialogActions>
    </Dialog>
  );
}
