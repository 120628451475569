import React, { useEffect, useState } from 'react';
import { DialogContent, Grid, Table, TableBody, TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core';
import { Delete, Note } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import { Button, IconButton, Panel, Select, SelectOption, useDialogs } from '@michelin/acid-components';
import { useSnackbar, useTranslation } from '@michelin/central-provider';
import { ProductOVM, TireProfileOVM, TireProfilePriorities } from '@michelin/fcp-view-models';
import { getOptions } from '@michelin/select-options-provider';
import { cloneDeep, isEqual } from 'lodash';
import {
  doSizesEqual,
  doWeEqual,
  filterTheBrand,
  getAxle,
  getOtd32,
  updatePriorities,
} from 'modules/Tires/Details/utils';
import { SOPValidationResult, sopValidation } from 'modules/Tires/sopValidations';
import { Actions, AxleName } from 'modules/Tires/types';
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  DropResult,
  Droppable,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { MspnSearch } from '../../Dialogs/MspnSearch';
import { Notes } from '../../Dialogs/Notes';

const useStyles = makeStyles((theme: any) => ({
  header: {
    paddingRight: '3px',
    paddingLeft: '3px',
  },
  tableCell: {
    paddingRight: '3px',
    paddingLeft: '3px',
  },
}));

interface Props {
  tireSize: string;
  axleName: AxleName;
  products: ProductOVM[];
  action: Actions;
  axleTireSizeReplaData: { data: TireProfilePriorities[]; first: number; items: number };
  otherEqualSize: string | undefined;
  addPriority: () => void;
  lastPriority: boolean;
}

const PriorityTable = (props: Props) => {
  const { axleName, tireSize, action, products, axleTireSizeReplaData, otherEqualSize, addPriority, lastPriority } =
    props;
  const { t } = useTranslation();
  const dialogs = useDialogs();
  const classes = useStyles(props);
  const { enqueueSnackbar } = useSnackbar();

  const [showNotesDialog, setShowNotesDialog] = useState(false);
  const [noteIndex, setNoteIndex] = useState<number>(0);
  const [noteText, setNoteText] = useState<string | null>(null);
  const [showMspnDialog, setShowMspnDialog] = useState(false);
  const [mspnIndex, setMspnIndex] = useState<any>(undefined);
  const [mspnPriority, setMspnPriority] = useState<any>(undefined);

  const [tireTypeOptions, setTireTypeOptions] = useState<SelectOption[]>([]);
  const [brandNewOptions, setBrandNewOptions] = useState<SelectOption[]>([]);
  const [brandRetOptions, setBrandRetOptions] = useState<SelectOption[]>([]);

  const equalSizes = getOptions('equal_sizes').map((option: SelectOption) => option.value);

  const { control, watch, getValues, setValue } = useFormContext<TireProfileOVM>();
  const { append } = useFieldArray({
    control,
    name: `axles.${axleName}.tire_replacement_priority`,
  });
  const isOnSite = /onsite/gi.test(getValues().main?.profile_type || '');

  const watchValues = watch(`axles.${axleName}.tire_replacement_priority`);

  useEffect(() => {
    if (!products) return;

    const ttOpt = getTypesBySize(products);
    const brandOptions = getBrandOptions(products);

    if (!ttOpt) return;
    setTireTypeOptions(ttOpt);
    setBrandNewOptions(brandOptions.new);
    setBrandRetOptions(brandOptions.retread);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterBrandsByType = (tireType: 'New' | 'Retread', products: ProductOVM[]) => {
    return products
      .filter((product) => {
        if (!product || !product.sizes) return false;
        let validSizeAlert = false;

        if (/new/i.test(tireType) || (product.isMichelin && /tread/i.test(tireType))) {
          product.sizes.forEach((z) => {
            if (doSizesEqual(z.name || '', tireSize, equalSizes)) {
              validSizeAlert = true;
            }
          });
          if (!validSizeAlert) return false;
        }

        return isEqual(product.type || '', tireType);
      })
      .map((p) => ({ label: p.name || '', value: p.name || '' }))
      .sort((a: SelectOption, b: SelectOption) => {
        if (isEqual(a.label.toUpperCase(), 'MICHELIN') || isEqual(a.label.toUpperCase(), 'MRT')) return -3;
        if (isEqual(b.label.toUpperCase(), 'MICHELIN') || isEqual(b.label.toUpperCase(), 'MRT')) return 3;
        if (isEqual(a.label.toUpperCase(), 'BFGOODRICH') || isEqual(a.label.toUpperCase(), 'BFGOODRICH')) return -2;
        if (isEqual(b.label.toUpperCase(), 'BFGOODRICH') || isEqual(b.label.toUpperCase(), 'BFGOODRICH')) return 2;
        if (/uniroyal/i.test(a.label)) return -1;
        if (/uniroyal/i.test(b.label)) return 1;
        if (/oliver/i.test(a.label)) return -1;
        if (/oliver/i.test(b.label)) return 1;
        if (/mega/i.test(a.label)) return -1;
        if (/mega/i.test(b.label)) return 1;
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });
  };

  const getTypesBySize = (products: ProductOVM[]): SelectOption[] | null => {
    const productsData: ProductOVM[] = [];
    const equalSizes = getOptions('equal_sizes').map((option: SelectOption) => option.value);
    const hasMichelin: { type: string; count: number }[] = [];

    if (!products || !tireSize) return null;

    products
      .filter((prod) => prod.type === (axleName === 'steer' ? 'New' : prod.type))
      .forEach((product) => {
        let pass = false;

        if (!/^new~/i.test(product.type || '') && !product.isMichelin) {
          pass = true;
        }

        product.sizes?.forEach((size) => {
          if (!size.name) return;
          if (tireSize === size.name || doWeEqual(tireSize, size.name, equalSizes)) {
            pass = true;
            return;
          }
        });

        if (pass && product.name) {
          productsData.push({ ...product });

          // Store if one of the items is a Michelin brand.
          if (product.isMichelin) {
            const index = hasMichelin.findIndex((mich) => mich.type === product.type);
            if (index > -1) hasMichelin[index].count += 1;
            else hasMichelin.push({ type: product.type || '', count: 1 });
          }
        }
      });

    // If no mich brand is present, we remove the option.
    return hasMichelin.map((item) => ({ value: item.type, label: item.type }));
  };

  const getBrandOptions = (products: ProductOVM[]): { new: SelectOption[]; retread: SelectOption[] } => {
    if (!products || !tireSize) return { new: [], retread: [] };

    return {
      new: filterBrandsByType('New', products),
      retread: filterBrandsByType('Retread', products),
    };
  };

  const getBrands = (index: number): SelectOption[] => {
    if (!watchValues) return [];

    const { tire_type, priority } = watchValues[index];

    if (tire_type === 'New') return filterTheBrand(brandNewOptions, isOnSite, priority);
    if (tire_type === 'Retread') return filterTheBrand(brandRetOptions, isOnSite, priority);

    return [];
  };

  const getTireSize = (index: number): SelectOption[] => {
    if (!products || !watchValues) return [];
    const { tire_type, brand } = watchValues[index];
    const tireSizeOptions: SelectOption[] = [];

    if (!tire_type || !brand) return [];

    const product = products.filter((prod) => (prod.name || '') === brand && (prod.type || '') === tire_type)[0];

    if (!product) return [];

    product.sizes
      ?.filter((size) => size.name === tireSize || (otherEqualSize && size.name === otherEqualSize))
      .forEach((size) => {
        tireSizeOptions.push({ label: size.name || '', value: size.name || '' });
      });

    return tireSizeOptions;
  };
  const getTreads = (index: number): SelectOption[] => {
    const axle = getAxle(axleName, getValues());
    if (!products || axle === null || axle === undefined || !axle.tire_replacement_priority) return [];

    const { tire_type, brand, tire_size } = axle.tire_replacement_priority?.[index];
    if (!tire_type || !brand) return [];
    const treadsOptions: SelectOption[] = [];

    if (!tire_type || !brand) return [];

    const product = products.filter((prod) => (prod.name || '') === brand && (prod.type || '') === tire_type)[0];
    if (!product) return [];

    // If the only size is none, we get the info from unique treads
    if (product.sizes?.length === 1 && product.sizes[0].name === 'None') {
      if (product && product.uniqueTreads)
        product.uniqueTreads.forEach((tread) => treadsOptions.push({ label: tread || '', value: tread || '' }));
    } else {
      const prod = product.sizes?.filter((size) => size.name === tire_size)[0];
      if (prod)
        prod.treads?.forEach((tread) => {
          treadsOptions.push({ label: tread.name || '', value: tread.name || '' });
        });
    }

    treadsOptions.push({ label: 'No Preference', value: 'No Preference' });
    return treadsOptions;
  };

  const getLoad = (index: number): SelectOption[] => {
    const axle = getAxle(axleName, getValues());
    if (!products || axle === null || axle === undefined || !axle.tire_replacement_priority) return [];

    const { tire_type, brand, tread_design, tire_size } = axle.tire_replacement_priority?.[index];
    if (!tire_type || !brand || !tread_design) return [];
    const loadOptions: SelectOption[] = [];
    if (!tire_type || !brand) return [];

    const product = products.filter((prod) => (prod.name || '') === brand && (prod.type || '') === tire_type)[0];

    if (!product) return [];

    // If none is not the only size, we can try to get the teads from the size property.
    if (!(product.sizes?.length === 1 && product.sizes[0].name === 'None')) {
      const treads = product.sizes?.filter((size) => size.name === tire_size)[0];
      const tread = treads ? treads.treads?.filter((tread) => tread.name === tread_design)[0] : undefined;
      if (tread && tread.loads) {
        tread.loads
          ?.filter((load) => load.load)
          .forEach((load) => loadOptions.push({ label: load.load || '', value: load.load || '' }));
      }
    }

    return loadOptions;
  };

  const getPriority = (index: number): TireProfilePriorities | null => {
    const axle = getAxle(axleName, getValues());
    if (!axle) return null;
    return (axle.tire_replacement_priority || [])[index];
  };

  const validateRemoveTireSize = (
    remove: Function,
    priority: 'all' | number,
    sopValidationResult?: SOPValidationResult,
  ) => {
    if (!sopValidationResult && priority === 'all') return;

    let sopMsg: string = '';
    let confMsg: string = '';
    let title: string = '';
    if (sopValidationResult && priority === 'all') {
      if (!sopValidationResult.valid && sopValidationResult.sopName && sopValidationResult.sopName.length > 0) {
        sopMsg = t(
          'Removing tire size {{TireSize}} will cause its related SOP Profile titled {{SOPName}} to be invalid.  An SOP Profile must contain at least one SOP preference within any one of the following SOP Profile pages:  Inflation Pressures, Tread Depth Pull Points, Wheel Balancing.',
          { TireSize: tireSize, SOPName: sopValidationResult.sopName },
        );
      }
      confMsg = t('Do you wish to proceed with removing the tire size {{TireSize}}', {
        TireSize: tireSize,
      });
      title = t('Delete Tire Size');
    }

    if (priority !== 'all') {
      confMsg = t('Do you wish to proceed with removing priority {{Priority}} on tire size {{TireSize}}', {
        Priority: priority,
        TireSize: tireSize,
      });
      title = t('Delete Tire Size Priority');
    }

    dialogs.confirmDialog(
      <DialogContent dividers>
        <div>
          <p>{sopMsg}</p>
          <p>{confMsg}</p>
        </div>
      </DialogContent>,
      title,
      t('Yes'),
      t('Cancel'),
      () => {
        remove();
      },
    );
  };

  const addTireSize = () => {
    const maxPriority = Math.max(
      ...(watchValues ?? [])
        .filter((i) => i.tire_size === tireSize || i.tire_size === otherEqualSize)
        .map((o) => o.priority || 0),
    );

    const newTireSize = {
      tire_type: axleName === 'steer' ? 'New' : '',
      tire_size: tireSize,
      priority: maxPriority + 1,
      brand: '',
      load_range: null,
      mspn: null,
      note: null,
      original_tire_depth: null,
      tread_design: null,
      width: null,
    };

    append(newTireSize);
  };

  const deleteTireSize = (index: number, priority: number) => {
    const auxAxle = cloneDeep(getAxle(axleName, getValues()));
    if (!auxAxle) return;
    auxAxle.tire_replacement_priority?.splice(index, 1);
    validateRemoveTireSize(() => {
      const newReplacementPriority = updatePriorities(
        auxAxle.tire_replacement_priority || [],
        tireSize,
        otherEqualSize,
      );
      setValue(`axles.${axleName}.tire_replacement_priority`, newReplacementPriority, { shouldDirty: true });
    }, priority);
  };

  const deleteAllTireSizes = async () => {
    const sopValidationResult: SOPValidationResult = await sopValidation('tire_size', {
      id: getValues().id || '',
      axleTypes: [axleName],
      tireSize: encodeURIComponent(tireSize),
    });
    validateRemoveTireSize(
      () => {
        const filteredReplacementPriority = (watchValues ?? []).filter(
          (item) => item.tire_size !== tireSize && item.tire_size !== otherEqualSize,
        );
        setValue(`axles.${axleName}.tire_replacement_priority`, filteredReplacementPriority, { shouldDirty: true });
      },
      'all',
      sopValidationResult,
    );
  };

  const checkUnique = (index: number, field: 'treadDesign' | 'loadRage', value: string): boolean => {
    const axle = getAxle(axleName, getValues());

    if (!axle) return true;
    const newPriority = (axle.tire_replacement_priority || [])[index];
    const priorities = axle.tire_replacement_priority || [];
    const tread_design = field === 'treadDesign' ? value : newPriority.tread_design;
    const load_range = field === 'loadRage' ? value : newPriority.load_range;
    const duplicate = priorities.filter((priority) => {
      const check =
        priority.priority !== newPriority.priority &&
        priority.tire_size === newPriority.tire_size &&
        priority.tire_type === newPriority.tire_type &&
        priority.brand === newPriority.brand &&
        priority.tread_design === tread_design;

      if (field === 'treadDesign') return check;
      if (field === 'loadRage') return check && priority.load_range === load_range;
      return false;
    });
    if (duplicate.length > 0) return false;
    return true;
  };

  const checkRequired = (index: number, field: string, value: string | undefined | null): boolean => {
    const axle = getAxle(axleName, getValues());
    if (!axle) return true;
    const newPriority = (axle.tire_replacement_priority || [])[index];

    if (field === 'brand' && newPriority.tire_type && !value) return false;
    if (field === 'tread_design' && newPriority.brand && !value) return false;
    if (field === 'load_range' && newPriority.tread_design && !value && getLoad(index).length > 0) return false;

    return true;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const indexBefore = result.source.index;
    const indexAfter = result.destination.index;

    const axle = getAxle(axleName, getValues());
    const replacementPriority = cloneDeep(axle?.tire_replacement_priority || []);
    const itemToMoved = replacementPriority[indexBefore];

    if (indexAfter === axleTireSizeReplaData.items - 1) return;
    if (indexAfter === 0) {
      const brands = filterTheBrand(
        [{ value: itemToMoved?.brand?.toString() || '', label: itemToMoved?.brand?.toString() || '' }],
        isOnSite,
        1,
      );
      if (brands.length === 0) {
        enqueueSnackbar(
          t("The brand {{brandName}} can't be in the priority {{priority}}", {
            brandName: itemToMoved?.brand?.toString(),
            priority: 1,
          }),
          {
            variant: 'error',
          },
        );
        return;
      }
    }
    if (indexBefore === axleTireSizeReplaData.first) {
      const filterByTireSize = replacementPriority.filter(
        (value) => value.tire_size === tireSize || value.tire_size === otherEqualSize,
      );
      const brands = filterTheBrand(
        [{ value: filterByTireSize[1]?.brand?.toString() || '', label: filterByTireSize[1]?.brand?.toString() || '' }],
        isOnSite,
        1,
      );
      if (brands.length === 0) {
        enqueueSnackbar(
          t("The brand {{brandName}} can't be moved from priority {{priority}}", {
            brandName: itemToMoved?.brand?.toString(),
            priority: 1,
          }),
          {
            variant: 'error',
          },
        );
        return;
      }
    }
    const indexs = replacementPriority.reduce((acc: number[], item, index) => {
      if (item.tire_size === tireSize || otherEqualSize === item.tire_size) {
        acc.push(index);
      }
      return acc;
    }, []);
    const itemMoved = replacementPriority.splice(indexBefore, 1)[0];
    replacementPriority.splice(indexs[indexAfter], 0, itemMoved);
    const newReplacementPriority = updatePriorities(replacementPriority, tireSize, otherEqualSize);
    setValue(`axles.${axleName}.tire_replacement_priority`, newReplacementPriority, { shouldDirty: true });
  };

  const checkDrag = (item: TireProfilePriorities) => {
    let ret = false;

    if (
      ((!item.tire_type || !item.brand || !item.tread_design) &&
        (!item.brand || !item.tire_size || !item.tread_design)) ||
      action === 'view' ||
      axleTireSizeReplaData.data.length === 1
    )
      ret = true;

    return ret;
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
    ...draggableStyle,
    ...(isDragging && {
      background: 'rgb(235,235,235)',
    }),
  });

  return (
    <>
      <Panel
        title={`${t('Tire Replacement Priority')} - ${tireSize}`}
        spacing={0}
        control={
          <span style={{ display: 'flex', alignContent: 'center' }}>
            <div style={{ display: 'flex', alignContent: 'center' }}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={addTireSize}
                disabled={action === 'view'}
                style={{ padding: '1px 10px', margin: '5px' }}
              >
                {t('Add')}
              </Button>
            </div>

            <div>
              <Button
                variant="contained"
                size="small"
                color="primary"
                onClick={deleteAllTireSizes}
                disabled={action === 'view'}
                style={{ padding: '1px 10px', margin: '5px' }}
              >
                {t('Remove')}
              </Button>
            </div>
            {lastPriority && (
              <div>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => addPriority()}
                  disabled={action === 'view' || !lastPriority}
                  style={{ padding: '1px 10px', margin: '5px' }}
                >
                  {t('Add New Tire Size')}
                </Button>
              </div>
            )}
          </span>
        }
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={`${axleName}${tireSize}droppable`}>
            {(provided) => (
              <Table size="small" style={{ width: '100%' }} {...provided.droppableProps} ref={provided.innerRef}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" className={classes.header}>
                      {t('Priority')}
                    </TableCell>
                    <TableCell align="left" className={classes.header}>
                      {t('Tire Type')}
                    </TableCell>
                    <TableCell align="left" className={classes.header}>
                      {t('Brand')}
                    </TableCell>
                    {otherEqualSize && (
                      <TableCell align="left" className={classes.header}>
                        {t('Tire Size')}
                      </TableCell>
                    )}
                    <TableCell align="left" className={classes.header}>
                      {t('Tread Design')}
                    </TableCell>
                    <TableCell align="left" className={classes.header}>
                      {t('Load Range')}
                    </TableCell>
                    <TableCell align="left" className={classes.header}>
                      {t('Width')}
                    </TableCell>
                    <TableCell align="left" className={classes.header}>
                      {t('Original Tire Depth')}
                    </TableCell>
                    <TableCell align="left" className={classes.header}>
                      {t('MSPN')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(watchValues ?? []).map((item, index) => {
                    if (!(item.tire_size === tireSize || item.tire_size === otherEqualSize)) return null;
                    return (
                      <Draggable
                        key={`${index}-${item.tire_size}-${item.priority}-${item.brand}-${item.tire_type}-${item.tread_design}`}
                        draggableId={String(
                          `${index}-${item.tire_size}-${item.priority}-${item.brand}-${item.tire_type}-${item.tread_design}`,
                        )}
                        index={index}
                        isDragDisabled={checkDrag(item)}
                      >
                        {(prov, theSnapshot) => (
                          <TableRow
                            ref={prov.innerRef}
                            {...prov.draggableProps}
                            {...prov.dragHandleProps}
                            style={getItemStyle(theSnapshot.isDragging, prov.draggableProps.style)}
                          >
                            <TableCell align="center" className={classes.tableCell}>
                              <Controller
                                name={`axles.${axleName}.tire_replacement_priority.${index}.priority`}
                                control={control}
                                rules={{
                                  required: true,
                                }}
                                render={({ field }) => <span>{field.value}</span>}
                              />
                            </TableCell>
                            <TableCell align="left" className={classes.tableCell}>
                              <Controller
                                name={`axles.${axleName}.tire_replacement_priority.${index}.tire_type`}
                                control={control}
                                rules={{
                                  required: true,
                                }}
                                render={({ field, fieldState }) => (
                                  <Select
                                    fullWidth
                                    required
                                    value={field.value || ''}
                                    options={tireTypeOptions}
                                    onChange={(e: any) => {
                                      field.onChange(e.target.value);
                                      setValue(`axles.${axleName}.tire_replacement_priority.${index}.brand`, '');
                                      setValue(`axles.${axleName}.tire_replacement_priority.${index}.tread_design`, '');
                                      setValue(`axles.${axleName}.tire_replacement_priority.${index}.load_range`, '');
                                      setValue(`axles.${axleName}.tire_replacement_priority.${index}.width`, '');
                                      setValue(
                                        `axles.${axleName}.tire_replacement_priority.${index}.original_tire_depth`,
                                        '',
                                      );
                                      setValue(`axles.${axleName}.tire_replacement_priority.${index}.mspn`, '');
                                    }}
                                    readOnly={action === 'view' || axleName === 'steer'}
                                    error={!!fieldState.error}
                                    disableUnderline={!!!fieldState.error}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell align="left" className={classes.tableCell}>
                              <Controller
                                name={`axles.${axleName}.tire_replacement_priority.${index}.brand`}
                                control={control}
                                rules={{
                                  validate: {
                                    required: (v) => checkRequired(index, 'brand', v),
                                  },
                                }}
                                render={({ field, fieldState }) => (
                                  <Select
                                    fullWidth
                                    required
                                    disableUnderline={!!!fieldState.error}
                                    value={field.value || ''}
                                    options={getBrands(index)}
                                    onChange={(e: any) => {
                                      field.onChange(e.target.value);
                                      setValue(`axles.${axleName}.tire_replacement_priority.${index}.tread_design`, '');
                                      setValue(`axles.${axleName}.tire_replacement_priority.${index}.load_range`, '');
                                      setValue(`axles.${axleName}.tire_replacement_priority.${index}.width`, '');
                                      setValue(
                                        `axles.${axleName}.tire_replacement_priority.${index}.original_tire_depth`,
                                        '',
                                      );
                                      setValue(`axles.${axleName}.tire_replacement_priority.${index}.mspn`, '');
                                      const tireSizelist = getTireSize(index);
                                      if (tireSizelist.length === 1 && otherEqualSize) {
                                        setValue(
                                          `axles.${axleName}.tire_replacement_priority.${index}.tire_size`,
                                          `${tireSizelist[0].value}`,
                                        );
                                      }
                                    }}
                                    readOnly={action === 'view'}
                                    error={!!fieldState.error}
                                  />
                                )}
                              />
                            </TableCell>
                            {otherEqualSize && (
                              <TableCell align="left" className={classes.tableCell}>
                                <Controller
                                  name={`axles.${axleName}.tire_replacement_priority.${index}.tire_size`}
                                  control={control}
                                  rules={{
                                    required: true,
                                  }}
                                  render={({ field, fieldState }) => (
                                    <Select
                                      fullWidth
                                      required
                                      disableUnderline={!!!fieldState.error}
                                      value={field.value || ''}
                                      options={getTireSize(index) || []}
                                      onChange={(e: any) => {
                                        field.onChange(e.target.value);
                                        setValue(
                                          `axles.${axleName}.tire_replacement_priority.${index}.tread_design`,
                                          '',
                                        );
                                        setValue(`axles.${axleName}.tire_replacement_priority.${index}.load_range`, '');
                                        setValue(`axles.${axleName}.tire_replacement_priority.${index}.width`, '');
                                        setValue(
                                          `axles.${axleName}.tire_replacement_priority.${index}.original_tire_depth`,
                                          '',
                                        );
                                        setValue(`axles.${axleName}.tire_replacement_priority.${index}.mspn`, '');
                                      }}
                                      readOnly={action === 'view'}
                                      error={!!fieldState.error}
                                    />
                                  )}
                                />
                              </TableCell>
                            )}

                            <TableCell align="left" className={classes.tableCell}>
                              <Controller
                                name={`axles.${axleName}.tire_replacement_priority.${index}.tread_design`}
                                control={control}
                                rules={{
                                  validate: {
                                    required: (v) => checkRequired(index, 'tread_design', v),
                                  },
                                }}
                                render={({ field, fieldState }) => (
                                  <Select
                                    fullWidth
                                    required
                                    disableUnderline={!!!fieldState.error}
                                    value={field.value || ''}
                                    options={getTreads(index) || []}
                                    onChange={(e: any) => {
                                      const priority = getPriority(index);
                                      const updateOthers = (load: string, width: string, otd: string, mspn: string) => {
                                        setValue(
                                          `axles.${axleName}.tire_replacement_priority.${index}.load_range`,
                                          load,
                                        );
                                        setValue(`axles.${axleName}.tire_replacement_priority.${index}.width`, width);
                                        setValue(
                                          `axles.${axleName}.tire_replacement_priority.${index}.original_tire_depth`,
                                          getOtd32(otd),
                                        );
                                        setValue(`axles.${axleName}.tire_replacement_priority.${index}.mspn`, mspn);
                                      };
                                      if (priority) {
                                        const tread = products
                                          .find((pro) => pro.name === priority.brand && pro.type === priority.tire_type)
                                          ?.sizes?.find((size) => size.name === priority.tire_size)
                                          ?.treads?.find((tread) => tread.name === e.target.value);
                                        let isUnique = true;
                                        if (!tread?.loads || (tread && tread.loads?.length === 1)) {
                                          isUnique = checkUnique(index, 'treadDesign', e.target.value);
                                        }
                                        if (isUnique) {
                                          if (tread && tread.loads?.length === 1) {
                                            updateOthers(
                                              tread.loads[0].load || '',
                                              tread.loads[0].width || '',
                                              tread.loads[0].otd || '',
                                              tread.loads[0].mspn || '',
                                            );
                                          } else {
                                            updateOthers('', '', '', '');
                                          }
                                          field.onChange(e.target.value);
                                        } else {
                                          enqueueSnackbar(t('That tire type already exists'), {
                                            variant: 'error',
                                          });
                                        }
                                        if (tread && tread.loads && tread.loads?.length > 2) {
                                          updateOthers('', '', '', '');
                                        }
                                      }
                                    }}
                                    readOnly={action === 'view'}
                                    error={!!fieldState.error}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell align="left" className={classes.tableCell}>
                              <Controller
                                name={`axles.${axleName}.tire_replacement_priority.${index}.load_range`}
                                control={control}
                                rules={{
                                  validate: {
                                    required: (v) => checkRequired(index, 'load_range', v),
                                  },
                                }}
                                render={({ field, fieldState }) => (
                                  <Select
                                    fullWidth
                                    required
                                    disableUnderline={!!!fieldState.error}
                                    value={field.value || ''}
                                    options={getLoad(index) || []}
                                    onChange={(e: any) => {
                                      const priority = getPriority(index);
                                      if (priority) {
                                        const load = products
                                          .find((pro) => pro.name === priority.brand && pro.type === priority.tire_type)
                                          ?.sizes?.find((size) => size.name === priority.tire_size)
                                          ?.treads?.find((tread) => tread.name === priority.tread_design)
                                          ?.loads?.find((load) => load.load === e.target.value);
                                        const isUnique = checkUnique(index, 'loadRage', e.target.value);
                                        if (isUnique) {
                                          setValue(
                                            `axles.${axleName}.tire_replacement_priority.${index}.width`,
                                            load?.width || '',
                                          );
                                          setValue(
                                            `axles.${axleName}.tire_replacement_priority.${index}.original_tire_depth`,
                                            getOtd32(load?.otd || ''),
                                          );
                                          setValue(
                                            `axles.${axleName}.tire_replacement_priority.${index}.mspn`,
                                            load?.mspn || '',
                                          );
                                          field.onChange(e.target.value);
                                        } else {
                                          enqueueSnackbar(t('That tire type already exists'), {
                                            variant: 'error',
                                          });
                                        }
                                      }
                                    }}
                                    readOnly={action === 'view' || getLoad(index).length === 0}
                                    error={!!fieldState.error}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell align="left" className={classes.tableCell}>
                              <Controller
                                name={`axles.${axleName}.tire_replacement_priority.${index}.width`}
                                control={control}
                                render={({ field, fieldState }) => (
                                  <Select
                                    fullWidth
                                    disableUnderline={true}
                                    value={field.value || ''}
                                    options={[{ value: field.value || '', label: field.value || '' }]}
                                    readOnly={true}
                                  />
                                )}
                              />
                            </TableCell>
                            <TableCell align="left" className={classes.tableCell}>
                              <Controller
                                name={`axles.${axleName}.tire_replacement_priority.${index}.original_tire_depth`}
                                control={control}
                                render={({ field }) => {
                                  const otd = getOtd32(field.value || '');
                                  return (
                                    <Select
                                      fullWidth
                                      disableUnderline={true}
                                      value={otd}
                                      options={[{ value: otd, label: otd }]}
                                      readOnly={true}
                                    />
                                  );
                                }}
                              />
                            </TableCell>
                            <TableCell align="left" className={classes.tableCell}>
                              <Controller
                                name={`axles.${axleName}.tire_replacement_priority.${index}.mspn`}
                                control={control}
                                render={({ field }) => {
                                  return (
                                    <>
                                      <Grid container spacing={0} direction="row" alignItems="flex-start">
                                        {action !== 'view' && (
                                          <Grid item>
                                            <IconButton
                                              color="primary"
                                              size="small"
                                              title={t('MSPN Search')}
                                              onClick={() => {
                                                const priority = getPriority(index);
                                                setShowMspnDialog(true);
                                                setMspnIndex(index);
                                                setMspnPriority(priority?.priority);
                                              }}
                                            >
                                              <SearchIcon fontSize="small" />
                                            </IconButton>
                                          </Grid>
                                        )}
                                        <Grid item>
                                          <Select
                                            fullWidth
                                            disableUnderline={true}
                                            value={field.value || ''}
                                            options={[{ value: field.value || '', label: field.value || '' }]}
                                            readOnly={true}
                                          />
                                        </Grid>
                                      </Grid>
                                    </>
                                  );
                                }}
                              />
                            </TableCell>
                            <TableCell align="center" className={classes.tableCell}>
                              <IconButton
                                color="primary"
                                size="small"
                                onClick={() => {
                                  const priority = getPriority(index);
                                  setNoteText(priority?.note || '');
                                  setNoteIndex(index);
                                  setShowNotesDialog(true);
                                }}
                              >
                                <Note fontSize="small" />
                              </IconButton>
                            </TableCell>
                            <TableCell align="center" className={classes.tableCell}>
                              <Controller
                                name={`axles.${axleName}.tire_replacement_priority.${index}.priority`}
                                control={control}
                                render={({ field }) => (
                                  <IconButton
                                    color="primary"
                                    size="small"
                                    disabled={action === 'view' || field.value === 1}
                                    onClick={() => {
                                      deleteTireSize(index, getPriority(index)?.priority!);
                                    }}
                                  >
                                    <Delete fontSize="small" />
                                  </IconButton>
                                )}
                              />
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </Droppable>
        </DragDropContext>
      </Panel>

      <MspnSearch
        open={showMspnDialog}
        onClose={() => setShowMspnDialog(false)}
        axleName={axleName}
        tireSize={tireSize}
        mspnIndex={mspnIndex as number}
        mspnPriority={mspnPriority as number}
        products={products}
        onSave={(row: TireProfilePriorities) => {
          setValue(`axles.${axleName}.tire_replacement_priority.${mspnIndex}`, row, { shouldDirty: true });
          setShowMspnDialog(false);
        }}
      />
      <Notes
        open={showNotesDialog}
        close={() => setShowNotesDialog(false)}
        action={action}
        note={noteText}
        onSave={(value: string | null) => {
          setValue(`axles.${axleName}.tire_replacement_priority.${noteIndex}.note`, value || '', { shouldDirty: true });
        }}
      />
    </>
  );
};

export { PriorityTable };
