/* eslint-disable no-param-reassign */
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Container, LoadingBounce, PreventTransitionPrompt, useDialogs } from '@michelin/acid-components';
import { CentralProviderContext, usePermissions, useTranslation } from '@michelin/central-provider';
import { SelectOption, getOptions } from '@michelin/select-options-provider';
import { auth } from 'Auth';
import { PRIMARY_LEVEL_VALUE, SECONDARY_LEVEL_VALUE } from 'components/Contact/ContactDetails/utils';
import { Contact } from 'components/Contact/utils';
import Messages from 'components/Messages';
import { TABBED_CONTENT_HEIGHT, scrollToDisplayFirstError } from 'components/Util';
import { DefaultProfiles } from 'modules/Billing';
import { checkTireDetails } from 'modules/Billing/Profiles/Panels/RequestedTiresDetail';
import { checkWheelDetails } from 'modules/Billing/Profiles/Panels/RequestedWheelsDetail';
import { useSnackbar } from 'notistack';
import { getBaseUrl } from 'prefs-and-service-offers';
import { useHistory } from 'react-router-dom';
import { tableQuery } from '../UpdateLink/profileQuery';
import Approvals, { checkApprovals } from './Approvals';
import BillingFabs from './Fabs';
import GeneralInformation, { checkGeneral, mandatoryDefaultmessage } from './GeneralInformation';
import { isDefaultProfile } from './GeneralInformation/utils';
import PurchasingProcedures from './PurchasingProcedures';
import { saveReassignations } from './PurchasingProcedures/utils';
import ReassignContactTypes from './ReassignContactTypes';
import { BillingReassignContactType } from './ReassignContactTypes/dialogs';
import { getChildrenAndParentsFromArray } from './ReassignContactTypes/utils';
import RequestedOffInstalledDetails from './RequestedOffInstalledDetails';
import RequestedPhotos from './RequestedPhotos';
import UniqueBillingProfileModal from './UniqueBillingProfileModal';
import { checkBothPurchasingProcedures } from './innerUtils';
import {
  BillingPreferences as BPrefs,
  BillingPreferences as BillingPreferencesType,
  BillingProfileLocation,
  BillingProfileTypes,
  BillingRequirementsPreferencesQuery,
  BillingServiceTypes,
  GET_BILLING_PREFERENCES,
  GET_BILLING_REQUIREMENTS,
  QueryBillingPreferences,
} from './query';
import {
  ValidationErrors,
  adjustBillingProfile,
  checkLocations,
  checkUniqueness,
  checkUserPermissions,
  getAllBillingRequirements,
  inputValidationErrors,
  newDefaultProfile,
  retrieveContacts,
  retrieveLocations,
  updateAssignedContactTypes,
  updateBillingProfileMutation,
  updateContactsWheelsTiresAndDollarAmount,
} from './utils';
import { SubBillingRequirements } from './utilsRequirements';

// These values should be standars in the DB Option Select Provider.
const graphqlErrorTitle = 'Database Error';
const graphqlFirstErrorMessage = 'Application could not load billing requirements data.';
const graphqlSecondErrorMessage = 'Please try again later or contact support if the error persists';
interface IBillingProfileContext {
  contacts: Contact[];
  expLocations: BillingProfileLocation[];
  assignedContactTypes: Map<string, BillingReassignContactType>;
}
export const BillingProfileContext = React.createContext<IBillingProfileContext>({
  contacts: [],
  expLocations: [],
  assignedContactTypes: new Map(),
});

interface BillingPreferencesProps {
  onCallFlag: boolean;
  setDataAndLoading: Function;
  action: 'edit' | 'view' | 'create';
  profileId: string;
  profileSummaryList: BillingPreferencesType[];
  handleReloadProfileSummaryList: () => Promise<void>;
  defaultProfiles: DefaultProfiles;
  setHeaderTitle: Dispatch<SetStateAction<string>>;
  setHeaderSubtitle: Dispatch<SetStateAction<string>>;
}

export interface ReassignContactTypesState {
  contactTypes: string[];
  contactTypeLevel: string;
  contactLevel: string;
  show: boolean;
  manualClick: boolean;
}
const EmptyReassignContactTypesState: ReassignContactTypesState = {
  contactTypes: [],
  contactTypeLevel: '',
  contactLevel: '',
  show: false,
  manualClick: false,
};

export default function BillingPreferences(props: BillingPreferencesProps) {
  const {
    onCallFlag,
    action,
    profileId: profileIdParameter,
    profileSummaryList,
    handleReloadProfileSummaryList,
  } = props;
  const { apolloClient } = auth;
  const customerNumber = auth.getCustomerNumber();
  const { hasFullAccountAccess } = useContext(CentralProviderContext);
  const permissions = usePermissions();

  const editFlag = action === 'edit' || action === 'create';
  const isUrbanCustomer: boolean = !!(
    permissions.location &&
    permissions.location.is_urban &&
    !permissions.location.is_commercial
  );

  /* DIALOGS */
  const { messageDialog } = useDialogs();

  /* OPTIONS */
  const { t } = useTranslation();
  const profileTypes: SelectOption[] = getOptions('profile_types').filter((x) => {
    if (isUrbanCustomer && x.value !== 'onsite') {
      return false;
    }
    return true;
  });

  /* PERMISSIONS */
  const updatePermission = permissions.allowsAction('billing.update');

  /* STATES */
  const [originalProfile, setOriginalProfile] = useState<undefined | BPrefs>(undefined);
  const [profile, setProfile] = useState<undefined | BPrefs>(undefined);
  const [allLocations, setAllLocations] = useState<undefined | BillingProfileLocation[]>(undefined);
  const [locations, setLocations] = useState<undefined | BillingProfileLocation[]>(undefined);
  const [originalLocations, setOriginalLocations] = useState<undefined | BillingProfileLocation[]>(undefined);
  const [{ data: billingRequirementsLocationHashKeyMap }, setBillingRequirementsState] = useState<{
    loading: boolean;
    error: any;
    data: undefined | Map<string, SubBillingRequirements>;
  }>({
    loading: true,
    error: undefined,
    data: undefined,
  });
  const [loadingLocations, setLoadingLocations] = useState(true);
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [{ loading: loadingContacts, data: contacts, allData: allContacts }, setContactsState] = useState<{
    loading: boolean;
    error: any;
    data: undefined | Contact[];
    allData: undefined | Contact[];
  }>({
    loading: true,
    error: undefined,
    data: undefined,
    allData: undefined,
  });

  const profileId =
    (profile &&
      profile.hash_key &&
      typeof profile.hash_key === 'string' &&
      profile.hash_key.length > 2 &&
      profile.hash_key.substr(2)) ||
    profileIdParameter;

  // Flags states
  const [savingFlag, setSavingFlag] = useState(false);
  const [modifiedFlag, setModifiedFlag] = useState(false);
  const [uniqueBillingProfile, setUniqueBillingProfile] = useState(false);

  // Saving validation errors state
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>(inputValidationErrors);

  // Approvals modified contacts state
  const [specialContacts, setSpecialContacts] = useState<Contact[]>([]);

  const [displayReassignContactTypes, setDisplayReassignContactTypes] = useState(false);
  // States for Reassign Contact Types Work Flow
  const [reassignContactTypesState, setReassignContactTypesState] =
    useState<ReassignContactTypesState>(EmptyReassignContactTypesState);
  const [assignedContactTypes, setAssignedContactTypes] = useState(new Map<string, BillingReassignContactType>());

  // Uniqueness locations
  const [duplicatedLocations, setDuplicatedLocations] = useState<number>();
  const [availableLocations, setAvailableLocations] = useState<BillingProfileLocation[]>([]);
  const [totalLocations, setTotalLocations] = useState<number>();
  const [profileType, setProfileType] = useState(
    profile && profile.general_information && profile.general_information.profile_type
      ? profile.general_information.profile_type
      : BillingProfileTypes.ers_onsite,
  );

  /* STATES-END */
  const setDefaultProfile = (p: BPrefs, def: boolean, locs: BillingProfileLocation[] = []) => {
    if (!locations || p.general_information.set_all_locations !== def) {
      p.assigned_locations = [];
      setLocations(def ? JSON.parse(JSON.stringify(locs)) : []);
    }
    p.general_information.default = def;
    p.general_information.set_all_locations = def;
    if (!def && permissions.location && permissions.location.customer_type.toLowerCase() === 'st') {
      p.general_information.set_all_locations = true;
    }
  };

  /*
    Query Billing Requirements for POSH Imporete field
  */
  const { loading: loadingBillingRequirement, data: billingRequirementQueryData } =
    useQuery<BillingRequirementsPreferencesQuery>(GET_BILLING_REQUIREMENTS, {
      variables: { hash_key: `6~${customerNumber}` },
      fetchPolicy: 'no-cache',
    });

  /* EFFECTS */
  /*
   Obtain or create profile and their locations depending on the action.
  */
  useEffect(() => {
    if (action === 'view' || action === 'edit') {
      props.setHeaderTitle(t('Billing Profile'));
      props.setHeaderSubtitle(`${t('loading...')}`);
    } else {
      props.setHeaderTitle(t('Create Billing Profile'));
      props.setHeaderSubtitle('');
    }

    const queryAsyncInfo = async () => {
      const billingRequirement =
        billingRequirementQueryData &&
        billingRequirementQueryData.getCustomerAssets &&
        billingRequirementQueryData.getCustomerAssets.billingRequirements;
      if (apolloClient) {
        const newBillingRequirementsPerLocationHashKey = getAllBillingRequirements();
        const newQueriedLocations = await retrieveLocations(apolloClient, '', true);

        if (action === 'view' || action === 'edit') {
          // The profile already exists.
          const billingProfileHashKeyToRequest = `7~${profileId}`;
          const { data: queriedBillingProfile } = await apolloClient.query<QueryBillingPreferences>({
            query: GET_BILLING_PREFERENCES,
            variables: { hash_key: billingProfileHashKeyToRequest },
            fetchPolicy: 'no-cache',
          });

          props.setHeaderSubtitle(queriedBillingProfile?.getBillingPrefs?.general_information?.profile_name || '');

          const queriedBillingProfileAllLocations =
            queriedBillingProfile &&
            queriedBillingProfile.getBillingPrefs &&
            queriedBillingProfile.getBillingPrefs.general_information &&
            queriedBillingProfile.getBillingPrefs.general_information.set_all_locations === true;

          const queriedLocations = await retrieveLocations(
            apolloClient,
            billingProfileHashKeyToRequest,
            queriedBillingProfileAllLocations,
            queriedBillingProfile.getBillingPrefs.owner_relationship,
          );
          const adjustedBillingProfile = adjustBillingProfile(
            queriedBillingProfile.getBillingPrefs,
            billingRequirement,
            false,
            queriedLocations,
            await newBillingRequirementsPerLocationHashKey,
          );

          setOriginalProfile(JSON.parse(JSON.stringify(adjustedBillingProfile)));
          setProfile(adjustedBillingProfile);
          setLocations(queriedLocations);
          setAllLocations(newQueriedLocations);
          setOriginalLocations(JSON.parse(JSON.stringify(queriedLocations)));
        } else if (action === 'create') {
          // All locations is always settled as yes. It's a default.

          // The profile is new.
          const ownerToAssign = auth.getCustomerData();
          if (ownerToAssign) {
            const { hash_key: ownerHashKey, customer_type: ownerType, customer_number: ownerNumber } = ownerToAssign;
            if (ownerType && ownerNumber) {
              const adjustedNewBillingProfile = adjustBillingProfile(
                newDefaultProfile(
                  ownerHashKey || auth.getCustomerNumber(),
                  'v0_customer',
                  ownerType,
                  ownerNumber,
                  // GSDCP-2158
                  isUrbanCustomer,
                ),
                billingRequirement,
                true,
                newQueriedLocations,
                await newBillingRequirementsPerLocationHashKey,
              );
              const defaultProfile = isDefaultProfile(adjustedNewBillingProfile, props.defaultProfiles);
              setDefaultProfile(adjustedNewBillingProfile, defaultProfile, newQueriedLocations);

              if (defaultProfile) messageDialog(mandatoryDefaultmessage(t), '', t('OK'), () => {});
              setOriginalProfile(JSON.parse(JSON.stringify(adjustedNewBillingProfile)));
              setProfile(adjustedNewBillingProfile);
              setAllLocations(newQueriedLocations);
              setOriginalLocations(JSON.parse(JSON.stringify(newQueriedLocations)));
            }
          }
        }
        setBillingRequirementsState({
          loading: false,
          error: undefined,
          data: await newBillingRequirementsPerLocationHashKey,
        });
        setLoadingProfile(false);
        setLoadingLocations(false);
      }
    };
    if (!loadingBillingRequirement) queryAsyncInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingBillingRequirement]);

  /*
    Obtain the contacts from the locations selected for the billing profile
  */
  useEffect(() => {
    const asyncEffect = async () => {
      setContactsState({
        loading: true,
        error: undefined,
        data: undefined,
        allData: undefined,
      });
      if (profile && profile.general_information) {
        if (profile.general_information.set_all_locations) {
          if (allLocations && allLocations.length > 0) {
            try {
              const { owner: profileOwner, owner_relationship } = profile;

              if (profileOwner) {
                const ownerRelationshipsSplitted = owner_relationship ? owner_relationship.split('~') : [];
                const ownerParentsRelationshipsSet = new Set<string>(ownerRelationshipsSplitted);
                const { customer_number: profileOwnerNumber, customer_type } = profileOwner;
                const filteredAllLocations = allLocations.filter((x: BillingProfileLocation) => {
                  if (ownerParentsRelationshipsSet.has(x.customer_number)) {
                    return true;
                  }
                  switch (customer_type) {
                    case 'PC':
                      return x.parent_company_number === profileOwnerNumber;
                    case 'HO':
                      return x.home_office_number === profileOwnerNumber;
                    case 'BT':
                      return x.bill_to_customer === profileOwnerNumber;
                    case 'ST':
                      return x.ship_to_customer === profileOwnerNumber;
                    default:
                      return false;
                  }
                });

                const {
                  contactsFilteredByAssignedLocationsHierarchy: contactsData,
                  contactsFilteredByAssignedAndCurrentLocation: allContactsData,
                } = await retrieveContacts(allLocations, filteredAllLocations);
                setContactsState({
                  loading: false,
                  error: undefined,
                  data: contactsData,
                  allData: allContactsData,
                });
              } else {
                setContactsState({
                  loading: false,
                  error: undefined,
                  data: [],
                  allData: [],
                });
              }
            } catch (e) {
              setContactsState({
                loading: false,
                error: e,
                data: undefined,
                allData: undefined,
              });
            }
          }
        } else if (locations && locations.length > 0) {
          try {
            const {
              contactsFilteredByAssignedLocationsHierarchy: contactsData,
              contactsFilteredByAssignedAndCurrentLocation: allContactsData,
            } = await retrieveContacts(locations);
            setContactsState({
              loading: false,
              error: undefined,
              data: contactsData,
              allData: allContactsData,
            });
          } catch (e) {
            setContactsState({
              loading: false,
              error: e,
              data: undefined,
              allData: undefined,
            });
          }
        } else {
          setContactsState({
            loading: false,
            error: undefined,
            data: [],
            allData: [],
          });
        }
      } else {
        setContactsState({
          loading: false,
          error: undefined,
          data: [],
          allData: [],
        });
      }
    };
    asyncEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, allLocations]);

  /*
    Load billing requirements
  */
  useEffect(() => {
    const asyncLoadAndCheckBillingRequirements = async () => {
      if (billingRequirementsLocationHashKeyMap && locations) {
        let po_required_for_service = false;
        let participates_in_approve_orders = false;
        locations.forEach((location) => {
          const { customer_number } = location;
          if (customer_number) {
            const billingRequirement = billingRequirementsLocationHashKeyMap.get(`1~${customer_number}`);
            if (billingRequirement && billingRequirement.po && billingRequirement.po.flag === 'Y') {
              po_required_for_service = true;
            }
            if (
              billingRequirement &&
              billingRequirement.fleet_dr_approval &&
              billingRequirement.fleet_dr_approval.flag === 'Y'
            ) {
              participates_in_approve_orders = true;
            }
          }
        });
        if (profile) {
          const newProfile = { ...profile };
          newProfile.general_information.po_required_for_service = po_required_for_service;
          newProfile.general_information.participates_in_approve_orders = participates_in_approve_orders;
          setProfile(newProfile);
        }
      }
    };
    asyncLoadAndCheckBillingRequirements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations]);

  const [expLocations, setExpLocations] = useState<BillingProfileLocation[]>([]);
  useEffect(() => {
    setExpLocations(getChildrenAndParentsFromArray(locations || [], allLocations || []));
  }, [locations, allLocations]);

  // Check permissions
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  checkUserPermissions(action, enqueueSnackbar, permissions, history);

  /*
    Discard & Save methods
  */
  const save = async (useAvailableLocations: boolean) => {
    try {
      setSavingFlag(true);
      if (auth && auth.apolloClient && profile && profile.general_information) {
        /* Check Mandatory Fields */
        const { check: mandatoryFieldsCheck, valErrors: mandatoryValErrors } = checkBothPurchasingProcedures(
          profile,
          validationErrors,
        );

        if (mandatoryFieldsCheck === true) {
          const errorMessage = t('Please enter values in highlighted required fields');
          setValidationErrors(mandatoryValErrors);
          return { errorMessage };
        }

        /* Check General Information */
        const valErrors = JSON.parse(JSON.stringify(validationErrors)) as ValidationErrors;
        let check = checkGeneral(
          profile.general_information,
          useAvailableLocations ? availableLocations : locations || [],
          valErrors,
          t,
        );
        if (check) {
          setValidationErrors(valErrors);
          setSavingFlag(false);
          return check;
        }

        /* Check Approvals */
        check = checkApprovals(
          profile.general_information.profile_type,
          profile.general_information.service_type,
          profile.approvals,
          specialContacts,
          valErrors,
        );
        if (check) {
          setValidationErrors(valErrors);
          setSavingFlag(false);
          return check;
        }

        /* Check Tire Details */
        check = checkTireDetails(t, profile.tire_details);
        if (check) {
          setSavingFlag(false);
          return check;
        }
        /* Check Wheel Details */
        check = checkWheelDetails(t, profile.wheel_details);
        if (check) {
          setSavingFlag(false);
          return check;
        }

        if (useAvailableLocations === false) {
          const uniquenessStatus = checkUniqueness(profile, profileId, locations || [], profileSummaryList);
          setAvailableLocations(uniquenessStatus.availableLocations);
          setDuplicatedLocations(uniquenessStatus.duplicatedCount);
          setTotalLocations(uniquenessStatus.locationsCount);
          setUniqueBillingProfile(uniquenessStatus.duplicatedCount !== 0);
          if (uniquenessStatus.duplicatedCount) {
            setSavingFlag(false);
            return undefined;
          }
        }

        // Update the billing profile
        const updatedCorrectly = await updateBillingProfileMutation(
          auth.apolloClient,
          profile,
          originalProfile,
          profileId,
          useAvailableLocations ? availableLocations : locations || [],
          originalLocations || [],
        );

        if (updatedCorrectly) {
          // Check If Reassign Contact Types flow needs to be activated
          const possibleContactReassignState = checkLocations(
            profile,
            contacts || [],
            useAvailableLocations ? availableLocations : locations || [],
            assignedContactTypes,
          );
          if (possibleContactReassignState) setReassignContactTypesState(possibleContactReassignState);

          // Update contact type reassigns if there is some
          const updatedAssignedContactTypes = updateAssignedContactTypes(assignedContactTypes);
          saveReassignations(new Map(updatedAssignedContactTypes));
          setAssignedContactTypes(new Map());

          // Update Contacts Wheels, Tires and Tire Dollar amount if there is some
          updateContactsWheelsTiresAndDollarAmount(auth.apolloClient, specialContacts, contacts || []);

          // Update states
          props.setHeaderTitle(t('Billing Profile'));
          props.setHeaderSubtitle(profile.general_information.profile_name);
          enqueueSnackbar(t('Successfully saved profile!'), { variant: 'success' });
          setSavingFlag(false);
          setModifiedFlag(false);
          setOriginalProfile(JSON.parse(JSON.stringify(profile)));
          setSpecialContacts([]);
          setLocations([...(locations || [])]);
          setOriginalLocations(JSON.parse(JSON.stringify(locations || [])));
          props.setDataAndLoading({ data: [1], loading: true });
          handleReloadProfileSummaryList();
          setTimeout(() => {
            if (action === 'create') {
              history.push(`${getBaseUrl()}/billing/${profileId}/edit`);
            }
          }, 300);
          setTimeout(async () => {
            if (auth && auth.apolloClient) {
              const data = await auth.apolloClient.query({
                query: tableQuery,
                variables: {
                  relationship: auth.getRelationship(),
                  queryName: 'billingProfileLog',
                  anyRecord: true,
                  dateString: (() => {
                    const a = new Date();
                    a.setMonth(a.getMonth() - 3);
                    return a.toISOString().replace(/t.+$/i, '');
                  })(),
                },
                fetchPolicy: 'no-cache',
              });
              props.setDataAndLoading({ loading: false, data: data.data });
            }
          }, 2000);
        } else {
          throw new Error(t('Error saving profile'));
        }
      }
    } catch (e) {
      enqueueSnackbar(t('Error has occured when saving.'), { variant: 'error' });
      scrollToDisplayFirstError();
      setSavingFlag(false);
      setModifiedFlag(true);
    }

    return undefined;
  };

  const discard = () => {
    setModifiedFlag(false);
    setProfile(JSON.parse(JSON.stringify(originalProfile)));
    setLocations(JSON.parse(JSON.stringify(originalLocations)));
    setSavingFlag(false);
    setAssignedContactTypes(new Map());
    setValidationErrors(inputValidationErrors);
    setSpecialContacts([]);
    setReassignContactTypesState(EmptyReassignContactTypesState);
  };

  const handleSaveButtonClick = () => {
    const savePromise = save(false);
    savePromise
      .then((saveResult: { errorMessage: string } | undefined) => {
        if (saveResult) {
          const { errorMessage } = saveResult;
          enqueueSnackbar(
            <>
              {t('Error has occured when saving.')}
              <br />
              {errorMessage}
            </>,
            { variant: 'error' },
          );
          scrollToDisplayFirstError();
        }
      })
      .finally(() => {
        setSavingFlag(false);
      });
  };

  /*
    Uniqueness handlers
  */
  const handleUniquenessSaveSelections = () => {
    setLocations(availableLocations);
    save(true);
    setUniqueBillingProfile(false);
  };

  const handleUniquenessDialogClose = () => {
    setUniqueBillingProfile(false);
    setSavingFlag(false);
  };

  /*
    Contact types check handler
  */
  const handleContactLevelChange = (
    contactLevel: string,
    contactType: string[],
    primary: boolean,
    manualClick?: boolean,
  ) => {
    const mappedContactTypeLevel = primary ? PRIMARY_LEVEL_VALUE : SECONDARY_LEVEL_VALUE;
    setReassignContactTypesState({
      contactLevel,
      contactTypeLevel: mappedContactTypeLevel,
      contactTypes: contactType,
      show: true,
      manualClick: manualClick || false,
    });
    setDisplayReassignContactTypes(true);
  };

  /*
    Profile type handler
  */
  const profileTypeHandler = async (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    if (!profile) return;
    const newProfile = { ...profile };
    const newProfileType = event.target.value as BillingProfileTypes;
    newProfile.general_information.profile_type = newProfileType;
    const defaultProfile = isDefaultProfile(newProfile, props.defaultProfiles);
    setDefaultProfile(newProfile, defaultProfile, originalLocations);
    if (defaultProfile) messageDialog(mandatoryDefaultmessage(t), '', t('Ok'), () => {});
    setModifiedFlag(true);
    setProfileType(newProfileType);
    setProfile(newProfile);
  };

  /*
    Wait al the lata is Loaded
  */
  if (loadingProfile === true || loadingBillingRequirement === true)
    return <LoadingBounce style={{ height: TABBED_CONTENT_HEIGHT }} />;

  /*
    Check update permission by location hierarchy
  */
  let updateByHierarchyEnabled = true;
  const isMichelinEmployee = hasFullAccountAccess('fleets');
  if (profile && profile.owner_relationship && !isMichelinEmployee) {
    updateByHierarchyEnabled = profile.owner_relationship.includes(auth.getMainCustomerNumber());
  }
  /*
    All the data ready to work.
  */
  if (!profile || !originalProfile || !locations) {
    return (
      <Messages.PlainMessage
        title={graphqlErrorTitle}
        messages={[graphqlFirstErrorMessage, graphqlSecondErrorMessage]}
      />
    );
  }

  return (
    <BillingProfileContext.Provider value={{ contacts: contacts || [], expLocations, assignedContactTypes }}>
      <Container>
        <GeneralInformation
          loadingLocations={loadingLocations}
          setModifiedFlag={setModifiedFlag}
          onCallFlag={onCallFlag}
          editProfile={setProfile}
          editLocations={(editLocations: any) => {
            setLocations([...editLocations]);
          }}
          locations={locations}
          profile={profile}
          editFlag={editFlag}
          validationErrors={validationErrors}
          profileTypeHandler={profileTypeHandler}
          isUrbanCustomer={isUrbanCustomer}
        />

        {profile.general_information.profile_type.toUpperCase() !== BillingProfileTypes.onsite.toUpperCase() ? (
          <PurchasingProcedures
            loading={loadingContacts || loadingLocations}
            setModifiedFlag={setModifiedFlag}
            profile={profile}
            editProfile={setProfile}
            profileType={profileType}
            ers
            editFlag={editFlag}
            locations={locations}
            setLocations={setLocations}
            contacts={allContacts || []}
            validationErrors={validationErrors.pp_ers}
            handleContactLevelChange={handleContactLevelChange}
          />
        ) : null}
        {profile.general_information.profile_type !== BillingProfileTypes.ers ? (
          <PurchasingProcedures
            loading={loadingContacts || loadingLocations}
            setModifiedFlag={setModifiedFlag}
            profile={profile}
            editProfile={setProfile}
            profileType={profileType}
            ers={false}
            editFlag={editFlag}
            locations={locations}
            setLocations={setLocations}
            contacts={allContacts || []}
            validationErrors={validationErrors.pp_os}
            handleContactLevelChange={handleContactLevelChange}
          />
        ) : null}
        <Approvals
          specialContacts={specialContacts}
          setModifiedFlag={setModifiedFlag}
          setSpecialContacts={setSpecialContacts}
          setProfile={setProfile}
          editFlag={editFlag}
          profile={profile}
          loading={loadingContacts || loadingLocations}
          error={undefined}
          validationErrors={validationErrors}
          data={contacts || []}
        />
        {isUrbanCustomer === false && (
          <>
            {profile.general_information.service_type !== BillingServiceTypes.MECHANICAL && (
              <>
                <RequestedOffInstalledDetails
                  setModifiedFlag={setModifiedFlag}
                  editFlag={editFlag}
                  isTire
                  profile={profile}
                  editFunction={setProfile}
                />
                <RequestedOffInstalledDetails
                  setModifiedFlag={setModifiedFlag}
                  editFlag={editFlag}
                  isTire={false}
                  profile={profile}
                  editFunction={setProfile}
                />
              </>
            )}
            <RequestedPhotos
              setModifiedFlag={setModifiedFlag}
              profile={profile}
              isEdit={editFlag}
              changeProfile={setProfile}
            />
          </>
        )}

        {/* FABS BUTTONS - SAVE - CANCEL - BACK */}
        <BillingFabs
          editFlag={editFlag}
          handleSaveClick={handleSaveButtonClick}
          modifiedFlag={modifiedFlag}
          profileId={props.profileId}
          savingFlag={savingFlag}
          updatePermission={updatePermission && updateByHierarchyEnabled}
        />

        {/* UNIQUENESS MODAL */}
        <UniqueBillingProfileModal
          open={uniqueBillingProfile}
          handleClose={handleUniquenessDialogClose}
          handleSave={handleUniquenessSaveSelections}
          profile={profile}
          profileTypes={profileTypes}
          duplicatedLocations={duplicatedLocations}
          totalLocations={totalLocations}
          availableLocations={availableLocations}
        />

        {/* PREVENT TRANSITION */}
        <PreventTransitionPrompt when={modifiedFlag} handleDiscard={discard} />

        {/* REASSIGN CONTACT TYPES FLOW */}
        <ReassignContactTypes
          onClose={(newAssignedContactTypes: Map<string, BillingReassignContactType>) => {
            setDisplayReassignContactTypes(false);
            setAssignedContactTypes(new Map(newAssignedContactTypes));
          }}
          setModifiedFlag={setModifiedFlag}
          reassignContactTypesState={reassignContactTypesState}
          display={displayReassignContactTypes}
        />
      </Container>
    </BillingProfileContext.Provider>
  );
}
