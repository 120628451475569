import { auth } from 'Auth';
import { getChainedHierarchy, getHighestLocation } from 'components/Contact/utils';
import gql from 'graphql-tag';
import { getAllResultsPaginated } from 'graphql/utils';
import { BillingPreferences } from '../Preferences/query';
import { typeBillingProfilesAssigned, typeBillingProfilesOwned, typeCustomer } from './types';

export const GET_BILLING_PROFILES = gql`
  query getBillingProfiles($relationship: String!) {
    getBillingProfiles(relationship: $relationship) {
      default
      is_deleted
      profile_name
      profile_type
      service_type
      profile_id
      assigned_locations
      set_all_locations
    }
  }
`;

export const getTypeString = (accountType: string, mode?: string) => {
  let parentTypeString: string = '';
  switch (accountType.toLowerCase()) {
    case 'st':
      parentTypeString = `
        parent {
          ${typeCustomer}
          ${typeBillingProfilesOwned}
          ${typeBillingProfilesAssigned}
          parent {
            ${typeCustomer}
            ${typeBillingProfilesOwned}
            ${typeBillingProfilesAssigned}
            parent {
              ${typeCustomer}
              ${typeBillingProfilesOwned}
              ${typeBillingProfilesAssigned}
            }
         }
      }
      `;
      break;
    case 'bt':
      parentTypeString = `
        parent {
          ${typeCustomer}
          ${typeBillingProfilesOwned}
          ${typeBillingProfilesAssigned}
          parent {
            ${typeCustomer}
            ${typeBillingProfilesOwned}
            ${typeBillingProfilesAssigned}
          }
      }
      `;
      break;
    case 'ho':
      parentTypeString = `
        parent {
          ${typeCustomer}
          ${typeBillingProfilesOwned}
          ${typeBillingProfilesAssigned}
      }
      `;
      break;

    default:
      break;
  }

  if (mode === 'all_children') {
    return `
      ${typeCustomer}
      ${typeBillingProfilesOwned}
      ${typeBillingProfilesAssigned}
      children {
        ${typeCustomer}
        ${typeBillingProfilesOwned}
        ${typeBillingProfilesAssigned}
        children {
          ${typeCustomer}
          ${typeBillingProfilesOwned}
          ${typeBillingProfilesAssigned}
          children {
            ${typeCustomer}
            ${typeBillingProfilesOwned}
            ${typeBillingProfilesAssigned}
          }
        }
      }
      ${parentTypeString}
    `;
  }

  return `
    ${typeCustomer}
    ${typeBillingProfilesOwned}
    ${typeBillingProfilesAssigned}
    children {
      ${typeCustomer}
      ${typeBillingProfilesOwned}
      ${typeBillingProfilesAssigned}
    }
    ${parentTypeString}
  `;
};

export const queryBillingProfiles = gql`
  query billingProfiles($hashKey: String!) {
    getCustomerBillingProfiles(hash_key: $hashKey) {
      hash_key
      range_key
      general_information {
        default
        profile_name
        profile_type
        service_type
        set_all_locations
      }
      owner_key
      owner_relationship
      assigned_locations
      owner {
        hash_key
        range_key
        customer_number
        customer_type
      }
    }
  }
`;

export const queryFilteredBillingProfiles = gql`
  query billingProfiles($hashKey: String!, $filter: String!) {
    getCustomerBillingProfiles(hash_key: $hashKey, filter: $filter) {
      hash_key
      range_key
      general_information {
        default
        profile_name
        profile_type
        service_type
        set_all_locations
      }
      owner_key
      owner_relationship
      assigned_locations
      owner {
        hash_key
        range_key
        customer_number
        customer_type
      }
    }
  }
`;

export const getCustomerLocations = gql`
  query CustomerAssets($hash_key: String!) {
    getCustomerAssets(hash_key: $hash_key, range_key: "v0_customer") {
      customer {
        hash_key
        children_count {
          total
          ho
          bt
          st
        }
      }
    }
  }
`;

export const getOwnedAndAssignedProfilesInChildrenHierarchy = async () => {
  const customerData = auth.getCustomerData();
  const flattenedBillingProfiles: any[] = [];
  const flattenedBillingRelationships: any[] = [];

  if (customerData) {
    const ownedPaginatedQuery = gql`
      query GenericPageableEntity(
        $hash_key: String!
        $range_key: String!
        $index: String!
        $limit: Int
        $nextToken: String
      ) {
        getEntityPaginated(
          hash_key: $hash_key
          range_key: $range_key
          index: $index
          limit: $limit
          nextToken: $nextToken
        ) {
          items
          nextToken
        }
      }
    `;
    const highestLocation = getHighestLocation(customerData);
    const chainedHierarchy = getChainedHierarchy(customerData);

    const allOwnedAndChildrenAssignedProfilesPaginated = await getAllResultsPaginated(
      ownedPaginatedQuery,
      {
        hash_key: `7~${highestLocation}`,
        range_key: chainedHierarchy,
        index: 'gsi2',
      },
      100,
      'getEntityPaginated',
    );

    try {
      if (
        allOwnedAndChildrenAssignedProfilesPaginated &&
        typeof Array.isArray(allOwnedAndChildrenAssignedProfilesPaginated)
      ) {
        allOwnedAndChildrenAssignedProfilesPaginated.forEach((paginatedBillingProfiles: any) => {
          const parsedBillingProfiles = JSON.parse(paginatedBillingProfiles);

          parsedBillingProfiles.forEach((billingProfileQueried: any) => {
            if (billingProfileQueried.is_deleted === false) {
              if (billingProfileQueried.general_information) {
                // Is a Billing Preference
                flattenedBillingProfiles.push(billingProfileQueried);
              } else {
                // Is a Billing Relationship
                flattenedBillingRelationships.push(billingProfileQueried);
              }
            }
          });
        });
      }
    } catch (e) {
      console.error(e);
    }
  }
  return { flattenedBillingProfiles, flattenedBillingRelationships };
};

export const addPossibleLocationToBillingMap = (
  finalBillingProfile: any,
  billingLocationsSearchText: Map<any, any>,
) => {
  const { hash_key: finalBillingProfileHashKey } = finalBillingProfile;

  if (finalBillingProfileHashKey) {
    let alreadyAddedStr = billingLocationsSearchText.get(finalBillingProfileHashKey);
    const newStr =
      `${finalBillingProfileHashKey.customer_addr1} ${finalBillingProfileHashKey.customer_addr2} ${finalBillingProfileHashKey.customer_city} ${finalBillingProfileHashKey.customer_name} ${finalBillingProfileHashKey.customer_number} ${finalBillingProfileHashKey.customer_state} ${finalBillingProfileHashKey.customer_type} ${finalBillingProfileHashKey.customer_zip} ${finalBillingProfileHashKey.extrnl_cust_id}`.replace(
        / (null )+/g,
        ' ',
      );

    if (alreadyAddedStr) {
      alreadyAddedStr = `${alreadyAddedStr} ${newStr}`;
    } else {
      alreadyAddedStr = newStr;
    }
    billingLocationsSearchText.set(finalBillingProfileHashKey, alreadyAddedStr);
  }
};

export async function getBillingProfilesSummaryList(): Promise<BillingPreferences[]> {
  if (!auth || !auth.apolloClient) return [];
  try {
    const result = await auth.apolloClient.query({
      query: queryBillingProfiles,
      variables: {
        hashKey: `1~${auth.getCustomerNumber()}`,
      },
      fetchPolicy: 'no-cache',
    });
    if (!result || !result.data) return [];
    return result.data.getCustomerBillingProfiles || [];
  } catch (e) {
    console.log(e);
    return [];
  }
}
