import React, { useState } from 'react';
import { Dialog, DialogActions } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { ClosableDialogContent } from 'components/ClosableDialog/ClosableDialogContent';
import { ClosableDialogTitle } from 'components/ClosableDialog/ClosableDialogTitle';
import { ContactControlDelegator, ContactDetails } from 'components/Contact/ContactDetails';
import { Contact } from 'components/Contact/utils';
import { BillingProfileLocation } from '../../query';
import { PORequiredContactTable } from '../Tables/poRequiredContactTable';

const useStyles = makeStyles(() => ({
  popup: { height: '100vh' },
}));

interface Props {
  editFlag: boolean;
  open: boolean;
  contacts: Array<Contact>;
  locations: BillingProfileLocation[];
  contactAssigned: string | undefined;
  onCloseHandler: () => void;
  setContact: (contact: Contact | undefined) => void;
  setLocations: (locations: BillingProfileLocation[]) => void;
}

export function PORequiredContactDialog(props: Props) {
  const { open, contacts, contactAssigned, setContact, locations, setLocations, editFlag } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const [createMode, setCreateMode] = useState(false);
  const dialogTitle = !createMode ? t('Select a Contact') : t('Create new Contact');
  let dialogContent = (
    <PORequiredContactTable
      editFlag={editFlag}
      contactAssigned={contactAssigned}
      contacts={contacts}
      setContact={setContact}
    />
  );

  const onCloseHandler = () => {
    if (createMode) {
      setCreateMode(false);
    } else {
      props.onCloseHandler();
    }
  };

  const createContactHandler = () => {
    setCreateMode(true);
  };

  const onAfterSaveHandler = async (contact: Contact) => {
    setContact(contact);
    setCreateMode(false);
    onCloseHandler();
    setLocations([...locations]);
  };

  const onAfterBackHandler = () => {
    setCreateMode(false);
  };

  const contactController: ContactControlDelegator = {};
  if (!open) return null;

  if (createMode) {
    dialogContent = (
      <ContactDetails
        action="create"
        hideHeader
        delegateControl={contactController}
        avoidFirstTimeAssign
        onAfterSaveHandler={onAfterSaveHandler}
        onAfterBackHandler={onAfterBackHandler}
        locations={[]}
      />
    );
  }

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      scroll="paper"
      open={open}
      classes={{ paper: classes.popup }}
      onClose={onCloseHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <ClosableDialogTitle onClose={onCloseHandler}>{dialogTitle}</ClosableDialogTitle>
      <ClosableDialogContent dividers style={{ padding: 0 }}>
        {dialogContent}
      </ClosableDialogContent>
      <DialogActions>
        {createMode ? (
          <Button
            color="success"
            style={{ margin: '8px' }}
            onClick={() => {
              if (contactController.save) contactController.save();
            }}
          >
            {t('Save')}
          </Button>
        ) : null}
        {editFlag && !createMode ? (
          <>
            <Button color="secondary" style={{ margin: '8px' }} onClick={createContactHandler}>
              {t('Create new Contact')}
            </Button>
            <Button color="success" style={{ margin: '8px' }} onClick={onCloseHandler}>
              {t('Accept')}
            </Button>
          </>
        ) : null}
        <Button
          color="danger"
          style={{ margin: '8px' }}
          onClick={!createMode ? onCloseHandler : () => setCreateMode(false)}
        >
          {t(!createMode ? 'Close' : 'Discard')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
