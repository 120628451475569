import React, { useEffect } from 'react';
import { usePermissions } from '@michelin/central-provider';
import { useHistory, useLocation } from 'react-router-dom';

export default function Main() {
  const { location, profile } = usePermissions();
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!location?.customer_number) return;
    const landing = `/${location?.customer_number}/all-preferences/locations`;
    const grantsCount = profile?.grants.length || 0;
    if (grantsCount < 2 && pathname === '/') {
      const locCount = profile?.grants[0].accounts.length || 0;
      if (locCount < 2) {
        history.push(landing);
        return;
      }
    }
    if (pathname !== `/${location?.customer_number}`) return;
    history.push(landing);
  }, [location, history, pathname, profile]);

  return <></>;
}
