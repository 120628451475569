import React, { useEffect, useState } from 'react';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import { Button, LoadingBounce } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { BillingProfileLocation } from 'components/Billing/Preferences/query';
import { BillingReassignContactType } from '..';
import LocationCard from '../../LocationCard';
import SelectContactTypesTable from '../../tables/selectContactTypesTable';
import { BillingReassignContact, getContactTypesFromContacts, getLocationContacts } from '../../utils';

interface Props {
  index: number;
  location: BillingProfileLocation;
  locationsNumber: number;
  assignedContactTypes: Map<string, BillingReassignContactType>;
  removedAssignedContactTypes: Set<string>;
  contactTypes: Array<string>;
  contactTypeLevels: Array<string>;
  onNext: () => void;
  onBack: () => void;
  // GSDCP-1874
  disableNext: boolean;
  disableBack: boolean;
  onChange: (level: string, roleArea: string, contact: BillingReassignContact, checked: boolean) => void;
}

interface State {
  loading: boolean;
  error: any;
  contacts: Array<BillingReassignContact>;
}

export async function loadContacts(locationSelected: BillingProfileLocation): Promise<Array<BillingReassignContact>> {
  const contacts: Array<BillingReassignContact> = await getLocationContacts(locationSelected.customer_number);
  return contacts;
}

export default function AssignContactTypes(props: Props): JSX.Element {
  const {
    index,
    location,
    locationsNumber,
    assignedContactTypes,
    removedAssignedContactTypes,
    onNext,
    onBack,
    onChange,
    disableNext,
    disableBack,
  } = props;
  const { t } = useTranslation();
  const [{ loading, contacts }, setState] = useState<State>({
    loading: true,
    error: undefined,
    contacts: [],
  });

  const contactTypesFromContacts = getContactTypesFromContacts(location, contacts);

  /* RETRIEVE CONTACTS IF A LOCATION HAS BEEN SELECTED */
  useEffect(() => {
    let isCancelled = false;
    setState({
      loading: true,
      error: undefined,
      contacts: [],
    });

    if (location) {
      const asyncEffect = async () => {
        // AVOID MULTIPLE QUERIES ON USER CLICKING NEXT MULTIPLE TIMES FAST
        setTimeout(async () => {
          if (isCancelled === false) {
            const c = await loadContacts(location);

            if (isCancelled === false) {
              setState({
                contacts: c,
                loading: false,
                error: undefined,
              });
            }
          }
        }, 1500);
      };

      asyncEffect();
    }

    // AVOID RACE-CONDITION ON MULTIPLES QUERIES
    return () => {
      isCancelled = true;
    };
  }, [location]);

  const contentHeader = (
    <div style={{ display: 'flex', margin: 16 }}>
      <span style={{ flex: '1 1 auto' }}>
        <LocationCard location={location} style={{ maxWidth: 'fit-content' }} />
      </span>
      <div style={{ flex: '1 1 auto', textAlign: 'right' }}>
        {disableBack === false && (
          <Button color="secondary" variant="contained" onClick={onBack}>
            <NavigateBefore />
            {` ${t('Prev')}`}
          </Button>
        )}
        {disableNext === false && (
          <Button color="primary" variant="contained" onClick={onNext} style={{ marginLeft: 8 }}>
            {`${t('Next')} `}
            <NavigateNext />
          </Button>
        )}
        <div>
          <p>{t('Location {{number}} of {{total}}', { number: index + 1, total: locationsNumber })}</p>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {contentHeader}
      {loading ? (
        <LoadingBounce style={{ height: '50vh' }} />
      ) : (
        <SelectContactTypesTable
          location={location}
          contacts={contacts}
          assignedContactTypes={assignedContactTypes}
          contactTypesFromContacts={contactTypesFromContacts}
          onChange={onChange}
          contactTypeLevels={props.contactTypeLevels}
          contactTypes={props.contactTypes}
          removedAssignedContactTypes={removedAssignedContactTypes}
        />
      )}
    </>
  );
}
